<template>
  <div>
    <div class="ant-page-header-tl">
      <a-select defaultValue="taboola" style="width: 120px; margin-right: 16px;" @change="handlePlatformChange">
        <a-select-option v-for="(item, index) in platformList" :key="index" :value="item.name">{{item.name}}</a-select-option>
      </a-select>
      <a-range-picker :default-value="dateString" @change="onDateChange"/>
      <a-button type="primary" style="margin-left: 16px;" @click="submitDateChange">Apply</a-button>
    </div>
    <div style="margin: 16px 24px -10px;"><b>{{dateString[0]}} ～ {{dateString[1]}}</b></div>
    <div class="section">
        <a-table 
          :columns="columns" 
          :data-source="dataList" 
          :rowKey="record => record.campaign_id + record.z_num"
          :pagination="false" 
          size="middle"
          bordered
          :loading="loading"
        >
        <div slot="campaign_name" slot-scope="text, record" style="overflow: hidden;text-decoration: underline;cursor: pointer;" @click="toLine(record.campaign_id,dateString[0],dateString[1])">{{text}}</div>
        <div class="smallbtn" slot="action" slot-scope="text, record">
          <a-button type="primary" size="small" @click="goCreated(record.campaign_id,'relaunch')" style="margin-right: 10px; background-color: #52c41a;border-color: #52c41a">Relaunch</a-button>
          <a-button type="primary" size="small" @click="toLine(record.campaign_id,dateString[0],dateString[1])" style="background-color: #4dcbcb;border-color: #4dcbcb">查看表现</a-button>
        </div>
        </a-table>
    </div>
  </div>
</template>
<script>
  import api from '@/common/api.js'
  import moment from 'moment';
  const defaultStartDate = moment().startOf('day').subtract(2, 'days');
  const defaultEndDate = moment().startOf('day').subtract(2, 'days')

  const columns = [
    {
      title:'Campaign ID',
      dataIndex: 'campaign_id',
      sorter: (a, b) => a.campaign_id - b.campaign_id,
    },
    {
      title:'Campaign Name',
      dataIndex: 'campaign_name',
      scopedSlots: { customRender: 'campaign_name' },
      sorter: (a, b) => a.campaign_name.localeCompare(b.campaign_name)
    },
    {
      title:'Create Time',
      dataIndex: 'create_time',
      customRender: text => {
        return moment(text * 1000).format('YYYY-MM-DD')
      },
      align: 'right',
      // sorter: (a, b) => a.create_time - b.create_time,
    },
    {
      title:'Session',
      dataIndex: 'sessions',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      align: 'right',
      sorter: (a, b) => a.sessions - b.sessions,
    },
    {
      title:'CPC上限',
      dataIndex: 'cpc_top',
      align: 'right',
      sorter: (a, b) => a.cpc_top - b.cpc_top,
    },
    {
      title:'spent',
      dataIndex: 'spent',
      align: 'right',
      sorter: (a, b) => a.spent - b.spent,
    },
    {
      title:'系数',
      dataIndex: 'x_num',
      align: 'right',
      sorter: (a, b) => a.x_num - b.x_num,
    },
    {
      title:'宗数',
      dataIndex: 'z_num',
      align: 'right',
      sorter: (a, b) => a.z_num - b.z_num,
    },
    {
      title:'宗数/花费',
      dataIndex: 'roi',
      align: 'right',
      sorter: (a, b) => a.roi - b.roi,
      customRender: text => {
        return text + "%"
      }
    },
    {
      title:'Detail',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
      width: '100px',
      align: 'center'
    }
  ]

  export default {
    data() {
      return {
        userInfo: {},
        defaultStartDate,
        defaultEndDate,
        url: '',
        columns,
        dataList: [],
        loading: false,
        post_coverage_data: {},
        dateString: [defaultStartDate.format('YYYY-MM-DD'),defaultEndDate.format('YYYY-MM-DD')],
        platformList: [
          {
            id: 0,
            name: 'taboola'
          },
          {
            id: 1,
            name: 'outbrain'
          },
        ],
        platform: 'taboola'
      }
    },
    methods: {
      handlePlatformChange(e){
        console.log(e)
        this.platform = e
      },
      onDateChange(date, dateString){
        this.dateString = dateString
        console.log(this.dateString); 
      },
      submitDateChange(){
        this.getList()
      },
      getList(){
        this.loading = true
        this.$post(api.update.getTopPerfCampaign, {
          period1: this.dateString,
          platform: this.platform,
          token: this.userInfo.token
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.dataList = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      toLine(campaign_id,start_date,end_date){
        const {href} = this.$router.resolve({
          path: '/line',
          query: {
            campaign_id: campaign_id,
            period: JSON.stringify([start_date, end_date])
          },
        })
        window.open(href, '_blank')
      },
      goCreated(campaign_id,type){
        let query = {}
        query = {
          campaign_id: campaign_id,
          type: type,
          platform: this.platform,
        }
        const {href} = this.$router.resolve({
          path: '/created',
          query: query,
        })
        window.open(href, '_blank')
      },
    },
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.getList()
    }
  };
</script>

<style> 
  .total-item{
    margin-right: 10px;
    margin-left: 10px;
    color: #ddd;
  }
</style>
