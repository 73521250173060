import Vue from 'vue'
import Vuex from 'vuex'
import VueDraggableResizable from 'vue-draggable-resizable'
import { Button, Input, Icon, Form, notification, message, Menu, Table, Row, Col, Spin, Tabs, Popconfirm, DatePicker, Select, Radio, Card, Dropdown, Pagination, Switch, Modal, Checkbox, FormModel, TimePicker, InputNumber, Upload, Drawer, Affix, Popover, Empty, Alert} from 'ant-design-vue';
import App from './App.vue'
import router from './router'
import VueResource from 'vue-resource'
import veLine from 'v-charts/lib/line.common'
// import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
// import axios from 'axios'
import {post,get} from './common/request'
// import VueAxios from 'vue-axios'
Vue.component('vue-draggable-resizable', VueDraggableResizable)
Vue.use(VueResource)
Vue.use(Vuex)
Vue.use(Button)
Vue.use(Input)
Vue.use(Spin)
Vue.use(Icon)
Vue.use(Form)
Vue.use(Menu)
Vue.use(message)
Vue.use(Table)
Vue.use(Row)
Vue.use(Col)
Vue.use(Tabs)
Vue.use(Popconfirm)
Vue.use(DatePicker)
Vue.use(Select)
Vue.use(Radio)
Vue.use(Card)
Vue.use(Dropdown)
Vue.use(Pagination)
Vue.use(Switch)
Vue.use(Modal)
Vue.use(Checkbox)
Vue.use(FormModel)
Vue.use(TimePicker)
Vue.use(InputNumber)
Vue.use(Upload)
Vue.use(Drawer)
Vue.use(Affix)
Vue.use(Popover)
Vue.use(Empty)
Vue.use(Alert)
Vue.component(veLine.name, veLine)
// Vue.prototype.$axios = axios;
Vue.prototype.$post = post;
Vue.prototype.$get = get;
// Vue.use(VueAxios, axios);

Vue.config.productionTip = false
Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
Vue.prototype.$confirm = Modal.confirm;

// const store = new Vuex.Store({
//   state: {
//     count: 0
//   },
//   mutations: {
//     increment (state) {
//       state.count++
//     }
//   }
// })

new Vue({
  render: h => h(App),
  router,
  // store
}).$mount('#app')
