// 引入插件
import Vue from 'vue'
import VueRouter from 'vue-router'

//告诉 vue 使用 vueRouter
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// Vue.use(Meta)

//引入组件
import WorkTotal from "../pages/okr/WorkTotal.vue";
import WorkIndividual from "../pages/okr/WorkIndividual.vue";
import WorkCampaign from "../pages/okr/WorkCampaign.vue";
import TotalList from "../pages/okr/TotalList.vue";
import GroupList from "../pages/okr/GroupList.vue";
import CampaignList from "../pages/okr/CampaignList.vue";
import PerforLine from "../pages/okr/PerforLine.vue";
import NewPost from "../pages/okr/NewPost.vue";
import NewNum from "../pages/okr/NewNum.vue";
import NewCampaign from "../pages/okr/NewCampaign.vue";
import Performance from "../pages/per/Performance.vue";
import CampaignAction from "../pages/campaign/CampaignAction.vue";
import AddCampaign from "../pages/campaign/AddCampaign.vue";
import CreatedCampaign from "../pages/campaign/Created.vue";
import BetterCampaign from "../pages/campaign/BetterCampaign.vue";
import ValuePost from "../pages/campaign/ValuePost.vue";
import TopCampaign from "../pages/campaign/TopCampaign.vue";
import CoverCampaign from "../pages/cover/CoverCampaign.vue";
import NoCoverPost from "../pages/cover/NoCoverPost.vue";
import ReCampaignList from "../pages/cover/ReCampaignList.vue";
import TopUrl from "../pages/cover/TopUrl.vue";
import Login from "../pages/user/Login.vue";
import BySite from "../pages/site/bysite.vue";
import ABTest from "../pages/abtest/ABTest.vue";
import ABTestDetail from "../pages/abtest/ABTestDetail.vue";
import ZumExpress from "../pages/zum/ZumExpress.vue";
import PushData from "../pages/zum/PushData.vue";
import AdwSpentTracking from "../pages/zum/AdwSpentTracking.vue";
import SitesPerfByProduct from "../pages/zum/SitesPerfByProduct.vue";
import SitesPerfByDate from "../pages/zum/SitesPerfByDate.vue";
import Draggable from "../pages/okr/Draggable.vue";
import ZumExpressDetail from "../pages/zum/ZumExpressDetail.vue";
import DailyZumExpress from "../pages/zum/DailyZumExpress.vue";
import AllSitesZumData from "../pages/zum/AllSitesZumData.vue";
import Data from "../pages/data/Data.vue";
import SiteDetail from "../pages/data/SiteDetail.vue";
import AffTotal from "../pages/aff/Total.vue";
import AffTotalByDay from "../pages/aff/TotalByDay.vue";
import AffPerf from "../pages/aff/Perf.vue";
import AffPerfByDay from "../pages/aff/PerfByDay.vue";
import AffBySite from "../pages/aff/AffBySite.vue";
import AffCreated from "../pages/aff/Created.vue";
import PauseCampaign from "../pages/campaign/PauseCampaign.vue";
import AlanReport from "../pages/alan/GetAlanReport.vue";
import SiteBlocking from "../pages/campaign/SiteBlocking.vue";
import SiteUnblock from "../pages/campaign/SiteUnblock.vue";
import SiteBlockingLog from "../pages/campaign/SiteBlockingLog.vue";
import PushAdd from "../pages/tools/PushAdd.vue";
import G7PushAdd from "../pages/tools/G7PushAdd.vue";
import PromoteProject from "../pages/campaign/PromoteProject.vue";
import AddOldPost from "../pages/campaign/AddOldPost.vue";
import PromoteWorkTotal from "../pages/campaign/PromoteWorkTotal.vue";
import PromoteWorkIndividual from "../pages/campaign/PromoteWorkIndividual.vue";
import PromoteWorkCampaign from "../pages/campaign/PromoteWorkCampaign.vue";
import CampaignTracking from "../pages/campaign/CampaignTracking.vue";
import SplitFlowPerf from "../pages/okr/SplitFlowPerf.vue";
import TaskLog from "../pages/tools/TaskLog.vue";
import AdwPerfByType from "../pages/zum/AdwPerfByType.vue";
import AdwPerfByCampaign from "../pages/zum/AdwPerfByCampaign.vue";
import AdwPerfByDay from "../pages/zum/AdwPerfByDay.vue";
import AdsViewable from "../pages/alan/AdsViewable.vue";
import SameCpnCover from "../pages/campaign/SameCpnCover.vue";
import BatchRelaunch from "../pages/campaign/BatchRelaunch.vue";
import AdwDaily from '../pages/per/AdwDaily';
import AdwDailyGA4 from '../pages/per/AdwDailyGA4.vue'
import BingDailyGA4 from '../pages/per/BingDailyGA4.vue'
// GA4
import AllSitesZumData4 from '../pages/zum/AllSitesZumData4';
import ZumExpressDetail4 from '../pages/zum/ZumExpressDetail4';
import AllSitesZumData0 from '../pages/zum/AllSitesZumData0';
import ZumExpressDetail0 from '../pages/zum/ZumExpressDetail0';

import AllSitesZumData01 from '../pages/zum/AllSitesZumData01';
import ZumExpressDetail01 from '../pages/zum/ZumExpressDetail01';

import RevenueCompare from '../pages/zum/RevenueCompare';
import BingTimezoneReport from '../pages/zum/BingTimezoneReport';
import ZindexByProduct from '../pages/zum/ZindexByProduct';

// Bing Report
import BingSpentReport from '../pages/zum/BingSpentReport';
import BingSpentReportDetail from '../pages/zum/BingSpentReportDetail';
import AutoBlock from '../pages/campaign/AutoBlock.vue'
import AutoBlockTask from '../pages/campaign/AutoBlockTask.vue'
import AutoBlockDetail from '../pages/campaign/AutoBlockDetail.vue'

const routes = [
  {
    path: "/BingSpentReportDetail",
    component: BingSpentReportDetail,
  },
  {
    path: "/BingSpentReport",
    component: BingSpentReport,
  },
  {
    path: "/ZindexByProduct",
    component: ZindexByProduct,
  },
  {
    path: "/BingTimezoneReport",
    component: BingTimezoneReport,
  },
  {
    path: "/RevenueCompare",
    component: RevenueCompare,
  },
  {
    path: "/zumexpress-detail4",
    component: ZumExpressDetail4,
  },
  {
    path: "/allsiteszum4",
    component: AllSitesZumData4,
  },
  {
    path: "/zumexpress-detail0",
    component: ZumExpressDetail0,
  },
  {
    path: "/allsiteszumdata0",
    component: AllSitesZumData0,
  },
  {
    path: "/zumexpress-detail01",
    component: ZumExpressDetail01,
  },
  {
    path: "/allsiteszumdata01",
    component: AllSitesZumData01,
  },
  {
    path: "/AdwSpentTracking",
    component: AdwSpentTracking,
  },
  {
    path: "/BatchRelaunch",
    component: BatchRelaunch,
  },
  {
    path: "/SameCpnCover",
    component: SameCpnCover,
  },
  {
    path: "/AdsViewable",
    component: AdsViewable,
  },
  {
    path: "/SiteUnblock",
    component: SiteUnblock,
  },
  {
    path: "/AdwPerfByDay",
    component: AdwPerfByDay,
  },
  {
    path: "/AdwPerfByCampaign",
    component: AdwPerfByCampaign,
  },
  {
    path: "/AdwPerfByType",
    component: AdwPerfByType,
  },
  {
    path: "/SitesPerfByProduct",
    component: SitesPerfByProduct,
  },
  {
    path: "/SitesPerfByDate",
    component: SitesPerfByDate,
  },
  {
    path: "/TaskLog",
    component: TaskLog,
  },
  {
    path: "/SplitFlowPerf",
    component: SplitFlowPerf,
  },
  {
    path: "/CampaignTracking",
    component: CampaignTracking,
  },
  {
    path: "/PromoteWorkCampaign",
    component: PromoteWorkCampaign,
  },
  {
    path: "/PromoteWorkIndividual",
    component: PromoteWorkIndividual,
  },
  {
    path: "/PromoteWorkTotal",
    component: PromoteWorkTotal,
  },
  {
    path: "/AddOldPost",
    component: AddOldPost,
  },
  {
    path: "/PromoteProject",
    component: PromoteProject,
  },
  {
    path: "/siteblocking",
    component: SiteBlocking,
  },
  {
    path: "/",
    component: DailyZumExpress,
  },
  {
    path: "/draggable",
    component: Draggable,
  },
  {
    path: "/totallist",
    component: TotalList,
  },
  {
    path: "/group",
    component: GroupList,
  },
  {
    path: "/campaign",
    component: CampaignList,
  },
  {
    path: "/line",
    component: PerforLine,
  },
  {
    path: "/dayznum",
    component: NewPost,
  },
  {
    path: "/newnum",
    component: NewNum,
  },
  {
    path: "/newcampaign",
    component: NewCampaign,
  },
  {
    path: "/performance",
    component: Performance,
  },
  {
    path: "/autoblock",
    component: AutoBlock,
  },
  {
    path: "/autoblocktask",
    component: AutoBlockTask,
  },
  {
    path: "/autoblockdetail",
    component: AutoBlockDetail,
  },
  {
    path: "/adw-daily",
    component: AdwDaily,
  },
  {
    path: "/adw-daily-ga4",
    component: AdwDailyGA4,
  },
  {
    path: "/bing-daily-ga4",
    component: BingDailyGA4,
  },
  {
    path: "/campaignaction",
    component: CampaignAction,
  },
  {
    path: "/AddCampaign",
    component: AddCampaign,
  },
  {
    path: "/created",
    component: CreatedCampaign,
  },
  {
    path: "/better",
    component: BetterCampaign,
  },
  {
    path: "/valuepost",
    component: ValuePost,
  },
  {
    path: "/topcampaign",
    component: TopCampaign,
  },
  {
    path: "/cover",
    component: CoverCampaign,
  },
  {
    path: "/nocoverpost",
    component: NoCoverPost,
  },
  {
    path: "/recampaignlist",
    component: ReCampaignList,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/topurl",
    component: TopUrl,
  },
  {
    path: "/bysite",
    component: BySite,
  },
  {
    path: "/abtest",
    component: ABTest,
  },
  {
    path: "/abtest-detail",
    component: ABTestDetail,
  },
  {
    path: "/zumexpress",
    component: ZumExpress,
  },
  {
    path: "/zumexpress-detail",
    component: ZumExpressDetail,
  },
  {
    path: "/daily-zumexpress",
    component: DailyZumExpress,
  },
  {
    path: "/allsiteszum",
    component: AllSitesZumData,
  },
  {
    path: "/data",
    component: Data,
  },
  {
    path: "/site-detail",
    component: SiteDetail,
  },
  {
    path: "/aff-total",
    component: AffTotal,
  },
  {
    path: "/aff-total-by-day",
    component: AffTotalByDay,
  },
  {
    path: "/aff-perf",
    component: AffPerf,
  },
  {
    path: "/aff-perf-by-day",
    component: AffPerfByDay,
  },
  {
    path: "/aff-by-site",
    component: AffBySite,
  },
  {
    path: "/aff-created",
    component: AffCreated,
  },
  {
    path: "/pause-campaign",
    component: PauseCampaign,
  },
  {
    path: "/alanreport",
    component: AlanReport,
  },
  {
    path: "/siteblockinglog",
    component: SiteBlockingLog,
  },
  {
    path: "/pushdata",
    component: PushData,
  },
  {
    path: "/pushadd",
    component: PushAdd,
  },
  {
    path: "/g7pushadd",
    component: G7PushAdd,
  },
  {
    path: "/WorkTotal",
    component: WorkTotal,
  },
  {
    path: "/WorkTotal",
    component: WorkTotal,
  },
  {
    path: "/WorkIndividual",
    component: WorkIndividual,
  },
  {
    path: "/WorkCampaign",
    component: WorkCampaign,
  },
];

var router = new VueRouter({
  routes,
});

export default router;
