<template>
  <div>
    <!-- date -->
    <div class="ant-page-header-tl">
      <a-date-picker
        v-if="dateString"
        :default-value="dateString"
        @change="onDateChange"
        style="margin-right: 8px"
      />
      <a-button type="primary" @click="submitDateChange">Apply</a-button>
    </div>
    <!-- table -->
    <div class="section">
      <a-table
        :columns="columns"
        :data-source="dataList"
        :rowKey="(record) => record.unique_key"
        :pagination="false"
        size="middle"
        bordered
        :loading="loading"
        class="data-table"
		defaultExpandAllRows
        v-if="dataList.length > 0"
      >
      </a-table>
    </div>
    <a-spin :spinning="spinning">
      <div class="section">
        <a-row>
          <a-col :span="12">
            <h4>Spend</h4>
            <ve-line
              :data="spendLineData"
              :legend-visible="false"
              :extend="chartExtend"
            ></ve-line>
          </a-col>
          <a-col :span="12">
            <h4>Clicks</h4>
            <ve-line
              :data="clicksLineData"
              :legend-visible="false"
              :extend="chartExtend"
            ></ve-line>
          </a-col>
        </a-row>
        <a-row>
          <div class="lang_box">
            <div class="lang_box_item">
              <span v-for="(item, index) in typeList" :key="index">
                <a-switch
                  :checked="item.checked"
                  @change="(e) => switchChange(e, item.name, item.color)"
                  :class="'switchcolor' + item.name"
                  size="small"
                />
                <span class="switch-tl">{{ item.name }}</span>
              </span>
            </div>
          </div>
        </a-row>
      </div>
    </a-spin>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
const defaultEndDate = moment()
  .startOf("day")
  .subtract(2, "days");

const columns = [
  {
    title: "DIVISION",
    dataIndex: "group",
    sorter: (a, b) => {
      if (!a.group.includes("TOTAL")) {
        return a.group.localeCompare(b.group);
      }
    },
  },
  {
    title: "Account Name",
    dataIndex: "account_name",
    sorter: (a, b) => {
      if (!a.group.includes("TOTAL")) {
        return a.account_name.localeCompare(b.account_name);
      }
    },
  },
  {
    title: "Account ID",
    dataIndex: "acocunt_id",
    sorter: (a, b) => {
      if (!a.group.includes("TOTAL")) {
        return a.acocunt_id.localeCompare(b.acocunt_id);
      }
    },
  },
  {
    title: "JBP Commitment",
    dataIndex: "jbp",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (!a.group.includes("TOTAL")) {
        return a.jbp - b.jbp;
      }
    },
  },
  {
    title: "Investment Quarter to Date",
    dataIndex: "quarter_perf",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (!a.group.includes("TOTAL")) {
        return a.quarter_perf - b.quarter_perf;
      }
    },
  },
  {
    title: "Last 7 days",
    dataIndex: "day_perf",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (!a.group.includes("TOTAL")) {
        return a.day_perf - b.day_perf;
      }
    },
  },
  {
    title: "GAP vs JBP Targets",
    dataIndex: "gap",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (!a.group.includes("TOTAL")) {
        return a.gap - b.gap;
      }
    },
  },
  {
    title: "Q1 Investment Extrapolation",
    dataIndex: "extrapolation",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (!a.group.includes("TOTAL")) {
        return a.extrapolation - b.extrapolation;
      }
    },
  },
  {
    title: "Q1 Delta vs Extrapolation",
    dataIndex: "extrapolation_gap",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (!a.group.includes("TOTAL")) {
        return a.extrapolation_gap - b.extrapolation_gap;
      }
    },
  },
];

export default {
  data() {
    return {
      userInfo: {},
      defaultEndDate,
      columns,
      dataList: [],
      loading: false,
      dateString: moment(defaultEndDate, "YYYY-MM-DD"),
      spendLineData: {
        columns: ["date", "Group1", "Group2", "Group3", "Group4", "Group5", "Total"],
        rows: [],
      },
      clicksLineData: {
        columns: ["date", "Group1", "Group2", "Group3", "Group4", "Group5", "Total"],
        rows: [],
      },
      typeList: [
        { name: "Appurse", color: "#59b1ef", checked: false },
        { name: "ConPonPac", color: "#59b1ef", checked: false },
        { name: "HiAppo", color: "#59b1ef", checked: false },
        { name: "Astrologiez", color: "#59b1ef", checked: false },
        { name: "Group1", color: "#59b1ef", checked: true },
        { name: "Popsilla", color: "#a294ff", checked: false },
        { name: "Game Stadia", color: "#a294ff", checked: false },
        { name: "Group2", color: "#a294ff", checked: true },
        { name: "DT - SGD", color: "#1ad4ae", checked: false },
        { name: "LF101 SGD Den (DT 2 SG)", color: "#1ad4ae", checked: false },
        { name: "Housing.LF101 SGD", color: "#1ad4ae", checked: false },
        { name: "Clinic.LF101 SGD", color: "#1ad4ae", checked: false },
        { name: "LF101 HKD", color: "#1ad4ae", checked: false },
        { name: "Group3", color: "#1ad4ae", checked: true },
        { name: "DMV", color: "#f70163", checked: false },
        { name: "Group4", color: "#f70163", checked: true },
        { name: "HGCP - SGD", color: "#e58f34", checked: false },
        { name: "HS HG", color: "#e58f34", checked: false },
        { name: "Group5", color: "#e58f34", checked: true },
        { name: "Total", color: "#e58f34", checked: true },
      ],
      chartExtend: {
        color: ["#59b1ef", "#a294ff", "#1ad4ae", "#f70163", "#e58f34", "#aad639"],
      },
      spinning: false,
    };
  },
  // 定义方法
  methods: {
    onDateChange(dateString) {
      this.dateString = dateString;
    },
    // 修改日期获取数据
    submitDateChange() {
      this.getList();
    },
    // 获取数据列表
    getList() {
      this.loading = true;
      this.$post(api.okr.getAdwTracking, {
        end_date: this.dateString.format("YYYY-MM-DD"),
        token: this.userInfo.token,
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataList = res.data.data.table;
          this.spendLineData.rows = res.data.data.chart.spent;
          this.clicksLineData.rows = res.data.data.chart.sessions;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 切换坐标
    switchChange(e, name, color) {
      if (e) {
        this.typeList.forEach((item) => {
          if (item.name == name) {
            item.checked = true;
            this.chartExtend.color.push(color);
          }
        });
        this.spendLineData.columns.push(name);
        this.clicksLineData.columns.push(name);
      } else {
        this.typeList.forEach((item) => {
          if (item.name == name) {
            item.checked = false;
            this.spendLineData.columns.forEach((a, index) => {
              if (a == name) {
                this.spendLineData.columns.splice(index, 1);
                this.chartExtend.color.splice(index - 1, 1);
              }
            });
            this.clicksLineData.columns.forEach((a, index) => {
              if (a == name) {
                this.clicksLineData.columns.splice(index, 1);
              }
            });
          }
        });
      }
    },
  },

  // 页面初始化
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.getList();
    this.line();
  },
};
</script>

<style>
.data-table tr:last-child td {
  border-top: 2px solid #e1e1e1;
}
.lang_box {
  margin-top: 0;
}
.lang_box_item {
  width: 100%;
  padding-left: 0;
}
.lang_box_item span {
  margin-bottom: 8px;
  margin-left: 10px;
}

.ant-switch-checked.switchcolorGroup1 {
  background-color: #59b1ef;
}
.ant-switch-checked.switchcolorAppurse {
  background-color: #59b1ef;
}
.ant-switch-checked.switchcolorConPonPac {
  background-color: #59b1ef;
}
.ant-switch-checked.switchcolorHiAppo {
  background-color: #59b1ef;
}
.ant-switch-checked.switchcolorAstrologiez {
  background-color: #59b1ef;
}
.ant-switch-checked.switchcolorGroup2 {
  background-color: #a294ff;
}
.ant-switch-checked.switchcolorPopsilla {
  background-color: #a294ff;
}
.ant-switch-checked.switchcolorGame {
  background-color: #a294ff;
}
.ant-switch-checked.switchcolorGroup3 {
  background-color: #1ad4ae;
}
.ant-switch-checked.switchcolorDT {
  background-color: #1ad4ae;
}
.ant-switch-checked.switchcolorLF101 {
  background-color: #1ad4ae;
}
.ant-switch-checked.switchcolorHousing\.LF101 {
  background-color: #1ad4ae;
}
.ant-switch-checked.switchcolorClinic\.LF101 {
  background-color: #1ad4ae;
}
.ant-switch-checked.switchcolorGroup4 {
  background-color: #f70163;
}
.ant-switch-checked.switchcolorDMV {
  background-color: #f70163;
}
.ant-switch-checked.switchcolorGroup5 {
  background-color: #e58f34;
}
.ant-switch-checked.switchcolorHGCP {
  background-color: #e58f34;
}
.ant-switch-checked.switchcolorHS {
  background-color: #e58f34;
}
.ant-switch-checked.switchcolorTotal {
  background-color: #aad639;
}
</style>
