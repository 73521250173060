<template>
  <div class="loginbox">
    <iframe
      src="/GoogleLogin.html"
      frameborder="0"
      :style="{ width: '100%', height: '100%' }"></iframe>
  </div>
</template>
<script>
  import api from '@/common/api.js'

  export default {
    created(){
        window.addEventListener('message', event => {
          if (event.data.token !== '' && event.data.status === true) {
            // console.log(event)
            this.$post(api.user.loginByMail, {
              idtoken: event.data.token
            }).then((res) => {
              // console.log(res)
              if (res.status === 200) {
                  localStorage.userInfo = JSON.stringify(res.data.data)
                  this.$message.success('登录成功');
                  const { href } = this.$router.resolve({
                    path: "/daily-zumexpress",
                  });
                  // window.open(href);
                  window.location.href = href
              }else {
                  this.$message.error('登录失败');
              }
            })
          }
        })
    },

  };
</script>
<style scoped>
  .loginbox{
    width: 100%;
    height: 100vh;
  }
</style>