<template>
  <div>
    <div class="ant-page-header-tl">
      <a-range-picker
        :default-value="dateString"
        style="margin-right: 8px"
        @change="onDateChange"
      />
      <a-button type="primary" @click="getData">Apply</a-button>
      <a-button type="primary" style="margin-left: 10px" @click="selectText">Copy</a-button>
      <span style="margin-left: 10px;float:right;"></span>
      <div style="float:right;font-weight:300;line-height:center">
        <span style="margin-left: 250px"
          >{{ showDate.start_date }} ～ {{ showDate.end_date }}</span
        >
        <span style="margin-left: 24px">Compare to</span>
        <span style="margin-left: 24px"
          >{{ showDate.start_date_before }} ～
          {{ showDate.end_date_before }}</span
        >
      </div>
    </div>
    <div class="section">
      <a-table
        :columns="tableColumn1"
        :data-source="tableList1"
        :rowKey="(record) => record.name"
        :pagination="false"
        size="small"
        bordered
        :loading="loading"
        style="margin-bottom: 24px;max-width:640px"
        :scroll="{ x: 500}"
      >
        <template slot="z_num_wow_num" slot-scope="text, record">
          {{ parseInt(text).toLocaleString() }}<br>
            <div :class="[
                record.z_num_wow_percent > 0 ? 'color-green' : '',
                record.z_num_wow_percent < 0 ? 'color-red' : '',
                record.z_num_wow_percent === 0 ? 'color-grey' : ''
              ]">
              ({{record.z_num_wow_percent}}%)
            </div>
        </template>
        <template slot="z_num_dod_num" slot-scope="text, record">
          {{ parseInt(text).toLocaleString() }}<br>
            <div :class="[
                record.z_num_dod_percent > 0 ? 'color-green' : '',
                record.z_num_dod_percent < 0 ? 'color-red' : '',
                record.z_num_dod_percent === 0 ? 'color-grey' : ''
              ]">
              ({{record.z_num_dod_percent}}%)
            </div>
        </template>
        <template slot="z_num" slot-scope="text">
          {{ parseInt(text).toLocaleString() }}
        </template>
      </a-table>


      <a-table
        :columns="tableColumn2"
        :data-source="tableList2"
        :pagination="false"
        size="small"
        bordered
        :loading="loading"
        style="margin-bottom: 24px;max-width:800px"
      >
       <template slot="z_num" slot-scope="text">
          {{ parseInt(text).toLocaleString() }}
        </template>
        <template slot="z_num_wow_num" slot-scope="text, record">
          {{ parseInt(text).toLocaleString() }}<br>
            <div :class="{
                'color-green': record.z_num_wow_percent > 10,
                'color-red': record.z_num_wow_percent < -10,
                'color-grey': record.z_num_wow_percent >= -10 && record.z_num_wow_percent <= 10
              }">
              ({{record.z_num_wow_percent}}%)
            </div>
        </template>
      </a-table>
    </div>
    <div class="section">
      <a-tabs type="card" default-active-key="relaunch">
        <a-tab-pane key="relaunch" tab="每日宗数" force-render>
          <div>

          <a-table
            :columns="columns1"
            :data-source="dayzumList.cc"
            :rowKey="(record) => record.sessions + record.slug + record.id"
            :pagination="false"
            size="small"
            bordered
            :loading="loading"
            style="margin-bottom: 24px"
            :rowClassName="function(record) {
                if (!record.name.includes('Total')) {
                  let className = 'color-black';
                  return className;
                }else{
                  className ='color-black-blod'
                  return className;
                }
              }
            "
            :expandRowByClick="true"
            :scroll="{ x: 900 }"
          >
            <div
              class="smallbtn"
              slot="action"
              slot-scope="text, record"
              style="padding: 4px 0px"
            >
              <a-button type="link" size="small" @click="goDetail(record)"
                >Check</a-button
              >
            </div>

            <span slot="name" slot-scope="text, record">
              <a-popover v-if="record.tag_color">
                <template slot="content">
                  {{ record.tag_txt }}
                </template>
                {{ text }}
              </a-popover>
              <span v-else>
                {{ text }}
              </span>
            </span>
            <template slot="z_num_wow_num" slot-scope="text, record">
              <div class="hoverbox">
                {{ parseInt(text).toLocaleString() }}<br>
                <div :class="{
                    'color-green': record.tag_color_wow == 'g',
                    'color-red': record.tag_color_wow =='r',
                    'color-grey': record.tag_color_wow ==''
                  }">
                  ({{record.z_num_wow_percent}}%)
                </div>
                <div  v-if="record.tag_txt_wow!=''"  class="hovertip">
                  {{record.tag_txt_wow }}
                </div>
              </div>
          
            </template>
            <template slot="z_num_dod_num" slot-scope="text, record">
              <div class="hoverbox">
                {{ parseInt(text).toLocaleString() }}<br>
                <div :class="{
                'color-green': record.tag_color == 'g',
                'color-red': record.tag_color =='r',
                'color-grey': record.tag_color ==''
              }">
                  ({{record.z_num_dod_percent}}%)
                </div>
                <div  v-if="record.tag_txt!=''"  class="hovertip">
                  {{record.tag_txt }}
                </div>
              </div>
              
            </template>
          </a-table>

          <a-table
            :columns="columns1"
            :data-source="dayzumList.hh"
            :rowKey="(record) => record.sessions + record.slug + record.id"
            :pagination="false"
            size="small"
            bordered
            :loading="loading"
            style="margin-bottom: 24px"
            :rowClassName="function(record) {
                  let className = 'color-black';
                if (!record.name.includes('Total')) {
                }else{
                  className ='color-black-blod'
                  
                }
                return className;
              }
            "
            :expandRowByClick="true"
            :scroll="{ x: 900 }"
          >
            <div
              class="smallbtn"
              slot="action"
              slot-scope="text, record"
              style="padding: 4px 0px"
            >
              <a-button type="link" size="small" @click="goDetail(record)"
                >Check</a-button
              >
            </div>
            <span slot="name" slot-scope="text, record">
              <a-popover v-if="record.tag_color">
                <template slot="content">
                  {{ record.tag_txt }}
                </template>
                {{ text }}
              </a-popover>
              <span v-else>
                {{ text }}
              </span>
            </span>
            <template slot="z_num_wow_num" slot-scope="text, record">
              <div class="hoverbox">
                {{ parseInt(text).toLocaleString() }}<br>
                <div :class="{
                    'color-green': record.tag_color_wow == 'g',
                    'color-red': record.tag_color_wow =='r',
                    'color-grey': record.tag_color_wow ==''
                  }">
                  ({{record.z_num_wow_percent}}%)
                </div>
                <div  v-if="record.tag_txt_wow!=''"  class="hovertip">
                  {{record.tag_txt_wow }}
                </div>
              </div>
          
            </template>
            <template slot="z_num_dod_num" slot-scope="text, record">
              <div class="hoverbox">
                {{ parseInt(text).toLocaleString() }}<br>
                <div :class="{
                'color-green': record.tag_color == 'g',
                'color-red': record.tag_color =='r',
                'color-grey': record.tag_color ==''
              }">
                  ({{record.z_num_dod_percent}}%)
                </div>
                <div  v-if="record.tag_txt!=''"  class="hovertip">
                  {{record.tag_txt }}
                </div>
              </div>
              
            </template>
          </a-table>

          <a-table
            :columns="columns1"
            :data-source="dayzumList.team"
            :rowKey="(record) => record.sessions + record.slug"
            :pagination="false"
            size="small"
            bordered
            :loading="loading"
            :rowClassName="function(record) {
                if (!record.name.includes('Total')) {
                  let className = 'color-black';
                  return className;
                }else{
                  className ='color-black-blod'
                  return className;
                }
              }
            "
            :expandRowByClick="true"
            :scroll="{ x: 900 }"
          >
            <div
              class="smallbtn"
              slot="action"
              slot-scope="text, record"
              style="padding: 4px 0px"
            >
              <a-button type="link" size="small" @click="goDetail(record)"
                >Check</a-button
              >
            </div>

            <span slot="name" slot-scope="text, record">
              <a-popover v-if="record.tag_color">
                <template slot="content">
                  {{ record.tag_txt }}
                </template>
                {{ text }}
              </a-popover>
              <span v-else>
                {{ text }}
              </span>
            </span>
            <template slot="z_num_wow_num" slot-scope="text, record">
              <div class="hoverbox">
                {{ parseInt(text).toLocaleString() }}<br>
                <div :class="{
                    'color-green': record.tag_color_wow == 'g',
                    'color-red': record.tag_color_wow =='r',
                    'color-grey': record.tag_color_wow ==''
                  }">
                  ({{record.z_num_wow_percent}}%)
                </div>
                <div v-if="record.tag_txt_wow!=''" class="hovertip">
                  {{record.tag_txt_wow }}
                </div>
              </div>
          
            </template>
            <template slot="z_num_dod_num" slot-scope="text, record">
              <div class="hoverbox">
                {{ parseInt(text).toLocaleString() }}<br>
                <div :class="{
                'color-green': record.tag_color == 'g',
                'color-red': record.tag_color =='r',
                'color-grey': record.tag_color ==''
              }">
                  ({{record.z_num_dod_percent}}%)
                </div>
                <div v-if="record.tag_txt!=''" class="hovertip">
                  {{record.tag_txt }}
                </div>
              </div>
            </template>
          </a-table>

          </div>
        </a-tab-pane>
        <a-tab-pane key="optimization" tab="季度宗数" style="width: 80%;">
          <a-table
            :columns="columns2"
            :data-source="quarterList.cc"
            :rowKey="(record) => record.sessions + record.slug"
            :pagination="false"
            size="small"
            bordered
            :loading="loading2"
            style="margin-bottom: 24px"
          >
          </a-table>
          <a-table
            :columns="columns2"
            :data-source="quarterList.hh"
            :rowKey="(record) => record.sessions + record.slug"
            :pagination="false"
            size="small"
            bordered
            :loading="loading2"
            style="margin-bottom: 24px"
          >
          </a-table>
          <a-table
            :columns="columns2"
            :data-source="quarterList.team"
            :rowKey="(record) => record.sessions + record.slug"
            :pagination="false"
            size="small"
            bordered
            :loading="loading2"
          >
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>



    <div class="copypart" ref="textToSelect">
      <a-table
        :columns="tableColumn1_copy"
        :data-source="tableList1"
        :rowKey="(record) => record.name"
        :pagination="false"
        size="small"
        bordered
        :loading="loading"
        style="margin-bottom: 24px;max-width:640px"
        :scroll="{ x: 500}"
      >
        <template slot="z_num_wow_num" slot-scope="text, record">
          {{ parseInt(text).toLocaleString() }}<br>
            <div :class="[
                record.z_num_wow_percent > 0 ? 'color-green' : '',
                record.z_num_wow_percent < 0 ? 'color-red' : '',
                record.z_num_wow_percent === 0 ? 'color-grey' : ''
              ]">
              ({{record.z_num_wow_percent}}%)
            </div>
        </template>
        <template slot="z_num_dod_num" slot-scope="text, record">
          {{ parseInt(text).toLocaleString() }}<br>
            <div :class="[
                record.z_num_dod_percent > 0 ? 'color-green' : '',
                record.z_num_dod_percent < 0 ? 'color-red' : '',
                record.z_num_dod_percent === 0 ? 'color-grey' : ''
              ]">
              ({{record.z_num_dod_percent}}%)
            </div>
        </template>
        <template slot="z_num" slot-scope="text">
          {{ parseInt(text).toLocaleString() }}
        </template>
      </a-table>
      <a-table
        :columns="tableColumn2_copy"
        :data-source="tableList2"
        :pagination="false"
        size="small"
        bordered
        :loading="loading"
        style="margin-bottom: 24px;max-width:800px"
      >
       <template slot="z_num" slot-scope="text">
          {{ parseInt(text).toLocaleString() }}
        </template>
        <template slot="z_num_wow_num" slot-scope="text, record">
          {{ parseInt(text).toLocaleString() }}<br>
            <div :class="{
                'color-green': record.z_num_wow_percent > 10,
                'color-red': record.z_num_wow_percent < -10,
                'color-grey': record.z_num_wow_percent >= -10 && record.z_num_wow_percent <= 10
              }">
              ({{record.z_num_wow_percent}}%)
            </div>
        </template>
      </a-table>
      <a-table
            :columns="columns1_copy"
            :data-source="dayzumList.cc"
            :rowKey="(record) => record.sessions + record.slug + record.id"
            :pagination="false"
            size="small"
            bordered
            :loading="loading"
            style="margin-bottom: 24px"
            :rowClassName="function(record) {
                if (!record.name.includes('Total')) {
                  let className = 'color-black';
                  return className;
                }else{
                  className ='color-black-blod'
                  return className;
                }
              }
            "
            :expandRowByClick="true"
            :scroll="{ x: 900 }"
          >
            <div
              class="smallbtn"
              slot="action"
              slot-scope="text, record"
              style="padding: 4px 0px"
            >
              <a-button type="link" size="small" @click="goDetail(record)"
                >Check</a-button
              >
            </div>

            <span slot="name" slot-scope="text, record">
              <a-popover v-if="record.tag_color">
                <template slot="content">
                  {{ record.tag_txt }}
                </template>
                {{ text }}
              </a-popover>
              <span v-else>
                {{ text }}
              </span>
            </span>
            <template slot="z_num_wow_num" slot-scope="text, record">
              <div class="hoverbox">
                {{ parseInt(text).toLocaleString() }}<br>
                <div :class="{
                    'color-green': record.tag_color_wow == 'g',
                    'color-red': record.tag_color_wow =='r',
                    'color-grey': record.tag_color_wow ==''
                  }">
                  ({{record.z_num_wow_percent}}%)
                </div>
                <div  v-if="record.tag_txt_wow!=''"  class="hovertip">
                  {{record.tag_txt_wow }}
                </div>
              </div>
          
            </template>
            <template slot="z_num_dod_num" slot-scope="text, record">
              <div class="hoverbox">
                {{ parseInt(text).toLocaleString() }}<br>
                <div :class="{
                'color-green': record.tag_color == 'g',
                'color-red': record.tag_color =='r',
                'color-grey': record.tag_color ==''
              }">
                  ({{record.z_num_dod_percent}}%)
                </div>
                <div  v-if="record.tag_txt!=''"  class="hovertip">
                  {{record.tag_txt }}
                </div>
              </div>
              
            </template>
      </a-table>
      <a-table
        :columns="columns1_copy"
        :data-source="dayzumList.hh"
        :rowKey="(record) => record.sessions + record.slug + record.id"
        :pagination="false"
        size="small"
        bordered
        :loading="loading"
        style="margin-bottom: 24px"
        :rowClassName="function(record) {
              let className = 'color-black';
            if (!record.name.includes('Total')) {
            }else{
              className ='color-black-blod'
              
            }
            return className;
          }
        "
        :expandRowByClick="true"
        :scroll="{ x: 900 }"
      >
        <div
          class="smallbtn"
          slot="action"
          slot-scope="text, record"
          style="padding: 4px 0px"
        >
          <a-button type="link" size="small" @click="goDetail(record)"
            >Check</a-button
          >
        </div>
        <span slot="name" slot-scope="text, record">
          <a-popover v-if="record.tag_color">
            <template slot="content">
              {{ record.tag_txt }}
            </template>
            {{ text }}
          </a-popover>
          <span v-else>
            {{ text }}
          </span>
        </span>
        <template slot="z_num_wow_num" slot-scope="text, record">
          <div class="hoverbox">
            {{ parseInt(text).toLocaleString() }}<br>
            <div :class="{
                'color-green': record.tag_color_wow == 'g',
                'color-red': record.tag_color_wow =='r',
                'color-grey': record.tag_color_wow ==''
              }">
              ({{record.z_num_wow_percent}}%)
            </div>
            <div  v-if="record.tag_txt_wow!=''"  class="hovertip">
              {{record.tag_txt_wow }}
            </div>
          </div>
      
        </template>
        <template slot="z_num_dod_num" slot-scope="text, record">
          <div class="hoverbox">
            {{ parseInt(text).toLocaleString() }}<br>
            <div :class="{
            'color-green': record.tag_color == 'g',
            'color-red': record.tag_color =='r',
            'color-grey': record.tag_color ==''
          }">
              ({{record.z_num_dod_percent}}%)
            </div>
            <div  v-if="record.tag_txt!=''"  class="hovertip">
              {{record.tag_txt }}
            </div>
          </div>
          
        </template>
      </a-table>
      <a-table
        :columns="columns1_copy"
        :data-source="dayzumList.team"
        :rowKey="(record) => record.sessions + record.slug"
        :pagination="false"
        size="small"
        bordered
        :loading="loading"
        :rowClassName="function(record) {
            if (!record.name.includes('Total')) {
              let className = 'color-black';
              return className;
            }else{
              className ='color-black-blod'
              return className;
            }
          }
        "
        :expandRowByClick="true"
        :scroll="{ x: 900 }"
      >
        <div
          class="smallbtn"
          slot="action"
          slot-scope="text, record"
          style="padding: 4px 0px"
        >
          <a-button type="link" size="small" @click="goDetail(record)"
            >Check</a-button
          >
        </div>

        <span slot="name" slot-scope="text, record">
          <a-popover v-if="record.tag_color">
            <template slot="content">
              {{ record.tag_txt }}
            </template>
            {{ text }}
          </a-popover>
          <span v-else>
            {{ text }}
          </span>
        </span>
        <template slot="z_num_wow_num" slot-scope="text, record">
          <div class="hoverbox">
            {{ parseInt(text).toLocaleString() }}<br>
            <div :class="{
                'color-green': record.tag_color_wow == 'g',
                'color-red': record.tag_color_wow =='r',
                'color-grey': record.tag_color_wow ==''
              }">
              ({{record.z_num_wow_percent}}%)
            </div>
            <div v-if="record.tag_txt_wow!=''" class="hovertip">
              {{record.tag_txt_wow }}
            </div>
          </div>
      
        </template>
        <template slot="z_num_dod_num" slot-scope="text, record">
          <div class="hoverbox">
            {{ parseInt(text).toLocaleString() }}<br>
            <div :class="{
            'color-green': record.tag_color == 'g',
            'color-red': record.tag_color =='r',
            'color-grey': record.tag_color ==''
          }">
              ({{record.z_num_dod_percent}}%)
            </div>
            <div v-if="record.tag_txt!=''" class="hovertip">
              {{record.tag_txt }}
            </div>
          </div>
        </template>
      </a-table>
    </div>
  </div>
  
</template>

<script>
import api from "@/common/api.js";
import { message } from 'ant-design-vue';
import moment from "moment";
const defaultStartDate = moment()
  .startOf("day")
  .subtract(2, "days");
const defaultEndDate = moment()
  .startOf("day")
  .subtract(2, "days");

const columns1 = [
  {
    title: "产品",
    dataIndex: "name",
    align: "left",
    width: 120,
    scopedSlots: { customRender: "name" },
  },
  {
    title: "平台",
    dataIndex: "medium",
    align: "left",
    width: 90,
  },
  {
    title: "Session",
    dataIndex: "sessions",
    align: "right",
    width: 90,
    sorter: (a, b) => {
      if (!a.name.includes("Total")) {
        return a.sessions - b.sessions;
      }
    },
    customRender: (text) => {
      return text && text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "C/S",
    dataIndex: "c_s",
    align: "right",
    customRender: (text) => {
      return text + "%";
    },
    width: 100,
    sorter: (a, b) => {
      if (!a.name.includes("Total")) {
        return a.c_s - b.c_s;
      }
    },
  },
  {
    title: "PV",
    dataIndex: "page_views",
    align: "right",
    width: 90,
    customRender: (text) => {
      return text && text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (!a.name.includes("Total")) {
        return a.page_views - b.page_views;
      }
    },
  },
  {
    title: "花费",
    dataIndex: "spent",
    align: "right",
    width: 90,
    customRender: (text) => {
      return text && text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (!a.name.includes("Total")) {
        return a.spent - b.spent;
      }
    },
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    width: 110,
    customRender: (text) => {
      return text && text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (!a.name.includes("Total")) {
        return a.z_num - b.z_num;
      }
    },
  },
  {
    title: "宗数 WoW",
    dataIndex: "z_num_wow_num",
    align: "right",
    width: 100,
    scopedSlots: { customRender: "z_num_wow_num" },
    sorter: (a, b) => {
      if (!a.name.includes("Total")) {
        return a.z_num_wow_num - b.z_num_wow_num;
      }
    },
  },
  {
    title: "宗数 DoD",
    dataIndex: "z_num_dod_num",
    align: "right",
    width: 100,
    scopedSlots: { customRender: "z_num_dod_num" },
    sorter: (a, b) => {
      if (!a.name.includes("Total")) {
        return a.z_num_dod_num - b.z_num_dod_num;
      }
    },
  },
  {
    title: "宗数/花费",
    dataIndex: "z_s",
    align: "right",
    customRender: (text) => {
      return text + "%";
    },
    width: 100,
    sorter: (a, b) => {
      if (!a.name.includes("Total")) {
        return a.z_s - b.z_s;
      }
    },
  },
  {
    title: "上限",
    dataIndex: "cpc_top",
    align: "right",
    width: 100,
    sorter: (a, b) => {
      if (!a.name.includes("Total")) {
        return a.cpc_top - b.cpc_top;
      }
    },
    customRender: (text) => {
      return text && text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "乘数",
    dataIndex: "multiplier",
    align: "right",
    customRender: (text) => {
      return text.toString();
    },
    width: 100,
    sorter: (a, b) => {
      if (!a.name.includes("Total")) {
        return a.multiplier - b.multiplier;
      }
    },
  },
  {
    title: "By Day",
    scopedSlots: { customRender: "action" },
    align: "center",
    width: 100,
  },
];

const columns2 = [
  {
    title: "产品",
    dataIndex: "name",
    align: "left",
    width: 100,
  },
  {
    title: "平台",
    dataIndex: "medium",
    align: "left",
    width: 100,
  },
  {
    title: "Session",
    dataIndex: "sessions",
    align: "right",
    width: 100,
    customRender: (text) => {
      return text && text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (!a.name.includes("Total")) {
        return a.sessions - b.sessions;
      }
    },
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    width: 100,
    customRender: (text) => {
      return text && text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (!a.name.includes("Total")) {
        return a.z_num - b.z_num;
      }
    },
  },
  {
    title: "宗数/花费",
    dataIndex: "z_s",
    align: "right",
    customRender: (text) => {
      return text + "%";
    },
    width: 100,
    sorter: (a, b) => {
      if (!a.name.includes("Total")) {
        return a.z_s - b.z_s;
      }
    },
  },
];

const tableColumn1 = [
  {
    title: "产品",
    dataIndex: "name",
    align: "left",
    width: 100,
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    width: 100,
    scopedSlots: { customRender: 'z_num' },
    sorter: (a, b) => {
      return a.z_num - b.z_num;
    },
  },
  {
    title: "宗数 WoW",
    dataIndex: "z_num_wow_num",
    align: "right",
    width: 100,
    scopedSlots: { customRender: "z_num_wow_num" },
    sorter: (a, b) => {
      return a.z_num_wow_num - b.z_num_wow_num;
    },
  },
  {
    title: "宗数 DoD",
    dataIndex: "z_num_dod_num",
    align: "right",
    width: 100,
    scopedSlots: { customRender: "z_num_dod_num" },
    sorter: (a, b) => {
      return a.z_num_dod_num - b.z_num_dod_num;
    },
  },
];

const tableColumn2 = [
  {
    title: "",
    dataIndex: "type",
    align: "left",
    width: 100,
    customRender: null
  },
  {
    title: "产品",
    dataIndex: "name",
    align: "left",
    width: 100,
    scopedSlots: { customRender: "name" },
  },
  {
    title: "平台",
    dataIndex: "medium",
    align: "left",
    width: 100,
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    width: 100,
    scopedSlots: { customRender: 'z_num' },
    sorter: (a, b) => {
        return a.z_num - b.z_num;
    },
  },
  {
    title: "宗数 WoW",
    dataIndex: "z_num_wow_num",
    align: "right",
    width: 100,
    scopedSlots: { customRender: "z_num_wow_num" },
    sorter: (a, b) => {
        return a.z_num_wow_num - b.z_num_wow_num;
    },
  },
];
const columns1_copy = [
  {
    title: "产品",
    dataIndex: "name",
    align: "left",
    width: 120,
    scopedSlots: { customRender: "name" },
  },
  {
    title: "平台",
    dataIndex: "medium",
    align: "left",
    width: 90,
  },
  {
    title: "Session",
    dataIndex: "sessions",
    align: "right",
    width: 90,
    customRender: (text) => {
      return text && text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "C/S",
    dataIndex: "c_s",
    align: "right",
    customRender: (text) => {
      return text + "%";
    },
    width: 100,
  },
  {
    title: "PV",
    dataIndex: "page_views",
    align: "right",
    width: 90,
    customRender: (text) => {
      return text && text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "花费",
    dataIndex: "spent",
    align: "right",
    width: 90,
    customRender: (text) => {
      return text && text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    width: 110,
    customRender: (text) => {
      return text && text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "宗数 WoW",
    dataIndex: "z_num_wow_num",
    align: "right",
    width: 100,
    scopedSlots: { customRender: "z_num_wow_num" },
  },
  {
    title: "宗数 DoD",
    dataIndex: "z_num_dod_num",
    align: "right",
    width: 100,
    scopedSlots: { customRender: "z_num_dod_num" },
  },
  {
    title: "宗数/花费",
    dataIndex: "z_s",
    align: "right",
    customRender: (text) => {
      return text + "%";
    },
    width: 100,
  },
  {
    title: "上限",
    dataIndex: "cpc_top",
    align: "right",
    width: 100,
    customRender: (text) => {
      return text && text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "乘数",
    dataIndex: "multiplier",
    align: "right",
    customRender: (text) => {
      return text.toString();
    },
    width: 100,
  },
  {
    title: "By Day",
    scopedSlots: { customRender: "action" },
    align: "center",
    width: 100,
  },
];
const tableColumn1_copy = [
  {
    title: "产品",
    dataIndex: "name",
    align: "left",
    width: 100,
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    width: 100,
    scopedSlots: { customRender: 'z_num' },
  },
  {
    title: "宗数 WoW",
    dataIndex: "z_num_wow_num",
    align: "right",
    width: 100,
    scopedSlots: { customRender: "z_num_wow_num" },
  },
  {
    title: "宗数 DoD",
    dataIndex: "z_num_dod_num",
    align: "right",
    width: 100,
    scopedSlots: { customRender: "z_num_dod_num" },
  },
];
const tableColumn2_copy = [
  {
    title: "",
    dataIndex: "type",
    align: "left",
    width: 100,
    customRender: null
  },
  {
    title: "产品",
    dataIndex: "name",
    align: "left",
    width: 100,
    scopedSlots: { customRender: "name" },
  },
  {
    title: "平台",
    dataIndex: "medium",
    align: "left",
    width: 100,
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    width: 100,
    scopedSlots: { customRender: 'z_num' },
  },
  {
    title: "宗数 WoW",
    dataIndex: "z_num_wow_num",
    align: "right",
    width: 100,
    scopedSlots: { customRender: "z_num_wow_num" },
  },
];
export default {
  data() {
    return {
      columns1_copy,
      tableColumn1_copy,
      tableColumn2_copy,
      moment,
      userInfo: {},
      columns1,
      columns2,
      tableColumn1,
      tableColumn2,
      adw_update_time: "",
      loading: false,
      loading2: false,
      loading3: false,
      okrList_2: [],
      dayzumList: {},
      quarterList: [],
      tableList1: [],
      tableList2: [],
      defaultStartDate,
      defaultEndDate,
      dateString: [
        defaultStartDate.format("YYYY-MM-DD"),
        defaultEndDate.format("YYYY-MM-DD"),
      ],
      showDate: {},
      rowSpanMap: {},
    };
  },
  methods: {
    goDetail(record) {
      let slug = record.slug;
      if (record.name == "Total HH" || record.name == "Total Team") {
        slug = record.slug + "_new";
      }
      const { href } = this.$router.resolve({
        path: "/zumexpress-detail01",
        query: {
          slug: slug,
          name: record.name,
        },
      });
      window.open(href, "_blank");
    },
    getDailyPerf() {
      this.loading = true;
      this.$post(api.ga4.getAllSitesZumDataNew01, {
        token: this.userInfo.token,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.showDate = res.data.data.date;
          this.dayzumList = res.data.data.list;
          this.tableList1 = res.data.data.table1;
          this.tableList2 = res.data.data.table2;
          this.adw_update_time = res.data.data.adw_update_time;
          let up=0;
          let down=0
          for(let i=0;i<this.tableList2.length;i++){
            if(this.tableList2[i].type=='增长'){
              console.log(this.tableList2[i].type);
              up++;
            }else{
              console.log(this.tableList2[i].type);
              down++;
            }
          }
          console.log(up);
          console.log(down)
          this.tableColumn2[0].customRender = (text, record,index) => {
              let item='';
              if(index.toString()=='0'){
                item= {
                children: text,
                attrs: {
                    rowSpan: up, // 在这里设置为1，因为我们合并整列，所以每个单元格的colSpan都是1
                  }
                };
              }else if(parseInt(index)==up){
                item= {
                children: text,
                attrs: {
                    rowSpan: down, // 在这里设置为1，因为我们合并整列，所以每个单元格的colSpan都是1
                  }
                };
              }else{
                item= {
                children: text,
                attrs: {
                    rowSpan: 0, // 在这里设置为1，因为我们合并整列，所以每个单元格的colSpan都是1
                  },
                };
              }
            return item;
          };
          this.tableColumn2_copy[0]=this.tableColumn2[0]
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    getQuarterPerf() {
      this.loading2 = true;
      this.$post(api.ga4.getQuarterPerfNew01, {
        token: this.userInfo.token,
      }).then((res) => {
        this.loading2 = false;
        if (res.data.status === 200) {
          this.quarterList = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    onDateChange(date, dateString) {
      this.dateString = dateString;
    },
    getData() {
      this.getDailyPerf();
    },
    selectText() {
      const text = this.$refs.textToSelect; // 通过 ref 属性获取 DOM 节点
      if (document.createRange && window.getSelection) {
        const range = document.createRange(); // 创建一个 Range 对象
        range.selectNodeContents(text); // 选择元素内容
        const selection = window.getSelection(); // 获取 Selection 对象
        selection.removeAllRanges(); // 清除当前的选择
        selection.addRange(range); // 将 Range 添加到 Selection 中
        document.execCommand('copy');
        message.success('复制成功');
      } else if (document.body.createTextRange) { // 针对 IE 的较旧方法
        const textRange = document.body.createTextRange();
        textRange.moveToElementText(text);
        textRange.select();
        document.execCommand('copy');
        message.success('复制成功');
      }
    }
  },
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.getDailyPerf();
    this.getQuarterPerf();
    this.selectText();
  },
};
</script>

<style>
.copypart {
  position: absolute;
  top: -99999999px;
}
.total-item {
  margin-right: 10px;
  margin-left: 10px;
  color: #ddd;
}
  .ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr.color-black
  > td {
  color: #000;
}
.color-black-blod {
  color: #000;
  font-weight: 700;
}
  .ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr.color-green
  > td {
  color: #0b8043;
  font-weight: bold;
}
.color-green {
  color: #0b8043;
  font-weight: bold;
}
  .ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr.color-red
  > td {
  color: #c53929;
  font-weight: bold;
}
.color-red {
  color: #c53929;
  font-weight: bold;

}
.color-grey {
  color: #999;
  font-weight: bold;

}
  .ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td {
  padding: 8px;
  color: #000;
}

/* .ant-table-small
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td {
  line-height: 32px;
} */

  .ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-tbody
  /* > tr:last-child */
  > td,

  .ant-table-small
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-tbody
  /* > tr:last-child */
  > td {
  font-weight: bold;
}
.hoverbox {
  position: relative;
}
.hoverbox:hover .hovertip{
  display: block;
}
.hovertip {
  display: none;
    position: absolute;
    padding: 2px 6px;
    background: #fff;
    box-shadow: 0px 2px 10px #d4d4d4;
    top: -26px;
    border-radius: 4px;
    text-wrap: nowrap;
    font-weight: 400;

}
</style>
