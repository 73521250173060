<template>
  <div>
      <div class="ant-page-header-tl">
        <a-button type="primary" style="margin-left:16px" @click="unBlock"
          >一键释放</a-button
        >
        <a-button type="primary" style="float:right" @click="getAllList"
          >提交</a-button
        >
        <a-select
          v-if="platform == 'Outbrain'"
          defaultValue="选择账户"
          style="width: 300px;float:right;margin-right: 16px"
          @change="handleMarketerChange"
        >
          <a-select-option
            v-for="(item, index) in marketer_list"
            :key="index"
            :value="item"
            >{{ item }}</a-select-option
          >
        </a-select>
        <a-select
          v-else
          defaultValue="选择账户"
          style="width: 300px;float:right;margin-right: 16px"
          @change="handleMarketerChange"
        >
          <a-select-option
            v-for="(item, index) in taboolaMarketer_list"
            :key="index"
            :value="item"
            >{{ item }}</a-select-option
          >
        </a-select>
        <a-select
          defaultValue="选择平台"
          style="width: 150px;float:right;margin-right: 16px"
          @change="handlePlatformChange"
        >
          <a-select-option
            v-for="(item, index) in platform_list"
            :key="index"
            :value="item"
            >{{ item }}</a-select-option
          >
        </a-select>
        <a-range-picker :default-value="dateString" @change="onDateChange" style="float:right;margin-right: 16px"/>
        
      </div>
      <div class="section">
        <div class="filter">
          <a-button
            @click="closeRule(item)"
            style="margin-right: 16px;"
            type="primary"
            v-for="(item, index) in ruleList"
            :key="index"
          >
            {{ item.blockName }} {{ item.contains }} {{ item.rule
            }}<a-icon type="close" />
          </a-button>
          <a-button
            style="margin-right: 16px;"
            v-if="!ruleVisible"
            @click="showRuleModal"
            type="primary"
          >
            <a-icon type="plus" />
            Filter
          </a-button>
          <a-button
              @click="deletRuleColumn()"
              v-if="!ruleVisible && ruleList.length > 0"
              >删除所有条件</a-button>
          <div
            v-if="ruleVisible"
            style="display: flex; flex:1; margin-top: 16px"
          >
            <a-select
              :value="blockName"
              style="min-width: 160px;"
              @change="handleRuleChange"
            >
              <a-select-option
                v-for="(item, index) in ['publisher','section']"
                :key="index"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
            <a-select
              :defaultValue="contains"
              style="min-width: 160px;margin: 0 16px;"
              @change="handleFilterChange"
            >
              <a-select-option
                v-for="(item, index) in ['contains','not contains']"
                :key="index"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
            <a-input
              style="min-width: 200px;margin-right: 16px;"
              v-model="rule"
              placeholder=""
            />
            <a-button
              @click="changeRuleColumn(blockName, contains, rule)"
              type="primary"
              >确定</a-button
            >
          </div>
        </div>
        <a-tabs :default-active-key="tab" @change="tabChange">
          <a-tab-pane key="1" tab="publisher-site unblock" v-if="platform == 'Outbrain'">
              <b>已勾选 {{selectedRowKeys1.length}} 项</b>
              <a-table
                :columns="columns"
                :data-source="dataList"
                :rowKey="(record) => record.section_id"
                :pagination="{ pageSize: pageSize }"
                size="middle"
                bordered
                :loading="loading"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys1,
                  onChange: onSelectChange,
                }"
              >
              </a-table>
          </a-tab-pane>
          <a-tab-pane key="2" tab="publisher unblock" force-render v-if="platform == 'Outbrain'">
              <b>已勾选 {{selectedRowKeys2.length}} 项</b>
              <a-table
                :columns="columns2"
                :data-source="PublisherList"
                :rowKey="(record) => record.pub_id"
                :pagination="{ pageSize: pageSize }"
                size="middle"
                bordered
                :loading="loading"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys2,
                  onChange: onSelectChange,
                }"
              >
              <a
                    slot="url"
                    slot-scope="text,record"
                    :href="record.url"
                    target="_blank"
                >{{text}}</a>
              </a-table>
          </a-tab-pane>
          <a-tab-pane key="3" tab="site unblock" force-render v-if="platform == 'Outbrain'">
              <b>已勾选 {{selectedRowKeys3.length}} 项</b>
              <a-table
                v-if="obDataLoaded"
                :columns="columns3"
                :data-source="siteList"
                :rowKey="(record) => record.section_id"
                :pagination="{ pageSize: pageSize }"
                size="middle"
                bordered
                :loading="loading"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys3,
                  onChange: onSelectChange,
                }"
                :scroll="{ x: true }"
              >             
              <div slot="Section_id" slot-scope="text,record">
                <span v-if="!isInBlackList(record.section_id)">
                  {{ text }}
                </span>
                <span v-else style="color: red;">
                  {{ text }}
                </span>
              </div>
                <a
                    slot="url"
                    slot-scope="text,record"
                    :href="record.url"
                    target="_blank"
                >{{text}}</a>
                <a
                    slot="publisher_url"
                    slot-scope="text,record"
                    :href="record.publisher_url"
                    target="_blank"
                >{{text}}</a>
              </a-table>
          </a-tab-pane>
          <a-tab-pane key="5" tab="site unblock" force-render v-if="platform == 'Taboola'">
              <b>已勾选 {{selectedRowKeys4.length}} 项</b>
              <a-table
                v-if="obDataLoaded"
                :columns="columns4"
                :data-source="siteList"
                :rowKey="(record) => record.site || record.site_id"
                :pagination="{ pageSize: pageSize }"
                size="middle"
                bordered
                :loading="loading"
                :row-selection="{
                  selectedRowKeys: selectedRowKeys4,
                  onChange: onSelectChange,
                }"
                :scroll="{ x: true }"
              >
              <div slot="Site" slot-scope="text,record">
                <span v-if="!isInBlackList(record.site)">
                  {{ text }}
                </span>
                <span v-else style="color: red;">
                  {{ text }}
                </span>
              </div>
                <a
                    slot="url"
                    slot-scope="text,record"
                    :href="record.url"
                    target="_blank"
                >{{text}}</a>
                <a
                    slot="publisher_url"
                    slot-scope="text,record"
                    :href="record.publisher_url"
                    target="_blank"
                >{{text}}</a>
              </a-table>

          </a-tab-pane>
          <a-tab-pane key="6" tab="黑名单" force-render v-if="platform == 'Taboola'">
            <div style="margin-bottom: 24px">
              <a-textarea
                  placeholder="请输入 ID, 添加多条 ID 请用回车隔开"
                  v-model="content"
                  style="flex: 1"
                  :auto-size="{ minRows: 5 }"
              />
              <a-select
                :value="black"
                style="min-width: 160px;margin: 16px 16px 0 0"
                @change="blackChange"
              >
                <a-select-option
                  v-for="(item, index) in ['publisher','site']"
                  :key="index"
                  :value="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
              <a-button
                @click="submitBlackID(content,black)"
                type="primary"
              >
                添加到黑名单
              </a-button>
            </div>
            <a-row>
                <!-- <a-col :span="12">
                    <a-table
                      :columns="publisherBlackColumns"
                      :data-source="publisherBlackList"
                      :rowKey="(record) => record.blacklist_id"
                      :pagination="false"
                      size="middle"
                      bordered
                      :loading="loading"
                      style="margin-right: 8px;"
                    >
                    </a-table>
                </a-col> -->
                <a-col :span="12">
                    <a-table
                      :columns="siteBlackColumnsTaboola"
                      :data-source="siteBlackList"
                      :rowKey="(record) => record.blacklist_id"
                      :pagination="false"
                      size="middle"
                      bordered
                      :loading="loading"
                      style="margin-left: 8px;"
                    >
                    </a-table>
                </a-col>
            </a-row>

          </a-tab-pane>
          <a-tab-pane key="4" tab="黑名单" force-render v-if="platform == 'Outbrain'">
            <div style="margin-bottom: 24px">
              <a-textarea
                  placeholder="请输入 ID, 添加多条 ID 请用回车隔开"
                  v-model="content"
                  style="flex: 1"
                  :auto-size="{ minRows: 5 }"
              />
              <a-select
                :value="black"
                style="min-width: 160px;margin: 16px 16px 0 0"
                @change="blackChange"
              >
                <a-select-option
                  v-for="(item, index) in ['publisher','site']"
                  :key="index"
                  :value="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
              <a-button
                @click="submitBlackID(content,black)"
                type="primary"
              >
                添加到黑名单
              </a-button>
            </div>
            <a-row>
                <a-col :span="12">
                    <a-table
                      :columns="publisherBlackColumns"
                      :data-source="publisherBlackList"
                      :rowKey="(record) => record.blacklist_id"
                      :pagination="false"
                      size="middle"
                      bordered
                      :loading="loading"
                      style="margin-right: 8px;"
                    >
                    </a-table>
                </a-col>
                <a-col :span="12">
                    <a-table
                      :columns="siteBlackColumns"
                      :data-source="siteBlackList"
                      :rowKey="(record) => record.blacklist_id"
                      :pagination="false"
                      size="middle"
                      bordered
                      :loading="loading"
                      style="margin-left: 8px;"
                    >
                    </a-table>
                </a-col>
            </a-row>

          </a-tab-pane>
        </a-tabs>
        <a-modal
          title="Title"
          :visible="visible"
          @ok="handleOk"
        >
          <p>ID为 {{BlackId}} 的项已被列入黑名单！</p>
        </a-modal>
      </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from 'moment';
const defaultStartDate = moment().startOf('day').subtract(9, 'days');
const defaultEndDate = moment().startOf('day').subtract(2, 'days');

const columns = [
  {
    title: "Section ID",
    dataIndex: "section_id",
    sorter: (a, b) => {
      return a.section_id.localeCompare(b.section_id);
    },
  },
  {
    title: "Section Name",
    dataIndex: "section_name",
    sorter: (a, b) => {
      return a.section_name.localeCompare(b.section_name);
    },
  },
  {
    title: "Publisher ID",
    dataIndex: "pub_id",
    sorter: (a, b) => {
      return a.pub_id.localeCompare(b.pub_id);
    },
  },
  {
    title: "Publisher Name",
    dataIndex: "pub_name",
    sorter: (a, b) => {
      return a.pub_name.localeCompare(b.pub_name);
    },
  },
  {
    title: "Block Time",
    dataIndex: "create_time",
    sorter: (a, b) => {
      return a.create_time.localeCompare(b.create_time);
    },
  },
];
const columns2 = [
  {
    title: "Publisher ID",
    dataIndex: "pub_id",
    sorter: (a, b) => {
      return a.pub_id.localeCompare(b.pub_id);
    },
  },
  {
    title: "Publisher Name",
    dataIndex: "pub_name",
    sorter: (a, b) => {
      return a.pub_name.localeCompare(b.pub_name);
    },
  },
  {
    title: "Block Time",
    dataIndex: "create_time",
    sorter: (a, b) => {
      return a.create_time.localeCompare(b.create_time);
    },
  },
  {
    title: "Clicks",
    dataIndex: "clicks",
    sorter: (a, b) => {
      return a.clicks - b.clicks;
    },
  },
  {
    title: "Url",
    dataIndex: "url",
    scopedSlots: { customRender: "url" },
  },
];
const columns3 = [
  {
    title: "Section ID",
    dataIndex: "section_id",
    scopedSlots: { customRender: "Section_id" },
    sorter: (a, b) => {
      return a.section_id.localeCompare(b.section_id);
    },
  },
  {
    title: "Section Name",
    dataIndex: "section_name",
    sorter: (a, b) => {
      return a.section_name.localeCompare(b.section_name);
    },
  },
  {
    title: "Publisher ID",
    dataIndex: "pub_id",
    sorter: (a, b) => {
      return a.pub_id.localeCompare(b.pub_id);
    },
  },
  {
    title: "Publisher Name",
    dataIndex: "pub_name",
    sorter: (a, b) => {
      return a.pub_name.localeCompare(b.pub_name);
    },
  },
  {
    title: "Block Time",
    dataIndex: "create_time",
    sorter: (a, b) => {
      return a.create_time.localeCompare(b.create_time);
    },
  },
  {
    title: "Clicks",
    dataIndex: "clicks",
    sorter: (a, b) => {
      return a.clicks - b.clicks;
    },
  },
  {
    title: "Url",
    dataIndex: "url",
    scopedSlots: { customRender: "url" },
    // width: 400
  },
  {
    title: "publisher URL",
    dataIndex: "publisher_url",
    scopedSlots: { customRender: "publisher_url" },
    // width: 400
  },
];
const columns4 = [
  {
    title: "Site",
    dataIndex: "site",
    scopedSlots: { customRender: "Site" },
    sorter: (a, b) => {
      return a.site.localeCompare(b.site);
    },
  },
  {
    title: "Site Id",
    dataIndex: "site_id",
    sorter: (a, b) => {
      return a.site_id.localeCompare(b.site_id);
    },
  },
  {
    title: "Site Name",
    dataIndex: "site_name",
    sorter: (a, b) => {
      return a.site_name.localeCompare(b.site_name);
    },
  },
  {
    title: "Block Time",
    dataIndex: "block_time",
    sorter: (a, b) => {
      return a.block_time.localeCompare(b.block_time);
    },
  },
  {
    title: "Blocking Level",
    dataIndex: "blocking_level",
    sorter: (a, b) => {
      return a.blocking_level.localeCompare(b.blocking_level);
    },
  },
  {
    title: "Clicks",
    dataIndex: "clicks",
    sorter: (a, b) => {
      return a.clicks - b.clicks;
    },
  },
];
const publisherBlackColumns = [
    {
      title: "Publisher ID",
      dataIndex: "blacklist_id",
    },
];
const siteBlackColumns = [
    {
      title: "Section ID",
      dataIndex: "blacklist_id",
    },
];
const siteBlackColumnsTaboola = [
    {
      title: "Site ID",
      dataIndex: "blacklist_id",
    },
    {
      title: "Site Name",
      dataIndex: "blacklist_site",
    },
];



export default {
  data() {
    return {
      dateString: [defaultStartDate.format('YYYY-MM-DD'),defaultEndDate.format('YYYY-MM-DD')],
      pageSize: 100,
      userInfo: {},
      columns,
      columns2,
      columns3,
      columns4,
      dataList: [],
      loading: false,
      marketer_list: [],
      marketer: "",
      selectedRowKeys1: [],
      selectedRowKeys2: [],
      selectedRowKeys3: [],
      selectedRowKeys4: [],
      PublisherList: [],
      PublisherList2: [],
      siteList2: [],
      tab: '1',
      siteList: [],
      ruleVisible: false,
      blockName: 'publisher',
      contains: 'contains',
      rule: '',
      ruleList: [],
      publisherBlackColumns,
      siteBlackColumns,
      siteBlackColumnsTaboola,
      publisherBlackList: [],
      siteBlackList: [],
      black: 'publisher',
      content: '',
      visible: false,
      BlackId: '',
      platform: 'Outbrain',
      platform_list: ['Outbrain','Taboola'],
      taboolaMarketer_list:[],
      obBlackListId: [],
      tbBlackListId: [],
      obDataLoaded: false,
    };
  },
  // 定义方法
  methods: {
    isInBlackList(sectionId) {
      // console.log("执行isInBlackList");
      if(this.platform == 'Taboola'){
        return this.tbBlackListId.includes(sectionId);
      }
      if (this.platform == 'Outbrain') {
        return this.obBlackListId.includes(sectionId);
      }
    },
    onSelectChange(selectedRowKeys) {
      // console.log('selectedRows:', selectedRows)
      // console.log("selectedRowKeys", selectedRowKeys);
      // console.log(this.tab)
      // console.log("siteBlackList", this.siteBlackList);
      if(this.platform == 'Taboola'){
        this.selectedRowKeys4 = selectedRowKeys;
        this.siteBlackList.forEach((item)=>{
          const lastKey = selectedRowKeys[selectedRowKeys.length - 1];
          // console.log(lastKey)
          //如果lastKey是空字符串或者undefined 则不执行
          if (lastKey === '' || lastKey === undefined) {
            // console.log("return")
            return;
          }
          //如果lastKey是由数字组成的
          if(/^\d+$/.test(lastKey) && item.blacklist_id.trim() == lastKey){
            this.BlackId = item.blacklist_id.trim()
            this.visible = true
            setTimeout(() => {
              this.visible = false;
            }, 5000);
            // console.log(this.BlackId)
          }
           else{
            if (item.blacklist_site == lastKey){
            this.visible = true
            this.BlackId = lastKey
            setTimeout(() => {
              this.visible = false;
            }, 5000);
            // console.log(this.BlackId)
          }
           }
        })
      }else{
        if(this.tab == '1'){
          this.selectedRowKeys1 = selectedRowKeys;
        }else if(this.tab == '2'){
          // console.log(this.publisherBlackList)
          this.selectedRowKeys2 = selectedRowKeys;
          this.publisherBlackList.forEach((item)=>{
            if(item.blacklist_id.trim() == selectedRowKeys[selectedRowKeys.length - 1]){
              this.BlackId = item.blacklist_id.trim()
              this.visible = true
              setTimeout(() => {
                this.visible = false;
              }, 5000);
            }
          })
        }else if(this.tab == '3'){
          this.selectedRowKeys3 = selectedRowKeys;
          this.siteBlackList.forEach((item)=>{
            if(item.blacklist_id.trim() == selectedRowKeys[selectedRowKeys.length - 1]){
              this.BlackId = item.blacklist_id.trim()
              this.visible = true
              setTimeout(() => {
                this.visible = false;
              }, 5000);
            }
          })
        }
      }
    },
    handleOk(){
      this.visible = false;
    },
    // 根据下拉获取section list
    handleMarketerChange(e) {
      this.marketer = e;
    },
    handlePlatformChange(e){
      this.siteList = [];
      this.platform = e;
      // console.log(this.platform)
      if(this.platform == 'Outbrain'){
        this.marketer_list = []
        this.getObMarketers();
      }else{
        this.taboolaMarketer_list = []
        this.getTblMarketers();
      }
    },
    getAllList(){
      if(this.platform == 'Outbrain'){
        this.getList();
        this.getPublisherList()
        this.getSiteList()
      }else{
        this.getTblSiteList()
      }
      this.getBlackID()
    },
    // 释放site
    unBlock() {
      this.loading = true;
      if(this.platform == 'Taboola'){
        this.$post(api.bysite.unblockTblSite, {
          token: this.userInfo.token,
          marketer: this.marketer,
          sites: this.selectedRowKeys4,
          start_date: this.dateString[0],
          end_date: this.dateString[1],
        }).then((res) => {
          this.loading = false;
          if (res.data.status === 200) {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
          this.selectedRowKeys4 = [];
        });
      }else{
          if(this.tab == '1'){
            // console.log(this.selectedRowKeys1)
            this.$post(api.bysite.unblockObSite, {
              token: this.userInfo.token,
              marketer: this.marketer,
              sections: this.selectedRowKeys1,
            }).then((res) => {
              this.loading = false;
              if (res.data.status === 200) {
                this.$message.success(res.data.message);
              } else {
                this.$message.error(res.data.message);
              }
              this.selectedRowKeys1 = [];
            });
          }else if(this.tab == '2'){
            // console.log(this.selectedRowKeys2)
            this.$post(api.bysite.unblockObPublisher, {
              token: this.userInfo.token,
              marketer: this.marketer,
              sections: this.selectedRowKeys2,
            }).then((res) => {
              this.loading = false;
              if (res.data.status === 200) {
                this.$message.success(res.data.message);
              } else {
                this.$message.error(res.data.message);
              }
              this.selectedRowKeys2 = [];
            });
          }else if(this.tab == '3'){
            // console.log(this.selectedRowKeys3)
            this.$post(api.bysite.unblockObSite, {
              token: this.userInfo.token,
              marketer: this.marketer,
              sections: this.selectedRowKeys3,
            }).then((res) => {
              this.loading = false;
              if (res.data.status === 200) {
                this.$message.success(res.data.message);
              } else {
                this.$message.error(res.data.message);
              }
              this.selectedRowKeys3 = [];
            });
          }
      }
    },
    //获取指定账户的 site unblock
    getList() {
      this.dataList = [];
      this.loading = true;
      this.$post(api.bysite.getObMarketerBlockList, {
        token: this.userInfo.token,
        marketer: this.marketer,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataList = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
        this.selectedRowKeys1 = [];
      });
    },
    //获取账户
    getObMarketers() {
      this.marketer_list = [];
      // this.loading = true;
      this.$post(api.bysite.getObMarketers, {
        token: this.userInfo.token,
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.marketer_list = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getTblMarketers() {
      this.taboolaMarketer_list = [];
      // this.loading = true;
      this.$post(api.bysite.getTblMarketers, {
        token: this.userInfo.token,
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.taboolaMarketer_list = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    //获取指定账户的 site unblock
    getPublisherList() {
      this.PublisherList = [];
      this.loading = true;
      this.$post(api.bysite.getObMarketerBlockPublisherList, {
        token: this.userInfo.token,
        marketer: this.marketer,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          // console.log(res.data)
          this.PublisherList = res.data.data;
          this.PublisherList2 = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
        this.selectedRowKeys2 = [];
      });
    },
    getSiteList() {
      this.siteList = [];
      this.loading = true;
      this.$post(api.bysite.getObMarketerBlockLists, {
        token: this.userInfo.token,
        marketer: this.marketer,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          // console.log(res.data)
          this.siteList = res.data.data;
          this.siteList2 = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
        this.selectedRowKeys3 = [];
      });
    },
    getTblSiteList() {
      this.siteList = [];
      this.loading = true;
      this.$post(api.bysite.getTblMarketerBlockLists, {
        platform: this.platform,
        token: this.userInfo.token,
        marketer: this.marketer,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          // console.log(res.data)
          this.siteList = res.data.data;
          this.siteList2 = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
        this.selectedRowKeys4 = [];
      });
    },
    tabChange(e){
      // console.log(e)
      this.tab = e
      // this.selectedRowKeys = [];
    },
    showRuleModal() {
      this.ruleVisible = !this.ruleVisible;
    },
    handleFilterChange(e) {
      this.contains = e;
      console.log(this.contains)

    },
    handleRuleChange(e) {
      this.blockName = e;
      console.log(this.blockName)

    },
    changeRuleColumn(blockName,contains,rule){
      if(blockName == 'publisher'){
        console.log(this.PublisherList)
        if (contains == "contains"){
          this.PublisherList = this.PublisherList.filter(
              (item) => item.pub_name.toLowerCase().includes(rule.toLowerCase())
          );
        }
        if (contains == "not contains"){
            this.PublisherList = this.PublisherList.filter(
              (item) => item.pub_name.toLowerCase().indexOf(rule.toLowerCase()) == -1
            );
            console.log(this.PublisherList)
        }
      }else{
        if (contains == "contains"){
          this.siteList = this.siteList.filter(
              (item) => item.section_name.toLowerCase().includes(rule.toLowerCase())
          );
        }
        if (contains == "not contains"){
            this.siteList = this.siteList.filter(
              (item) => item.section_name.toLowerCase().indexOf(rule.toLowerCase()) == -1
            );
            console.log(this.siteList)
        }
      }
      this.ruleList.push({
          blockName: blockName,
          contains: contains,
          rule: rule,
      });
      this.ruleVisible = false
      this.rule = ''
    },
    closeRule(name){
      // 可能有问题
      this.ruleList.forEach((ruleitem,index) => {
        //删掉需要删除的规则
        if (ruleitem == name) {
          this.ruleList.splice(index, 1);
        }
      });
      // if(this.tab == 2){
        if(this.ruleList.length == 0){
          this.PublisherList = this.PublisherList2
          this.siteList = this.siteList2
        }else{
          this.ruleList.forEach((ruleitem) => {
            if (ruleitem.blockName == "publisher"){
              if (ruleitem.contains == "contains"){
                this.PublisherList = []
                this.PublisherList2.forEach((item) => {
                  var a = item.pub_name.toLowerCase().includes(ruleitem.rule.toLowerCase())
                  if(a == true){
                      this.PublisherList.push(item)
                  }
                });
                console.log(this.PublisherList)
              }else{
                this.PublisherList = []
                this.PublisherList = this.PublisherList2.filter(
                  (item) => item.pub_name.toLowerCase().indexOf(ruleitem.rule.toLowerCase()) == -1
                );
              }
            }else{
              if (ruleitem.contains == "contains"){
                this.siteList = []
                this.siteList2.forEach((item) => {
                  var a = item.pub_name.toLowerCase().includes(ruleitem.rule.toLowerCase())
                  if(a == true){
                      this.siteList.push(item)
                  }
                });
                console.log(this.siteList)
              }else{
                this.siteList = []
                this.siteList = this.siteList2.filter(
                  (item) => item.pub_name.toLowerCase().indexOf(ruleitem.rule.toLowerCase()) == -1
                );
              }
            }
          });
        }
  
      // }
    },
    onDateChange(date, dateString) {
        this.dateString = dateString;
    },
    deletRuleColumn(){
      this.ruleList = [];
      this.PublisherList = this.PublisherList2
      this.siteList = this.siteList2
    },
    blackChange(e){
      this.black = e;
      console.log(this.black)
    },
    submitBlackID(content,black){
      // console.log(content)
      console.log(black)
      var newList = content.trim().split(/\n/);
      // console.log(newList)
      this.$post(api.bysite.updateBlacklist, {
        token: this.userInfo.token,
        marketer: this.marketer,
        platform: this.platform,
        type: black, //site / publisher
        id: newList
      }).then((res) => {
        if (res.data.status === 200) {
          if(black == "publisher"){
            newList.forEach((item)=>{
              this.publisherBlackList.unshift({blacklist_id: item})
            })
          }else{
            // Section ID
            newList.forEach((item)=>{
              this.siteBlackList.unshift({blacklist_id: item})
            })
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getBlackID(){
      console.log(this.marketer)
      this.loading = true;
      this.$post(api.bysite.getBlacklist, {
        token: this.userInfo.token,
        marketer: this.marketer,
        platform:  this.platform,
      }).then((res) => {
        this.loading = false;
          console.log(res.data)
        if (res.data.status === 200) {
          console.log(res.data.data.publisher)
          // this.siteList = res.data.data;
          this.publisherBlackList = res.data.data.publisher
          this.siteBlackList = res.data.data.site
          //对siteBlackList，取出每一项的blacklist_id属性组成一个新数组
          if(this.platform == 'Outbrain'){
            this.obBlackListId = this.siteBlackList.map((item) => item.blacklist_id);
            this.obDataLoaded = true;
          }else{
            this.tbBlackListId = this.siteBlackList.map((item) => item.blacklist_site);
            this.obDataLoaded = true;
                        // console.log("black",this.obBlackListId)
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    }
  },

  // 页面初始化
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
  },
};
</script>
