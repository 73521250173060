<template>
  <div>
    <!-- date -->
    <div class="ant-page-header-tl">
      <a-range-picker :default-value="dateString" @change="onDateChange"/>
      <a-button type="primary" style="margin-left: 16px;" @click="submitDateChange">Apply</a-button>
    </div>
    <!-- table -->
    <div class="section">
        <a-table 
          :columns="columns" 
          :data-source="dataList" 
          :rowKey="record => record.unique_id"
          :pagination="false" 
          size="middle"
          bordered
          :loading="loading"
          class="data-table"
        >
        </a-table>

      <div style="margin: 24px 24px 0;padding:13px;font-family:'Roboto', sans-serif;font-weight：400">
      注：<br/>					
        1. SNS - SNS：包含 AC/BA/BF/BS/FD/FT/FP/LF/TDI/LF101 共 10 站的数据。<br/>
        2. 订阅转化率：指所选时间段内，由推送渠道获得的 Session 在当前订阅数中的占比。<br/>		
        3. 推送打开率：指所选时间段内，由推送渠道获得的 Session 在总推送人次中的占比。<br/>		
        4. 退订数：由于数据存储逻辑的限制，本报告中此数据的有效起始日期为 6/3/2021，此日期前的数据请以各组报告为准。<br/>
        5. 由于各产品 Push Notification 功能的上线时间不一，本报告中各组产品数据的有效起始日期分别为：SNS - 5/6/2020；Hours - 5/28/2020；APP - 4/1/2020<br/>		
    </div>
  </div>
  <a-spin :spinning="spinning">
    <div class="section">
      <a-row>
        <a-col :span="12">
          <h4>PV 情况</h4>
          <ve-line :data="pvLineData" :legend-visible="false" :extend="chartExtend"></ve-line>
        </a-col>
        <a-col :span="12">
          <h4>宗数情况</h4>
          <ve-line :data="znumLineData" :legend-visible="false" :extend="chartExtend"></ve-line>
        </a-col>
      </a-row>
      <a-row>
        <div class="lang_box">
          <div class="lang_box_item">
            <span v-for="(item, index) in typeList" :key="index">
              <a-switch :checked="item.checked" @change="e => switchChange(e, item.name, item.color)" :class="'switchcolor'+item.name" size="small" />
              <span class="switch-tl">{{item.name}}</span>
            </span>
          </div>
        </div>
      </a-row>
    </div>
  </a-spin>
</div>
</template>
<script>
  import api from '@/common/api.js';
  import moment from 'moment';
  const defaultStartDate = moment().startOf('day').subtract(2, 'days');
  const defaultEndDate = moment().startOf('day').subtract(2, 'days');

  const defaultLineStartDate = moment().startOf('day').subtract(15, 'days');
  const defaultLineEndDate = moment().startOf('day').subtract(2, 'days');

  const columns = [
    {
      title:'Site',
      dataIndex: 'site_name',
      sorter: (a, b) => {
        if (!a.site_name.includes("Total")) {
          return a.site_name.localeCompare(b.site_name)
        }
      }
    },
    {
      title:'当前订阅数',
      dataIndex: 'subscribe',
      align: 'right',
      customRender: text => {
        // return parseFloat(text.toString()).toFixed(2).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        if (!a.site_name.includes("Total")) {
          return a.subscribe - b.subscribe
        }
      }
    },
    {
      title:'新增订阅数',
      dataIndex: 'new_subscribe',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        if (!a.site_name.includes("Total")) {
          return a.new_subscribe - b.new_subscribe
        }
      }
    },
    {
      title:'退订数',
      dataIndex: 'unsubscribe',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        if (!a.site_name.includes("Total")) {
          return a.unsubscribe - b.unsubscribe
        }
      }
    },
    {
      title:'推送次数',
      dataIndex: 'push_count',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        if (!a.site_name.includes("Total")) {
          return a.push_count - b.push_count
        }
      }
    },
    {
      title:'推送人次',
      dataIndex: 'push_num',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        if (!a.site_name.includes("Total")) {
          return a.push_num - b.push_num
        }
      }
    },
    {
      title:'推送打开率',
      dataIndex: 'push_open_rate',
      align: 'right',
      customRender: text => {
        return parseFloat(text.toString()*100).toFixed(2).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {
        if (!a.site_name.includes("Total")) {
          return a.push_open_rate - b.push_open_rate
        }
      }
    },
    {
      title:'Session',
      dataIndex: 'sessions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        if (!a.site_name.includes("Total")) {
          return a.sessions - b.sessions
        }
      }
    },
    {
      title:'PV',
      dataIndex: 'pv',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        if (!a.site_name.includes("Total")) {
          return a.pv - b.pv
        }
      }
    },
    {
      title:'Bounce Rate',
      dataIndex: 'bounce_rate',
      align: 'right',
      customRender: text => {
        return parseFloat(text.toString()).toFixed(2).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {
        if (!a.site_name.includes("Total")) {
          return a.bounce_rate - b.bounce_rate
        }
      }
    },
    {
      title:'P/S',
      dataIndex: 'p_s',
      align: 'right',
      customRender: text => {
        // return text.toString().match(/^\d+(?:\.\d{0,1})?/)
        return parseFloat(text.toString()).toFixed(1).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        if (!a.site_name.includes("Total")) {
          return a.p_s - b.p_s
        }
      }
    },
    {
      title:'Ads CTR',
      dataIndex: 'ads_ctr',
      align: 'right',
      customRender: text => {
        return parseFloat(text.toString()).toFixed(2).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {
        if (!a.site_name.includes("Total")) {
          return a.ads_ctr - b.ads_ctr
        }
      }
    },
    {
      title:'Ads CPC',
      dataIndex: 'ads_cpc',
      align: 'right',
      customRender: text => {
        return parseFloat(text.toString()).toFixed(3).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        if (!a.site_name.includes("Total")) {
          return a.ads_cpc - b.ads_cpc
        }
      }
    },
    {
      title:'CPC 上限',
      dataIndex: 'cpc_top',
      align: 'right',
      customRender: text => {
        return parseFloat(text.toString()).toFixed(3).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        if (!a.site_name.includes("Total")) {
          return a.cpc_top - b.cpc_top
        }
      }
    },
     {
      title:'宗数',
      dataIndex: 'z_num',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        if (!a.site_name.includes("Total")) {
          return a.z_num - b.z_num
        }
      }
    },
  ]

  export default {
    data() {
      return {
        userInfo: {},
        defaultStartDate,
        defaultEndDate,
        columns,
        dataList: [],
        loading: false,
        start_date: '',
        end_date: '',
        dateString: [moment(defaultStartDate, 'YYYY-MM-DD'), moment(defaultEndDate, 'YYYY-MM-DD')],
        lineDateString: [moment(defaultLineStartDate, 'YYYY-MM-DD'), moment(defaultLineEndDate, 'YYYY-MM-DD')],
        pvLineData: {
          columns: ['date', 'total'],
          rows: []
        },
        znumLineData: {
          columns: ['date', 'total'],
          rows: []
        },
        typeList: [
          {name: 'sns', color: '#59b1ef', checked: false},
          {name: 'hour', color: '#a294ff', checked: false},
          {name: 'app', color: '#1ad4ae', checked: false},
          {name: 'total', color: '#f70163', checked: true},
        ],
        chartExtend: {
          color: ['#f70163']
        },
        spinning: false,
      }
    },
    // 定义方法
    methods: {
      onDateChange(dateString) {
        this.dateString = dateString
        this.lineDateString = dateString
      },
      // 修改日期获取数据
      submitDateChange(){
        this.getList();
        this.line();
      },
      // 获取数据列表
      getList(){
        this.loading = true
        this.$post(api.okr.getPushPerf, {
          start_date: this.dateString[0].format('YYYY-MM-DD'),
          end_date: this.dateString[1].format('YYYY-MM-DD'),
          token: this.userInfo.token
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.dataList = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      // 切换坐标
      switchChange(e, name, color) {
        if (e) {
          this.typeList.forEach((item) => {
            if(item.name == name){
              item.checked = true
              this.chartExtend.color.push(color)
            }
          })
          this.pvLineData.columns.push(name)
          this.znumLineData.columns.push(name)
        } else {
          this.typeList.forEach(item => {
            if(item.name == name){
              item.checked = false
              this.pvLineData.columns.forEach((a, index) => {
                if(a == name){
                  this.pvLineData.columns.splice(index, 1)
                  this.chartExtend.color.splice(index-1, 1)
                }
              })
              this.znumLineData.columns.forEach((a, index) => {
                if(a == name){
                  this.znumLineData.columns.splice(index, 1)
                }
              })
            }
          })
        }
      },
      // 获取折线图所需数据
      line(){
        this.dataList = [];
        this.spinning = true;
        this.$post(api.okr.getPushLine, {
          start_date: this.lineDateString[0].format('YYYY-MM-DD'),
          end_date: this.lineDateString[1].format('YYYY-MM-DD'),
          token: this.userInfo.token
        }).then((res) => {
          this.spinning = false;
          if (res.data.status === 200) {
            this.pvLineData.rows = res.data.data.pv
            this.znumLineData.rows = res.data.data.z_num
          } else {
            this.$message.error(res.data.message);
          }
        });
      },
    },

    // 页面初始化
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.getList()
      this.line()
    },
  };
</script>

<style>
  .data-table tr:last-child td{
    border-top: 2px solid #e1e1e1;
  }
  .lang_box{
    margin-top: 0;
  }
  .lang_box_item{
    width: 100%;
    padding-left: 0;
  }
  .lang_box_item span{
    margin-bottom: 8px;
    margin-left: 10px
  }
  .ant-switch-checked.switchcolorsns{
    background-color: #59b1ef;
  }
  .ant-switch-checked.switchcolorhour{
    background-color: #a294ff;
  }
  .ant-switch-checked.switchcolorapp{
    background-color: #1ad4ae;
  }
  .ant-switch-checked.switchcolortotal{
    background-color: #f70163;
  }
</style>
