<template>
  <div id="app" style="margin: 24px">
    <!-- <div>
      <p style="margin-bottom: 24px">
        Current ant-design-vue version: {{ version }}
        <br>You can change ant-design-vue version on the left panel (Dependencies
        section).
      </p>
    </div> -->
    <!-- <a-divider/> -->
    <!-- code -->
    <a-table bordered :columns="columns" :components="components" :dataSource="data">
      <!-- <template :slot="slotItem.slots.title" v-for="(slotItem) of slots">{{ slotItem.name }}</template> -->
      <!-- <template v-slot:action>
        
      </template> -->
      <a slot="action" href="javascript:;">Delete</a>
    </a-table>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import api from '@/common/api.js'
// import { version } from "ant-design-vue";
// import VueDraggableResizable from "vue-draggable-resizable";

// Vue.component("vue-draggable-resizable", VueDraggableResizable);

// const data = [
//   {
//     key: 0,
//     date: "2018-02-11",
//     amount: 120,
//     type: "income",
//     note: "transfer"
//   },
//   {
//     key: 1,
//     date: "2018-03-11",
//     amount: 243,
//     type: "income",
//     note: "transfer"
//   },
//   {
//     key: 2,
//     date: "2018-04-11",
//     amount: 98,
//     type: "outcome",
//     note: "transfer"
//   }
// ];

const columns = [
  {
    title:'推广',
    dataIndex: 'platform',
    className: 'baseinfo',
    // fixed: 'left',
    width: 50,
  },
  {
    title:'Campaign ID',
    dataIndex: 'campaign_id',
    className: 'baseinfo',
    // fixed: 'left',
    scopedSlots: { customRender: 'campaign_id' },
    sorter: (a, b) => a.campaign_id.toString().localeCompare(b.campaign_id.toString()),
    width: 125
  },
  {
    title:'Campaign Name',
    dataIndex: 'campaign_name',
    className: 'baseinfo',
    // fixed: 'left',
    scopedSlots: { customRender: 'campaign_name' },
    width: 240,
    sorter: (a,b) => a.campaign_name.localeCompare(b.campaign_name),
    // sorter: (a, b) => a.value.localeCompare(b.value)
  },
  {
    title:'Campaign status',
    dataIndex: 'campaign_status',
    className: 'baseinfo',
    scopedSlots: { customRender: 'status' },
    sorter: (a,b) => a.campaign_status.localeCompare(b.campaign_status),
    // sorter: (a, b) => a.campaign_status - b.campaign_status,
    // fixed: 'left'
    width: 150
  },
  {
    title:'Start Date',
    dataIndex: 'start_date',
    className: 'baseinfo' ,
    sorter: (a,b) => a.start_date.localeCompare(b.start_date),
    // sorter: (a, b) => a.start_date_sort - b.start_date_sort,
    // fixed: 'left'
    width: 130,
    align: 'right'
  },
  {
    title:'Default CPC',
    dataIndex: 'default_cpc', 
    className: 'baseinfo',
    sorter: (a, b) => a.default_cpc - b.default_cpc,
    // fixed: 'left'
    width: 130,
    align: 'right'
  },
  
];

const draggingMap = {};
columns.forEach(col => {
  draggingMap[col.key] = col.width;
});
const draggingState = Vue.observable(draggingMap);
const drag = (h, props, children) => {
  let thDom = null;
  const { key, ...restProps } = props;
  const col = columns.find(col => {
    const k = col.dataIndex || col.key;
    return k === key;
  });
  if (!col.width) {
    return <th {...restProps}>{children}</th>;
  }
  const onDrag = (x) => {
    draggingState[key] = 0;
    col.width = Math.max(x, 1);
  };

  const onDragstop = () => {
    draggingState[key] = thDom.getBoundingClientRect().width;
  };
  return (
    <th
      {...restProps}
      v-ant-ref={r => (thDom = r)}
      width={col.width}
      class="resize-table-th"
    >
      {children}
      <vue-draggable-resizable
        key={col.key}
        class="table-draggable-handle"
        w={10}
        x={draggingState[key] || col.width}
        z={1}
        axis="x"
        draggable={true}
        resizable={false}
        onDragging={onDrag}
        onDragstop={onDragstop}
      />
    </th>
  );
};

export default {
  name: "App",
  data() {
    return {
      userInfo: {},
      moment,
    //   version,
      console: console,
      data: [],
      columns,
      components: {
        header: {
          cell: drag
        }
      }
    };
  },
  // created() {
  //   this.initColumns();
  // },
  // computed: {
  //   slots() {
  //     const slots = [];
  //     for (const column of this.columns) {
  //       if (column.slots && column.slots.title) {
  //         slots.push(column);
  //       }
  //     }

  //     return slots;
  //   }
  // },
  created () {
    if(localStorage.userInfo){
      this.userInfo = JSON.parse(localStorage.userInfo)
    }
    if(!this.userInfo.user_name) {
      this.$router.push({ path: '/login' })
      return false
    }
    this.getList()
  },
  methods: {
    getList(){
      let params = {
        platform: this.platform,
        period1: this.period1,
        period2: this.period2,
        token: this.userInfo.token
      }
      this.$post(api.filter.getCampaignList, params).then(res => {
        if (res.data.status === 200) {
          console.log(res.data.data)
          // return false
          let newData = res.data.data
          
          newData.data.forEach(item => {
            item.start_date_sort = parseInt(item.start_date.replace(/[-]/g,''))
          })
          this.data = newData.data
          this.period1 = [newData.start_date, newData.end_date]
          // this.period2 = [newData.start_date, newData.end_date]
          // this.period3 = [newData.start_date, newData.end_date]
          // this.de_start_date = newData.start_date
          // this.de_end_date = newData.end_date
        } else {
          this.$message.error(res.data.message)
        }
      });
    },
    // initColumns() {
    //   // 请求接口获取columns参数
    //   this.columns = [
    //     {
    //       title: "Date",
    //       dataIndex: "date",
    //       width: 200
    //     },
    //     {
    //       title: "Amount",
    //       dataIndex: "amount",
    //       width: 200,
    //       defaultSortOrder: "descend",
    //       sorter: (a, b) => a.amount - b.amount
    //     },
    //     {
    //       title: "Type",
    //       dataIndex: "type",
    //       width: 100,
    //       filters: [
    //         {
    //           text: "income",
    //           value: "income"
    //         },
    //         {
    //           text: "outcome",
    //           value: "outcome"
    //         }
    //       ],
    //       onFilter: (value, record) => record.type === value
    //     },
    //     {
    //       title: "Note",
    //       dataIndex: "note",
    //       width: 100
    //     },
    //     {
    //       key: 5,
    //       title: "Action",
    //       width: 100,
    //       scopedSlots: { customRender: "action" }
    //     }
    //   ];
    //   this.draggableResizable();
    // },
    // draggableResizable() {
    //   const draggingMap = {};
    //   console.dir(this.columns);
    //   this.columns.forEach(col => {
    //     draggingMap[col.key] = col.width;
    //   });
    //   const draggingState = Vue.observable(draggingMap);
    //   const drag = (hsfass, props, children) => {
    //     let thDom = null;
    //     const { key, ...restProps } = props;
    //     const col = this.columns.find(col => {
    //       const k = col.dataIndex || col.key;
    //       return k === key;
    //     });
    //     if (!col.width) {
    //       return <th {...restProps}>{children}</th>;
    //     }
    //     const onDrag = (x) => {
    //       draggingState[key] = 0;
    //       col.width = Math.max(x, 1);
    //     };

    //     const onDragstop = () => {
    //       draggingState[key] = thDom.getBoundingClientRect().width;
    //     };
    //     return (
    //       <th
    //         {...restProps}
    //         v-ant-ref={r => (thDom = r)}
    //         width={col.width}
    //         class="resize-table-th"
    //       >
    //         {children}
    //         <vue-draggable-resizable
    //           key={col.key}
    //           class="table-draggable-handle"
    //           w={10}
    //           x={draggingState[key] || col.width}
    //           z={1}
    //           axis="x"
    //           draggable={true}
    //           resizable={false}
    //           onDragging={onDrag}
    //           onDragstop={onDragstop}
    //         />
    //       </th>
    //     );
    //   };
    //   this.components = {
    //     header: {
    //       cell: drag
    //     }
    //   };
    // }
  }
};
</script>

<style>
.resize-table-th {
  position: relative; 
}
.resize-table-th .table-draggable-handle {
  height: 100% !important;
  bottom: 0;
  left: auto !important;
  right: -5px;
  cursor: col-resize;
  touch-action: none;
}
</style>