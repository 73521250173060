<template>
  <div>
    <div class="ant-page-header-tl">
      <a-select defaultValue="taboola" style="width: 160px;" @change="handlePlatformChange">
        <a-select-option v-for="(item, index) in platformList" :key="index" :value="item.name">{{item.name}}</a-select-option>
      </a-select>
      <a-button style="margin-left: 16px;" type="primary" @click="submitHandlePlatformChange">Apply</a-button>
    </div>
    <div class="section" style="position: relative">
      <a-tabs type="card" default-active-key="relaunch" @change="callback">
        <a-tab-pane key="relaunch" tab="Relaunch" force-render>
          <div style="margin-bottom: 16px;">
            <a-range-picker v-if="start_date1" :default-value="[moment(start_date1, 'YYYY-MM-DD'), moment(end_date1, 'YYYY-MM-DD')]" @change="handleNewDataChange"  />
            <span style="padding: 0 10px;">Compare to </span> 
            <a-range-picker @change="handleOldDataChange" v-if="start_date2" :default-value="[moment(start_date2, 'YYYY-MM-DD'), moment(end_date2, 'YYYY-MM-DD')]" />
            <a-button style="margin-left: 16px;" type="primary" @click="getRelaunchList">Check</a-button>
          </div>
          <a-table 
            :columns="columnsre" 
            :data-source="RelaunchList" 
            :rowKey="record => record.campaign_id"
            :pagination="false" 
            size="middle"
            :loading="loading"
            bordered
          >
            <div style="text-decoration: underline;cursor: pointer;" slot="toline" slot-scope="text, record" @click="toLine(record.campaign_id,start_date1,end_date1)">
              {{text}}
            </div>
            <div class="smallbtn" slot="action" slot-scope="text, record">
              <a-button type="primary" size="small" @click="goCreated(record.campaign_id,'relaunch')" style="margin-right: 10px;background-color: #52c41a;border-color: #52c41a">Relaunch</a-button>
              <a-button type="primary" size="small" @click="toLine(record.campaign_id,start_date1,end_date1)" style="margin-right: 10px; background-color: #4dcbcb;border-color: #4dcbcb">查看表现</a-button>
              <a-button type="primary" size="small" @click="goCreated(record.campaign_id,'optimization')" style="margin-right: 10px;">优化</a-button>
              <a-button size="small" @click="noCreatives(record.campaign_id)">不优化</a-button>
            </div>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="optimization" tab="Creatives">
          <a-range-picker v-if="c_start_date" :default-value="[moment(c_start_date, 'YYYY-MM-DD'), moment(c_end_date, 'YYYY-MM-DD')]"  style="margin-bottom: 16px;" @change="handleCreativeDataChange" />
          <a-button style="margin-left: 16px;margin-bottom: 16px;" type="primary" @click="getCreativeList">Check</a-button>
          <h3 class="creative-tl">Top PV</h3>
          <a-table 
            :columns="columnspv" 
            :data-source="top_limit_pv" 
            :rowKey="record => record.campaign_id"
            :pagination="false" 
            size="middle"
            :loading="loading"
            bordered
          >
            <div style="text-decoration: underline;cursor: pointer;" slot="toline" slot-scope="text, record" @click="toLine(record.campaign_id,c_start_date,c_end_date)">
              {{text}}
            </div>
            <div class="smallbtn" slot="action" slot-scope="text, record">
              <a-button type="primary" size="small" @click="goCreated(record.campaign_id,'relaunch')" style="margin-right: 10px;background-color: #52c41a;border-color: #52c41a">Relaunch</a-button>
              <a-button type="primary" size="small" @click="goCreated(record.campaign_id,'optimization')">优化</a-button>
            </div>
          </a-table>
          <a-button v-if="top_limit_pv.length < 15" @click="showMorePv" type="primary" style="margin-top: 16px;">Show More PV</a-button>
          <h3 class="creative-tl" style="margin-top: 24px;">Top Impression</h3>
          <a-table 
            :columns="columns" 
            :data-source="top_limit_impression" 
            :rowKey="record => record.campaign_id"
            :pagination="false" 
            size="middle"
            :loading="loading"
            bordered
          >
            <div style="text-decoration: underline;cursor: pointer;" slot="toline" slot-scope="text, record" @click="toLine(record.campaign_id,c_start_date,c_end_date)">
              {{text}}
            </div>
            <div class="smallbtn" slot="action" slot-scope="text, record">
              <a-button type="primary" size="small" @click="goCreated(record.campaign_id,'relaunch')" style="margin-right: 10px;background-color: #52c41a;border-color: #52c41a">Relaunch</a-button>
              <a-button type="primary" size="small" @click="goCreated(record.campaign_id,'optimization')">优化</a-button>
            </div>
          </a-table>
          <a-button v-if="top_limit_impression.length < 15" @click="showMoreImpression" type="primary" style="margin-top: 16px;">Show More Impression</a-button>
        </a-tab-pane>
        <a-tab-pane key="optimize" tab="To Optimize">
          <a-range-picker :default-value="period3"  style="margin-bottom: 16px;" @change="handleOptimizeDataChange" />
          <a-button style="margin-left: 16px;margin-bottom: 16px;" type="primary" @click="getOptimizeList">Check</a-button>
          <h3 class="creative-tl" style="margin-top: 24px;">CPC上限高，Session不足</h3>
          <a-table 
            :columns="columns3" 
            :data-source="optimize_list1" 
            :rowKey="record => record.campaign_id"
            :pagination="false" 
            size="middle"
            :loading="loading"
            bordered
          >
            <div style="text-decoration: underline;cursor: pointer;" slot="toline" slot-scope="text, record" @click="toLine(record.campaign_id,period3[0],period3[1])">
              {{text}}
            </div>
            <div class="smallbtn" slot="action" slot-scope="text, record">
              <a-button type="primary" size="small" @click="toLine(record.campaign_id,period3[0],period3[1])" style="margin-right: 10px; background-color: #4dcbcb;border-color: #4dcbcb">查看表现</a-button>
              <a-button type="primary" size="small" @click="goCreated(record.campaign_id,'optimization')" style="margin-right: 10px;">优化</a-button>
              <a-button size="small" @click="noCreatives1(record.campaign_id)">不优化</a-button>
            </div>
          </a-table>
          <h3 class="creative-tl" style="margin-top: 24px;">CPC上限不高，Session大</h3>
          <a-table 
            :columns="columns3" 
            :data-source="optimize_list2" 
            :rowKey="record => record.campaign_id"
            :pagination="false" 
            size="middle"
            :loading="loading"
            bordered
          >
            <div style="text-decoration: underline;cursor: pointer;" slot="toline" slot-scope="text, record" @click="toLine(record.campaign_id,period3[0],period3[1])">
              {{text}}
            </div>
            <div class="smallbtn" slot="action" slot-scope="text, record">
              <a-button type="primary" size="small" @click="toLine(record.campaign_id,period3[0],period3[1])" style="margin-right: 10px; background-color: #4dcbcb;border-color: #4dcbcb">查看表现</a-button>
              <a-button type="primary" size="small" @click="goCreated(record.campaign_id,'optimization')" style="margin-right: 10px;">优化</a-button>
              <a-button size="small" @click="noCreatives1(record.campaign_id)">不优化</a-button>
            </div>
          </a-table>
        </a-tab-pane>
        
      </a-tabs>
      </div>
  </div>
</template>
<script>
  import api from '@/common/api.js'
  import moment from 'moment'
  const defaultStartDate = moment().startOf('day').subtract(8, 'days');
  const defaultEndDate = moment().startOf('day').subtract(1, 'days')

  const columns = [
    {
      title:'Campaign Id',
      dataIndex: 'campaign_id',
      sorter: (a, b) => a.campaign_id.toString().localeCompare(b.campaign_id.toString())
    },
    {
      title:'Campaign Name',
      dataIndex: 'campaign_name',
      sorter: (a, b) => a.campaign_name.localeCompare(b.campaign_name),
      scopedSlots: { customRender: 'toline' },
    },
    {
      title:'Impressions',
      dataIndex: 'impressions',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      align: 'right',
      sorter: (a, b) => a.impressions - b.impressions,
    },
    {
      title:'Detail',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
      width: '100px',
      align: 'center'
    }
  ]

  const columnspv = [
    {
      title:'Campaign Id',
      dataIndex: 'campaign_id',
      sorter: (a, b) => a.campaign_id.toString().localeCompare(b.campaign_id.toString())
    },
    {
      title:'Campaign Name',
      dataIndex: 'campaign_name',
      sorter: (a, b) => a.campaign_name.localeCompare(b.campaign_name),
      scopedSlots: { customRender: 'toline' },
    },
    {
      title:'PV',
      dataIndex: 'page_views',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      align: 'right',
      sorter: (a, b) => a.page_views - b.page_views,
    },
    {
      title:'Detail',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
      width: '100px',
      align: 'center'
    }
  ]

  const columnsre = [
    {
      title:'Campaign Id',
      dataIndex: 'campaign_id',
      sorter: (a, b) => a.campaign_id.toString().localeCompare(b.campaign_id.toString())
    },
    {
      title:'Campaign Name',
      dataIndex: 'campaign_name',
      sorter: (a, b) => a.campaign_name.localeCompare(b.campaign_name),
      scopedSlots: { customRender: 'toline' },
      // sorter: (a, b) => a.campaign_name - b.campaign_name,
    },
    {
      title:'Sessions',
      dataIndex: 'sessions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.sessions - b.sessions,
    },
    {
      title:'Sessions Before',
      dataIndex: 'sessions_before',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.sessions_before - b.sessions_before,
    },
    {
      title:'Sessions Change',
      dataIndex: 'sessions_change',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.sessions_change - b.sessions_change,
    },
    {
      title:'Detail',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
      width: '100px',
      align: 'center'
    }
  ]

  const columns3 = [
    {
      title:'Campaign Id',
      dataIndex: 'campaign_id',
      sorter: (a, b) => a.campaign_id.toString().localeCompare(b.campaign_id.toString())
    },
    {
      title:'Campaign Name',
      dataIndex: 'campaign_name',
      sorter: (a, b) => a.campaign_name.localeCompare(b.campaign_name),
      scopedSlots: { customRender: 'toline' },
    },
    {
      title:'Session',
      dataIndex: 'sessions',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      align: 'right',
      sorter: (a, b) => a.sessions - b.sessions,
    },
    {
      title:'CPC上限',
      dataIndex: 'cpc_top',
      align: 'right',
      sorter: (a, b) => a.cpc_top - b.cpc_top,
    },
    {
      title:'ads cpc',
      dataIndex: 'ads_cpc',
      align: 'right',
      sorter: (a, b) => a.ads_cpc - b.ads_cpc,
    },
    {
      title:'ads ctr',
      dataIndex: 'ads_ctr',
      align: 'right',
      sorter: (a, b) => a.ads_ctr - b.ads_ctr,
    },
    {
      title:'p/s',
      dataIndex: 'p_s',
      align: 'right',
      sorter: (a, b) => a.p_s - b.p_s,
    },
    {
      title:'spent',
      dataIndex: 'spent',
      align: 'right',
      sorter: (a, b) => a.spent - b.spent,
    },
    {
      title:'系数',
      dataIndex: 'x_num',
      align: 'right',
      sorter: (a, b) => a.x_num - b.x_num,
    },
    {
      title:'宗数',
      dataIndex: 'z_num',
      align: 'right',
      sorter: (a, b) => a.z_num - b.z_num,
    },
    {
      title:'宗数/花费',
      dataIndex: 'roi',
      align: 'right',
      sorter: (a, b) => a.roi - b.roi,
      customRender: text => {
        return text + "%"
      }
    },
    {
      title:'Detail',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
      width: '100px',
      align: 'center'
    }
    
  ]

  export default {
    data() {
      return {
        c_period: [],
        c_end_date: '',
        c_start_date: '',
        moment,
        start_date1: '',
        start_date2: '',
        end_date1: '',
        end_date2: '',
        period1: [],
        period2: [],
        period3: [defaultStartDate.format('YYYY-MM-DD'),defaultEndDate.format('YYYY-MM-DD')],
        top_limit_impression: [],
        top_limit_pv: [],
        optimize_list1: [],
        optimize_list2: [],
        platform: 'taboola',
        campaign_type: 'relaunch',
        userInfo: {},
        columns,
        columnspv,
        columnsre,
        columns3,
        top_impression: [],
        top_pv: [],
        RelaunchList: [],
        // dataSource: [],
        loading: false,  
        platformList: [
          {
            id: 2,
            name: 'taboola'
          },
          {
            id: 3,
            name: 'outbrain'
          },
        ],   
      }
    },
    methods: {
      toLine(campaign_id,start_date,end_date){
        const {href} = this.$router.resolve({
          path: '/line',
          query: {
            campaign_id: campaign_id,
            period: JSON.stringify([start_date, end_date])
          },
        })
        window.open(href, '_blank')
      },
      handleCreativeDataChange(date, dateString){
        this.c_period = dateString
      },
      handleNewDataChange(date, dateString){
        this.period1 = dateString
      },
      handleOldDataChange(date, dateString){
        this.period2 = dateString
      },
      handleOptimizeDataChange(date, dateString){
        this.period3 = dateString
      },
      showMorePv(){
        this.top_limit_pv = this.top_pv
      },
      showMoreImpression(){
        this.top_limit_impression = this.top_impression
      },
      handlePlatformChange(e){
        console.log(e)
        this.platform = e
        // this.getList()
      },
      submitHandlePlatformChange(){
        if(this.campaign_type == 'relaunch'){
          this.getRelaunchList()
        }else if(this.campaign_type == 'optimization'){
          this.getCreativeList()
        }else{
          this.getOptimizeList()
        }
      },
      goCreated(campaign_id,type){
        let query = {}
        // if(this.campaign_type == 'relaunch'){
        query = {
          campaign_id: campaign_id,
          type: type,
          platform: this.platform,
        }
        // }
        const {href} = this.$router.resolve({
          path: '/created',
          query: query,
        })
        window.open(href, '_blank')
      },
      
      noCreatives(campaign_id){
        this.loading = true
        console.log(campaign_id);
        this.$post(api.update.noOptimize, {
          token: this.userInfo.token,
          campaign_id: campaign_id
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            const RelaunchList = this.RelaunchList;
            this.RelaunchList = RelaunchList.filter(item => item.campaign_id !== campaign_id)
          }else{
            this.$message.error(res.data.message)
          }
        });
      },

      noCreatives1(campaign_id){
        this.loading = true
        console.log(campaign_id);
        this.$post(api.update.noOptimize, {
          token: this.userInfo.token,
          type: 4,
          campaign_id: campaign_id
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            const RelaunchList = this.RelaunchList;
            this.RelaunchList = RelaunchList.filter(item => item.campaign_id !== campaign_id)
          }else{
            this.$message.error(res.data.message)
          }
        });
      },

      callback(key) {
        console.log(key);
        this.campaign_type = key
        if(key == 'relaunch'){
          this.getRelaunchList()
        }else if(key == 'optimization'){
          this.getCreativeList()
        }else{
          this.getOptimizeList()
        }
      },
      getRelaunchList(){
        this.RelaunchList = []
        this.loading = true
        this.$post(api.update.getToRelaunchCampaign, {
            platform: this.platform,
            period1: this.period1,
            period2: this.period2,
            token: this.userInfo.token
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
              console.log(res)
              let data = res.data.data
              this.RelaunchList = data.list
              this.start_date1 = data.start_date1
              this.start_date2  = data.start_date2
              this.end_date1 = data.end_date1
              this.end_date2 = data.end_date2
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      // getToRelaunchCampaign
      getCreativeList(){
        this.top_limit_impression = []
        this.top_limit_pv = []
        this.top_impression = []
        this.top_pv = []
        this.loading = true
        this.$post(api.update.getToOptimizeCampaign, {
            platform: this.platform,
            period1: this.c_period,
            token: this.userInfo.token
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
              console.log(res)
              let top_impression = res.data.data.top_impression
              let top_pv = res.data.data.top_pv
              this.c_end_date = res.data.data.end_date
              this.c_start_date = res.data.data.start_date
              this.top_impression = top_impression
              this.top_pv = top_pv
              top_impression.forEach((item, index) => {
                if(index < 10){
                  this.top_limit_impression.push(item)
                }
              });
              top_pv.forEach((item, index) => {
                if(index < 10){
                  this.top_limit_pv.push(item)
                }
              });
              
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      getOptimizeList(){
        this.loading = true
        this.$post(api.update.getToOptimizeCampaignNew, {
            platform: this.platform,
            period1: this.period3,
            token: this.userInfo.token
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
              let data = res.data.data
              console.log(data)
              this.optimize_list1 = data.high_cpc
              this.optimize_list2 = data.high_session
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
    },
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      // this.getCreativeList()
      this.getRelaunchList()
    }
  };
</script>

<style> 
  .cardwrapper{
    margin: 24px;
    /* background-color: #fff; */
  }
  /* .selectdate{
    position: absolute;
    right: 16px;
    top: 28px;
    background-color: #fff;
    padding-left: 32px;
    z-index: 999;
  } */
  .creative-tl{
    border-left: 5px solid #1890ff;
    padding-left: 10px;
    border-radius: 2px;
  }
  .showmore{
    line-height: 36px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 16px;
  }
</style>
