<template>
  <div>
    <div class="ant-page-header-tl">新文章的数量
      <div class="righttop">
        <a-range-picker v-if="period.length" :default-value="[moment(period[0], 'YYYY-MM-DD'), moment(period[1], 'YYYY-MM-DD')]" @change="rangeChange" />
        <a-button type="primary" style="margin-left: 16px;" @click="submitRangeChange">Apply</a-button>
      </div>
    </div>
    <div class="section">
      <!-- <a-card :loading="loading" :bordered="false"> -->
        <a-table 
          :loading="loading"
          :columns="columns" 
          :data-source="dataList" 
          :rowKey="record => record.editor"
          :pagination="false" 
          size="middle"
          bordered
        >
          <a slot="action" slot-scope="text, record" @click="goNewPost(record.editor)">Check</a>
        </a-table>
      <!-- </a-card>  -->
    </div>
  </div>
</template>
<script>
  import api from '@/common/api.js'
  import moment from 'moment';
  const columns = [
    {
      title:'Editor',
      dataIndex: 'editor',
      // sorter: (a, b) => a.editor - b.editor,
    },
    {
      title:'已上线文章数量',
      dataIndex: 'online_post_num',
      align: 'right',
      sorter: (a, b) => a.online_post_num - b.online_post_num,
    },
    {
      title:'目标数量',
      dataIndex: 'target_post_num',
      align: 'right',
      sorter: (a, b) => a.target_post_num - b.target_post_num,
    },
    {
      title:'Daily Session',
      dataIndex: 'daily_session',
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      align: 'right',
      sorter: (a, b) => a.daily_session - b.daily_session,
    },
    {
      title:'Daily 宗数',
      dataIndex: 'daily_z_num',
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      align: 'right',
      sorter: (a, b) => a.daily_z_num - b.daily_z_num,
    },
    {
      title:'完成百分比(Daily 宗数/200)',
      dataIndex: 'target_rate',
      customRender: text => {
        return (text*100).toFixed(2) + '%'
      },
      align: 'right',
      sorter: (a, b) => a.target_rate - b.target_rate,
    },
    {
      title:'Detail',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
      align: 'center'
    }
  ]

  export default {
    data() {
      return {
        moment,
        period: [],
        userInfo: {},
        columns,
        dataList: [],
        loading: false,      
      }
    },
    methods: {
      rangeChange(date, dateString){
        this.period = dateString  
      },
      submitRangeChange(){
        this.getList()
      },
      getList(){
        this.loading = true
        let params = {
          type: 'editor_data',
          period: JSON.stringify(this.period),
          token: this.userInfo.token
        }
        this.$post(api.okr.getL44Data, params).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.dataList = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      goNewPost(editor){
        const {href} = this.$router.resolve({
          path: '/dayznum',
          query: {
            editor: editor,
            period: JSON.stringify(this.period)
          },
        })
        window.open(href, '_blank')
      }
    },
    created () {
      this.period = JSON.parse(this.$route.query.period)
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.getList()
    }
  };
</script>

<style> 
  .section{
    margin: 24px;
    background-color: #fff;
  }
</style>
