/**
 * Parse the json to excel
 *  tableJson 导出数据 ; filenames导出表的名字; autowidth表格宽度自动 true or false; bookTypes xlsx & csv & txt
 * @param {(Object)} tableJson
 * @param {string} filenames
 * @param {boolean} autowidth
 * @param {string} bookTypes
 */
export function json2excel (tableJson, filenames, reportType, autowidth, bookTypes) {
  import("../../excel/Export2ExcelByDailyGa4").then(excel => {
    var tHeader = []
    var dataArr = []
    var sheetnames = []
    for (var i in tableJson) {
      tHeader.push(tableJson[i].tHeader)
      dataArr.push(formatJson(tableJson[i].filterVal, tableJson[i].tableData))
      sheetnames.push(tableJson[i].sheetName)
    }
    excel.export_json_to_excel({
      header: tHeader,
      data: dataArr,
      sheetname: sheetnames,
      filename: filenames,
      reportType:reportType,
      autoWidth: autowidth,
      bookType: bookTypes
    })
  })
}
// 数据过滤，时间过滤
function formatJson (filterVal, jsonData) {
  return jsonData.map(v => filterVal.map(j => v[j]))
}
