<template>
  <div>
    <div class="ant-page-header-tl">
      <!-- date -->
      <span style="margin-right: 5px">Date:</span>
      <a-range-picker :default-value="dateString" @change="onDateChange" />
      <!-- Platform -->
      <span style="margin-left: 10px; margin-right: 5px">Platform:</span>
      <a-select
        defaultValue="Taboola"
        style="width: 150px"
        @change="handlePlatformChange"
      >
        <a-select-option
          v-for="(item, index) in platformList"
          :key="index"
          :value="item"
          >{{ item }}</a-select-option
        >
      </a-select>
      <!-- Type -->
      <span style="margin-left: 10px; margin-right: 5px">Type:</span>
      <a-select
        defaultValue="site"
        style="width: 100px;"
        @change="handleTypeChange"
      >
        <a-select-option v-for="type in typeList" :key="type">{{
          type
        }}</a-select-option>
      </a-select>
      <!-- Product -->
      <span style="margin-left: 10px; margin-right: 5px">Product:</span>
      <a-select
        defaultValue="BA"
        style="width: 100px"
        @change="handleProductChange"
      >
        <a-select-option
          v-for="(item, index) in productList"
          :key="index"
          :value="item"
          >{{ item }}</a-select-option
        >
      </a-select>

      <a-button
        type="primary"
        style="margin-left: 16px"
        @click="filterAccount"
        >Filter Account</a-button>

       <a-select
        v-if="platform == 'Outbrain'"
        mode="multiple"
        placeholder="选择Outbrain账户"
        style="width: 250px;margin-left: 16px"
        @change="handleAccountChange"
      >
        <a-select-option
          v-for="(item,index) in accountListByProduct"
          :key="index"
          :value="index"
          >{{ item }}</a-select-option
        >
      </a-select>
      <a-select
        v-else
        mode="multiple"
        placeholder="选择Taboola账户"
        style="width: 250px;margin-left: 16px"
        @change="handleAccountChange"
      >
        <a-select-option
          v-for="(item,index) in accountListByProduct"
          :key="index"
          :value="item"
          >{{ item }}</a-select-option
        >
      </a-select>

      <a-button
        type="primary"
        style="margin-left: 16px"
        @click="submitDateChange"
        >Apply</a-button>

      <div style="margin-top: 16px" v-if="dataList.length > 0">
        <span style="margin-right: 10px">Click >= <a-input style="width: 100px" v-model="click" /></span>
        <span style="margin-right: 10px">上限 &lt;= <a-input style="width: 100px" v-model="top" /></span>
        <span style="margin-right: 16px">Click/Session >= <a-input style="width: 100px" v-model="cps" /> %</span>
        <span style="margin-right: 16px">Percent >= <a-input style="width: 100px" v-model="percent" /> %</span>
        <a-button
          type="primary"
          @click="filters"
          >筛选</a-button>
        <div style="margin: 16px 0" v-if="blockNum != ''" >Block总数: {{blockNum}}</div>
      </div>
      <div style="margin-top: 8px">
        <a-button
          type="primary"
          :disabled="!hasSelected"
          :loading="loading"
          @click="multiBlock"
        >
          Block
        </a-button>
        <span style="margin-left: 8px">
          <template v-if="hasSelected">
            {{ `Selected ${selectedRowKeys.length} items` }}
          </template>
        </span>
        <span style="float: right">{{ this.remain }}</span>
      </div>
    </div>
    <!-- table -->
    <div class="section">
      <a-table
        :columns="columnList"
        :data-source="dataList"
        :pagination="false"
        :rowKey="(record) => record.product + ' ' + record.id"
        :scroll="{ x: 2400, y: 1200 }"
        size="small"
        bordered
        :loading="loading"
        class="alan-data-table"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
      >
        <div slot="clicks_sessions" slot-scope="text">
          <span v-if="text > 150" style="color:#c53929">{{
            text.toFixed(2) + "%"
          }}</span
          ><span v-else>{{ text.toFixed(2) + "%" }}</span>
        </div>

        <div slot="block_status" slot-scope="text, record">
          <span v-if="record.block_status == 1">Blocked</span>
          <!-- <span v-else>Running</span> -->
        </div>
        <div
          class="smallbtn"
          slot="action"
          slot-scope="text, record"
          style="padding: 4px 0px"
        >
          <a-button type="primary" size="small" @click="block(record)"
            >Block</a-button
          >
        </div>
        <div
          class="smallbtn"
          slot="blacklist"
          slot-scope="text, record"
          style="padding: 4px 0px"
        >
          <a-button type="primary" size="small" @click="blacklist(record)"
            >Blacklist</a-button
          >
        </div>
      </a-table>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
const defaultStartDate = moment()
  .startOf("day")
  .subtract(15, "days");
const defaultEndDate = moment()
  .startOf("day")
  .subtract(2, "days");

const platformList = ["Taboola", "Outbrain"];
const typeListData = { Taboola: ["site"], Outbrain: ["site", "publisher"] };

const columnList = [
  {
    title: "Product",
    dataIndex: "product",
    sorter: (a, b) => {
      return a.product.localeCompare(b.product);
    },
    width: 120,
    fixed: "left",
  },
  {
    title: "Site/Publisher",
    dataIndex: "site",
    width: 360,
    ellipsis: true,
    sorter: (a, b) => {
      return a.name.localeCompare(b.name);
    },
    fixed: "left",
  },
    {
    title: "Account",
    dataIndex: "account_site",
    width: 120,
  },
  {
    title: "Impressions",
    dataIndex: "impressions",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.impressions - b.impressions;
    },
  },
  {
    title: "CTR",
    dataIndex: "ctr",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "%";
    },
    sorter: (a, b) => {
      return a.ctr - b.ctr;
    },
  },
  {
    title: "Clicks",
    dataIndex: "clicks",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.clicks - b.clicks;
    },
  },
  {
    title: "Sessions",
    dataIndex: "sessions",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.sessions - b.sessions;
    },
  },
  {
    title: "PV",
    dataIndex: "pv",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.pv - b.pv;
    },
  },
  {
    title: "P/S",
    dataIndex: "p_s",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.p_s - b.p_s;
    },
  },
  {
    title: "CPC上限",
    dataIndex: "cpc_top",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.cpc_top - b.cpc_top;
    },
  },
  {
    title: "Avg.CPC",
    dataIndex: "avg_cpc",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.avg_cpc - b.avg_cpc;
    },
  },
  {
    title: "Avg.CPC/CPC上限",
    dataIndex: "avg_cpc_cpc_top",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "%";
    },
    sorter: (a, b) => {
      return a.avg_cpc_cpc_top - b.avg_cpc_cpc_top;
    },
  },
  {
    title: "Clicks/Sessions",
    dataIndex: "clicks_sessions",
    align: "right",
    scopedSlots: { customRender: "clicks_sessions" },
    // customRender: text => {
    //   return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
    // },
    sorter: (a, b) => {
      return a.clicks_sessions - b.clicks_sessions;
    },
  },
  {
    title: "Percent",
    dataIndex: "percent",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "%";
    },
    sorter: (a, b) => {
      return a.percent - b.percent;
    },
  },
  {
    title: "Block Status",
    dataIndex: "block_status",
    align: "center",
    scopedSlots: { customRender: "block_status" },
    sorter: (a, b) => a.block_status.length - b.block_status.length,
  },
  {
    title: "Block",
    scopedSlots: { customRender: "action" },
    align: "center",
    width: 100,
    // fixed: "right",
  },
  {
    title: "Blacklist",
    scopedSlots: { customRender: "blacklist" },
    align: "center",
    width: 100,
    // fixed: "right",
  },
];

export default {
  data() {
    return {
      userInfo: {},
      pageSize: 100,
      defaultStartDate,
      defaultEndDate,
      dataList: [],
      account_site: [],
      accountList: [],
      accountListByProduct: [],
      dataList_back: [],
      account_key: "",
      columnList,
      loading: false,
      start_date: "",
      end_date: "",
      dateString: [
        moment(defaultStartDate, "YYYY-MM-DD"),
        moment(defaultEndDate, "YYYY-MM-DD"),
      ],
      platformList,
      typeListData,
      typeList: typeListData[platformList[0]],
      remain: "",
      type: "site",
      platform: "Taboola",
      product: "BA",
      productList: [
        "BA",
        "LF",
        "BF",
        "FD",
        "TDI",
        "FP",
        "FT",
        "AC",
        "BS",
        "AP",
        "ULCH",
      ],
      report: "data-1",
      reportList: [],
      selectedRowKeys: [],
      click: '50',
      top: '0.007',
      cps: '300',
      percent: '230',
      blockNum: '',
      productName: []
    };
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  // 定义方法
  methods: {
    onDateChange(dateString) {
      this.dateString = dateString;
    },
    filterAccount(){
      this.getAccount()
    },
    handlePlatformChange(e) {
      // console.log(this.platform)
      this.platform = e;
      this.typeList = typeListData[e];
    },
    handleProductChange(e) {
      this.product = e;
    },
    handleTypeChange(e) {
      this.type = e;
    },
    // 提交之后获取数据
    submitDateChange() {
      this.getList();
    },
    handleAccountChange(e) {
        console.log("key", e);
        this.account_key = e.join(",");
        this.account_site = e;
        this.dataList = this.dataList_back.filter(item => this.account_site.includes(item.account_site));
    },
    //get account by product
    getAccount() {
      this.$post(api.bysite.getAccountByProduct, {
        platform: this.platform,
        type: this.type,
        product: this.product,
        token: this.userInfo.token
      }).then((res) => {
        if (res.data.status === 200) {
          this.accountListByProduct = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 获取数据列表
    getList() {
      this.blockNum = '';
      this.loading = true;
      this.$post(api.bysite.sitePerformance, {
        platform: this.platform,
        type: this.type,
        product: this.product,
        start_date: this.dateString[0].format("YYYY-MM-DD"),
        end_date: this.dateString[1].format("YYYY-MM-DD"),
        account: this.account_key,
        token: this.userInfo.token
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.remain = res.data.data.remain;
          this.dataList = res.data.data.list;
          this.dataList_back = res.data.data.list;
          this.accountList = res.data.data.account_list;
          this.selectedRowKeys = []; // 清空已选项
          this.$message.success(res.data.message);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // block taboola
    blockTbl(e, ismulti = 0) {
      let params = [];
      if (ismulti == 1) {
        params = e;
      } else {
        params = [e.account_site + "|impr|" + e.name];
      }
      this.loading = true;
      this.$post(api.bysite.blockTblSite, {
        params: params,
        token: this.userInfo.token
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          // this.dataList = this.dataList.filter(item => res.data.data.idindexOf(item.name));
          this.dataList.map(function(obj) {
            if (res.data.data.indexOf(obj.name) >= 0) {
              obj.block_status = 1;
              return obj;
            }
          });
          this.$message.success(res.data.message);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // block outbrain
    blockOb(e, ismulti = 0) {
      this.loading = true;
      let params = [];
      if (ismulti == 1) {
        params = e;
      } else {
        params = [e.account_site + "|impr|" + e.name];
      }
      this.$post(api.bysite.blockObSite, {
        type: this.type,
        params: params,
        token: this.userInfo.token
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          // this.dataList = this.dataList.filter(item => item.id !== e.id);
          this.dataList.map(function(obj) {
            if (res.data.data.indexOf(obj.name) >= 0) {
              obj.block_status = 1;
              return obj;
            }
          });
          this.$message.success(res.data.message);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // block 操作
    block(e) {
      if (this.platform == "Taboola") {
        this.blockTbl(e);
      } else {
        this.blockOb(e);
      }
    },
    // 多选block操作
    multiBlock() {
      this.loading = true;
      let block_list = [];
      for (var i in this.selectedRowKeys) {
        for (var j in this.dataList) {
          if (
            this.selectedRowKeys[i] ==
            this.dataList[j]["product"] + ' ' + this.dataList[j]["id"]
          ) {
            // 需测试
            console.log(this.selectedRowKeys[i])
            block_list.push(
              this.dataList[j]["account_site"] +
                "|impr|" +
                this.dataList[j]["name"]
            );
            break;
          }
        }
      }
      // console.log(block_list)
      if (this.platform == "Taboola") {
        this.blockTbl(block_list, 1);
      } else {
        this.blockOb(block_list, 1);
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      // console.log(selectedRowKeys)
      this.productName = [];
      this.blockNum = '';
      selectedRowKeys.forEach((item) => {
        var index = item.indexOf(' ')
        var name = item.substring(0,index)
        this.productName.push(name)
      })
      console.log(this.productName)
      this.count()
    },

    filters(){
      this.productName = [];
      this.blockNum = '';
      // var lastArr = [];
      this.selectedRowKeys = [];
      this.dataList.forEach((item) => {
          if(item.clicks >= this.click) {
            if((item.cpc_top <= this.top) || (item.clicks_sessions >= this.cps) || (item.percent >= this.percent)) {
              this.selectedRowKeys.push(item.product + ' ' + item.id)
              this.productName.push(item.product)
            }
          }
      })
      if(this.selectedRowKeys.length == 0){
        this.blockNum = '没有满足条件的数据'
      }
      this.count()
    },
    count(){
      // console.log(this.productName)
      // this.blockNum = '';
      var lastArr = [];
      var newArr = [...new Set(this.productName)]; //去重
      var num = 0;
      // console.log(newArr)
      newArr.forEach(item=>{
        num = 0;
        this.productName.forEach(i=>{
          if(item == i){
            num++
          }
        })
        lastArr.push(`${item}:${num}个`)
      })
      // console.log(lastArr)
      lastArr.forEach(i=>{
        this.blockNum += i + ' | '
      })
    },
    blacklist(e){
      this.$post(api.bysite.updateBlacklist, {
        platform: this.platform,
        type: this.type,
        marketer: e.account_site,
        id: [e.id],
        site:[e.id, e.name],
        token: this.userInfo.token
      }).then((res) => {
        if (res.data.status === 200) {
          this.$message.success("已加入黑名单");
        } else {
          this.$message.error(res.data.message);
        }
      });
    }
  },
  // 页面初始化
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }

  },
};
</script>
