<template>
  <div>
    <div class="ant-page-header-tl">
      <a-date-picker
        v-if="dateString"
        :default-value="moment(dateString, 'YYYY-MM-DD')"
        @change="onDateChange"
        style="margin-right: 8px"
      />
      <a-button type="primary" @click="getData">Apply</a-button>
    </div>
    <a-row>
      <a-col :span="12">
        <div class="section-wrapper">
          <a-card :bordered="false" title="每日宗数">
            <a-table
              :columns="columns1"
              :data-source="dayzumList.cc"
              :rowKey="(record) => record.name"
              :pagination="false"
              size="small"
              bordered
              :loading="loading"
              style="margin-bottom: 24px"
            >
              <div
                class="smallbtn"
                slot="action"
                slot-scope="text, record"
                style="padding: 4px 0px"
              >
                <a-button type="primary" size="small" @click="goDetail(record)"
                  >Check</a-button
                >
              </div>
              <div slot="z_num" slot-scope="text">
                <b>{{
                  text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
                }}</b>
              </div>             
            </a-table>

            <a-table
              :columns="columns1"
              :data-source="dayzumList.hh"
              :rowKey="(record) => record.name"
              :pagination="false"
              size="small"
              bordered
              :loading="loading"
              style="margin-bottom: 24px"
            >
              <div
                class="smallbtn"
                slot="action"
                slot-scope="text, record"
                style="padding: 4px 0px"
              >
                <a-button type="primary" size="small" @click="goDetail(record)"
                  >Check</a-button
                >
              </div>
              <div slot="z_num" slot-scope="text">
                <b>{{
                  text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
                }}</b>
              </div>
            </a-table>

            <a-table
              :columns="columns1"
              :data-source="dayzumList.team"
              :rowKey="(record) => record.name"
              :pagination="false"
              size="small"
              bordered
              :loading="loading"
            >
              <div
                class="smallbtn"
                slot="action"
                slot-scope="text, record"
                style="padding: 4px 0px"
              >
                <a-button type="primary" size="small" @click="goDetail(record)"
                  >Check</a-button
                >
              </div>
              <div slot="z_num" slot-scope="text">
                <b>{{
                  text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
                }}</b>
              </div>
            </a-table>
          </a-card>
        </div>
      </a-col>
      <a-col :span="12">
        <div class="section-wrapper">
          <a-card :bordered="false" title="季度宗数">
            <a-table
              :columns="columns2"
              :data-source="quarterList.cc"
              :rowKey="(record) => record.name"
              :pagination="false"
              size="small"
              bordered
              :loading="loading2"
              style="margin-bottom: 24px"
            >
              <div
                class="smallbtn"
                slot="action"
                slot-scope="text, record"
                style="padding: 4px 0px"
              >
                <a-button type="primary" size="small" @click="goDetail(record)"
                  >Check</a-button
                >
              </div>
              <div slot="z_num" slot-scope="text">
                <b>{{
                  text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
                }}</b>
              </div>
            </a-table>
            <a-table
              :columns="columns2"
              :data-source="quarterList.hh"
              :rowKey="(record) => record.name"
              :pagination="false"
              size="small"
              bordered
              :loading="loading2"
              style="margin-bottom: 24px"
            >
              <div
                class="smallbtn"
                slot="action"
                slot-scope="text, record"
                style="padding: 4px 0px"
              >
                <a-button type="primary" size="small" @click="goDetail(record)"
                  >Check</a-button
                >
              </div>
              <div slot="z_num" slot-scope="text">
                <b>{{
                  text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
                }}</b>
              </div>
            </a-table>
            <a-table
              :columns="columns2"
              :data-source="quarterList.team"
              :rowKey="(record) => record.name"
              :pagination="false"
              size="small"
              bordered
              :loading="loading2"
            >
              <div
                class="smallbtn"
                slot="action"
                slot-scope="text, record"
                style="padding: 4px 0px"
              >
                <a-button type="primary" size="small" @click="goDetail(record)"
                  >Check</a-button
                >
              </div>
              <div slot="z_num" slot-scope="text">
                <b>{{
                  text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
                }}</b>
              </div>
            </a-table>
          </a-card>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";

const columns1 = [
  {
    title: "平台",
    dataIndex: "name",
    align: "left",
  },
  {
    title: "PV",
    dataIndex: "page_views",
    align: "right",
    width: 100,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.page_views - b.page_views
      }
    }
  },
  {
    title: "花费",
    dataIndex: "spent",
    align: "right",
    width: 100,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.spent - b.spent
      }
    }
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    width: 100,
    scopedSlots: { customRender: "z_num" },
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.z_num - b.z_num
      }
    }
  },
  //  {
  //   title: "上周宗数",
  //   dataIndex: "z_num_last",
  //   align: "right",
  //   width: 100,
  //   scopedSlots: { customRender: "z_num" },
  //   sorter: (a, b) => a.z_num - b.z_num,
  // },
  {
    title: "宗数 DOD",
    dataIndex: "z_num_dod",
    align: "right",
    width: 100,
    customRender: (text) => {
      return text + "%";
    },
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.z_num_dod - b.z_num_dod
      }
    }
  },
  {
    title: "宗数/花费",
    dataIndex: "z_s",
    align: "right",
    customRender: (text) => {
      return text + "%";
    },
    width: 100,
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.z_s - b.z_s
      }
    }
  },
  {
    title: "By Day",
    scopedSlots: { customRender: "action" },
    align: "center",
    width: 100,
    // fixed: 'right',
  },
];

const columns2 = [
  {
    title: "平台",
    dataIndex: "name",
    align: "left",
  },
  {
    title: "PV",
    dataIndex: "page_views",
    align: "right",
    width: 100,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.page_views - b.page_views
      }
    }
  },
  {
    title: "花费",
    dataIndex: "spent",
    align: "right",
    width: 100,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.spent - b.spent
      }
    }
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    width: 100,
    scopedSlots: { customRender: "z_num" },
    // customRender: text => {
    //   return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
    // },
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.z_num - b.z_num
      }
    }
  },
  {
    title: "宗数/花费",
    dataIndex: "z_s",
    align: "right",
    customRender: (text) => {
      return text + "%";
    },
    width: 100,
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.z_s - b.z_s
      }
    }
  },
  {
    title: "By Day",
    scopedSlots: { customRender: "action" },
    align: "center",
    width: 100,
    // fixed: 'right',
  },
];

export default {
  data() {
    return {
      moment,
      userInfo: {},
      columns1,
      columns2,
      loading: false,
      loading2: false,
      okrList_2: [],
      dayzumList: {},
      quarterList: [],
      dateString: moment()
        .startOf("day")
        .subtract(2, "days")
        .format()
        .slice(0, 10),
    };
  },
  methods: {
    goDetail(record) {
      const { href } = this.$router.resolve({
        path: "/zumexpress-detail",
        query: {
          slug: record.slug,
          name: record.name,
        },
      });
      window.open(href, "_blank");
    },
    getDailyPerf() {
      this.loading = true;
      this.$post(api.okr.getDailyPerf, {
          token: this.userInfo.token,
          end_date: this.dateString,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status === 200) {
            this.dayzumList = res.data.data;
            // console.log(this.dayzumList )
            // this.dateString = res.data.data.date
          } else {
            this.$message.error(res.data.message);
          }
        });
    },

    getQuarterPerf() {
      this.loading2 = true;
      this.$post(api.okr.getQuarterPerf, {
          token: this.userInfo.token,
          end_date: this.dateString,
        })
        .then((res) => {
          this.loading2 = false;
          if (res.data.status === 200) {
            this.quarterList = res.data.data;
            // this.dateString = res.data.data.date
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    onDateChange(date, dateString) {
      this.dateString = dateString;
    },
    getData() {
      this.getDailyPerf();
      this.getQuarterPerf();
    },
  },
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.getDailyPerf();
    this.getQuarterPerf();
  },
};
</script>

<style>
.total-item {
  margin-right: 10px;
  margin-left: 10px;
  color: #ddd;
}
</style>
