<template>
  <div>
    <div class="ant-page-header-tl">
      <span v-if="campaign_type == 'relaunch'">Relaunch </span>
      <span v-if="campaign_type == 'optimization'">Creative </span>
      <span v-if="!campaign_type">New </span>
      Campaign

      <a-button
        v-if="campaign_type == 'relaunch'"
        style="float:right;margin-left: 16px;"
        type="primary"
        @click="removeCreative"
        >Remove Creative</a-button
      >
    </div>
    <div class="created">
      <a-row
        style="background-color:#fff; margin-bottom: 24px; padding: 16px"
        v-if="campaign_type == 'relaunch' || campaign_type == 'optimization'"
      >
        <h1 class="title" style="margin-top: 0; margin-bottom: 16px;">
          {{ platform }}
        </h1>
        <div class="righttop">
          <a-range-picker
            :default-value="[defaultStartDate, defaultEndDate]"
            @change="onDateChange"
          />
          <a-button
            style="margin-left: 16px;"
            type="primary"
            @click="getDateChange"
            >Check</a-button
          >
        </div>

        <a-table
          :columns="columns"
          :data-source="creativeList"
          :rowKey="(record) => record.item_id"
          :pagination="false"
          size="small"
          bordered
          :loading="creativeloading"
          :scroll="{ x: 1600 }"
        >
          <div slot="status" slot-scope="text, record">
            <a-switch
              :disabled="campaign_type == 'relaunch'"
              :default-checked="record.is_active == 1"
              @click="onStatusChange(record.is_active, record.item_id)"
              size="small"
            />
          </div>
          <div
            v-if="record.item_id != curtable_id"
            @click="editorTitle(record.item_id, text)"
            class="crtitle"
            slot="pltitle"
            slot-scope="text, record"
          >
            {{ text }}
            <a-icon
              v-if="platform == 'taboola'"
              class="edittitle"
              type="edit"
            />
          </div>
          <div v-else slot="pltitle" class="editor-box">
            <a-input v-model="cur_table_tl" />
            <div class="editor-btn">
              <a-button style="margin-right: 16px" @click="noEditorBtn"
                >CANCEL</a-button
              >
              <a-button
                type="primary"
                :loading="ediTtitleLoading"
                @click="editorBtn"
                >SAVE</a-button
              >
            </div>
          </div>
          <span
            @click="deleteCreativeItem(record.item_id)"
            slot="action"
            slot-scope="text, record"
            ><a-icon style="fontSize: 18px;" type="delete"
          /></span>
          <div
            class="editorimage"
            style="cursor: pointer;"
            slot="image"
            slot-scope="text, record"
            @click="showEditImg(record.image, record.item_id)"
          >
            <img style="height: 75px;border-radius:4px;" :src="(record.gif_url ? record.gif_url : record.image)" />
            <a-icon
              class="edittitle"
              v-if="platform == 'taboola'"
              type="edit"
            />
          </div>
        </a-table>
      </a-row>
      <a-spin size="large" :spinning="pagespinning">
        <a-row :gutter="16">
          <a-col :span="12">
            <div class="section-tl">Taboola setting</div>
          </a-col>
          <a-col :span="12">
            <div class="section-tl">Outbrain setting</div>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <div class="form-wrapper">
              <div v-if="platform == 'outbrain'" class="form-disabled"></div>
              <h1 class="title">Campaign Setup</h1>
              <div class="form-tl"><b>Account</b></div>
              <a-select
                v-model="tbl_account"
                style="width: 280px;"
                @change="tblAccountChange"
              >
                <a-select-option
                  v-for="(item, index) in tbl_fields.account"
                  :key="index"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>

              <div class="form-tl"><b>模版</b></div>
              <a-select
                defaultValue="Select an template"
                style="width: 320px;"
                @change="tblTemplateChange"
              >
                <a-select-option
                  v-for="(item, index) in tbl_template"
                  :key="index"
                  :value="item.campaign_id"
                  >{{ item.campaign_name }}</a-select-option
                >
              </a-select>

              <div class="form-tl"><b>Campaign Name</b></div>
              <a-input
                placeholder="Enter Campaign Name"
                style="width: 600px"
                v-model="tbl_campaign_name"
              />

              <div class="form-tl"><b>Also Duplicate</b></div>
              <a-checkbox-group
                @change="duplicateChange"
                :default-value="['is_block_sites', 'is_tracking_code']"
              >
                <a-row>
                  <a-col :span="24">
                    <a-checkbox value="is_site_bid">
                      Bid Adjustments
                    </a-checkbox>
                  </a-col>
                  <a-col :span="24">
                    <a-checkbox value="is_block_sites">
                      Site Blocks
                    </a-checkbox>
                  </a-col>
                  <a-col :span="24">
                    <a-checkbox value="is_tracking_code">
                      Tracking Code and 3rd Party Tags
                    </a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>

              <div class="form-tl"><b>Brand Name</b></div>
              <a-input
                placeholder="Enter Brand Name"
                style="width: 220px"
                v-model="tbl_brand_text"
              />

              <!-- objective -->
              <div class="form-tl"><b>Campaign Objective</b></div>
              <a-radio-group v-model="tbl_objective">
                <a-radio :value="'DRIVE_WEBSITE_TRAFFIC'">
                  WEBSITE ENGAGEMENT
                </a-radio>
                <a-radio :value="'LEADS_GENERATION'">
                  LEAD GENERATION
                </a-radio>
              </a-radio-group>

              <h1 class="title">Schedule</h1>
              <div class="form-tl"><b>Start Date</b></div>
              <a-radio-group
                v-model="tbl_start_date_raido"
                @change="onStartChange"
              >
                <a-radio :value="1" :style="radioStyle">
                  as soon as reviewed
                </a-radio>
                <a-radio :value="2" :style="radioStyle">
                  Specify a start and end date
                </a-radio>
                <a-range-picker
                  @change="onTabStartChange"
                  :disabled="tbl_start_date_raido == 1"
                />
              </a-radio-group>

              <div class="form-tl"><b>Start Hour</b></div>
              <a-radio-group
                v-model="tbl_start_hour.mode"
                @change="onHourRadioChange"
              >
                <a-radio :value="'ALWAYS'" :style="radioStyle">
                  24/7
                </a-radio>
                <a-radio :value="'CUSTOM'" :style="radioStyle">
                  Set Specific Hour
                </a-radio>
              </a-radio-group>

              <div v-if="tbl_start_hour.mode == 'CUSTOM'">
                <a-row style="margin-bottom: 8px;margin-top: 8px;">
                  <a-col :span="6">
                    <span class="subtl">Day</span>
                  </a-col>
                  <a-col :span="6">
                    <span class="subtl">Starting Time</span>
                  </a-col>
                  <a-col :span="6" :offset="1">
                    <span class="subtl">Ending Time</span>
                  </a-col>
                </a-row>
                <a-row
                  class="hour-line"
                  v-for="(item, index) in tbl_start_hour.rules"
                  :key="index"
                >
                  <a-col :span="6"
                    ><a-checkbox
                      :checked="item.type == 'INCLUDE'"
                      :value="item.name"
                      name="tbl_hour"
                      @click="tblHourDayChange(index)"
                      ><span class="hourtl">{{ item.day }}</span></a-checkbox
                    ></a-col
                  >
                  <a-col :span="6"
                    ><a-time-picker
                      style="width: 100%;"
                      :value="moment(item.from_hour, 'HH')"
                      format="HH A"
                      @change="
                        (time, timeString) =>
                          ontblStartChange(time, timeString, index)
                      "
                    />
                  </a-col>
                  <a-col :span="1" style="text-align: center; color: #bfbfbf;"
                    >-</a-col
                  >
                  <a-col :span="6"
                    ><a-time-picker
                      style="width: 100%;"
                      :value="moment(item.until_hour, 'HH')"
                      format="HH A"
                      @change="
                        (time, timeString) =>
                          ontblEndChange(time, timeString, index)
                      "
                  /></a-col>
                </a-row>
              </div>

              <h1 class="title">Target</h1>
              <!-- 城市 -->
              <div class="form-tl"><b>Location</b></div>
              <div class="include">
                <a-select v-model="tbl_country['type']" style="width: 120px">
                  <a-select-option value="INCLUDE">
                    <a-icon type="check-circle" style="color: #52c41a" />
                    INCLUDE
                  </a-select-option>
                  <a-select-option value="EXCLUDE">
                    <a-icon type="close-circle" style="color: #ff4d4f" />
                    EXCLUDE
                  </a-select-option>
                </a-select>
                <span style="color: #bfbfbf">|</span>
                <a-select
                  mode="multiple"
                  v-model="tbl_country['value']"
                  style="width: 100%"
                  placeholder="Select Country"
                  option-label-prop="label"
                >
                  <a-select-option
                    v-for="(item, index) in tbl_fields.country"
                    :key="index"
                    :value="item.name"
                    :label="item.name"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </div>

              <!-- 设备 -->
              <div class="form-tl"><b>Device</b></div>
              <!-- tab_device -->
              <div class="include">
                <div style="width: 120px;margin-left: 11px;">
                  <a-icon type="check-circle" style="color: #52c41a" />
                  INCLUDE
                </div>
                <span style="color: #bfbfbf">|</span>
                <a-select
                  mode="multiple"
                  v-model="tbl_device['value']"
                  style="width: 100%"
                  placeholder="Select Device"
                  option-label-prop="label"
                >
                  <a-select-option
                    v-for="(item, index) in tbl_fields.device"
                    :key="index"
                    :value="item.name"
                    :label="item.name"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </div>

              <!-- Operating Systems -->
              <div class="form-tl"><b>Operating System</b></div>
              <div class="include">
                <a-select v-model="tbl_os['type']" style="width: 120px">
                  <a-select-option value="INCLUDE">
                    <a-icon type="check-circle" style="color: #52c41a" />
                    INCLUDE
                  </a-select-option>
                  <a-select-option value="EXCLUDE">
                    <a-icon type="close-circle" style="color: #ff4d4f" />
                    EXCLUDE
                  </a-select-option>
                </a-select>
                <span style="color: #bfbfbf">|</span>
                <a-select
                  mode="multiple"
                  v-model="tbl_os['value']"
                  style="width: 100%"
                  placeholder="Select Operating System"
                  option-label-prop="label"
                >
                  <a-select-option
                    v-for="(item, index) in tbl_fields.os"
                    :key="index"
                    :value="item.name"
                    :label="item.name"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </div>

              <!-- Browser -->
              <div class="form-tl"><b>Browser</b></div>
              <div class="include">
                <a-select
                  v-model="tbl_browsers['type']"
                  default-value="INCLUDE"
                  style="width: 120px"
                >
                  <a-select-option value="INCLUDE">
                    <a-icon type="check-circle" style="color: #52c41a" />
                    INCLUDE
                  </a-select-option>
                  <a-select-option value="Exclude">
                    <a-icon type="close-circle" style="color: #ff4d4f" />
                    EXCLUDE
                  </a-select-option>
                </a-select>
                <span style="color: #bfbfbf">|</span>
                <a-select
                  mode="multiple"
                  v-model="tbl_browsers['value']"
                  style="width: 100%"
                  placeholder="Select Browsers"
                  option-label-prop="label"
                >
                  <a-select-option
                    v-for="(item, index) in tbl_fields.browsers"
                    :key="index"
                    :value="item.name"
                    :label="item.name"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </div>

              <!-- Audience -->
              <!-- tbl_audience -->
              <div class="form-tl"><b>Audience</b></div>
              <!-- 包含 -->
              <div class="include" style="margin-bottom: 16px;">
                <div style="width: 120px;margin-left: 11px;">
                  <a-icon type="check-circle" style="color: #52c41a" />
                  {{ tbl_audience_in["type"] }}
                </div>
                <span style="color: #bfbfbf">|</span>
                <a-select
                  mode="multiple"
                  v-model="tbl_audience_in['value']"
                  style="width: 100%"
                  placeholder="Select Audience"
                  option-label-prop="label"
                >
                  <a-select-option
                    v-for="(item, index) in tbl_audience_list"
                    :key="index"
                    :value="item.name"
                    :label="item.name"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </div>
              <!-- 不包含 -->
              <div class="include">
                <div style="width: 120px;margin-left: 11px;">
                  <a-icon type="close-circle" style="color: #ff4d4f" />
                  {{ tbl_audience_ex["type"] }}
                </div>
                <span style="color: #bfbfbf">|</span>
                <a-select
                  mode="multiple"
                  v-model="tbl_audience_ex['value']"
                  style="width: 100%"
                  placeholder="Select Audience"
                  option-label-prop="label"
                >
                  <a-select-option
                    v-for="(item, index) in tbl_audience_list"
                    :key="index"
                    :value="item.name"
                    :label="item.name"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </div>

              <h1 class="title">Budget and Bidding</h1>
              <!-- Daily Budget -->
              <div class="form-tl"><b>Daily Budget</b></div>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_daily_budget"
                  style="width: 160px"
                />
                <span class="input-txt">USD</span>
              </div>

              <!-- Monthly Budget -->
              <div class="form-tl"><b>Monthly Budget</b></div>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_monthly_budget"
                  style="width: 220px"
                />
                <span class="input-txt">USD</span>
              </div>

              <!-- Default CPC -->
              <div class="form-tl"><b>Default CPC</b></div>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc"
                  style="width: 220px"
                  step="0.01"
                />
                <span class="input-txt">USD</span>
              </div>

              <!-- Bid Type -->
              <!-- <div class="form-tl"><b>Bid Type</b></div>
              <a-radio-group v-model="tbl_bid_type" @change="onStartChange">
                <a-radio value="FIXED">
                  Fixed Bid
                </a-radio>
                <a-radio value="OPTIMIZED_PAGEVIEWS">
                  Smart Bid（PV）
                </a-radio>
                <a-radio value="OPTIMIZED_CONVERSIONS">
                  Smart Bid（Conversion）
                </a-radio>
              </a-radio-group> -->
              <!-- Bid Strategy -->
              <div class="form-tl"><b>Bid Strategy</b></div>
              <a-radio-group v-model="tbl_bid_strategy" @change="onStartChange">
                <a-radio value="MAX_CONVERSIONS">
                 Max Conversions
                </a-radio>
                <a-radio value="TARGET_CPA">
                   Target CPA
                </a-radio>
                <a-radio value="SMART">
                 Smart
                </a-radio>
                <a-radio value="FIXED">
                 Fixed
                </a-radio>
              </a-radio-group>


              <h1 class="title">Tracking and Blocking</h1>
              <!-- Tracking Code -->
              <div class="form-tl"><b>Tracking Code</b></div>
              <a-textarea v-model="tbl_tracking_code" :rows="4" />

              <!-- Block Sites -->
              <div class="form-tl"><b>Block Sites</b></div>
              <div v-if="tbl_block_sites.type == 'EXCLUDE'">
                <div
                  class="block-item"
                  v-for="(item, index) in tbl_block_sites.value"
                  :key="index"
                  @click="deleteTBLBlockSite(item)"
                >
                  <a-icon type="stop" style="color: #ff4d4f" />
                  <span style="flex: 1; padding: 0 8px;">{{ item }}</span>
                  <a-icon type="close" style="color: #c8c8c8" />
                </div>
              </div>
            </div>
          </a-col>

          <a-col :span="12">
            <div class="form-wrapper">
              <div v-if="platform == 'taboola'" class="form-disabled"></div>
              <h1 class="title">Campaign Setup</h1>
              <div class="form-tl"><b>Account</b></div>
              <a-select
                v-model="ob_account"
                style="width: 280px;"
                @change="obAccountChange"
              >
                <a-select-option
                  v-for="(item, index) in ob_fields.account"
                  :key="index"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>

              <div class="form-tl"><b>模版</b></div>
              <a-select
                defaultValue="Select an template"
                style="width: 320px;"
                @change="obTemplateChange"
              >
                <a-select-option
                  v-for="(item, index) in ob_template"
                  :key="index"
                  :value="item.campaign_id"
                  >{{ item.campaign_name }}</a-select-option
                >
              </a-select>

              <div class="form-tl"><b>Campaign Name</b></div>
              <a-input
                placeholder="Enter Campaign Name"
                style="width: 600px"
                v-model="ob_campaign_name"
              />

              <div v-if="campaign_type == 'relaunch'">
                <div class="form-tl"><b>Also Duplicate</b></div>
                <a-checkbox-group
                  @change="duplicateChange"
                  :default-value="['is_block_sites', 'is_tracking_code']"
                >
                  <a-row>
                    <a-col :span="24">
                      <a-checkbox value="is_site_bid">
                        Publisher section CPC adjustments
                      </a-checkbox>
                    </a-col>
                    <a-col :span="24">
                      <a-checkbox value="is_block_sites">
                        Publisher and section blocks
                      </a-checkbox>
                    </a-col>
                    <a-col :span="24">
                      <a-checkbox value="is_tracking_code">
                        Tracking and 3rd Party Pixels
                      </a-checkbox>
                    </a-col>
                  </a-row>
                </a-checkbox-group>
              </div>
              <div class="form-tl"><b>Brand Name</b></div>
              <a-input
                disabled
                placeholder="Enter Brand Name"
                style="width: 220px"
                v-model="ob_brand_name"
              />
              <div class="form-tl"><b>Campaign Objective</b></div>
              <a-radio-group
                v-model="ob_objective"
                @change="onCampaignObjectiveChange"
              >
                <a-radio :value="'Traffic'">
                  Traffic
                </a-radio>
                <a-radio :value="'Conversions'">
                  Conversion
                </a-radio>
              </a-radio-group>

              <h1 class="title">Schedule</h1>
              <div class="form-tl"><b>Start Date</b></div>
              <a-radio-group
                v-model="ob_start_date_raido"
                @change="onStartChange"
              >
                <a-radio :value="1" :style="radioStyle">
                  as soon as reviewed
                </a-radio>
                <a-radio :value="2" :style="radioStyle">
                  Specify a start and end date
                </a-radio>
                <a-range-picker
                  @change="onTabStartChange"
                  :disabled="ob_start_date_raido == 1"
                />
              </a-radio-group>

              <div class="form-tl"><b>Start Hour</b></div>
              <a-radio-group
                v-model="ob_start_date_raido"
                @change="onStartChange"
              >
                <a-radio :value="1" :style="radioStyle">
                  24/7
                </a-radio>
                <a-radio :value="2" :style="radioStyle">
                  Set Specific Hour
                </a-radio>
              </a-radio-group>

              <div v-if="ob_start_date_raido == 2">
                <a-row style="margin-bottom: 8px;margin-top: 8px;">
                  <a-col :span="6">
                    <span class="subtl">Day</span>
                  </a-col>
                  <a-col :span="6">
                    <span class="subtl">Starting Time</span>
                  </a-col>
                  <a-col :span="6" :offset="1">
                    <span class="subtl">Ending Time</span>
                  </a-col>
                </a-row>
                <a-row
                  class="hour-line"
                  v-for="(item, index) in ob_hourList"
                  :key="index"
                >
                  <a-col :span="6"
                    ><a-checkbox
                      :checked="item.checked"
                      :value="item.name"
                      name="ob_hour"
                      @click="tblStartChange"
                      ><span class="hourtl">{{ item.name }}</span></a-checkbox
                    ></a-col
                  >
                  <a-col :span="6"
                    ><a-time-picker
                      style="width: 100%;"
                      use12-hours
                      :default-value="moment('12', 'HH')"
                      format="HH A"
                      @change="ontblMondayStartChange"
                    />
                  </a-col>
                  <a-col :span="1" style="text-align: center; color: #bfbfbf;"
                    >-</a-col
                  >
                  <a-col :span="6"
                    ><a-time-picker
                      style="width: 100%;"
                      use12-hours
                      :default-value="moment('12', 'HH')"
                      format="HH A"
                      @change="ontblMondayEndChange"
                  /></a-col>
                </a-row>
              </div>

              <h1 class="title">Target</h1>
              <!-- 城市 -->
              <div class="form-tl"><b>Location</b></div>
              <div class="include">
                <div style="width: 120px;margin-left: 11px;">
                  <a-icon type="check-circle" style="color: #52c41a" />
                  INCLUDE
                </div>
                <span style="color: #bfbfbf">|</span>
                <a-select
                  mode="multiple"
                  v-model="ob_country_value"
                  style="width: 100%"
                  placeholder="Select Country"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  option-label-prop="label"
                  @search="fetchLocation"
                  @change="handleOBLocationChange"
                  @select="handleOBLocationSelect"
                  @deselect="handleOBLocationDeSelect"
                >
                  <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                  <a-select-option
                    v-for="(item, index) in ob_country"
                    :key="index"
                    :title="item.name"
                    :id="item.id"
                    :value="item.name"
                    :label="item.name"
                    >[{{ item.geoType }}] - {{ item.name }}</a-select-option
                  >
                </a-select>
              </div>

              <!-- 设备 -->
              <div class="form-tl"><b>Device</b></div>
              <!-- tab_device -->
              <div class="include">
                <div style="width: 120px;margin-left: 11px;">
                  <a-icon type="check-circle" style="color: #52c41a" />
                  INCLUDE
                </div>
                <span style="color: #bfbfbf">|</span>
                <a-select
                  mode="multiple"
                  v-model="ob_device['value']"
                  style="width: 100%"
                  placeholder="Select Device"
                  option-label-prop="label"
                >
                  <a-select-option
                    v-for="(item, index) in ob_fields.device"
                    :key="index"
                    :value="item.name"
                    :label="item.name"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </div>

              <!-- Operating Systems -->
              <div class="form-tl"><b>Operating System</b></div>
              <div class="include">
                <div style="width: 120px;margin-left: 11px;">
                  <a-icon type="check-circle" style="color: #52c41a" />
                  INCLUDE
                </div>
                <span style="color: #bfbfbf">|</span>
                <a-select
                  mode="multiple"
                  v-model="ob_os['value']"
                  style="width: 100%"
                  placeholder="Select Operating System"
                  option-label-prop="label"
                >
                  <a-select-option
                    v-for="(item, index) in ob_fields.os"
                    :key="index"
                    :value="item.name"
                    :label="item.name"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </div>

              <!-- Browser -->
              <div class="form-tl"><b>Browser</b></div>
              <div class="include">
                <div style="width: 120px;margin-left: 11px;">
                  <a-icon type="check-circle" style="color: #52c41a" />
                  INCLUDE
                </div>
                <span style="color: #bfbfbf">|</span>
                <a-select
                  mode="multiple"
                  v-model="ob_browsers['value']"
                  style="width: 100%"
                  placeholder="Select Browsers"
                  option-label-prop="label"
                >
                  <a-select-option
                    v-for="(item, index) in ob_fields.browsers"
                    :key="index"
                    :value="item.name"
                    :label="item.name"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </div>

              <!-- Audience -->
              <!-- ob_audience -->
              <div class="form-tl"><b>Audience</b></div>
              <!-- 包含 -->
              <div class="include" style="margin-bottom: 16px;">
                <div style="width: 120px;margin-left: 11px;">
                  <a-icon type="check-circle" style="color: #52c41a" />
                  {{ ob_audience_in["type"] }}
                </div>
                <span style="color: #bfbfbf">|</span>
                <a-select
                  mode="multiple"
                  v-model="ob_audience_in['value']"
                  style="width: 100%"
                  placeholder="Select Audience"
                  option-label-prop="label"
                >
                  <a-select-option
                    v-for="(item, index) in ob_audience_list"
                    :key="index"
                    :value="item.name"
                    :label="item.name"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </div>
              <!-- 不包含 -->
              <div class="include">
                <div style="width: 120px;margin-left: 11px;">
                  <a-icon type="close-circle" style="color: #ff4d4f" />
                  {{ ob_audience_ex["type"] }}
                </div>
                <span style="color: #bfbfbf">|</span>
                <a-select
                  mode="multiple"
                  v-model="ob_audience_ex['value']"
                  style="width: 100%"
                  placeholder="Select Audience"
                  option-label-prop="label"
                >
                  <a-select-option
                    v-for="(item, index) in ob_audience_list"
                    :key="index"
                    :value="item.name"
                    :label="item.name"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </div>

              <h1 class="title">Budget and Bidding</h1>
              <!-- Daily Budget -->
              <div class="form-tl"><b>Daily Budget</b></div>
              <div class="input-number">
                <a-input-number
                  v-model="ob_daily_budget"
                  style="width: 160px"
                />
                <span class="input-txt">USD</span>
              </div>

              <!-- Monthly Budget -->
              <div class="form-tl"><b>Monthly Budget</b></div>
              <div class="input-number">
                <a-input-number
                  disabled
                  v-model="ob_monthly_budget"
                  style="width: 220px"
                />
                <span class="input-txt">USD</span>
              </div>

              <!-- Default CPC -->
              <div class="form-tl"><b>Default CPC</b></div>
              <div class="input-number">
                <a-input-number
                  v-model="ob_default_cpc"
                  style="width: 220px"
                  step="0.01"
                />
                <span class="input-txt">USD</span>
              </div>

              <!-- Bid Strategy -->
              <div class="form-tl"><b>Bid Strategy</b></div>
              <!-- <a-radio-group
                v-model="ob_bid_strategy"
                @change="onOBBidStrategyChange"
              >
                <a-radio
                  value="MAX_CONVERSION_FULLY_AUTOMATED"
                  :disabled="ob_objective != 'Conversions'"
                >
                  Fully Automatic
                </a-radio>
                <a-radio value="CPC" :disabled="ob_objective != 'Conversions'">
                  Semi Automatic
                </a-radio>
                <a-radio
                  value="TARGET_CPA_FULLY_AUTOMATED"
                  :disabled="ob_objective != 'Conversions'"
                >
                  Target CPA
                </a-radio>
                <a-radio value="MAX_CONVERSION" :disabled="ob_objective != 'Conversions'">
                  Max Conversion
                </a-radio>
              </a-radio-group> -->
              <a-radio-group v-model="ob_bid_strategy" @change="onOBBidStrategyChange">
              <a-radio value="TRAFFIC" :disabled="ob_objective != 'Conversions'">
                Traffic
              </a-radio>
              <a-radio value="CPC" :disabled="ob_objective != 'Conversions'">
                CPC
              </a-radio>
              <a-radio value="MAX_CONVERSION_FULLY_AUTOMATED" :disabled="ob_objective != 'Conversions'">
                Max Conversion Fully Automated
              </a-radio>
              <a-radio value="TARGET_CPA_FULLY_AUTOMATED" :disabled="ob_objective != 'Conversions'">
                Target CPA Fully Automated
              </a-radio>
              <a-radio value="TARGET_ROAS_FULLY_AUTOMATED" :disabled="ob_objective != 'Conversions'">
                Target ROAS Fully Automated
              </a-radio>
            </a-radio-group>
              <a-select
                v-if="ob_objective == 'Conversions'"
                mode="multiple"
                v-model="ob_conversions"
                style="width: 100%;margin-top: 16px;"
                placeholder="Supporting Conversion"
                option-label-prop="label"
                @change="onBidStrategyChange"
              >
                <a-select-option
                  v-for="(item, index) in ob_conversions_list"
                  :key="index"
                  :value="item.name"
                  :label="item.name"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
              <!-- 给后端传值！ -->
               <a-select
                v-if="ob_objective == 'Conversions' && ob_bid_strategy !== 'MAX_CONVERSION_FULLY_AUTOMATED'"
                mode="multiple"
                v-model="ob_conversions2"
                style="width: 100%;margin-top: 16px;"
                placeholder="Primary Conversion"
                option-label-prop="label"
                @change="onBidStrategyChange"
              >
                <a-select-option
                  :disabled="item.disabled"
                  v-for="(item, index) in ob_conversions_list"
                  :key="index"
                  :value="item.name"
                  :label="item.name"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
              <!-- CPC Adjustment -->
              <div v-if="ob_bid_strategy == 'CPC'">
                <div class="form-tl"><b>CPC Adjustment</b></div>
                <div class="input-number nopadding">
                  <a-input-number
                    v-model="ob_max_percentage"
                    :min="0"
                    :formatter="(value) => `${value}%`"
                    :parser="(value) => value.replace('%', '')"
                    @change="onPercentageChange"
                  />
                </div>
              </div>
              <!-- Target CPA -->
              <div v-if="ob_bid_strategy == 'TARGET_CPA_FULLY_AUTOMATED'">
                <div class="form-tl"><b>Target CPA</b></div>
                <div class="input-number">
                  <a-input-number
                    v-model="ob_target_cpa"
                    style="width: 100px"
                    step="0.01"
                  />
                  <span class="input-txt">USD</span>
                </div>
                <p style="color: #ff4d4f;font-size: 12px;margin-top:3px;">
                  Please enter a target CPA of at least 0.01$
                </p>
              </div>

              <!-- Target CPA MAX -->
              <div v-if="ob_bid_strategy == 'TARGET_CPA_FULLY_AUTOMATED'">
                <div class="form-tl"><b>Max CPC</b></div>
                <div class="input-number">
                  <a-input-number
                    v-model="ob_target_cpa_max"
                    style="width: 100px"
                    step="0.01"
                  />
                  <span class="input-txt">USD</span>
                </div>
              </div>

               <!-- Max Conversion -->
              <div v-if="ob_bid_strategy == 'MAX_CONVERSION' || ob_bid_strategy == 'MAX_CONVERSION_FULLY_AUTOMATED'" >
                <a-select
                  v-if="ob_objective == 'Conversions'"
                  mode="multiple"
                  v-model="ob_conversions2"
                  style="width: 100%;margin-top: 16px;"
                  placeholder="Primary Conversion"
                  option-label-prop="label"
                  @change="onBidStrategyChange"
                >
                  <a-select-option
                    :disabled="item.disabled"
                    v-for="(item, index) in ob_conversions_list"
                    :key="index"
                    :value="item.name"
                    :label="item.name"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
                <div class="form-tl"><b>Bid Cap</b></div>
                <div class="input-number">
                  <a-input-number
                    v-model="ob_target_cpa_max"
                    style="width: 220px"
                    step="1"
                  />
                  <span class="input-txt">USD</span>
                </div>
              </div>


              <h1 class="title">Tracking and Blocking</h1>
              <!-- Tracking Code -->
              <div class="form-tl"><b>Tracking Code</b></div>
              <a-textarea v-model="ob_tracking_code" :rows="4" />

              <!-- Block Sites -->
              <div class="form-tl"><b>Block Sites</b></div>
              <div
                class="block-item"
                v-for="(item, index) in ob_block_sites"
                :key="index"
                @click="deleteOBBlockSite(item.id)"
              >
                <a-icon type="stop" style="color: #ff4d4f" />
                <span style="flex: 1; padding: 0 8px;">{{ item.name }}</span>
                <a-icon type="close" style="color: #c8c8c8" />
              </div>
              <!-- <div class="block-item">
              <a-icon type="stop" style="color: #ff4d4f" />
              <span style="flex: 1; padding: 0 8px;">0404 - 0404</span>
              <a-icon type="close" style="color: #c8c8c8" />
            </div>
            <div class="block-item">
              <a-icon type="stop" style="color: #ff4d4f" />
              <span style="flex: 1; padding: 0 8px;">0404 - 0404</span>
              <a-icon type="close" style="color: #c8c8c8" />
            </div> -->
            </div>
            <!-- <a-button type="primary" @click="submitTaboolaData" style="margin-right: 16px;">提交到Taboola</a-button> -->
          </a-col>
        </a-row>

        <div class="section-tl" style="margin-top: 24px;margin-bottom: -8px;">
          Creative
        </div>
        <a-row :gutter="16" style="margin-top: 24px;">
          <a-col :span="12">
            <div class="form-wrapper">
              <div class="form-tl"><b>Landing Page</b></div>
              <a-input v-model="url" />
              <!-- {{url}} -->
              <div class="form-tl">
                <b>Add Title</b>
                <a-button
                  @click="showImportTitle"
                  size="small"
                  type="primary"
                  style="margin-left: 4px;"
                  >Import Title</a-button
                >
              </div>
              <!-- :disabled="item.disabled" -->
              <a-input
                v-for="(item, index) in tbl_title"
                @blur="tblCreativeTitleChange"
                :data-index="index"
                :key="index"
                style="margin-bottom: 8px;"
                prefix="+"
                v-model="item.name"
                placeholder="Click to add a title"
                ><a-icon
                  v-if="item.name"
                  @click="deleteTitle(index)"
                  slot="suffix"
                  type="close"/><a-icon
                  v-if="item.isHave"
                  slot="suffix"
                  type="exclamation-circle"
                  style="color: #ff4d4f;margin-left: 10px"
              /></a-input>
              <div class="clickadd" @click="addTitle">
                + <span class="clickadd-txt">Click to add a title</span>
              </div>
              <!-- <div class="creative" v-for="(item, index) in creative" :key="index">{{item.title}}</div> -->
              <div class="form-tl"><b>Add Image</b></div>
              <a-upload
                :action="api.PATH_API + 'api/Update/uploadImage'"
                list-type="picture-card"
                multiple
                :file-list="tbl_fileList"
                :data="{ token: userInfo.token }"
                @preview="handlePreview"
                @change="handleChange"
              >
                <div>
                  <a-icon type="plus" />
                  <div class="ant-upload-text">
                    Upload
                  </div>
                </div>
              </a-upload>
              <div style="display: flex;">
                <a-input placeholder="图片链接" v-model="new_creative_img" />
                <a-button
                  style="margin-left: 16px;"
                  type="primary"
                  @click="saveCreativeImg"
                  >确定</a-button
                >
              </div>

              <a-modal
                :visible="tbl_previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img
                  alt="example"
                  style="width: 100%"
                  :src="tbl_previewImage"
                />
              </a-modal>
              <div class="form-tl" style="margin-top: 16px;"><b>预览</b></div>
              <div class="preview-ls">
                <div
                  class="preview-item"
                  v-for="(item, index) in creative"
                  :key="index"
                >
                  <span
                    class="preview-colse"
                    @click="deletePreviewCreativeItem(index)"
                    ><a-icon type="close" style="color: #fff; font-size: 12px;"
                  /></span>
                  <div class="preview-con">
                    <img :src="item.url" alt="" />
                    <h3 class="preview-tl">{{ item.name }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="16" style="margin-top: 24px;">
          <a-col :span="12" style="text-align: center;">
            <a-button
              type="primary"
              :disabled="platform == 'outbrain'"
              @click="submitTaboolaData"
              >Taboola Submit</a-button
            >
          </a-col>
          <a-col :span="12" style="text-align: center;">
            <a-button
              :disabled="platform == 'taboola'"
              type="primary"
              @click="submitOutrainData"
              >Outbrain Submit</a-button
            >
          </a-col>
        </a-row>

        <a-modal
          title="Import Title"
          :visible="importTitleVisible"
          @ok="handleImportTitleOk"
          @cancel="handleImportTitleCancel"
        >
          <a-textarea
            v-model="importTitleValue"
            placeholder="Import Title"
            :auto-size="{ minRows: 5, maxRows: 8 }"
          />
        </a-modal>
        <a-modal
          title="编辑图片"
          :visible="img_visible"
          width="660px"
          @cancel="onClose"
        >
          <template slot="footer">
            <a-button key="back" @click="onClose">
              CANCEL
            </a-button>
            <a-button
              key="submit"
              type="primary"
              :loading="editImageLoading"
              @click="editorImageBtn"
            >
              SAVE
            </a-button>
          </template>
          <a-upload
            :action="api.PATH_API + 'api/Update/uploadImage'"
            list-type="picture-card"
            :file-list="edit_fileList"
            :data="{ token: userInfo.token }"
            @preview="handlePreview"
            @change="handleCreativeChange"
          >
            <div v-if="edit_fileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                Upload
              </div>
            </div>
          </a-upload>
          <div class="form-tl"><b>URL</b></div>
          <a-input
            v-if="platform == 'taboola'"
            @blur="creativeImgChange"
            v-model="cur_table_image"
          />
          <div v-else>{{ cur_table_image }}</div>
        </a-modal>
      </a-spin>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
const defaultSelectDate = moment()
  .startOf("day")
  .subtract(0, "days");
const defaultStartDate = moment()
  .startOf("day")
  .subtract(32, "days");
const defaultEndDate = moment()
  .startOf("day")
  .subtract(2, "days");
let initHourList = [
  {
    type: "INCLUDE",
    day: "MONDAY",
    from_hour: 0,
    until_hour: 24,
  },
  {
    type: "INCLUDE",
    day: "TUESDAY",
    from_hour: 0,
    until_hour: 24,
  },
  {
    type: "INCLUDE",
    day: "WEDNESDAY",
    from_hour: 0,
    until_hour: 24,
  },
  {
    type: "INCLUDE",
    day: "THURSDAY",
    from_hour: 0,
    until_hour: 24,
  },
  {
    type: "INCLUDE",
    day: "FRIDAY",
    from_hour: 0,
    until_hour: 24,
  },
  {
    type: "INCLUDE",
    day: "SATURDAY",
    from_hour: 0,
    until_hour: 24,
  },
  {
    type: "INCLUDE",
    day: "SUNDAY",
    from_hour: 0,
    until_hour: 24,
  },
];
const columns = [
  {
    scopedSlots: { customRender: "status" },
    width: 40,
  },
  {
    title: "Item Id",
    dataIndex: "item_id",
    width: 125,
    ellipsis: true,
  },
  {
    title: "Approve Status",
    dataIndex: "approve_status",
    width: 125,
  },
  {
    title: "Image",
    dataIndex: "image",
    scopedSlots: { customRender: "image" },
    align: "center",
    width: 125,
  },
  {
    title: "Title",
    dataIndex: "title",
    width: 500,
    scopedSlots: { customRender: "pltitle" },
  },
  {
    title: "Avg cpc",
    dataIndex: "avg_cpc",
    align: "right",
    width: 125,
  },
  {
    title: "Clicks",
    dataIndex: "clicks",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    width: 125,
  },
  {
    title: "CTR",
    dataIndex: "ctr",
    align: "right",
    width: 125,
  },
  {
    title: "Impressions",
    dataIndex: "impressions",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    width: 125,
  },
  {
    title: "Spent",
    dataIndex: "spent",
    align: "right",
    customRender: (text) => {
      return Math.round(text)
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    // width: 125
  },
  {
    title: "Detail",
    scopedSlots: { customRender: "action" },
    align: "center",
    fixed: "right",
    width: 125,
  },
  // tbl可删除
];

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  data() {
    return {
      importTitleValue: "",
      importTitleVisible: false,
      api,
      template_title: "",
      inputImgList: [],
      new_creative_img: "",
      editImageLoading: false,
      ediTtitleLoading: false,
      curtable_image_id: 0,
      pagespinning: false,
      creativeloading: false,
      tbl_end_date: "",
      tbl_account: "Select an account",
      initHourList,
      userInfo: {},
      edit_fileList: [
        {
          uid: "-1",
          name: "image",
          status: "done",
          url: "",
        },
      ], //编辑图片
      cur_edit_image_index: 0,
      img_visible: false,
      curtable_id: 0,
      cur_table_tl: "",
      cur_table_image: "",
      columns,
      moment,
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      creativeList: [],
      platform: "",
      cur_campaign_id: 0,
      campaign_type: "",
      url: "",
      tbl_previewVisible: false,
      tbl_previewImage: "",
      tbl_fileList: [
        // {
        //   uid: '-1',
        //   name: 'image.png',
        //   status: 'done',
        //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
        // {
        //   uid: '-2',
        //   name: 'image.png',
        //   status: 'done',
        //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
        // {
        //   uid: '-3',
        //   name: 'image.png',
        //   status: 'error',
        // },
      ],
      tbl_title: [
        {
          name: "",
          // disabled: false,
          isHave: false,
        },
        {
          name: "",
          // disabled: true,
          isHave: false,
        },
        {
          name: "",
          // disabled: true,
          isHave: false,
        },
      ],
      // tbl_title: [],
      tbl_checkhave: false,
      tbl_template: [], //tbl可选择的模版
      tbl_fields: {},
      tbl_campaign_name: "", //tbl name
      tbl_brand_text: "",
      tbl_country: {
        type: "INCLUDE",
        value: [],
      },
      tbl_device: {
        type: "INCLUDE",
        value: [],
      },
      tbl_audience_in: {
        type: "INCLUDE",
        value: [],
      },
      tbl_audience_ex: {
        type: "EXCLUDE",
        value: [],
      },
      tbl_audience_list: [],
      tbl_os: {
        type: "INCLUDE",
        value: [],
      },
      tbl_browsers: {
        type: "INCLUDE",
        value: [],
      },
      creative: [],
      tbl_daily_budget: 30,
      tbl_monthly_budget: "3000",
      tbl_default_cpc: 0,
      tbl_bid_strategy: "",
      tbl_bid_type: "",
      tbl_start_date_raido: 1,
      tbl_start_date: defaultSelectDate.format("YYYY-MM-DD"),
      tbl_tracking_code: "",
      tbl_block_sites: {},
      tbl_start_hour: {
        mode: "ALWAYS",
        rules: [],
        time_zone: "US/Pacific",
      },
      tbl_site_bid: [],
      tbl_objective: "DRIVE_WEBSITE_TRAFFIC",
      ob_account: "Select an account",
      ob_previewVisible: false,
      ob_previewImage: "",
      ob_fileList: [],
      ob_title: [],
      ob_checkhave: false,
      ob_template: [],
      ob_fields: {},
      ob_campaign_name: "",
      ob_brand_name: "",
      ob_country_value: [],
      ob_device: {
        type: "INCLUDE",
        value: [],
      },
      ob_audience_in: {
        type: "INCLUDE",
        value: [],
      },
      ob_audience_ex: {
        type: "EXCLUDE",
        value: [],
      },
      ob_audience_list: [],
      ob_os: {
        type: "INCLUDE",
        value: [],
      },
      ob_browsers: {
        type: "INCLUDE",
        value: [],
      },
      ob_creative: [],
      ob_daily_budget: 30,
      ob_monthly_budget: "3000",
      ob_default_cpc: 0,
      ob_bid_strategy: "",
      ob_start_date_raido: 1,
      ob_start_date: defaultSelectDate.format("YYYY-MM-DD"),
      ob_start_hour: 1,
      ob_objective: "Traffic",
      ob_hourList: [
        {
          name: "Monday",
          checked: "checked",
        },
        {
          name: "Tuesday",
          checked: "checked",
        },
        {
          name: "Wednesday",
          checked: "checked",
        },
        {
          name: "Thursday",
          checked: "checked",
        },
        {
          name: "Friday",
          checked: "checked",
        },
        {
          name: "Saturday",
          checked: "checked",
        },
        {
          name: "Sunday",
          checked: "checked",
        },
      ],
      ob_tracking_code: "",
      ob_block_sites: [],
      ob_max_percentage: 100,
      ob_target_cpa: 0,
      ob_target_cpa_max: 0,
      ob_site_bid: [],
      fetching: false,
      ob_country: [],
      ob_all_country: [],
      ob_end_date: "",
      ob_block_publishers: [],
      ob_conversions: [],
      ob_conversions2: [],
      ob_conversions_list: [],
      ob_bid_strategy_sum: 3,
      ob_section_name: "",
      defaultStartDate,
      defaultEndDate,
      dateString: [
        defaultStartDate.format("YYYY-MM-DD"),
        defaultEndDate.format("YYYY-MM-DD"),
      ],
      also_duplicate: "is_block_sites,is_tracking_code",
    };
  },
  methods: {
    duplicateChange(e) {
      console.log(e);
      this.also_duplicate = e.join(",");
      console.log(e);
    },
    handleImportTitleOk() {
      this.tbl_title = [];
      let importTitle = this.importTitleValue.split("\n");
      importTitle.forEach((item) => {
        this.tbl_title.push({ name: item, isHave: false });
      });
      this.importTitleVisible = false;
    },
    handleImportTitleCancel() {
      this.importTitleVisible = false;
    },
    showImportTitle() {
      this.importTitleVisible = true;
    },
    onDateChange(date, dateString) {
      this.dateString = dateString;
      console.log(dateString);
    },
    // 获取creative列表
    getDateChange() {
      this.creativeloading = true;
      this.$post(api.update.getCreativeList, {
        platform: this.platform,
        token: this.userInfo.token,
        period1: JSON.stringify([this.dateString[0], this.dateString[1]]),
        campaign_id: this.cur_campaign_id,
      }).then((res) => {
        this.creativeloading = false;
        if (res.data.status === 200) {
          let data = res.data.data;
          // console.log(data.creative_list);
          this.creativeList = data.creative_list;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    onPercentageChange(value) {
      this.ob_max_percentage = value;
    },
    handleChange({ fileList }) {
      this.creative = [];
      this.tbl_fileList = fileList;
      //重新获取预览数据
      this.tbl_title.forEach((item) => {
        if (item.name) {
          this.tbl_fileList.forEach((img) => {
            if (img.status == "done") {
              this.creative.push({ name: item.name, url: img.response.data });
            }
          });
        }
      });

      this.tbl_title.forEach((item) => {
        if (item.name) {
          this.inputImgList.forEach((a) => {
            this.creative.push({ name: item.name, url: a });
          });
        }
      });
    },
    saveCreativeImg() {
      console.log(this.new_creative_img);
      this.inputImgList.push(this.new_creative_img);
      console.log(this.inputImgList);
      // this.tbl_fileList.push({uid: '-1', name: 'image.png', status: 'done', response.data: this.new_creative_img})
      if (this.tbl_title.length) {
        this.tbl_title.forEach((item) => {
          if (item.name) {
            this.creative.push({ name: item.name, url: this.new_creative_img });
          }
        });
      }
    },
    // 修改creative状态（play/pause）
    onStatusChange(is_active, id) {
      let operate = "";
      if (is_active == 1) {
        operate = "pause";
      } else {
        operate = "play";
      }
      this.$post(api.update.updateCreative, {
        token: this.userInfo.token,
        params: {
          campaign_id: this.cur_campaign_id,
          item_id: id,
          operate: operate,
        },
      }).then((res) => {
        this.editImageLoading = false;
        if (res.data.status != 200) {
          this.$message.error(res.data.message);
        }
      });
    },
    submitOutrainData() {
      this.pagespinning = true;
      let audience = {};
      let newaudience_in = [];
      let newaudience_ex = [];
      let newBrowsers = {
        type: "ALL",
        value: [],
      };
      let newOs = {
        type: "ALL",
        value: [],
      };
      if (this.ob_browsers.value.length != 0) {
        newBrowsers.type = "INCLUDE";
        this.ob_browsers.value.forEach((item) => {
          this.ob_fields.browsers.forEach((a) => {
            if (a.name == item) {
              newBrowsers.value.push(a.value);
            }
          });
        });
      }
      if (this.ob_os.value.length != 0) {
        newOs.type = "INCLUDE";
        this.ob_os.value.forEach((item, index) => {
          this.ob_fields.os.forEach((a) => {
            if (a.name == item) {
              newOs.value[index] = { os_family: a.value, sub_categories: [] };
            }
          });
        });
      }

      if (this.ob_audience_in.value.length) {
        this.ob_audience_in.value.forEach((item) => {
          this.ob_audience_list.forEach((b) => {
            if (b.name == item) {
              newaudience_in.push(b.value);
            }
          });
        });
        audience.includedSegments = newaudience_in;
      }
      if (this.ob_audience_ex.value.length) {
        this.ob_audience_ex.value.forEach((item) => {
          this.ob_audience_list.forEach((b) => {
            if (b.name == item) {
              newaudience_ex.push(b.value);
            }
          });
        });
        audience.excludedSegments = newaudience_ex;
      }

      //Device
      let newDevice = {
        type: this.ob_device.type,
        value: [],
      };
      if (this.ob_device.value) {
        this.ob_device.value.forEach((item) => {
          console.log("====" + item);
          this.ob_fields.device.forEach((a) => {
            if (a.name == item) {
              console.log("--------" + a);
              newDevice.value.push(a.value);
            }
          });
        });
      }

      let newConversions = [];
      this.ob_conversions.forEach((item) => {
        this.ob_conversions_list.forEach((a) => {
          if (a.name == item) {
            newConversions.push(a.value);
          }
        });
      });

      let newConversions2 = [];
      this.ob_conversions2.forEach((item) => {
        this.ob_conversions_list.forEach((a) => {
          if (a.name == item) {
            newConversions2.push(a.value);
          }
        });
      });

      let newCountry = [];
      this.ob_all_country.forEach((item) => {
        newCountry.push(item.id);
      });

      if (!this.also_duplicate.includes("is_site_bid")) {
        this.ob_site_bid = [];
      }
      if (!this.also_duplicate.includes("is_block_sites")) {
        this.ob_block_publishers = [];
        this.ob_block_sites = [];
      }
      if (!this.also_duplicate.includes("is_tracking_code")) {
        this.ob_tracking_code = "";
      }
      let params = {
        account: this.ob_account,
        audience: audience,
        bid_strategy: this.ob_bid_strategy,
        block_sites: this.ob_block_sites,
        browsers: newBrowsers,
        campaign_name: this.ob_campaign_name,
        country: newCountry,
        daily_budget: this.ob_daily_budget,
        default_cpc: this.ob_default_cpc,
        device: newDevice,
        os: newOs,
        date: { start_date: this.ob_start_date, end_date: this.ob_end_date },
        tracking_code: this.ob_tracking_code,
        start_hour: this.ob_start_hour,
        objective: this.ob_objective,
        max_percentage: (this.ob_max_percentage / 100).toFixed(2),
        target_cpa: this.ob_target_cpa,
        target_cpa_max: this.ob_target_cpa_max,
        conversions: newConversions,
        primary_conversion: newConversions2,
        site_bid: this.ob_site_bid,
        block_publishers: this.ob_block_publishers,
        landing_page: this.url,
      };
      // if(this.ob_bid_strategy !== 'TARGET_CPA_FULLY_AUTOMATED' || this.ob_bid_strategy !== 'MAX_CONVERSION_FULLY_AUTOMATED'){
      //   delete params.target_cpa_max;
      // }
      let reqparams = {
        platform: "outbrain",
        params: params,
        token: this.userInfo.token,
      };

      if (this.campaign_type == "relaunch") {
        reqparams.campaign_id = this.cur_campaign_id;
        reqparams.operate_type = "relaunch";
      }

      let dataApi = api.update.campaignCreateAndUpdate;
      if (this.campaign_type == "optimization") {
        dataApi = api.filter.updateCampaign;
        reqparams.campaign_id = this.cur_campaign_id;
        reqparams.operate_type = "optimize";
      }

      if (this.ob_default_cpc > 0.12) {
        this.$confirm({
          title: "Please Notice！",
          content: "Your Default CPC is over 0.12, Doublecheck Before Change.",
          okText: "Save",
          onOk: () => {
            this.requestOubtainData(dataApi, reqparams);
          },
          onCancel: () => {
            this.pagespinning = false;
          },
        });
      } else {
        this.requestOubtainData(dataApi, reqparams);
      }
    },
    requestOubtainData(dataApi, reqparams) {
      this.$post(dataApi, reqparams).then((res) => {
        if (res.data.status == 200) {
          let campaign_id = res.data.data.campaign_id;
          let newCreative = [];
          this.creative.forEach((item) => {
            let creative_item = {
              title: item.name,
              thumbnail_url: item.url,
              section_name: this.ob_section_name,
            };
            newCreative.push(creative_item);
          });

          if (this.campaign_type == "relaunch" && this.creativeList.length) {
            this.creativeList.forEach((item) => {
              let creative_item = {
                title: item.title,
                thumbnail_url: item.image,
                section_name: this.ob_section_name,
              };
              newCreative.push(creative_item);
            });
          }

          // campaign层面操作成功
          this.$notification.success({
            message: res.data.title,
            description: res.data.message,
            duration: 0,
          });

          if (newCreative.length) {
            // Outbrain的Creativ新建
            const requestData = {
              campaign_id: campaign_id,
              landing_page: this.url,
              params: newCreative,
              token: this.userInfo.token,
            };
            // const hasFallbackUrl = this.creativeList.some(item => item.fallback_url);
            //   if (hasFallbackUrl) {
            //     requestData.gif_campaign_id = this.cur_campaign_id;
            //   }
            this.$post(api.update.createCreative, requestData).then((res) => {
              this.pagespinning = false;
              if (res.data.status == 200) {
                this.$message.success(res.data.message);
                this.$notification.success({
                  message: res.data.title,
                  description: res.data.message,
                  duration: 0,
                });
              } else {
                this.$message.error(res.data.message);
                this.$notification.error({
                  message: res.data.title,
                  description: res.data.message,
                  duration: 0,
                });
              }
            });
          } else {
            this.pagespinning = false;
          }
        } else {
          this.pagespinning = false;
          this.$message.error(res.data.message);
          this.$notification.error({
            message: res.data.title,
            description: res.data.message,
            duration: 0,
          });
        }
      });
    },
    submitTaboolaData() {
      this.pagespinning = true;
      let newCountry, audience, newaudience_in, newaudience_ex;

      let newBrowsers = {
        type: "ALL",
        value: [],
      };
      let newOs = {
        type: "ALL",
        value: [],
      };
      if (this.tbl_browsers.value.length != 0) {
        newBrowsers = this.tbl_browsers;
      }
      if (this.tbl_os.value.length != 0) {
        newOs.type = this.tbl_os.type;
        this.tbl_os.value.forEach((item, index) => {
          newOs.value[index] = { os_family: item, sub_categories: [] };
        });
      }

      //Device
      let newDevice = {
        type: this.tbl_device.type,
        value: [],
      };
      if (this.tbl_device.value) {
        this.tbl_device.value.forEach((item) => {
          this.tbl_fields.device.forEach((a) => {
            if (a.name == item) {
              newDevice.value.push(a.value);
            }
          });
        });
      }

      newCountry = {
        type: this.tbl_country.type,
        value: [],
      };
      if (this.tbl_country.value) {
        this.tbl_country.value.forEach((item) => {
          this.tbl_fields.country.forEach((a) => {
            if (a.name == item) {
              newCountry.value.push(a.value);
            }
          });
        });
      }
      audience = [];
      newaudience_in = {
        type: "",
        collection: [],
      };
      newaudience_ex = {
        type: "",
        collection: [],
      };
      if (this.tbl_audience_in.value.length) {
        newaudience_in.type = "INCLUDE";
        this.tbl_audience_in.value.forEach((item) => {
          console.log(item);
          this.tbl_audience_list.forEach((b) => {
            if (b.name == item) {
              console.log(b.value);
              newaudience_in.collection.push(b.value);
            }
          });
        });
        audience.push(newaudience_in);
      }

      if (this.tbl_audience_ex.value.length) {
        newaudience_ex.type = "EXCLUDE";
        this.tbl_audience_ex.value.forEach((item) => {
          console.log(item);
          this.tbl_audience_list.forEach((b) => {
            if (b.name == item) {
              console.log(b.value);
              newaudience_ex.collection.push(b.value);
            }
          });
        });
        audience.push(newaudience_ex);
      }

      if (!this.also_duplicate.includes("is_site_bid")) {
        this.tbl_site_bid = [];
      }
      if (!this.also_duplicate.includes("is_block_sites")) {
        this.tbl_block_sites = [];
      }
      if (!this.also_duplicate.includes("is_tracking_code")) {
        this.tbl_tracking_code = "";
      }

      let params = {
        account: this.tbl_account,
        audience: audience,
        bid_strategy: this.tbl_bid_strategy,
        // bid_type: this.tbl_bid_type,
        block_sites: this.tbl_block_sites,
        brand_text: this.tbl_brand_text,
        browsers: newBrowsers,
        campaign_name: this.tbl_campaign_name,
        objective: this.tbl_objective,
        country: newCountry,
        daily_budget: this.tbl_daily_budget,
        default_cpc: this.tbl_default_cpc,
        device: newDevice,
        monthly_budget: this.tbl_monthly_budget,
        os: newOs,
        date: { start_date: this.tbl_start_date, end_date: this.tbl_end_date },
        tracking_code: this.tbl_tracking_code,
        start_hour: this.tbl_start_hour,
        landing_page: this.url,
        site_bid: this.tbl_site_bid,
      };
        if (this.tbl_default_cpc === '0.000') {
        delete params.default_cpc;
      }

      let reqparams = {
        platform: "taboola",
        params: params,
        token: this.userInfo.token,
      };

      if (this.campaign_type == "relaunch") {
        reqparams.campaign_id = this.cur_campaign_id;
        reqparams.operate_type = "relaunch";
      }
      let dataApi = api.update.campaignCreateAndUpdate;
      if (this.campaign_type == "optimization") {
        dataApi = api.filter.updateCampaign;
        reqparams.campaign_id = this.cur_campaign_id;
        reqparams.operate_type = "optimize";
      }
      if (this.tbl_default_cpc > 0.12) {
        this.$confirm({
          title: "Please Notice!",
          content: "Your Default CPC is over 0.12, Doublecheck Before Change.",
          okText: "Save",
          onOk: () => {
            this.requestTaboolaData(dataApi, reqparams);
          },
          onCancel: () => {
            console.log("Cancel");
            this.pagespinning = false;
          },
        });
      } else {
        this.requestTaboolaData(dataApi, reqparams);
      }
    },
    requestTaboolaData(dataApi, reqparams) {
      this.$post(dataApi, reqparams).then((res) => {
        console.log(res);
        if (res.data.status == 200) {
          let campaign_id = res.data.data.campaign_id;
          console.log(campaign_id);

          let newCreative = [];
          if (this.creative.length) {
            this.creative.forEach((item) => {
              let creative_item = { 
                title: item.name, 
                thumbnail_url: item.url,
                fallback_url: item.fallback_url,
                video_url: item.video_url,
               };
              newCreative.push(creative_item);
            });
          }
          if (this.campaign_type == "relaunch") {
            if (this.creativeList.length) {
              this.creativeList.forEach((item) => {
                let creative_item = {
                  title: item.title,
                  thumbnail_url: item.image,
                  fallback_url: item.fallback_url,
                  video_url: item.video_url,
                };
                newCreative.push(creative_item);
              });
            }
          }
          if (newCreative.length) {
            this.getTblCreativeSum(campaign_id, newCreative);
          } else {
            this.pagespinning = false;
            this.$message.success(res.data.message);
            this.$notification.success({
              message: res.data.title,
              description: res.data.message,
              duration: 0,
            });
          }
        } else {
          this.pagespinning = false;
          this.$message.error(res.data.message);
          this.$notification.error({
            message: res.data.title,
            description: res.data.message,
            duration: 0,
          });
        }
      });
    },
    getTblCreativeSum(campaign_id, newCreative) {
      // Taboola creative初始化（分两步，第一步新建creative，第二步修改creative）
      const requestData = {
              campaign_id: campaign_id,
              landing_page: this.url,
              creative_num: newCreative.length,
              token: this.userInfo.token,
            };
            const hasFallbackUrl = this.creativeList.some(item => item.fallback_url);
            if (hasFallbackUrl) {
              requestData.gif_campaign_id = this.cur_campaign_id;
            }
            console.log(requestData);
      this.$post(api.update.createCreative, requestData).then((res) => {
        if (res.data.status == 200) {
          newCreative.forEach((item, index) => {
            item.item_id = res.data.data[index].item_id;
          });
          this.$notification.success({
            message: res.data.title,
            description: res.data.message,
            duration: 0,
          });
          // Taboola creative初始化（分两步，第一步新建creative，第二步修改creative）
          this.$post(api.update.updateTblCreative, {
            campaign_id: campaign_id,
            params: newCreative,
            token: this.userInfo.token,
          }).then((res2) => {
            this.pagespinning = false;
            if (res2.data.status == 200) {
              this.$notification.success({
                message: res2.data.title,
                description: res2.data.message,
                duration: 0,
              });
            } else {
              this.$notification.error({
                message: res2.data.title,
                description: res2.data.message,
                duration: 0,
              });
            }
          });
        } else {
          this.pagespinning = false;
          this.$message.error(res.data.message);
          this.$notification.error({
            message: res.data.title,
            description: res.data.message,
            duration: 0,
          });
        }
      });
    },
    onBidStrategyChange(value) {
      if (value.length == this.ob_bid_strategy_sum) {
        this.ob_conversions_list.forEach((a) => {
          a.disabled = true;
        });
        value.forEach((item) => {
          console.log(item);
          this.ob_conversions_list.forEach((a) => {
            if (a.name == item) a.disabled = false;
          });
        });
        console.log(this.conversions_list);
      } else {
        this.ob_conversions_list.forEach((a) => {
          a.disabled = false;
        });
      }
      console.log(`selected ${value}`);
    },
    onHourRadioChange(e) {
      this.tbl_start_hour.mode = e.target.value;
      this.tbl_start_hour.rules = initHourList;
      console.log(e.target.value);
    },
    tblHourDayChange(index) {
      this.tbl_start_hour.rules[index].type == "INCLUDE"
        ? (this.tbl_start_hour.rules[index].type = "EXCLUDE")
        : (this.tbl_start_hour.rules[index].type = "INCLUDE");
    },
    handleOBLocationDeSelect(value) {
      console.log(value);
      let new_ob_all_country = [];
      this.ob_all_country.forEach((item) => {
        if (item.name != value) {
          new_ob_all_country.push(item);
        }
      });
      this.ob_all_country = new_ob_all_country;
      console.log(this.ob_all_country);
    },
    handleOBLocationSelect(value, e) {
      let id = e.data.attrs.id;
      this.ob_all_country.push({ id: id, name: value });
      console.log(this.ob_all_country);
    },
    handleOBLocationChange(value) {
      Object.assign(this, {
        value,
        ob_country: [],
        fetching: false,
      });
    },
    fetchLocation(value) {
      console.log("fetching user", value);
      this.ob_country = [];
      this.fetching = true;
      if (value.length >= 2) {
        this.$post(api.update.getObCountry, {
          term: value,
          token: this.userInfo.token,
        }).then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.ob_country = res.data.data;
            this.fetching = false;
          }
        });
      }
    },
    deleteTBLBlockSite(blocksite) {
      this.tbl_block_sites.value.forEach((item, index) => {
        if (item == blocksite) {
          this.tbl_block_sites.value.splice(index, 1);
        }
      });
    },
    deleteOBBlockSite(blocksite) {
      this.ob_block_sites.forEach((item, index) => {
        if (item.id == blocksite) {
          this.ob_block_sites.splice(index, 1);
        }
      });
    },
    onOBBidStrategyChange() {
      this.ob_conversions2 = [];
      if (this.ob_bid_strategy == "TARGET_CPA_FULLY_AUTOMATED") {
        this.ob_conversions_list.forEach((a) => {
          a.disabled = false;
        });
        this.ob_conversions = [];
        this.ob_bid_strategy_sum = 1;
      } else {
        this.ob_bid_strategy_sum = 3;
      }
      console.log(this.ob_bid_strategy_sum);
    },
    afterVisibleChange(val) {
      console.log("visible", val);
    },
    creativeImgChange() {
      console.log(this.cur_table_image);
      if (!this.cur_table_image) {
        this.edit_fileList = [
          {
            uid: "-1",
            name: "image",
            status: "done",
            url: "",
          },
        ];
      } else {
        this.edit_fileList[0].url = this.cur_table_image;
      }
      console.log(this.edit_fileList);
    },
    showEditImg(image, id) {
      this.cur_table_image = image;
      this.img_visible = true;
      this.edit_fileList[0].url = image; //预览图
      this.curtable_image_id = id;
      this.creativeList.forEach((item, index) => {
        if (item.item_id == id) {
          this.cur_edit_image_index = index;
        }
      });
    },
    editorImageBtn() {
      if (this.campaign_type == "optimization") {
        this.editImageLoading = true;
        this.$post(api.update.updateCreative, {
          token: this.userInfo.token,
          params: {
            campaign_id: this.cur_campaign_id,
            item_id: this.curtable_image_id,
            image: this.cur_table_image,
          },
        }).then((res) => {
          console.log(res);
          this.editImageLoading = false;
          if (res.data.status == 200) {
            console.log(res);
            this.$message.success(res.data.message);
            this.creativeList[
              this.cur_edit_image_index
            ].image = this.cur_table_image;
            this.onClose();
          } else {
            this.$message.error(res.data.message);
          }
        });
      } else {
        this.creativeList[
          this.cur_edit_image_index
        ].image = this.cur_table_image;
        this.onClose();
      }
    },
    onClose() {
      this.img_visible = false;
    },
    deleteCreativeItem(id) {
      if (this.campaign_type == "optimization") {
        if (this.platform == "outbrain") {
          this.$message.error("outbrain不提供删除");
          return false;
        } else {
          this.$post(api.update.deleteCreative, {
            campaign_id: this.cur_campaign_id,
            item_id: id,
            token: this.userInfo.token,
          }).then((res) => {
            console.log(res);
            if (res.data.status == 200) {
              this.creativeList.forEach((item, index) => {
                if (item.item_id == id) {
                  this.creativeList.splice(index, 1);
                }
              });
            }
          });
        }
      } else {
        this.creativeList.forEach((item, index) => {
          if (item.item_id == id) {
            this.creativeList.splice(index, 1);
          }
        });
      }
    },
    noEditorBtn() {
      this.curtable_id = "";
    },
    editorBtn() {
      if (this.campaign_type == "optimization") {
        this.ediTtitleLoading = true;
        this.$post(api.update.updateCreative, {
          token: this.userInfo.token,
          params: {
            campaign_id: this.cur_campaign_id,
            item_id: this.curtable_id,
            title: this.cur_table_tl,
          },
        }).then((res) => {
          this.ediTtitleLoading = false;
          if (res.data.status == 200) {
            this.$message.success("修改成功");
            this.creativeList.forEach((item) => {
              if (item.item_id == this.curtable_id) {
                item.title = this.cur_table_tl;
              }
            });
            this.curtable_id = "";
          } else {
            this.$message.error("修改失败");
          }
        });
      } else {
        this.creativeList.forEach((item) => {
          if (item.item_id == this.curtable_id) {
            item.title = this.cur_table_tl;
          }
        });
        this.curtable_id = "";
      }
    },
    editorTitle(id, title) {
      if (this.platform == "outbrain") {
        return false;
      }
      this.curtable_id = id;
      this.cur_table_tl = title;
      console.log(title);
      console.log(id);
    },

    handleCancel() {
      this.tbl_previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.tbl_previewImage = file.url || file.preview;
      console.log(this.tbl_previewImage);
      this.tbl_previewVisible = true;
    },

    handleCreativeChange({ fileList }) {
      console.log(fileList);
      if (fileList.length == 0) this.cur_table_image = "";
      this.edit_fileList = fileList;
      if (fileList.length && fileList[0].status == "done") {
        this.cur_table_image = fileList[0].response.data;
      }

      console.log(this.edit_fileList);
    },
    deleteTitle(index) {
      console.log(this.tbl_fileList);
      this.creative = [];
      console.log(index);
      this.tbl_title.splice(index, 1);

      //重新获取预览数据
      this.tbl_title.forEach((item) => {
        if (item.name) {
          this.tbl_fileList.forEach((img) => {
            this.creative.push({ name: item.name, url: img.response.data });
          });
        }
      });

      this.tbl_title.forEach((item) => {
        if (item.name) {
          this.inputImgList.forEach((img) => {
            this.creative.push({ name: item.name, url: img });
          });
        }
      });

      console.log(this.creative);
    },
    addTitle() {
      if (
        !this.tbl_checkhave &&
        this.tbl_title[this.tbl_title.length - 1].name
      ) {
        this.tbl_title.push({ name: "", isHave: false });
      }
    },
    tblCreativeTitleChange(e) {
      let nindex = e.target.dataset.index;
      let value = e.target.value;
      console.log(this.tbl_title);
      this.tbl_title.forEach((item, index) => {
        if (item.name && nindex != index && value == item.name) {
          //name不为空 且 不是当前的输入框 且 现有输入框的值等于已有的name
          item.isHave = true;
          this.tbl_title[nindex].isHave = true;
          this.tbl_checkhave = true;
        } else if (item.name && nindex != index && value != item.name) {
          //name不为空 且 不是当前的输入框 且 现有输入框的值不等于等于已有的name
          item.isHave = false;
          this.tbl_title[nindex].isHave = false;
          this.tbl_checkhave = false;
        }
      });
      if (!this.tbl_checkhave && value) {
        this.creative = [];
        //重新获取预览数据
        this.tbl_title.forEach((item) => {
          if (item.name) {
            this.tbl_fileList.forEach((img) => {
              this.creative.push({ name: item.name, url: img.response.data });
            });
          }
        });
      }

      console.log(this.tbl_title);
    },
    onCampaignObjectiveChange(e) {
      console.log(e);
      if (e.target.value == "Conversions") {
        this.ob_bid_strategy = "MAX_CONVERSION_FULLY_AUTOMATED";
      }
    },
    deletePreviewCreativeItem(index) {
      this.creative.splice(index, 1);
    },
    //获取默认数据
    tblTemplateChange(e) {
      this.pagespinning = true;
      this.tbl_os.value = [];
      this.tbl_audience_in.value = [];
      this.tbl_audience_ex.value = [];
      this.tbl_block_sites = {};
      this.$post(api.update.getCampaignInfo, {
        campaign_id: e,
        token: this.userInfo.token,
      }).then((res) => {
        if (res.data.status === 200) {
          this.pagespinning = false;
          let data = res.data.data;
          this.tbl_bid_strategy = data.bid_strategy;
          // this.tbl_bid_type = data.bid_type;
          if (
            this.campaign_type &&
            !data.campaign_name.toLowerCase().includes("template")
          ) {
            this.tbl_campaign_name = data.campaign_name;
          } else {
            //替换掉 template Template new template
            let campaign_name = data.campaign_name;
            let startIndex = campaign_name.indexOf("-");
            let endIndex = campaign_name.indexOf("-", startIndex + 1);
            this.tbl_campaign_name =
              campaign_name.slice(0, startIndex + 2) +
              this.template_title +
              campaign_name.slice(endIndex - 1);
          }

          this.tbl_brand_text = data.brand_text;
          this.tbl_site_bid = data.site_bid;
          this.tbl_account = data.account;
          this.tbl_fields.account.forEach((item) => {
            if (item.value == data.account) {
              this.tbl_template = item.template;
            }
          });
          if (this.platform) {
            this.url = data.landing_page || this.url;
          }
          let newCountryValue = [];
          if (data.country.value.length) {
            data.country.value.forEach((item) => {
              this.tbl_fields.country.forEach((itemchild) => {
                if (itemchild.value == item) {
                  newCountryValue.push(itemchild.name);
                }
              });
            });
          }
          this.tbl_country.value = newCountryValue;
          this.tbl_country.type = data.country.type;

          console.log("------" + JSON.stringify(this.tbl_country));

          if (data.os.type == "ALL") {
            this.tbl_os.type = "INCLUDE";
          } else {
            this.tbl_os = data.os;
          }

          if (data.browsers.type == "ALL") {
            this.tbl_browsers.type = "INCLUDE";
            this.tbl_browsers.value = [];
          } else {
            this.tbl_browsers = data.browsers;
          }

          this.tbl_daily_budget = data.daily_budget;
          console.log(this.tbl_daily_budget);

          this.tbl_monthly_budget = data.monthly_budget;
          this.tbl_objective = data.objective;
          this.tbl_default_cpc = data.default_cpc;
          this.tbl_bid_strategy = data.bid_strategy;
          // this.tbl_bid_type = data.bid_type;

          let newDeviceValue = [];
          if (data.device.value.length) {
            data.device.value.forEach((item) => {
              this.tbl_fields.device.forEach((itemchild) => {
                if (itemchild.value == item) {
                  newDeviceValue.push(itemchild.name);
                }
              });
            });
          }
          this.tbl_device.value = newDeviceValue;
          this.tbl_device.type = data.device.type;

          this.tbl_audience_list = this.tbl_fields.audience[data.account];
          if (data.audience) {
            data.audience.forEach((item) => {
              if (item.type == "INCLUDE") {
                item.collection.forEach((a) => {
                  this.tbl_audience_list.forEach((b) => {
                    if (b.value == a) {
                      this.tbl_audience_in.value.push(b.name);
                    }
                  });
                });
              } else {
                item.collection.forEach((a) => {
                  this.tbl_audience_list.forEach((b) => {
                    if (b.value == a) {
                      this.tbl_audience_ex.value.push(b.name);
                    }
                  });
                });
              }
            });
          }

          this.tbl_audience = data.tbl_audience;
          this.tbl_tracking_code = data.tracking_code;
          this.tbl_block_sites = data.block_sites;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    obTemplateChange(e) {
      this.pagespinning = true;
      console.log(e);
      this.ob_all_country = [];
      this.ob_country_value = [];
      this.ob_device.value = [];
      this.ob_block_sites = [];
      this.ob_os.value = [];
      this.ob_browsers.value = [];
      this.ob_audience_in.value = [];
      this.ob_audience_ex.value = [];
      this.ob_conversions_list = [];
      this.ob_conversions = [];
      this.ob_section_name = this.ob_fields.account[0].section_name;
      console.log("ob_section_name:" + this.ob_section_name);
      this.$post(api.update.getCampaignInfo, {
        campaign_id: e,
        token: this.userInfo.token,
      }).then((res) => {
        if (res.data.status === 200) {
          console.log(res);
          this.pagespinning = false;
          let data = res.data.data;
          console.log("campaign_name:" + data.campaign_name);
          if (
            this.campaign_type &&
            !data.campaign_name.toLowerCase().includes("template")
          ) {
            this.ob_campaign_name = data.campaign_name;
          } else {
            let campaign_name = data.campaign_name;
            let startIndex = campaign_name.indexOf("-");
            let endIndex = campaign_name.indexOf("-", startIndex + 1);
            this.ob_campaign_name =
              campaign_name.slice(0, startIndex + 2) +
              this.template_title +
              campaign_name.slice(endIndex - 1);
          }

          this.ob_target_cpa = data.target_cpa;
          this.ob_target_cpa_max = data.target_cpa_max;
          this.ob_brand_name = data.brand_text;
          this.ob_account = data.account;
          this.ob_fields.account.forEach((item) => {
            if (item.value == data.account) {
              this.ob_template = item.template;
            }
          });
          if (this.platform) {
            this.url = data.landing_page || this.url;
          }

          if (data.country.length) {
            data.country.forEach((item) => {
              this.ob_all_country.push({ id: item.id, name: item.name });
              this.ob_country_value.push(item.name);
            });
          }
          if (data.os.type == "ALL") {
            this.ob_os.type = "INCLUDE";
          } else {
            this.ob_os = data.os;
          }

          if (data.browsers.type == "ALL") {
            this.ob_browsers.type = "INCLUDE";
            this.ob_browsers.value = [];
          } else {
            this.ob_browsers = data.browsers;
          }

          this.ob_daily_budget = data.daily_budget;
          this.ob_monthly_budget = data.monthly_budget;
          this.ob_default_cpc = data.default_cpc;
          this.ob_objective = data.objective;
          this.ob_bid_strategy = data.bid_strategy;
          this.ob_device.type = data.device.type;
          data.device.value.forEach((item) => {
            this.ob_fields.device.forEach((a) => {
              if (a.value == item) {
                this.ob_device.value.push(a.name);
              }
            });
          });

          this.ob_audience_list = this.ob_fields.audience[data.account];
          if (data.audience.include) {
            data.audience.include.forEach((item) => {
              console.log(item);
              this.ob_audience_list.forEach((a) => {
                if (a.value == item) {
                  this.ob_audience_in.value.push(a.name);
                }
              });
            });
          }
          if (data.audience.exclude) {
            data.audience.exclude.forEach((item) => {
              this.ob_audience_list.forEach((a) => {
                if (a.value == item) {
                  this.ob_audience_ex.value.push(a.name);
                }
              });
            });
          }

          this.ob_audience_list = this.ob_fields.audience[data.account];
          this.ob_tracking_code = data.tracking_code;
          this.ob_max_percentage = data.max_percentage;
          this.ob_site_bid = data.site_bid;
          this.ob_block_sites = data.block_sites;
          this.ob_conversions_list = this.ob_fields.conversion[data.account];
          if (data.conversions.length || data.primary_conversion.length ) {
            //页面上显示的默认的conversions
            data.conversions.forEach((item) => {
              this.ob_conversions_list.forEach((a) => {
                if (a.value == item) {
                  this.ob_conversions.push(a.name);
                }
              });
            });
            this.ob_conversions_list.forEach((a) => {
                if (a.value == data.primary_conversion) {
                  this.ob_conversions2.push(a.name);
                }
            });
          } else {
            this.ob_conversions = [];
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    //tbl 选择账号
    tblAccountChange(e) {
      this.tbl_fields.account.forEach((item) => {
        if (item.value == e) {
          this.tbl_template = item.template;
        }
      });

      //通过账号得到 Audience
      this.tbl_audience_list = this.tbl_fields.audience[e];
    },

    //ob 选择账号
    obAccountChange(e) {
      this.ob_fields.account.forEach((item) => {
        if (item.value == e) {
          this.ob_template = item.template;
        }
      });

      //通过账号得到 Audience
      this.tbl_audience_list = this.tbl_fields.audience[e];
    },
    ontblEndChange(time, timeString, index) {
      console.log(time, timeString);
      this.tbl_start_hour.rules[index].until_hour = timeString
        .substr(0, 2)
        .replace(/\b(0+)/gi, "");
    },
    ontblStartChange(time, timeString, index) {
      console.log(time, timeString, index);
      console.log(this.tbl_start_hour.rules);
      this.tbl_start_hour.rules[index].from_hour = timeString
        .substr(0, 2)
        .replace(/\b(0+)/gi, "");
    },
    tblStartChange(e) {
      console.log(e);
    },
    onTabStartChange(date, dateString) {
      console.log(date, dateString);
      this.tbl_start_date = dateString[0];
      this.tbl_end_date = dateString[1];
    },
    onStartChange(e) {
      console.log("radio checked", e.target.value);
    },
    removeCreative()
    {
      this.creativeList = [];
    },
    getFields() {
      let params = { post_url: this.url, token: this.userInfo.token };
      if (
        this.campaign_type == "relaunch" ||
        this.campaign_type == "optimization"
      ) {
        params = {
          campaign_id: this.cur_campaign_id,
          token: this.userInfo.token,
        };
      }
      this.$post(api.update.getFields, params).then((res) => {
        if (res.data.status === 200) {
          this.tbl_fields = res.data.data.tbl_fields;
          this.ob_fields = res.data.data.ob_fields;
          this.template_title = res.data.data.title;
          //如果relaunch campaign_id存在 获取模版数据
          if (this.cur_campaign_id) {
            this.getDateChange();
            if (this.platform == "outbrain") {
              this.obTemplateChange(this.cur_campaign_id);
            }
            if (this.platform == "taboola") {
              this.tblTemplateChange(this.cur_campaign_id);
            }
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }

    //relaunch campaign_id
    //新建 post_url
    this.url = this.$route.query.url; //通过url获取getFields
    this.cur_campaign_id = this.$route.query.campaign_id; //通过campaign_id获取getFields
    this.campaign_type = this.$route.query.type;
    this.platform = this.$route.query.platform;
    this.getFields();
  },
};
</script>

<style>
.created {
  margin: 24px;
}
.created .ant-spin-nested-loading > div > .ant-spin {
  position: fixed;
  bottom: 0;
}
.form-wrapper {
  background-color: #fff;
  padding: 1px 16px 32px;
  margin-bottom: 16px;
  position: relative;
}
.form-tl {
  margin-bottom: 8px;
  margin-top: 24px;
}
.title {
  color: #1890ff;
  font-size: 24px;
  margin-bottom: -8px;
  margin-top: 24px;
  text-transform: capitalize;
}
.section-tl {
  margin-bottom: 16px;
  font-weight: bold;
}
.hourtl {
  width: 100px;
  display: inline-block;
}
.hour-line {
  margin-bottom: 8px;
}
.subtl {
  color: #bfbfbf;
}
.include {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  /* width: 300px; */
}
.include .ant-select-selection {
  border-width: 0;
}
.block-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.block-item:hover {
  background-color: #f6f6f6;
}
.input-number {
  position: relative;
}
.input-txt {
  position: absolute;
  left: 8px;
  top: 0;
  line-height: 34px;
}
.input-number .ant-input-number-input {
  padding-left: 40px;
}
.creative {
  border-bottom: 1px solid #eee;
  line-height: 1;
  padding: 10px 0;
}
.clickadd {
  border: 1px solid #d9d9d9;
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
  padding: 0 11px;
  background-color: #f5f5f5;
  cursor: pointer;
}
.clickadd-txt {
  padding-left: 8px;
  color: rgba(0, 0, 0, 0.25);
}
.preview-ls {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
}
.preview-item {
  width: 33.3333%;
  padding-left: 16px;
  position: relative;
}
.preview-colse {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 16px;
  top: 0;
  text-align: center;
  line-height: 16px;
  background-color: rgba(0, 0, 0, 0.85);
  /* border-radius: 100%; */
  border-radius: 0 0 10px 0;
  cursor: pointer;
}
.preview-item img {
  max-width: 100%;
  border-radius: 4px;
}
.preview-tl {
  font-size: 14px;
  line-height: 1.2;
  margin-top: 8px;
  margin-bottom: 16px;
}
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-thead
  > tr
  > th,
.ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th {
  background-color: #f7f7f7;
}
.ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td {
  position: relative;
}
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-tbody
  > tr
  > td {
  cursor: pointer;
}
.crtitle {
  cursor: pointer;
  min-height: 60px;
  display: flex;
  align-items: center;
}
.editorimage {
  position: relative;
  height: 75px;
}
.edittitle {
  position: absolute;
  right: 6px;
  cursor: pointer;
  color: #1890ff;
  bottom: 6px;
  opacity: 0;
}
.editorimage .edittitle {
  bottom: 0;
  right: 0;
}
.crtitle:hover .edittitle,
.editorimage:hover .edittitle {
  opacity: 1;
}
.editor-box {
  position: absolute;
  top: -38px;
  left: 0;
  right: -120px;
  bottom: 0;
  background-color: #fff;
  z-index: 9;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.25);
  padding: 16px;
}
.editor-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
.form-disabled {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.65);
  z-index: 99;
}
</style>
