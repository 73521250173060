<template>
  <div>
    <!-- <div class="ant-page-header-tl">OKR目标</div> -->
    <div class="section-wrapper" style="height: 228px;border: 2px solid #54c9ff;">
      <a-card :loading="loading10" :bordered="false" title="OKR: 两个推广平台季度末宗数达 4,000（季度末 PV 达 3,000,000）Outbrain 平台季度末宗数达 2,500，PV 达 1,500,000 Taboola 平台季度末宗数达 1,500，PV 达 1,500,000">
        <!-- <a-range-picker slot="extra" :default-value="[defaultSelectDate.startDate, defaultSelectDate.endDate]"
        @change="onPicker10Change" /> -->
        <!-- <a slot="extra" href="#">More</a> -->
        <div slot="extra">
          <a-date-picker size="small" :default-value="defaultSelectDate.endDate" @change="onPicker10Change" />
          <a-button size="small" type="primary" style="margin-left: 8px;" @click="submitPicker10Change">Apply</a-button>
        </div>
        
        <a-table 
          :columns="columnsAll" 
          :data-source="okrList_10" 
          :rowKey="record => record.name"
          :pagination="false" 
          size="small"
          bordered
        >
          <a v-if="record.name != 'Total'" slot="action" slot-scope="text, record" @click="goGroup(api.okr.getL10Total, record.name, dateString10)">Check</a>
        </a-table>
      </a-card> 
    </div>
    <div class="section-wrapper" style="height: 224px;">
      <a-card :loading="loading12" :bordered="false" title="Action 1: 新市场：调研日本市场的潜力和机会，实现日文 Campaign 的覆盖，期待季度末 Daily 宗数 1,000">
        <div slot="extra">
          <a-range-picker size="small" :default-value="[defaultSelectDate.startDate, defaultSelectDate.endDate]" @change="onPicker12Change" />
          <a-button size="small" type="primary" style="margin-left: 8px;" @click="submitPicker12Change">Apply</a-button>
        </div>
        <a-table 
          :columns="columns" 
          :data-source="okrList_12" 
          :rowKey="record => record.name"
          :pagination="false" 
          size="small"
          bordered
        >
          <a v-if="record.name != 'Total'" slot="action" slot-scope="text, record" @click="goGroup(api.okr.getL12Data, record.name, dateString12)">Check</a>
        </a-table>
      </a-card> 
    </div>
    <div class="section-wrapper" style="height: 224px;">
      <a-card :bordered="false" :loading="loading13" title="Action 2: 无新 Campaign 的情况：新文章上线后平均 7 天内完成新 Campaign 在 Taboola 和 Outbrain 平台的覆盖">
        <div slot="extra">
          <a-range-picker size="small" :default-value="[defaultSelectDate.startDate, defaultSelectDate.endDate]" @change="onPicker13Change" />
          <a-button type="primary" size="small" style="margin-left: 8px;" @click="submitPicker13Change">Apply</a-button>
        </div>
        <a-table 
          :columns="columns" 
          :data-source="okrList_13" 
          :rowKey="record => record.name"
          :pagination="false"
          size="small"
          bordered
        >
          <a v-if="record.name != 'Total'" slot="action" slot-scope="text, record" @click="goGroup(api.okr.getL13Data, record.name, dateString13)">Check</a>
        </a-table>
      </a-card> 
    </div>
    <div class="section-wrapper" style="height:170px">
      <a-card :bordered="false" :loading="loading14" title="Action 3: 无 OTB Campaign 的情况：将未在 Outbrain 覆盖的 Taboola Campaign 100% 覆盖到 Outbrain 平台">
        <div slot="extra">
          <a-range-picker size="small" :default-value="[defaultSelectDate.startDate, defaultSelectDate.endDate]" @change="onPicker14Change" />
          <a-button type="primary" size="small" style="margin-left: 8px;" @click="submitPicker14Change">Apply</a-button>
        </div>
        <a-table 
          :columns="columns" 
          :data-source="okrList_14" 
          :rowKey="record => record.name"
          :pagination="false"
          size="small"
          bordered
        >
          <a v-if="record.name != 'Total'" slot="action" slot-scope="text, record" @click="goGroup(api.okr.getL14Data, record.name, dateString14)">Check</a>
        </a-table>
      </a-card> 
    </div>
    <div class="section-wrapper" style="height:170px">
      <a-card :bordered="false" :loading="loading15" title="Action 4: 无 OTB iOS Campaign 的情况：完成 Outbrain 中覆盖 iOS Campaign 的任务，用工具实现 100% 覆盖">
        <div slot="extra">
          <a-range-picker size="small" :default-value="[defaultSelectDate.startDate, defaultSelectDate.endDate]" @change="onPicker15Change" />
          <a-button type="primary" size="small" style="margin-left: 8px;" @click="submitPicker15Change">Apply</a-button>
        </div>
        <a-table 
          :columns="columns" 
          :data-source="okrList_15" 
          :rowKey="record => record.name"
          :pagination="false"
          size="small"
          bordered
        >
          <a slot="action" slot-scope="text, record" @click="goGroup(api.okr.getL15Data, record.name, dateString15)">Check</a>
        </a-table>
      </a-card> 
    </div>
    <div class="section-wrapper" style="height:332px">
      <a-card :bordered="false" :loading="loading16" title="Action 5: 新市场：新建并维护 Global Campaign，季度共带来宗数 20,000（Q2 BA Global Campaign 占 BA 总宗数的 85%）">
        <div slot="extra">
          <a-range-picker size="small" :default-value="[defaultSelectDate.startDate, defaultSelectDate.endDate]" @change="onPicker16Change" />
          <a-button type="primary" size="small" style="margin-left: 8px;" @click="submitPicker16Change">Apply</a-button>
        </div>
        <a-table 
          :columns="columns2" 
          :data-source="okrList_16" 
          :rowKey="record => record.name"
          :pagination="false"
          size="small"
          bordered
        >
          <a v-if="record.name != 'Total'" slot="action" slot-scope="text, record" @click="goGroup(api.okr.getL16Data, record.name, dateString16, record.platform, record.country)">Check</a>
        </a-table>
      </a-card> 
    </div>
    <div class="section-wrapper" style="height: 224px;">
      <a-card :bordered="false" :loading="loading17" title="Action 6: Ads 优化：Relaunch 表现下降的 Top Campaign 和有潜力 Campaign，优化后的宗数达到 20,000">
        <div slot="extra">
          <a-range-picker size="small" :default-value="[defaultSelectDate.startDate, defaultSelectDate.endDate]" @change="onPicker17Change" />
          <a-button type="primary" size="small" style="margin-left: 8px;" @click="submitPicker17Change">Apply</a-button>
        </div>
        <a-table 
          :columns="columns2" 
          :data-source="okrList_17" 
          :rowKey="record => record.name"
          :pagination="false"
          size="small"
          bordered
        >
          <a v-if="record.name != 'Total'" slot="action" slot-scope="text, record" @click="goCampaign(api.okr.getL17Data, record.name, dateString17, record.platform, record.category)">Check</a>
        </a-table>
      </a-card> 
    </div>
    <div class="section-wrapper" style="height: 386px;">
      <a-card :bordered="false" :loading="loading19" title="Action 7: Ads 设置：测试 Event-based Conversion LookAlike 项目，找到对宗数更有效的用户 Targeting 设置">
        <div slot="extra">
          <a-range-picker size="small" :default-value="[defaultSelectDate.startDate, defaultSelectDate.endDate]" @change="onPicker19Change" />
          <a-button type="primary" size="small" style="margin-left: 8px;" @click="submitPicker19Change">Apply</a-button>
        </div>
        <a-table 
          :columns="columns2" 
          :data-source="okrList_19" 
          :rowKey="record => record.name"
          :pagination="false"
          size="small"
          bordered
        >
          <div slot="action" slot-scope="text, record" v-if="record.name != 'Total'">
          <!-- NO_AUDIENCE campaign 其他跳group -->
            <a  v-if="record.name.indexOf('s') != -1" @click="goCampaign(api.okr.getL19Data, record.name, record.platform, record.category)">Check</a>
            <a v-else @click="goGroup(api.okr.getL19Data, record.name, dateString19, record.platform, record.category)">Check</a>
          </div>
        </a-table>
      </a-card> 
    </div>
    <div class="section-wrapper" style="height: 170px;">
      <a-card :bordered="false" :loading="loading24" title="Action 8: Push 功能：完善 Push 功能，季度末 Daily 宗数达 2,000（获取 2,000,000 个用户）">
        <div slot="extra">
          <a-range-picker size="small" :default-value="[defaultSelectDate.startDate, defaultSelectDate.endDate]" @change="onPicker24Change" />
          <a-button type="primary" size="small" style="margin-left: 8px;" @click="submitPicker24Change">Apply</a-button>
        </div>
        <a-table 
          :columns="columns3" 
          :data-source="okrList_24" 
          :rowKey="record => record.name"
          :pagination="false"
          size="small"
          bordered
        >
          <a slot="action" slot-scope="text, record" :href="record.push_report_url" target="_blank">Check</a>
        </a-table>
      </a-card> 
    </div>
    <div class="section-wrapper" style="height: 170px;">
      <a-card :bordered="false" :loading="loading44" title="Action 9: 新文章宗数和数量：季度末新文章日均宗数达到 200，推广篇数达到 100">
        <div slot="extra">
          <a-range-picker size="small" :default-value="[defaultSelectDate.startDate, defaultSelectDate.endDate]" @change="onPicker44Change" />
          <a-button type="primary" size="small" style="margin-left: 8px;" @click="submitPicker44Change">Apply</a-button>
        </div>
        <a-table 
          :columns="columns4" 
          :data-source="okrList_44" 
          :rowKey="record => record.name"
          :pagination="false"
          size="small"
          bordered
        >
          <div slot="newz_num" slot-scope="text">
            {{text.toFixed(2)}} <a style="margin-left:20px" @click="goNewPost(1, dateString44)">Check</a>
          </div>
          <div slot="newp_num" slot-scope="text">
            {{text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')}} <a style="margin-left:20px" @click="goNewPost(2, dateString44)">Check</a>
          </div>
          
        </a-table>
      </a-card> 
    </div>
  </div>
</template>
<script>
  import api from '@/common/api.js'
  import moment from 'moment';
  // import axios from 'axios'
  // import qs from 'Qs'

  //默认2天前日期

  const day2 = moment().startOf('day').subtract(2, 'days').format().slice(0, 10)
  const defaultSelectDate = {
    startDate: moment('2020-07-01', 'YYYY-MM-DD'),
    endDate: moment().startOf('day').subtract(2, 'days'),
  }

  const columns = [
    {
      title: 'Platform',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Post Number',
      dataIndex: 'post_num',
      align: 'right',
      sorter: (a, b) => a.post_num - b.post_num,
    },
    {
      title: 'Post Covered',
      dataIndex: 'post_cover_num',
      align: 'right',
      sorter: (a, b) => a.post_cover_num - b.post_cover_num,
    },
    {
      title:'Cam Number',
      dataIndex: 'campaign_num',
      align: 'right',
      sorter: (a, b) => a.campaign_num - b.campaign_num,
    },
    {
      title:'Sessions',
      dataIndex: 'sessions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.sessions - b.sessions,
    }, 
    {
      title:'PV',
      dataIndex: 'page_views',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.page_views - b.page_views,
    },
    {
      title:'P/S',
      dataIndex: 'ps',
      align: 'right',
      customRender: text => {
        return text.toFixed(2)
      },
      sorter: (a, b) => a.ps - b.ps,
    },
    {
      title:'宗数',
      dataIndex: 'z_num',
      key: 'z_num',
      align: 'right',
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.z_num - b.z_num,
    },
    {
      title:'Detail',
      dataIndex: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    }
  ]

  const columns2 = [
    {
      title: 'Platform',
      dataIndex: 'name',
      customRender: text => {
        if(text.indexOf("EVENT_BASED") != -1){
          return text.replace('EVENT_BASED', 'Event Based')
        }else if(text.indexOf("CONVERSION") != -1){
          return text.replace('CONVERSION', 'Event Based')
        }else if(text.indexOf("URL_BASED") != -1){
          return text.replace('URL_BASED', 'URL Based')
        }else if(text.indexOf("PIXEL") != -1){
          return text.replace('PIXEL', 'URL Based')
        }else if(text.indexOf("NO_AUDIENCE") != -1){
          return text.replace('NO_AUDIENCE', 'No Audience')
        }else if(text.indexOf("LOOK_A_LIKES") != -1){
          return text.replace('LOOK_A_LIKES', 'Lookalike')
        }else{
          return text
        }
        
        // return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title:'Cam Number',
      dataIndex: 'campaign_num',
      align: 'right',
      sorter: (a, b) => a.campaign_num - b.campaign_num,
    },
    {
      title:'Sessions',
      dataIndex: 'sessions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.sessions - b.sessions,
    }, 
    {
      title:'PV',
      dataIndex: 'page_views',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.page_views - b.page_views,
    },
    {
      title:'P/S',
      dataIndex: 'ps',
      align: 'right',
      customRender: text => {
        return text.toFixed(2)
      },
      sorter: (a, b) => a.ps - b.ps,
    },
    {
      title:'宗数',
      dataIndex: 'z_num',
      key: 'z_num',
      align: 'right',
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.z_num - b.z_num,
    },
    // {
    //   title:'宗数%',
    //   dataIndex: 'z_num',
    //   align: 'right',
    //   customRender: text => {
    //     return text+'%'
    //   }
    // },
    {
      title:'Detail',
      dataIndex: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    }
  ]
  const columns3 = [
    {
      title: 'Push 宗数',
      dataIndex: 'z_num',
      align: 'right',
      customRender: text => {
        return text.toFixed(2)
      },
      sorter: (a, b) => a.z_num - b.z_num,
    },
    {
      title:'Push PV',
      dataIndex: 'page_views',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.page_views - b.page_views,
    },
    {
      title:'Push Subscribers',
      dataIndex: 'total_subscribers',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.total_subscribers - b.total_subscribers,
    }, 
    {
      title:'Push 订阅率',
      dataIndex: 'subscribe_rate',
      align: 'right',
      customRender: text => {
        return text.toFixed(2)*100+'%'
      },
      sorter: (a, b) => a.subscribe_rate - b.subscribe_rate,
    },
    {
      title:'Push 打开率',
      dataIndex: 'open_rate',
      align: 'right',
      customRender: text => {
        return text.toFixed(2)*100+'%'
      },
      sorter: (a, b) => a.open_rate - b.open_rate,
    },
    {
      title:'Detail',
      dataIndex: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    }
  ]
  const columns4 = [
    {
      title: '新文章日均宗数',
      dataIndex: 'daily_z_num',
      align: 'center',
      scopedSlots: { customRender: 'newz_num' },
      // customRender: text => {
      //   return text.toFixed(2)+'%'
      // }
    },
    {
      title:'新文章的数量',
      dataIndex: 'new_post_num',
      align: 'center',
      scopedSlots: { customRender: 'newp_num' },
      // customRender: text => {
      //   return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      // }
    }
  ]

  const columnsAll = [
    {
      title: 'Platform',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: '目标 PV',
      dataIndex: 'target_pv',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.target_pv - b.target_pv,
    },
    {
      title: '当前 PV',
      dataIndex: 'pv',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.pv - b.pv,
    },
    {
      title: 'PV%',
      dataIndex: 'pv_rate',
      align: 'right',
      customRender: text => {
        return text + '%'
      },
      sorter: (a, b) => a.pv_rate - b.pv_rate,
      //有错
    },
    {
      title: '目标宗数',
      dataIndex: 'target_z_num',
      align: 'right',
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.target_z_num - b.target_z_num,
    },
    {
      title: '当前宗数',
      dataIndex: 'z_num',
      align: 'right',
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.z_num - b.z_num,
    },
    {
      title: '宗数%',
      dataIndex: 'z_num_rate',
      align: 'right',
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,') + '%'
      },
      sorter: (a, b) => a.z_num_rate - b.z_num_rate,
    },
  ]
  export default {
    data() {
      return {
        userInfo: {},
        defaultSelectDate,
        api,
        columns,
        columns2,
        columns3,
        columns4,
        columnsAll,
        okrList_10: [],
        okrList_12: [],
        okrList_13: [],
        okrList_14: [],
        okrList_15: [],
        okrList_16: [],
        okrList_17: [],
        okrList_19: [],
        okrList_24: [],
        okrList_44: [],
        loading10: false,
        loading12: false,
        loading13: false,
        loading14: false,
        loading15: false,
        loading16: false,
        loading17: false,
        loading19: false,
        loading24: false,
        loading44: false,
        dateString10: day2,
        dateString12: ['2020-07-01', day2],
        dateString13: ['2020-07-01', day2],
        dateString14: ['2020-07-01', day2],
        dateString15: ['2020-07-01', day2],
        dateString16: ['2020-07-01', day2],
        dateString17: ['2020-07-01', day2],
        dateString19: ['2020-07-01', day2],
        dateString24: ['2020-07-01', day2],
        dateString44: ['2020-07-01', day2]
      }
    },
    methods: {
      submitPicker10Change(){
        this.getOkr(api.okr.getL10Total)
      },
      submitPicker12Change(){
        this.getOkr(api.okr.getL12Data)
      },
      submitPicker13Change(){
        this.getOkr(api.okr.getL13Data)
      },
      submitPicker14Change(){
        this.getOkr(api.okr.getL14Data)
      },
      submitPicker15Change(){
        this.getOkr(api.okr.getL15Data)
      },
      submitPicker16Change(){
        this.getOkr(api.okr.getL16Data) 
      },
      submitPicker17Change(){
        this.getOkr(api.okr.getL16Data)
      },
      submitPicker19Change(){
        this.getOkr(api.okr.getL19Data)
      },
      submitPicker24Change(){
        this.getOkr(api.okr.getL24Data)
      },
      submitPicker44Change(){
        this.getOkr(api.okr.getL44Data) 
      },
      onPicker10Change(date, dateString){
        this.dateString10 = dateString 
        console.log(dateString)
      },
      onPicker12Change(date, dateString){
        this.dateString12 = dateString
        //this.getOkr(api.okr.getL12Data)
        console.log(dateString)
      },
      onPicker13Change(date, dateString){
        this.dateString13 = dateString
        //this.getOkr(api.okr.getL13Data)
        console.log(dateString)
      },
      onPicker14Change(date, dateString){
        this.dateString14 = dateString
        //this.getOkr(api.okr.getL14Data)
        console.log(dateString)
      },
      onPicker15Change(date, dateString){
        this.dateString15 = dateString
        //this.getOkr(api.okr.getL15Data)
        console.log(dateString)
      },
      onPicker16Change(date, dateString){
        this.dateString16 = dateString
        //this.getOkr(api.okr.getL16Data) 
        console.log(dateString)
      },
      onPicker17Change(date, dateString){
        this.dateString17 = dateString
        //this.getOkr(api.okr.getL16Data)
        console.log(dateString)
      },
      onPicker19Change(date, dateString){
        this.dateString19 = dateString
        //this.getOkr(api.okr.getL19Data)
        console.log(dateString)
      },
      onPicker24Change(date, dateString){
        this.dateString24 = dateString
        //this.getOkr(api.okr.getL24Data)
        console.log(dateString)
      },
      onPicker44Change(date, dateString){
        this.dateString44 = dateString
        //this.getOkr(api.okr.getL44Data) 
        console.log(dateString)
      },
      getOkr(url){
        let params = {token: this.userInfo.token}
        if(url == api.okr.getL10Total){
          this.loading10 = true
          if(this.dateString10) params.period = this.dateString10
        }
        if(url == api.okr.getL12Data){
          this.loading12 = true
          if(this.dateString12) params.period = JSON.stringify(this.dateString12)
        }
        if(url == api.okr.getL13Data){
          this.loading13 = true
          if(this.dateString13) params.period = JSON.stringify(this.dateString13)
        }
        if(url == api.okr.getL14Data){
          this.loading14 = true
          if(this.dateString14) params.period = JSON.stringify(this.dateString14)
        }
        if(url == api.okr.getL15Data){
          this.loading15 = true
          if(this.dateString15) params.period = JSON.stringify(this.dateString15)
        }
        if(url == api.okr.getL16Data){
          this.loading16 = true
          if(this.dateString16) params.period = JSON.stringify(this.dateString16)
        }
        if(url == api.okr.getL17Data){
          this.loading17 = true
          if(this.dateString17) params.period = JSON.stringify(this.dateString17)
        }
        if(url == api.okr.getL19Data){
          this.loading19 = true
          if(this.dateString19) params.period = JSON.stringify(this.dateString19)
        }
        if(url == api.okr.getL24Data){
          this.loading24 = true
          if(this.dateString24) params.period = JSON.stringify(this.dateString24)
        }
        if(url == api.okr.getL44Data){
          this.loading44 = true
          if(this.dateString44) params.period = JSON.stringify(this.dateString44)
        }


        // axios
        this.$post(url, params).then(res => {
          if (res.data.status === 200) {
            if(url == api.okr.getL10Total){
              this.loading10 = false
              this.okrList_10 = res.data.data
              return
            }
            if(url == api.okr.getL12Data){
              this.loading12 = false
              this.okrList_12 = res.data.data
              return
            }
            if(url == api.okr.getL13Data){
              this.loading13 = false
              this.okrList_13 = res.data.data
              return
            }
            if(url == api.okr.getL14Data){
              this.loading14 = false
              this.okrList_14 = res.data.data
              return
            }
            if(url == api.okr.getL15Data){
              this.loading15 = false
              this.okrList_15 = res.data.data
              return
            }
            if(url == api.okr.getL16Data){
              this.loading16 = false
              this.okrList_16 = res.data.data
              return
            }
            if(url == api.okr.getL17Data){
              this.loading17 = false
              this.okrList_17 = res.data.data
              return
            }
            if(url == api.okr.getL19Data){
              this.loading19 = false
              this.okrList_19 = res.data.data
              return
            }
            if(url == api.okr.getL24Data){
              this.loading24 = false
              this.okrList_24 = res.data.data
              return
            }
            if(url == api.okr.getL44Data){
              this.loading44 = false
              this.okrList_44 = res.data.data
              return
            }
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      goGroup(url, name, period, platform, country){

        let query = {
          url: url,
          name: name,
          period: JSON.stringify(period) 
        }
        let path = '/group'
        if(platform){
          query = {
            url: url,
            name: name,
            platform: platform,
            country: country,
            period: JSON.stringify(period) 
          }
          path = '/campaign'
        }
        const {href} = this.$router.resolve({
          path: path,
          query: query,
        })
        window.open(href, '_blank')
      },
      goCampaign(url, name, period, platform, country){
        let query = {
          url: url,
          name: name,
          platform: platform,
          country: country,
          period: JSON.stringify(period) 
        }
        let path = '/campaign'
        const {href} = this.$router.resolve({
          path: path,
          query: query,
        })
        window.open(href, '_blank')
      },
      goNewPost(type, period){
        let query = {
          period: JSON.stringify(period)
        }
        let path = '/dayznum'
        if(type == 2){
          path = '/newnum'
        }
        const {href} = this.$router.resolve({
          path: path,
          query: query,
        })
        window.open(href, '_blank')
      },
    },
    created () {
      
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.getOkr(api.okr.getL10Total)
      this.getOkr(api.okr.getL12Data)
      this.getOkr(api.okr.getL13Data)
      this.getOkr(api.okr.getL14Data)
      this.getOkr(api.okr.getL15Data)
      this.getOkr(api.okr.getL16Data)
      this.getOkr(api.okr.getL17Data)
      this.getOkr(api.okr.getL19Data)
      this.getOkr(api.okr.getL24Data)
      this.getOkr(api.okr.getL44Data)
      console.log(JSON.parse(localStorage.userInfo))
    }
  };
</script>

<style> 
  .section-wrapper{
    margin: 24px;
    background-color: #fff;
  }
  .section-wrapper .ant-card-extra{
    padding: 8px 0;
  }
  .noshow{
    display: none;
  }
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td{
    padding: 8px;
    color: #000;
    font-size: 12px;
  }
  .ant-table-small > .ant-table-content > .ant-table-body{
    margin: 0;
  }
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th{
    background-color: #f5f5f5;
    text-align: center;
  }
</style>
