<template>
  <div>

    <div class="section-wrapper section-wrapper-sp" style="margin-left: 24px">
      <section class="layout-minbox">
        <h2 class="layout-mintit">Bing Timezone Table ({{ page_start_date }} ～ {{ page_end_date }})</h2>
        <div style="margin-bottom: 16px">
          <a-date-picker :default-value="dateStringOne" @change="onDateChangeOne" style="width: 140px" />
          <a-button type="primary" @click="getData" style="margin-left: 8px"
            >Apply</a-button
          >
        </div>
        <a-table
          :columns="columns1"
          :data-source="dayzumList"
          :rowKey="(record) => record.site"
          :pagination="false"
          size="middle"
          bordered
          :loading="loading"
          class="znum-data-table"
        >
        </a-table>
      </section>
      <section class="layout-minbox">
        <h2 class="layout-mintit">Bing Timezone Chart</h2>
        <div style="margin-bottom: 16px">
          <a-range-picker :default-value="dateString" @change="onDateChange" style="width: 240px" />
          <a-select
            placeholder="Site"
            style="width: 100px;margin-left: 8px"
            @change="handleSiteChange"
            :value="site"
          >
            <a-select-option
              v-for="(item, index) in siteList"
              :key="index"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
          <a-button
            style="margin-left: 8px"
            type="primary"
            @click="getZoneChart"
            >Apply</a-button
          >
        </div>
        <div id="echart" class="echart"></div>
      </section>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
import * as echarts from "echarts";
const defaultStartDate = moment().startOf("day").subtract(16, "days");
const defaultEndDate = moment().startOf("day").subtract(2, "days");

const columns1 = [
  {
    title: "Product",
    dataIndex: "site",
    align: "left",
    width: 150,
    sorter: (a, b) => {
      return a.site.localeCompare(b.site);
    },
  },
  {
    title: "PT Revenue",
    dataIndex: "pt",
    align: "right",
    customRender: (text, row) => {
      return {
        children: parseFloat(text.toString()).toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
        attrs: {
          style: `color: ${text == row.max ? "red" : ""}; text-align: right;`,
        },
      };
    },
    sorter: (a, b) => {
      return a.pt - b.pt;
    },
  },
  {
    title: "UTC8 Revenue",
    dataIndex: "utc",
    align: "right",
    customRender: (text, row) => {
      return {
        children: parseFloat(text.toString()).toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
        attrs: {
          style: `color: ${text == row.max ? "red" : ""}; text-align: right;`,
        },
      };
    },
    sorter: (a, b) => {
      return a.utc - b.utc;
    },
  },
  {
    title: "Diff(PT-UTC8)",
    dataIndex: "diff",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    sorter: (a, b) => {
      return a.diff - b.diff;
    },
  },
];

export default {
  data() {
    return {
      moment,
      userInfo: {},
      columns1,
      loading: false,
      okrList_2: [],
      dayzumList: [],
      quarterList: [],
      defaultStartDate,
      defaultEndDate,
      page_start_date: "",
      page_end_date: "",
      start_date: "",
      end_date: "",
      dateString: [
        defaultStartDate.format("YYYY-MM-DD"),
        defaultEndDate.format("YYYY-MM-DD"),
      ],
      dateStringOne:defaultEndDate.format("YYYY-MM-DD"),
      site: "apk",
      chart_date: [],
      chart_pt: [],
      chart_utc8: [],
      siteList: [],
    };
  },
  methods: {
    getDailyPerf() {
      this.loading = true;
      this.$post(api.ga4.bingTimeZoneReport, {
        token: this.userInfo.token,
        end_date: this.dateStringOne,
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dayzumList = res.data.data.data.map((item) => {
            const max = Math.max(item.pt, item.utc);
            return { ...item, max };
          });
          this.page_start_date = res.data.data.start_date;
          this.page_end_date = res.data.data.end_date;

          this.siteList = [];
          for (let i = 0; i < res.data.data.data.length; i++) {
            this.siteList.push({
              value: res.data.data.data[i].site,
              name: res.data.data.data[i].site,
            });
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 折线图
    echartInit() {
      let myChart = echarts.init(document.getElementById("echart"));
      let option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["pt", "utc8"],
        },
        grid: {
          left: "0%",
          right: "10%",
          bottom: "0%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.chart_date,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "pt",
            type: "line",
            data: this.chart_pt,
          },
          {
            name: "utc8",
            type: "line",
            data: this.chart_utc8,
          },
        ],
      };
      myChart.setOption(option);
    },
    //折线图接口
    getZoneChart() {
      this.$post(api.ga4.bingTimeZoneChart, {
        token: this.userInfo.token,
        site: this.site,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.chart_date = [];
          this.chart_pt = [];
          this.chart_utc8 = [];
          for (let i = 0; i < res.data.data.data.length; i++) {
            this.chart_date.push(res.data.data.data[i].date);
            this.chart_pt.push(res.data.data.data[i].pt);
            this.chart_utc8.push(res.data.data.data[i].utc);
          }
          this.echartInit();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    onDateChangeOne(date, dateString) {
      this.dateStringOne = dateString;
    },
    onDateChange(date, dateString) {
      this.dateString = dateString;
    },
    handleSiteChange(e) {
      this.site = e;
    },
    getData() {
      this.getDailyPerf();
      // this.getZoneChart();
    },
  },
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.getDailyPerf();
    this.getZoneChart();
  },
};
</script>

<style>
.total-item {
  margin-right: 10px;
  margin-left: 10px;
  color: #ddd;
}
.znum-data-table tr:last-child td {
  border-top: 1px #e8e8e8;
}
.echart {
  height: 400px;
  width: 100%;
}
.section-wrapper-sp {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
}
.layout-mintit {
  padding-bottom: 6px;
  margin-bottom: 16px;
  font-size: 18px;
  border-bottom: 1px solid rgb(228, 228, 228);
}
.layout-minbox {
    width: calc( 50% - 10px);
    box-sizing: border-box;
    padding: 16px;
    background-color: #fff;
}
</style>
