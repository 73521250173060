<template>
  <div>
    <div class="ant-page-header-tl">
      <a-range-picker
        :default-value="dateString"
        @change="onDateChange"
        style="margin-right: 8px"
      />
      <a-button type="primary" @click="submitRangeChange">Apply</a-button>
    </div>

    <div class="section-wrapper zumDetail">
      <a-card :loading="loading" :bordered="false" :title="name">
        <a-row>
          <a-col :span="20">
            <ve-line
              :data="velineData"
              :legend-visible="false"
              :settings="chartSettings"
              :extend="chartExtend"
            ></ve-line>
          </a-col>
          <a-col :span="3" :offset="1">
            <div class="checkitem">
              <a-switch class="spent" default-checked @change="onSpentChange" />
              Spend
            </div>
            <div class="checkitem">
              <a-switch class="znum" default-checked @change="onClicksChange" />
              Clicks
            </div>
            <div class="checkitem">
              <a-switch class="pageview" @change="onImpressionChange" />
              Impression
            </div>
            <div class="checkitem">
              <a-switch class="sessions" @change="onCtrChange" />
              CTR
            </div>
            <div class="checkitem">
              <a-switch class="sessions" @change="onAvgcpcChange" />
              Avg.CPC
            </div>
            <div class="checkitem">
              <a-switch class="adscpc" @change="onConversionsChange" />
              Conversions
            </div>
            <div class="checkitem">
              <a-switch class="adsctr" @change="onConversionsrateChange" />
              Conversion Rate
            </div>
          </a-col>
        </a-row>
      </a-card>
    </div>

    <a-row>
      <a-col :span="20">
        <div class="section-wrapper">
          <a-card :bordered="false">
            <a-table
              :columns="columns"
              :data-source="spentPerfList"
              :rowKey="(record) => record.date"
              :pagination="false"
              size="small"
              bordered
              :loading="loading"
            >
            </a-table>
          </a-card>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
const defaultStartDate = moment()
  .startOf("day")
  .subtract(31, "days");
const defaultEndDate = moment()
  .startOf("day")
  .subtract(2, "days");

const columns = [
{
    title: "Date",
    dataIndex: "date",
    key: "date",
    width: 100,
    sorter: (a, b) => a.date.localeCompare(b.date),
  },
  {
    title: "Spend",
    dataIndex: "spent",
    align: "right",
    sorter: (a, b) => a.spent - b.spent,
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  {
    title: "Clicks",
    dataIndex: "clicks",
    align: "right",
    sorter: (a, b) => a.clicks - b.clicks,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Impression",
    dataIndex: "impressions",
    align: "right",
    sorter: (a, b) => a.impressions - b.impressions,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "CTR",
    dataIndex: "ctr",
    align: "right",
    sorter: (a, b) => a.ctr - b.ctr,
    customRender: (text) => {
      return (
        parseFloat(text.toString())
          .toFixed(2)
          .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "%"
      );
    },
  },
  {
    title: "Avg.CPC",
    dataIndex: "avg_cpc",
    align: "right",
    sorter: (a, b) => a.avg_cpc - b.avg_cpc,
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(2)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Conversions",
    dataIndex: "conversions",
    align: "right",
    sorter: (a, b) => a.conversions - b.conversions,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Conversion Rate",
    dataIndex: "conversion_rate",
    align: "right",
    sorter: (a, b) => a.conversion_rate - b.conversion_rate,
    customRender: (text) => {
      return (
        parseFloat(text.toString())
          .toFixed(2)
          .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "%"
      );
    },
  },
];

export default {
  data() {
    return {
      moment,
      userInfo: {},
      columns,
      loading: false,
      spentPerfList: [],
      name: "",
      level: "",
      site: "",
      customer: "",
      account: "",
      dateString: [
        defaultStartDate.format("YYYY-MM-DD"),
        defaultEndDate.format("YYYY-MM-DD"),
      ],
      velineData: {
        columns: ["date"],
        rows: [],
      },
      chartExtend: {
        color: ["#6578a0", "#1ad4ae"],
        series: {
          smooth: false,
        },
      },
      chartSettings: {
        axisSite: {
          right: [
            "avg_cpc",
            "ctr",
            "conversion_rate",
          ],
        },
      },
    };
  },
  methods: {
    onSpentChange(checked) {
      if (!checked) {
        this.velineData.columns.forEach((item, index) => {
          if (item == "spent") {
            this.chartExtend.color.splice(index - 1, 1);
            this.velineData.columns.splice(index, 1);
          }
        });
      } else {
        this.velineData.columns.push("spent");
        this.chartExtend.color.push("#6578a0");
      }
      console.log(`a-switch to ${checked}`);
    },
    onClicksChange(checked) {
      if (!checked) {
        this.velineData.columns.forEach((item, index) => {
          if (item == "clicks") {
            this.chartExtend.color.splice(index - 1, 1);
            this.velineData.columns.splice(index, 1);
          }
        });
      } else {
        this.velineData.columns.push("clicks");
        this.chartExtend.color.push("#1ad4ae");
      }
      console.log(`a-switch to ${checked}`);
    },
    onImpressionChange(checked) {
      if (!checked) {
        this.velineData.columns.forEach((item, index) => {
          if (item == "impressions") {
            this.chartExtend.color.splice(index - 1, 1);
            this.velineData.columns.splice(index, 1);
          }
        });
      } else {
        this.velineData.columns.push("impressions");
        this.chartExtend.color.push("#59b1ef");
      }
      console.log(`a-switch to ${checked}`);
    },
    onCtrChange(checked) {
      if (!checked) {
        this.velineData.columns.forEach((item, index) => {
          if (item == "ctr") {
            this.chartExtend.color.splice(index - 1, 1);
            this.velineData.columns.splice(index, 1);
          }
        });
      } else {
        this.velineData.columns.push("ctr");
        this.chartExtend.color.push("#cea8bc");
      }
      console.log(`a-switch to ${checked}`);
    },
    onAvgcpcChange(checked) {
      if (!checked) {
        this.velineData.columns.forEach((item, index) => {
          if (item == "avg_cpc") {
            this.chartExtend.color.splice(index - 1, 1);
            this.velineData.columns.splice(index, 1);
          }
        });
      } else {
        this.velineData.columns.push("avg_cpc");
        this.chartExtend.color.push("#fa6f87");
      }
      console.log(`a-switch to ${checked}`);
    },
    onConversionsChange(checked) {
      if (!checked) {
        this.velineData.columns.forEach((item, index) => {
          if (item == "conversions") {
            this.chartExtend.color.splice(index - 1, 1);
            this.velineData.columns.splice(index, 1);
          }
        });
      } else {
        this.velineData.columns.push("conversions");
        this.chartExtend.color.push("#ffb980");
      }
      console.log(`a-switch to ${checked}`);
    },
    onConversionsrateChange(checked) {
      if (!checked) {
        this.velineData.columns.forEach((item, index) => {
          if (item == "conversion_rate") {
            this.chartExtend.color.splice(index - 1, 1);
            this.velineData.columns.splice(index, 1);
          }
        });
      } else {
        this.velineData.columns.push("conversion_rate");
        this.chartExtend.color.push("#c4b4e4");
      }
      console.log(`a-switch to ${checked}`);
    },
    onSessionsChange(checked) {
      if (!checked) {
        this.velineData.columns.forEach((item, index) => {
          if (item == "sessions") {
            this.chartExtend.color.splice(index - 1, 1);
            this.velineData.columns.splice(index, 1);
          }
        });
      } else {
        this.velineData.columns.push("sessions");
        this.chartExtend.color.push("#59b1ef");
      }
      console.log(`a-switch to ${checked}`);
    },

    getPerfByDay() {
      this.loading = true;
      this.$post(api.pref.getBingSpentDetail, {
        token: this.userInfo.token,
        level: this.level,
        site: this.site,
        customer: this.customer,
        account: this.account,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
        customer_name: this.$route.query.name,
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          let data = res.data.data;
          this.spentPerfList = data;

          this.velineData.columns.push("spent");
          this.velineData.columns.push("clicks");
          this.velineData.rows = Array.from(this.spentPerfList);
          this.velineData.rows.reverse();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    // 日期切换
    onDateChange(date, dateString) {
      this.dateString = dateString;
    },

    submitRangeChange() {
      (this.velineData = {
        columns: ["date"],
        rows: [],
      }),
        this.getPerfByDay();
    },
  },

  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.level = this.$route.query.level;
    this.site = this.$route.query.site;
    this.customer = this.$route.query.customer;
    this.account = this.$route.query.account;
    this.name = 'Level: ' + this.$route.query.level + '  |  Site: ' + this.$route.query.site + '  |  Name: ' + this.$route.query.name;
    this.getPerfByDay();
  },
};
</script>
<style>
.zumDetail .ant-switch-checked.spent {
  background-color: #6578a0;
}
</style>
