<template>
  <div>
    <div class="ant-page-header-tl">
      <span style="margin-right:5px">Platform:</span>
      <a-select
        defaultValue="Taboola"
        style="width: 150px"
        @change="onPlatformChange"
      >
        <a-select-option
          v-for="(item, index) in platformList"
          :key="index"
          :value="item"
          >{{ item }}</a-select-option
        >
      </a-select>
      <span style="margin-left:16px;margin-right:5px">Date:</span>
      <a-range-picker :default-value="dateString" @change="onDateChange" />
    </div>
    <!-- table -->
    <div class="section">
      <a-table
        :columns="columns"
        :data-source="dataList"
        :pagination="false"
        :rowKey="(record) => record.project + record.name"
        size="middle"
        bordered
        :loading="loading"
      >
        <div
          style="text-decoration: underline;cursor: pointer;"
          slot="toindividual"
          slot-scope="text, record"
          @click="toIndividual(record)"
        >
          {{ text }}
        </div>
      </a-table>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
const defaultStartDate = '2023-01-01';
const defaultEndDate = moment()
  .startOf("day")
  .subtract(2, "days");
const platformList = ["Taboola", "Outbrain"];
const columns = [
  {
    title: "文章",
    dataIndex: "project",
    sorter: (a, b) => {
      return a.project.localeCompare(b.project);
    },
    customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {},
          };
          if (index % 7 === 0) {
            obj.attrs.rowSpan = 7;
          }
          // These two are merged into above cell
          if (index % 7 !== 0) {
            obj.attrs.rowSpan = 0;
          }
          return obj;
    },
  },
  {
    title: "Team Member",
    dataIndex: "name",
    scopedSlots: { customRender: "toindividual" },
  },
  {
    title: "Campaign Number",
    dataIndex: "campaign_num",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Sessions",
    dataIndex: "sessions",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "PV",
    dataIndex: "page_views",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Spent",
    dataIndex: "spent",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
];

export default {
  data() {
    return {
      userInfo: {},
      columns,
      dataList: [],
      loading: false,
      platform: "Taboola",
      platformList,
      defaultStartDate,
      defaultEndDate,
      dateString: [
        moment(defaultStartDate, "YYYY-MM-DD"),
        moment(defaultEndDate, "YYYY-MM-DD"),
      ],
    };
  },
  // 定义方法
  methods: {
    // 修改时间
    onDateChange(dateString) {
      this.dateString = dateString;
      this.getList();
    },

    // 修改平台
    onPlatformChange(e) {
      this.platform = e;
      this.getList();
    },

    // 跳转到个人页面
    toIndividual(param) {
      const { href } = this.$router.resolve({
        path: "/PromoteWorkCampaign",
        query: {
          name: param.name,
          project: param.project,
          platform: this.platform,
          name_id: param.name_id,
          project_id: param.project_id,
          start_date: this.dateString[0].format("YYYY-MM-DD"),
          end_date: this.dateString[1].format("YYYY-MM-DD"),
        },
      });
      window.open(href, "_blank");
    },

    // 获取数据列表
    getList() {
      this.loading = true;
      this.$post(api.okr.getPromoteWorkTotal, {
        token: this.userInfo.token,
        platform: this.platform,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataList = res.data.data;
          console.log(this.dataList);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },

  // 页面初始化
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.getList();
  },
};
</script>
