<template>
  <div>
    <div class="section">
        <div style="margin-bottom: 16px;">
          <span style="display: inline-block;width: 85px">Platform:</span>
          <a-select
            v-model="platform"
            style="width: 120px;"
            @change="handlePlatformChange"
          >
            <a-select-option
              v-for="(item, index) in platformList"
              :key="index"
              :value="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </div>
        <div style="margin-bottom: 16px;">
            <span style="display: inline-block;width: 85px">Title:</span>
            <a-input placeholder="title" style="width: 600px" v-model="title"/>
        </div>
        <div style="margin-bottom: 16px;">
            <span style="display: inline-block;width: 85px">Keyword:</span>
            <a-input placeholder="keyword" style="width: 600px" v-model="keyword"/>
        </div>
        <div style="margin-bottom: 16px;">
            <span style="display: inline-block;width: 85px">Topic:</span>
            <a-input placeholder="topic" style="width: 600px" v-model="topic"/>
        </div>
        <div style="margin-bottom: 16px;">
            <span style="display: inline-block;margin-bottom: 16px;">Image:</span>
            <a-upload
              :action="api.PATH_API+'api/Update/uploadImage'"
              list-type="picture-card"
              :file-list="fileList"
              :data="{token: userInfo.token}"
              @preview="handlePreview"
              @change="handleChange"
            >
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  Upload
                </div>
              </div>
            </a-upload>

            <a-input placeholder="图片链接" v-model="image" style="width: 600px"/>

            <div class="form-tl" style="margin-top: 16px;"><b>预览</b></div>
            <div class="preview-ls" v-if="creative.length != 0">
              <div class="preview-item" v-for="(item, index) in creative" :key="index">
                <span class="preview-colse" @click="deletePreviewCreativeItem(index)"><a-icon type="close" style="color: #fff; font-size: 12px;" /></span>
                <div class="preview-con">
                  <img :src="item.url" alt="">
                  <h3 class="preview-tl">{{item.name}}</h3>
                </div>
              </div>
            </div>

        </div>
        <a-button style="width: 120px;" @click="putList()" type="primary">
          添加
        </a-button>

    </div>
  </div>
</template>
<script>
  import api from '@/common/api.js'

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  export default {
    data() {
      return {
        api,
        userInfo: {},
        platform: 'taboola',
        title: '',
        keyword: '',
        topic: '',
        image: '',
        platformList: [
          {
            id: 1,
            name: 'taboola'
          },
          {
            id: 2,
            name: 'outbrain'
          }
        ],
        tbl_previewVisible: false,
        tbl_previewImage: '',
        creative: [],
        fileList: [],
      }
    },
    computed: {

    },
    methods: {
      handlePlatformChange(e){
        this.platform = e
        console.log(this.platform)
      },
      async handlePreview(file) {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        this.tbl_previewImage = file.url || file.preview;
        console.log(this.tbl_previewImage)
        this.tbl_previewVisible = true;
      },

      handleChange({ fileList }) {
        this.creative = []
        this.fileList = fileList;

        this.fileList.forEach((img) => {
          console.log(img)
          if(img.status == 'done'){
            this.creative.push({name: img.title, url: img.response.data})
            this.image = img.response.data

          }
        })
 
      },

      deletePreviewCreativeItem(index){
        this.creative.splice(index, 1)
      },

      putList(){
        this.loading = true
        let params = {
          id: this.id,
          platform: this.platform,
          title: this.title,
          keyword: this.keyword,
          topic: this.topic,
          image: this.image
        }
        this.$post(api.auto.addCreative, {
          token: this.userInfo.token,
          params: params
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.$message.success('添加成功');
            this.title = '';
            this.keyword = '';
            this.topic = '';
            this.image = '';
            this.fileList = [];
            this.creative = []
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
    },
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }

      if(this.$route.query.id){
        this.id =  this.$route.query.id 
        this.platform =  this.$route.query.platform 
        this.title = this.$route.query.title 
        this.keyword = this.$route.query.keyword
        this.topic = this.$route.query.topic
        this.image = this.$route.query.image
        this.creative.push({name: this.title, url: this.image})
      }
    }
  };
</script>

<style> 
  .cardwrapper{
    margin: 24px;
    /* background-color: #fff; */
  }
  .smallbtn .ant-btn-sm{
    font-size: 12px;
  }
</style>
