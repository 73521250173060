<template>
    <div>
        <div class="section">
            <a-table
                :columns="columnList"
                :data-source="taskList"
                :pagination="false"
                :rowKey="(record) => record.block_site"
                :scroll="{ x: 2400, y: 1200 }"
                size="small"
                bordered
                style=" table-layout: fixed;"
            >
            <template
                class="smallbtn"
                slot="blacklist"
                slot-scope="text, record"
                style="padding: 4px 0px"
                >
                <a-button type="primary" size="small" @click="blacklist(record)"
                    >Blacklist</a-button
                >
                </template>
            </a-table>
        </div>
    </div>
</template>
<script>
import api from "@/common/api.js";
const columnList = [
    {
        title:"Platform",
        dataIndex: "platform",
        fixed: "left",
        width: 80,
    },
    {
        title:"Product",
        dataIndex: "detail.product",
        fixed: "left",
        width: 100,
    },
    {
        title:"Site/Publisher",
        dataIndex: "detail.site_name",
        fixed: "left",
        width: 200,
    },
    {
        title:"Account",
        dataIndex: "account",
        align: "left",
    },
    {
        title:"Impressions",
        dataIndex: "detail.impressions",
        align: "left",
    },
    {
        title:"CTR",
        dataIndex: "detail.ctr",
        customRender: (text) => {
            const formattedText = parseFloat(text).toFixed(2);
            return formattedText.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%";
        },
        align: "left",
    },
    {
        title:"Clicks",
        dataIndex: "detail.clicks",
        align: "left",
        customRender: (text) => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
    },
    {
        title:"Sessions",
        dataIndex: "detail.sessions",
        align: "left",
        customRender: (text) => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
    },
    {
        title:"PV",
        dataIndex: "detail.pv",
        align: "left",
        customRender: (text) => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
    },
    {
        title:"P/S",
        dataIndex: "detail.p_s",
        align: "left",
        customRender: (text) => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
    },
    {
        title:"CPC上限",
        dataIndex: "detail.cpc_top",
        align: "left",
    },
    {
        title:"Avg.CPC",
        dataIndex: "detail.avg_cpc",
        align: "left",
    },
    {
        title:"Avg.CPC/CPC上限",
        align: "left",
        customRender: (text, record) => {
        const cpcTop = record.detail.cpc_top;
        const avgCpc = record.detail.avg_cpc;
        return avgCpc && cpcTop ? ((avgCpc / cpcTop) * 100).toFixed(2) + '%' : 'N/A';
        }
    },
    {
        title:"Clicks/Sessions",
        customRender: (text, record) => {
        const clicks = record.detail.clicks;
        const sessions = record.detail.sessions;
        return sessions && clicks ? ((clicks / sessions) * 100).toFixed(2) + '%' : 'N/A';
        },
        align: "left",
    },
    {
        title:"Prercent",
        align: "left",
        customRender: (text, record) => {
        const cpcTop = record.detail.cpc_top;
        const avgCpc = record.detail.avg_cpc;
        const clicks = record.detail.clicks;
        const sessions = record.detail.sessions;

        const avgCpcCpcTop = avgCpc && cpcTop ? (avgCpc / cpcTop) : null;
        const clicksSessions = clicks && sessions ? (clicks / sessions) : null;

        return avgCpcCpcTop !== null && clicksSessions !== null 
            ? ((avgCpcCpcTop * clicksSessions)* 100).toFixed(2) + '%' 
            : 'N/A';
        }
    },
    {
        title:"Section ID",
        dataIndex: "detail.site",
        align: "left",
    },
    {
        title:"是否已block",
        dataIndex: "is_success",
        customRender: (text, record) => {
        if (text) {
            return "是";
        } else {
            return `否 - ${record.block_info}`;
        }
    },
        align: "left",
    },
    {
        title:"是否已加入黑名单",
        dataIndex: "is_black",
        customRender: (text) => {
            return text ? "是" : "否";
        },
        align: "left",
    },
    {
        title:"Blacklist",
        scopedSlots: { customRender: "blacklist" },
        align: "left",
    },

]

export default {
  data() {
    return {
        Id: 0,
        taskList: [],
        columnList: columnList,
    };
    },
    created() {
        if (localStorage.userInfo) {
            this.userInfo = JSON.parse(localStorage.userInfo);
            }
            if (!this.userInfo.user_name) {
            this.$router.push({ path: "/login" });
            return false;
            }
            //如果路由中含有id，则为编辑页面，获取id对应的数据
            if (this.$route.query.id) {
            this.Id = this.$route.query.id;
            this.getTaskList();
            }
    },
    methods: {
        blacklist(e) {
            let id, site;
            if (e.platform === 'Taboola') {
                id = [e.detail.site_id];
                site = [e.detail.site_id, e.detail.site];
            } else if (e.platform === 'Outbrain') {
                id = [e.detail.site];
                site = [e.detail.site, e.detail.site];
            }
        this.$post(api.bysite.updateBlacklist, {
            platform: e.platform,
            type: e.type,
            marketer: e.account,
            token: this.userInfo.token,
            id: id,
            site: site,
        }).then((res) => {
            if (res.data.status === 200) {
            this.$message.success("已加入黑名单");
            } else {
            this.$message.error(res.data.message);
            }
        }).catch((error) => {
            this.$message.error("请求失败，请稍后重试");
            console.error(error);
        });
        },
        getTaskList() {
            this.$post(api.bysite.getSiteBlockCornRes,{ 
                token: this.userInfo.token, 
                cron_id: Number(this.Id)
            }).then((res) => {
                if(res.status == 200){
                    this.taskList = res.data.data;
                }
                else{
                    this.$message.error(res.message);
                }
            });
        },
    },
};

</script>