var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"ant-page-header-tl"},[_c('a-range-picker',{staticStyle:{"margin-right":"8px"},attrs:{"default-value":_vm.dateString},on:{"change":_vm.onDateChange}}),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.getData}},[_vm._v("Apply")]),_c('a-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary"},on:{"click":_vm.selectText}},[_vm._v("Copy")]),_c('span',{staticStyle:{"margin-left":"10px","float":"right"}}),_c('div',{staticStyle:{"float":"right","font-weight":"300","line-height":"center"}},[_c('span',{staticStyle:{"margin-left":"250px"}},[_vm._v(_vm._s(_vm.showDate.start_date)+" ～ "+_vm._s(_vm.showDate.end_date))]),_c('span',{staticStyle:{"margin-left":"24px"}},[_vm._v("Compare to")]),_c('span',{staticStyle:{"margin-left":"24px"}},[_vm._v(_vm._s(_vm.showDate.start_date_before)+" ～ "+_vm._s(_vm.showDate.end_date_before))])])],1),_c('div',{staticClass:"section"},[_c('a-table',{staticStyle:{"margin-bottom":"24px","max-width":"640px"},attrs:{"columns":_vm.tableColumn1,"data-source":_vm.tableList1,"rowKey":(record) => record.name,"pagination":false,"size":"small","bordered":"","loading":_vm.loading,"scroll":{ x: 500}},scopedSlots:_vm._u([{key:"z_num_wow_num",fn:function(text, record){return [_vm._v(" "+_vm._s(parseInt(text).toLocaleString())),_c('br'),_c('div',{class:[
              record.z_num_wow_percent > 0 ? 'color-green' : '',
              record.z_num_wow_percent < 0 ? 'color-red' : '',
              record.z_num_wow_percent === 0 ? 'color-grey' : ''
            ]},[_vm._v(" ("+_vm._s(record.z_num_wow_percent)+"%) ")])]}},{key:"z_num_dod_num",fn:function(text, record){return [_vm._v(" "+_vm._s(parseInt(text).toLocaleString())),_c('br'),_c('div',{class:[
              record.z_num_dod_percent > 0 ? 'color-green' : '',
              record.z_num_dod_percent < 0 ? 'color-red' : '',
              record.z_num_dod_percent === 0 ? 'color-grey' : ''
            ]},[_vm._v(" ("+_vm._s(record.z_num_dod_percent)+"%) ")])]}},{key:"z_num",fn:function(text){return [_vm._v(" "+_vm._s(parseInt(text).toLocaleString())+" ")]}}])}),_c('a-table',{staticStyle:{"margin-bottom":"24px","max-width":"800px"},attrs:{"columns":_vm.tableColumn2,"data-source":_vm.tableList2,"pagination":false,"size":"small","bordered":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"z_num",fn:function(text){return [_vm._v(" "+_vm._s(parseInt(text).toLocaleString())+" ")]}},{key:"z_num_wow_num",fn:function(text, record){return [_vm._v(" "+_vm._s(parseInt(text).toLocaleString())),_c('br'),_c('div',{class:{
              'color-green': record.z_num_wow_percent > 10,
              'color-red': record.z_num_wow_percent < -10,
              'color-grey': record.z_num_wow_percent >= -10 && record.z_num_wow_percent <= 10
            }},[_vm._v(" ("+_vm._s(record.z_num_wow_percent)+"%) ")])]}}])})],1),_c('div',{staticClass:"section"},[_c('a-tabs',{attrs:{"type":"card","default-active-key":"relaunch"}},[_c('a-tab-pane',{key:"relaunch",attrs:{"tab":"每日宗数","force-render":""}},[_c('div',[_c('a-table',{staticStyle:{"margin-bottom":"24px"},attrs:{"columns":_vm.columns1,"data-source":_vm.dayzumList.cc,"rowKey":(record) => record.sessions + record.slug + record.id,"pagination":false,"size":"small","bordered":"","loading":_vm.loading,"rowClassName":function(record) {
              if (!record.name.includes('Total')) {
                let className = 'color-black';
                return className;
              }else{
                _vm.className ='color-black-blod'
                return _vm.className;
              }
            },"expandRowByClick":true,"scroll":{ x: 900 }},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return _c('div',{staticClass:"smallbtn",staticStyle:{"padding":"4px 0px"}},[_c('a-button',{attrs:{"type":"link","size":"small"},on:{"click":function($event){return _vm.goDetail(record)}}},[_vm._v("Check")])],1)}},{key:"name",fn:function(text, record){return _c('span',{},[(record.tag_color)?_c('a-popover',[_c('template',{slot:"content"},[_vm._v(" "+_vm._s(record.tag_txt)+" ")]),_vm._v(" "+_vm._s(text)+" ")],2):_c('span',[_vm._v(" "+_vm._s(text)+" ")])],1)}},{key:"z_num_wow_num",fn:function(text, record){return [_c('div',{staticClass:"hoverbox"},[_vm._v(" "+_vm._s(parseInt(text).toLocaleString())),_c('br'),_c('div',{class:{
                  'color-green': record.tag_color_wow == 'g',
                  'color-red': record.tag_color_wow =='r',
                  'color-grey': record.tag_color_wow ==''
                }},[_vm._v(" ("+_vm._s(record.z_num_wow_percent)+"%) ")]),(record.tag_txt_wow!='')?_c('div',{staticClass:"hovertip"},[_vm._v(" "+_vm._s(record.tag_txt_wow)+" ")]):_vm._e()])]}},{key:"z_num_dod_num",fn:function(text, record){return [_c('div',{staticClass:"hoverbox"},[_vm._v(" "+_vm._s(parseInt(text).toLocaleString())),_c('br'),_c('div',{class:{
              'color-green': record.tag_color == 'g',
              'color-red': record.tag_color =='r',
              'color-grey': record.tag_color ==''
            }},[_vm._v(" ("+_vm._s(record.z_num_dod_percent)+"%) ")]),(record.tag_txt!='')?_c('div',{staticClass:"hovertip"},[_vm._v(" "+_vm._s(record.tag_txt)+" ")]):_vm._e()])]}}])}),_c('a-table',{staticStyle:{"margin-bottom":"24px"},attrs:{"columns":_vm.columns1,"data-source":_vm.dayzumList.hh,"rowKey":(record) => record.sessions + record.slug + record.id,"pagination":false,"size":"small","bordered":"","loading":_vm.loading,"rowClassName":function(record) {
                let className = 'color-black';
              if (!record.name.includes('Total')) {
              }else{
                className ='color-black-blod'
                
              }
              return className;
            },"expandRowByClick":true,"scroll":{ x: 900 }},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return _c('div',{staticClass:"smallbtn",staticStyle:{"padding":"4px 0px"}},[_c('a-button',{attrs:{"type":"link","size":"small"},on:{"click":function($event){return _vm.goDetail(record)}}},[_vm._v("Check")])],1)}},{key:"name",fn:function(text, record){return _c('span',{},[(record.tag_color)?_c('a-popover',[_c('template',{slot:"content"},[_vm._v(" "+_vm._s(record.tag_txt)+" ")]),_vm._v(" "+_vm._s(text)+" ")],2):_c('span',[_vm._v(" "+_vm._s(text)+" ")])],1)}},{key:"z_num_wow_num",fn:function(text, record){return [_c('div',{staticClass:"hoverbox"},[_vm._v(" "+_vm._s(parseInt(text).toLocaleString())),_c('br'),_c('div',{class:{
                  'color-green': record.tag_color_wow == 'g',
                  'color-red': record.tag_color_wow =='r',
                  'color-grey': record.tag_color_wow ==''
                }},[_vm._v(" ("+_vm._s(record.z_num_wow_percent)+"%) ")]),(record.tag_txt_wow!='')?_c('div',{staticClass:"hovertip"},[_vm._v(" "+_vm._s(record.tag_txt_wow)+" ")]):_vm._e()])]}},{key:"z_num_dod_num",fn:function(text, record){return [_c('div',{staticClass:"hoverbox"},[_vm._v(" "+_vm._s(parseInt(text).toLocaleString())),_c('br'),_c('div',{class:{
              'color-green': record.tag_color == 'g',
              'color-red': record.tag_color =='r',
              'color-grey': record.tag_color ==''
            }},[_vm._v(" ("+_vm._s(record.z_num_dod_percent)+"%) ")]),(record.tag_txt!='')?_c('div',{staticClass:"hovertip"},[_vm._v(" "+_vm._s(record.tag_txt)+" ")]):_vm._e()])]}}])}),_c('a-table',{attrs:{"columns":_vm.columns1,"data-source":_vm.dayzumList.team,"rowKey":(record) => record.sessions + record.slug,"pagination":false,"size":"small","bordered":"","loading":_vm.loading,"rowClassName":function(record) {
              if (!record.name.includes('Total')) {
                let className = 'color-black';
                return className;
              }else{
                _vm.className ='color-black-blod'
                return _vm.className;
              }
            },"expandRowByClick":true,"scroll":{ x: 900 }},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return _c('div',{staticClass:"smallbtn",staticStyle:{"padding":"4px 0px"}},[_c('a-button',{attrs:{"type":"link","size":"small"},on:{"click":function($event){return _vm.goDetail(record)}}},[_vm._v("Check")])],1)}},{key:"name",fn:function(text, record){return _c('span',{},[(record.tag_color)?_c('a-popover',[_c('template',{slot:"content"},[_vm._v(" "+_vm._s(record.tag_txt)+" ")]),_vm._v(" "+_vm._s(text)+" ")],2):_c('span',[_vm._v(" "+_vm._s(text)+" ")])],1)}},{key:"z_num_wow_num",fn:function(text, record){return [_c('div',{staticClass:"hoverbox"},[_vm._v(" "+_vm._s(parseInt(text).toLocaleString())),_c('br'),_c('div',{class:{
                  'color-green': record.tag_color_wow == 'g',
                  'color-red': record.tag_color_wow =='r',
                  'color-grey': record.tag_color_wow ==''
                }},[_vm._v(" ("+_vm._s(record.z_num_wow_percent)+"%) ")]),(record.tag_txt_wow!='')?_c('div',{staticClass:"hovertip"},[_vm._v(" "+_vm._s(record.tag_txt_wow)+" ")]):_vm._e()])]}},{key:"z_num_dod_num",fn:function(text, record){return [_c('div',{staticClass:"hoverbox"},[_vm._v(" "+_vm._s(parseInt(text).toLocaleString())),_c('br'),_c('div',{class:{
              'color-green': record.tag_color == 'g',
              'color-red': record.tag_color =='r',
              'color-grey': record.tag_color ==''
            }},[_vm._v(" ("+_vm._s(record.z_num_dod_percent)+"%) ")]),(record.tag_txt!='')?_c('div',{staticClass:"hovertip"},[_vm._v(" "+_vm._s(record.tag_txt)+" ")]):_vm._e()])]}}])})],1)]),_c('a-tab-pane',{key:"optimization",staticStyle:{"width":"80%"},attrs:{"tab":"季度宗数"}},[_c('a-table',{staticStyle:{"margin-bottom":"24px"},attrs:{"columns":_vm.columns2,"data-source":_vm.quarterList.cc,"rowKey":(record) => record.sessions + record.slug,"pagination":false,"size":"small","bordered":"","loading":_vm.loading2}}),_c('a-table',{staticStyle:{"margin-bottom":"24px"},attrs:{"columns":_vm.columns2,"data-source":_vm.quarterList.hh,"rowKey":(record) => record.sessions + record.slug,"pagination":false,"size":"small","bordered":"","loading":_vm.loading2}}),_c('a-table',{attrs:{"columns":_vm.columns2,"data-source":_vm.quarterList.team,"rowKey":(record) => record.sessions + record.slug,"pagination":false,"size":"small","bordered":"","loading":_vm.loading2}})],1)],1)],1),_c('div',{ref:"textToSelect",staticClass:"copypart"},[_c('a-table',{staticStyle:{"margin-bottom":"24px","max-width":"640px"},attrs:{"columns":_vm.tableColumn1_copy,"data-source":_vm.tableList1,"rowKey":(record) => record.name,"pagination":false,"size":"small","bordered":"","loading":_vm.loading,"scroll":{ x: 500}},scopedSlots:_vm._u([{key:"z_num_wow_num",fn:function(text, record){return [_vm._v(" "+_vm._s(parseInt(text).toLocaleString())),_c('br'),_c('div',{class:[
              record.z_num_wow_percent > 0 ? 'color-green' : '',
              record.z_num_wow_percent < 0 ? 'color-red' : '',
              record.z_num_wow_percent === 0 ? 'color-grey' : ''
            ]},[_vm._v(" ("+_vm._s(record.z_num_wow_percent)+"%) ")])]}},{key:"z_num_dod_num",fn:function(text, record){return [_vm._v(" "+_vm._s(parseInt(text).toLocaleString())),_c('br'),_c('div',{class:[
              record.z_num_dod_percent > 0 ? 'color-green' : '',
              record.z_num_dod_percent < 0 ? 'color-red' : '',
              record.z_num_dod_percent === 0 ? 'color-grey' : ''
            ]},[_vm._v(" ("+_vm._s(record.z_num_dod_percent)+"%) ")])]}},{key:"z_num",fn:function(text){return [_vm._v(" "+_vm._s(parseInt(text).toLocaleString())+" ")]}}])}),_c('a-table',{staticStyle:{"margin-bottom":"24px","max-width":"800px"},attrs:{"columns":_vm.tableColumn2_copy,"data-source":_vm.tableList2,"pagination":false,"size":"small","bordered":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"z_num",fn:function(text){return [_vm._v(" "+_vm._s(parseInt(text).toLocaleString())+" ")]}},{key:"z_num_wow_num",fn:function(text, record){return [_vm._v(" "+_vm._s(parseInt(text).toLocaleString())),_c('br'),_c('div',{class:{
              'color-green': record.z_num_wow_percent > 10,
              'color-red': record.z_num_wow_percent < -10,
              'color-grey': record.z_num_wow_percent >= -10 && record.z_num_wow_percent <= 10
            }},[_vm._v(" ("+_vm._s(record.z_num_wow_percent)+"%) ")])]}}])}),_c('a-table',{staticStyle:{"margin-bottom":"24px"},attrs:{"columns":_vm.columns1_copy,"data-source":_vm.dayzumList.cc,"rowKey":(record) => record.sessions + record.slug + record.id,"pagination":false,"size":"small","bordered":"","loading":_vm.loading,"rowClassName":function(record) {
              if (!record.name.includes('Total')) {
                let className = 'color-black';
                return className;
              }else{
                _vm.className ='color-black-blod'
                return _vm.className;
              }
            },"expandRowByClick":true,"scroll":{ x: 900 }},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return _c('div',{staticClass:"smallbtn",staticStyle:{"padding":"4px 0px"}},[_c('a-button',{attrs:{"type":"link","size":"small"},on:{"click":function($event){return _vm.goDetail(record)}}},[_vm._v("Check")])],1)}},{key:"name",fn:function(text, record){return _c('span',{},[(record.tag_color)?_c('a-popover',[_c('template',{slot:"content"},[_vm._v(" "+_vm._s(record.tag_txt)+" ")]),_vm._v(" "+_vm._s(text)+" ")],2):_c('span',[_vm._v(" "+_vm._s(text)+" ")])],1)}},{key:"z_num_wow_num",fn:function(text, record){return [_c('div',{staticClass:"hoverbox"},[_vm._v(" "+_vm._s(parseInt(text).toLocaleString())),_c('br'),_c('div',{class:{
                  'color-green': record.tag_color_wow == 'g',
                  'color-red': record.tag_color_wow =='r',
                  'color-grey': record.tag_color_wow ==''
                }},[_vm._v(" ("+_vm._s(record.z_num_wow_percent)+"%) ")]),(record.tag_txt_wow!='')?_c('div',{staticClass:"hovertip"},[_vm._v(" "+_vm._s(record.tag_txt_wow)+" ")]):_vm._e()])]}},{key:"z_num_dod_num",fn:function(text, record){return [_c('div',{staticClass:"hoverbox"},[_vm._v(" "+_vm._s(parseInt(text).toLocaleString())),_c('br'),_c('div',{class:{
              'color-green': record.tag_color == 'g',
              'color-red': record.tag_color =='r',
              'color-grey': record.tag_color ==''
            }},[_vm._v(" ("+_vm._s(record.z_num_dod_percent)+"%) ")]),(record.tag_txt!='')?_c('div',{staticClass:"hovertip"},[_vm._v(" "+_vm._s(record.tag_txt)+" ")]):_vm._e()])]}}])}),_c('a-table',{staticStyle:{"margin-bottom":"24px"},attrs:{"columns":_vm.columns1_copy,"data-source":_vm.dayzumList.hh,"rowKey":(record) => record.sessions + record.slug + record.id,"pagination":false,"size":"small","bordered":"","loading":_vm.loading,"rowClassName":function(record) {
            let className = 'color-black';
          if (!record.name.includes('Total')) {
          }else{
            className ='color-black-blod'
            
          }
          return className;
        },"expandRowByClick":true,"scroll":{ x: 900 }},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return _c('div',{staticClass:"smallbtn",staticStyle:{"padding":"4px 0px"}},[_c('a-button',{attrs:{"type":"link","size":"small"},on:{"click":function($event){return _vm.goDetail(record)}}},[_vm._v("Check")])],1)}},{key:"name",fn:function(text, record){return _c('span',{},[(record.tag_color)?_c('a-popover',[_c('template',{slot:"content"},[_vm._v(" "+_vm._s(record.tag_txt)+" ")]),_vm._v(" "+_vm._s(text)+" ")],2):_c('span',[_vm._v(" "+_vm._s(text)+" ")])],1)}},{key:"z_num_wow_num",fn:function(text, record){return [_c('div',{staticClass:"hoverbox"},[_vm._v(" "+_vm._s(parseInt(text).toLocaleString())),_c('br'),_c('div',{class:{
              'color-green': record.tag_color_wow == 'g',
              'color-red': record.tag_color_wow =='r',
              'color-grey': record.tag_color_wow ==''
            }},[_vm._v(" ("+_vm._s(record.z_num_wow_percent)+"%) ")]),(record.tag_txt_wow!='')?_c('div',{staticClass:"hovertip"},[_vm._v(" "+_vm._s(record.tag_txt_wow)+" ")]):_vm._e()])]}},{key:"z_num_dod_num",fn:function(text, record){return [_c('div',{staticClass:"hoverbox"},[_vm._v(" "+_vm._s(parseInt(text).toLocaleString())),_c('br'),_c('div',{class:{
          'color-green': record.tag_color == 'g',
          'color-red': record.tag_color =='r',
          'color-grey': record.tag_color ==''
        }},[_vm._v(" ("+_vm._s(record.z_num_dod_percent)+"%) ")]),(record.tag_txt!='')?_c('div',{staticClass:"hovertip"},[_vm._v(" "+_vm._s(record.tag_txt)+" ")]):_vm._e()])]}}])}),_c('a-table',{attrs:{"columns":_vm.columns1_copy,"data-source":_vm.dayzumList.team,"rowKey":(record) => record.sessions + record.slug,"pagination":false,"size":"small","bordered":"","loading":_vm.loading,"rowClassName":function(record) {
          if (!record.name.includes('Total')) {
            let className = 'color-black';
            return className;
          }else{
            _vm.className ='color-black-blod'
            return _vm.className;
          }
        },"expandRowByClick":true,"scroll":{ x: 900 }},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return _c('div',{staticClass:"smallbtn",staticStyle:{"padding":"4px 0px"}},[_c('a-button',{attrs:{"type":"link","size":"small"},on:{"click":function($event){return _vm.goDetail(record)}}},[_vm._v("Check")])],1)}},{key:"name",fn:function(text, record){return _c('span',{},[(record.tag_color)?_c('a-popover',[_c('template',{slot:"content"},[_vm._v(" "+_vm._s(record.tag_txt)+" ")]),_vm._v(" "+_vm._s(text)+" ")],2):_c('span',[_vm._v(" "+_vm._s(text)+" ")])],1)}},{key:"z_num_wow_num",fn:function(text, record){return [_c('div',{staticClass:"hoverbox"},[_vm._v(" "+_vm._s(parseInt(text).toLocaleString())),_c('br'),_c('div',{class:{
              'color-green': record.tag_color_wow == 'g',
              'color-red': record.tag_color_wow =='r',
              'color-grey': record.tag_color_wow ==''
            }},[_vm._v(" ("+_vm._s(record.z_num_wow_percent)+"%) ")]),(record.tag_txt_wow!='')?_c('div',{staticClass:"hovertip"},[_vm._v(" "+_vm._s(record.tag_txt_wow)+" ")]):_vm._e()])]}},{key:"z_num_dod_num",fn:function(text, record){return [_c('div',{staticClass:"hoverbox"},[_vm._v(" "+_vm._s(parseInt(text).toLocaleString())),_c('br'),_c('div',{class:{
          'color-green': record.tag_color == 'g',
          'color-red': record.tag_color =='r',
          'color-grey': record.tag_color ==''
        }},[_vm._v(" ("+_vm._s(record.z_num_dod_percent)+"%) ")]),(record.tag_txt!='')?_c('div',{staticClass:"hovertip"},[_vm._v(" "+_vm._s(record.tag_txt)+" ")]):_vm._e()])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }