<template>
  <div :class="['aside', collapsed ? 'smaside' : '']">
    <!-- <div class="username">Root</div> -->
    <a-popconfirm
      placement="right"
      title="你要切换账户吗?"
      ok-text="是的"
      cancel-text="取消"
      @confirm="confirm"
      @cancel="cancel"
    >
      <div class="laruser">
        <img
          v-if="userInfo.image"
          class="avatar"
          :src="userInfo.image"
          alt=""
        />
        <img v-else class="avatar" src="../assets/avatar.png" alt="" />
        <p v-if="userInfo.nickname">{{ userInfo.nickname }}</p>
      </div>
    </a-popconfirm>

    <div class="hidemenu">
      <a-button style="padding: 8px 0;height: auto;" @click="toggleCollapsed">
        <a-icon :type="collapsed ? 'right' : 'left'" />
      </a-button>
    </div>
    <a-menu
      :defaultSelectedKeys="['100']"
      :selectedKeys="selectedKeys"
      mode="inline"
      theme="dark"
      :inlineCollapsed="collapsed"
      collapsed="true"
      :open-keys="openKeys"
      @select="menuSelect"
      @openChange="onOpenChange"
    >
      <a-sub-menu key="100">
        <span slot="title"
          ><a-icon type="profile" /><span>SNS Editors</span></span
        >
        <a-menu-item key="102">
          <a-icon type="team" />
          <router-link :to="{ path: '/WorkTotal' }">
            Work Perf
          </router-link>
        </a-menu-item>
        <a-menu-item key="103">
          <a-icon type="profile" />
          <router-link :to="{ path: '/splitFlowPerf' }">
            分流Perf
          </router-link>
        </a-menu-item>

        <a-sub-menu key="104">
          <span slot="title"
            ><a-icon type="plus-circle" /><span>推送添加</span></span
          >
          <a-menu-item key="1041">
            <a-icon type="plus" />
            <router-link :to="{ path: '/PushAdd' }">
              G3推送添加
            </router-link>
          </a-menu-item>
          <a-menu-item key="1042">
            <a-icon type="plus" />
            <router-link :to="{ path: '/G7PushAdd' }">
              G7推送添加
            </router-link>
          </a-menu-item>
        </a-sub-menu>
      </a-sub-menu>

      <a-sub-menu key="200">
        <span slot="title"
          ><a-icon type="build" /><span>SNS Promotion</span></span
        >
        <a-menu-item key="201">
          <a-icon type="bell" />
          <router-link :to="{ path: '/performance' }">
            Perf by Campaign
          </router-link>
        </a-menu-item>

        <a-menu-item key="202">
          <a-icon type="issues-close" />
          <router-link :to="{ path: '/cover' }">
            Campaign 覆盖情况
          </router-link>
        </a-menu-item>

        <a-sub-menu key="203">
          <span slot="title"><a-icon type="heart" /><span>优化</span></span>
          <a-menu-item key="2031">
            <a-icon type="layout" />
            <router-link :to="{ path: '/campaignaction' }">
              新建
            </router-link>
          </a-menu-item>

          <a-menu-item key="2032">
            <a-icon type="like" />
            <router-link :to="{ path: '/topcampaign' }">
              Top Campaign 自动覆盖
            </router-link>
          </a-menu-item>
          <a-menu-item key="2033">
            <a-icon type="crown" />
            <router-link :to="{ path: '/valuepost' }">
              Valuable Post 批量覆盖
            </router-link>
          </a-menu-item>
          <a-menu-item key="20331">
            <a-icon type="crown" />
            <router-link :to="{ path: '/SameCpnCover' }">
              相同 Campaign 类型覆盖
            </router-link>
          </a-menu-item>
          <a-menu-item key="2034">
            <a-icon type="trophy" />
            <router-link :to="{ path: '/better' }">
              Optimization
            </router-link>
          </a-menu-item>
          <a-menu-item key="2035">
            <a-icon type="trophy" />
            <router-link :to="{ path: '/tasklog' }">
              Data Update Log
            </router-link>
          </a-menu-item>
        </a-sub-menu>

        <a-sub-menu key="204">
          <span slot="title"><a-icon type="build" /><span>Project</span></span>
          <a-menu-item key="2041">
            <a-icon type="layout" />
            <router-link :to="{ path: '/PromoteWorkTotal' }">
              Work Perf
            </router-link>
          </a-menu-item>

          <a-menu-item key="2042">
            <a-icon type="layout" />
            <router-link :to="{ path: '/PromoteProject' }">
              Post Perf
            </router-link>
          </a-menu-item>

          <a-menu-item key="2043">
            <a-icon type="layout" />
            <router-link :to="{ path: '/CampaignTracking' }">
              Campaign Tracking
            </router-link>
          </a-menu-item>
        </a-sub-menu>

        <a-menu-item key="205">
          <a-icon type="poweroff" />
          <router-link :to="{ path: '/pause-campaign' }">
            Pause
          </router-link>
        </a-menu-item>

        <a-menu-item key="206">
          <a-icon type="book" />
          <router-link :to="{ path: '/bysite' }">
            By Site
          </router-link>
        </a-menu-item>

        <a-sub-menu key="207">
          <span slot="title"
            ><a-icon type="disconnect" /><span>Site Blocking</span></span
          >
          <a-menu-item key="2071">
            <a-icon type="stop" />
            <router-link :to="{ path: '/siteblocking' }">
              To Block
            </router-link>
          </a-menu-item>
          <a-menu-item key="2072">
            <a-icon type="file" />
            <router-link :to="{ path: '/siteblockinglog' }">
              Block Log
            </router-link>
          </a-menu-item>
          <a-menu-item key="2073">
            <a-icon type="book" />
            <router-link :to="{ path: '/siteunblock' }">
              Site Unblock
            </router-link>
          </a-menu-item>
          <a-menu-item key="2074">
            <a-icon type="smile" />
            <router-link :to="{ path: '/autoblock' }">
              Site Auto Block
            </router-link>
          </a-menu-item>
        </a-sub-menu>

        <a-menu-item key="208">
          <a-icon type="file-search" />
          <router-link :to="{ path: '/alanreport' }">
            Alan 报告
          </router-link>
        </a-menu-item>
      </a-sub-menu>

      <a-sub-menu key="300">
        <span slot="title"
          ><a-icon type="line-chart" /><span>Company Report</span></span
        >

        <a-sub-menu key="301">
          <span slot="title"
            ><a-icon type="account-book" />
            <span>Zindex Report</span>
          </span>
          <a-menu-item key="3017">
            <a-icon type="stock" />
            <router-link :to="{ path: '/allsiteszumdata01' }">
              Zindex by Platform (+not set)
            </router-link>
          </a-menu-item>

          <a-menu-item key="3011">
            <a-icon type="stock" />
            <router-link :to="{ path: '/BingSpentReport' }">
              Bing Spent Report
            </router-link>
          </a-menu-item>

          <a-menu-item key="3016">
            <a-icon type="shrink" />
            <router-link :to="{ path: '/ZindexByProduct' }">
              Zindex by Product
            </router-link>
          </a-menu-item>


          <!-- <a-menu-item key="3012">
            <a-icon type="stock" />
            <router-link :to="{ path: '/allsiteszum4' }">
              Zindex by Platform (GA4)
            </router-link>
          </a-menu-item> -->
          <a-menu-item key="3013">
            <a-icon type="rise" />
            <router-link :to="{ path: '/allsiteszum' }">
              Zindex by Platform (GA3)
            </router-link>
          </a-menu-item>

          <a-menu-item key="3015">
            <a-icon type="shrink" />
            <router-link :to="{ path: '/BingTimezoneReport' }">
              Bing 时区报告
            </router-link>
          </a-menu-item>

          <a-menu-item key="3014">
            <a-icon type="control" />
            <router-link :to="{ path: '/RevenueCompare' }">
              Revenue Compare
            </router-link>
          </a-menu-item>

          <a-menu-item key="3010">
            <a-icon type="shrink" />
            <router-link :to="{ path: '/daily-zumexpress' }">
              Daily Zindex
            </router-link>
          </a-menu-item>
        </a-sub-menu>

        <a-sub-menu key="305">
          <span slot="title"
            ><a-icon type="account-book" />
            <span>Daily Report</span>
          </span>
          <a-menu-item key="3051">
            <a-icon type="stock" />
            <router-link :to="{ path: '/adw-daily-ga4' }">
              Adwords Daily
            </router-link>
          </a-menu-item>
          <a-menu-item key="3052">
            <a-icon type="stock" />
            <router-link :to="{ path: '/bing-daily-ga4' }">
              Bing Daily
            </router-link>
          </a-menu-item>
        </a-sub-menu>

        <!-- <a-menu-item key="302">
          <a-icon type="reconciliation" />
          <router-link :to="{ path: '/data' }">
            PV Report
          </router-link>
        </a-menu-item>

        <a-menu-item key="303">
          <a-icon type="notification" />
          <router-link :to="{ path: '/pushdata' }">
            Push Report
          </router-link>
        </a-menu-item>

        <a-menu-item key="304">
          <a-icon type="notification" />
          <router-link :to="{ path: '/adwperfbytype' }">
            Adw Perf
          </router-link>
        </a-menu-item> -->
      </a-sub-menu>
    </a-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      collapsed: true,
      selectedKeys: ["0"],
      username: "",
      rootSubmenuKeys: ["100", "200", "300"],
      openKeys: [],
      lastOpenKeys: [],
      menuFirstOpen: true,
    };
  },
  methods: {
    confirm() {
      localStorage.removeItem("userInfo");
      this.$router.push({ path: "/login" });
    },
    cancel() {},
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
      if (this.collapsed == false && this.menuFirstOpen) {
        this.openKeys = ["200"];
        this.menuFirstOpen = false;
      } else if (this.collapsed == false && !this.menuFirstOpen) {
        this.openKeys = this.lastOpenKeys;
      } else {
        this.lastOpenKeys = this.openKeys;
        this.openKeys = [];
      }
    },
    menuSelect({ selectedKeys }) {
      this.selectedKeys = selectedKeys;
    },
    // 自动关闭
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
  },
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
  },
};
</script>

<style>
.aside {
  position: relative;
  padding-top: 40px;
  width: 256px;
  flex: 0 0 256px;
  background-color: #001529;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  transition: all ease 0.25s;
}
.smaside {
  width: 80px;
  flex: 0 0 80px;
}
.smaside .ant-menu-item {
  font-size: 0;
}
.ant-menu-item {
  display: flex;
  align-items: center;
}
.hidemenu {
  position: absolute;
  right: -6px;
  top: 10px;
  z-index: 99;
}
.laruser {
  /* padding-left: 24px; */
  color: rgba(255, 255, 255, 0.75);
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 16px;
  text-align: center;
  cursor: pointer;
}
.avatar {
  width: 54px;
  height: 54px;
  border-radius: 90px;
  background-color: rgba(255, 255, 255, 0.25);
  margin-bottom: 10px;
}
</style>
