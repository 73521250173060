<template>
  <div>
    <!-- <div class="ant-page-header-tl">
      调价
    </div> -->
    <div class="section inputedit">
      <a-table 
          :columns="columnsCam" 
          :data-source="curCampaign" 
          :scroll="{ x: 2600, y: 800}"
          :rowKey="record => record.campaign_id"
          :pagination="false" 
          size="small"
          bordered
        >
          <div slot="campaign_id" slot-scope="text" style="width: 102px;overflow: hidden;">{{text}}</div>
          <div style="text-decoration: underline;cursor: pointer;width: 220px;overflow:hidden;" slot="toline" slot-scope="text, record" @click="toLine(record.campaign_id)">
            {{text}}
          </div>
          <div slot="default_cpc" slot-scope="text, record">
            <input
              class="inputtxt"
              type="text"
              v-model="record.suggest_cpc"
              @blur="inputBlur(record.suggest_cpc)"
            />
          </div>
          <div slot="daily_budget" slot-scope="text, record">
            <input
              class="inputtxt"
              type="text"
              v-model="record.daily_budget"
            />
          </div>
        </a-table>
    </div>
    <!-- table -->
    <div class="section">
        <div style="text-align: right;"><a-button style="margin-bottom: 16px;" type="primary" @click="submitCheck">Apply</a-button></div>
        <a-table 
          :columns="columns" 
          :data-source="dataList" 
          :scroll="{ x: 2000, y: 1000}"
          :rowKey="record => record.site_id"
          :pagination="{pageSize: pageSize}"
          size="small"
          bordered
          :loading="loading"
        >
          <div slot="site_id" slot-scope="text" style="width: 102px;overflow: hidden;">{{text}}</div>
          <div style="width: 220px;overflow:hidden;" slot="site_name" slot-scope="text, record" @click="toLine(record.campaign_id)">
            {{text}}
          </div>
          <div class="smallbtn" slot="action" slot-scope="text, record">
            <a-input-number
              :defaultValue="text"
              :step="5"
              :formatter="(value) => `${value}%`"
              :parser="(value) => value.replace('%', '')"
              @change="(value) => onChange(value, record)"
            />
          </div>
        </a-table>
    </div>
  </div>
</template>
<script>
  import api from '@/common/api.js'
  // import moment from 'moment';
  const columnsCam = [
    {
      title:'Platform',
      dataIndex: 'platform',
      // sorter: (a, b) => {
      //   return a.platform.localeCompare(b.platform)
      // },
      fixed: 'left',
      width: 100,
    },
    {
      title:'Campaign ID',
      dataIndex: 'campaign_id',
      // sorter: (a, b) => {
      //   return a.campaign_id.localeCompare(b.campaign_id)
      // },
      fixed: 'left',
      width: 125,
      scopedSlots: {customRender: 'campaign_id'}
    },
    {
      title:'Campaign Name',
      dataIndex: 'campaign_name',
      // sorter: (a, b) => {
      //   return a.campaign_name.localeCompare(b.campaign_name)
      // },
      scopedSlots: { customRender: 'toline' },
      fixed: 'left',
      width: 240,
    },
    {
      title:'Campaign Status',
      dataIndex: 'campaign_status',
      // sorter: (a, b) => {
      //   return a.campaign_status.localeCompare(b.campaign_status)
      // },
      width: 180
    },
    {
      title:'Start Date',
      dataIndex: 'start_date',
      // sorter: (a, b) => {
      //   return a.start_date.localeCompare(b.start_date)
      // },
      width: 120
    },
    {
      title:'Default CPC',
      dataIndex: 'default_cpc',
      align: 'right',
      scopedSlots: {customRender: "default_cpc"},
      // sorter: (a, b) => {
      //   return a.default_cpc - b.default_cpc
      // },
      width: 130,
    },
    {
      title:'Daily Budget',
      dataIndex: 'daily_budget',
      align: 'right',
      scopedSlots: {customRender: "daily_budget"},
      // sorter: (a, b) => {
      //   return a.daily_budget - b.daily_budget
      // },
      width: 130,
    },
    {
      title:'Revenue',
      dataIndex: 'revenue',
      align: 'right',
      customRender: text => {
        return parseFloat(text.toString()).toFixed(2).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      // sorter: (a, b) => {
      //   return a.revenue - b.revenue
      // },
      width: 130,
    },
    {
      title:'Spent',
      dataIndex: 'spent',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      // sorter: (a, b) => {
      //   if (!a.platform.includes("Total")) {
      //     return a.spent - b.spent
      //   }
      // },
      width: 80,
    },
    {
      title:'ROAS',
      dataIndex: 'roas',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      // sorter: (a, b) => {
      //   if (!a.platform.includes("Total")) {
      //     return a.roas - b.roas
      //   }
      // },
      width: 80,
    },
    {
      title:'Affilite Conversions ',
      dataIndex: 'aff_conversions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      // sorter: (a, b) => {
      //   return a.aff_conversions - b.aff_conversions
      // },
      width: 180,
    },
    {
      title:'Affilite CPA ',
      dataIndex: 'aff_cpa',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      // sorter: (a, b) => {
      //   return a.aff_cpa - b.aff_cpa
      // },
      width: 130,
    },
    {
      title:'Affilite CVR ',
      dataIndex: 'aff_cvr',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      // sorter: (a, b) => {
      //   return a.aff_cvr - b.aff_cvr
      // },
      width: 130,
    },
    {
      title:'Outbound Conversions',
      dataIndex: 'outbound_conversions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      // sorter: (a, b) => {
      //   return a.outbound_conversions - b.outbound_conversions
      // },
      width: 200,
    },
    {
      title:'Outbond CPA ',
      dataIndex: 'outbound_cpa',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      // sorter: (a, b) => {
      //   return a.outbound_cpa - b.outbound_cpa
      // },
      width: 130,
    },
    {
      title:'Outbond CVR',
      dataIndex: 'outbound_cvr',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      // sorter: (a, b) => {
      //   return a.outbound_cvr - b.outbound_cvr
      // },
      width: 130,
    },
    {
      title:'Impressions',
      dataIndex: 'impressions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      // sorter: (a, b) => {
      //   return a.impressions - b.impressions
      // },
      width: 130,
    },
    {
      title:'Clicks',
      dataIndex: 'clicks',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      // sorter: (a, b) => {
      //   return a.clicks - b.clicks
      // },
      width: 80,
    },
    {
      title:'CTR',
      dataIndex: 'ctr',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      // sorter: (a, b) => {
      //   return a.ctr - b.ctr
      // },
      width: 80,
    },
    {
      title:'Avg.CPC',
      dataIndex: 'avg_cpc',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      // sorter: (a, b) => {
      //   return a.avg_cpc - b.avg_cpc
      // },
      width: 100,
    }
  ];
  const columns = [
    {
      title:'Site ID',
      dataIndex: 'site_id',
      sorter: (a, b) => {
        return a.site_id.localeCompare(b.site_id)
      },
      fixed: 'left',
      width: 125,
      scopedSlots: {customRender: 'site_id'}
    },
    {
      title:'Site Name',
      dataIndex: 'site_name',
      sorter: (a, b) => {
        return a.campaign_name.localeCompare(b.campaign_name)
      },
      scopedSlots: { customRender: 'site_name' },
      fixed: 'left',
      width: 240,
    },
    {
      title:'Revenue',
      dataIndex: 'revenue',
      align: 'right',
      customRender: text => {
        return parseFloat(text.toString()).toFixed(2).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.revenue - b.revenue
      },
      width: 130,
    },
    {
      title:'Spent',
      dataIndex: 'spent',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        if (!a.platform.includes("Total")) {
          return a.spent - b.spent
        }
      },
      width: 80,
    },
    {
      title:'ROAS',
      dataIndex: 'roas',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {
        if (!a.platform.includes("Total")) {
          return a.roas - b.roas
        }
      },
      width: 80,
    },
    {
      title:'Bid Before',
      dataIndex: 'bid_before',
      align: 'right',
      sorter: (a, b) => {
        if (!a.platform.includes("Total")) {
          return a.roas - b.roas
        }
      },
      width: 120,
    },
    {
      title:'Bid After',
      dataIndex: 'bid_after',
      align: 'right',
      sorter: (a, b) => {
        if (!a.platform.includes("Total")) {
          return a.roas - b.roas
        }
      },
      width: 120,
    },
    {
      title:'Affilite Conversions ',
      dataIndex: 'aff_conversions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.aff_conversions - b.aff_conversions
      },
      width: 180,
    },
    {
      title:'Affilite CPA ',
      dataIndex: 'aff_cpa',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.aff_cpa - b.aff_cpa
      },
      width: 130,
    },
    {
      title:'Affilite CVR ',
      dataIndex: 'aff_cvr',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {
        return a.aff_cvr - b.aff_cvr
      },
      width: 130,
    },
    {
      title:'Outbound Conversions',
      dataIndex: 'outbound_conversions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.outbound_conversions - b.outbound_conversions
      },
      width: 200,
    },
    {
      title:'Outbond CPA ',
      dataIndex: 'outbound_cpa',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.outbound_cpa - b.outbound_cpa
      },
      width: 130,
    },
    {
      title:'Outbond CVR',
      dataIndex: 'outbound_cvr',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {
        return a.outbound_cvr - b.outbound_cvr
      },
      width: 130,
    },
    {
      title:'Impressions',
      dataIndex: 'impressions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.impressions - b.impressions
      },
      width: 150,
    },
    {
      title:'Clicks',
      dataIndex: 'clicks',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.clicks - b.clicks
      },
      width: 120,
    },
    {
      title:'CTR',
      dataIndex: 'ctr',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {
        return a.ctr - b.ctr
      },
      width: 120,
    },
    {
      title:'Avg.CPC',
      dataIndex: 'avg_cpc',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.avg_cpc - b.avg_cpc
      },
      width: 100,
    },
    {
      title: "+/-",
      dataIndex: 'suggest_percent',
      scopedSlots: {customRender: "action"},
      align: "center",
      width: 120,
      fixed: 'right',
    }
  ]
  export default {
    data() {
      return {
        columnsCam,
        columns,
        dataList: [],
        curCampaign: [],
        curItem: [],
        site_list: [],
        platform: '',
        loading: false,
        pageSize: 50
      }
    },
    methods: {
      inputBlur(text) {
        console.log(text)
        console.log(this.curItem.default_cpc)
        if(this.curItem.default_cpc != text){
          this.curItem.default_cpc = text
          this.dataList.forEach(item => {
            item.bid_after = text * (100 + item.suggest_percent)/100
          })
        }
      },
      submitCheck() {
        if(this.curItem.suggest_cpc > 0.12){
          this.$confirm({
            title: 'Please Notice！',
            content: 'Your Default CPC is over 0.12, Doublecheck Before Change.',
            okText: 'Save',
            onOk: () => {
              this.submitData()
            },
            onCancel: () => {
              console.log('Cancel');
            },
          });
        }else{
          this.submitData()
        }  
      },
      submitData() {
        this.loading = true;
        let params = {
          default_cpc: this.curItem.suggest_cpc,
          daily_budget: this.curItem.daily_budget,
          site_list: this.site_list
        }
        // console.log(params)
        this.post(api.aff.adjustSiteCpc, {
            token: this.userInfo.token,
            campaign_id: this.curItem.campaign_id,
            params: params
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status === 200) {
              this.$message.success("提交成功");
            } else {
              this.$message.error(res.data.message);
            }
          });
      },
      onChange(value, record) {
        console.log(this.site_list)
        
        let newBidAfter = this.curItem.default_cpc * (100 + value)/100
        // console.log(newBidAfter)
        if( newBidAfter <= 0.01 && this.platform == 'TBL'){
          this.$message.error('Suggested CPC After不能低于0.01')
          return false
        }
        if( newBidAfter <= 0.03 && this.platform == 'OB'){
          this.$message.error('Suggested CPC After不能低于0.03')
          return false
        }
        this.site_list.forEach(item => {
          if (item.site_id == record.site_id && this.platform == 'OB') {
            item.bid_value = value;
          }
          if (item.site == record.site && this.platform == 'TBL') {
            item.bid_value = value;
          }
        });
        record.bid_after = newBidAfter.toFixed(3)
        record.suggest_percent = value
      },
      getList(){
        this.loading = true
        this.$post(api.aff.getSitesByCampaign, {
          campaign_id: this.curItem.campaign_id,
          token: this.userInfo.token
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            let data = res.data.data
            this.dataList = data
            data.forEach((item) => {
              let site_json = {
                bid_value: item.suggest_percent,
              }
              if(this.platform == "OB"){
                // site_json.site = item.site_name
                site_json.site_id = item.site_id
              }else if(this.platform == "TBL"){
                site_json.site = item.site
              }
              this.site_list.push(site_json);
              // this.bid_after = item.bid_after;
            })
          } else {
            this.$message.error(res.data.message)
          }
        });
      }
    },
    created(){
      if (localStorage.userInfo) {
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.curItem = JSON.parse(this.$route.query.record) 
      this.curItem.suggest_cpc = this.curItem.default_cpc
      this.curCampaign[0] = this.curItem 
      this.platform = this.curItem.platform
      console.log(this.curCampaign)
      this.getList()
    }
  };
</script>
<style> 
  

</style>
