<template>
  <!-- 导航栏顶部 -->
  <div>
    <div class="ant-page-header-tl">Campaign Performance By Day
      <div class="righttop">
        <a-range-picker :default-value="dateString" @change="onDateChange"/>
        <a-button type="primary" style="margin-left: 16px;" @click="submitRangeChange">Apply</a-button>
      </div>
    </div>

    <!-- 图表 -->
    <div class="section-wrapper">
      <a-card :loading="loading" :bordered="false" :title="title">
        <a-row>
          <a-col :span="20">
            <ve-line :data="velineData" :legend-visible="false" :settings="chartSettings" :extend="chartExtend"></ve-line>
          </a-col>
          <a-col :span="3" :offset="1">
            <div class="checkitem">
              <a-switch class="revenue" default-checked @change="onRevenueChange" />
              Revenue
            </div>
            <div class="checkitem">
              <a-switch class="spent" default-checked @change="onSpentChange" />
              Spent
            </div>
            <div class="checkitem">
              <a-switch class="roas" @change="onRoasChange" />
              ROAS
            </div>
            <div class="checkitem">
              <a-switch class="aff_conversions" @change="onAffConversionsChange" />
              Affilite Conversions
            </div>
            <div class="checkitem">
              <a-switch class="aff_cpa" @change="onAffCpaChange" />
              Affilite CPA
            </div>
            <div class="checkitem">
              <a-switch class="aff_cvr" @change="onAffCvrChange" />
              Affilite CVR
            </div>
            <div class="checkitem">
              <a-switch class="outbound_conversions" @change="onOutboundConversionsChange" />
              Outbound Conversions
            </div>
            <div class="checkitem">
              <a-switch class="outbound_cpa" @change="onOutboundCpaChange" />
              Outbond CPA
            </div>
            <div class="checkitem">
              <a-switch class="outbound_cvr" @change="onOutboundCvrChange" />
              Outbond CVR
            </div>
            <div class="checkitem">
              <a-switch class="impressions" @change="onImpressinsChange" />
              Impressions
            </div>
            <div class="checkitem">
              <a-switch class="clicks" @change="onClicksChange" />
              Clicks
            </div>
            <div class="checkitem">
              <a-switch class="ctr" @change="onCtrChange" />
              CTR
            </div>
            <div class="checkitem">
              <a-switch class="avg_cpc" @change="onAvgCpcChange" />
              Avg.CPC
            </div>
          </a-col>
        </a-row>
      </a-card> 
    </div>

    <!-- 表格 -->
    <div class="section-wrapper">
      <a-card :bordered="false">
        <a-table 
          :columns="columns" 
          :data-source="dataList" 
          :rowKey="record => record.date"
          :pagination="false" 
          size="middle"
          bordered
        >
        </a-table>
      </a-card>
    </div>
  </div>

</template>
<script>
  import api from '@/common/api.js'
  import moment from 'moment';
  const defaultStartDate = moment().startOf('day').subtract(8, 'days');
  const defaultEndDate = moment().startOf('day').subtract(2, 'days');

  const columns = [
    {
      title:'日期',
      dataIndex: 'date',
      sorter: (a, b) => a.date_sort - b.date_sort,
    },
    {
      title:'Revenue',
      dataIndex: 'revenue',
      align: 'right',
      customRender: text => {
        return parseFloat(text.toString()).toFixed(2).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.revenue - b.revenue
      }
    },
    {
      title:'Spent',
      dataIndex: 'spent',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        if (!a.platform.includes("Total")) {
          return a.spent - b.spent
        }
      }
    },
    {
      title:'ROAS',
      dataIndex: 'roas',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {
        if (!a.platform.includes("Total")) {
          return a.roas - b.roas
        }
      }
    },
    {
      title:'Affconversions ',
      dataIndex: 'aff_conversions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.aff_conversions - b.aff_conversions
      }
    },
    {
      title:'Aff CPA',
      dataIndex: 'aff_cpa',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.aff_cpa - b.aff_cpa
      }
    },
    {
      title:'Aff CVR',
      dataIndex: 'aff_cvr',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {
        return a.aff_cvr - b.aff_cvr
      }
    },
    {
      title:'Outbound Conversions',
      dataIndex: 'outbound_conversions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.outbound_conversions - b.outbound_conversions
      }
    },
    {
      title:'Obd CPA',
      dataIndex: 'outbound_cpa',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.outbound_cpa - b.outbound_cpa
      }
    },
    {
      title:'Obd CVR',
      dataIndex: 'outbound_cvr',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {
        return a.outbound_cvr - b.outbound_cvr
      }
    },
    {
      title:'Impressions',
      dataIndex: 'impressions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.impressions - b.impressions
      }
    },
    {
      title:'Clicks',
      dataIndex: 'clicks',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.clicks - b.clicks
      }
    },
    {
      title:'CTR',
      dataIndex: 'ctr',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {
        return a.ctr - b.ctr
      }
    },
    {
      title:'Avg.CPC',
      dataIndex: 'avg_cpc',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.avg_cpc - b.avg_cpc
      }
    }
  ]

  export default {
    data() {
      return {
        moment,
        userInfo: {},
        defaultStartDate,
        defaultEndDate,
        campaign_id: '',
        columns,
        dataList: [],
        loading: false,  
        title: '',
        allData: [],
        dateString: [moment(defaultStartDate, 'YYYY-MM-DD'), moment(defaultEndDate, 'YYYY-MM-DD')],
        velineData: {
          columns: ['date'],
          rows: []
        }, 
        chartSettings: {
          axisSite: { right: ['revenue','spent','roas','aff_conversions','aff_cpa','aff_cvr','outbound_conversions','outbound_cpa','outbound_cvr', 'impressions', 'clicks', 'ctr', 'avg_cpc'] },
        },
        chartExtend: {
          color: ["#59b1ef", "#1ad4ae"]
        },
      }
    },
    methods: {
      onDateChange(dateString){
        this.dateString = dateString 
      },
      submitRangeChange(){
        this.getPerfLine()
      },
      onRevenueChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'revenue'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('revenue')
          this.chartExtend.color.push('#59b1ef')
        }
        console.log(`a-switch to ${checked}`);
      },
      onSpentChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'spent'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('spent')
          this.chartExtend.color.push('#1ad4ae')
        }
        console.log(`a-switch to ${checked}`);
      },
      onRoasChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'roas'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('roas')
          this.chartExtend.color.push('#fa6f87')
        }
        console.log(`a-switch to ${checked}`);
      },
      onAffConversionsChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'aff_conversions'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('aff_conversions')
          this.chartExtend.color.push('#ffb980')
        }
        console.log(`a-switch to ${checked}`);
      },
      onAffCpaChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'aff_cpa'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('aff_cpa')
          this.chartExtend.color.push('#c4b4e4')
        }
        console.log(`a-switch to ${checked}`);
      },
      onAffCvrChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'aff_cvr'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          }) 
        }else{
          this.velineData.columns.push('aff_cvr')
          this.chartExtend.color.push('#87a996')
        }
        console.log(`a-switch to ${checked}`);
      },
      onOutboundConversionsChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'outbound_conversions'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('outbound_conversions')
          this.chartExtend.color.push('#d9d0c7')
        }
        console.log(`a-switch to ${checked}`);
      },
      onOutboundCpaChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'outbound_cpa'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('outbound_cpa')
          this.chartExtend.color.push('#9bbbff')
        }
        console.log(`a-switch to ${checked}`);
      },
      onOutboundCvrChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'outbound_cvr'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('outbound_cvr')
          this.chartExtend.color.push('#fba7d3')
        }
        console.log(`a-switch to ${checked}`);
      },
      onImpressinsChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'impressions'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('impressions')
          this.chartExtend.color.push('#a294ff')
        }

        console.log(`a-switch to ${checked}`);
      },
      onClicksChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'clicks'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('clicks')
          this.chartExtend.color.push('#80c19b')
        }
        console.log(`a-switch to ${checked}`);
      },
      onCtrChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'ctr'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('ctr')
          this.chartExtend.color.push('#6578a0')
        }
        console.log(`a-switch to ${checked}`);
      },
      onAvgCpcChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'avg_cpc'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('avg_cpc')
          this.chartExtend.color.push('#0166a6')
        }
        console.log(`a-switch to ${checked}`);
      },
      getPerfLine() {
        this.loading = true
        this.$post(api.aff.getAffCampaignPerfByDay, {
          campaign_id: this.campaign_id,
          start_date: this.dateString[0].format('YYYY-MM-DD'),
          end_date: this.dateString[1].format('YYYY-MM-DD'),
          token: this.userInfo.token
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            let data = res.data.data
            this.title = data.table_data[0].campaign_name + ' | ' + data.table_data[0].campaign_id
            this.allData = data.chart_data
            data.table_data.forEach(item => {
              item.date_sort = parseInt(item.date.replace(/[-]/g,''))
            })
            this.dataList = data.table_data
            this.velineData.columns.push('revenue')
            this.velineData.columns.push('spent')
            this.velineData.rows = this.allData
          } else {
            this.$message.error(res.data.message)
          }
        });
      }
    },
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.campaign_id = this.$route.query.campaign_id
      this.dateString = [moment(this.$route.query.start_date, 'YYYY-MM-DD'), moment(this.$route.query.end_date, 'YYYY-MM-DD')]
      this.getPerfLine()
    }
  };
</script>

<style> 
  .checkitem{
    margin-bottom: 6px;
  }
  .ant-switch-checked.revenue{
    background-color: #59b1ef;
  }
  .ant-switch-checked.spent{
    background-color: #1ad4ae;
  }
  .ant-switch-checked.roas{
    background-color: #fa6f87;
  }
  .ant-switch-checked.aff_conversions{
    background-color: #ffb980;
  }
  .ant-switch-checked.aff_cpa{
    background-color: #c4b4e4;
  }
  .ant-switch-checked.aff_cvr{
    background-color: #87a996;
  }
  .ant-switch-checked.outbound_conversions{
    background-color: #0166a6;
  }
  .ant-switch-checked.outbound_cpa{
    background-color: #d9d0c7;
  }
  .ant-switch-checked.outbound_cvr{
    background-color: #9bbbff;
  }
  .ant-switch-checked.impressions{
    background-color: #fba7d3;
  }
  .ant-switch-checked.clicks{
    background-color: #a294ff;
  }
  .ant-switch-checked.ctr{
    background-color: #80c19b;
  }
  .ant-switch-checked.avg_cpc{
    background-color: #6578a0;
  }
</style>
