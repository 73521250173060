<template>
  <div>
    <div class="ant-page-header-tl">Campaign 列表
      <div class="righttop">
        <a-range-picker v-if="period.length" :default-value="[moment(period[0], 'YYYY-MM-DD'), moment(period[1], 'YYYY-MM-DD')]" @change="rangeChange" />
        <a-button type="primary" style="margin-left: 16px;" @click="submitRangeChange">Apply</a-button>
      </div>
    </div>
    <div class="section">
      <a-table 
        :columns="columns" 
        :data-source="dataList" 
        :rowKey="record => record.editor"
        :pagination="false" 
        size="middle"
        :loading="loading"
        bordered
      >
        <a slot="action" slot-scope="text, record" @click="goLine(record.campaign_id)">Check</a>
      </a-table>
    </div>
  </div>
</template>
<script>
  import api from '@/common/api.js'
  import moment from 'moment';

  const columns = [
    {
      title:'Platform',
      dataIndex: 'platform',
    },
    {
      title:'Landing Page',
      dataIndex: 'landing_page',
    },
    {
      title:'Campaign Id',
      dataIndex: 'campaign_id',
    },
    {
      title:'PV',
      dataIndex: 'page_views',
      sorter: (a, b) => a.page_views - b.page_views,
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      }
    },
    {
      title:'P/S',
      dataIndex: 'ps',
      align: 'right',
      customRender: text => {
        return text.toFixed(2)
      },
      sorter: (a, b) => a.ps - b.ps,
    },
    {
      title:'宗数',
      dataIndex: 'z_num',
      align: 'right',
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.z_num - b.z_num,
    },
    {
      title:'Detail',
      dataIndex: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    }
  ]

  export default {
    data() {
      return {
        moment,
        period: [],
        userInfo: {},
        post_url: '',
        columns,
        dataList: [],
        loading: false,      
      }
    },
    methods: {
      rangeChange(date, dateString){
        this.period = dateString
      },
      submitRangeChange(){
        this.getList()
      },
      getList(){
        this.loading = true
        let params = {
          post_url: this.post_url,
          token: this.userInfo.token
        }
        this.$post(api.pref.getCampaignListByPost, params).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.dataList = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      goLine(campaign_id){
        const {href} = this.$router.resolve({
          path: '/line',
          query: {
            campaign_id: campaign_id,
            period: JSON.stringify(this.period)
          },
        })
        window.open(href, '_blank')
      }
    },
    created () {
      this.period = JSON.parse(this.$route.query.period)
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.post_url = this.$route.query.post_url
      this.getList()
    }
  };
</script>

<style> 
  .section{
    margin: 24px;
    background-color: #fff;
  }
</style>
