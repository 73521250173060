<template>
  <div id="app" class="screen">
    <v-sidenav></v-sidenav>
    <div class="gray-bg" style="overflow:auto;">
      <!-- <div class="ant-pro-global-header">

      </div> -->
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import sidenav from '@/components/SideNav'

export default {
  name: 'App',
  components: {
    'v-sidenav': sidenav,
  }
}
</script>

<style>
@font-face
{
    font-family: 'Roboto';
    font-weight: normal;
    font-style: normal;

    font-display: swap;
    src: local('Roboto Regular'), local('Roboto-Regular'), url(./assets/fonts/Roboto-Regular.woff2) format('woff2'), url(./assets/fonts/Roboto-Regular.woff) format('woff'), url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
}
#app {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  /* color: #2c3e50; */
  color: #222;
}

.screen{
  min-height: 100%;
  display: flex;
}
.gray-bg{
  flex: 1;
  background: #f0f2f5;
}
.ant-pro-global-header {
  position: relative;
  height: 64px;
  line-height: 64px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
  text-align: left;
  padding-left: 24px;
}
.common-tl{
  padding-left: 60px;
  text-align: right;
  padding-right: 24px;
  cursor: pointer;
}
.ant-page-header-tl{
  padding: 16px 24px;
  background-color: #fff;
  font-size: 16px;
  font-weight: 600;
}

</style>
