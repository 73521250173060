<template>
  <div>
    <div class="ant-page-header-tl">
      <span style="margin-right:5px">Platform:</span>
      <a-select
        v-model="platform"
        style="width: 120px; margin-right: 16px"
        @change="handlePlatformChange"
      >
        <a-select-option
          v-for="(item, index) in platformList"
          :key="index"
          :value="item.name"
          >{{ item.name }}</a-select-option
        >
      </a-select>
      <span style="margin-left:16px;margin-right:5px">Date:</span>
      <a-range-picker :default-value="dateString" @change="onDateChange" />
    </div>
    <div class="valuepage">
      <a-tabs type="card" @change="tabCallBack">
        <a-tab-pane key="1" tab="Valuable Post">
          <div class="form-tl">
            已选择<b>{{ best_campaign.length }}</b
            >条url
          </div>

          <a-table
            @change="handleTableChange"
            :scroll="{ x: 1500, y: 500 }"
            :columns="columns"
            :data-source="dataList"
            :rowKey="(record) => record.url"
            size="small"
            :loading="loading"
            bordered
            :rowSelection="{
              selectedRowKeys: campaign_ids,
              onChange: onSelectChange,
              onSelect: onSelectValue,
            }"
            :pagination="{ pageSize: pageSize }"
          >

            <div
              slot="filterDropdown"
              slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
              style="padding: 8px"
            >
              <a-input

                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
              >
                Search
              </a-button>
              <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />

            <template slot="customRender" slot-scope="text, record, index, column">
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                {{ text }}
              </template>
            </template>

            <div class="valueoverflow" slot="url" slot-scope="text">
              <a-popover title="">
                <template slot="content">
                  {{ text }}
                </template>
                {{ text }}
              </a-popover>
            </div>
            <div
              class="valueoverflow"
              slot="best_campaign_id"
              slot-scope="text"
            >
              {{ text }}
            </div>
            <div class="smallbtn" slot="action" slot-scope="text, record">
              <a-button
                size="small"
                type="primary"
                @click="toHide(record.url, record.platform)"
                >Hide</a-button
              >
            </div>
          </a-table>
          <h4>批量覆盖</h4>
          <!-- Taboola -->
          <div style="width: 50%" v-if="platform == 'taboola'">
            <!-- objective -->
            <div class="form-tl"><b>Campaign Objective</b></div>
            <a-radio-group v-model="tbl_objective">
              <a-radio :value="'DRIVE_WEBSITE_TRAFFIC'">
                WEBSITE ENGAGEMENT
              </a-radio>
              <a-radio :value="'LEADS_GENERATION'">
                LEAD GENERATION
              </a-radio>
            </a-radio-group>

            <!-- Bid Strategy -->
            <div class="form-tl"><b>Bid Strategy</b></div>
            <a-radio-group v-model="tbl_bid_strategy">
              <a-radio value="FIXED">
                Fixed Bid
              </a-radio>
              <a-radio value="OPTIMIZED_PAGEVIEWS">
                Smart Bid（PV）
              </a-radio>
              <a-radio value="OPTIMIZED_CONVERSIONS">
                Smart Bid（Conversion）
              </a-radio>
            </a-radio-group>

            <!-- location -->
            <div class="form-tl"><b>Location</b></div>
            <div class="include">
              <a-select v-model="tbl_country['type']" style="width: 120px">
                <a-select-option value="INCLUDE">
                  <a-icon type="check-circle" style="color: #52c41a" />
                  INCLUDE
                </a-select-option>
                <a-select-option value="EXCLUDE">
                  <a-icon type="close-circle" style="color: #ff4d4f" />
                  EXCLUDE
                </a-select-option>
              </a-select>
              <span style="color: #bfbfbf">|</span>
              <a-select
                mode="multiple"
                v-model="tbl_country['value']"
                style="width: 100%"
                placeholder="Select Country"
                option-label-prop="label"
              >
                <a-select-option
                  v-for="(item, index) in tbl_fields.country"
                  :key="index"
                  :value="item.name"
                  :label="item.name"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </div>

            <div class="form-tl"><b>Operating System</b></div>
            <div class="include">
              <a-select v-model="tbl_os['type']" style="width: 120px">
                <a-select-option value="INCLUDE">
                  <a-icon type="check-circle" style="color: #52c41a" />
                  INCLUDE
                </a-select-option>
                <a-select-option value="EXCLUDE">
                  <a-icon type="close-circle" style="color: #ff4d4f" />
                  EXCLUDE
                </a-select-option>
              </a-select>
              <span style="color: #bfbfbf">|</span>
              <a-select
                mode="multiple"
                v-model="tbl_os['value']"
                style="width: 100%"
                placeholder="Select Operating System"
                option-label-prop="label"
              >
                <a-select-option
                  v-for="(item, index) in tbl_fields.os"
                  :key="index"
                  :value="item.name"
                  :label="item.name"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </div>

            <!-- 设备 -->
            <div class="form-tl"><b>Device</b></div>
            <div class="include">
              <div style="width: 120px;margin-left: 11px;">
                <a-icon type="check-circle" style="color: #52c41a" />
                INCLUDE
              </div>
              <span style="color: #bfbfbf">|</span>
              <a-select
                mode="multiple"
                v-model="tbl_device['value']"
                style="width: 100%"
                placeholder="Select Device"
                option-label-prop="label"
              >
                <a-select-option
                  v-for="(item, index) in tbl_fields.device"
                  :key="index"
                  :value="item.name"
                  :label="item.name"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </div>

            <!-- Browser -->
            <div class="form-tl"><b>Browser</b></div>
            <div class="include">
              <a-select
                v-model="tbl_browsers['type']"
                default-value="INCLUDE"
                style="width: 120px"
              >
                <a-select-option value="INCLUDE">
                  <a-icon type="check-circle" style="color: #52c41a" />
                  INCLUDE
                </a-select-option>
                <a-select-option value="Exclude">
                  <a-icon type="close-circle" style="color: #ff4d4f" />
                  EXCLUDE
                </a-select-option>
              </a-select>
              <span style="color: #bfbfbf">|</span>
              <a-select
                mode="multiple"
                v-model="tbl_browsers['value']"
                style="width: 100%"
                placeholder="Select Browsers"
                option-label-prop="label"
              >
                <a-select-option
                  v-for="(item, index) in tbl_fields.browsers"
                  :key="index"
                  :value="item.name"
                  :label="item.name"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </div>

            <!-- Default CPC -->
            <div class="form-tl"><b>Default CPC</b></div>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc"
                style="width: 220px"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
            <a-alert
              v-if="alertShow1"
              message="your Default CPC > 0.07"
              type="warning"
              closable
              show-icon
              style="width: 250px;margin-top: 8px"
            />

            <!-- Budget -->
            <div class="form-tl"><b>Budget</b></div>
            <div class="input-number">
              <a-input-number
                v-model="tbl_budget"
                style="width: 160px"
                @change="budgetInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
            <a-alert
              v-if="alertShow2"
              message="your Budget > 20"
              type="warning"
              closable
              show-icon
              style="width: 250px;margin-top: 8px"
            />

            <div class="form-tl"><b>Campaign Name</b></div>
            <div>
              <a-input
                placeholder="{prefix}"
                style="width: 240px"
                v-model="tbl_campaign_prefix"
              />
              - brand txt - title -
              <a-input
                placeholder="{suffix}"
                style="width: 240px"
                v-model="tbl_campaign_suffix"
              />
            </div>

            <!-- Audience -->
            <!-- tbl_audience -->
            <div class="form-tl"><b>Audience</b></div>
            <!-- 包含 -->
            <div class="include" style="margin-bottom: 16px;">
              <div style="width: 120px;margin-left: 11px;">
                <a-icon type="check-circle" style="color: #52c41a" />
                {{ tbl_audience_in["type"] }}
              </div>
              <span style="color: #bfbfbf">|</span>
              <a-select
                mode="multiple"
                v-model="tbl_audience_in['value']"
                style="width: 100%"
                placeholder="Select Audience"
                option-label-prop="label"
              >
                <a-select-option
                  v-for="(item, index) in tbl_audience_list"
                  :key="index"
                  :value="item"
                  :label="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
            </div>

            <div class="form-tl" v-if="tbl_site_list.length">
              <b>Account</b>
            </div>
            <div
              class="site-line"
              v-for="(item, index) in tbl_site_list"
              :key="index"
            >
              <div class="site-item">{{ item.site }}</div>
              <a-select
                style="width: 280px;"
                @change="(value) => siteChange(value, item.site)"
              >
                <a-select-option
                  v-for="(a, index) in tbl_account[item.site]"
                  :key="index"
                  :value="a"
                  >{{ a }}</a-select-option
                >
              </a-select>
            </div>

            <div style="margin-top: 24px;">
              <a-button
                type="primary"
                size="large"
                style="margin-right: 16px;"
                @click="tblSubmit(1)"
                >ProTest</a-button
              >
              <a-button
                type="danger"
                size="large"
                style="margin-right: 16px;"
                :loading="tblLoading"
                @click="tblSubmit(2)"
                >Submit</a-button
              >
            </div>
          </div>

          <!-- Outbrain -->
          <div style="width: 50%" v-if="platform == 'outbrain'">
            <div class="form-tl"><b>Campaign Objective</b></div>
            <a-radio-group
              v-model="ob_objective"
              @change="onCampaignObjectiveChange"
            >
              <a-radio :value="'Traffic'">
                Traffic
              </a-radio>
              <a-radio :value="'Conversions'">
                Conversion
              </a-radio>
            </a-radio-group>

            <div class="form-tl"><b>Location</b></div>
            <div class="include">
              <div style="width: 120px;margin-left: 11px;">
                <a-icon type="check-circle" style="color: #52c41a" />
                INCLUDE
              </div>
              <span style="color: #bfbfbf">|</span>
              <a-select
                mode="multiple"
                v-model="ob_country_value"
                style="width: 100%"
                placeholder="Select Country"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                option-label-prop="label"
                @search="fetchLocation"
                @change="handleOBLocationChange"
                @select="handleOBLocationSelect"
                @deselect="handleOBLocationDeSelect"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option
                  v-for="(item, index) in ob_country"
                  :key="index"
                  :title="item.name"
                  :id="item.id"
                  :value="item.name"
                  :label="item.name"
                  >[{{ item.geoType }}] - {{ item.name }}</a-select-option
                >
              </a-select>
            </div>

            <!-- Operating Systems -->
            <div class="form-tl"><b>Operating System</b></div>
            <div class="include">
              <div style="width: 120px;margin-left: 11px;">
                <a-icon type="check-circle" style="color: #52c41a" />
                INCLUDE
              </div>
              <span style="color: #bfbfbf">|</span>
              <a-select
                mode="multiple"
                v-model="ob_os['value']"
                style="width: 100%"
                placeholder="Select Operating System"
                option-label-prop="label"
              >
                <a-select-option
                  v-for="(item, index) in ob_fields.os"
                  :key="index"
                  :value="item.name"
                  :label="item.name"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </div>

            <div class="form-tl"><b>Device</b></div>
            <!-- device -->
            <div class="include">
              <div style="width: 120px;margin-left: 11px;">
                <a-icon type="check-circle" style="color: #52c41a" />
                INCLUDE
              </div>
              <span style="color: #bfbfbf">|</span>
              <a-select
                mode="multiple"
                v-model="ob_device['value']"
                style="width: 100%"
                placeholder="Select Device"
                option-label-prop="label"
              >
                <a-select-option
                  v-for="(item, index) in ob_fields.device"
                  :key="index"
                  :value="item.name"
                  :label="item.name"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </div>

            <!-- Browser -->
            <div class="form-tl"><b>Browser</b></div>
            <div class="include">
              <div style="width: 120px;margin-left: 11px;">
                <a-icon type="check-circle" style="color: #52c41a" />
                INCLUDE
              </div>
              <span style="color: #bfbfbf">|</span>
              <a-select
                mode="multiple"
                v-model="ob_browsers['value']"
                style="width: 100%"
                placeholder="Select Browsers"
                option-label-prop="label"
              >
                <a-select-option
                  v-for="(item, index) in ob_fields.browsers"
                  :key="index"
                  :value="item.name"
                  :label="item.name"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </div>

            <!-- Default CPC -->
            <div class="form-tl"><b>Default CPC</b></div>
            <div class="input-number">
              <a-input-number
                v-model="ob_default_cpc"
                style="width: 220px"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
            <a-alert
              v-if="alertShow1"
              message="your Default CPC > 0.07"
              type="warning"
              closable
              show-icon
              style="width: 250px;margin-top: 8px"
            />

            <!-- Monthly Budget -->
            <div class="form-tl"><b>Budget</b></div>
            <div class="input-number">
              <a-input-number
                v-model="ob_budget"
                style="width: 220px"
                @change="budgetInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
            <a-alert
              v-if="alertShow2"
              message="your Budget > 20"
              type="warning"
              closable
              show-icon
              style="width: 250px;margin-top: 8px"
            />

            <div class="form-tl"><b>Campaign Name</b></div>
            <div>
              <a-input
                placeholder="{prefix}"
                style="width: 240px"
                v-model="ob_campaign_prefix"
              />
              - brand txt - title -
              <a-input
                placeholder="{suffix}"
                style="width: 240px"
                v-model="ob_campaign_suffix"
              />
            </div>

            <!-- Audience -->
            <!-- ob_audience -->
            <div class="form-tl"><b>Audience</b></div>
            <!-- 包含 -->
            <div class="include" style="margin-bottom: 16px;">
              <div style="width: 120px;margin-left: 11px;">
                <a-icon type="check-circle" style="color: #52c41a" />
                {{ ob_audience_in["type"] }}
              </div>
              <span style="color: #bfbfbf">|</span>
              <a-select
                mode="multiple"
                v-model="ob_audience_in['value']"
                style="width: 100%"
                placeholder="Select Audience"
                option-label-prop="label"
              >
                <a-select-option
                  v-for="(item, index) in ob_audience_list"
                  :key="index"
                  :value="item"
                  :label="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
            </div>
            <!-- 111111111111111111111111111 -->
            <div v-if="conversionsShow">
              <div class="form-tl"><b>Conversions</b></div>
              <a-select
                mode="multiple"
                v-model="ob_conversions"
                style="width: 100%;margin-bottom: 16px"
                placeholder="Supporting Conversion"
                option-label-prop="label"
                v-decorator="[
                  'bio',
                  {
                    rules: [{ required: true }],
                  },
                ]"
              >
                <a-select-option
                  :disabled="item.disabled"
                  v-for="(item, index) in ob_conversions_list"
                  :key="index"
                  :value="item"
                  :label="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
              <a-select
                mode="multiple"
                v-model="ob_conversions2"
                style="width: 100%;"
                placeholder="Primary Conversion"
                option-label-prop="label"
                v-decorator="[
                  'bio',
                  {
                    rules: [{ required: true }],
                  },
                ]"
              >
                <a-select-option
                  :disabled="item.disabled"
                  v-for="(item, index) in ob_conversions_list"
                  :key="index"
                  :value="item"
                  :label="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
            </div>

            <div class="form-tl" v-if="tbl_site_list.length">
              <b>Account</b>
            </div>
            <div
              class="site-line"
              v-for="(item, index) in tbl_site_list"
              :key="index"
            >
              <div class="site-item">{{ item.site }}</div>
              <a-select
                style="width: 280px;"
                @change="(value) => siteChange(value, item.site)"
              >
                <a-select-option
                  v-for="(a, index) in ob_account[item.site]"
                  :key="index"
                  :value="a"
                  >{{ a }}</a-select-option
                >
              </a-select>
            </div>

            <div style="margin-top: 24px;">
              <a-button
                type="primary"
                size="large"
                style="margin-right: 16px;"
                @click="obSubmit(1)"
                >ProTest</a-button
              >
              <a-button
                type="danger"
                size="large"
                style="margin-right: 16px;"
                :loading="obLoading"
                @click="obSubmit(2)"
                >Submit</a-button
              >
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="Covered List">
          <a-table
            :columns="covered_columns"
            :data-source="coveredDataList"
            :rowKey="(record) => record.from + record.to + record.create_time"
            size="middle"
            :loading="loading"
            :pagination="{ pageSize: pageSize }"
            bordered
          >
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
const defaultStartDate = moment()
  .startOf("day")
  .subtract(8, "days")
  .format("YYYY-MM-DD");
const defaultEndDate = moment()
  .startOf("day")
  .subtract(2, "days")
  .format("YYYY-MM-DD");
let columns = [
  {
    title: "URL",
    dataIndex: "url",
    // scopedSlots: { customRender: "url" },
    width: "350px",
    sorter: (a, b) => a.url.localeCompare(b.url),
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'url',
    },
    onFilter: (value, record) =>
      record.url
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
   
  },
  // {
  //   title: "上次覆盖日期",
  //   dataIndex: "covered_date",
  //   width: 130,
  //   scopedSlots: { customRender: "covered_date" },
  //   sorter: (a, b) => a.covered_date.localeCompare(b.covered_date),
  // },
  // {
  //   title: "已覆盖数量",
  //   dataIndex: "covered_num",
  //   width: 110,
  //   scopedSlots: { customRender: "covered_num" },
  //   sorter: (a, b) => a.covered_num - b.covered_num,
  // },
  {
    title: "Session",
    dataIndex: "sessions",
    align: "right",
    width: 120,
    sorter: (a, b) => a.sessions - b.sessions,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Daily Session",
    dataIndex: "daily_session",
    align: "right",
    width: 140,
    sorter: (a, b) => a.daily_session - b.daily_session,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "CPC 上限",
    dataIndex: "cpc_top",
    align: "right",
    width: 120,
    sorter: (a, b) => a.cpc_top - b.cpc_top,
  },
  {
    title: "P/S",
    dataIndex: "p_s",
    width: 120,
    align: "right",
    sorter: (a, b) => a.p_s - b.p_s,
  },
  {
    title: "Ads CTR",
    dataIndex: "ads_ctr",
    width: 120,
    align: "right",
    sorter: (a, b) => a.ads_ctr - b.ads_ctr,
    customRender: (text) => {
      return parseFloat(text).toFixed(1)+ '%'
    },
  },
  {
    title: "Ads CPC",
    dataIndex: "ads_cpc",
    width: 120,
    align: "right",
    sorter: (a, b) => a.ads_cpc - b.ads_cpc,
  },
  {
    title: "系数",
    dataIndex: "x_num",
    align: "right",
    width: 120,
    sorter: (a, b) => a.x_num - b.x_num,
    customRender: (text) => {
      return Math.round(text)
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    width: 120,
    sorter: (a, b) => a.z_num - b.z_num,
    customRender: (text) => {
      return Math.round(text)
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Daily 宗数",
    dataIndex: "daily_z_num",
    align: "right",
    width: 120,
    sorter: (a, b) => a.daily_z_num - b.daily_z_num,
    customRender: (text) => {
      return Math.round(text)
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Best Campaign",
    dataIndex: "best_campaign_id",
    scopedSlots: { customRender: "best_campaign_id" },
    width: 200,
    sorter: (a, b) => a.best_campaign_id.localeCompare(b.best_campaign_id),
  },
];
let covered_columns = [
  {
    title: "From",
    dataIndex: "from",
    scopedSlots: { customRender: "from" },
    sorter: (a, b) => a.from.localeCompare(b.from),
  },
  {
    title: "To",
    dataIndex: "to",
    scopedSlots: { customRender: "to" },
    sorter: (a, b) => a.to.localeCompare(b.to),
  },
  {
    title: "Campaign Name",
    dataIndex: "campaign_name",
    scopedSlots: { customRender: "campaign_name" },
    sorter: (a, b) => a.campaign_name.localeCompare(b.campaign_name),
  },
  {
    title: "URL",
    dataIndex: "url",
    scopedSlots: { customRender: "url" },
    width: "350px",
    sorter: (a, b) => a.url.localeCompare(b.url),
  },
  {
    title: "Operate Time",
    dataIndex: "create_time",
    customRender: (text) => {
      return moment(text * 1000).format("YYYY/MM/DD HH:mm");
    },
    scopedSlots: { customRender: "create_time" },
    sorter: (a, b) => a.create_time.localeCompare(b.create_time),
  },
];
export default {
  data() {
    return {
      columns,
      covered_columns,
      userInfo: [],
      platform: "taboola",
      type: 0, // 标识Top URL来源
      dataList: [],
      tmpDataList: [],
      tmpDataList1: [],
      coveredDataList: [],
      loading: false,
      tblLoading: false,
      obLoading: false,
      pageSize: 50,
      campaign_ids: [],
      tbl_fields: [],
      ob_fields: [],
      dateString: [defaultStartDate, defaultEndDate],
      platformList: [
        {
          id: 2,
          name: "taboola",
        },
        {
          id: 3,
          name: "outbrain",
        },
      ],
      tbl_country: {
        type: "INCLUDE",
        value: [],
      },
      tbl_os: {
        type: "INCLUDE",
        value: [],
      },
      tbl_device: {
        type: "INCLUDE",
        value: [],
      },
      tbl_browsers: {
        type: "INCLUDE",
        value: [],
      },
      tbl_default_cpc: 0,
      tbl_budget: 0,
      tbl_campaign_prefix: "",
      tbl_campaign_suffix: "",
      tbl_audience_in: {
        type: "INCLUDE",
        value: [],
      },
      tbl_audience_ex: {
        type: "EXCLUDE",
        value: [],
      },
      tbl_audience_list: [],
      tbl_account: [],
      tbl_site_list: [],
      common_account: [],
      best_campaign: [],
      ob_country: [],
      ob_country_value: [],
      ob_all_country: [],
      fetching: false,
      ob_os: {
        type: "INCLUDE",
        value: [],
      },
      ob_device: {
        type: "INCLUDE",
        value: [],
      },
      ob_browsers: {
        type: "INCLUDE",
        value: [],
      },
      ob_default_cpc: 0,
      ob_budget: 0,
      ob_campaign_prefix: "",
      ob_campaign_suffix: "",
      ob_audience_in: {
        type: "INCLUDE",
        value: [],
      },
      ob_audience_list: [],
      ob_conversions: [],
      ob_conversions_list: [],
      ob_conversions2: [],
      tbl_objective: "DRIVE_WEBSITE_TRAFFIC",
      tbl_bid_strategy: "FIXED",

      ob_objective: "Traffic",
      alertShow1: false,
      alertShow2: false,
      conversionsShow: false,
      last_update_date: "",
      tab: 1,
      covered_post: "",
      new_covered_post: [],
      tbl_site_list_name: [],
    };
  },
  methods: {
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = '';
    },
    onDateChange(date, dateString) {
      this.dateString = dateString;
      if (this.tab == 1) {
        this.getList();
      } else if (this.tab == 2) {
        this.getCoveredList();
      }
    },
    onSwitchChange() {
      if (this.dataList.length < 100) {
        this.dataList = this.tmpDataList;
      } else {
        this.dataList = this.tmpDataList1;
      }
    },
    onCampaignObjectiveChange(e) {
      this.ob_objective = e.target.value;
      if (this.ob_objective == "Conversions") {
        this.conversionsShow = true;
      } else {
        this.conversionsShow = false;
      }
    },
    handleOBLocationDeSelect(value) {
      let new_ob_all_country = [];
      this.ob_all_country.forEach((item) => {
        if (item.name != value) {
          new_ob_all_country.push(item);
        }
      });
      this.ob_all_country = new_ob_all_country;
    },
    handleOBLocationSelect(value, e) {
      let id = e.data.attrs.id;
      this.ob_all_country.push({ id: id, name: value });
    },
    handleOBLocationChange(value) {
      Object.assign(this, {
        value,
        ob_country: [],
        fetching: false,
      });
    },
    fetchLocation(value) {
      this.ob_country = [];
      this.fetching = true;
      if (value.length >= 2) {
        this.$post(api.update.getObCountry, {
          term: value,
          token: this.userInfo.token,
        }).then((res) => {
          if (res.data.status === 200) {
            this.ob_country = res.data.data;
            this.fetching = false;
          }
        });
      }
    },
    obSubmit(type) {
      let newCountry = [];
      this.ob_all_country.forEach((item) => {
        newCountry.push(item.id);
      });

      let newOs = {
        type: "ALL",
        value: [],
      };
      if (this.ob_os.value.length != 0) {
        newOs.type = "INCLUDE";
        this.ob_os.value.forEach((item, index) => {
          this.ob_fields.os.forEach((a) => {
            if (a.name == item) {
              newOs.value[index] = { os_family: a.value, sub_categories: [] };
            }
          });
        });
      }

      //Device
      let newDevice = {
        type: this.ob_device.type,
        value: [],
      };
      if (this.ob_device.value) {
        this.ob_device.value.forEach((item) => {
          console.log("====" + item);
          this.ob_fields.device.forEach((a) => {
            if (a.name == item) {
              console.log("--------" + a);
              newDevice.value.push(a.value);
            }
          });
        });
      }

      // Browsers
      let newBrowsers = {
        type: "ALL",
        value: [],
      };
      if (this.ob_browsers.value.length != 0) {
        newBrowsers.type = "INCLUDE";
        this.ob_browsers.value.forEach((item) => {
          this.ob_fields.browsers.forEach((a) => {
            if (a.name == item) {
              newBrowsers.value.push(a.value);
            }
          });
        });
      }

      let newaudience_in = [];
      if (this.ob_audience_in.value.length) {
        this.ob_audience_in.value.forEach((item) => {
          this.ob_audience_list.forEach((b) => {
            if (b.name == item) {
              newaudience_in.push(b.value);
            }
          });
        });
      }

      let newConversions = [];
      this.ob_conversions.forEach((item) => {
        this.ob_conversions_list.forEach((a) => {
          if (a == item) {
            newConversions.push(a);
            console.log(newConversions);
          }
        });
      });
      if (newConversions == "") {
        this.$message.error("Conversions不能为空");
      }

      let newConversions2 = [];
      this.ob_conversions2.forEach((item) => {
        this.ob_conversions_list.forEach((a) => {
          if (a == item) {
            newConversions2.push(a);
            console.log(newConversions2);
          }
        });
      });
      if (newConversions2 == "") {
        this.$message.error("Conversions不能为空");
      }

      let params = {
        country: newCountry,
        os: newOs,
        device: newDevice,
        default_cpc: this.ob_default_cpc,
        budget: this.ob_budget,
        audience: newaudience_in,
        campaign_name: [this.ob_campaign_prefix, this.ob_campaign_suffix],
        account: this.common_account,
        conversions: newConversions,
        primary_conversion: newConversions2,
        best_campaign: this.best_campaign,
        objective: this.ob_objective,
        browsers: newBrowsers,
      };

      if (type == 1) {
        this.$post(api.auto.preTest, {
          token: this.userInfo.token,
          platform: this.platform,
          params: params,
        }).then((res) => {
          if (res.data.status === 200) {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        });
      }
      if (type == 2) {
        this.obLoading = true;
        this.$post(api.auto.batchCoverCampaign, {
          token: this.userInfo.token,
          platform: this.platform,
          params: params,
        }).then((res) => {
          this.obLoading = false;
          if (res.data.status === 200) {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        });
      }
    },
    tblSubmit(type) {
      let newCountry, audience;
      newCountry = {
        type: this.tbl_country.type,
        value: [],
      };
      if (this.tbl_country.value) {
        this.tbl_country.value.forEach((item) => {
          this.tbl_fields.country.forEach((a) => {
            if (a.name == item) {
              newCountry.value.push(a.value);
            }
          });
        });
      }

      let newOs = {
        type: "ALL",
        value: [],
      };
      if (this.tbl_os.value.length != 0) {
        newOs.type = this.tbl_os.type;
        this.tbl_os.value.forEach((item, index) => {
          newOs.value[index] = { os_family: item, sub_categories: [] };
        });
      }

      //Device
      let newDevice = {
        type: this.tbl_device.type,
        value: [],
      };
      if (this.tbl_device.value) {
        this.tbl_device.value.forEach((item) => {
          this.tbl_fields.device.forEach((a) => {
            if (a.name == item) {
              newDevice.value.push(a.value);
            }
          });
        });
      }

      // Browser
      let newBrowsers = {
        type: "ALL",
        value: [],
      };
      if (this.tbl_browsers.value.length != 0) {
        newBrowsers = this.tbl_browsers;
      }

      audience = [];
      if (this.tbl_audience_in.value.length) {
        this.tbl_audience_in.value.forEach((item) => {
          this.tbl_audience_list.forEach((a) => {
            if (a == item) {
              audience.push(a);
            }
          });
        });
      }

      let params = {
        country: newCountry,
        os: newOs,
        device: newDevice,
        default_cpc: this.tbl_default_cpc,
        budget: this.tbl_budget,
        audience: audience,
        campaign_name: [this.tbl_campaign_prefix, this.tbl_campaign_suffix],
        account: this.common_account,
        best_campaign: this.best_campaign,
        objective: this.tbl_objective,
        bid_strategy: this.tbl_bid_strategy,
        browsers: newBrowsers,
      };

      if (type == 1) {
        this.$post(api.auto.preTest, {
          token: this.userInfo.token,
          platform: this.platform,
          params: params,
        }).then((res) => {
          if (res.data.status === 200) {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        });
      }
      if (type == 2) {
        this.tblLoading = true;
        this.$post(api.auto.batchCoverCampaign, {
          token: this.userInfo.token,
          platform: this.platform,
          params: params,
        }).then((res) => {
          this.tblLoading = false;
          if (res.data.status === 200) {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        });
      }
    },
    handlePlatformChange(e) {
      console.log(this.common_account);
      this.best_campaign = [];
      this.tbl_site_list = [];
      this.common_account = [];
      this.platform = e;
      if (this.tab == 1) {
        this.getList();
      } else if (this.tab == 2) {
        this.getCoveredList();
      }
    },
    tabCallBack(key) {
      if (key == 1) {
        this.tab = 1;
        this.getList();
      } else if (key == 2) {
        this.tab = 2;
        this.getCoveredList();
      }
    },
    getList() {
      this.loading = true;
      this.$post(api.auto.getLandingPagePerf, {
        token: this.userInfo.token,
        platform: this.platform,
        type: this.type,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataList = res.data.data;
          // this.tmpDataList = JSON.parse(JSON.stringify(this.dataList));
          // this.tmpDataList1 = this.dataList;
          // this.new_covered_post.forEach((item) => {
          //   this.dataList.forEach((listItem, index) => {
          //     if (listItem.url == item) {
          //       this.dataList.splice(index, 1);
          //     }
          //   });
          // });
        }
      });
    },
    // 获取已覆盖列表
    getCoveredList() {
      this.loading = true;
      this.$post(api.auto.getCoveredList, {
        token: this.userInfo.token,
        platform: this.platform,
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.coveredDataList = res.data.data;
        }
      });
    },
    getFixedFields() {
      this.$post(api.auto.getFields, {
        token: this.userInfo.token,
      }).then((res) => {
        if (res.data.status === 200) {
          this.tbl_fields = res.data.data.tbl_fields;
          this.ob_fields = res.data.data.ob_fields;
          this.tbl_audience_list = this.tbl_fields.audience;
          this.tbl_account = this.tbl_fields.account;
          this.ob_audience_list = this.ob_fields.audience;
          this.ob_conversions_list = this.ob_fields.conversion;
          this.ob_account = this.ob_fields.account;
        }
      });
    },
    handleTableChange(pagination, filters) {
      console.log(pagination, filters);
      this.selectedRowKeys = [];
    },
    cpcInputChange(value) {
      console.log(value);
      console.log(this.alertShow1);
      if (value > 0.07) {
        this.alertShow1 = true;
      } else {
        this.alertShow1 = false;
      }
    },
    budgetInputChange(value) {
      if (value > 20) {
        this.alertShow2 = true;
      } else {
        this.alertShow2 = false;
      }
    },
    onSelectChange(selectedRowKeys) {
      this.campaign_ids = selectedRowKeys;
    },
    onSelectValue(e) {
      let result = this.tbl_site_list_name.find((item) => {
        //没去重
        return item.best_campaign_id == e.best_campaign_id;
      });
      let result1 = this.tbl_site_list.find((item) => {
        //去重的
        return item.site == e.site;
      });
      if (result1 == undefined) {
        //去重的
        this.tbl_site_list.push({
          site: e.site,
          best_campaign_id: e.best_campaign_id,
        });
      }
      if (result == undefined) {
        //没去重&选择
        this.tbl_site_list_name.push({
          site: e.site,
          best_campaign_id: e.best_campaign_id,
        });
      } else {
        //没去重&取消选项
        let result2 = this.tbl_site_list_name.findIndex((item) => {
          return item.best_campaign_id == e.best_campaign_id;
        });
        if (result2 != -1) {
          this.tbl_site_list_name.splice(result2, 1);
        }
      }
      let result3 = this.tbl_site_list_name.find((item) => {
        return item.site == e.site;
      });
      if (result3 == undefined) {
        let result4 = this.tbl_site_list.findIndex((item) => {
          return item.site == e.site;
        });
        if (result4 != -1) {
          this.tbl_site_list.splice(result4, 1);
        }
      }
      // console.log(this.tbl_site_list)

      // let result = this.tbl_site_list.find((item) => {
      //   return item.site == e.site
      // })

      // let result2 = this.tbl_site_list.findIndex(item => {
      //   return item.site == e.site
      // })

      // if(result == undefined){
      //   this.tbl_site_list.push({site: e.site, best_campaign_id: e.best_campaign_id})
      // }
      // console.log(this.tbl_site_list)

      // if(result2 != -1){
      //   this.tbl_site_list.splice(result2, 1)
      // }

      let best_site = this.best_campaign.findIndex((item) => {
        return item.best_campaign_id == e.best_campaign_id;
      });
      if (best_site == -1) {
        this.best_campaign.push({
          site: e.site,
          best_campaign_id: e.best_campaign_id,
          landing_page: e.url,
          tag: e.tag,
        });
      } else {
        this.best_campaign.splice(best_site, 1);
      }
    },

    siteChange(name, site) {
      let index = this.common_account.findIndex((item) => {
        return item.site == site;
      });
      // console.log(index)
      if (index == -1) {
        this.common_account.push({ site: site, name: name });
      } else {
        this.common_account.splice(index, 1, { site: site, name: name });
      }
      //console.log(this.common_account)
    },
  },
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    if (this.$route.query.covered_post) {
      this.covered_post = this.$route.query.covered_post;
      this.new_covered_post = Object.values(JSON.parse(this.covered_post));
      this.platform = this.$route.query.platform.toLowerCase();
      this.type = 1;
    }

    this.getList();
    this.getFixedFields();
  },
};
</script>
<style>
.valuepage {
  background-color: #fff;
  margin: 8px;
  padding: 16px;
}
.valueoverflow {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.site-line {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.site-item {
  padding-right: 16px;
  width: 40px;
}
</style>
