<template>
  <div>
    <div class="ant-page-header-tl">
      <a-range-picker :default-value="dateString" @change="onDateChange" style="margin-right: 8px"/>
      <a-button type="primary" @click="submitRangeChange">Apply</a-button>
    </div>

    <div class="section-wrapper zumDetail">
      <a-card :loading="loading" :bordered="false" :title="name">
        <a-row>
          <a-col :span="20">
            <ve-line :data="velineData" :legend-visible="false" :settings="chartSettings" :extend="chartExtend"></ve-line>
          </a-col>
          <a-col :span="3" :offset="1">
            <div class="checkitem">
              <a-switch class="spent" default-checked @change="onSpentChange" />
              Spent
            </div>
            <div class="checkitem">
              <a-switch class="znum" default-checked @change="onZnumChange" />
              宗数
            </div>
            <div class="checkitem">
              <a-switch class="pageview" @change="onPageviewChange" />
              Page View
            </div>
            <div class="checkitem">
              <a-switch class="sessions" @change="onSessionsChange" />
              Sessions
            </div>
            <div class="checkitem">
              <a-switch class="adscpc" @change="onAdscpcChange" />
              Ads CPC
            </div>
            <div class="checkitem">
              <a-switch class="adsctr" @change="onAdsctrChange" />
              Ads CTR
            </div>
            <div class="checkitem">
              <a-switch class="avgcpc" @change="onAvgcpcChange" />
              Avg.CPC
            </div>
            <div class="checkitem">
              <a-switch class="cpctop" @change="onCpcTopChange" />
              CPC TOP
            </div>
            <div class="checkitem">
              <a-switch class="pagesession" @change="onPageSessionChange" />
              P/S
            </div>
            <div class="checkitem">
              <a-switch class="pagesession" @change="onCSChange" />
              C/S
            </div>
          </a-col>
        </a-row>
      </a-card>
    </div>

    <a-row>
      <a-col :span="20">
        <div class="section-wrapper">
          <a-card :bordered="false">
            <a-table
              :columns="columns"
              :data-source="zumPerfList"
              :rowKey="record => record.date"
              :pagination="false"
              size="small"
              bordered
              :loading="loading"
            >
              <div slot="z_num" slot-scope="text">
                <b>{{
                  text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
                }}</b>
              </div>
            </a-table>
          </a-card>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
  import api from '@/common/api.js'
  import moment from 'moment';
  const defaultStartDate = moment().startOf('day').subtract(31, 'days');
  const defaultEndDate = moment().startOf('day').subtract(2, 'days');

  const columns = [
    {
      title:'Date',
      dataIndex: 'date',
      align: 'left',
      sorter: (a, b) => a.date.toString().localeCompare(b.date.toString())
    },
    {
      title: 'PV',
      dataIndex: 'page_views',
      align: 'right',
      sorter: (a, b) => a.page_views - b.page_views,
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
    },
    {
      title: 'Sessions',
      dataIndex: 'sessions',
      align: 'right',
      sorter: (a, b) => a.sessions - b.sessions,
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
    },
    {
      title: "Click/Session",
      dataIndex: "c_s",
      align: "right",
      customRender: (text) => {
        return text + "%";
      },
      width: 120,
      sorter: (a, b) => a.c_s - b.c_s,
    },
    {
      title: 'P/S',
      dataIndex: 'page_per_session',
      align: 'right',
      customRender: text => {
        return parseFloat(text.toString()).toFixed(1).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => a.page_per_session - b.page_per_session,
    },
    {
      title: 'Ads CTR',
      dataIndex: 'ads_ctr',
      align: 'right',
      customRender: text => {
        return parseFloat(text.toString()).toFixed(1).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => a.ads_ctr - b.ads_ctr,
    },
    {
      title: 'Ads CPC',
      dataIndex: 'ads_cpc',
      align: 'right',
      customRender: text => {
        return parseFloat(text.toString()).toFixed(3).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => a.ads_cpc - b.ads_cpc,
    },
    {
      title: 'CPC上限',
      dataIndex: 'cpc_top',
      align: 'right',
      customRender: text => {
        return parseFloat(text.toString()).toFixed(3).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => a.cpc_top - b.cpc_top,
    },
    {
      title: 'Avg.CPC',
      dataIndex: 'avg_cpc',
      align: 'right',
      customRender: text => {
        return parseFloat(text.toString()).toFixed(3).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => a.avg_cpc - b.avg_cpc,
    },
    {
      title: '花费',
      dataIndex: 'spent',
      align: 'right',
      sorter: (a, b) => a.spent - b.spent,
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
    },
    {
      title:'宗数',
      dataIndex: 'z_num',
      align: 'right',
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.z_num - b.z_num,
      scopedSlots: { customRender: 'z_num' },
    },
    {
      title: "宗数/花费",
      dataIndex: "z_s",
      align: "right",
      customRender: (text) => {
        return text + "%";
      },
      sorter: (a, b) => a.z_s - b.z_s,
    }
  ]

  export default {
    data() {
      return {
        moment,
        userInfo: {},
        columns,
        loading: false,
        zumPerfList: [],
        z_num: '',
        slug: '',
        name: '',
        dateString: [defaultStartDate.format('YYYY-MM-DD'),defaultEndDate.format('YYYY-MM-DD')],
        velineData: {
          columns: ['date'],
          rows: []
        },
        chartExtend: {
          color: ["#6578a0", "#1ad4ae"],
          series: {
            smooth: false,
          },
        },
        chartSettings: {
          axisSite: { 
            right: [
            'page_per_session',
            'ads_ctr',
            'ads_cpc',
            'cpc_top',
            'avg_cpc',
            // 'z_num',
            'c_s'
            ] 
          },
        },
      }
    },
    methods: {

      onPageviewChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'page_views'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('page_views')
          this.chartExtend.color.push('#59b1ef')

        }
        console.log(`a-switch to ${checked}`);
      },
      onZnumChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'z_num'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('z_num')
          this.chartExtend.color.push('#1ad4ae')
        }
        console.log(`a-switch to ${checked}`);
      },
      onAdscpcChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'ads_cpc'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('ads_cpc')
          this.chartExtend.color.push('#fa6f87')
        }
        console.log(`a-switch to ${checked}`);
      },
      onAdsctrChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'ads_ctr'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('ads_ctr')
          this.chartExtend.color.push('#ffb980')
        }
        console.log(`a-switch to ${checked}`);
      },
      onAvgcpcChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'avg_cpc'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('avg_cpc')
          this.chartExtend.color.push('#c4b4e4')
        }
        console.log(`a-switch to ${checked}`);
      },
      onSessionsChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'sessions'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('sessions')
          this.chartExtend.color.push('#59b1ef')

        }
        console.log(`a-switch to ${checked}`);
      },
      onPageSessionChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'page_per_session'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('page_per_session')
          this.chartExtend.color.push('#a294ff')
        }
        console.log(`a-switch to ${checked}`);
      },
       onCSChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'c_s'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('c_s')
          this.chartExtend.color.push('#e6d2db')
        }
        console.log(`a-switch to ${checked}`);
      },
      onSpentChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'spent'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('spent')
          this.chartExtend.color.push('#6578a0')
        }
        console.log(`a-switch to ${checked}`);
      },
      onCpcTopChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'cpc_top'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('cpc_top')
          this.chartExtend.color.push('#0166a6')
        }
        console.log(`a-switch to ${checked}`);
      },

      getPerfByDay(){
        this.loading = true
        this.$post(api.ga4.getPerfByDay0, {
          token: this.userInfo.token,
          slug: this.slug,
          name: this.name,
          start_date: this.dateString[0],
          end_date: this.dateString[1]
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            let data = res.data.data.data
            this.zumPerfList = data
            this.zumPerfList.forEach(item => {
              item.z_s = item.spent == 0 ? 0 : Math.round(item.z_num*100/item.spent)
            })
            this.velineData.columns.push('spent')
            this.velineData.columns.push('z_num')
            this.velineData.rows = Array.from(this.zumPerfList)
            this.velineData.rows.reverse()
          }else{
            this.$message.error(res.data.message)
          }
        });
      },
      onDateChange(date, dateString) {
        this.dateString = dateString;
      },
      submitRangeChange(){
        this.velineData = {
          columns: ['date'],
          rows: []
        },
        this.getPerfByDay()
      },
    },
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.slug = this.$route.query.slug
      this.name = this.$route.query.name + "（Data-0）"
      this.getPerfByDay()
    }
  };
</script>
<style>
.zumDetail .ant-switch-checked.spent{
    background-color: #6578a0;
}
</style>
