<template>
  <div>
    <div class="ant-page-header-tl">
      <a-select
        defaultValue="all"
        style="width: 120px; margin-right: 16px;"
        @change="handlePlatformChange"
      >
        <a-select-option
          v-for="(item, index) in platformList"
          :key="index"
          :value="item.name"
          >{{ item.name }}</a-select-option
        >
      </a-select>
      <a-button type="primary" @click="getPlatformChange">Apply</a-button>
      <a-button type="primary" @click="adjustAll" style="margin-left: 10px;"
        >一键调价</a-button
      >
      <a-button
        type="primary"
        @click="clearCache"
        style="margin-left: 10px;background-color: #52c41a;border-color: #52c41a;float:right;"
        >Refresh</a-button
      >
    </div>
    <div class="section">
      <div class="bysite_val" style="margin-top: 10px;">
        <a-row>
          <a-col :span="2" class="label-name">
            0.01
          </a-col>
          <a-col :span="2">
            <a-input v-model="x11" type="text" />
          </a-col>
          <a-col :span="2" offset="1">
            <a-input v-model="x12" type="text" />
          </a-col>
        </a-row>
        <a-row style="margin-top:15px;">
          <a-col :span="2" class="label-name">
            0.03
          </a-col>
          <a-col :span="2">
            <a-input v-model="x31" type="text" />
          </a-col>
          <a-col :span="2" offset="1">
            <a-input v-model="x32" type="text" />
          </a-col>
          <a-col :span="2" offset="1">
            <a-input v-model="x33" type="text" />
          </a-col>
        </a-row>
        <a-row style="margin-top:15px;">
          <a-col :span="2" class="label-name">
            Session
          </a-col>
          <a-col :span="2">
            <a-input v-model="adj_session" type="text" />
          </a-col>
          <a-col :span="2" offset="1" class="label-name">
            <a-button type="primary" @click="updateAdjVal">Submit</a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="section bySite">
      <a-spin :spinning="applyloading">
        <a-tabs type="card" v-model="activeKey" @change="paneChange">
          <!--最近5天上传的Campaign / Session>100cpc%超过150% / 白名单-->
          <a-tab-pane key="1" tab="Auto Add">
            <a-table
              :columns="columns"
              :data-source="dataList"
              :rowKey="(record) => record.campaign_id"
              :pagination="{ pageSize: pageSize }"
              size="small"
              bordered
              :loading="loading"
              :scroll="{ x: 2000 }"
              :rowSelection="{
                selectedRowKeys: campaign_ids,
                onChange: onSelectChange,
                onSelect: onSelectValue,
              }"
            >
              <div
                slot="campaign_id"
                slot-scope="text"
                style="width: 103px;overflow: hidden;"
              >
                {{ text }}
              </div>
              <div
                slot="campaign_name"
                slot-scope="text, record"
                style="width: 500px;overflow: hidden;text-decoration: underline;cursor: pointer;"
                @click="
                  toLine(
                    record.campaign_id,
                    defaultStartDate,
                    defaultSelectDate
                  )
                "
              >
                <a-popover title="">
                  <template slot="content">
                    {{ text }}
                  </template>
                  {{ text }}
                </a-popover>
              </div>

              <div
                v-if="record.campaign_id != suggest_id"
                @click="editorTitle2(record.campaign_id, text)"
                class="edit_budget"
                slot="suggest_cpc"
                slot-scope="text, record"
                style="cursor: pointer;"
              >
                {{ text }} <a-icon class="edittitle" type="edit" />
              </div>
              <div
                v-else
                slot="suggest_cpc"
                slot-scope="text, record"
                class="editor-box"
              >
                <a-input v-model="suggest_tl" />
                <div class="editor-btn">
                  <a-button
                    :size="size"
                    style="margin-right: 16px"
                    @click="noEditorBtn2"
                    >CANCEL</a-button
                  >
                  <a-button
                    type="primary"
                    :size="size"
                    :loading="ediTtitleLoading"
                    @click="editorBtn2(record.campaign_id, record)"
                    >SAVE</a-button
                  >
                </div>
              </div>

              <!-- <div slot="suggest_cpc1" slot-scope="text, record">
                {{
                  parseFloat(
                    (
                      record.cpc_top -
                      (record.avg_cpc - record.default_cpc)
                    ).toString()
                  )
                    .toFixed(3)
                    .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
                }}
              </div> -->
              <div
                v-if="record.campaign_id != curtable_id"
                @click="editorTitle(record.campaign_id, text)"
                class="edit_budget"
                slot="campaign_budget"
                slot-scope="text, record"
                style="cursor: pointer;"
              >
                {{ text }} <a-icon class="edittitle" type="edit" />
              </div>
              <div
                v-else
                slot="campaign_budget"
                slot-scope="text, record"
                class="editor-box"
              >
                <a-input v-model="cur_table_tl" />
                <div class="editor-btn">
                  <a-button
                    :size="size"
                    style="margin-right: 16px"
                    @click="noEditorBtn"
                    >CANCEL</a-button
                  >
                  <a-button
                    type="primary"
                    :size="size"
                    :loading="ediTtitleLoading"
                    @click="editorBtn(record.campaign_id, record)"
                    >SAVE</a-button
                  >
                </div>
              </div>

              <div class="smallbtn" slot="action" slot-scope="text, record">
                <a-button type="primary" size="small" @click="goCover(record)"
                  >Check</a-button
                >
                <a-button
                  :disabled="record.is_update"
                  style="margin-left: 5px;"
                  size="small"
                  @click="bindApply(record)"
                  >Apply</a-button
                >
              </div>
            </a-table>
          </a-tab-pane>
          <a-tab-pane key="3" tab="Manual Add">
            <a-textarea v-model="site_list" placeholder="" :rows="6" />
            生效时间：<a-range-picker
              :default-value="[defaultWhiteStartDate, defaultWhiteEndDate]"
              @change="onWhiteDateChange"
            />
            <a-button
              type="primary"
              @click="submitBtn"
              style="margin: 16px 16px;"
              >提交</a-button
            >
            <a-card :bordered="false" title="手动添加列表">
              <a-table
                :columns="white_list_columns"
                :data-source="dataList3"
                :rowKey="(record) => record.campaign_id"
                :pagination="{ pageSize: pageSize }"
                size="small"
                bordered
                :loading="loading"
                :rowSelection="{
                  selectedRowKeys: campaign_ids,
                  onChange: onSelectChange,
                  onSelect: onSelectValue,
                }"
                :scroll="{ x: 2000 }"
              >
                <div
                  slot="campaign_id"
                  slot-scope="text"
                  style="width: 103px;overflow: hidden;"
                >
                  {{ text }}
                </div>
                <div
                  slot="campaign_name"
                  slot-scope="text, record"
                  style="width: 500px;overflow: hidden;text-decoration: underline;cursor: pointer;"
                  @click="
                    toLine(record.campaign_id, 
                    defaultStartDate,
                    defaultSelectDate)
                  "
                >
                  <a-popover title="">
                    <template slot="content">
                      {{ text }}
                    </template>
                    {{ text }}
                  </a-popover>
                </div>

                <!-- <span slot="suggest_cpc1" slot-scope="text, record">{{
                  parseFloat(
                    (
                      record.cpc_top -
                      (record.avg_cpc - record.default_cpc)
                    ).toString()
                  )
                    .toFixed(3)
                    .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
                }}</span> -->

                <div
                  v-if="record.campaign_id != suggest_id"
                  @click="editorTitle2(record.campaign_id, text)"
                  class="edit_budget"
                  slot="suggest_cpc"
                  slot-scope="text, record"
                  style="cursor: pointer;"
                >
                  {{ text }} <a-icon class="edittitle" type="edit" />
                </div>
                <div
                  v-else
                  slot="suggest_cpc"
                  slot-scope="text, record"
                  class="editor-box"
                >
                  <a-input v-model="suggest_tl" />
                  <div class="editor-btn">
                    <a-button
                      :size="size"
                      style="margin-right: 16px"
                      @click="noEditorBtn2"
                      >CANCEL</a-button
                    >
                    <a-button
                      type="primary"
                      :size="size"
                      :loading="ediTtitleLoading"
                      @click="editorBtn2(record.campaign_id, record)"
                      >SAVE</a-button
                    >
                  </div>
                </div>
                <div
                  v-if="record.campaign_id != curtable_id"
                  @click="editorTitle(record.campaign_id, text)"
                  class="edit_budget"
                  slot="campaign_budget"
                  slot-scope="text, record"
                >
                  {{ text }} <a-icon class="edittitle" type="edit" />
                </div>
                <div v-else slot="campaign_budget" class="editor-box">
                  <a-input v-model="cur_table_tl" />
                  <div class="editor-btn">
                    <a-button
                      :size="size"
                      style="margin-right: 16px"
                      @click="noEditorBtn"
                      >CANCEL</a-button
                    >
                    <a-button
                      type="primary"
                      :size="size"
                      :loading="ediTtitleLoading"
                      @click="editorBtn(record.campaign_id, dataList3)"
                      >SAVE</a-button
                    >
                  </div>
                </div>

                <div class="smallbtn" slot="action" slot-scope="text, record">
                  <a-button type="primary" size="small" @click="goCover(record)"
                    >Check</a-button
                  >
                  <a-button
                    :disabled="record.is_update"
                    style="margin-left: 5px;"
                    size="small"
                    @click="bindApply(record)"
                    >Apply</a-button
                  >
                </div>
              </a-table>
            </a-card>
          </a-tab-pane>
          <a-tab-pane key="4" tab="New Account By Site">
            <a-textarea v-model="site_list" placeholder="" :rows="6" />
            生效时间：<a-range-picker
              :default-value="[defaultWhiteStartDate, defaultWhiteEndDate]"
              @change="onWhiteDateChange"
            />
            <a-button
              type="primary"
              @click="submitBtn"
              style="margin: 16px 16px;"
              >提交</a-button
            >
            <a-card :bordered="false" title="手动添加列表">
              <a-table
                :columns="white_list_columns"
                :data-source="dataList4"
                :rowKey="(record) => record.campaign_id"
                :pagination="{ pageSize: pageSize }"
                size="small"
                bordered
                :loading="loading"
                :rowSelection="{
                  selectedRowKeys: campaign_ids,
                  onChange: onSelectChange,
                  onSelect: onSelectValue,
                }"
                :scroll="{ x: 2000 }"
              >
                <div
                  slot="campaign_id"
                  slot-scope="text"
                  style="width: 103px;overflow: hidden;"
                >
                  {{ text }}
                </div>
                <div
                  slot="campaign_name"
                  slot-scope="text, record"
                  style="width: 500px;overflow: hidden;text-decoration: underline;cursor: pointer;"
                  @click="
                    toLine(record.campaign_id, 
                    defaultStartDate,
                    defaultSelectDate)
                  "
                >
                  <a-popover title="">
                    <template slot="content">
                      {{ text }}
                    </template>
                    {{ text }}
                  </a-popover>
                </div>

                <!-- <span slot="suggest_cpc1" slot-scope="text, record">{{
                  parseFloat(
                    (
                      record.cpc_top -
                      (record.avg_cpc - record.default_cpc)
                    ).toString()
                  )
                    .toFixed(3)
                    .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
                }}</span> -->

                <div
                  v-if="record.campaign_id != suggest_id"
                  @click="editorTitle2(record.campaign_id, text)"
                  class="edit_budget"
                  slot="suggest_cpc"
                  slot-scope="text, record"
                  style="cursor: pointer;"
                >
                  {{ text }} <a-icon class="edittitle" type="edit" />
                </div>
                <div
                  v-else
                  slot="suggest_cpc"
                  slot-scope="text, record"
                  class="editor-box"
                >
                  <a-input v-model="suggest_tl" />
                  <div class="editor-btn">
                    <a-button
                      :size="size"
                      style="margin-right: 16px"
                      @click="noEditorBtn2"
                      >CANCEL</a-button
                    >
                    <a-button
                      type="primary"
                      :size="size"
                      :loading="ediTtitleLoading"
                      @click="editorBtn2(record.campaign_id, record)"
                      >SAVE</a-button
                    >
                  </div>
                </div>
                <div
                  v-if="record.campaign_id != curtable_id"
                  @click="editorTitle(record.campaign_id, text)"
                  class="edit_budget"
                  slot="campaign_budget"
                  slot-scope="text, record"
                >
                  {{ text }} <a-icon class="edittitle" type="edit" />
                </div>
                <div v-else slot="campaign_budget" class="editor-box">
                  <a-input v-model="cur_table_tl" />
                  <div class="editor-btn">
                    <a-button
                      :size="size"
                      style="margin-right: 16px"
                      @click="noEditorBtn"
                      >CANCEL</a-button
                    >
                    <a-button
                      type="primary"
                      :size="size"
                      :loading="ediTtitleLoading"
                      @click="editorBtn(record.campaign_id, dataList3)"
                      >SAVE</a-button
                    >
                  </div>
                </div>

                <div class="smallbtn" slot="action" slot-scope="text, record">
                  <a-button type="primary" size="small" @click="goCover(record)"
                    >Check</a-button
                  >
                  <a-button
                    :disabled="record.is_update"
                    style="margin-left: 5px;"
                    size="small"
                    @click="bindApply(record)"
                    >Apply</a-button
                  >
                </div>
              </a-table>
            </a-card>
          </a-tab-pane>
        </a-tabs>
      </a-spin>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
const defaultSelectDate = moment()
  .startOf("day")
  .subtract(1, "days");
const defaultStartDate = moment()
  .startOf("day")
  .subtract(15, "days");
// 白名单时间
const defaultWhiteStartDate = moment()
  .startOf("day")
  .subtract(0, "days");
const defaultWhiteEndDate = moment()
  .startOf("day")
  .subtract(0, "days");

const columns = [
  {
    title: "Platform",
    dataIndex: "platform",
    width: 100,
    sorter: (a, b) => a.budget_ratio - b.budget_ratio,
  },
  {
    title: "From",
    dataIndex: "type",
    width: 100,
    sorter: (a, b) => a.budget_ratio - b.budget_ratio,
    filters: [{
      text: '1',
      value: '1',
    },{
      text: '2',
      value: '2',
    },
    {
      text: '3',
      value: '3',
    },
    ],
    filterMultiple: false,
    onFilter: (value, record) => record.type.indexOf(value) !== -1,
  },
  {
    title: "Bid Strategy",
    dataIndex: "bid_type",
    ellipsis: true,
    width: 120,
    sorter: (a, b) => a.bid_type.localeCompare(b.bid_type),
  },
  {
    title: "Campaign ID",
    dataIndex: "campaign_id",
    width: 130,
    scopedSlots: { customRender: "campaign_id" },
    sorter: (a, b) =>
      a.campaign_id.toString().localeCompare(b.campaign_id.toString()),
  },
  {
    title: "Campaign Name",
    dataIndex: "campaign_name",
    width: 520,
    scopedSlots: { customRender: "campaign_name" },
    sorter: (a, b) => a.campaign_name.localeCompare(b.campaign_name),
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    width: 90,
    scopedSlots: { customRender: "start_date" },
    sorter: (a, b) => a.start_date.localeCompare(b.start_date),
  },
  {
    title: "Session",
    dataIndex: "sessions",
    customRender: (text) => {
      if (text) {
        return text;
      } else {
        return "/";
      }
    },
    width: 100,
    align: "right",
    sorter: (a, b) => a.sessions - b.sessions,
  },
  {
    title: "PV",
    dataIndex: "page_views",
    align: "right",
    width: 80,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => a.page_views - b.page_views,
  },
  {
    title: "Ads CTR",
    dataIndex: "ads_ctr",
    align: "right",
    customRender: (text) => {
      if (text) {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "%";
      } else {
        return "/";
      }
    },
    sorter: (a, b) => a.ads_ctr - b.ads_ctr,
    width: 100,
  },
  {
    title: "Ads CPC",
    dataIndex: "ads_cpc",
    align: "right",
    customRender: (text) => {
      if (text) {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      } else {
        return "/";
      }
    },
    sorter: (a, b) => a.ads_cpc - b.ads_cpc,
    width: 100,
  },
  {
    title: "P/S",
    dataIndex: "pages_per_session",
    align: "right",
    sorter: (a, b) => a.pages_per_session - b.pages_per_session,
    width: 100,
  },
  {
    title: "CPC 上限",
    dataIndex: "cpc_top",
    align: "right",
    sorter: (a, b) => a.cpc_top - b.cpc_top,
    width: 100,
  },
  {
    title: "Average CPC",
    dataIndex: "avg_cpc",
    align: "right",
    width: 140,
    customRender: (text) => {
      return text.toFixed(3);
    },
    sorter: (a, b) => a.avg_cpc - b.avg_cpc,
  },
  {
    title: "CPC%",
    dataIndex: "ratio",
    align: "right",
    sorter: (a, b) => a.ratio - b.ratio,
    width: 100,
    customRender: (text) => {
      return (text * 100).toFixed(2) + "%";
    },
  },
  {
    title: "Default CPC Before",
    dataIndex: "default_cpc",
    align: "right",
    sorter: (a, b) => a.default_cpc - b.default_cpc,
    width: 180,
  },
  {
    title: "Default CPC After",
    dataIndex: "suggest_cpc",
    align: "right",
    scopedSlots: { customRender: "suggest_cpc" },
    sorter: (a, b) => a.suggest_cpc - b.suggest_cpc,
    width: 150,
  },
  {
    title: "Suggested Default CPC After",
    dataIndex: "x",
    customRender: (text) => {
      return text.toFixed(3);
    },
    width: 150,
    align: "right",
  },
  {
    title: "Budget",
    dataIndex: "daily_budget",
    align: "right",
    width: 100,
    scopedSlots: { customRender: "campaign_budget" },
    sorter: (a, b) => a.daily_budget - b.daily_budget,
  },
  {
    title: "Budget%",
    dataIndex: "budget_ratio",
    width: 100,
    customRender: (text) => {
      return (text * 100).toFixed(2) + "%";
    },
    align: "right",
    sorter: (a, b) => a.budget_ratio - b.budget_ratio,
  },
  {
    title: "Monthly Budget",
    dataIndex: "monthly_budget",
    align: "right",
    width: 160,
    customRender: (text) => {
      if (text) {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      } else {
        return "/";
      }
    },
    sorter: (a, b) => a.monthly_budget - b.monthly_budget,
  },
  {
    title: "Check",
    scopedSlots: { customRender: "action" },
    align: "center",
    width: 150,
    fixed: "right",
  },
];
const white_list_columns = [
  {
    title: "Platform",
    dataIndex: "platform",
    width: 100,
    sorter: (a, b) => a.budget_ratio - b.budget_ratio,
  },
  {
    title: "Bid Strategy",
    dataIndex: "bid_type",
    ellipsis: true,
    width: 120,
    sorter: (a, b) => a.bid_type.localeCompare(b.bid_type),
  },
  {
    title: "Campaign ID",
    dataIndex: "campaign_id",
    width: 130,
    scopedSlots: { customRender: "campaign_id" },
    sorter: (a, b) =>
      a.campaign_id.toString().localeCompare(b.campaign_id.toString()),
  },
  {
    title: "Campaign Name",
    dataIndex: "campaign_name",
    width: 520,
    scopedSlots: { customRender: "campaign_name" },
    sorter: (a, b) => a.campaign_name.localeCompare(b.campaign_name),
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    width: 90,
    scopedSlots: { customRender: "start_date" },
    sorter: (a, b) => a.start_date.localeCompare(b.start_date),
  },
  {
    title: "Session",
    dataIndex: "sessions",
    customRender: (text) => {
      if (text) {
        return text;
      } else {
        return "/";
      }
    },
    width: 100,
    align: "right",
    sorter: (a, b) => a.sessions - b.sessions,
  },
  {
    title: "PV",
    dataIndex: "page_views",
    align: "right",
    width: 80,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => a.page_views - b.page_views,
  },
  {
    title: "Ads CTR",
    dataIndex: "ads_ctr",
    align: "right",
    customRender: (text) => {
      if (text) {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "%";
      } else {
        return "/";
      }
    },
    sorter: (a, b) => a.ads_ctr - b.ads_ctr,
    width: 100,
  },
  {
    title: "Ads CPC",
    dataIndex: "ads_cpc",
    align: "right",
    customRender: (text) => {
      if (text) {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      } else {
        return "/";
      }
    },
    sorter: (a, b) => a.ads_cpc - b.ads_cpc,
    width: 100,
  },
  {
    title: "P/S",
    dataIndex: "pages_per_session",
    align: "right",
    sorter: (a, b) => a.pages_per_session - b.pages_per_session,
    width: 100,
  },
  {
    title: "CPC 上限",
    dataIndex: "cpc_top",
    align: "right",
    sorter: (a, b) => a.cpc_top - b.cpc_top,
    width: 100,
  },
  {
    title: "Average CPC",
    dataIndex: "avg_cpc",
    align: "right",
    width: 140,
    customRender: (text) => {
      return text.toFixed(3);
    },
    sorter: (a, b) => a.avg_cpc - b.avg_cpc,
  },
  {
    title: "CPC%",
    dataIndex: "ratio",
    align: "right",
    sorter: (a, b) => a.ratio - b.ratio,
    width: 100,
    customRender: (text) => {
      return (text * 100).toFixed(2) + "%";
    },
  },
  {
    title: "Default CPC Before",
    dataIndex: "default_cpc",
    align: "right",
    sorter: (a, b) => a.default_cpc - b.default_cpc,
    width: 180,
  },
  {
    title: "Default CPC After",
    dataIndex: "suggest_cpc",
    align: "right",
    // customRender: text => {
    //   return parseFloat(text.toString()).toFixed(3).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    // },
    scopedSlots: { customRender: "suggest_cpc" },
    sorter: (a, b) => a.suggest_cpc - b.suggest_cpc,
    width: 150,
  },
  {
    title: "Suggested Default CPC After",
    dataIndex: "x",
    customRender: (text) => {
      return text.toFixed(3);
    },
    width: 150,
    align: "right",
  },
  {
    title: "Budget",
    dataIndex: "daily_budget",
    align: "right",
    width: 100,
    scopedSlots: { customRender: "campaign_budget" },
    sorter: (a, b) => a.daily_budget - b.daily_budget,
  },
  {
    title: "Budget%",
    dataIndex: "budget_ratio",
    width: 100,
    customRender: (text) => {
      return (text * 100).toFixed(2) + "%";
    },
    align: "right",
    sorter: (a, b) => a.budget_ratio - b.budget_ratio,
  },
  {
    title: "Monthly Budget",
    dataIndex: "monthly_budget",
    align: "right",
    width: 160,
    customRender: (text) => {
      if (text) {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      } else {
        return "/";
      }
    },
    sorter: (a, b) => a.monthly_budget - b.monthly_budget,
  },
  {
    title: "生效时间",
    dataIndex: "white_date",
    align: "left",
    sorter: (a, b) => a.white_date - b.white_date,
    width: 180,
  },
  {
    title: "Check",
    scopedSlots: { customRender: "action" },
    align: "center",
    width: 150,
    fixed: "right",
  },
];

export default {
  data() {
    return {
      campaign_ids: [],
      by_site_val: [],
      x11: 0,
      x12: 0,
      x31: 0,
      x32: 0,
      x33: 0,
      adj_session: 0,
      applyloading: false,
      pageSize: 100,
      activeKey: "1",
      userInfo: {},
      defaultWhiteStartDate,
      defaultWhiteEndDate,
      defaultSelectDate,
      defaultStartDate,
      url: "",
      columns,
      white_list_columns,
      dataList: [],
      dataList3: [],
      dataList4: [],
      start_date: "",
      size: "small",
      curtable_id: 0,
      cur_table_tl: "",
      suggest_id: 0,
      suggest_tl: "",
      ediTtitleLoading: false,
      loading: false,
      post_coverage_data: {},
      dateString: [
        defaultSelectDate.format("YYYY-MM-DD"),
        defaultSelectDate.format("YYYY-MM-DD"),
      ],
      whiteDateString: [
        defaultWhiteStartDate.format("YYYY-MM-DD"),
        defaultWhiteEndDate.format("YYYY-MM-DD"),
      ],
      platformList: [
        {
          id: 0,
          name: "all",
        },
        {
          id: 1,
          name: "taboola",
        },
        {
          id: 2,
          name: "outbrain",
        },
      ],
      platform: "all",
      site_list: "",
      daily_budget: "",
      campaign_type: "1",
      updateCampaignStatus: true,
    };
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.campaign_ids = selectedRowKeys;
      console.log(this.campaign_ids);
    },
    onSelectValue(e) {
      console.log(e.campaign_id);
    },
    adjustAll() {
      // this.loading = true;
      this.$post(api.bysite.adjustAll, {
        token: this.userInfo.token,
        campaign_ids: this.campaign_ids,
      }).then((res) => {
        // this.loading = false;
        if (res.data.status === 200) {
          this.$notification.success({
            message: res.data.message,
            duration: 0,
          });
        } else {
         this.$notification.error({
            message: res.data.message,
            duration: 0,
          });
        }
        this.campaign_ids = [];
      });
    },
    // 白名单提交
    submitBtn() {
      this.$post(api.bysite.addWhiteList, {
        token: this.userInfo.token,
        site_list: this.site_list,
        start_date: this.whiteDateString[0],
        end_date: this.whiteDateString[1],
      }).then((res) => {
        if (res.data.status == 200) {
          this.$message.success("提交成功");
        } else {
          this.$message.error("提交失败");
        }
      });
    },
    getAdjustVal() {
      this.$post(api.bysite.getAdjustVal, {
        token: this.userInfo.token,
      }).then((res) => {
        if (res.data.status == 200) {
          this.by_site_val = res.data.data;
          this.adj_session = res.data.data["session"];
          this.x11 = res.data.data["x11"];
          this.x12 = res.data.data["x12"];
          this.x31 = res.data.data["x31"];
          this.x32 = res.data.data["x32"];
          this.x33 = res.data.data["x33"];
        }
      });
    },
    updateAdjVal() {
      this.$post(api.bysite.updateAdjustVal, {
        token: this.userInfo.token,
        x11: this.x11,
        x12: this.x12,
        x31: this.x31,
        x32: this.x32,
        x33: this.x33,
        session: this.adj_session,
      }).then((res) => {
        if (res.data.status == 200) {
          this.$message.success("修改成功");
        } else {
          this.$message.error("修改失败");
        }
      });
    },
    noEditorBtn() {
      this.curtable_id = "";
    },
    noEditorBtn2() {
      this.suggest_id = "";
    },
    bindApply(record) {
      console.log(record);
      this.applyloading = true;
      this.$post(api.filter.updateCampaign, {
        token: this.userInfo.token,
        campaign_id: record.campaign_id,
        params: {
          daily_budget: record.daily_budget,
          default_cpc: record.suggest_cpc,
          by_site: 1,
        },
      }).then((res) => {
        this.applyloading = false;
        if (res.data.status == 200) {
          this.$message.success("修改成功");
          record.default_cpc = record.suggest_cpc;
          record.is_update = true;
        } else {
          this.$message.error("修改失败");
        }
      });
    },
    editorBtn(campaign_id, record) {
      (this.ediTtitleLoading = true),
        this.$post(api.filter.updateCampaign, {
          token: this.userInfo.token,
          campaign_id: campaign_id,
          params: {
            daily_budget: this.cur_table_tl,
          },
        }).then((res) => {
          this.ediTtitleLoading = false;
          if (res.data.status == 200) {
            this.$message.success("修改成功");
            record.daily_budget = this.cur_table_tl;
            this.curtable_id = "";
          } else {
            this.$message.error("修改失败");
          }
        });
    },
    editorBtn2(campaign_id, record) {
      this.updateCampaignStatus = true;
      if (record.platform == "taboola") {
        if (this.suggest_tl < 0.01) {
          this.$message.error("已达到最低值0.01");
          this.updateCampaignStatus = false;
        }
      } else if (record.id <= 11293 && record.platform == "outbrain") {
        if (this.suggest_tl < 0.03) {
          this.updateCampaignStatus = false;
          this.$message.error("已达到最低值0.03");
        }
      } else if (record.id > 11293 && record.platform == "outbrain") {
        if (this.suggest_tl < 0.01) {
          this.updateCampaignStatus = false;
          this.$message.error("已达到最低值0.01");
        }
      }
      if (this.updateCampaignStatus) {
        (this.ediTtitleLoading = true),
          this.$post(api.filter.updateCampaign, {
            token: this.userInfo.token,
            campaign_id: campaign_id,
            params: {
              default_cpc: this.suggest_tl,
              by_site: 1,
            },
          }).then((res) => {
            this.ediTtitleLoading = false;
            if (res.data.status == 200) {
              this.$message.success("修改成功");
              record.suggest_cpc = this.suggest_tl;
              record.default_cpc = this.suggest_tl;
              this.suggest_id = "";
            } else {
              this.$message.error("修改失败");
            }
          });
      }
    },
    editorTitle(id, title) {
      this.curtable_id = id;
      this.cur_table_tl = title;
    },
    editorTitle2(id, title) {
      this.suggest_id = id;
      this.suggest_tl = title;
    },

    toLine(campaign_id, start_date, end_date) {
      const { href } = this.$router.resolve({
        path: "/line",
        query: {
          campaign_id: campaign_id,
          period: JSON.stringify([start_date, end_date]),
        },
      });
      window.open(href, "_blank");
    },
    handlePlatformChange(e) {
      console.log(e);
      this.platform = e;
    },
    paneChange(e) {
      console.log(e);
      this.campaign_type = e;
      if (e == "1") {
        this.getList();
      } else if (e == "2") {
        this.getList2();
      } else if (e == "3") {
        this.getList3();
      }else if (e == "4") {
        this.getList4();
      }
    },
    onWhiteDateChange(date, dateString) {
      this.whiteDateString = dateString;
    },
    onDateChange(date, dateString) {
      this.dateString = dateString;
    },
    getList() {
      this.loading = true;
      this.$post(api.bysite.getBysite1Data, {
        platform: this.platform,
        token: this.userInfo.token,
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataList = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getList3() {
      this.loading = true;
      this.$post(api.bysite.getWhiteListCampaignData, {
        token: this.userInfo.token,
        platform: this.platform,
      }).then((res) => {
        this.loading = false;
        console.log(res.data.status);
        if (res.data.status === 200) {
          this.dataList3 = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    getList4() {
      this.loading = true;
      this.$post(api.bysite.getNewAccountCampaignData, {
        token: this.userInfo.token,
        platform: this.platform,
      }).then((res) => {
        this.loading = false;
        console.log(res.data.status);
        if (res.data.status === 200) {
          this.dataList4 = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getPlatformChange() {
      if (this.campaign_type == "1") {
        this.getList();
      } else if (this.campaign_type == "2") {
        this.getList2();
      } else if (this.campaign_type == "3") {
        this.getList3();
      }else if (this.campaign_type == "4") {
        this.getList();
      }
    },
    clearCache() {
      this.loading = true;
      this.$post(api.bysite.clearCache).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.$message.success("刷新成功");
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    goCover(record) {
      const { href } = this.$router.resolve({
        path: "/nocoverpost",
        query: {
          platform: record.platform,
          record: JSON.stringify(record),
        },
      });
      window.open(href, "_blank");
    },
  },
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.getList();
    this.getAdjustVal();
  },
};
</script>
<style>
.total-item {
  margin-right: 10px;
  margin-left: 10px;
  color: #ddd;
}
.bySite .editor-box {
  top: 0px;
  padding: 8px;
  bottom: auto;
}
.bySite .editor-btn {
  margin-top: 8px;
}
.bySite .edittitle {
  bottom: 2px;
  right: 2px;
}
.bySite .edit_budget:hover .edittitle {
  opacity: 1;
}
</style>
