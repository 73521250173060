<template>
  <div>
    <div class="section">
      <a-button
        type="primary"
        @click="getList"
        style="margin-left: 25px;background-color: #52c41a;border-color: #52c41a;"
        >Refresh</a-button
      >
      <a-card :bordered="false">
        <a-table
          :columns="columns"
          :data-source="dataList"
          :pagination="false"
          :rowKey="(record) => record.task_id"
          size="middle"
          bordered
          :loading="loading"
        >
          <span
            v-if="record.result === '失败'"
            style="color:red;text-decoration: underline;cursor: pointer;"
            slot="result"
            slot-scope="text, record"
            @click="runTask(record.task_id, record.name)"
          >
            {{ text }}
          </span>
          <span
            v-else-if="record.task_id === 37"
            style="text-decoration: underline;cursor: pointer;"
            slot="result"
            slot-scope="text, record"
            @click="runTask(record.task_id, record.name)"
          >
            {{ text }}
          </span>
          <span v-else style="color:green;" slot="result" slot-scope="text">{{
            text
          }}</span>
        </a-table>
        <div
          style="margin: 24px 24px 0;padding:3px;font-family:'Roboto', sans-serif;font-weight：400"
        >
          1. Result 显示为“失败”时可直接点击“失败”来更新。<br />
          2.
          可在一段时间（更新时间不一致，但都不会超过300s）后点击“Refresh”查看是否更新成功，若还是失败可重复一到两次步骤1、2。<br />
          3. 上述步骤依然失败可直接联系后端。<br />
          4. 请在最后一步点击最后一项“添加Daily宗数”的 Result
          来记录Daily宗数。<br />
        </div>
      </a-card>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
const columns = [
  {
    title: "Task ID",
    dataIndex: "task_id",
    align: "right",
    sorter: (a, b) => {
      return a.task_id - b.task_id;
    },
  },
  {
    title: "Task Name",
    dataIndex: "name",
    sorter: (a, b) => {
      return a.name.localeCompare(b.name);
    },
  },
  {
    title: "Start Time",
    dataIndex: "start_time",
    sorter: (a, b) => {
      return a.start_time.localeCompare(b.start_time);
    },
  },
  {
    title: "End Time",
    dataIndex: "end_time",
    sorter: (a, b) => {
      return a.end_time.localeCompare(b.end_time);
    },
  },
  {
    title: "Estimated Time",
    dataIndex: "estimated_time",
    align: "right",
    customRender: (text) => {
      return text + "s";
    },
    sorter: (a, b) => {
      return a.estimated_time - b.estimated_time;
    },
  },
  {
    title: "Execute Time",
    dataIndex: "execute_time",
    align: "right",
    customRender: (text) => {
      return text + "s";
    },
    sorter: (a, b) => {
      return a.execute_time - b.execute_time;
    },
  },
  {
    title: "Result",
    dataIndex: "result",
    scopedSlots: { customRender: "result" },
    sorter: (a, b) => {
      return a.result.localeCompare(b.result);
    },
  },
];

export default {
  data() {
    return {
      userInfo: {},
      columns,
      dataList: [],
      loading: false,
    };
  },
  // 定义方法
  methods: {
    // 获取数据列表
    getList() {
      this.loading = true;
      this.$post(api.tools.getTaskLog, {
        token: this.userInfo.token,
        url: this.url,
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataList = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 更新数据
    runTask(task_id, task_name) {
      this.$post(api.tools.runTask, {
        token: this.userInfo.token,
        task_id: task_id,
        task_name: task_name,
      }).then((res) => {
        if (res.data.status === 200) {
          this.$notification.success({
            message: res.data.title,
            description: res.data.message,
            duration: 0,
          });
        } else {
          this.$notification.error({
            message: res.data.title,
            description: res.data.message,
            duration: 0,
          });
        }
      });
    },
  },

  // 页面初始化
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.getList();
  },
};
</script>
<style scoped>
.ant-table-thead >>> tr > th,
.ant-table-tbody > tr > td div {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
