<template>
  <div>
    <!-- <div class="ant-page-header-tl"><a-date-picker :default-value="defaultSelectDate" @change="onDateChange" /></div> -->
    <div class="section">
        <a-table 
          :columns="columns" 
          :data-source="dataList" 
          :rowKey="record => record.campaign_id"
          :pagination="false" 
          size="middle"
          bordered
          :loading="loading"
        >
          <div v-if="record.platform != 'Total'" class="smallbtn" slot="action" slot-scope="text, record">
            <a-button type="primary" size="small" @click="goCreated(record.campaign_id)">Relaunch</a-button>
          </div> 
        </a-table>
    </div>
  </div>
</template>
<script>
  import api from '@/common/api.js'

  const columns = [
    {
      title:'Campaign Id',
      dataIndex: 'campaign_id',
      sorter: (a, b) => a.campaign_id.toString().localeCompare(b.campaign_id.toString())
    },
    {
      title:'Campaign Name',
      dataIndex: 'campaign_name',
      sorter: (a, b) => a.campaign_name.localeCompare(b.campaign_name)
    },
    {
        title:'Detail',
        scopedSlots: { customRender: 'action' },
        align: 'center',
        width: '100px'
    }
  ]

  export default {
    data() {
      return {
        userInfo: {},
        url: '',
        platform: '',
        columns,
        dataList: [],
        loading: false,
      }
    },
    methods: {
      getList(){
        this.loading = true
        this.$post(api.update.getCampaignList, {
          landing_page: this.url,
          platform: this.platform.toLowerCase(),
          token: this.userInfo.token
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.dataList = res.data.data.campaign_list
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      goCreated(campaign_id){
        const {href} = this.$router.resolve({
          path: '/created',
          query: {
            campaign_id: campaign_id,
            type: 'relaunch',
            platform: this.platform.toLowerCase()
          },
        })
        window.open(href, '_blank')
      }
    },
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.url = this.$route.query.url
      this.platform = this.$route.query.platform
      this.getList()
    }
  };
</script>

<style> 
  .total-item{
    margin-right: 10px;
    margin-left: 10px;
    color: #ddd;
  }
</style>
