<template>
  <div>
    <div class="ant-page-header-tl">
      <a-range-picker :default-value="dateString" @change="onDateChange" />
      <a-button type="primary" @click="getData" style="margin-left: 8px"
        >Apply</a-button
      >
      <a-button style="margin-left: 8px" @click="toggleExpand">{{ expandAll ? '折叠所有' : '展开所有' }}</a-button>
    </div>

    <div
      class="section-wrapper"
      style=" margin-left: 24px; margin-right: 300px;float: left;"
    >
    <a-card :bordered="false" title="Bing Spent by Customer">
        <a-table
          :columns="customerColumns"
          :data-source="customerPerfList"
          :rowKey="(record) => record.id"
          :pagination="false"
          bordered
          :loading="loading"
          class="znum-data-table"
          :expandedRowKeys="expandedRowKeysCustomer"
          :expandable="{ onExpand: handleOnExpandCustomer }"
          @expand="onRowExpandCustomer"
          :rowClassName="function (record) {
            if (record.class_level != null) {
              let className = '';
              if (record.class_level == 0) {
                className = 'layout-table-level0';
              } 
              return className;
            }
          }
        "
        >
        <div
            class="smallbtn"
            slot="action"
            slot-scope="text, record"
            style="padding: 4px 0px"
          >
            <a-button type="link" size="small" @click="goDetail(record)">Check</a-button>
          </div>
        </a-table>
    </a-card>
    </div>

    <div
      class="section-wrapper"
      style=" margin-left: 24px; margin-right: 300px;float: left;"
    >
      <a-card :bordered="false" title="Bing Spent by Account">
        <a-table
          :columns="columns"
          :data-source="spentPerfList"
          :rowKey="(record) => record.id"
          :pagination="false"
          bordered
          :loading="loading"
          class="znum-data-table"
          :expandedRowKeys="expandedRowKeys"
          :expandable="{ expandedRowRender, onExpand: handleOnExpand }"
          @expand="onRowExpand"
          :rowClassName="function (record) {
            if (record.class_level != null) {
              let className = '';
              if (record.class_level == 0) {
                className = 'layout-table-level0';
              } else if (record.class_level == 1) {
                className = 'layout-table-level1';
              }
              return className;
            }
          }
        "
        >

        <span
          slot="name"
          slot-scope="text, record"
        >
          <a-popover>
            <template slot="content">
              {{ record.remark }}
            </template>
              {{ text }}
          </a-popover>
        </span>

          <div
            class="smallbtn"
            slot="action"
            slot-scope="text, record"
            style="padding: 4px 0px"
          >
            <a-button type="link" size="small" @click="goDetail(record)">Check</a-button>
          </div>
        </a-table>
      </a-card>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";

const defaultStartDate = moment()
  .startOf("day")
  .subtract(2, "days");
const defaultEndDate = moment()
  .startOf("day")
  .subtract(2, "days");

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    align: "left",
    width: 150,
    scopedSlots: { customRender: 'name' },
    sorter: (a, b) => {
      return a.name.localeCompare(b.name);
    },
  },
  {
    title: "Spend",
    dataIndex: "spent",
    align: "right",
    sorter: (a, b) => a.spent - b.spent,
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  {
    title: "Clicks",
    dataIndex: "clicks",
    align: "right",
    sorter: (a, b) => a.clicks - b.clicks,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Impression",
    dataIndex: "impressions",
    align: "right",
    sorter: (a, b) => a.impressions - b.impressions,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "CTR",
    dataIndex: "ctr",
    align: "right",
    sorter: (a, b) => a.ctr - b.ctr,
    customRender: (text) => {
      return (
        parseFloat(text.toString())
          .toFixed(2)
          .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "%"
      );
    },
  },
  {
    title: "Avg.CPC",
    dataIndex: "avg_cpc",
    align: "right",
    sorter: (a, b) => a.avg_cpc - b.avg_cpc,
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(2)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Conversions",
    dataIndex: "conversions",
    align: "right",
    sorter: (a, b) => a.conversions - b.conversions,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Conversion Rate",
    dataIndex: "conversion_rate",
    align: "right",
    sorter: (a, b) => a.conversion_rate - b.conversion_rate,
    customRender: (text) => {
      return (
        parseFloat(text.toString())
          .toFixed(2)
          .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "%"
      );
    },
  },
  {
    title: "Account Name",
    dataIndex: "account_name",
    align: "left",
    width: 200,
    scopedSlots: { customRender: 'account_name' },
    sorter: (a, b) => {
      return a.account_name.localeCompare(b.account_name);
    },
  },
  {
    title: "Account ID",
    dataIndex: "account_id",
    align: "left",
    width: 150,
    scopedSlots: { customRender: 'account_id' },
    sorter: (a, b) => {
      return a.account_id.localeCompare(b.account_id);
    },
  },
  {
    title: "Balance",
    dataIndex: "balance",
    align: "right",
    sorter: (a, b) => a.balance - b.balance,
    customRender: (text) => {
      return (
        parseFloat(text.toString())
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
    },
  },
  {
    title: "近七天日均消耗",
    dataIndex: "avg_spent",
    align: "right",
    sorter: (a, b) => a.avg_spent - b.avg_spent,
    customRender: (text) => {
      return (
        parseFloat(text.toString())
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
    },
  },
  {
    title: "预估可消耗天数",
    dataIndex: "limit_days",
    align: "right",
    sorter: (a, b) => a.limit_days - b.limit_days,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "By Day",
    scopedSlots: { customRender: "action" },
    align: "center",
    width: 100,
  },
];

const customerColumns = [
  {
    title: "Name",
    dataIndex: "name",
    align: "left",
    width: 150,
    scopedSlots: { customRender: 'name' },
    sorter: (a, b) => {
      return a.name.localeCompare(b.name);
    },
  },

  {
    title: "Account Name",
    dataIndex: "id",
    align: "left",
    width: 150,
    scopedSlots: { customRender: 'name' },
    sorter: (a, b) => {
      return a.id.localeCompare(b.id);
    },
  },
  {
    title: "Spend",
    dataIndex: "spent",
    align: "right",
    sorter: (a, b) => a.spent - b.spent,
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  {
    title: "Clicks",
    dataIndex: "clicks",
    align: "right",
    sorter: (a, b) => a.clicks - b.clicks,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Impression",
    dataIndex: "impressions",
    align: "right",
    sorter: (a, b) => a.impressions - b.impressions,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "CTR",
    dataIndex: "ctr",
    align: "right",
    sorter: (a, b) => a.ctr - b.ctr,
    customRender: (text) => {
      return (
        parseFloat(text.toString())
          .toFixed(2)
          .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "%"
      );
    },
  },
  {
    title: "Avg.CPC",
    dataIndex: "avg_cpc",
    align: "right",
    sorter: (a, b) => a.avg_cpc - b.avg_cpc,
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(2)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Conversions",
    dataIndex: "conversions",
    align: "right",
    sorter: (a, b) => a.conversions - b.conversions,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Conversion Rate",
    dataIndex: "conversion_rate",
    align: "right",
    sorter: (a, b) => a.conversion_rate - b.conversion_rate,
    customRender: (text) => {
      return (
        parseFloat(text.toString())
          .toFixed(2)
          .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "%"
      );
    },
  },
  {
    title: "Balance",
    dataIndex: "balance",
    align: "right",
    sorter: (a, b) => a.balance - b.balance,
    customRender: (text) => {
      return (
        parseFloat(text.toString())
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
    },
  },
  {
    title: "近七天日均消耗",
    dataIndex: "avg_spent",
    align: "right",
    sorter: (a, b) => a.avg_spent - b.avg_spent,
    customRender: (text) => {
      return (
        parseFloat(text.toString())
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
    },
  },
  {
    title: "预估可消耗天数",
    dataIndex: "limit_days",
    align: "right",
    sorter: (a, b) => a.limit_days - b.limit_days,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "By Day",
    scopedSlots: { customRender: "action" },
    align: "center",
    width: 100,
  },
];

export default {
  data() {
    return {
      moment,
      userInfo: {},
      columns,
      customerColumns,
      loading: false,
      okrList_2: [],
      spentPerfList: {},
      customerPerfList: {},
      quarterList: [],
      defaultStartDate,
      defaultEndDate,
      start_date: "",
      end_date: "",
      dateString: [
        defaultStartDate.format("YYYY-MM-DD"),
        defaultEndDate.format("YYYY-MM-DD"),
      ],
      expandedRowKeys: [],
      expandedRowKeysCustomer:[],
      expandAll: false,
    };
  },
  methods: {
    getDailyPerf() {
      this.loading = true;
      this.$post(api.pref.getBingSpentList, {
        token: this.userInfo.token,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.spentPerfList = res.data.data;
          for (let i = 0; i < this.spentPerfList.length; i++) {
            this.spentPerfList[i].class_level = 0;
            if (this.spentPerfList[i].children != null) {
              for (let j = 0; j < this.spentPerfList[i].children.length; j++) {
                this.spentPerfList[i].children[j].class_level = 1;
              }
            }
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    getCustomerPerf() {
      this.loading = true;
      this.$post(api.pref.getBingSpentByCustomer, {
        token: this.userInfo.token,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.customerPerfList = res.data.data;
          for (let i = 0; i < this.customerPerfList.length; i++) {
            this.customerPerfList[i].class_level = 0;
            if (this.customerPerfList[i].children != null) {
              for (let j = 0; j < this.customerPerfList[i].children.length; j++) {
                this.customerPerfList[i].children[j].class_level = 1;
              }
            }
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    goDetail(record) {
      const { href } = this.$router.resolve({
        path: "/BingSpentReportDetail",
        query: {
          level: record.level,
          site: record.site,
          customer: record.customer_id,
          account: record.account_id,
          name: record.name,
        },
      });
      window.open(href, "_blank");
    },

    onDateChange(date, dateString) {
      this.dateString = dateString;
    },
    getData() {
      this.getCustomerPerf();
      this.getDailyPerf();
    },

    onRowExpand(expanded, record) {
      this.handleOnExpand(expanded, record);
    },
    onRowExpandCustomer(expanded, record) {
      this.handleOnExpandCustomer(expanded, record);
    },

    toggleExpand() {
      if (this.expandAll) {
        this.expandedRowKeys = [];
        this.expandedRowKeysCustomer=[];
      } else {
        this.setDefaultExpandedKeys();
      }
      this.expandAll = !this.expandAll;
    },

    // 默认展开所有行（一级和二级）
    setDefaultExpandedKeys() {
      const keys = [];
      this.spentPerfList.forEach((item) => {
        keys.push(item.id);
        if (item.children) {
          item.children.forEach((child) => {
            keys.push(child.id);
          });
        }
      });
      this.expandedRowKeys = keys;
      
      const keys_c = [];
      this.customerPerfList.forEach((item) => {
        keys_c.push(item.id);
        if (item.children) {
          item.children.forEach((child) => {
            keys_c.push(child.id);
          });
        }
      });
      this.expandedRowKeysCustomer = keys_c;
    },

    expandedRowRender(record) {
      // 如果有第二级，则返回第二级的表格或内容
      if (record.children) {
        return `<a-table :columns="columns" :data-source="record.children" :pagination="false"></a-table>`;
      }
      return null;
    },

    handleOnExpandCustomer(expanded, record) {
      const id = record.id;
      let rowIndex = this.expandedRowKeysCustomer.indexOf(id);
      if (expanded) {
        if (rowIndex === -1) {
          this.expandedRowKeysCustomer.push(id);
        }
      } else {
        if (rowIndex !== -1) {
          this.expandedRowKeysCustomer.splice(rowIndex, 1);
        }
      }

      // 检查是否所有项都已展开来更新 expandAll 状态
      this.expandAll = this.expandedRowKeysCustomer.length +this.expandedRowKeys.length=== this.spentPerfList.length+this.customerPerfList.length;
    },
    
    handleOnExpand(expanded, record) {
      const id = record.id;
      let rowIndex = this.expandedRowKeys.indexOf(id);
      if (expanded) {
        if (rowIndex === -1) {
          this.expandedRowKeys.push(id);
        }
      } else {
        if (rowIndex !== -1) {
          this.expandedRowKeys.splice(rowIndex, 1);
        }
      }

      // 检查是否所有项都已展开来更新 expandAll 状态
      this.expandAll = this.expandedRowKeysCustomer.length +this.expandedRowKeys.length=== this.spentPerfList.length+this.customerPerfList.length;
    },
    //
  },

  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.getCustomerPerf();
    this.getDailyPerf();
  },
};
</script>

<style>
.total-item {
  margin-right: 10px;
  margin-left: 10px;
  color: #ddd;
}
.znum-data-table tr:last-child td {
  border-top: 1px #e8e8e8;
}
.layout-table-level0 {
    font-weight: 700;
    color: #102e4a;
}
.layout-table-level1 {
    font-weight: 600;
    color: #35648e;
}
</style>
