<template>
  <div>
    <div class="ant-page-header-tl">批量添加推送</div>

    <div style="padding:40px;">
      <div class="ant-page-header-tl" style="padding-bottom: 0">
        <div class="pushInput">
          <div class="pushInputItem">
            <span>日期</span>
            <a-range-picker
              :default-value="[
                defaultSelectDate.startDate,
                defaultSelectDate.startDate,
              ]"
              @change="onRangePickerChange"
            />
          </div>
        </div>
      </div>
      <div class="ant-page-header-tl">
        <div class="pushInput">
          <div class="pushInputItem">
            <span>URL</span>
            <a-input type="text" v-model="url" />
          </div>
          <div class="pushInputItem">
            <span>title</span>
            <a-input type="text" v-model="title" />
          </div>
          <div class="pushInputItem">
            <span>message</span>
            <a-input type="text" v-model="message" />
          </div>
          <div class="pushInputItem item-btn">
            <span>icon</span>
            <a-input type="text" v-model="icon" />
          </div>
          <div class="pushInputItem item-btn">
            <span>选择站点</span>
            <a-select
              placeholder="请选择站点"
              style="width: 400px;margin-right:8px;"
              mode="multiple"
              @change="handleSiteChange"
            >
              <a-select-option
                v-for="(item, index) in siteList"
                :key="index"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </div>
          <div class="pushInputItem">
            <a-button style="margin-left: 8px" type="primary" @click="getList"
              >确认</a-button
            >
          </div>
          <div class="pushInputItem">
            <span></span>
            <a-button type="primary" @click="goPreview()">预览</a-button>
          </div>
        </div>
        <!--      <a-select placeholder="请选择站点" style="width: 300px;margin-right:8px;" mode="multiple" @change="handleSiteChange">-->
        <!--        <a-select-option v-for="(item, index) in siteList" :key="index" :value="item.value">{{item.name}}</a-select-option>-->
        <!--      </a-select>-->
        <!--      <a-range-picker :default-value="[defaultSelectDate.startDate, defaultSelectDate.startDate]" @change="onRangePickerChange" />-->
        <!--      <a-button style="margin-left: 8px" type="primary" @click="getList">确认</a-button>-->
      </div>
      <a-table
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="columns"
        :pagination="false"
        :rowKey="(record) => record.index"
        :loading="loading"
        :data-source="list"
      >
        <!--        <a-input slot="url" slot-scope="result, record" v-model="record.url" @change="onUrlChange(record.index, record.url)" type="text" />-->
        <!--        <a-input slot="titles" slot-scope="result, record" v-model="record.titles" type="text" />-->
        <!--        <a-input slot="message" slot-scope="result, record" v-model="record.message" type="text" />-->
        <!--        <a-input slot="icon" slot-scope="result, record" v-model="record.icon" type="text" />-->
        <!-- <a-input slot="image" slot-scope="result, record" v-model="record.image" type="text" /> -->
        <div slot="ps_mobile_pv" slot-scope="text">
          <span v-if="text == '/'">{{ text }}</span>
          <span v-else>{{ text + "%" }}</span>
        </div>
        <!-- <a-button slot="mod" slot-scope="text, record" type="primary" size="small" v-if="record.mod!=0" @click="getMod(record.site,record.index,record.url)">模板</a-button> -->
        <!-- <a-button slot="mod" slot-scope="text, record" type="primary" size="small" @click="getMod(record.site,record.index,record.url)" v-else disabled>模板</a-button> -->
        <a-button
          slot="action"
          slot-scope="text, record"
          type="primary"
          size="small"
          @click="
            goPreview(
              record.icon,
              record.url,
              record.index,
              record.titles,
              record.message,
              record.pushsite,
              record.badge,
              record.image
            )
          "
          >预览</a-button
        >
      </a-table>
      <div class="ant-page-header-tl">
        <div>共{{ daysum }}天，共{{ listcount }}条数据</div>
        <a-button
          v-if="selectedRows != false"
          @click="checkShowModal(0)"
          type="primary"
          class="syncbtn"
        >
          添加推送
        </a-button>
        <a-button
          v-else
          disabled
          @click="checkShowModal(0)"
          type="primary"
          class="syncbtn"
        >
          添加推送
        </a-button>

        <a-button
          v-if="selectedRows != false"
          @click="checkShowModal(1)"
          type="primary"
          class="syncbtn"
          style="float:right;background-color: #52c41a;border-color: #52c41a"
        >
          立即推送
        </a-button>
        <a-button
          v-else
          disabled
          @click="checkShowModal(1)"
          type="primary"
          class="syncbtn"
          style="float:right;"
        >
          立即推送
        </a-button>

        <div style="font-size:14px;color:#666;">备注：</div>
        <div style="font-size:14px;color:#666;">
          * 1.
          选择站点和日期，点击“确认”出现出现要推送的站点和时间，填写和预览完毕后点击“添加推送”即可。
        </div>
        <div style="font-size:14px;color:#666;">
          * 2. title与message不要使用成对的英文单引号与双引号。
        </div>
      </div>
    </div>

    <a-modal v-model="visible" title="提示" @ok="addPushapps">
      <div slot="footer">
        <a-button v-if="preview_status" type="primary" @click="addPushapps"
          >确认</a-button
        >
      </div>
      <!-- <p>共{{daysum}}天，共{{listcount}}条数据</p> -->
      <div v-if="preview_status">
        <p>{{ action_str }}</p>
        <p>
          即将添加 {{ time_start }}~{{ time_over }} 期间的
          <span style="color:red">{{ count }}</span> 条推送记录
        </p>
        <p>是否确认</p>
      </div>
      <div v-else>
        <p>{{ action_str }}</p>
        <p>请全部预览后重试</p>
      </div>
    </a-modal>

    <a-modal v-model="visible_immediate" title="提示" @ok="addPushImmediate">
      <div slot="footer">
        <a-button v-if="preview_status" type="primary" @click="addPushImmediate"
          >确认</a-button
        >
      </div>
      <!-- <p>共{{daysum}}天，共{{listcount}}条数据</p> -->
      <div v-if="preview_status">
        <p>{{ action_str }}</p>
        <p>
          即将立即推送 {{ time_start }}~{{ time_over }} 期间的
          <span style="color:red">{{ count }}</span> 条推送记录
        </p>
        <p>是否确认</p>
      </div>
      <div v-else>
        <p>{{ action_str }}</p>
        <p>请全部预览后重试</p>
      </div>
    </a-modal>

    <div class="preview" v-if="url" ref="refModel">
      <a-modal
        :getContainer="() => $refs.refModel"
        v-model="visible_preview"
        title="预览"
        :closable="false"
        :maskClosable="false"
        @ok="hideModal"
      >
        <div slot="footer">
          <a-button type="primary" @click="hideModal">确认</a-button>
        </div>
        <a-row v-if="icon">
          <a-col :span="3" class="line-name">
            推送效果预览(桌面端iOS)
          </a-col>
          <a-col :span="6">
            <div
              style="display:flex;background-color:#f2f7f6f1;border-radius:8px;width:350px;height:64px;"
            >
              <div
                style="width:52px;display: flex;
          justify-content: center;
          align-items: center;"
              >
                <img
                  src="@/assets/chrome_logo.png"
                  alt=""
                  style="width:26px;height:26px;border-radius:2px;"
                />
              </div>
              <div style="width:166px;padding:6px 0;">
                <p
                  style="margin:-2px 0;font-size:12px;font-weight:bold;overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;"
                >
                  {{ title }}
                </p>
                <p
                  style="margin:0px;font-size:10px;font-weight:bold;overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;"
                >
                  {{ pushsite }}
                </p>
                <p
                  style="margin:0px;font-size:10px;overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;"
                >
                  {{ message }}
                </p>
              </div>
              <div
                style="width:64px;display: flex;
          justify-content: center;
          align-items: center;border-right:1px solid #e4e4e4;"
              >
                <img
                  :src="icon"
                  alt=""
                  style="width:44px;height:44px;border-radius:2px;"
                />
              </div>
              <div
                style="display: flex;flex-direction: column;justify-content: center;"
              >
                <div
                  style="font-size:14px;text-align: center;height:32px;width:68px;line-height:32px;border-bottom:1px solid #e4e4e4;"
                >
                  关闭
                </div>
                <div
                  style="font-size:14px;text-align: center;height:32px;width:68px;line-height:32px;"
                >
                  设置
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row v-if="icon">
          <a-col :span="3" class="line-name">
            推送效果预览(移动端Android)
          </a-col>
          <a-col :span="6">
            <div
              style="display:flex;flex-direction:column;background-color:#f2f7f6f1;border-radius:8px;width:350px;padding:14px;"
            >
              <div style="display: flex;height: 20px;">
                <img
                  src="@/assets/chrome_logo_app.png"
                  alt=""
                  style="width:18px;height:18px;border-radius:2px;margin-right:10px;opacity: 0.7;"
                />
                <p
                  style="margin:0px;font-size:10px;font-weight:bold;overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;"
                >
                  {{ pushsite }}
                </p>
              </div>
              <div style="display: flex;">
                <div style="width:260px;">
                  <p
                    style="margin:6px 0 -2px 0;font-size:10px;font-weight:bold;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;"
                  >
                    {{ title }}
                  </p>
                  <p
                    style="margin:0px;font-size:10px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;"
                  >
                    {{ message }}
                  </p>
                </div>
                <div
                  style="width:64px;display: flex;justify-content: center;align-items: center;"
                >
                  <img
                    :src="icon"
                    alt=""
                    style="width:44px;height:44px;border-radius:2px;"
                  />
                </div>
              </div>
              <div v-if="image">
                <img
                  :src="image"
                  alt=""
                  style="display:block;object-fit:cover;width:322px;height:160px;border-radius:2px;margin-top:6px;"
                />
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row v-if="icon">
          <a-col :span="3" class="line-name">
            icon
          </a-col>
          <a-col :span="6">
            <img :src="icon" alt="" style="width:75px;height:75px" />
          </a-col>
        </a-row>
        <a-row v-if="url">
          <a-col :span="16" offset="1">
            <iframe width="1400" height="640" :src="url"></iframe>
          </a-col>
        </a-row>
      </a-modal>
    </div>

    <div style="padding:40px;">
      <div class="ant-page-header-tl">推送历史</div>
      <a-table
        :columns="historyColumns"
        :pagination="false"
        :rowKey="(record) => record.id"
        :loading="loading"
        :data-source="historyList"
      >
        <a-input
          slot="url"
          disabled
          slot-scope="result, record"
          v-model="record.url"
          @change="onUrlChange(record.index, record.url)"
          type="text"
        />
        <a-input
          slot="titles"
          disabled
          slot-scope="result, record"
          v-model="record.titles"
          type="text"
        />
        <a-input
          slot="message"
          disabled
          slot-scope="result, record"
          v-model="record.message"
          type="text"
        />
        <a-input
          slot="icon"
          disabled
          slot-scope="result, record"
          v-model="record.icon"
          type="text"
        />
        <!-- <a-input slot="badge" disabled slot-scope="result, record" v-model="record.badge" type="text" /> -->
        <!-- <a-input slot="image" disabled slot-scope="result, record" v-model="record.image" type="text" /> -->
        <div slot="ps_mobile_pv" slot-scope="text">
          <span v-if="text == '/'">{{ text }}</span>
          <span v-else>{{ text + "%" }}</span>
        </div>
        <!-- <a-button slot="mod" slot-scope="text, record" type="primary" size="small" v-if="record.mod!=0" @click="getMod(record.site,record.index,record.url)">模板</a-button> -->
        <!-- <a-button slot="mod" slot-scope="text, record" type="primary" size="small" @click="getMod(record.site,record.index,record.url)" v-else disabled>模板</a-button> -->
        <a-button
          slot="action"
          slot-scope="text, record"
          type="primary"
          size="small"
          @click="
            goPreview(
              record.icon,
              record.url,
              record.index,
              record.titles,
              record.message
            )
          "
          >预览</a-button
        >
      </a-table>
    </div>
  </div>
</template>

<script>
import api from "@/common/api.js";
import moment from "moment";
const defaultSelectDate = {
  startDate: moment()
    .startOf("day")
    .subtract(-1, "days"),
};
const columns = [
  {
    title: "序号",
    dataIndex: "index",
  },
  {
    title: "实际站点",
    dataIndex: "pushsite",
  },
  {
    title: "pushdate",
    dataIndex: "pushdate",
  },
  {
    title: "hour",
    dataIndex: "hour",
  },
  {
    title: "country",
    dataIndex: "country",
    scopedSlots: { customRender: "country" },
  },
];
const historyColumns = [
  {
    title: "序号",
    dataIndex: "id",
  },
  {
    title: "实际站点",
    dataIndex: "sites",
  },
  {
    title: "推送站点",
    dataIndex: "pushsite",
  },
  {
    title: "pushdate",
    dataIndex: "pushdate",
  },
  {
    title: "hour",
    dataIndex: "hour",
  },
  {
    title: "URL",
    dataIndex: "url",
    scopedSlots: { customRender: "url" },
  },
  {
    title: "title",
    dataIndex: "title",
    scopedSlots: { customRender: "title" },
  },
  {
    title: "message",
    dataIndex: "message",
    scopedSlots: { customRender: "message" },
  },
  {
    title: "icon",
    dataIndex: "icon",
    scopedSlots: { customRender: "icon" },
  },
];
export default {
  data() {
    return {
      author: {},
      review: "",
      username: "",
      account: "",
      loading: false,
      site: "",
      siteList: [
        { value: "LF", name: "LittleFries" },
        { value: "FD", name: "FitDib" },
        { value: "FP", name: "Flipopular" },
        { value: "AC", name: "AlphaCute" },
        { value: "LF101", name: "LiveFit101" },
        { value: "BA", name: "BuzzAura" },
        { value: "BF", name: "BuzzFond" },
        { value: "BS", name: "BuzzSuper" },
        { value: "FT", name: "Fittors" },
        { value: "TDI", name: "TheDecorIdeas" },
        { value: "HS", name: "Housing" },
        { value: "GAME_WWW", name: "GameJoystick(WWW)" },
        { value: "GAME_JA", name: "GameJoystick(JA)" },
        { value: "usdentalservice", name: "(G7)USDentalService" },
        { value: "lawinfopedia", name: "(G7)LawInfoPedia" },
        { value: "trackingex", name: "(G7)TrackingEX" },
        // {value: 'GAME',name: 'GameJoystick(EN)',},
        // {value: 'GAME_ES',name: 'GameJoystick(ES)',},
      ],
      pushsite: "",
      badge: "",
      image: "",
      defaultSelectDate,
      list: [],
      historyList: [],
      time_start: "",
      time_over: "",
      columns,
      historyColumns,
      selectedRows: [],
      selectedRowKeys: [],
      daysum: 0,
      listcount: 0,
      visible: false,
      visible_immediate: false,
      visible_preview: false,
      count: 0,
      index: "",
      action: [],
      action_str: "",
      preview_status: false,
      title: "",
      message: "",

      pushid: "",
      defaultsiteValue: "",
      sites: [
        "www.littlefries.com",
        "www.fitdib.com",
        "www.flipopular.com",
        "www.alphacute.com",
        "www.livefit101.com",
        "www.buzzaura.com",
        "www.buzzfond.com",
        "www.buzzsuper.com",
        "www.fittors.com",
        "www.thedecorideas.com",
        "housing.livefit101.com",
        "www.gamejoystick.com",
        "en.gamejoystick.com",
        "ja.gamejoystick.com",
        "es.gamejoystick.com",
      ],
      url: "",
      icon: "",
      dateString: "",
      dateFormat: "YYYY-MM-DD",
      hour: "",
      hourlist: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
      ],
    };
  },
  methods: {
    moment,
    showModal() {
      console.log(this.$refs);
      this.visible_preview = true;
    },
    hideModal() {
      this.visible_preview = false;
      this.pushsite = "";
      this.badge = "";
      this.image = "";
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRows = selectedRows;
      this.selectedRowKeys = selectedRowKeys;
    },
    onRangePickerChange(date, dateString) {
      this.time_start = dateString[0];
      this.time_over = dateString[1];
    },
    handleSiteChange(e) {
      this.site = e;
    },
    // onUrlChange(index, url){console.log(url)},
    onUrlChange1(index, url) {
      var url_arr = url.split("/");
      let slug = "";
      slug = url_arr[url_arr.length - 2];
      this.list[index - 1].icon =
        "https://www.popranking.com/push/icon/" + slug + ".jpg";
      this.list[index - 1].image =
        "https://myappcdn.com/push_pic/Your-Mobile-Phone-Ringtones-Influence-On-Others.jpeg";
    },
    getList() {
      this.list = [];
      if (!this.site) {
        this.$message.error("请选择站点");
      } else {
        if (!this.time_start || !this.time_over) {
          var date = new Date();

          date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
          var year = date.getFullYear();
          var month = date.getMonth() + 1;
          var day = date.getDate();
          if (month < 10) {
            month = "0" + month;
          }
          if (day < 10) {
            day = "0" + day;
          }
          var nowDate = year + "-" + month + "-" + day;
          this.time_start = this.time_over = nowDate;
        }

        //初始化日期列表，数组
        var diffdate = new Array();
        var i = 0;
        var index = 1;
        var time_start = this.time_start;
        var time_over = this.time_over;
        //开始日期小于等于结束日期,并循环
        while (time_start <= time_over) {
          diffdate[i] = time_start;
          //获取开始日期时间戳
          var stime_ts = new Date(time_start).getTime();
          this.site.forEach((element) => {
            if (element == "LF") {
              this.list.push({
                index: index++,
                site: "www.littlefries.com",
                pushsite: "www.littlefries.com",
                pushdate: time_start,
                hour: "0",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "www.littlefries.com",
                pushsite: "www.littlefries.com",
                pushdate: time_start,
                hour: "9",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "www.littlefries.com",
                pushsite: "www.littlefries.com",
                pushdate: time_start,
                hour: "21",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
            } else if (element == "FD") {
              this.list.push({
                index: index++,
                site: "www.fitdib.com",
                pushsite: "www.fitdib.com",
                pushdate: time_start,
                hour: "0",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "www.fitdib.com",
                pushsite: "www.fitdib.com",
                pushdate: time_start,
                hour: "9",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "www.fitdib.com",
                pushsite: "www.fitdib.com",
                pushdate: time_start,
                hour: "21",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
            } else if (element == "FP") {
              this.list.push({
                index: index++,
                site: "www.flipopular.com",
                pushsite: "www.flipopular.com",
                pushdate: time_start,
                hour: "0",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "www.flipopular.com",
                pushsite: "www.flipopular.com",
                pushdate: time_start,
                hour: "9",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "www.flipopular.com",
                pushsite: "www.flipopular.com",
                pushdate: time_start,
                hour: "21",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
            } else if (element == "AC") {
              this.list.push({
                index: index++,
                site: "www.alphacute.com",
                pushsite: "www.alphacute.com",
                pushdate: time_start,
                hour: "3",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "UK",
              });
              this.list.push({
                index: index++,
                site: "www.alphacute.com",
                pushsite: "www.alphacute.com",
                pushdate: time_start,
                hour: "14",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "UK",
              });
              this.list.push({
                index: index++,
                site: "www.alphacute.com",
                pushsite: "www.alphacute.com",
                pushdate: time_start,
                hour: "21",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "UK",
              });
              this.list.push({
                index: index++,
                site: "www.alphacute.com",
                pushsite: "www.alphacute.com",
                pushdate: time_start,
                hour: "1",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "US",
              });
              this.list.push({
                index: index++,
                site: "www.alphacute.com",
                pushsite: "www.alphacute.com",
                pushdate: time_start,
                hour: "8",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "US",
              });
              this.list.push({
                index: index++,
                site: "www.alphacute.com",
                pushsite: "www.alphacute.com",
                pushdate: time_start,
                hour: "19",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "US",
              });
            } else if (element == "LF101") {
              this.list.push({
                index: index++,
                site: "www.livefit101.com",
                pushsite: "www.livefit101.com",
                pushdate: time_start,
                hour: "0",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "www.livefit101.com",
                pushsite: "www.livefit101.com",
                pushdate: time_start,
                hour: "9",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "www.livefit101.com",
                pushsite: "www.livefit101.com",
                pushdate: time_start,
                hour: "21",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
            } else if (element == "BA") {
              this.list.push({
                index: index++,
                site: "www.buzzaura.com",
                pushsite: "www.buzzaura.com",
                pushdate: time_start,
                hour: "3",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "UK",
              });
              this.list.push({
                index: index++,
                site: "www.buzzaura.com",
                pushsite: "www.buzzaura.com",
                pushdate: time_start,
                hour: "14",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "UK",
              });
              this.list.push({
                index: index++,
                site: "www.buzzaura.com",
                pushsite: "www.buzzaura.com",
                pushdate: time_start,
                hour: "19",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "UK",
              });
              this.list.push({
                index: index++,
                site: "www.buzzaura.com",
                pushsite: "www.buzzaura.com",
                pushdate: time_start,
                hour: "1",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "US",
              });
              this.list.push({
                index: index++,
                site: "www.buzzaura.com",
                pushsite: "www.buzzaura.com",
                pushdate: time_start,
                hour: "7",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "US",
              });
              this.list.push({
                index: index++,
                site: "www.buzzaura.com",
                pushsite: "www.buzzaura.com",
                pushdate: time_start,
                hour: "22",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "US",
              });
            } else if (element == "BF") {
              this.list.push({
                index: index++,
                site: "www.buzzfond.com",
                pushsite: "www.buzzfond.com",
                pushdate: time_start,
                hour: "0",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "UK",
              });
              this.list.push({
                index: index++,
                site: "www.buzzfond.com",
                pushsite: "www.buzzfond.com",
                pushdate: time_start,
                hour: "14",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "UK",
              });
              this.list.push({
                index: index++,
                site: "www.buzzfond.com",
                pushsite: "www.buzzfond.com",
                pushdate: time_start,
                hour: "19",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "UK",
              });
              this.list.push({
                index: index++,
                site: "www.buzzfond.com",
                pushsite: "www.buzzfond.com",
                pushdate: time_start,
                hour: "0",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "US",
              });
              this.list.push({
                index: index++,
                site: "www.buzzfond.com",
                pushsite: "www.buzzfond.com",
                pushdate: time_start,
                hour: "8",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "US",
              });
              this.list.push({
                index: index++,
                site: "www.buzzfond.com",
                pushsite: "www.buzzfond.com",
                pushdate: time_start,
                hour: "20",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "US",
              });
            } else if (element == "BS") {
              this.list.push({
                index: index++,
                site: "www.buzzsuper.com",
                pushsite: "www.buzzsuper.com",
                pushdate: time_start,
                hour: "0",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "www.buzzsuper.com",
                pushsite: "www.buzzsuper.com",
                pushdate: time_start,
                hour: "9",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "www.buzzsuper.com",
                pushsite: "www.buzzsuper.com",
                pushdate: time_start,
                hour: "21",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
            } else if (element == "FT") {
              this.list.push({
                index: index++,
                site: "www.fittors.com",
                pushsite: "www.fittors.com",
                pushdate: time_start,
                hour: "0",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "www.fittors.com",
                pushsite: "www.fittors.com",
                pushdate: time_start,
                hour: "9",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "www.fittors.com",
                pushsite: "www.fittors.com",
                pushdate: time_start,
                hour: "21",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
            } else if (element == "TDI") {
              this.list.push({
                index: index++,
                site: "www.thedecorideas.com",
                pushsite: "www.thedecorideas.com",
                pushdate: time_start,
                hour: "0",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "www.thedecorideas.com",
                pushsite: "www.thedecorideas.com",
                pushdate: time_start,
                hour: "9",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "www.thedecorideas.com",
                pushsite: "www.thedecorideas.com",
                pushdate: time_start,
                hour: "21",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
            } else if (element == "HS") {
              this.list.push({
                index: index++,
                site: "housing.livefit101.com",
                pushsite: "housing.livefit101.com",
                pushdate: time_start,
                hour: "0",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "housing.livefit101.com",
                pushsite: "housing.livefit101.com",
                pushdate: time_start,
                hour: "9",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "housing.livefit101.com",
                pushsite: "housing.livefit101.com",
                pushdate: time_start,
                hour: "21",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
            } else if (element == "GAME_WWW") {
              this.list.push({
                index: index++,
                site: "www.gamejoystick.com",
                pushsite: "www.gamejoystick.com",
                pushdate: time_start,
                hour: "0",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "www.gamejoystick.com",
                pushsite: "www.gamejoystick.com",
                pushdate: time_start,
                hour: "9",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "www.gamejoystick.com",
                pushsite: "www.gamejoystick.com",
                pushdate: time_start,
                hour: "21",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
            } else if (element == "GAME") {
              this.list.push({
                index: index++,
                site: "en.gamejoystick.com",
                pushsite: "en.gamejoystick.com",
                pushdate: time_start,
                hour: "0",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "en.gamejoystick.com",
                pushsite: "en.gamejoystick.com",
                pushdate: time_start,
                hour: "21",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
            } else if (element == "GAME_JA") {
              this.list.push({
                index: index++,
                site: "ja.gamejoystick.com",
                pushsite: "ja.gamejoystick.com",
                pushdate: time_start,
                hour: "12",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
              this.list.push({
                index: index++,
                site: "ja.gamejoystick.com",
                pushsite: "ja.gamejoystick.com",
                pushdate: time_start,
                hour: "21",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
            } else if (element == "UDS") {
              this.list.push({
                index: index++,
                site: "www.usdentalservice.com",
                pushsite: "www.usdentalservice.com",
                pushdate: time_start,
                hour: "8",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
            } else if (element == "Legal") {
              this.list.push({
                index: index++,
                site: "www.lawinfopedia.com",
                pushsite: "www.lawinfopedia.com",
                pushdate: time_start,
                hour: "8",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
            } else if (element == "TE") {
              this.list.push({
                index: index++,
                site: "www.trackingex.com",
                pushsite: "www.trackingex.com",
                pushdate: time_start,
                hour: "8",
                url: "",
                titles: "",
                message: "",
                icon: "",
                badge: "",
                image: "",
                mod: "0",
                country: "Global",
              });
            }
          });

          //增加一天时间戳后的日期
          var next_date = stime_ts + 24 * 60 * 60 * 1000;
          //拼接年月日，这里的月份会返回（0-11），所以要+1
          var next_dates_y = new Date(next_date).getFullYear() + "-";
          var next_dates_m =
            new Date(next_date).getMonth() + 1 < 10
              ? "0" + (new Date(next_date).getMonth() + 1) + "-"
              : new Date(next_date).getMonth() + 1 + "-";
          var next_dates_d =
            new Date(next_date).getDate() < 10
              ? "0" + new Date(next_date).getDate()
              : new Date(next_date).getDate();
          time_start = next_dates_y + next_dates_m + next_dates_d;
          //增加数组key
          i++;
        }
        this.daysum = i;
        this.listcount = this.list.length;
        this.loading = true;

        // 获取历史推送
        this.$post(api.tools.pushGetHistory, {
          site: this.site,
          token: this.userInfo.token,
        }).then((res) => {
          this.loading = false;
          if (res.data.status === 200) {
            // this.$message.success(res.data.message)
            this.historyList = res.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        });
      }
    },
    getMod(site, index, url) {
      this.loading = true;
      this.$post(api.aff.getAffTotalPerf, {
        user: this.username,
        site: site,
        url: url,
        account: this.account,
        timestamp: Date.parse(new Date()),
        token: this.userInfo.token,
      }).then((res) => {
        this.loading = false;
        if (res.data.code === 200) {
          this.$message.success(res.data.message);
          this.list[index - 1].titles = res.data.list.data.title;
          this.list[index - 1].message = res.data.list.data.message;
          this.list[index - 1].icon = res.data.list.data.icon;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    // 预览
    goPreview() {
      if (!this.url) {
        this.$message.error("url为空");
      } else if (!this.title) {
        this.$message.error("title为空");
      } else if (!this.message) {
        this.$message.error("message为空");
      } else if (!this.icon) {
        this.$message.error("icon为空");
      } else {
        this.action.push("1");
        // console.log(this.list[0].pushsite)
        this.pushsite = this.list[0].pushsite;
        this.showModal();
      }
    },
    // goPreview1(icon,url,index,title,message,pushsite,badge,image){
    //   if(!url){
    //     this.$message.error('url为空')
    //   }else if(!title){
    //     this.$message.error('title为空')
    //   }else if(!message){
    //     this.$message.error('message为空')
    //   }else if(!icon){
    //     this.$message.error('icon为空')
    //   }else{
    //     this.action.push(index)
    //     this.icon = icon
    //     this.url = url
    //     this.title = title
    //     this.message = message
    //     this.pushsite = pushsite
    //     this.badge = badge
    //     this.image = image
    //   }
    //   this.showModal()
    // },
    checkShowModal(tag = 0) {
      console.log(tag);
      this.index = "";
      this.count = this.selectedRows.length;
      for (var i = 0; i < this.count; i++) {
        this.index += this.selectedRows[i].index + ",";
      }
      if (this.action.length == 0) {
        this.action_str = "所有记录均没有预览";
      } else {
        this.action_str = "已全部预览";
        this.preview_status = true;
        this.selectedRows.forEach((item) => {
          item.url = this.url;
          item.titles = this.title;
          item.message = this.message;
          item.icon = this.icon;
        });
      }
      if (tag == 0) {
        this.visible = true;
      } else {
        this.visible_immediate = true;
      }
      // if(this.action.length == 0){
      //   this.action_str = '所有记录均没有预览'
      // }else{
      //   var n = [];
      //   var quchong = function (event) {
      //       for (var i = 0; i < event.length; i++) {
      //           if (n.indexOf(event[i]) == -1) n.push(event[i]);
      //       }
      //   };
      //   quchong(this.action);
      //   n.sort();
      //   this.action = n
      //
      //   var subSet = function (arr1, arr2) {
      //     var set2 = new Set(arr2);
      //     var subset = [];
      //     arr1.forEach(function(val) {
      //       if (!set2.has(val)) {
      //           subset.push(val);
      //       }
      //     });
      //     return subset;
      //   };
      //   var noaction = subSet(this.selectedRowKeys, this.action)
      //   if(noaction.length != 0){
      //     this.action_str = '序号 '+noaction.toString()+' 未预览'
      //   }else{
      //     this.action_str = '已全部预览'
      //     this.preview_status = true
      //   }
      // }
    },
    addPushapps() {
      this.loading = true;
      this.visible = false;
      this.$post(api.tools.pushAdd, {
        site: this.site,
        list: this.selectedRows,
        token: this.userInfo.token,
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.$message.success(res.data.message);
          this.list = "";
          this.daysum = 0;
          this.icon = 0;
          this.url = "";
          this.selectedRows = [];
          this.selectedRowKeys = [];
          this.action = [];
          this.action_str = "";
          this.preview_status = false;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    addPushImmediate() {
      this.loading = true;
      this.visible_immediate = false;
      this.$post(api.tools.pushAddImmediate, {
        site: this.site,
        list: this.selectedRows,
        token: this.userInfo.token,
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.$message.success(res.data.message);
          this.list = "";
          this.daysum = 0;
          this.icon = 0;
          this.url = "";
          this.selectedRows = [];
          this.selectedRowKeys = [];
          this.action = [];
          this.action_str = "";
          this.preview_status = false;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
  },
};
</script>
<style>
.getapk {
  margin: 24px;
  flex: 1;
}
.apkbtn {
  margin-top: 16px;
  margin-bottom: 16px;
}
.ant-table {
  background-color: #fff;
}
.bold {
  font-weight: bold;
}
.sumpage {
  font-size: 20px;
  margin-top: 16px;
}
.langcheck {
  border-bottom: 1px solid #e9e9e9;
  margin-top: 30px;
}
.overtext {
  margin-left: 20px;
}
.preview .ant-modal-content {
  width: 1550px;
  position: absolute;
  left: -400px;
}
.preview .ant-row {
  margin-bottom: 16px;
}
.pushInput {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.pushInputItem {
  display: flex;
  flex-direction: column;
}
.pushInputItem span {
  text-align: center;
  margin-bottom: 5px;
}
</style>
