<template>
  <div>
    <div class="ant-page-header-tl">
      <span style="margin-right:5px">Site:</span>
      <a-select defaultValue="AC" style="width: 150px" @change="onSiteChange">
        <a-select-option
          v-for="(item, index) in siteList"
          :key="index"
          :value="item"
          >{{ item }}</a-select-option
        >
      </a-select>
      <span style="margin-left:16px;margin-right:5px">Platform:</span>
      <a-select
        defaultValue="All"
        style="width: 150px"
        @change="onPlatformChange"
      >
        <a-select-option
          v-for="(item, index) in platformList"
          :key="index"
          :value="item"
          >{{ item }}</a-select-option
        >
      </a-select>
      <span style="margin-left:16px;margin-right:5px">Date:</span>
      <a-range-picker :default-value="dateString" @change="onDateChange" />
      <a-button
        class="btn-right"
        type="primary"
        style="margin-left:16px;"
        @click="onCommit"
        >Apply</a-button
      >
      <a-button
        class="btn-right"
        type="primary"
        icon="download"
        style="float: right"
        @click="handleExports"
        >Export</a-button
      >
    </div>
    <!-- table -->
    <div class="section">
      <a-table
        :columns="columns"
        :data-source="dataList"
        :pagination="{ pageSize: pageSize }"
        :rowKey="(record) => record.url + record.status"
        size="middle"
        bordered
        :loading="loading"
      >
      </a-table>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
let weekOfDay = parseInt(moment().format("E"));
const defaultStartDate = moment()
  .subtract(weekOfDay + 7, "days")
  .format("YYYY-MM-DD");
const defaultEndDate = moment()
  .subtract(weekOfDay + 1, "days")
  .format("YYYY-MM-DD");

const platformList = ["All", "Taboola", "Outbrain"];
const siteList = ["AC", "BA", "BF", "BS", "FD", "FT", "LF", "TDI"];

const columns = [
  {
    title: "URL",
    dataIndex: "url",
    sorter: (a, b) => {
      return a.url.localeCompare(b.url);
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: (a, b) => {
      return a.url.localeCompare(b.url);
    },
  },
  {
    title: "Session",
    dataIndex: "sessions",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.sessions - b.sessions;
    },
  },
  {
    title: "PV",
    dataIndex: "page_views",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.page_views - b.page_views;
    },
  },
  {
    title: "P/S",
    dataIndex: "p_s",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(1)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.p_s - b.p_s;
    },
  },
  {
    title: "Bounce Rate",
    dataIndex: "bounce_rate",
    align: "right",
    customRender: (text) => {
      return (
        parseFloat(text.toString())
          .toFixed(1)
          .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "%"
      );
    },
    sorter: (a, b) => {
      return a.bounce_rate - b.bounce_rate;
    },
  },
  {
    title: "Ads CPC",
    dataIndex: "ads_cpc",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(3)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.ads_cpc - b.ads_cpc;
    },
  },
  {
    title: "Ads CTR",
    dataIndex: "ads_ctr",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(1)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.ads_ctr - b.ads_ctr;
    },
  },
  {
    title: "Ads Score",
    dataIndex: "ads_score",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(1)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.ads_score - b.ads_score;
    },
  },
  {
    title: "CPC 上限",
    dataIndex: "cpc_top",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(3)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.cpc_top - b.cpc_top;
    },
  },
  {
    title: "Spent",
    dataIndex: "spent",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(1)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.spent - b.spent;
    },
  },
  {
    title: "系数",
    dataIndex: "x_num",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(1)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.x_num - b.x_num;
    },
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(1)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.z_num - b.z_num;
    },
  },
];

export default {
  data() {
    return {
      userInfo: {},
      columns,
      platform: "All",
      platformList,
      site: "AC",
      pageSize: 100,
      siteList,
      dataList: [],
      loading: false,
      defaultStartDate,
      defaultEndDate,
      dateString: [
        moment(defaultStartDate, "YYYY-MM-DD"),
        moment(defaultEndDate, "YYYY-MM-DD"),
      ],
    };
  },
  // 定义方法
  methods: {
    // 修改时间
    onDateChange(dateString) {
      this.dateString = dateString;
      // this.getList();
    },

    // 修改平台
    onPlatformChange(e) {
      this.platform = e;
      // this.getList();
    },

    // 修改site
    onSiteChange(e) {
      this.site = e;
      // this.getList();
    },

    onCommit() {
      this.getList();
    },

    // 导出
    handleExports() {
      const { columns, dataList } = this;
      this.loading = true;
      let newlist = [];
      require.ensure([], () => {
        const { export_json_to_excel } = require("../../excel/Export2Excel"); //---require 括号里面是相对路径其实是引用  Export2Excel.js
        const tHeader = []; //----tHeader 数组里面放的是字段的对应名
        const filterVal = []; //-----filterVal  字段对应的值
        columns &&
          columns.map((data) => {
            tHeader.push(data.title);
            filterVal.push(data.dataIndex);
          });
        this.title = "分流表现 " + moment().locale('zh-cn').format('YYYY_MM_DD_HH_mm_ss');
        newlist = dataList;

        // const list = keyData;//----对应的json数组
        const data = this.formatJson(filterVal, newlist);
        export_json_to_excel(tHeader, data, this.title);
        this.loading = false;
        // this.keyvisible = false;
        this.$message.success("导出成功");
      });
    },

    // 格式化json
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    // 获取数据列表
    getList() {
      this.loading = true;
      this.$post(api.okr.splitFlowPerf, {
        token: this.userInfo.token,
        site: this.site,
        platform: this.platform,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataList = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
  // 页面初始化
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.getList();
  },
};
</script>
