<template>
  <div class="performancepage">
    <div class="ant-page-header-tl">
      <a-select
        defaultValue="outbrain"
        style="width: 120px; margin-right: 16px;"
        @change="handlePlatformChange"
      >
        <a-select-option
          v-for="(item, index) in platformList"
          :key="index"
          :value="item.name"
          >{{ item.name }}</a-select-option
        >
      </a-select>
      <a-button @click="showModal" style="margin-right: 16px;">
        Columns
      </a-button>
      <a-button
        class="btn-right"
        type="primary"
        icon="download"
        style="margin-right: 16px;"
        @click="handleExports"
        >Export</a-button
      >
      <span style="margin-right: 8px;">Show Archived</span>
      <a-switch @change="onOpenArchived" />

      <div class="righttop">
        <!-- Compare  -->
        <a-switch style="margin-right: 16px;" @change="ctrlCompare" />
        <a-select
          v-if="curPeriod != 'no Compare'"
          v-model="curPeriod"
          style="width: 130px; margin-right: 16px;"
          @change="handlePeriodChange"
        >
          <a-select-option
            v-for="(item, index) in periodList"
            :key="index"
            :value="item.name"
            >{{ item.name }}</a-select-option
          >
        </a-select>
        <!-- <a-range-picker v-if="curPeriod == 'no Compare' && period1.length" :default-value="[moment(period1[0], 'YYYY-MM-DD'), moment(period1[1], 'YYYY-MM-DD')]" @change="handleNoCompareStartChange" /> -->
        <a-range-picker
          v-if="curPeriod == 'no Compare'"
          :default-value="[
            moment()
              .subtract(8, 'day')
              .format('YYYY-MM-DD'),
            moment()
              .subtract(2, 'day')
              .format('YYYY-MM-DD'),
          ]"
          @change="handleNoCompareStartChange"
        />

        <a-range-picker
          v-if="curPeriod == 'Previous period' && period2.length"
          :open="rangeopen"
          :default-value="[
            moment(period2[0], 'YYYY-MM-DD'),
            moment(period2[1], 'YYYY-MM-DD'),
          ]"
          @change="handleNewDataChange"
        >
          <template slot="dateRender" slot-scope="current, today">
            <div
              class="ant-calendar-date"
              :style="getCurrentStyle(current, today)"
            >
              {{ current.date() }}
            </div>
          </template>
          <template slot="renderExtraFooter">
            <div
              style="padding: 8px 0;width: 100%;position: relative;width: 523px;"
            >
              <!-- Compare -->
              <span class="period" @click="previousPeriod"
                >Previous period</span
              >
              <!-- <span class="period">Custom</span> -->
              <div style="position: absolute;right:0;top:12px;">
                <a-button style="margin-right: 16px;" @click="cancelRangePicker"
                  >Cancel</a-button
                >
                <a-button type="primary" @click="submitRangePicker"
                  >Apply</a-button
                >
              </div>
            </div>
          </template>
        </a-range-picker>
        <a-range-picker
          v-if="curPeriod == 'Custom' && period1.length"
          :default-value="[
            moment(period1[0], 'YYYY-MM-DD'),
            moment(period1[1], 'YYYY-MM-DD'),
          ]"
          @change="handleCustomStartChange"
        />
        <a-range-picker
          style="margin-left: 16px;"
          v-if="curPeriod == 'Custom' && period2.length"
          @change="handleCustomEndChange"
        />
        <a-button
          v-if="curPeriod == 'Previous period'"
          type="primary"
          style="margin-left: 16px;"
          @click="rangePickerSelect"
          >选择日期</a-button
        >
        <a-button
          v-else
          type="primary"
          style="margin-left: 16px;"
          @click="getList"
          >Apply</a-button
        >
      </div>
    </div>
    <div style="margin: 16px 24px;font-weight: bold;position: relative;">
      <span
        v-if="
          period2.length && period1[0] != period2[0] && period1[1] != period2[1]
        "
        >{{ period2[0] }}~{{ period2[1] }} Campare to </span
      >{{ period1[0] }}~{{ period1[1] }}
      <span style="margin-left: 32px;" v-if="campaign_ids.length"
        ><b>{{ campaign_ids.length }}</b> Selected</span
      >
      <a-button
        v-if="ruleList.length"
        @click="showFilterModal"
        type="danger"
        style="position: absolute; right: 0;top: -5px;margin-left: 16px; margin-bottom: 16px;float:right;"
        >Save This Filter</a-button
      >
    </div>
    <div class="section" style="margin-top: 16px;">
      <div
        class="filter"
        style="display: flex;align-items: center;flex-wrap: wrap;margin-bottom: 0;"
      >
        <!-- <span style="margin-right: 16px;color: #ccc;">|</span> -->
        <a-button
          @click="closeRule(item.name)"
          style="margin-right: 16px;margin-bottom: 16px;"
          type="primary"
          v-for="(item, index) in ruleList"
          :key="index"
        >
          {{ item.curName }} {{ item.curNeed }} {{ item.rule
          }}<a-icon type="close" />
        </a-button>
        <a-button
          style="margin-bottom: 16px;"
          v-if="!ruleVisible"
          @click="showRuleModal"
          type="primary"
        >
          <a-icon type="plus" />
          Filter
        </a-button>
        <div
          v-if="ruleVisible"
          style="display: flex;flex:1;margin-bottom: 16px;"
        >
          <a-select
            :value="defaultvalue"
            style="min-width: 160px;"
            @change="handleRuleChange"
          >
            <a-select-option
              :disabled="item.disabled"
              v-for="(item, index) in columns"
              :key="index"
              :value="item.dataIndex"
              >{{ item.title }}</a-select-option
            >
          </a-select>
          <a-select
            defaultValue="Condition"
            style="min-width: 160px;margin: 0 16px;"
            @change="handleFilterChange"
          >
            <a-select-option
              v-for="(item, index) in filterList"
              :key="index"
              :value="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
          <a-input
            style="min-width: 200px;margin-right: 16px;"
            v-model="rule"
            placeholder=""
          />
          <a-button
            @click="changeRuleColumn(curColumn, curName, curNeed, rule, 1)"
            type="primary"
            >确定</a-button
          >

          <!-- name: this.curColumn,
          curName: this.curName,
          curNeed: this.curNeed,
          rule: this.rule -->
        </div>
        <div
          v-if="saveFilterList.length"
          style="margin-bottom: 16px;margin-left: 16px;"
        >
          <!-- <span style="margin: 0 16px;color: #ccc;">|</span> -->
          <a-select
            v-model="selectFilterAction"
            style="width: 160px;"
            @change="handleFilterActionChange"
          >
            <a-select-option
              v-for="(item, index) in saveFilterList"
              :key="index"
              :value="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </div>
        <div style="margin-bottom: 16px;">
          <span style="margin: 0 16px;color: #ccc;">|</span>
          <a-select
            v-model="selectAction"
            style="width: 160px;"
            @change="handleActionChange"
          >
            <a-select-option
              v-for="(item, index) in campaignAction"
              :key="index"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </div>
        <!-- v-if="campaign_ids.length" -->

        <div style="margin-bottom: 16px;">
          <span style="margin-left: 16px;color: #ccc;">|</span>

          <a-popover
            :title="poptitle"
            placement="bottom"
            trigger="click"
            :visible="popclicked"
            @visibleChange="handleClickChange"
          >
            <template v-if="labelList.length && !isNewLabel" slot="content">
              <a-input-search
                placeholder="Search"
                v-model="labelsearchname"
                style="width: 300px;display: block;margin-bottom: 8px;"
                @search="onLabelSearch"
              />
              <div
                class="labelitem"
                v-for="(item, index) in searchLabel(labelsearchname)"
                :key="index"
                @click="chageLabelCheckbox(item)"
              >
                <span :class="['checkbox', item.type]"></span>
                {{ item.name }}
              </div>
              <a-empty v-if="!searchLabel(labelsearchname).length" />
              <div class="labelwp">
                <div class="labelwp-item" @click="createNewLabel">
                  NEW LABEL
                </div>
                <div class="labelwp-item" @click="popHide">CANCEL</div>
                <div
                  class="labelwp-item"
                  @click="labelApply"
                  v-if="searchLabel(labelsearchname).length"
                >
                  APPLY
                </div>
              </div>
            </template>
            <template v-else slot="content">
              <a-row>
                <a-col :span="4"
                  ><div style="line-height: 32px;">Name</div></a-col
                >
                <a-col :span="20"><a-input v-model="labelname"/></a-col>
              </a-row>
              <div class="labelwp" style="width: 300px;">
                <div class="labelwp-item" @click="popHide">CANCEL</div>
                <div class="labelwp-item" @click="popCreate">CREATE</div>
              </div>
            </template>
            <a-button style="margin-left: 16px;">
              Label
            </a-button>
          </a-popover>
        </div>
      </div>
      <a-table
        @change="handleTableChange"
        :scroll="{ x: 2675, y: 800 }"
        :columns="columns"
        :data-source="dataList"
        :rowKey="(record) => record.campaign_id"
        size="small"
        :loading="loading"
        bordered
        :rowSelection="{
          selectedRowKeys: campaign_ids,
          onChange: onSelectChange,
          onSelect: onSelectPer,
        }"
        :pagination="{ pageSize: pageSize }"
      >
        <div
          slot="campaign_id"
          slot-scope="text"
          style="width: 151px;overflow: hidden;"
        >
          {{ text }}
        </div>
        <div
          slot="campaign_name"
          slot-scope="text, record"
          style="width: 220px;overflow:hidden;text-decoration: underline;cursor: pointer;"
          @click="toLine(record.campaign_id)"
        >
          <a-popover title="">
            <template slot="content">
              {{ text }}
            </template>
            {{ text }}
          </a-popover>
        </div>
        <div slot="status" slot-scope="text">
          <span v-if="text == 'RUNNING'" class="runing"></span>{{ text }}
        </div>
        <div slot="ads_ctr" slot-scope="text, record">
          {{ text }}%
          <span
            v-if="record.ads_ctr_percent || record.ads_ctr_percent == 0"
            class="percent"
            >({{ record.ads_ctr_percent }}%)</span
          >
        </div>
        <div slot="ads_cpc" slot-scope="text, record">
          {{ text }}
          <span
            v-if="record.ads_cpc_percent || record.ads_cpc_percent == 0"
            class="percent"
            >({{ record.ads_cpc_percent }}%)</span
          >
        </div>
        <div slot="ctr" slot-scope="text, record">
          {{ text }}%
          <span
            v-if="record.ctr_percent || record.ctr_percent == 0"
            class="percent"
            >({{ record.ctr_percent }}%)</span
          >
        </div>
        <div slot="clicks" slot-scope="text, record">
          {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
          <span
            v-if="record.clicks_percent || record.clicks_percent == 0"
            class="percent"
            >({{ record.clicks_percent }}%)</span
          >
        </div>
        <div slot="avg_cpc" slot-scope="text, record">
          {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
          <span
            v-if="record.avg_cpc_percent || record.avg_cpc_percent == 0"
            class="percent"
            >({{ record.avg_cpc_percent }}%)</span
          >
        </div>
        <div slot="spent" slot-scope="text, record">
          {{
            Math.round(text)
              .toString()
              .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
          }}
          <span
            v-if="record.spent_percent || record.spent_percent == 0"
            class="percent"
            >({{ record.spent_percent }}%)</span
          >
        </div>
        <div slot="sessions" slot-scope="text, record">
          {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
          <span
            v-if="record.sessions_percent || record.sessions_percent == 0"
            class="percent"
            >({{ record.sessions_percent }}%)</span
          >
        </div>
        <div slot="new_user" slot-scope="text, record">
          {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
          <span
            v-if="record.new_user_percent || record.new_user_percent == 0"
            class="percent"
            >({{ record.new_user_percent }}%)</span
          >
        </div>
        <div slot="page_views" slot-scope="text, record">
          {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
          <span
            v-if="record.page_views_percent || record.page_views_percent == 0"
            class="percent"
            >({{ record.page_views_percent }}%)</span
          >
        </div>
        <div slot="pages_per_session" slot-scope="text, record">
          {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
          <span
            v-if="
              record.pages_per_session_percent ||
                record.pages_per_session_percent == 0
            "
            class="percent"
            >({{ record.pages_per_session_percent }}%)</span
          >
        </div>
        <div slot="cpc_top" slot-scope="text, record">
          {{ text }}
          <span
            v-if="record.cpc_top_percent || record.cpc_top_percent == 0"
            class="percent"
            >({{ record.cpc_top_percent }}%)</span
          >
        </div>
        <div slot="x_num" slot-scope="text, record">
          {{ text }}
          <span
            v-if="record.x_num_percent || record.x_num_percent == 0"
            class="percent"
            >({{ record.x_num_percent }}%)</span
          >
        </div>
        <div slot="z_num" slot-scope="text, record">
          <!-- {{text}} -->
          {{
            Math.round(text)
              .toString()
              .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
          }}
          <span
            v-if="record.z_num_percent || record.z_num_percent == 0"
            class="percent"
            >({{ record.z_num_percent }}%)</span
          >
        </div>
        <a
          slot="action"
          slot-scope="text, record"
          @click="showDrawer(record.platform, record.campaign_id)"
          >Setting</a
        >
        <div slot="daily_budget" slot-scope="text">
          {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
        </div>
        <div slot="impressions" slot-scope="text">
          {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
        </div>
        <div slot="bounce_rate" slot-scope="text">{{ text }}%</div>
        <div slot="conversion_rate" slot-scope="text">{{ text }}%</div>
        <div slot="total_conversions" slot-scope="text">
          {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
        </div>
        <div slot="view_conversions" slot-scope="text">
          {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
        </div>
        <div slot="view_conversion_rate" slot-scope="text">{{ text }}%</div>
        <div slot="visible_impressions" slot-scope="text">
          {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
        </div>
      </a-table>
      <!-- 总和 -->
      <div class="sumwrapper" v-if="isShowTotal">
        <a-table
          @change="handleTableChange"
          :columns="columnsSum"
          :data-source="dataListSum"
          :rowKey="(record) => record.id"
          size="small"
          bordered
          :pagination="false"
        >
          <div slot="ads_ctr" slot-scope="text, record">
            {{ text }}%
            <span
              v-if="record.ads_ctr_percent || record.ads_ctr_percent == 0"
              class="percent"
              >({{ record.ads_ctr_percent }}%)</span
            >
          </div>
          <div slot="ads_cpc" slot-scope="text, record">
            {{ text }}
            <span
              v-if="record.ads_cpc_percent || record.ads_cpc_percent == 0"
              class="percent"
              >({{ record.ads_cpc_percent }}%)</span
            >
          </div>
          <div slot="ctr" slot-scope="text, record">
            {{ text }}%
            <span
              v-if="record.ctr_percent || record.ctr_percent == 0"
              class="percent"
              >({{ record.ctr_percent }}%)</span
            >
          </div>
          <div slot="clicks" slot-scope="text, record">
            {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
            <span
              v-if="record.clicks_percent || record.clicks_percent == 0"
              class="percent"
              >({{ record.clicks_percent }}%)</span
            >
          </div>
          <div slot="avg_cpc" slot-scope="text, record">
            {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
            <span
              v-if="record.avg_cpc_percent || record.avg_cpc_percent == 0"
              class="percent"
              >({{ record.avg_cpc_percent }}%)</span
            >
          </div>
          <div slot="spent" slot-scope="text, record">
            {{
              Math.round(text)
                .toString()
                .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
            }}
            <span
              v-if="record.spent_percent || record.spent_percent == 0"
              class="percent"
              >({{ record.spent_percent }}%)</span
            >
          </div>
          <div slot="sessions" slot-scope="text, record">
            {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
            <span
              v-if="record.sessions_percent || record.sessions_percent == 0"
              class="percent"
              >({{ record.sessions_percent }}%)</span
            >
          </div>
          <div slot="new_user" slot-scope="text, record">
            {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
            <span
              v-if="record.new_user_percent || record.new_user_percent == 0"
              class="percent"
              >({{ record.new_user_percent }}%)</span
            >
          </div>
          <div slot="page_views" slot-scope="text, record">
            {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
            <span
              v-if="record.page_views_percent || record.page_views_percent == 0"
              class="percent"
              >({{ record.page_views_percent }}%)</span
            >
          </div>
          <div slot="pages_per_session" slot-scope="text, record">
            {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
            <span
              v-if="
                record.pages_per_session_percent ||
                  record.pages_per_session_percent == 0
              "
              class="percent"
              >({{ record.pages_per_session_percent }}%)</span
            >
          </div>
          <div slot="cpc_top" slot-scope="text, record">
            {{ text }}
            <span
              v-if="record.cpc_top_percent || record.cpc_top_percent == 0"
              class="percent"
              >({{ record.cpc_top_percent }}%)</span
            >
          </div>
          <div slot="x_num" slot-scope="text, record">
            {{ text }}
            <span
              v-if="record.x_num_percent || record.x_num_percent == 0"
              class="percent"
              >({{ record.x_num_percent }}%)</span
            >
          </div>
          <div slot="z_num" slot-scope="text, record">
            <!-- {{text}} -->
            {{
              Math.round(text)
                .toString()
                .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
            }}
            <span
              v-if="record.z_num_percent || record.z_num_percent == 0"
              class="percent"
              >({{ record.z_num_percent }}%)</span
            >
          </div>

          <div slot="impressions" slot-scope="text">
            {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
          </div>
          <div slot="bounce_rate" slot-scope="text">{{ text }}%</div>
          <div slot="conversion_rate" slot-scope="text">{{ text }}%</div>
          <div slot="total_conversions" slot-scope="text">
            {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
          </div>
          <div slot="view_conversions" slot-scope="text">
            {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
          </div>
          <div slot="view_conversion_rate" slot-scope="text">{{ text }}%</div>
          <div slot="visible_impressions" slot-scope="text">
            {{ text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") }}
          </div>
        </a-table>
      </div>
      <div
        :style="{
          width: '130px',
          position: 'absolute',
          bottom: isShowTotal ? '98px' : '32px',
          left: '16px',
        }"
      >
        Show rows:
        <a-select size="small" v-model="pageSize">
          <a-select-option
            v-for="(item, index) in ablePageList"
            :key="index"
            :value="item.size"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </div>
    </div>

    <!-- 保留当前筛选条件 -->
    <a-modal
      title="保留当前筛选条件"
      :visible="saveFilterVisible"
      @cancel="handleFilterCancel"
      @ok="changeFilterSave"
    >
      <a-row>
        <a-col
          :span="5"
          style="text-align: right; padding-right: 16px;line-height: 32px;"
          >name</a-col
        >
        <a-col :span="16"><a-input v-model="saveFilterName"/></a-col>
      </a-row>
    </a-modal>

    <!-- 自定义列 -->
    <a-modal
      title="Colums"
      :visible="visible"
      @cancel="handleCancel"
      width="660px"
      @ok="changeColumn"
    >
      <h4>Base info</h4>
      <a-row>
        <a-col :span="8" v-for="(item, index) in colBaseInfoList" :key="index">
          <a-checkbox
            :checked="item.checked"
            :value="item.name"
            name="base_info"
            @click="checkboxChange(item.name, 1)"
            >{{ item.show_name }}</a-checkbox
          >
        </a-col>
      </a-row>
      <h4 style="margin-top: 16px;">Performance</h4>
      <a-row>
        <a-col :span="8" v-for="(item, index) in colPerfList" :key="index">
          <a-checkbox
            :checked="item.checked"
            :value="item.name"
            name="perf"
            @click="checkboxChange(item.name, 2)"
            >{{ item.show_name }}</a-checkbox
          >
        </a-col>
      </a-row>
    </a-modal>

    <!-- Change Setting -->
    <a-drawer
      width="600px"
      title="Change Setting"
      placement="right"
      :closable="false"
      :visible="drawervisible"
    >
      <h1 class="title">Campaign Setup</h1>
      <div class="form-tl"><b>Campaign Name</b></div>
      <a-input
        placeholder="Enter Campaign Name"
        style="width: 100%"
        v-model="campaign_name"
      />

      <div v-if="brand_text">
        <div class="form-tl"><b>Brand Name</b></div>
        <a-input
          placeholder="Enter Brand Name"
          style="width: 220px"
          v-model="brand_text"
        />
      </div>

      <!-- objective -->
      <div v-if="curPlatform == 'TBL'">
        <div class="form-tl"><b>Campaign Objective</b></div>
        <a-radio-group v-model="tbl_objective">
          <a-radio :value="'DRIVE_WEBSITE_TRAFFIC'">
            WEBSITE ENGAGEMENT
          </a-radio>
          <a-radio :value="'LEADS_GENERATION'">
            LEAD GENERATION
          </a-radio>
        </a-radio-group>
      </div>
      <div v-if="curPlatform == 'OB'">
        <div class="form-tl"><b>Campaign Objective</b></div>
        <a-radio-group
          v-model="ob_objective"
          @change="onCampaignObjectiveChange"
        >
          <a-radio :value="'Traffic'">
            Traffic
          </a-radio>
          <a-radio :value="'Conversions'">
            Conversion
          </a-radio>
        </a-radio-group>
      </div>
      <h1 class="title">Schedule</h1>
      <div class="form-tl"><b>Time Frame</b></div>
      <div>Start Date: {{ start_date }}</div>
      <div>
        End Date:
        <a-date-picker v-model="end_date" @change="onPickerChange" placeholder="Select Date" />
      </div>

      <div v-if="curPlatform == 'TBL'">
        <div class="form-tl"><b>Start Hour</b></div>
        <a-radio-group v-model="start_hour.mode" @change="onStartHourChange">
          <a-radio :value="'ALWAYS'" :style="radioStyle">
            24/7
          </a-radio>
          <a-radio :value="'CUSTOM'" :style="radioStyle">
            Set Specific Hour
          </a-radio>
        </a-radio-group>
        <div v-if="start_hour.mode == 'CUSTOM'">
          <a-row style="margin-bottom: 8px;margin-top: 8px;">
            <a-col :span="6">
              <span class="subtl">Day</span>
            </a-col>
            <a-col :span="6">
              <span class="subtl">Starting Time</span>
            </a-col>
            <a-col :span="6" :offset="1">
              <span class="subtl">Ending Time</span>
            </a-col>
          </a-row>
          <a-row
            class="hour-line"
            v-for="(item, index) in start_hour.rules"
            :key="index"
          >
            <a-col :span="6"
              ><a-checkbox
                :checked="item.type == 'INCLUDE'"
                :value="item.day"
                name="hour"
                @click="startTimeChange(index)"
                ><span class="hourtl">{{ item.day }}</span></a-checkbox
              ></a-col
            >
            <a-col :span="6"
              ><a-time-picker
                style="width: 100%;"
                :value="moment(item.from_hour, 'HH')"
                format="HH A"
                @change="
                  (time, timeString) =>
                    onTimeStartChange(time, timeString, index)
                "
              />
            </a-col>
            <a-col :span="1" style="text-align: center; color: #bfbfbf;"
              >-</a-col
            >
            <a-col :span="6"
              ><a-time-picker
                style="width: 100%;"
                :value="moment(item.until_hour, 'HH')"
                format="HH A"
                @change="
                  (time, timeString) => onTimeEndChange(time, timeString, index)
                "
            /></a-col>
          </a-row>
        </div>
      </div>
      <h1 class="title">Target</h1>
      <!-- 城市 -->
      <div class="form-tl"><b>Location</b></div>
      <div v-if="curPlatform == 'TBL'" class="include">
        <a-select v-model="country['type']" style="width: 120px">
          <a-select-option value="INCLUDE">
            <a-icon type="check-circle" style="color: #52c41a" />
            INCLUDE
          </a-select-option>
          <a-select-option value="EXCLUDE">
            <a-icon type="close-circle" style="color: #ff4d4f" />
            EXCLUDE
          </a-select-option>
        </a-select>
        <span style="color: #bfbfbf">|</span>
        <a-select
          mode="multiple"
          v-model="country['value']"
          style="width: 100%"
          placeholder="Select Country"
          option-label-prop="label"
        >
          <a-select-option
            v-for="(item, index) in fields.country"
            :key="index"
            :value="item.name"
            :label="item.name"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </div>
      <div v-if="curPlatform == 'OB'" class="include">
        <div style="width: 120px;margin-left: 11px;">
          <a-icon type="check-circle" style="color: #52c41a" />
          INCLUDE
        </div>
        <span style="color: #bfbfbf">|</span>
        <a-select
          mode="multiple"
          v-model="country['value']"
          style="width: 100%"
          placeholder="Select Country"
          :filter-option="false"
          :not-found-content="fetching ? undefined : null"
          option-label-prop="label"
          @search="fetchLocation"
          @change="handleOBLocationChange"
          @select="handleOBLocationSelect"
          @deselect="handleOBLocationDeSelect"
        >
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option
            v-for="(item, index) in ob_country"
            :key="index"
            :title="item.name"
            :id="item.id"
            :value="item.name"
            :label="item.name"
            >[{{ item.geoType }}] - {{ item.name }}</a-select-option
          >
        </a-select>
      </div>

      <!-- 设备 -->
      <div class="form-tl"><b>Device</b></div>
      <!-- tab_device -->
      <div class="include">
        <div style="width: 120px;margin-left: 11px;">
          <a-icon type="check-circle" style="color: #52c41a" />
          INCLUDE
        </div>
        <span style="color: #bfbfbf">|</span>
        <a-select
          mode="multiple"
          v-model="device['value']"
          style="width: 100%"
          placeholder="Select Device"
          option-label-prop="label"
        >
          <a-select-option
            v-for="(item, index) in fields.device"
            :key="index"
            :value="item.name"
            :label="item.name"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </div>
      <!-- Operating Systems -->
      <div class="form-tl"><b>Operating System</b></div>
      <div class="include">
        <div v-if="curPlatform == 'OB'" style="width: 120px;margin-left: 11px;">
          <a-icon type="check-circle" style="color: #52c41a" />
          INCLUDE
        </div>
        <a-select
          v-if="curPlatform == 'TBL'"
          v-model="os['type']"
          style="width: 120px"
        >
          <a-select-option value="INCLUDE">
            <a-icon type="check-circle" style="color: #52c41a" />
            INCLUDE
          </a-select-option>
          <a-select-option value="EXCLUDE">
            <a-icon type="close-circle" style="color: #ff4d4f" />
            EXCLUDE
          </a-select-option>
        </a-select>
        <span style="color: #bfbfbf">|</span>
        <a-select
          mode="multiple"
          v-model="os['value']"
          style="width: 100%"
          placeholder="Select Operating System"
          option-label-prop="label"
        >
          <a-select-option
            v-for="(item, index) in fields.os"
            :key="index"
            :value="item.value"
            :label="item.value"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </div>

      <!-- Browser -->
      <div class="form-tl"><b>Browser</b></div>
      <div class="include">
        <div v-if="curPlatform == 'OB'" style="width: 120px;margin-left: 11px;">
          <a-icon type="check-circle" style="color: #52c41a" />
          INCLUDE
        </div>
        <a-select
          v-if="curPlatform == 'TBL'"
          v-model="browsers['type']"
          style="width: 120px"
        >
          <a-select-option value="INCLUDE">
            <a-icon type="check-circle" style="color: #52c41a" />
            INCLUDE
          </a-select-option>
          <a-select-option value="Exclude">
            <a-icon type="close-circle" style="color: #ff4d4f" />
            Exclude
          </a-select-option>
        </a-select>
        <span style="color: #bfbfbf">|</span>
        <a-select
          mode="multiple"
          v-model="browsers['value']"
          style="width: 100%"
          placeholder="Select Browsers"
          option-label-prop="label"
        >
          <a-select-option
            v-for="(item, index) in fields.browsers"
            :key="index"
            :value="item.value"
            :label="item.value"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </div>

      <!-- Audience -->
      <!-- ob_audience -->
      <div class="form-tl"><b>Audience</b></div>
      <!-- 包含 -->
      <div class="include" style="margin-bottom: 16px;">
        <div style="width: 120px;margin-left: 11px;">
          <a-icon type="check-circle" style="color: #52c41a" />
          {{ audience_in["type"] }}
        </div>
        <span style="color: #bfbfbf">|</span>
        <a-select
          mode="multiple"
          v-model="audience_in['value']"
          style="width: 100%"
          placeholder="Select Audience"
          option-label-prop="label"
        >
          <a-select-option
            v-for="(item, index) in audience_list"
            :key="index"
            :value="item.name"
            :label="item.name"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </div>
      <!-- 不包含 -->
      <div class="include">
        <div style="width: 120px;margin-left: 11px;">
          <a-icon type="close-circle" style="color: #ff4d4f" />
          {{ audience_ex["type"] }}
        </div>
        <span style="color: #bfbfbf">|</span>
        <a-select
          mode="multiple"
          v-model="audience_ex['value']"
          style="width: 100%"
          placeholder="Select Audience"
          option-label-prop="label"
        >
          <a-select-option
            v-for="(item, index) in audience_list"
            :key="index"
            :value="item.name"
            :label="item.name"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </div>

      <h1 class="title">Budget and Bidding</h1>
      <!-- Daily Budget -->
      <div class="form-tl"><b>Daily Budget</b></div>
      <div class="input-number">
        <a-input-number v-model="daily_budget" style="width: 160px" />
        <span class="input-txt">USD</span>
      </div>

      <!-- Monthly Budget -->
      <div v-if="monthly_budget">
        <div class="form-tl"><b>Monthly Budget</b></div>
        <div class="input-number">
          <a-input-number v-model="monthly_budget" style="width: 220px" />
          <span class="input-txt">USD</span>
        </div>
      </div>

      <!-- Bid Strategy -->
      <div v-if="curPlatform == 'TBL'">
        <div class="form-tl"><b>Bid Strategy</b></div>
        <a-radio-group v-model="bid_strategy">
          <a-radio value="MAX_CONVERSIONS" :style="radioStyle">
            Max Conversions
          </a-radio>
          <a-radio value="TARGET_CPA" :style="radioStyle">
            Target CPA
          </a-radio>
          <a-radio value="SMART" :style="radioStyle">
            Smart
          </a-radio>
          <a-radio value="FIXED" :style="radioStyle">
            Fixed
          </a-radio>
        </a-radio-group>
      </div>

      <div v-if="curPlatform == 'OB' && ob_objective == 'Conversions'">
        <div class="form-tl"><b>Bid Strategy</b></div>
        <a-radio-group v-model="bid_strategy" @change="onOBBidStrategyChange">
          <a-radio value="TRAFFIC">
            Traffic
          </a-radio>
          <a-radio value="CPC">
            CPC
          </a-radio>
          <a-radio value="MAX_CONVERSION_FULLY_AUTOMATED">
            Max Conversion Fully Automated
          </a-radio>
          <a-radio value="TARGET_CPA_FULLY_AUTOMATED">
            Target CPA Fully Automated
          </a-radio>
          <a-radio value="TARGET_ROAS_FULLY_AUTOMATED" >
                Target ROAS Fully Automated
          </a-radio>
        </a-radio-group>

        <a-select
          mode="multiple"
          v-model="conversions"
          style="width: 100%;margin-top: 16px;"
          placeholder="Supporting Conversion"
          option-label-prop="label"
          @change="onBidStrategyChange"
        >
          <a-select-option
            :disabled="item.disabled"
            v-for="(item, index) in conversions_list"
            :key="index"
            :value="item.name"
            :label="item.name"
            >{{ item.name }}</a-select-option
          >
        </a-select>
        <!-- 新 -->
        <a-select
          mode="multiple"
          v-model="conversions2"
          style="width: 100%;margin-top: 16px;"
          placeholder="Primary Conversion"
          option-label-prop="label"
          @change="onBidStrategyChange"
        >
          <a-select-option
            :disabled="item.disabled"
            v-for="(item, index) in conversions_list"
            :key="index"
            :value="item.name"
            :label="item.name"
            >{{ item.name }}</a-select-option
          >
        </a-select>
        <!-- CPC Adjustment -->
        <div v-if="bid_strategy == 'CPC'">
          <div class="form-tl"><b>CPC Adjustment</b></div>
          <div class="input-number nopadding">
            <a-input-number
              v-model="ob_max_percentage"
              :min="0"
              :formatter="(value) => `${value}%`"
              :parser="(value) => value.replace('%', '')"
              @change="onChange"
            />
          </div>
        </div>
      </div>
      <!-- Target CPA -->
      <div v-if="bid_strategy == 'TARGET_CPA_FULLY_AUTOMATED'">
        <div class="form-tl"><b>Target CPA</b></div>
        <div class="input-number">
          <a-input-number
            v-model="ob_target_cpa"
            style="width: 100px"
            step="1"
          />
          <span class="input-txt">USD</span>
        </div>
        <p style="color: #ff4d4f;font-size: 12px;margin-top:3px;">
          Please enter a target CPA of at least 0.1$
        </p>
      </div>
      <!-- Target CPA MAX -->
              <div v-if="bid_strategy == 'TARGET_CPA_FULLY_AUTOMATED'">
                <div class="form-tl"><b>Max CPC</b></div>
                <div class="input-number">
                  <a-input-number
                    v-model="target_cpa_max"
                    style="width: 100px"
                    step="0.01"
                  />
                  <span class="input-txt">USD</span>
                </div>
              </div>

      <!-- Default CPC -->
      <div v-if="bid_strategy != 'MAX_CONVERSION_FULLY_AUTOMATED'">
        <div class="form-tl"><b>Default CPC</b></div>
        <div class="input-number">
          <a-input-number
            v-model="default_cpc"
            style="width: 220px"
            step="0.01"
          />
          <span class="input-txt">USD</span>
        </div>
      </div>
      
      <!-- Max Conversion -->
      <div v-if="bid_strategy == 'MAX_CONVERSION_FULLY_AUTOMATED'">
        <div class="form-tl"><b>Bid Cap</b></div>
        <div class="input-number">
          <a-input-number
            v-model="target_cpa_max"
            style="width: 220px"
            step="1"
          />
          <span class="input-txt">USD</span>
        </div>
      </div>


      <h1 class="title">Tracking and Blocking</h1>
      <!-- Tracking Code -->
      <div class="form-tl"><b>Tracking Code</b></div>
      <a-textarea v-model="tracking_code" placeholder="" :rows="4" />

      <!-- Block Sites -->
      <div class="form-tl"><b>Block Sites</b></div>
      <div
        v-if="curPlatform == 'TBL' && block_sites.type == 'EXCLUDE'"
        style="margin-bottom: 100px;"
      >
        <div
          class="block-item"
          v-for="(item, index) in block_sites.value"
          :key="index"
          @click="deleteBlockSite(item)"
        >
          <a-icon type="stop" style="color: #ff4d4f" />
          <span style="flex: 1; padding: 0 8px;">{{ item }}</span>
          <a-icon type="close" style="color: #c8c8c8" />
        </div>
      </div>

      <div v-if="curPlatform == 'OB'" style="margin-bottom: 100px;">
        <div
          class="block-item"
          v-for="(item, index) in block_sites"
          :key="index"
          @click="deleteBlockSite(item.id)"
        >
          <a-icon type="stop" style="color: #ff4d4f" />
          <span style="flex: 1; padding: 0 8px;">{{ item.name }}</span>
          <a-icon type="close" style="color: #c8c8c8" />
        </div>
      </div>

      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginRight: 8px" @click="onDrawerClose">
          Cancel
        </a-button>
        <a-button type="primary" @click="onDrawerSave" :loading="drawLoading">
          Save
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
let initHourList = [
  {
    type: "INCLUDE",
    day: "MONDAY",
    from_hour: 0,
    until_hour: 24,
  },
  {
    type: "INCLUDE",
    day: "TUESDAY",
    from_hour: 0,
    until_hour: 24,
  },
  {
    type: "INCLUDE",
    day: "WEDNESDAY",
    from_hour: 0,
    until_hour: 24,
  },
  {
    type: "INCLUDE",
    day: "THURSDAY",
    from_hour: 0,
    until_hour: 24,
  },
  {
    type: "INCLUDE",
    day: "FRIDAY",
    from_hour: 0,
    until_hour: 24,
  },
  {
    type: "INCLUDE",
    day: "SATURDAY",
    from_hour: 0,
    until_hour: 24,
  },
  {
    type: "INCLUDE",
    day: "SUNDAY",
    from_hour: 0,
    until_hour: 24,
  },
];
let columnsSum = [
  {
    title: "",
    dataIndex: "total",
  },

  {
    title: "Ads CTR",
    dataIndex: "ads_ctr",
    scopedSlots: { customRender: "ads_ctr" },
    align: "right",
  },
  {
    title: "Ads CPC",
    dataIndex: "ads_cpc",
    scopedSlots: { customRender: "ads_cpc" },
    align: "right",
  },
  {
    title: "CTR",
    dataIndex: "ctr",
    scopedSlots: { customRender: "ctr" },
    align: "right",
  },
  {
    title: "Clicks",
    dataIndex: "clicks",
    align: "right",
    scopedSlots: { customRender: "clicks" },
    // customRender: text => {
    //   return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
    // }
  },
  {
    title: "Avg.CPC",
    dataIndex: "avg_cpc",
    width: 130,
    scopedSlots: { customRender: "avg_cpc" },
    align: "right",
  },
  {
    title: "Spent",
    dataIndex: "spent",
    scopedSlots: { customRender: "spent" },
    align: "right",
  },
  {
    title: "Session",
    dataIndex: "sessions",
    align: "right",
    scopedSlots: { customRender: "sessions" },
    // customRender: text => {
    //   return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
    // }
  },
  {
    title: "New User",
    dataIndex: "new_user",
    align: "right",
    scopedSlots: { customRender: "new_user" },
    // customRender: text => {
    //   return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
    // }
  },
  {
    title: "PV",
    dataIndex: "page_views",
    align: "right",
    scopedSlots: { customRender: "page_views" },
    // customRender: text => {
    //   return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
    // }
  },
  {
    title: "P/S",
    dataIndex: "pages_per_session",
    align: "right",
    scopedSlots: { customRender: "pages_per_session" },
  },
  {
    title: "CPC 上限",
    dataIndex: "cpc_top",
    align: "right",
    scopedSlots: { customRender: "cpc_top" },
  },
  {
    title: "系数",
    dataIndex: "x_num",
    align: "right",
    scopedSlots: { customRender: "x_num" },
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    scopedSlots: { customRender: "z_num" },
  },
  // {
  //   title:'Impressions',
  //   dataIndex: 'impressions',
  //   scopedSlots: { customRender: 'impressions' },
  //   align: 'right'
  // },
  // {
  //   title:'Conversions',
  //   dataIndex: 'conversions',
  //   scopedSlots: { customRender: 'conversions' },
  //   align: 'right'
  // }
  // {
  //   title: 'Viewable Impressions',
  //   dataIndex: 'visible_impressions',
  //   align: 'right',
  //   customRender: text => {
  //     return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
  //   }
  // },
  // {
  //   title: 'VCTR',
  //   dataIndex: 'vctr',
  //   align: 'right',
  // },
  // {
  //   title: 'CPM ',
  //   dataIndex: 'cpm',
  //   align: 'right',
  // },
  // {
  //   title: 'VCPM ',
  //   dataIndex: 'vcpm',
  //   align: 'right',
  // }
];

export default {
  data() {
    return {
      ob_target_cpa_max: 0,
      isShowArchived: false,
      drawLoading: false,
      isShowTotal: false,
      columnsSum,
      dataListSum: [],
      pageSize: 50,
      selectFilterAction: "Saved Filters",
      saveFilterList: [], //保留的筛选条件
      saveFilterName: "",
      saveFilterVisible: false,
      poptitle: "New Label",
      isNewLabel: false,
      // selectLabelNames: [],
      labelsearchname: "",
      labelList: [],
      labelname: "",
      popclicked: false,
      curPeriod: "no Compare",
      rangeopen: false,
      datePickerVisible: false,
      isPrevious: false,
      // de_end_date: '',
      // de_start_date: '',
      period1: [
        moment()
          .subtract(8, "day")
          .format("YYYY-MM-DD"),
        moment()
          .subtract(2, "day")
          .format("YYYY-MM-DD"),
      ],
      period2: [],
      period3: [],
      campaign_list: [],
      deal_campaign_list: [],
      // pagination: {
      //   pageSize: 50,
      //   current: 1
      // },
      block_publishers: "",
      site_bid: [],
      target_cpa_max: '',
      ob_max_percentage: 100,
      ob_target_cpa: 0,
      bid_strategy_sum: 3,
      // ob_bid_disabled: false,
      conversions: [],
      conversions2: [],
      conversions_list: [],
      bid_strategy: "",
      tbl_objective: "DRIVE_WEBSITE_TRAFFIC",
      ob_objective: "Traffic",
      ob_all_country: [],
      ob_country: [],
      fetching: false,
      curPlatform: "",
      userInfo: {},
      moment,
      drawervisible: false,
      selectAction: "Edit",
      campaign_ids: [],
      form: {},
      defaultvalue: "Choose",
      labelCol: { span: 5 },
      wrapperCol: { span: 12 },
      visible: false,
      ruleVisible: false,
      rule: "",
      // columns,
      dataList: [],
      fixedData: [],
      loading: false,
      colBaseInfoList: [],
      colPerfList: [],
      periodList: [
        {
          id: 1,
          name: "Previous period",
        },
        {
          id: 2,
          name: "Custom",
        },
        // {
        //   id: 3,
        //   name: 'no Compare'
        // }
      ],
      platformList: [
        {
          id: 1,
          name: "all",
        },
        {
          id: 2,
          name: "taboola",
        },
        {
          id: 3,
          name: "outbrain",
        },
      ],
      platform: "outbrain",
      filterList: [
        {
          id: 1,
          name: "equals",
        },
        {
          id: 2,
          name: "not equals",
        },
        {
          id: 3,
          name: "contains",
        },
        {
          id: 4,
          name: "not contains",
        },
        {
          id: 5,
          name: ">",
        },
        {
          id: 6,
          name: "<",
        },
      ],
      curColumn: "",
      curNeed: 0,
      ruleList: [],
      curName: "",
      campaignAction: [
        {
          id: 1,
          name: "Pause",
        },
        {
          id: 2,
          name: "Play",
        },
        {
          id: 3,
          name: "Relaunch",
        },
        // {
        //   id: 4,
        //   name: 'Change setting'
        // },
        {
          id: 5,
          name: "优化 Creatives",
        },
        {
          id: 6,
          name: "Copy",
        },
        {
          id: 7,
          name: "批量 Archive",
        },
      ],
      fields: [],
      campaign_id: "",
      campaign_name: "",
      brand_text: "",
      start_date: "",
      end_date: "9999-12-31",
      // select_hour: 1,
      start_hour: {
        mode: "ALWAYS",
        rules: [],
        time_zone: "US/Pacific",
      },
      // start_hour: {
      //   mode: 'CUSTOM',
      //   rules: initHourList,
      //   time_zone: 'US/Pacific'
      // },
      // hourList: initHourList,
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      country: {
        type: "INCLUDE",
        value: [],
      },
      device: {
        type: "INCLUDE",
        value: [],
      },
      os: {
        type: "INCLUDE",
        value: [],
      },
      browsers: {
        type: "INCLUDE",
        value: [],
      },
      audience_in: {
        type: "INCLUDE",
        value: [],
      },
      audience_ex: {
        type: "EXCLUDE",
        value: [],
      },
      audience_list: [],
      daily_budget: 30,
      monthly_budget: "3000",
      default_cpc: 0,
      tracking_code: "",
      block_sites: {
        type: "EXCLUDE",
        value: [],
      },
      ablePageList: [
        {
          size: 25,
          name: "25",
        },
        {
          size: 50,
          name: "50",
        },
        {
          size: 75,
          name: "75",
        },
      ],
    };
  },
  computed: {
    columns() {
      const columns = [
        {
          title: "推广",
          dataIndex: "platform",
          className: "baseinfo",
          fixed: "left",
          width: 50,
        },
        {
          title: "Campaign ID",
          dataIndex: "campaign_id",
          className: "baseinfo",
          fixed: "left",
          scopedSlots: { customRender: "campaign_id" },
          sorter: (a, b) =>
            a.campaign_id.toString().localeCompare(b.campaign_id.toString()),
          width: 200,
        },
        {
          title: "Campaign Name",
          dataIndex: "campaign_name",
          className: "baseinfo",
          fixed: "left",
          scopedSlots: { customRender: "campaign_name" },
          width: 240,
          sorter: (a, b) => a.campaign_name.localeCompare(b.campaign_name),
          // sorter: (a, b) => a.value.localeCompare(b.value)
        },
        {
          title: "Campaign status",
          dataIndex: "campaign_status",
          className: "baseinfo",
          scopedSlots: { customRender: "status" },
          sorter: (a, b) => a.campaign_status.localeCompare(b.campaign_status),
          // sorter: (a, b) => a.campaign_status - b.campaign_status,
          // fixed: 'left'
          width: 150,
        },
        {
          title: "Start Date",
          dataIndex: "start_date",
          className: "baseinfo",
          sorter: (a, b) => a.start_date.localeCompare(b.start_date),
          // sorter: (a, b) => a.start_date_sort - b.start_date_sort,
          // fixed: 'left'
          width: 130,
          align: "right",
        },
        {
          title: "Default CPC",
          dataIndex: "default_cpc",
          className: "baseinfo",
          sorter: (a, b) => a.default_cpc - b.default_cpc,
          // fixed: 'left'
          width: 130,
          align: "right",
        },
        // {
        //   title: 'Label',
        //   dataIndex: 'label',
        //   width: 150,
        //   sorter: (a, b) => a.label.toString().localeCompare(b.label.toString()),
        // },
        {
          title: "CPA",
          dataIndex: "cpa",
          className: "per",
          sorter: (a, b) => a.per - b.per,
          align: "right",
          customRender: (text) => {
            return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
          },
        },
        {
          title: "Total CPA",
          dataIndex: "total_cpa",
          className: "per",
          sorter: (a, b) => a.per - b.per,
          align: "right",
          customRender: (text) => {
            return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
          },
        },
        {
          title: "Ads CTR",
          dataIndex: "ads_ctr",
          className: "per",
          sorter: (a, b) => a.ads_ctr - b.ads_ctr,
          scopedSlots: { customRender: "ads_ctr" },
          align: "right",
        },
        {
          title: "Ads CPC",
          dataIndex: "ads_cpc",
          className: "per",
          sorter: (a, b) => a.ads_cpc - b.ads_cpc,
          scopedSlots: { customRender: "ads_cpc" },
          align: "right",
        },
        {
          title: "CTR",
          dataIndex: "ctr",
          className: "per",
          sorter: (a, b) => a.ctr - b.ctr,
          scopedSlots: { customRender: "ctr" },
          align: "right",
        },
        {
          title: "Clicks",
          dataIndex: "clicks",
          className: "per",
          sorter: (a, b) => a.clicks - b.clicks,
          align: "right",
          scopedSlots: { customRender: "clicks" },
          // customRender: text => {
          //   return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
          // }
        },
        {
          title: "Avg.CPC",
          dataIndex: "avg_cpc",
          className: "per",
          sorter: (a, b) => a.avg_cpc - b.avg_cpc,
          width: 130,
          scopedSlots: { customRender: "avg_cpc" },
          align: "right",
        },
        {
          title: "Spent",
          dataIndex: "spent",
          className: "per",
          scopedSlots: { customRender: "spent" },
          sorter: (a, b) => a.spent - b.spent,
          align: "right",
        },
        {
          title: "Session",
          dataIndex: "sessions",
          className: "per",
          sorter: (a, b) => a.sessions - b.sessions,
          align: "right",
          scopedSlots: { customRender: "sessions" },
          // customRender: text => {
          //   return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
          // }
        },
        {
          title: "New User",
          dataIndex: "new_user",
          className: "per",
          sorter: (a, b) => a.new_user - b.new_user,
          align: "right",
          scopedSlots: { customRender: "new_user" },
          // customRender: text => {
          //   return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
          // }
        },
        {
          title: "PV",
          dataIndex: "page_views",
          className: "per",
          sorter: (a, b) => a.page_views - b.page_views,
          align: "right",
          scopedSlots: { customRender: "page_views" },
          // customRender: text => {
          //   return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
          // }
        },
        {
          title: "P/S",
          dataIndex: "pages_per_session",
          className: "per",
          sorter: (a, b) => a.pages_per_session - b.pages_per_session,
          align: "right",
          scopedSlots: { customRender: "pages_per_session" },
        },
        {
          title: "CPC 上限",
          dataIndex: "cpc_top",
          className: "per",
          sorter: (a, b) => a.cpc_top - b.cpc_top,
          align: "right",
          scopedSlots: { customRender: "cpc_top" },
        },
        {
          title: "CPC%",
          dataIndex: "cpc_percent",
          className: "per",
          sorter: (a, b) => a.cpc_percent - b.cpc_percent,
          align: "right",
          customRender: (text) => {
            return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "%";
          },
        },
        {
          title: "系数",
          dataIndex: "x_num",
          className: "per",
          sorter: (a, b) => a.x_num - b.x_num,
          align: "right",
          scopedSlots: { customRender: "x_num" },
        },
        {
          title: "宗数",
          dataIndex: "z_num",
          className: "per",
          sorter: (a, b) => a.z_num - b.z_num,
          align: "right",
          scopedSlots: { customRender: "z_num" },
          // customRender: text => {
          //   if(text !== 0){return text.toFixed(2)} else{return text}

          // }
        },
        {
          title: "宗数/花费",
          dataIndex: "z_s",
          className: "per",
          sorter: (a, b) => a.z_s - b.z_s,
          align: "right",
          customRender: (text) => {
            return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "%";
          },
        },
        {
          title: "Change Setting",
          className: "per",
          scopedSlots: { customRender: "action" },
          fixed: "right",
          align: "center",
          width: 120,
        },
      ];
      return columns;
    },
  },
  methods: {
    handleFilterActionChange(e) {
      if (localStorage.saveFilterList) {
        this.saveFilterList = JSON.parse(localStorage.saveFilterList);
      }
      this.dataList = this.fixedData;
      let target = this.saveFilterList.find((item) => item.name == e);
      this.ruleList = target.list;
      this.ruleList.forEach((item) => {
        this.changeRuleColumn(
          item.name,
          item.curName,
          item.curNeed,
          item.rule,
          0
        );
      });
    },
    changeFilterSave() {
      if (localStorage.saveFilterList) {
        this.saveFilterList = JSON.parse(localStorage.saveFilterList);
      }
      let newItem = { name: this.saveFilterName, list: this.ruleList };
      this.saveFilterList.splice(0, 0, newItem);
      localStorage.saveFilterList = JSON.stringify(this.saveFilterList);
      this.saveFilterVisible = false;
    },
    handleFilterCancel() {
      this.saveFilterVisible = false;
    },
    showFilterModal() {
      this.saveFilterVisible = true;
    },
    chageLabelCheckbox(item) {
      if (item.type != "allhave") {
        item.type = "allhave";
        this.deal_campaign_list.forEach((b) => {
          if (!b.label.includes(item.name)) {
            if (b.label) {
              b.label = b.label + "," + item.name;
            } else {
              b.label = item.name;
            }
          }
        });
        this.campaign_list.forEach((a) => {
          if (!a.label.includes(item.name)) {
            if (a.label) {
              a.label = a.label + "," + item.name;
            } else {
              a.label = item.name;
            }
          }
        });
      } else {
        item.type = "";
        this.campaign_list.forEach((a) => {
          if (a.label.includes(item.name)) {
            let newLabel = a.label.split(",");
            let index = newLabel.indexOf(item.name);
            newLabel.splice(index, 1);
            a.label = newLabel.join(",");
          }
        });
      }
    },
    // 是否展示Archived Campaign
    onOpenArchived(checked) {
      this.isShowArchived = checked;
    },
    createNewLabel() {
      this.isNewLabel = true;
      this.poptitle = "New Label";
    },
    labelApply() {
      // let index = this.labelList.findIndex(item => item.type == 'allhave' || item.type == 'parthave')
      // if(index == -1){
      //   this.$message.error('选择一个Label~');
      //   return false
      // }
      this.deal_campaign_list.forEach((item, index) => {
        item.label.split(",").forEach((a) => {
          if (this.campaign_list[index].label.includes(a)) {
            this.labelHttp(a, item.campaign_id, 0);
          } else {
            this.labelHttp(a, item.campaign_id, 1);
          }
        });

        if (index == this.campaign_list.length - 1) {
          this.labelList.forEach((b) => {
            b.type = "";
          });
        }
      });
    },
    labelCheckboxChange(e) {
      console.log(e);
    },
    searchLabel(keyword) {
      return this.labelList.filter((item) => {
        if (item.name.includes(keyword)) {
          return item;
        }
      });
    },
    onLabelSearch(e) {
      this.labelsearchname = e.target.value;
    },
    handleClickChange() {
      if (!this.campaign_ids.length) {
        this.$message.error("先选择需要打Label的Campaign~");
        return false;
      }
      // this.getLabel()
      this.popclicked = true;
      //取多个数组的交集
      let intersection = (list, ...args) =>
        list.filter((item) => args.every((list) => list.includes(item)));
      let allLabel = [];
      this.campaign_list.forEach((item) => {
        allLabel.push(item.label.split(","));
      });
      let allHaveLabel = intersection(...allLabel);
      //只要包含就 parthave
      this.campaign_list.forEach((item) => {
        this.labelList.forEach((a) => {
          if (item.label.includes(a.name)) {
            a.type = "parthave";
          }
        });
      });
      //交集 allhave
      this.labelList.forEach((b) => {
        if (allHaveLabel.includes(b.name)) {
          b.type = "allhave";
        }
      });
    },
    labelChange(e) {
      console.log(e);
    },
    popHide() {
      // this.selectLabelNames = []
      this.labelList.forEach((b) => {
        b.type = "";
      });
      this.popclicked = false;
      this.isNewLabel = false;
    },
    getLabel() {
      this.labelList = [];
      this.$post(api.filter.getLabelList, {
        token: this.userInfo.token,
      }).then((res) => {
        if (res.data.status == 200) {
          let data = res.data.data;
          data.forEach((item) => {
            this.labelList.push({ name: item, type: "" });
          });
          if (this.labelList.length) {
            this.poptitle = "Select Label";
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    popCreate() {
      if (!this.labelname) {
        this.$message.error("label 不能为空～");
        return false;
      }
      this.campaign_ids.forEach((item) => {
        this.labelHttp(this.labelname, item, 0);
      });
      this.isNewLabel = false;
      setTimeout(() => {
        this.getLabel();
      }, 2e3);
    },
    labelHttp(labelname, campaign_id, type) {
      this.$post(api.filter.operateLabel, {
        label: labelname,
        campaign_id: campaign_id,
        operate: type,
        token: this.userInfo.token,
      }).then((res) => {
        if (res.data.status == 200) {
          this.$message.success(res.data.message);
          this.popclicked = false;
          this.dataList.forEach((data) => {
            if (data.campaign_id == campaign_id) {
              if (type == 1) {
                let newLabel = data.label.split(",");
                let index = newLabel.indexOf(labelname);
                newLabel.splice(index, 1);
                data.label = newLabel.join(",");
              } else {
                if (data.label) {
                  if (!data.label.includes(labelname)) {
                    data.label = data.label + "," + labelname;
                  }
                } else {
                  data.label = labelname;
                }
              }
            }
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    //导出
    handleExports() {
      const { columns, dataList } = this;
      this.loading = true;
      let newlist = [];
      require.ensure([], () => {
        const { export_json_to_excel } = require("../../excel/Export2Excel"); //---require 括号里面是相对路径其实是引用  Export2Excel.js
        const tHeader = []; //----tHeader 数组里面放的是字段的对应名
        const filterVal = []; //-----filterVal  字段对应的值
        columns &&
          columns.map((data) => {
            tHeader.push(data.title);
            filterVal.push(data.dataIndex);
          });
        this.title = "performance";
        newlist = dataList;

        // const list = keyData;//----对应的json数组
        const data = this.formatJson(filterVal, newlist);
        export_json_to_excel(tHeader, data, this.title);
        this.loading = false;
        this.$message.success("导出成功");
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    toLine(campaign_id) {
      const { href } = this.$router.resolve({
        path: "/line",
        query: {
          campaign_id: campaign_id,
          period: JSON.stringify([this.period1[0], this.period1[1]]),
        },
      });
      window.open(href, "_blank");
    },
    ctrlCompare(e) {
      if (e) {
        this.handlePeriodChange("Previous period");
      } else {
        this.curPeriod = "no Compare";
        this.period1 = this.period2;
        this.period2 = [];
      }
    },
    handleNoCompareStartChange(date, dateString) {
      this.period1 = dateString;
    },
    cancelRangePicker() {
      this.rangeopen = false;
    },
    submitRangePicker() {
      this.period1 = [this.preStartDate, this.preEndDate];
      this.rangeopen = false;
      this.getList();
    },
    rangePickerSelect(e) {
      console.log(e);
      this.rangeopen = true;
    },
    showSelectDate() {
      this.datePickerVisible = true;
    },
    //显示比较时间
    previousPeriod() {
      this.isPrevious = true;
      let days =
        (Date.parse(this.period2[1]) - Date.parse(this.period2[0])) /
        (1 * 24 * 60 * 60 * 1000); //计算相隔几天
      this.preStartDate = this.getNextDate(this.period2[0], -(days + 1)); //获取前一个时间段的开始时间
      this.preEndDate = this.getNextDate(this.period2[0], -1); //获取前一个时间段的结束时间
    },
    //获取某个日期指定前几天是哪一天
    getNextDate(date, day) {
      let dd = new Date(date);
      dd.setDate(dd.getDate() + day);
      let y = dd.getFullYear();
      let m =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      let d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      return y + "-" + m + "-" + d;
    },
    getCurrentStyle(current) {
      const style = {};
      //把current转换城'yy-mm-dd'格式
      let timestamp = new Date(current + 8 * 3600 * 1000);
      let newCurrent = timestamp
        .toJSON()
        .substr(0, 10)
        .replace("T", " ");
      if (this.isPrevious) {
        // this.period1 = [preStartDate, preEndDate]
        if (
          newCurrent === this.preStartDate ||
          newCurrent === this.preEndDate
        ) {
          style.backgroundColor = "#52c41a";
          style.color = "#fff";
        } else if (
          newCurrent > this.preStartDate &&
          newCurrent < this.preEndDate
        ) {
          style.backgroundColor = "#e5f7dc";
        }
      } else {
        // if(newCurrent > this.de_start_date && newCurrent < this.de_end_date){
        //   style.backgroundColor = '#e6f7ff'
        // }
      }
      return style;
    },
    handleNewDataChange(date, dateString) {
      this.isPrevious = false;
      this.period2 = dateString;
    },
    handleCustomStartChange(date, dateString) {
      this.period1 = dateString;
      this.period3 = dateString;
    },
    handleCustomEndChange(date, dateString) {
      this.period2 = dateString;
    },
    handleOldDataChange(date, dateString) {
      this.period2 = dateString;
    },
    onChange(value) {
      this.ob_max_percentage = value;
    },
    onBidStrategyChange(value) {
      if (value.length == this.bid_strategy_sum) {
        this.conversions_list.forEach((a) => {
          a.disabled = true;
        });
        value.forEach((item) => {
          this.conversions_list.forEach((a) => {
            if (a.name == item) a.disabled = false;
          });
        });
      } else {
        this.conversions_list.forEach((a) => {
          a.disabled = false;
        });
      }
    },
    onOBBidStrategyChange() {
      if (this.bid_strategy == "TARGET_CPA_FULLY_AUTOMATED") {
        this.conversions_list.forEach((a) => {
          a.disabled = false;
        });
        this.conversions = [];
        this.bid_strategy_sum = 1;
      } else {
        this.bid_strategy_sum = 3;
      }
    },
    onCampaignObjectiveChange(e) {
      if (e.target.value == "Conversions") {
        this.bid_strategy = "MAX_CONVERSION_FULLY_AUTOMATED";
      }
    },
    fetchLocation(value) {
      this.ob_country = [];
      this.fetching = true;
      if (value.length >= 2) {
        this.$post(api.update.getObCountry, {
          term: value,
          token: this.userInfo.token,
        }).then((res) => {
          if (res.data.status === 200) {
            this.ob_country = res.data.data;
            this.fetching = false;
          }
        });
      }
    },
    handleOBLocationDeSelect(value) {
      let new_ob_all_country = [];
      this.ob_all_country.forEach((item) => {
        if (item.name != value) {
          new_ob_all_country.push(item);
        }
      });
      this.ob_all_country = new_ob_all_country;
    },
    handleOBLocationSelect(value, e) {
      let id = e.data.attrs.id;
      this.ob_all_country.push({ id: id, name: value });
    },
    handleOBLocationChange(value) {
      Object.assign(this, {
        value,
        ob_country: [],
        fetching: false,
      });
    },
    showDrawer(platform, campaign_id) {
      this.curPlatform = platform; //当前平台
      this.drawervisible = true;
      this.getFields(platform, campaign_id);
    },
    archiveCampaign() {
      this.$post(api.filter.archiveCampaign, {
        campaign_ids: this.campaign_ids,
        token: this.userInfo.token,
      }).then((res) => {
        // this.loading = false
        if (res.data.status === 200) {
          this.$message.success("Archive 成功");
          this.selectAction = "Edit";
          res.data.data.forEach((item) => {
            this.dataList.forEach((data) => {
              if (data.campaign_id == item) {
                data.campaign_status = "Archived";
              }
            });
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    //选择操作
    handleActionChange(e) {
      if (!this.campaign_ids.length) {
        this.$message.error("请先选择一个Campaign，再继续操作！");
        this.selectAction = "Edit";
        return false;
      }
      if (e == 1 || e == 2) {
        //pause
        let operate = "pause";
        if (e == 2) operate = "play";
        this.$post(api.filter.pauseCampaign, {
          campaign_ids: this.campaign_ids,
          operate: operate,
          token: this.userInfo.token,
        }).then((res) => {
          // this.loading = false
          if (res.data.status === 200) {
            this.$message.success(operate + "成功");
            this.selectAction = "Edit";
            this.campaign_ids.forEach((item) => {
              this.dataList.forEach((data) => {
                if (data.campaign_id == item) {
                  if (operate == "play") {
                    data.campaign_status = "Running";
                  } else {
                    data.campaign_status = "Paused";
                  }
                }
              });
            });
            // this.campaign_ids = []
          } else {
            this.$message.error(res.data.message);
          }
        });
      }
      if (e == 3) {
        // if()
        //relaunch
        this.campaign_list.forEach((item) => {
          const { href } = this.$router.resolve({
            path: "/created",
            query: {
              campaign_id: item.campaign_id,
              type: "relaunch",
              platform: item.platform,
            },
          });
          window.open(href, "_blank");
        });
        this.selectAction = "Edit";
      }
      if (e == 5) {
        //creatives
        this.campaign_list.forEach((item) => {
          const { href } = this.$router.resolve({
            path: "/created",
            query: {
              campaign_id: item.campaign_id,
              type: "optimization",
              platform: item.platform,
            },
          });
          window.open(href, "_blank");
        });
        this.selectAction = "Edit";
      }
      if (e == 6) {
        const { href } = this.$router.resolve({
          path: "/batchRelaunch",
          query: {
            campaign_ids: JSON.stringify(this.campaign_ids),
            platform: this.platform,
          },
        });
        window.open(href, "_blank");
        this.selectAction = "Copy";
      }
      if (e == 7) {
        // this.archiveCampaign();
        const onOk = this.archiveCampaign;
        this.$confirm({
          title: "Are you sure to archive?",
          okText: "Yes",
          okType: "primary",
          cancelText: "No",
          onOk,
          onCancel() {
            console.log("Cancel");
          },
        });
      }
    },
    onSelectChange(selectedRowKeys) {
      this.campaign_ids = selectedRowKeys;
    },
    onSelectPer(e) {
      let result = this.campaign_list.find((item) => {
        return item.campaign_id == e.campaign_id;
      });
      if (result != undefined) {
        this.campaign_list.forEach((item, index) => {
          if (result.campaign_id == item.campaign_id) {
            this.campaign_list.splice(index, 1);
          }
        });

        this.deal_campaign_list.forEach((item, index) => {
          if (result.campaign_id == item.campaign_id) {
            this.deal_campaign_list.splice(index, 1);
          }
        });
      } else {
        this.campaign_list.push({
          campaign_id: e.campaign_id,
          platform: e.platform == "OB" ? "outbrain" : "taboola",
          label: e.label,
        });
        this.deal_campaign_list.push({
          campaign_id: e.campaign_id,
          label: e.label,
        });
      }
    },
    handleTableChange(pagination, filters) {
      this.selectedRowKeys = [];
      this.filteredInfo = filters;
    },
    closeRule(name) {
      this.campaign_ids = [];
      this.selectFilterAction = "Saved Filters";
      this.ruleList.forEach((ruleitem, index) => {
        //删掉需要删除的规则
        if (ruleitem.name == name) {
          this.ruleList.splice(index, 1);
        }
      });

      //还原数据
      this.isShowTotal = false;
      this.dataList = this.fixedData;
      this.ruleList.forEach((ruleitem) => {
        if (ruleitem.curNeed == ">")
          this.dataList = this.dataList.filter((item) => {
            if (ruleitem.name == "start_date") {
              return (
                item["start_date_sort"] >
                Number(ruleitem.rule.trim().replace(/[-]/g, ""))
              );
            } else {
              return item[ruleitem.name] > Number(ruleitem.rule);
            }
          });

        if (ruleitem.curNeed == "<")
          this.dataList = this.dataList.filter((item) => {
            if (ruleitem.name == "start_date") {
              return (
                item["start_date_sort"] <
                Number(ruleitem.rule.trim().replace(/[-]/g, ""))
              );
            } else {
              return item[ruleitem.name] < Number(ruleitem.rule);
            }
          });

        if (ruleitem.curNeed == "contains")
          this.dataList = this.dataList.filter((item) =>
            item[ruleitem.name]
              .toLowerCase()
              .includes(ruleitem.rule.toLowerCase())
          );
        if (ruleitem.curNeed == "not contains")
          this.dataList = this.dataList.filter(
            (item) =>
              item[ruleitem.name]
                .toLowerCase()
                .indexOf(ruleitem.rule.toLowerCase()) == -1
          );
        if (ruleitem.curNeed == "equals")
          this.dataList = this.dataList.filter(
            (item) =>
              item[ruleitem.name].toLowerCase() == ruleitem.rule.toLowerCase()
          );
        if (ruleitem.curNeed == "not equals")
          this.dataList = this.dataList.filter(
            (item) =>
              item[ruleitem.name].toString().toLowerCase() !=
              ruleitem.rule.toLowerCase()
          );
      });
    },
    changeRuleColumn(curColumn, curName, curNeed, rule, type) {
      if (curNeed == ">")
        this.dataList = this.dataList.filter((item) => {
          if (curColumn == "start_date") {
            return (
              item["start_date_sort"] > Number(rule.trim().replace(/[-]/g, ""))
            );
          } else {
            return item[curColumn] > Number(rule);
          }
        });
      if (curNeed == "<")
        this.dataList = this.dataList.filter((item) => {
          if (curColumn == "start_date") {
            return (
              item["start_date_sort"] < Number(rule.trim().replace(/[-]/g, ""))
            );
          } else {
            return item[curColumn] < Number(rule);
          }
        });
      if (curNeed == "contains")
        this.dataList = this.dataList.filter((item) =>
          item[curColumn].toLowerCase().includes(rule.toLowerCase())
        );
      if (curNeed == "not contains")
        this.dataList = this.dataList.filter(
          (item) =>
            item[curColumn].toLowerCase().indexOf(rule.toLowerCase()) == -1
        );
      if (curNeed == "not equals")
        this.dataList = this.dataList.filter(
          (item) =>
            item[curColumn].toString().toLowerCase() != rule.toLowerCase()
        );
      if (curNeed == "equals") {
        let newDataList = [];
        let newrule = rule.split(",");
        newrule.forEach((item) => {
          this.dataList.forEach((a) => {
            if(a.user == null){
              a.user = 'N/A'
            }
            if (
              a[this.curColumn].toString().trim().toLowerCase() == item.trim().toString().toLowerCase()
            ) {
            // console.log(a[this.curColumn])

              newDataList.push(a);
            }
          });
        });
        this.dataList = newDataList;
      }

      if (type == 1) {
        this.ruleList.push({
          name: curColumn,
          curName: curName,
          curNeed: curNeed,
          rule: rule,
        });
        this.defaultvalue = "选择列";
        this.ruleVisible = false;
      }
      if (this.platform != "all" && this.dataList.length) {
        this.isShowTotal = true;
        let clicks = 0;
        let impressions = 0;
        let spent = 0;
        let conversions = 0;
        let total_conversions = 0;
        let view_conversions = 0;
        let sessions = 0;
        let new_user = 0;
        let page_views = 0;
        let x_num = 0;
        let z_num = 0;
        let ads_ctr_wpv = 0;
        let ads_score_wpv = 0;
        let ads_cpc_wpv = 0;
        let bounce_rate_wsession = 0;
        let cpc_top_wsession = 0;
        let visible_impressions = 0;
        let cpm_wctr = 0;
        let cpm_wctr_sum = 0;
        let vcpm_wvctr = 0;
        let vcpm_wvctr_sum = 0;
        this.dataList.forEach((item) => {
          clicks += Number(item.clicks);
          impressions += Number(item.impressions);
          spent += Number(item.spent);
          conversions += Number(item.conversions);
          total_conversions += Number(item.total_conversions);
          view_conversions += Number(item.view_conversions);
          sessions += Number(item.sessions);
          new_user += Number(item.new_user);
          page_views += Number(item.page_views);
          x_num += Number(item.x_num);
          z_num += Number(item.z_num);
          ads_ctr_wpv += Number(item.page_views) * Number(item.ctr);
          ads_score_wpv += Number(item.page_views) * Number(item.ads_score);
          ads_cpc_wpv +=
            Number(item.page_views) * Number(item.ctr) * Number(item.ads_cpc);
          bounce_rate_wsession +=
            Number(item.sessions) * Number(item.bounce_rate);
          cpc_top_wsession += Number(item.sessions) * Number(item.cpc_top);
          if (item.platform == "TBL") {
            visible_impressions += Number(item.visible_impressions);
            cpm_wctr +=
              Number(item.ctr) * Number(item.avg_cpc) * Number(item.cpm);
            cpm_wctr_sum += Number(item.ctr) * Number(item.avg_cpc);
            vcpm_wvctr +=
              Number(item.vctr) * Number(item.avg_cpc) * Number(item.vcpm);
            vcpm_wvctr_sum += Number(item.vctr) * Number(item.avg_cpc);
          }
        });
        this.dataListSum[0] = {
          id: 1,
          total: "Total",
          clicks: clicks,
          impressions: impressions,
          spent: spent,
          conversions: conversions,
          total_conversions: total_conversions,
          sessions: sessions,
          new_user: new_user,
          page_views: page_views,
          x_num: x_num.toFixed(2),
          z_num: Math.round(z_num),
          pages_per_session: (page_views / sessions).toFixed(2),
          ctr: (clicks / impressions).toFixed(2),
          avg_cpc: (spent / clicks).toFixed(3),
          cpa: (spent / conversions).toFixed(3),
          conversion_rate: (conversions / clicks).toFixed(2),
          total_cpa: (spent / conversions).toFixed(3),
          view_conversion_rate: (view_conversions / clicks).toFixed(3),
          ads_ctr: (ads_ctr_wpv / page_views).toFixed(2),
          ads_score: (ads_score_wpv / page_views).toFixed(2),
          ads_cpc: (ads_cpc_wpv / ads_ctr_wpv).toFixed(3),
          bounce_rate: (bounce_rate_wsession / sessions).toFixed(2),
          cpc_top: (cpc_top_wsession / sessions).toFixed(3),
        };
        if (this.platform == "taboola") {
          this.dataListSum[0].visible_impressions = visible_impressions;
          this.dataListSum[0].vctr = (clicks / visible_impressions).toFixed(2);
          this.dataListSum[0].cpm = (cpm_wctr / cpm_wctr_sum).toFixed(2);
          this.dataListSum[0].vcpm = (vcpm_wvctr / vcpm_wvctr_sum).toFixed(2);
        }
      }
    },
    handleFilterChange(e) {
      this.curNeed = e;
    },
    handleRuleChange(e) {
      this.defaultvalue = e;
      this.curColumn = e;
      this.columns.forEach((item) => {
        if (item.dataIndex == e) {
          this.curName = item.title;
        }
      });
    },
    //判断是否是数字。
    isNumber(val) {
      var regPos = /^[-+]?[0-9]*\.?[0-9]+$/;
      if (regPos.test(val)) {
        return true;
      } else {
        return false;
      }
    },
    checkboxChange(name, type) {
      let isString = true;
      //获取数据类型
      if (this.isNumber(this.dataList[0][name])) {
        isString = false;
      }
      /*
          type: 1 Base info
          type: 2 Performance
        */
      if (type == 1) {
        this.colBaseInfoList.forEach((item) => {
          if (item.name == name) {
            //判断是否选中
            if (!item.checked) {
              //找到要插入的位置
              let indexList = [];
              this.columns.forEach((x, index) => {
                if (x.className == "baseinfo") {
                  indexList.push(index);
                }
              });

              let newItem = {};
              //Label没有办法判断是数字还是字符
              if (name == "Label") {
                newItem = {
                  title: item.show_name,
                  dataIndex: item.name,
                  className: "baseinfo",
                  align: "left",
                  sorter: (a, b) => a[item.name].localeCompare(b[item.name]),
                };
              } else {
                //新的item
                newItem = {
                  title: item.show_name,
                  dataIndex: item.name,
                  className: "baseinfo",
                  align: isString ? "left" : "right",
                  sorter: (a, b) => {
                    if (isString) {
                      return a[item.name].localeCompare(b[item.name]);
                    } else {
                      return a[item.name] - b[item.name];
                    }
                  },
                  scopedSlots: { customRender: item.name },
                };
              }

              //定制各属性特殊的要求
              if (name == "audience_name") {
                newItem.width = 400;
              }
              // if(name == 'approval_state'){
              //   newItem.width = 120
              // }
              this.columns.splice(indexList.length, 0, newItem);
            } else {
              this.columns.forEach((item, index) => {
                if (item.dataIndex == name) {
                  this.columns.splice(index, 1);
                }
              });
            }
            item.checked = !item.checked;
          }
        });
      }
      if (type == 2) {
        this.colPerfList.forEach((item) => {
          if (item.name == name) {
            if (!item.checked) {
              let newItem = {
                title: item.show_name,
                dataIndex: item.name,
                className: "per",
                align: isString ? "left" : "right",
                sorter: (a, b) => {
                  if (isString) {
                    return a[item.name].localeCompare(b[item.name]);
                  } else {
                    return a[item.name] - b[item.name];
                  }
                },
                scopedSlots: { customRender: item.name },
              };
              this.columns.splice(this.columns.length - 1, 0, newItem);

              //总和表头变化
              let newSumItem = {
                title: item.show_name,
                dataIndex: item.name,
                align: "right",
                scopedSlots: { customRender: item.name },
              };

              this.columnsSum.splice(this.columnsSum.length, 0, newSumItem);
            } else {
              this.columns.forEach((item, index) => {
                if (item.dataIndex == name) {
                  this.columns.splice(index, 1);
                }
              });

              this.columnsSum.forEach((item, index) => {
                if (item.dataIndex == name) {
                  this.columnsSum.splice(index, 1);
                }
              });
            }
            item.checked = !item.checked;
          }
        });
      }
    },
    changeColumn() {
      this.visible = false;
    },
    showRuleModal() {
      this.ruleVisible = !this.ruleVisible;
    },
    showModal() {
      this.visible = true;
    },
    handleCancel() {
      this.visible = false;
      this.ruleVisible = false;
    },
    handlePlatformChange(e) {
      this.isShowTotal = false;
      this.platform = e;
      this.getList();
    },
    handlePeriodChange(e) {
      this.curPeriod = e;
      if (e == "Previous period") {
        this.period2 = this.period1;
        this.previousPeriod();
        this.isPrevious = true;
      } else if (e == "no Compare") {
        this.period2 = this.period1;
        this.period2 = [];
      }
    },
    getList() {
      this.campaign_list = [];
      this.deal_campaign_list = [];
      this.campaign_ids = [];
      this.loading = true;
      let params = {
        platform: this.platform,
        period1: this.period1,
        period2: this.period2,
        isShowArchived: this.isShowArchived,
        token: this.userInfo.token,
      };

      this.$post(api.filter.getCampaignList, params).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {

          let newData = res.data.data;

          newData.data.forEach((item) => {
            item.start_date_sort = parseInt(
              item.start_date.replace(/[-]/g, "")
            );
          });
          this.dataList = newData.data;
          if (this.curPeriod == "no Compare") {
            this.period1 = [newData.start_date, newData.end_date];
          }
          this.fixedData = newData.data;
          this.colBaseInfoList = newData.column.base_info;
          // console.log(this.colBaseInfoList)
          this.colPerfList = newData.column.perf;
          if (this.ruleList.length) {
            this.ruleList.forEach((item) => {
              this.changeRuleColumn(
                item.name,
                item.curName,
                item.curNeed,
                item.rule,
                0
              );
            });
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    //获取两个平台的固定数据
    getFields(platform, campaign_id) {
      this.campaign_id = campaign_id;
      let params = { campaign_id: campaign_id, token: this.userInfo.token };
      this.$post(api.update.getFields, params).then((res) => {
        if (res.data.status === 200) {
          if (platform == "OB") {
            this.fields = res.data.data.ob_fields;
          } else {
            this.fields = res.data.data.tbl_fields;
          }
          this.templateChange(campaign_id);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    accountChange(e) {
      console.log(e);
    },
    onStartHourChange(e) {
      let value = e.target.value;
      this.start_hour.mode = value;
      if (value == "ALWAYS") {
        this.start_hour.rules = [];
      } else {
        this.start_hour.rules = initHourList;
      }
    },
    templateChange(campaign_id) {
      this.audience_in.value = [];
      this.audience_ex.value = [];
      this.conversions = [];
      this.conversions2 = [];
      this.$post(api.update.getCampaignInfo, {
        campaign_id: campaign_id,
        token: this.userInfo.token,
      }).then((res) => {
        if (res.data.status === 200) {
          let data = res.data.data;
          if (this.curPlatform == "OB") {
            this.ob_objective = data.objective;
            this.bid_strategy = data.bid_strategy;
            this.conversions_list = this.fields.conversion[data.account]; //根据账户找到对应的conversions_list
            this.ob_target_cpa = data.target_cpa;
            // this.ob_target_cpa_max = data.target_cpa_max;
            this.ob_max_percentage = data.max_percentage;
            this.block_publishers = data.block_publishers;
            this.site_bid = data.site_bid;
            this.target_cpa_max = data.target_cpa_max;
            if (data.conversions.length || data.primary_conversion.length ) {
              data.conversions.forEach((item) => {
                this.conversions_list.forEach((a) => {
                  if (a.value == item) {
                    this.conversions.push(a.name);
                  }
                });
              });
              this.conversions_list.forEach((a) => {
                  if (a.value == data.primary_conversion) {
                    this.conversions2.push(a.name);
                  }
              });
            }
          }
          this.account = data.account;
          this.tbl_objective = data.objective;
          this.campaign_name = data.campaign_name;
          this.brand_text = data.brand_text;
          this.start_date = data.start_date;
          this.end_date = data.end_date;
          this.country = data.country;
          this.start_hour = data.start_hour;
          let newCountryValue = [];
          if (this.curPlatform == "TBL") {
            if (data.country.value.length) {
              data.country.value.forEach((item) => {
                this.fields.country.forEach((itemchild) => {
                  if (itemchild.value == item) {
                    newCountryValue.push(itemchild.name);
                  }
                });
              });
            }
            this.country.type = data.country.type;
          } else {
            if (data.country.length) {
              data.country.forEach((item) => {
                this.ob_all_country.push({ id: item.id, name: item.name });
                newCountryValue.push(item.name);
              });
            }
            this.country.type = "INCLUDE";
          }

          this.country.value = newCountryValue;

          this.daily_budget = data.daily_budget;
          this.monthly_budget = data.monthly_budget;
          this.default_cpc = data.default_cpc;
          this.bid_strategy = data.bid_strategy;
          // if(this.bid_strategy === 'SMART'){
          // this.default_cpc = 0.04
          // }
          let newDeviceValue = [];
          if (data.device.value.length) {
            data.device.value.forEach((item) => {
              this.fields.device.forEach((itemchild) => {
                if (itemchild.value == item) {
                  newDeviceValue.push(itemchild.name);
                }
              });
            });
          }
          this.device.value = newDeviceValue;
          this.device.type = data.device.type;

          this.tracking_code = data.tracking_code;
          this.block_sites = data.block_sites;
          if (data.os.type == "ALL") {
            this.os.type = "INCLUDE";
            this.os.value = [];
          } else {
            this.os = data.os;
          }

          if (data.browsers.type == "ALL") {
            this.browsers.type = "INCLUDE";
            this.browsers.value = [];
          } else {
            this.browsers = data.browsers;
          }

          //由account得到audience_list
          this.audience_list = this.fields.audience[data.account];
          // TBL audience
          if (this.curPlatform == "TBL" && data.audience) {
            data.audience.forEach((item) => {
              if (item.type == "INCLUDE") {
                item.collection.forEach((a) => {
                  this.audience_list.forEach((b) => {
                    if (b.value == a) {
                      this.audience_in.value.push(b.name);
                    }
                  });
                });
              } else {
                item.collection.forEach((a) => {
                  this.audience_list.forEach((b) => {
                    if (b.value == a) {
                      this.audience_ex.value.push(b.name);
                    }
                  });
                });
              }
            });
            this.audience = data.audience;
          }
          // OB audience
          if (
            (this.curPlatform == "OB" && data.audience.include) ||
            data.audience.exclude
          ) {
            data.audience.include.forEach((item) => {
              this.audience_list.forEach((a) => {
                if (a.value == item) {
                  this.audience_in.value.push(a.name);
                }
              });
            });
            if (data.audience.exclude) {
              data.audience.exclude.forEach((item) => {
                this.audience_list.forEach((a) => {
                  if (a.value == item) {
                    this.audience_ex.value.push(a.name);
                  }
                });
              });
            }
            this.audience = data.audience;
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    onPickerChange(date, dateString) {
            this.end_date = dateString;
          },
    //删除一个block site
    deleteBlockSite(blocksite) {
      if (this.curPlatform == "TBL") {
        this.block_sites.value.forEach((item, index) => {
          if (item == blocksite) {
            this.block_sites.value.splice(index, 1);
          }
        });
      } else {
        this.block_sites.forEach((item, index) => {
          if (item.id == blocksite) {
            this.block_sites.splice(index, 1);
          }
        });
      }
    },
    onDrawerClose() {
      this.drawervisible = false;
      this.ob_all_country = [];
    },
    onDateDrawerClose() {
      this.datePickerVisible = false;
    },
    // onPickerChange(date, dateString) {
    //   this.end_date = dateString;
    // },
    //保存修改setting
    onDrawerSave() {
      this.drawLoading = true;
      let newBrowsers = {
        type: "ALL",
        value: [],
      };
      let newOs = {
        type: "ALL",
        value: [],
      };
      if (this.browsers.value.length != 0) {
        newBrowsers = this.browsers;
      }
      if (this.os.value.length != 0) {
        newOs.type = this.os.type;
        this.os.value.forEach((item, index) => {
          newOs.value[index] = { os_family: item, sub_categories: [] };
        });
      }
      //country
      let newCountry,
        audience,
        newaudience_in,
        newaudience_ex,
        newConversions = [],
        newConversions2 = [];
      if (this.curPlatform == "TBL") {
        newCountry = {
          type: this.country.type,
          value: [],
        };
        if (this.country.value) {
          this.country.value.forEach((item) => {
            this.fields.country.forEach((a) => {
              if (a.name == item) {
                newCountry.value.push(a.value);
              }
            });
          });
        }
        audience = [];
        newaudience_in = {
          type: "",
          collection: [],
        };
        newaudience_ex = {
          type: "",
          collection: [],
        };
        if (this.audience_in.value.length) {
          newaudience_in.type = "INCLUDE";
          this.audience_in.value.forEach((item) => {
            this.audience_list.forEach((b) => {
              if (b.name == item) {
                newaudience_in.collection.push(b.value);
              }
            });
          });
          audience.push(newaudience_in);
        }
        if (this.audience_ex.value.length) {
          newaudience_ex.type = "EXCLUDE";
          this.audience_ex.value.forEach((item) => {
            this.audience_list.forEach((b) => {
              if (b.name == item) {
                newaudience_ex.collection.push(b.value);
              }
            });
          });
          audience.push(newaudience_ex);
        }
      } else {
        newCountry = [];
        this.ob_all_country.forEach((item) => {
          newCountry.push(item.id);
        });

        audience = {};
        newaudience_in = [];
        newaudience_ex = [];

        if (this.audience_in.value.length) {
          this.audience_in.value.forEach((item) => {
            this.audience_list.forEach((b) => {
              if (b.name == item) {
                newaudience_in.push(b.value);
              }
            });
          });
          audience.includedSegments = newaudience_in;
        }
        if (this.audience_ex.value.length) {
          this.audience_ex.value.forEach((item) => {
            this.audience_list.forEach((b) => {
              if (b.name == item) {
                newaudience_ex.push(b.value);
              }
            });
          });
          audience.excludedSegments = newaudience_ex;
        }

        this.conversions.forEach((item) => {
          this.conversions_list.forEach((a) => {
            if (a.name == item) {
              newConversions.push(a.value);
            }
          });
        });

        this.conversions2.forEach((item) => {
          this.conversions_list.forEach((a) => {
            if (a.name == item) {
              newConversions2.push(a.value);
            }
          });
        });
      }

      //Device
      let newDevice = {
        type: this.device.type,
        value: [],
      };
      if (this.device.value) {
        this.device.value.forEach((item) => {
          this.fields.device.forEach((a) => {
            if (a.name == item) {
              newDevice.value.push(a.value);
            }
          });
        });
      }
      // if((this.bid_strategy === 'SMART' && this.default_cpc === 0)|| (this.bid_strategy === 'FIXED' && this.default_cpc === 0)){
      //     this.default_cpc = 0.04
      // }
      if(this.end_date === '' || this.end_date === this.start_date){
        this.end_date = '9999-12-31'
      }
      let params = {
        account: this.account,
        audience: audience,
        bid_strategy: this.bid_strategy,
        block_sites: this.block_sites,
        brand_text: this.brand_text,
        browsers: newBrowsers,
        campaign_name: this.campaign_name,
        country: newCountry,
        daily_budget: this.daily_budget,
        default_cpc: this.default_cpc,
        device: newDevice,
        monthly_budget: this.monthly_budget,
        os: newOs,
        date: { start_date: this.start_date, end_date: this.end_date },
        tracking_code: this.tracking_code,
        start_hour: this.start_hour,
        objective:
          this.curPlatform == "OB" ? this.ob_objective : this.tbl_objective,
        max_percentage: (this.ob_max_percentage / 100).toFixed(2),
        target_cpa: this.ob_target_cpa,
        // ob_target_cpa: this.ob_target_cpa_max,
        conversions: newConversions,
        primary_conversion: newConversions2,
        site_bid: this.site_bid,
        block_publishers: this.block_publishers,
        target_cpa_max: this.target_cpa_max,
      };

      if (this.default_cpc > 0.12) {
        this.$confirm({
          title: "Please Notice！",
          content: "Your Default CPC is over 0.12, Doublecheck Before Change.",
          okText: "Save",
          onOk: () => {
            this.submitUpdateCampaign(params);
          },
          onCancel: () => {
            this.drawLoading = false;
          },
        });
      } else {
        this.submitUpdateCampaign(params);
      }
    },
    submitUpdateCampaign(params) {
      this.$post(api.filter.updateCampaign, {
        campaign_id: this.campaign_id,
        params: params,
        token: this.userInfo.token,
      }).then((res) => {
        if (res.data.status === 200) {
          this.drawLoading = false;
          let item = this.dataList.find(
            (item) => item.campaign_id == this.campaign_id
          );
          item.daily_budget = this.daily_budget;
          item.default_cpc = this.default_cpc;
          item.max_percentage = this.ob_max_percentage;
          item.monthly_budget = this.monthly_budget;
          this.$message.success(res.data.message);
          this.onDrawerClose();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    startTimeChange(index) {
      this.start_hour.rules[index].type == "INCLUDE"
        ? (this.start_hour.rules[index].type = "EXCLUDE")
        : (this.start_hour.rules[index].type = "INCLUDE");
    },
    onTimeStartChange(time, timeString, index) {
      this.start_hour.rules[index].from_hour = timeString
        .substr(0, 2)
        .replace(/\b(0+)/gi, "");
    },
    onTimeEndChange(time, timeString, index) {
      this.start_hour.rules[index].until_hour = timeString
        .substr(0, 2)
        .replace(/\b(0+)/gi, "");
    },
  },
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.getLabel();

    if (localStorage.saveFilterList) {
      this.saveFilterList = JSON.parse(localStorage.saveFilterList);
    }
  },
};
</script>
<style>
.section {
  margin: 24px;
  background-color: #fff;
  padding: 16px;
  position: relative;
}
.filter {
  margin-bottom: 16px;
}
.performancepage
  .ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td {
  overflow: hidden;
}
/* .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.baseinfo,
  .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th.baseinfo, */
.ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-header
  > table
  > .ant-table-thead
  > tr
  > th.baseinfo,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-left
  > .ant-table-header
  > table
  > .ant-table-thead
  > tr
  > th.baseinfo {
  background-color: #f4fbf0;
}
/* .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th.per, */
.ant-table-small
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-header
  > table
  > .ant-table-thead
  > tr
  > th.per {
  background-color: #fff4f4;
}
.gray-bg {
  overflow: hidden;
}
.ant-table td {
  white-space: normal!important;
  font-size: 12px;
  padding: 4px 5px;
}
.widthno {
  width: 0;
  transition: all ease 0.25s;
}
.widthauto {
  width: auto;
}
.runing {
  width: 6px;
  height: 6px;
  display: inline-block;
  background-color: #52c41a;
  border-radius: 100%;
  margin-right: 6px;
}
.nopadding.input-number .ant-input-number-input {
  padding-left: 16px;
}
.percent {
  position: absolute;
  bottom: -2px;
  right: 8px;
  color: #aaa;
  transform: scale(0.9);
  transform-origin: right bottom;
}
.period {
  border: 1px solid #1890ff;
  color: #1890ff;
  border-radius: 4px;
  line-height: 30px;
  display: inline-block;
  padding: 0 10px;
  margin-left: 8px;
  cursor: pointer;
}
.righttop {
  position: absolute;
  right: 24px;
  top: 12px;
  display: flex;
  align-items: center;
}
.labelwp {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  color: #1890ff;
}
.labelitem {
  line-height: 28px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  cursor: pointer;
}
.labelitem:hover {
  background-color: #f5f5f5;
}
.labelwp-item {
  cursor: pointer;
}
.checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-right: 8px;
}
.allhave,
.parthave {
  background-color: #1890ff;
  border-color: #1890ff;
  position: relative;
}
.allhave::after {
  position: absolute;
  content: "";
  width: 6px;
  height: 8px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  top: 2px;
  right: 4px;
  transform: rotate(45deg);
}
.parthave::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  background-color: #fff;
  top: 6px;
  left: 3px;
}
.performancepage .ant-table-pagination.ant-pagination {
  float: left;
  margin-left: 146px;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header{
      padding-bottom: 4px;
}
</style>