<template>
  <div>
    <div class="ant-page-header-tl">
        <a-select v-model="site" style="width: 170px; margin-right: 16px;" @change="handleSiteChange">
            <a-select-option v-for="(item, index) in site_list" :key="index" :value="item">{{item}}</a-select-option>
        </a-select>
        <a-range-picker v-if="period.length" :default-value="[moment(period[0], 'YYYY-MM-DD'), moment(period[1], 'YYYY-MM-DD')]" @change="onChange" style="margin-right: 16px;" />
        <a-button type="primary" @click="getList">Apply</a-button>
    </div>
    <div class="section">
        <!-- <a-card :bordered="false" title="A/B Test"> -->
            <a-table 
                :columns="columns" 
                :data-source="dataSource" 
                :rowKey="record => record.landing_page"
                :pagination="false" 
                size="small"
                bordered
                :loading="loading"
            > 
              <div slot="page_views" slot-scope="text, record">{{text}} <span v-if="record.landing_page == 'change'">%</span></div>
              <div slot="sessions" slot-scope="text, record">{{text}} <span v-if="record.landing_page == 'change'">%</span></div>
              <div slot="ads_cpc" slot-scope="text, record">{{text}} <span v-if="record.landing_page == 'change'">%</span></div>
              <div slot="ads_score" slot-scope="text, record">{{text}} <span v-if="record.landing_page == 'change'">%</span></div>
              <div slot="ps" slot-scope="text, record">{{text}} <span v-if="record.landing_page == 'change'">%</span></div>
              <div slot="cpc_top" slot-scope="text, record">{{text}} <span v-if="record.landing_page == 'change'">%</span></div>
              <router-link slot="action" slot-scope="text, record" v-if="record.landing_page != 'change'" :to="{path: '/abtest-detail', query: {site: site,type: record.landing_page, period: JSON.stringify(period) }}">Check</router-link>
            </a-table>
        <!-- </a-card> -->
    </div>
  </div>
</template>
<script>
  import api from '@/common/api.js'
  import moment from 'moment';

  const columns = [
    {
        title:'',
        dataIndex: 'landing_page',
        
    },
    {
        title:'Total Session',
        dataIndex: 'sessions',
        align: 'right',
        scopedSlots: { customRender: 'sessions' },
        customRender: text => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
        },
        sorter: (a, b) => a.sessions - b.sessions,
    },
    {
        title:'Total PV',
        dataIndex: 'page_views',
        align: 'right',
        scopedSlots: { customRender: 'page_views' },
        customRender: text => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
        },
        sorter: (a, b) => a.page_views - b.page_views,
    },
    {
        title:'加权 ads CTR',
        dataIndex: 'ads_ctr',
        align: 'right',
        customRender: text => {
          return text + '%'
        },
        sorter: (a, b) => a.ads_ctr - b.ads_ctr,
    },
    {
        title:'加权 ads CPC',
        dataIndex: 'ads_cpc',
        align: 'right',
        scopedSlots: { customRender: 'ads_cpc' },
        sorter: (a, b) => a.ads_cpc - b.ads_cpc,
    },
    {
        title:'加权 ads score',
        dataIndex: 'ads_score',
        align: 'right',
        scopedSlots: { customRender: 'ads_score' },
        sorter: (a, b) => a.ads_score - b.ads_score,
    },
    {
        title:'P/S',
        dataIndex: 'ps',
        align: 'right',
        scopedSlots: { customRender: 'ps' },
        sorter: (a, b) => a.ps - b.ps,
    },
    {
        title:'加权 CPC 上限',
        dataIndex: 'cpc_top',
        align: 'right',
        scopedSlots: { customRender: 'cpc_top' },
        sorter: (a, b) => a.cpc_top - b.cpc_top,
    },
    {
        title:'原始数据',
        scopedSlots: { customRender: 'action' },
        align: 'center',
    },
    
  ]


  export default {
    data() {
      return {
        moment,
        userInfo: {},
        columns,
        dataSource: [],
        loading: false,
        site_list: [],
        site: 'ba',
        period: []
      }
    },
    methods: {
      onChange(date, dateString){
        this.period = dateString
      },
      handleSiteChange(e){
        console.log(e)
        this.site = e
      },
      getSiteList(){
        this.$post(api.abtest.siteList, {
          token: this.userInfo.token,
        }).then(res => {
          // console.log(res)
          if(res.data.status === 200) {
            this.site_list = res.data.data
          }
        });
      },
      getList(){
        this.loading = true
        this.$post(api.abtest.abTest, {
          token: this.userInfo.token,
          site: this.site,
          // test_period: JSON.stringify(this.period) 
          test_period: this.period
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.dataSource = res.data.data.perf
            this.site = res.data.data.site
            this.period = [res.data.data.start_date, res.data.data.end_date]
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
    },
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.getSiteList()
      this.getList()
    }
  };
</script>

<style> 
  .total-item{
    margin-right: 10px;
    margin-left: 10px;
    color: #ddd;
  }
  /* .greenfont{
    color: #43a912;
  }
  .redfont{
    color: #f42630;
  } */
</style>
