<template>
  <div>
    <div class="ant-page-header-tl">{{name}}
      <div class="righttop">
        <a-range-picker v-if="period.length" :default-value="[moment(period[0], 'YYYY-MM-DD'), moment(period[1], 'YYYY-MM-DD')]" @change="rangeChange" />
        <a-button type="primary" style="margin-left: 16px;" @click="submitRangeChange">Apply</a-button>
      </div>
    </div>
    <div class="section">
      <!-- <a-card :loading="loading" :bordered="false" :title="name"> -->
        <a-table 
          :columns="columns" 
          :data-source="dataList" 
          :rowKey="record => record.campaign_id"
          size="middle"
          bordered
          :loading="loading"
          :pagination="{pageSize: 200}"
        >
          <a slot="action" slot-scope="text, record" @click="goLine(record.campaign_id)">Check</a>
          <div style="text-decoration: underline;cursor: pointer;" slot="toline" slot-scope="text, record" @click="toLine(record.campaign_id)">
            {{text}}
          </div>
        </a-table>
      <!-- </a-card>  -->
    </div>
  </div>
</template>
<script>
  // import api from '@/common/api.js'
  import moment from 'moment';
  const columns = [
    {
      title:'Campaign Id',
      dataIndex: 'campaign_id',
      sorter: (a, b) => a.campaign_id.toString().localeCompare(b.campaign_id.toString())
    },
    {
      title:'Campaign Name',
      dataIndex: 'campaign_name',
      scopedSlots: { customRender: 'toline' },
      sorter: (a, b) => a.campaign_name.localeCompare(b.campaign_name)
    },
    {
      title:'PV',
      dataIndex: 'page_views',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      align: 'right',
      sorter: (a, b) => a.page_views - b.page_views,
    },
    {
      title:'P/S',
      dataIndex: 'ps',
      customRender: text => {
        return text.toFixed(2)
      },
      align: 'right',
      sorter: (a, b) => a.ps - b.ps,
    },
    {
      title:'宗数',
      dataIndex: 'z_num',
      key: 'z_num',
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      align: 'right',
      sorter: (a, b) => a.z_num - b.z_num,
    },
    {
      title:'Detail',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
      align: 'center'
    }
  ]

  const columns2 = [
    {
      title: '平台',
      dataIndex: 'name',
    },
    {
      title:'camp_num',
      dataIndex: 'campaign_num',
    },
    {
      title:'pv',
      dataIndex: 'page_views',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      }
    },
    {
      title:'p/s',
      dataIndex: 'ps',
      customRender: text => {
        return text.toFixed(2)
      }
    },
    {
      title:'宗数',
      dataIndex: 'z_num',
      key: 'z_num',
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      }
    },
    {
      title:'Detail',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
    }
  ]
  export default {
    data() {
      return {
        moment,
        period: [],
        userInfo: {},
        url: '',
        name: '',
        post_url: '',
        columns,
        columns2,
        dataList: [],
        loading: false,  
        platform: '',    
        param: ''
      }
    },
    methods: {
      toLine(campaign_id){
        const {href} = this.$router.resolve({
          path: '/line',
          query: {
            campaign_id: campaign_id,
            period: JSON.stringify([this.period[0], this.period[1]])
          },
        })
        window.open(href, '_blank')
      },
      rangeChange(date, dateString){
        this.period = dateString  
      },
      submitRangeChange(){
        this.getCampaign()
      },
      getCampaign(){
        this.loading = true
        let params = {
          type: 'campaign_data',
          platform: this.name,
          post_url: this.post_url,
          period: JSON.stringify(this.period),
          token: this.userInfo.token
        }
        if(this.platform){
          console.log('22222222')
          params = {
            type: 'campaign_data',
            platform: this.platform,
            param: this.param,
            period: JSON.stringify(this.period),
            token: this.userInfo.token
          }
        }
        console.log(params)
        this.$post(this.url, params).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.dataList = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      goLine(campaign_id){
        const {href} = this.$router.resolve({
          path: '/line',
          query: {
            campaign_id: campaign_id,
            period: JSON.stringify(this.period)
          },
        })
        window.open(href, '_blank')
      }
    },
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.url =  this.$route.query.url
      this.name = this.$route.query.name.toLowerCase()
      this.post_url = this.$route.query.post_url
      this.period = JSON.parse(this.$route.query.period)

      if(this.$route.query.platform){
        this.platform = this.$route.query.platform
        this.param = this.$route.query.country
      }
      
      this.getCampaign()
    }
  };
</script>

<style> 

</style>
