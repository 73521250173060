<template>
  <div>
    <div class="ant-page-header-tl">
      <span style="margin-right:5px">Date:</span>
      <a-range-picker :default-value="dateString" @change="onDateChange" />
      <a-button
        class="btn-right"
        type="primary"
        style="margin-left:16px;"
        @click="onCommit"
        >Apply</a-button
      >
      <div style="float:right">
        <span style="margin-right:5px">按推广时间查询</span>
        <a-switch @change="onSwitch" />
      </div>
    </div>
    <!-- table -->
    <div class="section">
      <a-table
        :columns="columns"
        :data-source="dataList"
        :pagination="false"
        :rowKey="(record) => record.name"
        size="middle"
        bordered
        :loading="loading"
      >
        <div
          v-if="
            record.name != 'SUM of Editors’' &&
              record.name != 'SUM of SNS' &&
              record.name != '占比'
          "
          style="text-decoration: underline;cursor: pointer;"
          slot="toindividual"
          slot-scope="text, record"
          @click="toIndividual(record)"
        >
          {{ text }}
        </div>
        <div v-else slot="toindividual" slot-scope="text">
          <b>{{ text }}</b>
        </div>
      </a-table>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
let weekOfDay = parseInt(moment().format("E"));
const defaultStartDate = moment()
  .subtract(weekOfDay + 7, "days")
  .format("YYYY-MM-DD");
const defaultEndDate = moment()
  .subtract(weekOfDay + 1, "days")
  .format("YYYY-MM-DD");

const columns = [
  {
    title: "Editor",
    dataIndex: "name",
    scopedSlots: { customRender: "toindividual" },
    sorter: (a, b) => {
      if (
        !a.name.includes("SUM of Editors’") &&
        !a.name.includes("SUM of SNS") &&
        !a.name.includes("占比")
      ) {
        return a.name.localeCompare(b.name);
      }
    },
  },
  {
    title: "完成数",
    dataIndex: "post_num",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (
        !a.name.includes("SUM of Editors’") &&
        !a.name.includes("SUM of SNS") &&
        !a.name.includes("占比")
      ) {
        return a.post_num - b.post_num;
      }
    },
  },
  {
    title: "上线数",
    dataIndex: "online_num",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (
        !a.name.includes("SUM of Editors’") &&
        !a.name.includes("SUM of SNS") &&
        !a.name.includes("占比")
      ) {
        return a.online_num - b.online_num;
      }
    },
  },
  {
    title: "推广数",
    dataIndex: "promote_num",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (
        !a.name.includes("SUM of Editors’") &&
        !a.name.includes("SUM of SNS") &&
        !a.name.includes("占比")
      ) {
        return a.promote_num - b.promote_num;
      }
    },
  },
  {
    title: "Daily Session>100 Num",
    dataIndex: "running_num",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (
        !a.name.includes("SUM of Editors’") &&
        !a.name.includes("SUM of SNS") &&
        !a.name.includes("占比")
      ) {
        return a.running_num - b.running_num;
      }
    },
  },
  {
    title: "Session",
    dataIndex: "perf.sessions",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (
        !a.name.includes("SUM of Editors’") &&
        !a.name.includes("SUM of SNS") &&
        !a.name.includes("占比")
      ) {
        return a.perf.sessions - b.perf.sessions;
      }
    },
  },
  {
    title: "PV",
    dataIndex: "perf.page_views",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (
        !a.name.includes("SUM of Editors’") &&
        !a.name.includes("SUM of SNS") &&
        !a.name.includes("占比")
      ) {
        return a.perf.page_views - b.perf.page_views;
      }
    },
  },
  {
    title: "P/S",
    dataIndex: "perf.p_s",
    align: "right",
    customRender: (text) => {
      if (text == undefined || text == "/") return "/";
      return parseFloat(text.toString())
        .toFixed(1)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (
        !a.name.includes("SUM of Editors’") &&
        !a.name.includes("SUM of SNS") &&
        !a.name.includes("占比")
      ) {
        return a.perf.p_s - b.perf.p_s;
      }
    },
  },
  {
    title: "Ads*",
    dataIndex: "perf.ads_score",
    align: "right",
    customRender: (text) => {
      if (text == undefined || text == "/") return "/";
      return parseFloat(text.toString())
        .toFixed(1)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (
        !a.name.includes("SUM of Editors’") &&
        !a.name.includes("SUM of SNS") &&
        !a.name.includes("占比")
      ) {
        return a.perf.ads_score - b.perf.ads_score;
      }
    },
  },
  {
    title: "CPC 上限",
    dataIndex: "perf.cpc_top",
    align: "right",
    customRender: (text) => {
      if (text == undefined || text == "/") return "/";
      return parseFloat(text.toString())
        .toFixed(3)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (
        !a.name.includes("SUM of Editors’") &&
        !a.name.includes("SUM of SNS") &&
        !a.name.includes("占比")
      ) {
        return a.perf.cpc_top - b.perf.cpc_top;
      }
    },
  },
  {
    title: "宗数",
    dataIndex: "perf.z_num",
    customRender: (text) => {
      if (text == undefined) return "/";
      return text.toString();
    },
    align: "right",
    sorter: (a, b) => {
      if (
        !a.name.includes("SUM of Editors’") &&
        !a.name.includes("SUM of SNS") &&
        !a.name.includes("占比")
      ) {
        return a.perf.z_num - b.perf.z_num;
      }
    },
  },
  {
    title: "平均推广天数",
    dataIndex: "perf.days",
    align: "right",
    customRender: (text) => {
      if (text == undefined || text == "/") return "/";
      return text;
    },
    sorter: (a, b) => {
      if (
        !a.name.includes("SUM of Editors’") &&
        !a.name.includes("SUM of SNS") &&
        !a.name.includes("占比")
      ) {
        return a.perf.days - b.perf.days;
      }
    },
  },
  {
    title: "Daily Session",
    dataIndex: "perf.daily_session",
    align: "right",
    customRender: (text) => {
      if (text == undefined || text == "/") return "/";
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (
        !a.name.includes("SUM of Editors’") &&
        !a.name.includes("SUM of SNS") &&
        !a.name.includes("占比")
      ) {
        return a.perf.daily_session - b.perf.daily_session;
      }
    },
  },
  {
    title: "Daily PV",
    dataIndex: "perf.daily_pv",
    align: "right",
    customRender: (text) => {
      if (text == undefined || text == "/") return "/";
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (
        !a.name.includes("SUM of Editors’") &&
        !a.name.includes("SUM of SNS") &&
        !a.name.includes("占比")
      ) {
        return a.perf.daily_pv - b.perf.daily_pv;
      }
    },
  },
  {
    title: "Daily 宗数",
    dataIndex: "perf.daily_z_num",
    align: "right",
    customRender: (text) => {
      if (text == undefined || text == "/") return "/";
      return parseFloat(text.toString())
        .toFixed(1)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (
        !a.name.includes("SUM of Editors’") &&
        !a.name.includes("SUM of SNS") &&
        !a.name.includes("占比")
      ) {
        return a.perf.daily_z_num - b.perf.daily_z_num;
      }
    },
  },
];

export default {
  data() {
    return {
      isSwitch: false,
      userInfo: {},
      columns,
      dataList: [],
      loading: false,
      defaultStartDate,
      defaultEndDate,
      dateString: [defaultStartDate, defaultEndDate],
    };
  },
  // 定义方法
  methods: {
    // 修改时间
    onDateChange(date, dateString) {
      this.dateString = dateString;
    },

    onCommit() {
      this.getList();
    },

    // 是否展示Archived Campaign
    onSwitch(checked) {
      this.isSwitch = checked;
    },

    // 跳转到个人页面
    toIndividual(param) {
      const { href } = this.$router.resolve({
        path: "/WorkIndividual",
        query: {
          name: param.name,
          promote_num: param.promote_num,
          running_num: param.running_num,
          profit_num: param.profit_num,
          start_date: this.dateString[0],
          end_date: this.dateString[1],
          is_switch: this.isSwitch,
        },
      });
      window.open(href, "_blank");
    },

    // 获取数据列表
    getList() {
      this.loading = true;
      this.$post(api.okr.getWorkTotal, {
        token: this.userInfo.token,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
        is_switch: this.isSwitch,
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataList = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
  // 页面初始化
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.getList();
  },
};
</script>
