<template>
  <div>
    <div class="ant-page-header-tl">
      新文章日均宗数
      <div class="righttop">
        <a-range-picker v-if="period.length" :default-value="[moment(period[0], 'YYYY-MM-DD'), moment(period[1], 'YYYY-MM-DD')]" @change="rangeChange" />
        <a-button type="primary" style="margin-left: 16px;" @click="submitRangeChange">Apply</a-button>
      </div>
    </div>
    <div class="section">
      <!-- <a-card :loading="loading" :bordered="false"> -->
        <a-table 
          :columns="columns" 
          :data-source="dataList" 
          :rowKey="record => record.url"
          :pagination="false" 
          size="middle"
          :loading="loading"
          bordered
        >
          <a slot="url" target="_blank" slot-scope="text, record" :href="record.url">{{text}}</a>
          <a slot="action" slot-scope="text, record" @click="goNewCampaign(record.url)">Check</a>
        </a-table>
      <!-- </a-card>  -->
    </div>
  </div>
</template>
<script>
  import api from '@/common/api.js'
  import moment from 'moment';
  const columns = [
    {
      title:'URL',
      dataIndex: 'url',
      scopedSlots: { customRender: 'url' },
      // sorter: (a, b) => a.url - b.url,
    },
    {
      title:'Editor',
      dataIndex: 'editor',
      
    },
    {
      title:'推广时间',
      dataIndex: 'promote_date',
      sorter: (a, b) => a.promote_date_sort - b.promote_date_sort,
    },
    {
      title:'Sessions',
      dataIndex: 'sessions',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.sessions - b.sessions,
      align: 'right'
    },
    {
      title:'CPC 上限',
      dataIndex: 'cpc_top',
      customRender: text => {
        return text.toFixed(3)
      },
      align: 'right',
      sorter: (a, b) => a.cpc_top - b.cpc_top,
    },
    {
      title:'第二页的翻页比率',
      dataIndex: 'second_page_rate',
      key: 'second_page_rate',
      customRender: text => {
        return (text*100).toFixed(2)+'%'
      },
      align: 'right',
      sorter: (a, b) => a.second_page_rate - b.second_page_rate,
    },
    {
      title:'宗数',
      dataIndex: 'z_num',
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      align: 'right',
      sorter: (a, b) => a.z_num - b.z_num,
    },
    {
      title:'Detail',
      dataIndex: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    }
  ]

  export default {
    data() {
      return {
        moment,
        period: [],
        userInfo: {},
        api,
        columns,
        dataList: [],
        loading: false,    
        editor: ''  
      }
    },
    methods: {
      rangeChange(date, dateString){
        this.period = dateString  
      },
      submitRangeChange(){
        this.getList()
      },
      getList(){
        this.loading = true
        let params = {
          type: 'group_data',
          period: JSON.stringify(this.period),
          token: this.userInfo.token
        }
        if(this.editor){
          params = {
            type: 'editor_data',
            param: this.editor,
            period: JSON.stringify(this.period),
            token: this.userInfo.token
          }
        }
        this.$post(api.okr.getL44Data, params).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            let data = res.data.data
            // this.dataList = res.data.data

            data.forEach(item => {
              item.promote_date_sort = parseInt(item.promote_date.replace(/[-]/g,''))
            })
            this.dataList = data

          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      goNewCampaign(url){
        const {href} = this.$router.resolve({
          path: '/newcampaign',
          query: {
            post_url: url,
            period: JSON.stringify(this.period)
          },
        })
        window.open(href, '_blank')
      }
    },
    created () {
      this.period = JSON.parse(this.$route.query.period)
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      if(this.$route.query.editor) {
        this.editor = this.$route.query.editor
      }
      
      this.getList()
    }
  };
</script>

<style> 
  .section{
    margin: 24px;
    background-color: #fff;
  }
</style>
