<template>
  <div>
    <div class="ant-page-header-tl">
      <a-range-picker :default-value="dateString" style="margin-right: 8px" @change="onDateChange"/>
      <a-button type="primary" @click="getData">Apply</a-button>
      <a-button
        type="primary"
        @click="synAdw"
        style="margin-left: 10px;background-color: #52c41a;border-color: #52c41a;float:right;"
        >GA4 Adwords 更新</a-button>
        <span style="margin-left: 10px;float:right;"></span>
    </div>
    <div style="margin: 16px 24px -10px;" v-if="showDate"><b>{{showDate.start_date}} ～ {{showDate.end_date}}</b><span style="margin-left: 24px">Compare to</span><b style="margin-left: 24px">{{showDate.start_date_before}} ～ {{showDate.end_date_before}}</b><span style="float:right;">Adwords 上次同步时间：{{adw_update_time}}</span></div>
    <a-row>
      <a-col :span="14">
        <div class="section-wrapper">
          <a-card :bordered="false" title="每日宗数（GA4）">
            <a-table
              :columns="columns1"
              :data-source="dayzumList.cc"
              :rowKey="(record) => record.sessions+record.slug+record.id"
              :pagination="false"
              size="small"
              bordered
              :loading="loading"
              style="margin-bottom: 24px"
              :rowClassName="function(record){
                if(!record.name.includes('Total')){
                  let className = 'color-black';
                  if(record.tag_color == 'g') className = 'color-green';
                  if(record.tag_color == 'r') className = 'color-red';
                  return className;
                }
              }"
              :scroll="{ x: 900}"
            >
              <div
                class="smallbtn"
                slot="action"
                slot-scope="text, record"
                style="padding: 4px 0px"
              >
                <a-button type="link" size="small" @click="goDetail(record)"
                  >Check</a-button
                >
              </div>

              <div slot="name" slot-scope="text, record">
                <a-popover v-if="record.tag_color">
                  <template slot="content">
                    {{ record.tag_txt }}
                  </template>
                  {{ text}}
                </a-popover>
                <span v-else>
                  {{ text}}
                </span>
              </div>
              <div slot="z_num_dod" slot-scope="text, record">
                <a-popover v-if="record.tag_color">
                  <template slot="content">
                    {{ record.tag_txt }}
                  </template>
                  {{ text + '%' }}
                </a-popover>
                <span v-else>
                  {{ text + '%' }}
                </span>
              </div>

            </a-table>

            <a-table
              :columns="columns1"
              :data-source="dayzumList.hh"
              :rowKey="(record) => record.sessions+record.slug+record.id"
              :pagination="false"
              size="small"
              bordered
              :loading="loading"
              style="margin-bottom: 24px"
              :rowClassName="function(record){
                if(!record.name.includes('Total')){
                  let className = 'color-black';
                  if(record.tag_color == 'g') className = 'color-green';
                  if(record.tag_color == 'r') className = 'color-red';
                  return className;
                }
              }"
              :scroll="{ x: 900}"
            >
              <div
                class="smallbtn"
                slot="action"
                slot-scope="text, record"
                style="padding: 4px 0px"
              >
                <a-button type="link" size="small" @click="goDetail(record)"
                  >Check</a-button
                >
              </div>
              <span slot="name" slot-scope="text, record">
                <a-popover v-if="record.tag_color">
                  <template slot="content">
                    {{ record.tag_txt }}
                  </template>
                  {{ text}}
                </a-popover>
                <span v-else>
                  {{ text}}
                </span>
              </span>
              <div slot="z_num_dod" slot-scope="text, record">
                <a-popover v-if="record.tag_color">
                  <template slot="content">
                    {{ record.tag_txt }}
                  </template>
                  {{ text + '%' }}
                </a-popover>
                <span v-else>
                  {{ text + '%' }}
                </span>
              </div>
            </a-table>

            <a-table
              :columns="columns1"
              :data-source="dayzumList.team"
              :rowKey="(record) => record.sessions+record.slug"
              :pagination="false"
              size="small"
              bordered
              :loading="loading"
              :rowClassName="function(record){
                if(!record.name.includes('Total')){
                  let className = 'color-black';
                  if(record.tag_color == 'g') className = 'color-green';
                  if(record.tag_color == 'r') className = 'color-red';
                  return className;
                }
              }"
              :scroll="{ x: 900}"
            >
              <div
                class="smallbtn"
                slot="action"
                slot-scope="text, record"
                style="padding: 4px 0px"
              >
                <a-button type="link" size="small" @click="goDetail(record)"
                  >Check</a-button
                >
              </div>

              <div slot="name" slot-scope="text, record">
                <a-popover v-if="record.tag_color">
                  <template slot="content">
                    {{ record.tag_txt }}
                  </template>
                  {{ text}}
                </a-popover>
                <span v-else>
                  {{ text}}
                </span>
              </div>
              <div slot="z_num_dod" slot-scope="text, record">
                <a-popover v-if="record.tag_color">
                  <template slot="content">
                    {{ record.tag_txt }}
                  </template>
                  {{ text + '%' }}
                </a-popover>
                <span v-else>
                  {{ text + '%' }}
                </span>
              </div>
            </a-table>
          </a-card>
        </div>
      </a-col>
      <a-col :span="10">
        <div class="section-wrapper">
          <a-card :bordered="false" title="季度宗数（GA4）">
            <a-table
              :columns="columns2"
              :data-source="quarterList.cc"
              :rowKey="(record) => record.sessions+record.slug"
              :pagination="false"
              size="small"
              bordered
              :loading="loading2"
              style="margin-bottom: 24px"
            >
              <div
                class="smallbtn"
                slot="action"
                slot-scope="text, record"
                style="padding: 4px 0px"
              >
                <a-button type="primary" size="small" @click="goDetail(record)"
                  >Check</a-button
                >
              </div>
            </a-table>
            <a-table
              :columns="columns2"
              :data-source="quarterList.hh"
              :rowKey="(record) => record.sessions+record.slug"
              :pagination="false"
              size="small"
              bordered
              :loading="loading2"
              style="margin-bottom: 24px"
            >
              <div
                class="smallbtn"
                slot="action"
                slot-scope="text, record"
                style="padding: 4px 0px"
              >
                <a-button type="primary" size="small" @click="goDetail(record)"
                  >Check</a-button
                >
              </div>
            </a-table>
            <a-table
              :columns="columns2"
              :data-source="quarterList.team"
              :rowKey="(record) => record.sessions+record.slug"
              :pagination="false"
              size="small"
              bordered
              :loading="loading2"
            >
              <div
                class="smallbtn"
                slot="action"
                slot-scope="text, record"
                style="padding: 4px 0px"
              >
                <a-button type="primary" size="small" @click="goDetail(record)"
                  >Check</a-button
                >
              </div>
            </a-table>
          </a-card>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
const defaultStartDate = moment().startOf('day').subtract(2, 'days');
const defaultEndDate = moment().startOf('day').subtract(2, 'days');

const columns1 = [
  {
    title: "产品",
    dataIndex: "name",
    align: "left",
    width: 120,
    scopedSlots: { customRender: "name" },
  },
  {
    title: "平台",
    dataIndex: "medium",
    align: "left",
    width: 90
  },
  {
    title: "Session",
    dataIndex: "sessions",
    align: "right",
    width: 90,
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.sessions - b.sessions
      }
    },
    customRender: (text) => {
      return text && text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
   {
    title: "Click/Session",
    dataIndex: "c_s",
    align: "right",
    customRender: (text) => {
      return text + "%";
    },
    width: 130,
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.c_s - b.c_s
      }
    }
  },
  {
    title: "PV",
    dataIndex: "page_views",
    align: "right",
    width: 90,
    customRender: (text) => {
      return text && text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.page_views - b.page_views
      }
    }
  },
  {
    title: "花费",
    dataIndex: "spent",
    align: "right",
    width: 90,
    customRender: (text) => {
      return text && text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.spent - b.spent
      }
    }
  },
  {
    title: "GA4 宗数",
    dataIndex: "z_num4",
    align: "right",
    width: 110,
    customRender: (text) => {
      return text && text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.z_num4 - b.z_num4
      }
    }
  },
  {
    title: "实际宗数",
    dataIndex: "z_num",
    align: "right",
    width: 110,
    customRender: (text) => {
      return text && text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.z_num - b.z_num
      }
    }
  },
  {
    title: "宗数 DOD",
    dataIndex: "z_num_dod",
    align: "right",
    width: 100,
    scopedSlots: { customRender: "z_num_dod" },
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.z_num_dod - b.z_num_dod
      }
    }
  },
  {
    title: "宗数/花费",
    dataIndex: "z_s",
    align: "right",
    customRender: (text) => {
      return text + "%";
    },
    width: 100,
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.z_s - b.z_s
      }
    }
  },
  {
    title: "宗数来源",
    dataIndex: "z_num_from",
    align: "left",
    width: 80,
  },
  {
    title: "By Day",
    scopedSlots: { customRender: "action" },
    align: "center",
    width: 100,
  },
];

const columns2 = [
  {
    title: "产品",
    dataIndex: "name",
    align: "left",
  },
  {
    title: "平台",
    dataIndex: "medium",
    align: "left",
  },
  {
    title: "Session",
    dataIndex: "sessions",
    align: "right",
    customRender: (text) => {
      return text && text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.sessions - b.sessions
      }
    },
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    width: 100,
    customRender: (text) => {
      return text && text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.z_num - b.z_num
      }
    }
  },
  {
    title: "宗数/花费",
    dataIndex: "z_s",
    align: "right",
    customRender: (text) => {
      return text + "%";
    },
    width: 100,
    sorter: (a, b) => {
      if(!a.name.includes("Total")) {
        return a.z_s - b.z_s
      }
    }
  },
];

export default {
  data() {
    return {
      moment,
      userInfo: {},
      columns1,
      columns2,
      adw_update_time: '',
      loading: false,
      loading2: false,
      loading3: false,
      okrList_2: [],
      dayzumList: {},
      quarterList: [],
      defaultStartDate,
      defaultEndDate,
      dateString: [defaultStartDate.format('YYYY-MM-DD'),defaultEndDate.format('YYYY-MM-DD')],
      showDate: {}
    };
  },
  methods: {
    goDetail(record) {
      let slug = record.slug;
      if(record.name == 'Total HH' || record.name == 'Total Team'){
        slug = record.slug + '_new';
      }
      const { href } = this.$router.resolve({
        path: "/zumexpress-detail4",
        query: {
          slug: slug,
          name: record.name,
        },
      });
      window.open(href, "_blank");
    },
    getDailyPerf() {
      this.loading = true;
      this.$post(api.ga4.getAllSitesZumDataNew, {
          token: this.userInfo.token,
          start_date: this.dateString[0],
          end_date: this.dateString[1],
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status === 200) {
            this.showDate = res.data.data.date;
            this.dayzumList = res.data.data.list;
            this.adw_update_time = res.data.data.adw_update_time;
          } else {
            this.$message.error(res.data.message);
          }
        });
    },

    getQuarterPerf() {
      this.loading2 = true;
      this.$post(api.ga4.getQuarterPerfNew, {
          token: this.userInfo.token,
        })
        .then((res) => {
          this.loading2 = false;
          if (res.data.status === 200) {
            this.quarterList = res.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    onDateChange(date, dateString) {
      this.dateString = dateString;
    },
    getData() {
      this.getDailyPerf();
    },

    synAdw() {
      this.loading2 = true;
      this.$post(api.ga4.synAdw, {
        token: this.userInfo.token,
      }).then((res) => {
        this.loading2 = false;
        if (res.data.status === 200) {
          this.$notification.success({
            message: res.data.message,
            duration: 0,
          });
          this.getDailyPerf();
          this.getQuarterPerf();
        } else {
          this.$notification.error({
            message: res.data.message,
            duration: 0,
          });
        }
      });
    },
  },
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.getDailyPerf();
    this.getQuarterPerf();
  },
};
</script>

<style scoped>
.total-item {
  margin-right: 10px;
  margin-left: 10px;
  color: #ddd;
}
/deep/ .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr.color-black > td{
  color: #000;
}
/deep/ .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr.color-green > td{
  color: #0b8043;
  font-weight: bold;
}
/deep/ .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr.color-red > td{
  color: #c53929;
  font-weight: bold;
}
/deep/ .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td{
  padding: 8px;
  color: #000;
}
/deep/ .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td{
  line-height: 32px;
}
/deep/ .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr:last-child > td,
/deep/ .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr:last-child > td{
  font-weight: bold;
}
</style>

