<!-- Copy -->
<template>
  <div>
    <div class="valuepage">
      <h4>{{ platform }} 批量覆盖</h4>
      <!-- Taboola -->
      <div style="" v-if="platform == 'taboola'">
        <!-- Campaign Name -->
        <div class="form-tl"><b>Campaign Name</b></div>
        <div>
          <a-input
            placeholder="{prefix}"
            style="width: 240px"
            v-model="tbl_campaign_prefix"
          />
          - Origin Campaign Name
        </div>

        <!-- Budget -->
        <div class="form-tl"><b>Budget</b></div>
        <div class="input-number">
          <a-input-number
            v-model="tbl_budget"
            style="width: 160px"
            @change="budgetInputChange"
          />
          <span class="input-txt">USD</span>
        </div>
        <a-alert
          v-if="alertShow2"
          message="your Budget > 20"
          type="warning"
          closable
          show-icon
          style="width: 250px;margin-top: 8px"
        />

        <!-- Default CPC -->
        <div class="form-tl"><b>Default CPC</b></div>
        <div class="default-cpc-list">
          <div class="default-cpc-item">
            <span class="default-cpc-title">T1 Android Audience</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_android_mobile_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T1 All Desktop/Tablet</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_all_dt_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title"
              >T1 All Desktop/Tablet Audience</span
            >
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_all_dt_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T1 All Mobile Audience</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_all_mobile_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T1 All Desktop</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_all_desktop_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T1 All Desktop Audience</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_all_desktop_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T2 Android Audience</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_android_mobile_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T1 All Tablet</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_all_tablet_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T1 All Tablet Audience</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_all_tablet_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T2 All Mobile Audience</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_all_mobile_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T2 All Desktop/Tablet</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_all_dt_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title"
              >T2 All Desktop/Tablet Audience</span
            >
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_all_dt_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T1 Android</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_android_mobile_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T2 All Desktop</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_all_desktop_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T2 All Desktop Audience</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_all_desktop_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T1 All Mobile</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_all_mobile_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T2 All Tablet</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_all_tablet_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T2 All Tablet Audience</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_all_tablet_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T2 Android</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_android_mobile_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item"></div>
          <div class="default-cpc-item"></div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T2 All Mobile</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_all_mobile_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>

          <div class="default-cpc-list" style="margin-top:48px;">
            <div class="default-cpc-item">
              <span class="default-cpc-title">Global All Mobile</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g1_all_mobile_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">Global 2 All Mobile</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g2_all_mobile_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">T 1 All OS All Device</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.t1_all_all_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">Global All Mobile Audience</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g1_all_mobile_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title"
                >Global 2 All Mobile Audience</span
              >
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g2_all_mobile_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">T 2 All OS All Device</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.t2_all_all_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">Global Android Mobile</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g1_android_mobile_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">Global 2 Android Mobile</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g2_android_mobile_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
           <div class="default-cpc-item">
              <span class="default-cpc-title">Global 1 All OS All Device</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g1_all_all_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title"
                >Global Android Mobile Audience</span
              >
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g1_android_mobile_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title"
                >Global 2 Android Mobile Audience</span
              >
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g2_android_mobile_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">Global 2 All OS All Device</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g2_all_all_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">Global Desktop/Tablet</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g1_all_dt_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">Global 2 Desktop/Tablet</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g2_all_dt_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item"></div>
            <div class="default-cpc-item">
              <span class="default-cpc-title"
                >Global Desktop/Tablet Audience</span
              >
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g1_all_dt_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title"
                >Global 2 Desktop/Tablet Audience</span
              >
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g2_all_dt_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item"></div>
          </div>

         <!-- 111111111111 -->
          <div class="default-cpc-list" style="margin-top:48px;">
            <div class="default-cpc-item">
              <span class="default-cpc-title">T1 iOs Mobile</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.t1_ios_mobile_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">T1 iOS Mobile Audience</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.t1_ios_mobile_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item"></div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">T2 iOS Mobile Audience</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.t2_ios_mobile_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">T2 iOS Mobile</span
              >
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.t2_ios_mobile_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item"></div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">G1 ios Mobile</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g1_ios_mobile_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">G1 iOS Mobile Audience</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g1_ios_mobile_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item"></div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">G2 iOs Mobile</span
              >
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g2_ios_mobile_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">G2 iOS Mobile Audience</span
              >
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g2_ios_mobile_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
          </div>
          <!-- 2222222222 -->
        </div>
        <a-alert
          v-if="alertShow1"
          message="your Default CPC > 0.07"
          type="warning"
          closable
          show-icon
          style="width: 250px;margin-top: 8px"
        />

        <div style="margin-top: 24px;">
          <a-button
            type="danger"
            size="large"
            style="margin-right: 16px;"
            :loading="tblLoading"
            @click="tblSubmit()"
            >Submit</a-button
          >
        </div>
      </div>

      <!-- Outbrain -->
      <div style="" v-if="platform == 'outbrain'">
        <!-- Campaign Name -->
        <div class="form-tl"><b>Campaign Name</b></div>
        <div>
          <a-input
            placeholder="{prefix}"
            style="width: 240px"
            v-model="ob_campaign_prefix"
          />
          - Origin Campaign Name
        </div>

        <!-- Monthly Budget -->
        <div class="form-tl"><b>Budget</b></div>
        <div class="input-number">
          <a-input-number
            v-model="ob_budget"
            style="width: 220px"
            @change="budgetInputChange"
          />
          <span class="input-txt">USD</span>
        </div>
        <a-alert
          v-if="alertShow2"
          message="your Budget > 20"
          type="warning"
          closable
          show-icon
          style="width: 250px;margin-top: 8px"
        />

        <!-- Default CPC -->
        <div class="form-tl"><b>Default CPC</b></div>
        <div class="default-cpc-list">
          <div class="default-cpc-item">
            <span class="default-cpc-title">T1 Android Audience</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_android_mobile_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T1 All Desktop/Tablet</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_all_dt_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title"
              >T1 All Desktop/Tablet Audience</span
            >
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_all_dt_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T1 All Mobile Audience</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_all_mobile_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T1 All Desktop</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_all_desktop_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T1 All Desktop Audience</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_all_desktop_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T2 Android Audience</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_android_mobile_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T1 All Tablet</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_all_tablet_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T1 All Tablet Audience</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_all_tablet_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T2 All Mobile Audience</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_all_mobile_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T2 All Desktop/Tablet</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_all_dt_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title"
              >T2 All Desktop/Tablet Audience</span
            >
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_all_dt_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T1 Android</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_android_mobile_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T2 All Desktop</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_all_desktop_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T2 All Desktop Audience</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_all_desktop_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T1 All Mobile</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t1_all_mobile_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T2 All Tablet</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_all_tablet_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T2 All Tablet Audience</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_all_tablet_audience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T2 Android</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_android_mobile_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>
          <div class="default-cpc-item"></div>
          <div class="default-cpc-item"></div>
          <div class="default-cpc-item">
            <span class="default-cpc-title">T2 All Mobile</span>
            <div class="input-number">
              <a-input-number
                v-model="tbl_default_cpc.t2_all_mobile_nonaudience"
                class="default-cpc-input"
                step="0.01"
                @change="cpcInputChange"
              />
              <span class="input-txt">USD</span>
            </div>
          </div>

          <div class="default-cpc-list" style="margin-top:48px;">
            <div class="default-cpc-item">
              <span class="default-cpc-title">Global All Mobile</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g1_all_mobile_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">Global 2 All Mobile</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g2_all_mobile_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">T 1 All OS All Device</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.t1_all_all_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">Global All Mobile Audience</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g1_all_mobile_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">Global 2 All Mobile Audience</span
              >
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g2_all_mobile_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">T 2 All OS All Device</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.t2_all_all_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">Global Android Mobile</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g1_android_mobile_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">Global 2 Android Mobile</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g2_android_mobile_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">Global 1 All OS All Device</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g1_all_all_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title"
                >Global Android Mobile Audience</span
              >
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g1_android_mobile_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title"
                >Global 2 Android Mobile Audience</span
              >
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g2_android_mobile_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">Global 2 All OS All Device</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g2_all_all_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">Global Desktop/Tablet</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g1_all_dt_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">Global 2 Desktop/Tablet</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g2_all_dt_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item"></div>
            <div class="default-cpc-item">
              <span class="default-cpc-title"
                >Global Desktop/Tablet Audience</span
              >
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g1_all_dt_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title"
                >Global 2 Desktop/Tablet Audience</span
              >
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g2_all_dt_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item"></div>
          </div>

<!-- 33333333 -->
          <div class="default-cpc-list" style="margin-top:48px;">
            <div class="default-cpc-item">
              <span class="default-cpc-title">T1 iOs Mobile</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.t1_ios_mobile_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">T1 iOS Mobile Audience</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.t1_ios_mobile_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item"></div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">T2 iOS Mobile Audience</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.t2_ios_mobile_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">T2 iOS Mobile</span
              >
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.t2_ios_mobile_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item"></div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">G1 ios Mobile</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g1_ios_mobile_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">G1 iOS Mobile Audience</span>
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g1_ios_mobile_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item"></div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">G2 iOs Mobile</span
              >
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g2_ios_mobile_nonaudience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
            <div class="default-cpc-item">
              <span class="default-cpc-title">G2 iOS Mobile Audience</span
              >
              <div class="input-number">
                <a-input-number
                  v-model="tbl_default_cpc.g2_ios_mobile_audience"
                  class="default-cpc-input"
                  step="0.01"
                  @change="cpcInputChange"
                />
                <span class="input-txt">USD</span>
              </div>
            </div>
          </div>
<!-- 44444444 -->
        </div>
        <a-alert
          v-if="alertShow1"
          message="your Default CPC > 0.07"
          type="warning"
          closable
          show-icon
          style="width: 250px;margin-top: 8px"
        />

        <div style="margin-top: 24px;">
          <a-button
            type="danger"
            size="large"
            style="margin-right: 16px;"
            :loading="obLoading"
            @click="obSubmit()"
            >Submit</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
export default {
  data() {
    return {
      tbl_default_cpc: {
        t1_android_mobile_audience: 0,
        t1_all_dt_nonaudience: 0,
        t1_all_dt_audience: 0,
        t1_all_mobile_audience: 0,
        t1_all_desktop_nonaudience: 0,
        t1_all_desktop_audience: 0,
        t2_android_mobile_audience: 0,
        t1_all_tablet_nonaudience: 0,
        t1_all_tablet_audience: 0,
        t2_all_mobile_audience: 0,
        t2_all_dt_nonaudience: 0,
        t2_all_dt_audience: 0,
        t1_android_mobile_nonaudience: 0,
        t2_all_desktop_nonaudience: 0,
        t2_all_desktop_audience: 0,
        t1_all_mobile_nonaudience: 0,
        t2_all_tablet_nonaudience: 0,
        t2_all_tablet_audience: 0,
        t2_android_mobile_nonaudience: 0,
        t2_all_mobile_nonaudience: 0,
        g1_all_mobile_nonaudience: 0,
        g2_all_mobile_nonaudience: 0,
        g1_all_mobile_audience: 0,
        g2_all_mobile_audience: 0,
        g1_android_mobile_nonaudience: 0,
        g2_android_mobile_nonaudience: 0,
        g1_android_mobile_audience: 0,
        g2_android_mobile_audience: 0,
        g1_all_dt_nonaudience: 0,
        g2_all_dt_nonaudience: 0,
        g1_all_dt_audience: 0,
        g2_all_dt_audience: 0,
        t1_ios_mobile_audience: 0,
        t2_ios_mobile_audience: 0,
        t1_ios_mobile_nonaudience: 0,
        t2_ios_mobile_nonaudience: 0,
        t2_ios_tablet_nonaudience: 0,
        t2_ios_tablet_audience: 0,
        g1_ios_mobile_nonaudience: 0,
        g1_ios_mobile_audience: 0,
        g2_ios_mobile_nonaudience: 0,
        g2_ios_mobile_audience: 0,
        g1_all_all_nonaudience: 0,
        g2_all_all_nonaudience: 0,
        t1_all_all_nonaudience: 0,
        t2_all_all_nonaudience: 0,

      },
      userInfo: [],
      platform: "taboola",
      campaign_ids: [],
      loading: false,
      tblLoading: false,
      obLoading: false,
      tbl_fields: [],
      ob_fields: [],
      platformList: [
        {
          id: 2,
          name: "taboola",
        },
        {
          id: 3,
          name: "outbrain",
        },
      ],
      tbl_budget: 20,
      fetching: false,
      tbl_campaign_prefix: '',
      ob_campaign_prefix: '',
      ob_default_cpc: 0,
      ob_budget: 20,
      alertShow1: false,
      alertShow2: false,
    };
  },
  methods: {
    // outbrain
    obSubmit() {
      let params = {
        default_cpc: this.tbl_default_cpc,
        platform: this.platform,
        budget: this.ob_budget,
        name_prefix: this.ob_campaign_prefix,
        campaign_ids: this.campaign_ids,
      };
      this.obLoading = true;
      this.$post(api.update.batchRelaunchCpn, {
        token: this.userInfo.token,
        params: params,
      }).then((res) => {
        this.obLoading = false;
        if (res.data.status === 200) {
          this.$message.success(res.data.message);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // taboola
    tblSubmit() {
      let params = {
        default_cpc: this.tbl_default_cpc,
        platform: this.platform,
        budget: this.tbl_budget,
        name_prefix: this.tbl_campaign_prefix,
        campaign_ids: this.campaign_ids,
      };
      this.tblLoading = true;
      this.$post(api.update.batchRelaunchCpn, {
        token: this.userInfo.token,
        params: params,
      }).then((res) => {
        this.tblLoading = false;
        if (res.data.status === 200) {
          this.$message.success(res.data.message);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getFixedFields() {
      this.$post(api.update.getCpnDefaultFields, {
        token: this.userInfo.token,
        campaign_ids: this.campaign_ids,
        platform: this.platform,
      }).then((res) => {
        if (res.data.status === 200) {
          for (var key in res.data.data) {
            this.tbl_default_cpc[key] = res.data.data[key];
          }
        }
      });
    },
    cpcInputChange(value) {
      if (value > 0.07) {
        this.alertShow1 = true;
      } else {
        this.alertShow1 = false;
      }
    },
    budgetInputChange(value) {
      if (value > 20) {
        this.alertShow2 = true;
      } else {
        this.alertShow2 = false;
      }
    },
  },
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    if (this.$route.query.platform) {
      this.campaign_ids = JSON.parse(this.$route.query.campaign_ids);
      this.platform = this.$route.query.platform.toLowerCase();
    }
    this.getFixedFields();
  },
};
</script>
<style>
.valuepage {
  background-color: #fff;
  margin: 8px;
  padding: 16px;
}
.valueoverflow {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.site-line {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.site-item {
  padding-right: 16px;
  width: 40px;
}
.default-cpc-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.default-cpc-item {
  display: flex;
  align-items: center;
  width: 30%;
  margin-bottom: 8px;
}
.default-cpc-title {
  width: 230px;
}
.default-cpc-input {
  width: 120px;
}
</style>
