<template>
  <div>
    <div class="section">
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="New">
          <div class="form-tl">是否显示隐藏文章：<a-switch @change="onShow" />
          </div>
          <a-table 
            @change="handleTableChange"
            :columns="columns" 
            :data-source="newPost" 
            :rowKey="record => record.landing_page"
            :pagination="{pageSize: pageSize}"
            size="middle"
            :loading="loading"
            bordered
          >
            <div class="smallbtn" slot="action" slot-scope="text, record">
              <a-button size="small" style="margin-right: 10px;" @click="toHide(record.landing_page)">隐藏</a-button>
              <a-button size="small" style="margin-right: 10px;" @click="noPromotion(record.landing_page)">不推广</a-button>
              <a-button size="small" type="primary" style="margin-right: 10px;" @click="goCreated(record.landing_page)">推广</a-button>
              <a-button size="small" type="primary" style="background-color: #52c41a;border-color: #52c41a" @click="Promoted(record.landing_page)">已推广</a-button>
            </div>  
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="Outbrain only" force-render>
          <a-table 
            :columns="columns2" 
            :data-source="noCoveredPost" 
            :rowKey="record => record.url"
            :pagination="{pageSize: pageSize}"
            size="middle"
            :loading="loading"
            bordered
          >
            <div class="smallbtn" slot="action" slot-scope="text, record">
              <!-- <a-button size="small" style="margin-right: 10px;">不推广</a-button> -->
              <a-button type="primary" size="small" @click="goCreated(record.url)">推广</a-button>
            </div>    
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="3" tab="Creative Library">
          <div style="margin-bottom: 16px;">
            <a-select
              defaultValue="taboola"
              style="width: 120px;"
              @change="handlePlatformChange"
            >
              <a-select-option
                v-for="(item, index) in platformList"
                :key="index"
                :value="item.name"
                >{{ item.name }}</a-select-option
              >
            </a-select>
            <span style="margin: 0 16px;color: #ccc;">|</span>
            <a-input placeholder="Content" style="width: 200px" v-model="content"/>
            <a-button style="margin: 0 16px;" @click="getCreative" type="primary">
              Apply
            </a-button>
            <a-button style="width: 120px;float: right" @click="goAddContent()" type="primary">
              添加
            </a-button>
          </div>

          <a-table 
            :columns="columns3" 
            :data-source="creativeList" 
            :rowKey="record => record.id"
            :pagination="{pageSize: 100}"
            size="small"
            :loading="loading"
            bordered
          >
            <!-- <div slot="titles" slot-scope="text" style="width: 380px;overflow: hidden;">{{text}}</div> -->
            <div slot="image" slot-scope="text" style="width: 500px;overflow:hidden;"><img :src="text" style="width: 60px;height: 60px;margin-right: 8px" alt="">{{text}}</div>

            <div class="smallbtn" slot="action" slot-scope="text, record">
              <a-button size="small" type="primary" style="margin-right: 10px;" @click="goAddContent(record)">编辑</a-button>

              <!-- <a-button size="small" type="dashed" @click="showDeleteConfirm(record.id)">删除</a-button> -->
              <a-popconfirm
                  title="确定要删除吗"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="deleteCreative(record.id)"
                >
                  <a-button size="small" type="dashed">删除</a-button>
                </a-popconfirm>
            </div>  
            

          </a-table>

        </a-tab-pane>
      </a-tabs>
      </div>
  </div>
</template>
<script>
  import api from '@/common/api.js'

  const columns2 = [
    {
      title:'URL',
      dataIndex: 'url',
      sorter: (a, b) => a.url.localeCompare(b.url)
    },
    {
      title:'PV',
      dataIndex: 'pv',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      align: 'right',
      sorter: (a, b) => a.pv - b.pv,
    },
    {
      title:'Detail',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
      align: 'center'
    }
  ]

  const columns3 = [
    {
      title:'Title',
      dataIndex: 'title',
      sorter: (a,b) => a.title.localeCompare(b.title),
      scopedSlots: { customRender: 'titles' },
      // width: 400
    },
    {
      title:'Image',
      dataIndex: 'image',
      scopedSlots: { customRender: 'image' },
      width: 320
    },
    {
      title:'Keyword',
      dataIndex: 'keyword',
      align: 'center',
      sorter: (a, b) => a.keyword.localeCompare(b.keyword)
    },
    {
      title:'Topic',
      dataIndex: 'topic',
      align: 'center',
      sorter: (a, b) => a.topic.localeCompare(b.topic)
    },
    {
      title:'Date Added',
      dataIndex: 'create_time',
      align: 'center',
      sorter: (a, b) => a.create_time.localeCompare(b.create_time)
    },
    {
      title:'Operate',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
      align: 'center'
    }
  ]

  export default {
    data() {
      return {
        show_hide: 0,
        pageSize: 50,
        userInfo: {},
        // activeKey: panes[0].key,
        // columns,
        columns2,
        columns3,
        newPost: [],
        noCoveredPost: [],
        creativeList: [],
        loading: false, 
        filteredInfo: null,  
        status: 0,
        platform: 'taboola',
        content: '',
        platformList: [
          {
            id: 1,
            name: 'taboola'
          },
          {
            id: 2,
            name: 'outbrain'
          }
        ],
      }
    },
    computed: {
      columns() {
        let { filteredInfo } = this;
        filteredInfo = filteredInfo || {};
        const columns = [
          {
            title:'Landing Page',
            dataIndex: 'landing_page',
            sorter: (a, b) => a.landing_page.localeCompare(b.landing_page)
          },
          {
            title: 'Promote Status',
            dataIndex: 'promote_state',
            align: 'center',
            filters: [
              {
                text: '已推广',
                value: '已推广',
              },
              {
                text: '不推广',
                value: '不推广',
              },
              {
                text: '待推广',
                value: '待推广',
              }
            ],
            filteredValue: filteredInfo.promote_state || null,
            onFilter: (value, record) => record.promote_state.indexOf(value) === 0,
          },
          {
            title:'Online Time',
            dataIndex: 'online_time',
            align: 'right',
            sorter: (a, b) => a.online_time_sort - b.online_time_sort,
          },
          {
            title:'Days',
            dataIndex: 'days',
            align: 'right',
            sorter: (a, b) => a.days - b.days,
          },
          {
            title:'is Hide',
            dataIndex: 'is_hide',
            align: 'right',
            sorter: (a, b) => a.is_hide - b.is_hide,
            className: this.show_hide == 1 ? 'show' : 'hidden',
          },
          {
            title:'Detail',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
            align: 'center'
          }
        ]
        return columns;
      }
    },
    methods: {
      // 是否隐藏Post
      toHide(landing_page) {
        this.$post(api.update.hideLandingPage, {
            token: this.userInfo.token,
            url: landing_page,
          }).then(res => {
            this.loading = false
            if (res.data.status === 200) {
              this.$message.success(res.data.message)
              const newPost = this.newPost;
              this.newPost = newPost.filter(item => item.landing_page !== landing_page)
            } else {
              this.$message.error(res.data.message)
            }
          });
      },
      // 是否展示隐藏文章
      onShow() {
        if (this.show_hide === 0) {
          this.show_hide = 1
        } else {
          this.show_hide = 0
        }
        this.getList();
      },
      handlePlatformChange(e){
        this.platform = e
        console.log(this.platform)
        // this.getList()
      },
      noPromotion(landing_page){
        this.$post(api.update.changePromoteStatus, {
          landing_page: landing_page,
          status: 2,
          token: this.userInfo.token
        }).then(res => {
          // this.loading = false
          if (res.data.status === 200) {
            console.log(res)
            this.newPost.forEach((item) => {
              if(item.landing_page == landing_page){
                item.promote_state = '不推广'
              }
              // item.online_time_sort = parseInt(item.online_time.replace(/[-]/g,''))
            })
            // this.newPost = res.data.data.new_post
            // this.noCoveredPost = res.data.data.no_covered_post

            this.$message.success('修改成功，不推广')
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      handleTableChange(pagination,filters) {
        this.filteredInfo = filters;
      },
      goCreated(url){
        const {href} = this.$router.resolve({
          path: '/created',
          query: {
            url: url
          },
        })
        window.open(href, '_blank')
      },
      Promoted(landing_page){
        this.$post(api.update.changePromoteStatus, {
          landing_page: landing_page,
          status: 1,
          token: this.userInfo.token
        }).then(res => {
          // this.loading = false
          console.log(res.data.status);
          if (res.data.status === 200) {
            console.log(res)
            this.$message.success('已推广')
            const newPost = this.newPost;
            this.newPost = newPost.filter(item => item.landing_page !== landing_page)
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      callback(key) {
        console.log(key);
        // if(key == '3'){
        //   this.getCreative()
        // }
      },
      getCreative(){
        this.loading = true
        this.$post(api.auto.getCreativeList, {
          token: this.userInfo.token,
          platform: this.platform,
          content: this.content
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.creativeList = res.data.data
            console.log(this.creativeList)
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      goAddContent(url){
        if(url){
          const {href} = this.$router.resolve({
            path: '/AddCampaign',
            query: {
              id: url.id,
              platform: this.platform,
              title: url.title,
              keyword: url.keyword,
              topic: url.topic,
              image: url.image
            }
          })
          window.open(href, '_blank')
        }else{
          const {href} = this.$router.resolve({
            path: '/AddCampaign'
          })
          window.open(href, '_blank')
        }
      },
      // showDeleteConfirm(id){
      //   this.$confirm({
      //     title: 'Are you sure delete this task?',
      //     okText: 'Yes',
      //     okType: 'primary',
      //     cancelText: 'No',
      //     onOk() {
      //       this.deleteCreative(id)
      //     },
      //     onCancel() {
      //       console.log('Cancel');
      //     },
      //   })
      // },
      deleteCreative(id){
        this.loading = true
        this.$post(api.auto.deleteCreative, {
          token: this.userInfo.token,
          creative_id: id
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.$message.success('删除成功');
            const creativeList = this.creativeList;
            this.creativeList = creativeList.filter(item => item.id !== id)
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      hide() {
        console.log("quxiao");
      },
      getList(){
        this.loading = true
        this.$post(api.update.getToCreatePost, {
          token: this.userInfo.token,
          show_hide: this.show_hide,
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.newPost = res.data.data.new_post
            this.newPost.forEach(item => {
              item.online_time_sort = parseInt(item.online_time.replace(/[-]/g,''))
            })
            this.noCoveredPost = res.data.data.no_covered_post
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
    },
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.getList()
      this.getCreative()
    }
  };
</script>

<style> 
  .cardwrapper{
    margin: 24px;
    /* background-color: #fff; */
  }
  .smallbtn .ant-btn-sm{
    font-size: 12px;
  }
  .hidden {
    display: none;
  }
</style>
