<template>
  <div class="all-product">
    <div class="ant-page-header-tl">
      <span style="margin-right:5px">Date:</span>
      <a-range-picker :default-value="dateString" @change="onDateChange" />
      <a-button
        class="btn-right"
        type="primary"
        style="margin-left:16px;"
        @click="onCommit"
        >Apply</a-button
      >
      <a-button
        class="btn-right"
        type="primary"
        icon="download"
        style="float: right"
        @click="handleExports"
        >Export</a-button
      >
    </div>

    <!-- table -->
    <div class="section">
      <a-table
        style="1px solid #333 !important;"
        :columns="columns"
        :data-source="dataList"
        :pagination="false"
        :rowKey="(record) => record.team + record.site"
        size="middle"
        bordered
        :loading="loading"
        defaultExpandAllRows
        v-if="dataList.length>0"
      >
        <span
          style="text-decoration: underline;cursor: pointer;"
          slot="sitePerfByDate"
          slot-scope="text, record"
          @click="sitePerfByDate(record)"
        >{{text}}</span>
      </a-table>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";

const defaultStartDate = moment()
  .startOf("day")
  .subtract(31, "days");
const defaultEndDate = moment()
  .startOf("day")
  .subtract(2, "days");

const columns = [
  {
    title: "Site",
    dataIndex: "full_name",
    scopedSlots: { customRender: "sitePerfByDate" },
    sorter: (a, b) => {
      return a.full_name.localeCompare(b.full_name);
    },
  },
  {
    title: "Promote",
    children: [
      {
        title: "Session",
        dataIndex: "promote_sessions",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.promote_sessions - b.promote_sessions;
        },
      },
      {
        title: "PV",
        dataIndex: "promote_page_views",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.promote_page_views - b.promote_page_views;
        },
      },
      {
        title: "P/S",
        dataIndex: "promote_page_per_session",
        align: "right",
        customRender: (text) => {
          return parseFloat(text.toString())
            .toFixed(1)
            .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.promote_page_per_session - b.promote_page_per_session;
        },
      },
      {
        title: "CPC上限",
        dataIndex: "promote_cpc_top",
        align: "right",
        customRender: (text) => {
          return parseFloat(text.toString())
            .toFixed(3)
            .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.total_cpc_top - b.total_cpc_top;
        },
      },
      {
        title: "宗数",
        dataIndex: "promote_z_num",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.promote_z_num - b.promote_z_num;
        },
      },
    ],
  },
  {
    title: "Push",
    children: [
      {
        title: "Session",
        dataIndex: "push_sessions",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.push_sessions - b.push_sessions;
        },
      },
      {
        title: "PV",
        dataIndex: "push_page_views",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.push_page_views - b.push_page_views;
        },
      },
      {
        title: "P/S",
        dataIndex: "push_page_per_session",
        align: "right",
        customRender: (text) => {
          return parseFloat(text.toString())
            .toFixed(1)
            .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.push_page_per_session - b.push_page_per_session;
        },
      },
      {
        title: "CPC上限",
        dataIndex: "push_cpc_top",
        align: "right",
        customRender: (text) => {
          return parseFloat(text.toString())
            .toFixed(3)
            .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.push_cpc_top - b.push_cpc_top;
        },
      },
      {
        title: "宗数",
        dataIndex: "push_z_num",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.push_z_num - b.push_z_num;
        },
      },
    ],
  },
  {
    title: "Others",
    children: [
      {
        title: "Session",
        dataIndex: "other_sessions",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.other_sessions - b.other_sessions;
        },
      },
      {
        title: "PV",
        dataIndex: "other_page_views",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.other_page_views - b.other_page_views;
        },
      },
      {
        title: "P/S",
        dataIndex: "other_page_per_session",
        align: "right",
        customRender: (text) => {
          return parseFloat(text.toString())
            .toFixed(1)
            .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.other_page_per_session - b.other_page_per_session;
        },
      },
      {
        title: "CPC上限",
        dataIndex: "other_cpc_top",
        align: "right",
        customRender: (text) => {
          return parseFloat(text.toString())
            .toFixed(3)
            .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.other_cpc_top - b.other_cpc_top;
        },
      },
      {
        title: "宗数",
        dataIndex: "other_z_num",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.other_z_num - b.other_z_num;
        },
      },
    ],
  },
  {
    title: "Total",
    children: [
      {
        title: "Session",
        dataIndex: "total_sessions",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.total_sessions - b.total_sessions;
        },
      },
      {
        title: "PV",
        dataIndex: "total_page_views",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.total_page_views - b.total_page_views;
        },
      },
      {
        title: "P/S",
        dataIndex: "total_page_per_session",
        align: "right",
        customRender: (text) => {
          return parseFloat(text.toString())
            .toFixed(1)
            .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.total_page_per_session - b.total_page_per_session;
        },
      },
      {
        title: "CPC上限",
        dataIndex: "total_cpc_top",
        align: "right",
        customRender: (text) => {
          return parseFloat(text.toString())
            .toFixed(3)
            .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.total_cpc_top - b.total_cpc_top;
        },
      },
      {
        title: "宗数",
        dataIndex: "total_z_num",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.total_z_num - b.total_z_num;
        },
      },
    ],
  },
];

export default {
  data() {
    return {
      userInfo: {},
      columns,
      dataList: [],
      loading: false,
      defaultStartDate,
      defaultEndDate,
      dateString: [
        moment(defaultStartDate, "YYYY-MM-DD"),
        moment(defaultEndDate, "YYYY-MM-DD"),
      ],
      excelDataList: [],
      medium: "by_site",
    };
  },
  // 定义方法
  methods: {
    // 修改时间
    onDateChange(dateString) {
      this.dateString = dateString;
    },
    onCommit() {
      this.getList();
    },

    // 导出
    handleExports() {
      const { columns, excelDataList } = this;
      this.loading = true;
      let newlist = [];
      require.ensure([], () => {
        const { export_json_to_excel } = require("../../excel/Export2ExcelMulti"); //---require 括号里面是相对路径其实是引用  Export2Excel.js
        const tHeader = []; //----tHeader 数组里面放的是字段的对应名
        const multitHeader = [
          'Site',
          'Promote',
          '',
          '',
          '',
          '',
          'Push',
          '',
          '',
          '',
          '',
          'Others',
          '',
          '',
          '',
          '',
          'Total',
          '',
          '',
          '',
          '',
        ];
        const merges = [
          "A1:A2",
          "B1:F1",
          "G1:K1",
          "L1:P1",
          "Q1:U1"
        ];

        const filterVal = []; //-----filterVal  字段对应的值
        columns &&
          columns.map((data) => {
            console.log(data);
            if(data.children){
              data.children.forEach(child => {
                tHeader.push(child.title)
                filterVal.push(child.dataIndex)
              })
            }else{
              tHeader.push(data.title)
              filterVal.push(data.dataIndex)
            }
          });
        this.title =
          "所有产品表现(by Site)_" +
          moment()
            .locale("zh-cn")
            .format("YYMMDDHHmm");
        newlist = excelDataList;

        console.log(filterVal);
        const data = this.formatJson(filterVal, newlist);

        console.log(data);
        export_json_to_excel(multitHeader, tHeader, data, this.title, merges);
        this.loading = false;
        this.$message.success("导出成功");
      });
    },
    // 格式化json(配合上面一起)
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    // 跳转到byDate页面
    sitePerfByDate(param) {
      const { href } = this.$router.resolve({
        path: "/SitesPerfByDate",
        query: {
          site: param.site,
          full_name: param.full_name,
          start_date: this.dateString[0].format("YYYY-MM-DD"),
          end_date: this.dateString[1].format("YYYY-MM-DD"),
        },
      });
      window.open(href, "_blank");
    },

    // 获取数据列表
    getList() {
      this.loading = true;
      this.$post(api.okr.allSitesPerf, {
        token: this.userInfo.token,
        medium: this.medium,
        start_date: this.dateString[0].format("YYYY-MM-DD"),
        end_date: this.dateString[1].format("YYYY-MM-DD"),
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataList = res.data.data;
          let excelDataList = [];
          this.dataList.forEach(item => {
            excelDataList.push(item)
            if(item.children.length){
              item.children.forEach(child => {
                excelDataList.push(child)
              })
            }
          })
          this.excelDataList = excelDataList;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
  // 页面初始化
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.getList();
  },
};
</script>
<style scoped>
  .all-product >>> .ant-table-tbody > tr.ant-table-row-level-0 {
    background: #eeeeee !important;
  }
  .all-product >>> .ant-table-tbody > tr.ant-table-row-level-0 > td:nth-child(5n+6) {
    background: #eeeeee !important;
  }
  .all-product >>> .ant-table-tbody {
    color:#000;
  }
  .all-product >>> .ant-table-bordered .ant-table-body > table {
    border: 1px solid #333;
    /* border-bottom: 1px solid #333; */
    /* border-bottom-left-radius: 8px; */
    border-radius: 4px;
  }
  .all-product >>> .ant-table-thead > tr:first-child > th {
    border-top:none;
  }
  .all-product >>> .ant-table-thead > tr > th:last-child {
    border-right: none;
  }
  .all-product >>> .ant-table-tbody > tr > td:last-child {
    border-right: none;
  }
</style>
<style>
  .all-product {font-family: 'Roboto'}
  .all-product .ant-table-thead > tr > th:nth-child(5),
  .all-product .ant-table-thead > tr:nth-child(2) > th:nth-child(-n+20) .ant-table-header-column .ant-table-column-sorters::before
  {
    background: #e6d2db;
  }

  .all-product .ant-table-thead > tr > th:nth-child(4),
  .all-product .ant-table-thead > tr:nth-child(2) > th:nth-child(-n+15) .ant-table-header-column .ant-table-column-sorters::before
  {
    background: #f8e6d0;
  }

  .all-product .ant-table-thead > tr > th:nth-child(3),
  .all-product .ant-table-thead > tr:nth-child(2) > th:nth-child(-n+10) .ant-table-header-column .ant-table-column-sorters::before
  {
    background: #dce9d5;
  }

  .all-product .ant-table-thead > tr > th:nth-child(2),
  .all-product .ant-table-thead > tr:nth-child(2) > th:nth-child(-n+5) .ant-table-header-column .ant-table-column-sorters::before
  {
    background: #ccd9f5;
  }
  .all-product .ant-table-tbody > tr > td:nth-child(5n+6) {
    font-weight: bold;
  }
  .all-product .ant-table-tbody > tr.ant-table-row-level-1 > td:nth-child(6) {
    background: #ccd9f5;
  }
  .all-product .ant-table-tbody > tr.ant-table-row-level-1 > td:nth-child(11) {
    background: #dce9d5;
  }
  .all-product .ant-table-tbody > tr.ant-table-row-level-1 > td:nth-child(16) {
    background: #f8e6d0;
  }
  .all-product .ant-table-tbody > tr.ant-table-row-level-1 > td:nth-child(21) {
    background: #e6d2db;
  }

  .all-product .ant-table-tbody > tr > td:nth-child(1) > span {
    color:#0000EE;
  }
  .all-product .ant-table-thead > tr >th,
  .all-product .ant-table-tbody > tr > td {
    border-top:1px solid #333;
    border-right:1px solid #333;
  }
  .all-product .ant-table-row-level-1:last-child > td:first-child {
    border-bottom-left-radius: 4px;
  }
  .all-product .ant-table-row-level-1:last-child > td:last-child {
    border-bottom-right-radius: 4px;
  }
  .all-product .ant-table-row-level-0:last-child > td:first-child {
    border-bottom-left-radius: 4px;
  }
  .all-product .ant-table-row-level-0:last-child > td:last-child {
    border-bottom-right-radius: 4px;
  }
</style>

