<template>
  <div>
    <!-- date -->
    <div class="ant-page-header-tl">
      <a-select
        defaultValue="taboola"
        style="width: 120px; margin-right: 16px"
        @change="handlePlatformChange"
      >
        <a-select-option
          v-for="(item, index) in platformList"
          :key="index"
          :value="item.name"
          >{{ item.name }}</a-select-option
        >
      </a-select>
    </div>
    <!-- table -->
    <div class="section">
        <h3 class="creative-tl" style="margin-top: 24px;">spent≤ 5</h3>
        <a-table 
          :columns="up_columns" 
          :data-source="up_limit_list" 
          :rowKey="record => record.campaign_id"
          :pagination="false" 
          size="small"
          bordered
          :loading="loading"
          :rowSelection="{selectedRowKeys: campaign_ids, onChange: onSelectChange, onSelect: onSelectValue}"
          class="data-table"
        >
          <div style="text-decoration: underline;cursor: pointer;" slot="toline" slot-scope="text, record" @click="toLine(record.campaign_id,start_date,end_date)">
            <a-popover title="">
              <template slot="content">
                {{text}} 
              </template>
              {{text}} 
            </a-popover>
            <!-- {{text}} -->
          </div>
          <div class="smallbtn" slot="action" slot-scope="text, record" style="padding: 4px 0px">
            <a-button type="primary" size="small" @click="pause(record.campaign_id)">Pause</a-button>
          </div>
        </a-table>
        <a-button @click="pauseAll" type="primary" style="margin-top: 16px;margin-right: 16px;">Pause All</a-button>
        <a-button v-if="up_limit_list.length < 15" @click="showMoreSpent" type="primary" style="margin-top: 16px;">Show More Spent≤5</a-button>

        <h3 class="creative-tl" style="margin-top: 24px;">spent > 5，且 宗数/花费 &lt; -50%</h3>
        <a-table
          :columns="down_columns" 
          :data-source="down_list" 
          :rowKey="record => record.campaign_id"
          :pagination="false" 
          size="small"
          bordered
          :loading="loading"
          :rowSelection="{selectedRowKeys: campaign_ids, onChange: onSelectChange, onSelect: onSelectValue}"
          class="data-table"
        >
            <div style="text-decoration: underline;cursor: pointer;" slot="toline" slot-scope="text, record" @click="toLine(record.campaign_id,start_date,end_date)">
              <a-popover title="">
                <template slot="content">
                  {{text}} 
                </template>
                {{text}} 
              </a-popover>
              <!-- {{text}} -->
            </div>
            <div class="smallbtn" slot="action" slot-scope="text, record" style="padding: 4px 0px">
              <a-button type="primary" size="small" @click="pause(record.campaign_id)">Pause</a-button>
            </div>
        </a-table>
        <a-button @click="pauseAll" type="primary" style="margin-top: 16px;">Pause All</a-button>
    </div>
  </div>
</template>
<script>
  import api from '@/common/api.js'
 
  const up_columns = [
    {
      title:'Campaign ID',
      dataIndex: 'campaign_id',
      ellipsis: true,
      sorter: (a, b) => {
        return a.campaign_id.localeCompare(b.campaign_id)
      }
    },
    {
      title:'Campaign Name',
      dataIndex: 'campaign_name',
      ellipsis: true,
      width: 500,
      sorter: (a, b) => {
        return a.campaign_name.localeCompare(b.campaign_name)
      },
      scopedSlots: { customRender: 'toline' },
    },
    {
      title:'Add Date',
      dataIndex: 'create_time',
      sorter: (a, b) => {
        return a.start_date.localeCompare(b.create_time)
      }
    },
    {
      title:'Start Date',
      dataIndex: 'start_date',
      sorter: (a, b) => {
        return a.start_date.localeCompare(b.start_date)
      }
    },
    {
      title:'Session ',
      dataIndex: 'sessions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.sessions - b.sessions
      }
    },
    {
      title:'CPC上限',
      dataIndex: 'cpc_top',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.cpc_top - b.cpc_top
      }
    },
    {
      title:'spent',
      dataIndex: 'spent',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.spent - b.spent
      }
    },
    {
      title:'系数',
      dataIndex: 'x_num',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.x_num - b.x_num
      }
    },
    {
      title:'宗数',
      dataIndex: 'z_num',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.z_num - b.z_num
      }
    },
    {
      title:'宗数/花费',
      dataIndex: 'roi',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {
        return a.roi - b.roi
      }
    },
    {
      title: "Details",
      scopedSlots: {customRender: "action"},
      align: "center",
      width: 100,
    }
  ]

  const down_columns = [
    {
      title:'Campaign ID',
      dataIndex: 'campaign_id',
      ellipsis: true,
      sorter: (a, b) => {
        return a.campaign_id.localeCompare(b.campaign_id)
      }
    },
    {
      title:'Campaign Name',
      dataIndex: 'campaign_name',
      ellipsis: true,
      width: 500,
      sorter: (a, b) => {
        return a.campaign_name.localeCompare(b.campaign_name)
      },
      scopedSlots: { customRender: 'toline' },
    },
    {
      title:'Add Date',
      dataIndex: 'create_time',
      sorter: (a, b) => {
        return a.start_date.localeCompare(b.create_time)
      }
    },
    {
      title:'Start Date',
      dataIndex: 'start_date',
      sorter: (a, b) => {
        return a.start_date.localeCompare(b.start_date)
      }
    },
    {
      title:'Session ',
      dataIndex: 'sessions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.sessions - b.sessions
      }
    },
    {
      title:'CPC上限',
      dataIndex: 'cpc_top',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.cpc_top - b.cpc_top
      }
    },
    {
      title:'spent',
      dataIndex: 'spent',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.spent - b.spent
      }
    },
    {
      title:'系数',
      dataIndex: 'x_num',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.x_num - b.x_num
      }
    },
    {
      title:'宗数',
      dataIndex: 'z_num',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.z_num - b.z_num
      }
    },
    {
      title:'宗数/花费',
      dataIndex: 'roi',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {
        return a.roi - b.roi
      }
    },
    {
      title: "Details",
      scopedSlots: {customRender: "action"},
      align: "center",
      width: 100,
    }
  ]

  export default {
    data() {
      return {
        campaign_id: [],
        campaign_ids: [],
        up_list: [],
        up_limit_list: [],
        down_list: [],
        platformList: [
        {
          id: 2,
          name: "taboola",
        },
        {
          id: 3,
          name: "outbrain",
        },
      ],
        userInfo: {},
        up_columns,
        down_columns,
        dataList: [],
        loading: false,
        start_date: '',
        end_date: ''
      }
    },
    // 定义方法
    methods: {
      onSelectChange(selectedRowKeys) {
        this.campaign_ids = selectedRowKeys;
      },
      onSelectValue(e){
        console.log(e)
      },
      showMoreSpent(){
        this.up_limit_list = this.up_list
      },
      // 获取数据列表
      getList(){
        this.up_list = []
        this.up_limit_list = []

        this.loading = true
        this.$post(api.update.getToPauseCampaign, {
          platform: this.platform,
          token: this.userInfo.token
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.dataList = res.data.data
            this.up_list = res.data.data.data.up
            this.down_list = res.data.data.data.down
            this.start_date = res.data.data.start_date
            this.end_date = res.data.data.end_date
            this.up_list.forEach((item, index) => {
              if(index < 10){
                this.up_limit_list.push(item)
              }
            });
          } else {
            this.$message.error(res.data.message)
          }
        });
      },

      pause(campaign_ids){
        this.loading = true
        this.campaign_id = []
        this.campaign_id.push(campaign_ids)
        this.$post(api.update.pauseCampaign, {
          token: this.userInfo.token,
          campaign_ids: this.campaign_id
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.$message.success('pause成功');
            this.up_list = this.up_list.filter(item => item.campaign_id !== campaign_ids)
            this.up_limit_list = this.up_limit_list.filter(item => item.campaign_id !== campaign_ids)
            this.down_list = this.down_list.filter(item => item.campaign_id !== campaign_ids)
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      pauseAll(){
        this.loading = true
        this.$post(api.update.pauseCampaign, {
          token: this.userInfo.token,
          campaign_ids: this.campaign_ids
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.$message.success('pause成功');
            this.campaign_ids.forEach((item_id) => {
              this.up_list = this.up_list.filter(item => item.campaign_id !== item_id)
              this.up_limit_list = this.up_limit_list.filter(item => item.campaign_id !== item_id)
              this.down_list = this.down_list.filter(item => item.campaign_id !== item_id)
            })
            this.campaign_ids = []

          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      toLine(campaign_id,start_date,end_date){
        const {href} = this.$router.resolve({
          path: '/line',
          query: {
            campaign_id: campaign_id,
            period: JSON.stringify([start_date, end_date])
          },
        })
        window.open(href, '_blank')
      },

      // 下拉选择触发时间
      handlePlatformChange(e) {
        this.platform = e
        this.getList()
      },

      // 跳转到by day表现
      byDayPerf(record) {
        const { href } = this.$router.resolve({
          path: '/aff-total-by-day',
          query: {
            platform: record.platform,
          },
        });
        window.open(href, "_blank");
      },
    },

    // 页面初始化
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.getList()
    },
  };
</script>
