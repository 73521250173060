<template>
  <div>
    <div class="ant-page-header-tl">
      <a-range-picker :default-value="dateString" @change="onDateChange"/>

      <a-button type="primary" style="margin-left: 16px;" @click="submitDateChange">Apply</a-button>
    </div>
    <div style="margin: 16px 24px -10px;"><b>{{start_date}} ～ {{end_date}}</b><span style="margin-left: 24px">Compare to</span><b style="margin-left: 24px">{{start_date_before}} ～ {{end_date_before}}</b></div>
    <div class="section">
        <a-table
          :columns="columns"
          :data-source="dataList1"
          :rowKey="record => record.site"
          :pagination="false"
          size="middle"
          bordered
          :loading="loading"
          class="data-table"
        >
            <div slot="organic_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>
            <div slot="promote_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>
            <div slot="push_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>
            <div slot="total_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>

            <div v-if="record.site !='sum'" style="text-decoration: underline;cursor: pointer;" slot="toline" slot-scope="text, record" @click="toLine(record.slug)">
                {{text}}
            </div>
            <div v-else slot="toline" slot-scope="text">
                <b>{{text[0].toUpperCase() + text.slice(1)}}</b>
            </div>
        </a-table>
    </div>
    <div class="section">
        <a-table
          :columns="columns2"
          :data-source="dataList2"
          :rowKey="record => record.site"
          :pagination="false"
          size="middle"
          bordered
          :loading="loading"
          class="data-table"
        >
            <div slot="organic_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>
            <div slot="promote_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>
            <div slot="push_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>
            <div slot="total_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>

            <div v-if="record.site !='sum'" style="text-decoration: underline;cursor: pointer;" slot="toline" slot-scope="text, record" @click="toLine(record.slug)">
                {{text}}
            </div>
            <div v-else slot="toline" slot-scope="text">
              <b>{{text[0].toUpperCase() + text.slice(1)}}</b>
            </div>
        </a-table>
    </div>
    <div class="section">
        <a-table
          :columns="columns3"
          :data-source="dataList3"
          :rowKey="record => record.site"
          :pagination="false"
          size="middle"
          bordered
          :loading="loading"
          class="data-table"
        >
            <div slot="organic_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>
            <div slot="promote_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>
            <div slot="push_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>
            <div slot="total_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>
            <span v-if="record.site !='sum' && record.site !='ps'" style="text-decoration: underline;cursor: pointer;" slot="toline" slot-scope="text, record" @click="toLine(record.slug)">{{text}}</span>
            <span v-else-if="record.site =='sum'" slot="toline" slot-scope="text"><b>{{text[0].toUpperCase() + text.slice(1)}}</b></span>
            <span v-else slot="toline" slot-scope="text">{{text}}</span>
        </a-table>
    </div>
    <div class="section">
        <a-table
          :columns="columns4"
          :data-source="dataList4"
          :rowKey="record => record.site"
          :pagination="false"
          size="middle"
          bordered
          :loading="loading"
          class="data-table"
        >
            <div slot="organic_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>
            <div slot="promote_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>
            <div slot="push_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>
            <div slot="total_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>
            <span v-if="record.site !='sum' && record.site !='ps'" style="text-decoration: underline;cursor: pointer;" slot="toline" slot-scope="text, record" @click="toLine(record.slug)">{{text}}</span>
            <span v-else-if="record.site =='sum'" slot="toline" slot-scope="text"><b>{{text[0].toUpperCase() + text.slice(1)}}</b></span>
            <span v-else slot="toline" slot-scope="text">{{text}}</span>
        </a-table>
    </div>

  </div>
</template>
<script>
  import api from '@/common/api.js'
  import moment from 'moment';
  const defaultStartDate = moment().startOf('day').subtract(2, 'days');
  const defaultEndDate = moment().startOf('day').subtract(2, 'days')

  const columns = [
    {
      title:'Group3',
      dataIndex: 'name_show',
      scopedSlots: { customRender: 'toline' },
      sorter: (a, b) => {
        console.log(a.site.includes("sum"))
        if(!a.site.includes("sum")) {
          return a.site.localeCompare(b.site)
        }
      }
    },
    {
      title:'自然流量',
      dataIndex: 'organic',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.organic - b.organic
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'organic_dod',
      align: 'right',
      scopedSlots: { customRender: 'organic_dod' },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.organic_dod - b.organic_dod
        }
      }
    },
    {
      title:'推广流量',
      dataIndex: 'promote',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.promote - b.promote
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'promote_dod',
      align: 'right',
      scopedSlots: { customRender: 'promote_dod' },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.promote_dod - b.promote_dod
        }
      }
    },
    {
      title:'推送流量',
      dataIndex: 'push',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.push - b.push
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'push_dod',
      align: 'right',
      scopedSlots: { customRender: 'push_dod' },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.push_dod - b.push_dod
        }
      }
    },
    {
      title:'总流量',
      dataIndex: 'total',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.total - b.total
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'total_dod',
      align: 'right',
      scopedSlots: { customRender: 'total_dod' },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.total_dod - b.total_dod
        }
      }
    }
  ]

  const columns2 = [
    {
      title:'Group4',
      dataIndex: 'name_show',
      scopedSlots: { customRender: 'toline' },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.site.localeCompare(b.site)
        }
      }
    },
    {
      title:'自然流量',
      dataIndex: 'organic',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.organic - b.organic
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'organic_dod',
      align: 'right',
      scopedSlots: { customRender: 'organic_dod' },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.organic_dod - b.organic_dod
        }
      }
    },
    {
      title:'推广流量',
      dataIndex: 'promote',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.promote - b.promote
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'promote_dod',
      align: 'right',
      scopedSlots: { customRender: 'promote_dod' },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.promote_dod - b.promote_dod
        }
      }
    },
    {
      title:'推送流量',
      dataIndex: 'push',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.push - b.push
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'push_dod',
      align: 'right',
      scopedSlots: { customRender: 'push_dod' },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.push_dod - b.push_dod
        }
      }
    },
    {
      title:'总流量',
      dataIndex: 'total',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.total - b.total
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'total_dod',
      align: 'right',
      scopedSlots: { customRender: 'total_dod' },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.total_dod - b.total_dod
        }
      }
    }
  ]

  const columns3 = [
    {
      title:'Group5',
      dataIndex: 'name_show',
      scopedSlots: { customRender: 'toline' },
      // customRender: (text, record) => {
      //   console.log('0000:'+JSON.stringify(record) )
      //   // if(text){return text}else{return '/'}
      // },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.site.localeCompare(b.site)
        }
      }
    },
    {
      title:'自然流量',
      dataIndex: 'organic',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.organic - b.organic
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'organic_dod',
      align: 'right',
      scopedSlots: { customRender: 'organic_dod' },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.organic_dod - b.organic_dod
        }
      }
    },
    {
      title:'推广流量',
      dataIndex: 'promote',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.promote - b.promote
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'promote_dod',
      align: 'right',
      scopedSlots: { customRender: 'promote_dod' },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.promote_dod - b.promote_dod
        }
      }
    },
    {
      title:'推送流量',
      dataIndex: 'push',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.push - b.push
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'push_dod',
      align: 'right',
      scopedSlots: { customRender: 'push_dod' },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.push_dod - b.push_dod
        }
      }
    },
    {
      title:'总流量',
      dataIndex: 'total',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.total - b.total
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'total_dod',
      align: 'right',
      scopedSlots: { customRender: 'total_dod' },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.total_dod - b.total_dod
        }
      }
    }
  ]
  const columns4 = [
    {
      title:'Group7',
      dataIndex: 'name_show',
      scopedSlots: { customRender: 'toline' },
      // customRender: (text, record) => {
      //   console.log('0000:'+JSON.stringify(record) )
      //   // if(text){return text}else{return '/'}
      // },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.site.localeCompare(b.site)
        }
      }
    },
    {
      title:'自然流量',
      dataIndex: 'organic',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.organic - b.organic
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'organic_dod',
      align: 'right',
      scopedSlots: { customRender: 'organic_dod' },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.organic_dod - b.organic_dod
        }
      }
    },
    {
      title:'推广流量',
      dataIndex: 'promote',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.promote - b.promote
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'promote_dod',
      align: 'right',
      scopedSlots: { customRender: 'promote_dod' },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.promote_dod - b.promote_dod
        }
      }
    },
    {
      title:'推送流量',
      dataIndex: 'push',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.push - b.push
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'push_dod',
      align: 'right',
      scopedSlots: { customRender: 'push_dod' },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.push_dod - b.push_dod
        }
      }
    },
    {
      title:'总流量',
      dataIndex: 'total',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.total - b.total
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'total_dod',
      align: 'right',
      scopedSlots: { customRender: 'total_dod' },
      sorter: (a, b) => {
        if(!a.site.includes("sum")) {
          return a.total_dod - b.total_dod
        }
      }
    }
  ]

  export default {
    data() {
      return {
        userInfo: {},
        defaultStartDate,
        defaultEndDate,
        url: '',
        columns,
        columns2,
        columns3,
        columns4,
        dataList1: [],
        dataList2: [],
        dataList3: [],
        dataList4: [],
        site_name: '',
        loading: false,
        start_date: '',
        end_date: '',
        start_date_before: '',
        end_date_before:'',
        dateString: [defaultStartDate.format('YYYY-MM-DD'),defaultEndDate.format('YYYY-MM-DD')]
      }
    },
    methods: {
      toLine(site){
          const {href} = this.$router.resolve({
          path: '/site-detail',
          query: {
              site: site,
          },
          })
          window.open(href, '_blank')
      },
      onDateChange(date, dateString){
        this.dateString = dateString
        console.log(this.dateString);
      },
      submitDateChange(){
        this.getList()
      },
      getList(){
        this.loading = true
        this.$post(api.okr.getAllSitesData, {
          start_date: this.dateString[0],
          end_date: this.dateString[1],
          token: this.userInfo.token
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.dataList1 = res.data.data.data[0].data
            this.dataList2 = res.data.data.data[1].data
            this.dataList3 = res.data.data.data[2].data
            this.dataList4 = res.data.data.data[3].data
            this.start_date = res.data.data.start_date
            this.end_date = res.data.data.end_date
            this.start_date_before = res.data.data.start_date_before
            this.end_date_before = res.data.data.end_date_before
            // console.log(this.dataList3)
          } else {
            this.$message.error(res.data.message)
          }
        });
      }
    },
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.getList()
    }
  };
</script>

<style>
  .total-item{
    margin-right: 10px;
    margin-left: 10px;
    color: #ddd;
  }
  .data-table tr:last-child td{
    border-top: 1px solid #999;
  }

</style>
