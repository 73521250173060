<template>
    <div>
        <div class="ant-page-header-tl">
            <span style="margin-left: 10px; margin-right: 5px;">Date:</span>
            <a-range-picker
                style="width: 300px; margin-right: 16px;"
                @change="handleCustomChange"
                v-model="autoDate"
            />
            <a-button
                type="primary"
                style="margin-left: 16px;"
                @click="handleApply"
                >Apply
            </a-button>
        </div>
        <div class="section">
            <a-table
                :columns="columnList"
                :data-source="taskList"
                :pagination="false"
                :rowKey="(record) => record.id"
                size="small"
                bordered
                style=" table-layout: fixed;"
            >
            <template slot="cronContent" slot-scope="text">
                <div v-for="(mainValue, mainKey) in text" :key="mainKey">
                    <strong>{{ mainKey }}</strong>:
                    <div v-for="(subValue, subKey) in mainValue" :key="subKey">
                    {{ subKey }} - {{ subValue.clicks }} clicks
                    </div>
                </div>
            </template>
            <template slot="action" slot-scope="text, record">
                <a-button type="primary" size="small"  @click="goDetail(record)">Check</a-button>
            </template>
            <template slot="editor" slot-scope="text, record">
                <a-button type="primary" size="small"  @click="goEditor(record)">Edit</a-button>
            </template>
            <template slot="delete" slot-scope="text, record">
                <a-button type="primary" size="small"  @click="goDelete(record)">Delete</a-button>
            </template>
            </a-table>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import api from "@/common/api.js";
const columnList =[
    {
        title: "Id",
        dataIndex: "id",
        align: "left",
    },
    {
        title:"Platform",
        dataIndex: "platform",
        align: "left",
    },
    {
        title:"Date",
        dataIndex: "date",
        align: "left",
    },
    {
        title:"Hour",
        dataIndex: "hour",
        align: "left",
    },
    {
        title: "Site",
        dataIndex: "cron_content",
        align: "left",
        scopedSlots: { customRender: "cronContent" }
    },
    {
        title:"CPC%",
        dataIndex: "cpc_per",
        align: "left",
    },
    {
        title:"Status",
        dataIndex: "status",
        align: "left",
        customRender: (text) => {
            return text == 0 ? "待执行" : (text == 1 ? "已执行" : "已删除");
        },
    },
    {
        title:"Blacklist",
        align: "left",
        dataIndex: "is_exec_black",
    },
    { 
      title: "Check",
      scopedSlots: { customRender: "action" },
      align: "left",
    },
    { 
      title: "Edit",
      scopedSlots: { customRender: "editor" },
      align: "left",
    },
    { 
      title: "Delete",
      scopedSlots: { customRender: "delete" },
      align: "left",
    },
]
export default {
  data() {
    return {
        userInfo: {},
        clickRule: {},
        accountList: [],
        autoDate:[moment().format("YYYY-MM-DD"), moment().add(20,'days').format("YYYY-MM-DD")],
        taskList: [],
        columnList,
        };
    },
    methods: {
        goDelete(record){
            let data = {
                token: this.userInfo.token,
                cron_id: record.id,
                platform: record.platform,
                hour: record.hour,
                cron_content: record.cron_content,
                cpc_per: record.cpc_per,
                is_del: 1
            }
            //使用弹窗来确认是否删除
            this.$confirm({
                title: "Are you sure to delete this task?",
                okText: "Yes",
                okType: "danger",
                cancelText: "No",
                onOk: () => {
                    this.$post(api.bysite.updateSiteBlockCorn, data).then((res) => {
                        if(res.status == 200){
                            this.$message.success(res.message);
                            this.handleApply();
                        }
                        else{
                            this.$message.error(res.message);
                        }
                    });
                },
                onCancel() {
                    console.log("Cancel");
                },
            });
        },
        goEditor(record){
            this.$router.push({ path: "/autoBlock", query: { id: record.id } });
        },
        goDetail(record){
            this.$router.push({ path: "/autoblockdetail", query: { id: record.id } });
        },
        handlePlatformChange(e) {
            this.platform = e;
            this.getAccountList()
        },
        handleCustomChange(date, dateString) {
            this.autoDate = dateString;
        },
        handleHourChange(e) {
            this.hour = e;
        },
        handleApply(){
            let data = {
                token: this.userInfo.token,
                start_date: this.autoDate[0],
                end_date: this.autoDate[1],
            }
            this.$post(api.bysite.getSiteBlockCorn, data).then((res) => {
                if(res.status == 200){
                    this.$message.success(res.message);
                    this.taskList = res.data.data;
                }
                else{
                    this.$message.error(res.message);
                }
            });
        },
    },
    created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.handleApply();
  },
};
</script>