<template>
  <div class="performancepage">
    <div class="ant-page-header-tl">
      <a-select
          defaultValue="campaign"
          style="width: 150px; margin-right: 16px;"
          @change="handlePlatformChange"
      >
        <a-select-option
            v-for="(item, index) in reportList"
            :key="index"
            :value="item.name"
        >{{ item.label }}</a-select-option
        >
      </a-select>
      <a-select
          defaultValue="lip"
          style="width: 150px; margin-right: 16px;"
          @change="handleAccountChange"
      >
        <a-select-option
            v-for="(item, index) in accountList"
            :key="index"
            :value="item"
        >{{ item }}</a-select-option
        >
      </a-select>
      <a-button
          class="btn-right"
          type="primary"
          icon="download"
          style="margin-right: 16px;"
          @click="handleExports"
      >Export</a-button>
      <a-button
          class="btn-right"
          type="primary"
          icon="download"
          style="margin-right: 16px;"
          @click="handleExportsSums"
      >Export Sum</a-button
      >
      <div class="righttop">
        <a-range-picker
            :default-value="[moment(period[0], 'YYYY-MM-DD'),moment(period[1], 'YYYY-MM-DD')]"
            :value="[moment(period[0], 'YYYY-MM-DD'),moment(period[1], 'YYYY-MM-DD')]"
            @change="changeDate"
        />
        <a-button
            type="primary"
            style="margin-left: 16px;"
            @click="getList"
        >Apply</a-button
        >
      </div>
    </div>
    <div class="section" style="margin-top: 16px;">
      <div
          class="filter"
          style="display: flex;align-items: center;flex-wrap: wrap;margin-bottom: 0;"
      >
        <a-button
            @click="closeRule(item.name)"
            style="margin-right: 16px;margin-bottom: 16px;"
            type="primary"
            v-for="(item, index) in ruleList"
            :key="index"
        >
          {{ item.curName }} {{ item.curNeed }} {{ item.rule }}<a-icon type="close" />
        </a-button>
        <a-button
            style="margin-bottom: 16px;"
            v-if="!ruleVisible"
            @click="showRuleModal"
            type="primary"
        >
          <a-icon type="plus" />
          Filter
        </a-button>
        <div
          v-if="ruleVisible"
          style="display: flex;flex:1;margin-bottom: 16px;"
        >
          <a-select
              :value="defaultvalue"
              style="min-width: 160px;"
              @change="handleRuleChange"
          >
            <a-select-option
                :disabled="item.disabled"
                v-for="(item, index) in columnList"
                :key="index"
                :value="item.dataIndex"
            >{{ item.name }}</a-select-option
            >
          </a-select>
          <a-select
              defaultValue="Condition"
              style="min-width: 160px;margin: 0 16px;"
              @change="handleFilterChange"
          >
            <a-select-option
                v-for="(item, index) in filterList"
                :key="index"
                :value="item.name"
            >{{ item.name }}</a-select-option
            >
          </a-select>
          <a-input
              style="width: 360px;margin-right: 16px;"
              v-model="rule"
              placeholder=""
          />
          <a-button
                @click="changeRuleColumn(curColumn, curName, curNeed, rule, 1)"
                type="primary"
            >确定</a-button
            >
        </div>
      </div>
      <a-table
          :columns="columns"
          :data-source="dataList"
          size="small"
          :loading="loading"
          bordered
          :rowKey="(record) => record.id"
          :pagination="{ pageSize: pageSize }"
      >
      </a-table>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
import { json2excel } from '@/pages/utils/outExcelMultiple.js'
import { json2excelBySum } from '@/pages/utils/outExcelMultipleBySum.js'
let columnsByCampaign = [
  {
    title: "date",
    dataIndex: "date",
    className: "per",
    align: "left",
  },
  {
    title: "campaign",
    dataIndex: "campaign_name",
    className: "per",
    align: "left",
  },
  {
    title: "campaign type",
    dataIndex: "campaign_type",
    className: "per",
    align: "left",
  },
  {
    title: "device",
    dataIndex: "device",
    className: "per",
    align: "left",
  },
  {
    title: "aw clicks",
    dataIndex: "clicks",
    className: "per",
    sorter: (a, b) => a.clicks - b.clicks,
    align: "right",
    customRender: text => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
    }
  },
  {
    title: "sessions",
    dataIndex: "sessions",
    className: "per",
    sorter: (a, b) => a.sessions - b.sessions,
    align: "right",
    customRender: text => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
    }
  },
  {
    title: "aw cpc",
    dataIndex: "aw_cpc",
    className: "per",
    sorter: (a, b) => a.aw_cpc - b.aw_cpc,
    align: "right",
    customRender: text => {
      return text.toFixed(3)
    }
  },
  {
    title: "上限",
    dataIndex: "cpc_top",
    className: "per",
    sorter: (a, b) => a.cpc_top - b.cpc_top,
    align: "right",
    customRender: text => {
      return text.toFixed(3)
    }
  },
  {
    title: "%",
    dataIndex: "cpc_percent",
    className: "per",
    sorter: (a, b) => a.cpc_percent - b.cpc_percent,
    align: "right",
    customRender: text => {
      return (text * 100).toFixed() + '%'
    }
  },
  {
    title: "系数",
    dataIndex: "x_num",
    className: "per",
    sorter: (a, b) => a.x_num - b.x_num,
    align: "right",
    customRender: text => {
      return  text.toFixed(1).toLocaleString()
    }
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    className: "per",
    sorter: (a, b) => a.z_num - b.z_num,
    align: "right",
    customRender: text => {
      return  text.toFixed(1).toLocaleString()
    }
  },
  {
    title: "pv",
    dataIndex: "page_views",
    className: "per",
    sorter: (a, b) => a.page_views - b.page_views,
    align: "right",
    customRender: text => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    }
  },
  {
    title: "p/s",
    dataIndex: "pages_per_session",
    className: "per",
    sorter: (a, b) => a.pages_per_session - b.pages_per_session,
    align: "right",
  },
]
let columnsByAdGroup = [
  {
    title: "date",
    dataIndex: "date",
    className: "per",
    align: "left",
  },
  {
    title: "campaign",
    dataIndex: "campaign_name",
    className: "per",
    align: "left",
  },
  {
    title: "campaign type",
    dataIndex: "campaign_type",
    className: "per",
    align: "left",
  },
  {
    title: "ad group",
    dataIndex: "ad_group",
    className: "per",
    align: "left",
  },
  {
    title: "device",
    dataIndex: "device",
    className: "per",
    align: "left",
  },
  {
    title: "aw clicks",
    dataIndex: "clicks",
    className: "per",
    sorter: (a, b) => a.clicks - b.clicks,
    align: "right",
    customRender: text => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
    }
  },
  {
    title: "sessions",
    dataIndex: "sessions",
    className: "per",
    sorter: (a, b) => a.sessions - b.sessions,
    align: "right",
    customRender: text => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
    }
  },
  {
    title: "aw cpc",
    dataIndex: "aw_cpc",
    className: "per",
    sorter: (a, b) => a.aw_cpc - b.aw_cpc,
    align: "right",
    customRender: text => {
      return text.toFixed(3)
    }
  },
  {
    title: "上限",
    dataIndex: "cpc_top",
    className: "per",
    sorter: (a, b) => a.cpc_top - b.cpc_top,
    align: "right",
    customRender: text => {
      return text.toFixed(3)
    }
  },
  {
    title: "%",
    dataIndex: "cpc_percent",
    className: "per",
    sorter: (a, b) => a.cpc_percent - b.cpc_percent,
    align: "right",
    customRender: text => {
      return (text * 100).toFixed() + '%'
    }
  },
  {
    title: "系数",
    dataIndex: "x_num",
    className: "per",
    sorter: (a, b) => a.x_num - b.x_num,
    align: "right",
    customRender: text => {
      return  text.toFixed(1).toLocaleString()
    }
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    className: "per",
    sorter: (a, b) => a.z_num - b.z_num,
    align: "right",
    customRender: text => {
      return  text.toFixed(1).toLocaleString()
    }
  },
  {
    title: "pv",
    dataIndex: "page_views",
    className: "per",
    sorter: (a, b) => a.page_views - b.page_views,
    align: "right",
    customRender: text => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    }
  },
  {
    title: "p/s",
    dataIndex: "pages_per_session",
    className: "per",
    sorter: (a, b) => a.pages_per_session - b.pages_per_session,
    align: "right",
  },
]
let columnsByKeywords = [
  {
    title: "date",
    dataIndex: "date",
    className: "per",
    align: "left",
  },
  {
    title: "campaign",
    dataIndex: "campaign_name",
    className: "per",
    align: "left",
  },
  {
    title: "campaign type",
    dataIndex: "campaign_type",
    className: "per",
    align: "left",
  },
  {
    title: "ad group",
    dataIndex: "ad_group",
    className: "per",
    align: "left",
  },
  {
    title: "keyword",
    dataIndex: "keywords",
    className: "per",
    align: "left",
  },
  {
    title: "device",
    dataIndex: "device",
    className: "per",
    align: "left",
  },
  {
    title: "aw clicks",
    dataIndex: "clicks",
    className: "per",
    sorter: (a, b) => a.clicks - b.clicks,
    align: "right",
    customRender: text => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
    }
  },
  {
    title: "sessions",
    dataIndex: "sessions",
    className: "per",
    sorter: (a, b) => a.sessions - b.sessions,
    align: "right",
    customRender: text => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
    }
  },
  {
    title: "aw cpc",
    dataIndex: "aw_cpc",
    className: "per",
    sorter: (a, b) => a.aw_cpc - b.aw_cpc,
    align: "right",
    customRender: text => {
      return text.toFixed(3)
    }
  },
  {
    title: "上限",
    dataIndex: "cpc_top",
    className: "per",
    sorter: (a, b) => a.cpc_top - b.cpc_top,
    align: "right",
    customRender: text => {
      return text.toFixed(3)
    }
  },
  {
    title: "%",
    dataIndex: "cpc_percent",
    className: "per",
    sorter: (a, b) => a.cpc_percent - b.cpc_percent,
    align: "right",
    customRender: text => {
      return (text * 100).toFixed() + '%'
    }
  },
  {
    title: "系数",
    dataIndex: "x_num",
    className: "per",
    sorter: (a, b) => a.x_num - b.x_num,
    align: "right",
    customRender: text => {
      return  text.toFixed(1).toLocaleString()
    }
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    className: "per",
    sorter: (a, b) => a.z_num - b.z_num,
    align: "right",
    customRender: text => {
      return  text.toFixed(1).toLocaleString()
    }
  },
  {
    title: "pv",
    dataIndex: "page_views",
    className: "per",
    sorter: (a, b) => a.page_views - b.page_views,
    align: "right",
    customRender: text => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    }
  },
  {
    title: "p/s",
    dataIndex: "pages_per_session",
    className: "per",
    sorter: (a, b) => a.pages_per_session - b.pages_per_session,
    align: "right",
  },
]
let columnsByAdSense = [
  {
    title: "date",
    dataIndex: "date",
    className: "per",
    align: "left",
  },
  {
    title: "campaign",
    dataIndex: "campaign_name",
    className: "per",
    align: "left",
  },
  {
    title: "campaign type",
    dataIndex: "campaign_type",
    className: "per",
    align: "left",
  },
  {
    title: "device",
    dataIndex: "device",
    className: "per",
    align: "left",
  },
  {
    title: "sessions",
    dataIndex: "sessions",
    className: "per",
    sorter: (a, b) => a.sessions - b.sessions,
    align: "right",
    customRender: text => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
    }
  },
  {
    title: "pageviews",
    dataIndex: "page_views",
    className: "per",
    sorter: (a, b) => a.page_views - b.page_views,
    align: "right",
    customRender: text => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    }
  },
  {
    title: "ads CTR",
    dataIndex: "ads_ctr",
    className: "per",
    sorter: (a, b) => a.ads_ctr - b.ads_ctr,
    align: "right",
    customRender: text => {
      return (text * 100).toFixed() + '%'
    }
  },
  {
    title: "ads CPC",
    dataIndex: "ads_cpc",
    className: "per",
    sorter: (a, b) => a.ads_cpc - b.ads_cpc,
    align: "right",
    customRender: text => {
      return text.toFixed(3)
    }
  },
  {
    title: "ads score",
    dataIndex: "ads_score",
    className: "per",
    sorter: (a, b) => a.ads_score - b.ads_score,
    align: "right",
    customRender: text => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    }
  },
  {
    title: "pages per session",
    dataIndex: "pages_per_session",
    className: "per",
    sorter: (a, b) => a.pages_per_session - b.pages_per_session,
    align: "right",
    customRender: text => {
      return text.toFixed(1)
    }
  },
  {
    title: "bounce rate",
    dataIndex: "bounce_rate",
    className: "per",
    align: "right",
    customRender: text => {
      return (text * 100).toFixed() + '%'
    }
  },
  {
    title: "CPC 上限",
    dataIndex: "cpc_top",
    className: "per",
    align: "right",
    customRender: text => {
      return text.toFixed(3)
    }
  },
]
export default {
  data() {
    return {
      moment,
      reportType: "campaign",
      reportName:"by campaign",
      reportList: [
        {
          id: 1,
          label: "by campaign",
          name:"campaign",
        },
        {
          id: 2,
          label: "by ad group",
          name:"adgroup",
        },
        {
          id: 3,
          label: "by keyword",
          name:"keyword",
        },
        {
          id: 4,
          label: "ad sense",
          name:"sense",
        },
      ],
      accountType:"lip",
      accountList:[],
      period: [
        moment()
        .subtract(1, "day")
        .format("YYYY-MM-DD"),
        moment()
        .subtract(1, "day")
        .format("YYYY-MM-DD"),
      ],
      columnsByCampaign,
      columnsByAdGroup,
      columnsByKeywords,
      columnsByAdSense,
      pageSize: 100,


      ruleVisible: false,
      defaultvalue: "Choose",
      columnList:[
        {
          id:1,
          name:'campaign',
          dataIndex:'campaign_name',
        },
        {
          id:2,
          name:'ad group',
          dataIndex:'ad_group',
        },
        {
          id:3,
          name:'keyword',
          dataIndex:'keywords',
        },
        {
          id:4,
          name:'device',
          dataIndex:'device',
        },
        {
          id:5,
          name:'campaign type',
          dataIndex:'campaign_type',
        },
      ],
      filterList: [
        {
          id: 1,
          name: "contains",
        },
        {
          id: 2,
          name: "equals",
        },
        {
          id: 2,
          name: "not contains",
        },
      ],
      ruleList: [],
      curColumn: "",
      curName: "",
      curNeed: 0,
      rule: "",

      fixedData: [],
      dataList: [],
      dataListBySum:[],
      loading: false,



    };
  },
  computed: {
    columns() {
      if (this.reportType === 'campaign') {
        return this.columnsByCampaign
      } else if (this.reportType === 'adgroup') {
        return this.columnsByAdGroup
      } else if (this.reportType === 'keyword') {
        return this.columnsByKeywords
      } else if (this.reportType === 'sense') {
        return this.columnsByAdSense
      } else {
        return ''
      }
    },
    ExcelTitle() {
      if (this.reportType === 'campaign') {
        return ['date', 'campaign', 'campaign type', 'device', 'aw clicks', 'sessions', 'aw cpc', '上限', '%', '系数', '宗数', 'pv', 'p/s']
      } else if (this.reportType === 'adgroup') {
        return ['date', 'campaign', 'campaign type', 'ad group', 'device', 'aw clicks', 'sessions', 'aw cpc', '上限', '%', '系数', '宗数', 'pv', 'p/s']
      } else if (this.reportType === 'keyword') {
        return ['date', 'campaign', 'campaign type', 'ad group', 'keyword', 'device', 'aw clicks', 'sessions', 'aw cpc', '上限', '%', '系数', '宗数', 'pv', 'p/s']
      } else if (this.reportType === 'sense') {
        return ['date', 'campaign', 'campaign type', 'device', 'sessions', 'pageviews', 'ads CTR', 'ads CPC', 'ads score', 'page per session', 'bounce rate', 'CPC 上限']
      } else {
        return ''
      }
    },
    ExcelSlug() {
      if (this.reportType === 'campaign') {
        return ['date', 'campaign_name', 'campaign_type', 'device', 'clicks', 'sessions', 'aw_cpc', 'cpc_top', 'cpc_percent', 'x_num', 'z_num', 'page_views', 'pages_per_session']
      } else if (this.reportType === 'adgroup') {
        return ['date', 'campaign_name', 'campaign_type', 'ad_group', 'device', 'clicks', 'sessions', 'aw_cpc', 'cpc_top', 'cpc_percent', 'x_num', 'z_num', 'page_views', 'pages_per_session']
      } else if (this.reportType === 'keyword') {
        return ['date', 'campaign_name', 'campaign_type', 'ad_group', 'keywords', 'device',  'clicks', 'sessions', 'aw_cpc', 'cpc_top', 'cpc_percent', 'x_num', 'z_num', 'page_views', 'pages_per_session']
      } else if (this.reportType === 'sense') {
        return ['date', 'campaign_name', 'campaign_type', 'device', 'sessions', 'page_views', 'ads_ctr', 'ads_cpc', 'ads_score', 'pages_per_session', 'bounce_rate', 'cpc_top']
      } else {
        return ''
      }
    },
    ExcelSumTitle() {
      if (this.reportType === 'campaign') {
        return ['campaign type', 'date', 'aw clicks', 'sessions', 'aw cpc', '上限', '%', '系数', '宗数', 'pv', 'p/s']
      } else if (this.reportType === 'adgroup') {
        return ['campaign type', 'date', 'aw clicks', 'sessions', 'aw cpc', '上限', '%', '系数', '宗数', 'pv', 'p/s']
      } else if (this.reportType === 'keyword') {
        return ['campaign type', 'date', 'aw clicks', 'sessions', 'aw cpc', '上限', '%', '系数', '宗数', 'pv', 'p/s']
      } else if (this.reportType === 'sense') {
        return ['campaign type', 'date', 'sessions', 'pageviews', 'ads CTR', 'ads CPC', 'ads score', 'page per session', 'bounce rate', 'CPC 上限']
      } else {
        return ''
      }
    },
    ExcelSumSlug() {
      if (this.reportType === 'campaign') {
        return ['campaign_type', 'date', 'clicks', 'sessions', 'aw_cpc', 'cpc_top', 'cpc_percent', 'x_num', 'z_num', 'page_views', 'pages_per_session']
      } else if (this.reportType === 'adgroup') {
        return ['campaign_type', 'date', 'clicks', 'sessions', 'aw_cpc', 'cpc_top', 'cpc_percent', 'x_num', 'z_num', 'page_views', 'pages_per_session']
      } else if (this.reportType === 'keyword') {
        return ['campaign_type', 'date', 'clicks', 'sessions', 'aw_cpc', 'cpc_top', 'cpc_percent', 'x_num', 'z_num', 'page_views', 'pages_per_session']
      } else if (this.reportType === 'sense') {
        return ['campaign_type', 'date', 'sessions', 'page_views', 'ads_ctr', 'ads_cpc', 'ads_score', 'pages_per_session', 'bounce_rate', 'cpc_top']
      } else {
        return ''
      }
    },
  },
  methods: {

    getAccountList() {
      this.$post(api.daily.getAdwordsAccountList, {
        token: this.userInfo.token,
      }).then((res) => {
        if (res.data.status === 200) {
          this.accountList = res.data.data
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    handlePlatformChange(e) {
      this.ruleList = []
      this.rule = ''
      this.reportType = e;
      this.period = [
        moment().subtract(1, "day").format("YYYY-MM-DD"),
        moment().subtract(1, "day").format("YYYY-MM-DD"),
      ]
      this.getList();
    },
    handleAccountChange(e) {
      this.ruleList = []
      this.rule = ''
      this.accountType = e
      this.period = [
        moment().subtract(1, "day").format("YYYY-MM-DD"),
        moment().subtract(1, "day").format("YYYY-MM-DD"),
      ]
      this.getList();
    },
    changeDate(date, dateString) {
      this.ruleList = []
      this.rule = ''
      this.period[0] = dateString[0]
      this.period[1] = dateString[1]
    },
    getList() {
      this.loading = true
      this.dataList = []
      this.$post(api.daily.getAdwordsByCampaign, {
        token: this.userInfo.token,
        report_type:this.reportType,
        account:this.accountType,
        start_date:this.period[0],
        end_date:this.period[1],
        // start_date:'2023-04-09',
        // end_date:'2023-04-10',
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.fixedData = res.data.data
          this.dataList = res.data.data
          this.loading = false
        } else {
          this.$message.error(res.data.message);
          this.loading = false

        }
      });
    },

    handleExports() {
      this.cpnExport2Excel()
    },
    handleExportsSums() {
      this.cpnExport2ExcelSums()
    },
    //  导出Excel
    cpnExport2Excel() {
      if(this.reportType == 'campaign') {
        this.reportName = ' by campaign'
      }else if (this.reportType == 'adgroup') {
        this.reportName = ' by ad group'
      }else if (this.reportType == 'keyword') {
        this.reportName = ' by keyword'
      }else if (this.reportType == 'sense') {
        this.reportName = ' ad sense'
      }
      let excelTitle = this.accountType + this.reportName + ' ' + this.period[0].split('-').join('') + ' - ' + this.period[1].split('-').join('')
      var excelDatas = [
        {
          tHeader: this.ExcelTitle, // sheet表一头部
          filterVal: this.ExcelSlug, // 表一的数据字段
          tableData: this.dataList, // 表一的整体json数据
          sheetName: 'sheet1' // 表一的sheet名字
        },
      ]

      if(this.dataList.length == 0) {
        this.$message.error('报告暂无数据');
      }else {
        json2excel(excelDatas, excelTitle, this.reportType, true,'xlsx')
      }
    },

    //  导出Excel Sums
    cpnExport2ExcelSums() {
      if(this.reportType == 'campaign') {
        this.reportName = ' by campaign'
      }else if (this.reportType == 'adgroup') {
        this.reportName = ' by ad group'
      }else if (this.reportType == 'keyword') {
        this.reportName = ' by keyword'
      }else if (this.reportType == 'sense') {
        this.reportName = ' ad sense'
      }
      let countArr = []
      // let typeArr = []
      let tempDataListBySum = []
      this.dataListBySum = []
      this.dataList.map(item => {
        if(countArr.includes(item.date + ',' + item.campaign_type)) {
            // 数据累加
            tempDataListBySum.map((child,index) => {
              // console.log(child,index)
              if(child.date == item.date && child.campaign_type == item.campaign_type) {
                // aw clicks
                tempDataListBySum[index].clicks += item.clicks
                // sessions
                tempDataListBySum[index].sessions += item.sessions
                // aw_cpc
                tempDataListBySum[index].aw_cpcWclicks += item.aw_cpc * item.clicks
                tempDataListBySum[index].aw_cpc = tempDataListBySum[index].aw_cpcWclicks / tempDataListBySum[index].clicks
                // cpc_top
                tempDataListBySum[index].cpc_topWsessions += item.cpc_top * item.sessions
                tempDataListBySum[index].cpc_top = tempDataListBySum[index].cpc_topWsessions / tempDataListBySum[index].sessions
                // percent
                tempDataListBySum[index].cpc_percent = tempDataListBySum[index].aw_cpc / tempDataListBySum[index].cpc_top
                // x_num
                tempDataListBySum[index].x_num += item.x_num
                // z_num
                tempDataListBySum[index].z_num += item.z_num
                // page_views
                tempDataListBySum[index].page_views += item.page_views
                // pages_per_session
                tempDataListBySum[index].pages_per_session = tempDataListBySum[index].page_views / tempDataListBySum[index].sessions

                if(this.reportType == 'sense') {
                   // ads_ctr
                  tempDataListBySum[index].ads_ctrWpage_views += item.ads_ctr.toFixed(2) * item.page_views
                  tempDataListBySum[index].ads_ctr = tempDataListBySum[index].ads_ctrWpage_views / tempDataListBySum[index].page_views
                  // ads_cpc
                  tempDataListBySum[index].ads_ctrWpage_viewsWads_cpc += item.ads_ctr.toFixed(2) * item.page_views * item.ads_cpc
                  tempDataListBySum[index].ads_cpc = tempDataListBySum[index].ads_ctrWpage_viewsWads_cpc / tempDataListBySum[index].ads_ctrWpage_views
                  // ads_score
                  tempDataListBySum[index].ads_scoreWpage_views += item.ads_score * item.page_views
                  tempDataListBySum[index].ads_score = tempDataListBySum[index].ads_scoreWpage_views / tempDataListBySum[index].page_views
                  // bounce_rate
                  tempDataListBySum[index].bounce_rateWsessions += item.bounce_rate * item.sessions
                  tempDataListBySum[index].bounce_rate = tempDataListBySum[index].bounce_rateWsessions / tempDataListBySum[index].sessions

                }
              }
            })
        }else {
          // cpc_top * sessions
          item.cpc_topWsessions = item.cpc_top * item.sessions
          // aw_cpc * clicks
          item.aw_cpcWclicks = item.aw_cpc * item.clicks

          if(this.reportType == 'sense') {
            // ads_ctr
            item.ads_ctrWpage_views = item.ads_ctr.toFixed(2) * item.page_views
            // ads_cpc
            item.ads_ctrWpage_viewsWads_cpc = item.ads_ctr.toFixed(2) * item.page_views * item.ads_cpc
            // ads_score
            item.ads_scoreWpage_views = item.ads_score * item.page_views
            // bounce_rate
            item.bounce_rateWsessions = item.bounce_rate * item.sessions
          }
          
          countArr.push(item.date + ',' + item.campaign_type)
          // typeArr.push(item.campaign_type)
          tempDataListBySum.push(JSON.parse(JSON.stringify(item)))
        }
      })
      // console.log(tempDataListBySum)
      this.dataListBySum = tempDataListBySum

      let excelTitle = this.accountType + this.reportName + ' ' + this.period[0].split('-').join('') + ' - ' + this.period[1].split('-').join('') + ' by day total'
      var excelDatas = [
        {
          tHeader: this.ExcelSumTitle, // sheet表一头部
          filterVal: this.ExcelSumSlug, // 表一的数据字段
          tableData: this.dataListBySum, // 表一的整体json数据
          sheetName: 'sheet1' // 表一的sheet名字
        },
      ]

      if(this.dataList.length == 0) {
        this.$message.error('报告暂无数据');
      }else {
        json2excelBySum(excelDatas, excelTitle, this.reportType, true,'xlsx')
      }
    },

    showRuleModal() {
      this.ruleVisible = !this.ruleVisible;
    },

    handleRuleChange(e) {
      this.defaultvalue = e;
      this.curColumn = e;
      this.columnList.forEach((item) => {
        if (item.dataIndex == e) {
          this.curName = item.name;
        }
      });
    },

    handleFilterChange(e) {
      this.curNeed = e;
    },

    changeRuleColumn(curColumn, curName, curNeed, rule, type) {
      if (curNeed == "contains") this.dataList = this.dataList.filter((item) => item[curColumn].toLowerCase().includes(rule.toLowerCase()));
      if (curNeed == "equals") this.dataList = this.dataList.filter((item) => item[curColumn].toLowerCase() == rule.toLowerCase());
      if (curNeed == "not contains") this.dataList = this.dataList.filter((item) => item[curColumn].toLowerCase().indexOf(rule.toLowerCase()) == -1);
      // if (curNeed == "not equals")
      //   this.dataList = this.dataList.filter(
      //       (item) =>
      //           item[curColumn].toString().toLowerCase() != rule.toLowerCase()
      //   );

      if (type == 1) {
        this.ruleList.push({
          curName: curName,
          curNeed: curNeed,
          rule: rule,
        });
        this.defaultvalue = "选择列";
        this.ruleVisible = false;
        this.rule = ''
      }

    },

    closeRule(name) {
      this.ruleList.forEach((ruleitem, index) => {
        //删掉需要删除的规则
        if (ruleitem.name == name) {
          this.ruleList.splice(index, 1);
        }
      });
      //还原数据
      this.dataList = this.fixedData;
      this.ruleList.forEach((ruleitem) => {
        if (ruleitem.curNeed == "contains") this.dataList = this.dataList.filter((item) => item[ruleitem.name].toLowerCase().includes(ruleitem.rule.toLowerCase()));
        if (ruleitem.curNeed == "equals") this.dataList = this.dataList.filter((item) => item[ruleitem.name].toLowerCase() == ruleitem.rule.toLowerCase());
        if (ruleitem.curNeed == "contains") this.dataList = this.dataList.filter((item) => item[ruleitem.name].toLowerCase().includes(ruleitem.rule.toLowerCase()));
      });
    },
  },
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
      this.getAccountList()
      this.getList()
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }

  },
};
</script>
<style>
.section {
  margin: 24px;
  background-color: #fff;
  padding: 16px;
  position: relative;
}
.filter {
  margin-bottom: 16px;
}
.performancepage
.ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-body
> table
> .ant-table-tbody
> tr
> td {
  overflow: hidden;
}

.ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-header
> table
> .ant-table-thead
> tr
> th.baseinfo,
.ant-table-small
> .ant-table-content
> .ant-table-fixed-left
> .ant-table-header
> table
> .ant-table-thead
> tr
> th.baseinfo {
  background-color: #f4fbf0;
}
/* .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th.per, */
.ant-table-small .ant-table-content .ant-table-thead tr th.per {
  background-color: #fff4f4 !important;
}
.gray-bg {
  overflow: hidden;
}
.ant-table td {
  white-space: normal!important;
  font-size: 12px;
  padding: 4px 5px;
}
.widthno {
  width: 0;
  transition: all ease 0.25s;
}
.widthauto {
  width: auto;
}
.runing {
  width: 6px;
  height: 6px;
  display: inline-block;
  background-color: #52c41a;
  border-radius: 100%;
  margin-right: 6px;
}
.nopadding.input-number .ant-input-number-input {
  padding-left: 16px;
}
.percent {
  position: absolute;
  bottom: -2px;
  right: 8px;
  color: #aaa;
  transform: scale(0.9);
  transform-origin: right bottom;
}
.period {
  border: 1px solid #1890ff;
  color: #1890ff;
  border-radius: 4px;
  line-height: 30px;
  display: inline-block;
  padding: 0 10px;
  margin-left: 8px;
  cursor: pointer;
}
.righttop {
  position: absolute;
  right: 24px;
  top: 12px;
  display: flex;
  align-items: center;
}
.labelwp {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  color: #1890ff;
}
.labelitem {
  line-height: 28px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  cursor: pointer;
}
.labelitem:hover {
  background-color: #f5f5f5;
}
.labelwp-item {
  cursor: pointer;
}
.checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-right: 8px;
}
.allhave,
.parthave {
  background-color: #1890ff;
  border-color: #1890ff;
  position: relative;
}
.allhave::after {
  position: absolute;
  content: "";
  width: 6px;
  height: 8px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  top: 2px;
  right: 4px;
  transform: rotate(45deg);
}
.parthave::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  background-color: #fff;
  top: 6px;
  left: 3px;
}
.performancepage .ant-table-pagination.ant-pagination {
  float: left;
  margin-left: 146px;
}
</style>