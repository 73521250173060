<template>
  <div>
    <div class="section">
        <div style="margin-bottom: 16px;"><b>站点：</b>{{site}}<span class="total-item">| </span><b>样式：</b>
        <span v-if="type==1">新样式</span><span v-else>旧样式</span>
        <span class="total-item">| </span> <b>时间: </b>{{period[0]}}~{{period[1]}}</div>
        <a-table 
            :columns="columns" 
            :data-source="tableSource" 
            :rowKey="record => record.landing_page"
            :pagination="false" 
            size="small"
            bordered
            :loading="loading"
        >
            
        </a-table>
    </div>
  </div>
</template>
<script>
  import api from '@/common/api.js'
  import moment from 'moment';

  const columns = [
    {
        title:'Landing Page',
        dataIndex: 'landing_page',
        align: 'left',
        sorter: (a, b) => a.landing_page.localeCompare(b.landing_page)
    },
    {
        title:'Total Session',
        dataIndex: 'sessions',
        align: 'right',
        customRender: text => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
        },
        sorter: (a, b) => a.sessions - b.sessions,
    },
    {
        title:'Total PV',
        dataIndex: 'page_views',
        align: 'right',
        customRender: text => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
        },
        sorter: (a, b) => a.page_views - b.page_views,
    },
    {
        title:'加权 ads CTR',
        dataIndex: 'ads_ctr',
        align: 'right',
        customRender: text => {
          return text + '%'
        },
        sorter: (a, b) => a.ads_ctr - b.ads_ctr,
    },
    {
        title:'加权 ads CPC',
        dataIndex: 'ads_cpc',
        align: 'right',
        sorter: (a, b) => a.ads_cpc - b.ads_cpc,
    },
    {
        title:'加权 ads score',
        dataIndex: 'ads_score',
        align: 'right',
        sorter: (a, b) => a.ads_score - b.ads_score,
    },
    {
        title:'P/S',
        dataIndex: 'ps',
        align: 'right',
        sorter: (a, b) => a.ps - b.ps,
    },
    {
        title:'加权 CPC 上限',
        dataIndex: 'cpc_top',
        align: 'right',
        sorter: (a, b) => a.cpc_top - b.cpc_top,
    },
  ]


  export default {
    data() {
      return {
        moment,
        userInfo: {},
        columns,
        tableSource: [],
        loading: false,
        type: 0,
        period: []
      }
    },
    methods: {
      getList(){
        this.loading = true
        this.$post(api.abtest.abTestDetail, {
          token: this.userInfo.token,
          site: this.site,
          is_new: this.type,
          test_period: this.period
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.tableSource = res.data.data.perf
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
    },
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.site =  this.$route.query.site
      let type = this.$route.query.type
      this.period = JSON.parse(this.$route.query.period)
      this.type = (type=='新样式' ? 1 : 0)
      this.getList()
    }
  };
</script>

<style> 
  .total-item{
    margin-right: 10px;
    margin-left: 10px;
    color: #ddd;
  }
</style>
