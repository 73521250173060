<template>
  <div>
    <!-- date -->
    <div class="ant-page-header-tl">
      <span style="margin-right:5px">Date:</span>
      <a-range-picker :default-value="dateString" @change="onDateChange"/>
      <!-- Product -->
      <span style="margin-left:10px;margin-right:5px">Product:</span>
      <a-select mode="multiple" defaultValue="BA" style="width: 200px;" @change="handleProductChange">
        <a-select-option v-for="(item, index) in productList" :key="index" :value="item">{{item}}</a-select-option>
      </a-select>
      <!-- Report -->
      <span style="margin-left:10px;margin-right:5px">Report:</span>
      <a-select defaultValue="Landing Page" style="width: 260px; margin-right: 16px;" @change="handleReportChange">
        <a-select-option v-for="(item, index) in reportList" :key="index" :value="item.index">{{item.name}}</a-select-option>
      </a-select>
      <a-button type="primary" style="margin-left: 16px;" @click="submitDateChange">Apply</a-button>
    </div>
    <!-- table -->
    <div class="section">
        <a-table 
          :columns="columnList" 
          :data-source="dataList" 
          :pagination="{pageSize: pageSize}" 
          :rowKey="record => record.id"
          :scroll="{y: 1200 }"
          size="small"
          bordered
          :loading="loading"
          class="alan-data-table"
        >
          <div slot="sessions" slot-scope="text">{{text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')}}</div>
          <div slot="pv" slot-scope="text">{{text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')}}</div>
          <div slot="ads_ctr" slot-scope="text">{{text}}%</div>
          <div slot="bounce_rate" slot-scope="text">{{text}}%</div>
        </a-table>
    </div>
  </div>
</template>
<script>
  import api from '@/common/api.js'
  import moment from 'moment';
  const defaultStartDate = moment().startOf('day').subtract(1, 'days');
  const defaultEndDate = moment().startOf('day').subtract(1, 'days')

  export default {
    data() {
      return {
        userInfo: {},
        pageSize: 100,
        defaultStartDate,
        defaultEndDate,
        dataList: [],
        columnList: [],
        column1 : ['product', 'campaign', 'campaign_name', 'medium', 'landing_page', 'landing', 'country', 'device', 'site'],
        loading: false,
        start_date: '',
        end_date: '',
        dateString: [moment(defaultStartDate, 'YYYY-MM-DD'), moment(defaultEndDate, 'YYYY-MM-DD')],
        product : ['BA'],
        productList: ['BA', 'LF', 'BF', 'FD', 'TDI', 'FP', 'FT', 'AC', 'BS', 'AP', 'LF101'],
        report: 'data-1',
        reportList: [
          {
            index: 'data-1',
            name: 'Landing Page'
          },
          {
            index: 'data-2',
            name: 'Landing Page by Site'
          },
          {
            index: 'insight-1',
            name: '广告价值: Landing Page'
          },
          {
            index: 'insight-2',
            name: '广告价值: Landing Page + by Site'
          },
          {
            index: 'data-1-campaign',
            name: 'Campaign ID'
          },
          {
            index: 'data-2-campaign',
            name: 'Campaign ID: by Site'
          },
          {
            index: 'insight-1-campaign',
            name: '广告价值: Campaign ID'
          },
          {
            index: 'insight-2-campaign',
            name: '广告价值: Campaign ID + by Site'
          },
          {
            index: 'data-1-campaign-country',
            name: 'Campaign ID: Country'
          },
          {
            index: 'data-2-campaign-country',
            name: 'Campaign ID: Country & Site'
          },
          // {
          //   index: 'data-1-campaign-provider',
          //   name: 'Campaign ID: Provider'
          // },
          // {
          //   index: 'data-1-campaign-provider-os',
          //   name: 'Campaign ID: Provider & OS'
          // },
          // {
          //   index: 'data-2-campaign-provider-os',
          //   name: 'Campaign ID: Provider & Site & OS'
          // },
        ],
      }
    },
    // 定义方法
    methods: {
      onDateChange(dateString) {
        this.dateString = dateString
      },
      handleProductChange(e){
        this.product = e
      },
      handleReportChange(e){
        this.report = e
      },
      // 修改日期获取数据
      submitDateChange(){
        this.getList()
      },
      // 获取数据列表
      getList() {
        this.loading = true
        this.$post(api.alan.getAlanReport, {
          product: this.product,
          report: this.report,
          start_date: this.dateString[0].format('YYYY-MM-DD'),
          end_date: this.dateString[1].format('YYYY-MM-DD'),
          token: this.userInfo.token
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.dataList = res.data.data.data
            this.columnList = res.data.data.columns
            this.columnList.forEach((item) => {
                this.adjustColumn(item)
            })
            // this.start_date = res.data.data.start_date
            // this.end_date = res.data.data.end_date
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      adjustColumn(column) {
        // 控制对齐方式
        if (this.column1.indexOf(column.dataIndex) >= 0) {
          column.align = 'left'
          column.sorter = (a, b) => a[column.dataIndex].localeCompare(b[column.dataIndex])
        } else {
          column.align = 'right'
          column.sorter = (a, b) => a[column.dataIndex] - b[column.dataIndex]
        }
        column.scopedSlots = { customRender: column.dataIndex}
        
        if (column.dataIndex == 'campaign_name') {
          column.width = 600
          column.ellipsis = true
        }
        if (column.dataIndex == 'campaign') {
          column.width = 270
        }
        if (column.dataIndex == 'site') {
          column.width = 300
          column.ellipsis = true
        }
        if (column.dataIndex == 'landing') {
          column.width = 300
          column.ellipsis = true
        }
      },
    },

    // 页面初始化
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      // this.getList()
    },
  };
</script>