<template>
  <div class="adw-perf">
    <div class="ant-page-header-tl">
      <span style="margin-right:5px">Date:</span>
      <a-range-picker :default-value="dateString" @change="onDateChange" />
      <a-button
        class="btn-right"
        type="primary"
        style="margin-left:16px;"
        @click="onCommit"
        >Apply</a-button
      >
    </div>
    <div style="margin: 16px 24px -10px;">
      <b>{{ name }} {{ type }} Campaign Perf</b>
    </div>
    <!-- table -->
    <div class="section">
      <a-table
        :columns="columns"
        :data-source="dataList"
        :pagination="false"
        :rowKey="(record) => record.campaign_name + record.type"
        size="middle"
        bordered
        :loading="loading"
      >
      </a-table>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
const defaultStartDate = moment()
  .startOf("day")
  .subtract(7, "days")
  .format("YYYY-MM-DD");
const defaultEndDate = moment()
  .startOf("day")
  .subtract(1, "days")
  .format("YYYY-MM-DD");

const columns = [
  {
    title: "Campaign Name",
    dataIndex: "campaign_name",
    sorter: (a, b) => {
      return a.name.localeCompare(b.name);
    },
  },
  {
    title: "Cpn Type",
    scopedSlots: { customRender: "adwCampaignPerf" },
    dataIndex: "type",
    sorter: (a, b) => {
      return a.cpn_type.localeCompare(b.cpn_type);
    },
  },
  {
    title: "Sessions",
    dataIndex: "sessions",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.sessions - b.sessions;
    },
  },
  {
    title: "PV",
    dataIndex: "page_views",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.page_views - b.page_views;
    },
  },
  {
    title: "Avg.CPC",
    dataIndex: "avg_cpc",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(3)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.avg_cpc - b.avg_cpc;
    },
  },
  {
    title: "CPC 上限",
    dataIndex: "cpc_top",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(3)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.cpc_top - b.cpc_top;
    },
  },
  {
    title: "花费",
    dataIndex: "spent",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.spent - b.spent;
    },
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.spent - b.spent;
    },
  },
];

export default {
  data() {
    return {
      userInfo: {},
      columns,
      dataList: [],
      loading: false,
      name: "",
      site: "",
      type: "",
      defaultStartDate,
      defaultEndDate,
      dateString: [defaultStartDate, defaultEndDate],
    };
  },
  // 定义方法
  methods: {
    // 修改时间
    onDateChange(date, dateString) {
      this.dateString = dateString;
    },

    onCommit() {
      this.getList();
    },

    // 获取数据列表
    getList() {
      this.loading = true;
      this.$post(api.okr.getAdwPerfByCampaign, {
        token: this.userInfo.token,
        site: this.site,
        type: this.type,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataList = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
  // 页面初始化
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }

    if (this.$route.query.site !== undefined) {
      this.name = this.$route.query.name;
      this.site = this.$route.query.site;
      this.type = this.$route.query.type;
      this.dateString[0] = this.$route.query.start_date;
      this.dateString[1] = this.$route.query.end_date;
    }

    this.getList();
  },
};
</script>

<style scoped>
.adw-perf {
  font-family: "Roboto";
}
</style>
