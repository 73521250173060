<template>
  <div>
    <div class="ant-page-header-tl">
      <a-range-picker :default-value="dateString" @change="onDateChange"/>
      <a-button type="primary" @click="getData" style="margin-left: 8px">Apply</a-button>
    </div>
    <div style="margin: 16px 24px -10px;"><b>{{start_date}} ～ {{end_date}}</b><span style="margin-left: 24px">Compare to</span><b style="margin-left: 24px">{{start_date_before}} ～ {{end_date_before}}</b></div>
    <div class="section-wrapper" style=" margin-left: 24px; margin-right: 300px;float: left;">
          <a-card :bordered="false" title="每日宗数（GA3）">
            <a-table
              :columns="columns1"
              :data-source="dayzumList.list"
              :rowKey="(record) => record.name + record.page_views + record.sessions"
              :pagination="false"
              size="small"
              bordered
              :loading="loading"
              style="margin-bottom: 24px"
              class="znum-data-table"
            >
            <div slot="z_num_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text +'%'}}</span><span v-else style="color: #c53929">{{text+'%'}}</span></div>
              <div
                class="smallbtn"
                slot="action"
                slot-scope="text, record"
                style="padding: 4px 0px"
              >
                <a-button type="primary" size="small" @click="goDetail(record)"
                  >Check</a-button
                >
              </div>
              <div slot="z_num" slot-scope="text">
                <b>{{
                  text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
                }}</b>
              </div>
              <span v-if="record.name=='Total Team'" slot="toline" slot-scope="text"><b>{{text}}</b></span>
            </a-table>
          </a-card>
        </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
const defaultStartDate = moment().startOf('day').subtract(2, 'days');
const defaultEndDate = moment().startOf('day').subtract(2, 'days');

const columns1 = [
  {
    title: "平台",
    dataIndex: "name",
    align: "left",
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    width: 100,
    scopedSlots: { customRender: "z_num" },
    sorter: (a, b) => {
        if(!a.name.includes("Total Team")) {
          return a.z_num - b.z_num
        }
      }
  },
  {
    title: "宗数 DOD",
    dataIndex: "z_num_dod",
    align: "right",
    width: 100,
    scopedSlots: { customRender: "z_num_dod" },
    sorter: (a, b) => {
        if(!a.name.includes("Total Team")) {
          return a.z_num - b.z_num
        }
      }
  },
  {
    title: "Session",
    dataIndex: "sessions",
    align: "right",
    width: 100,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
        if(!a.name.includes("Total Team")) {
          return a.sessions - b.sessions
        }
      }
  },
  {
    title: "P/S",
    dataIndex: "pages_per_session",
    align: "right",
    width: 100,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
        if(!a.name.includes("Total Team")) {
          return a.pages_per_session - b.pages_per_session
        }
      }
  },
  {
    title: "Ads CTR",
    dataIndex: "ads_ctr",
    align: "right",
    customRender: (text) => {
      return text + "%";
    },
    width: 100,
    sorter: (a, b) => {
        if(!a.name.includes("Total Team")) {
          return a.ads_ctr - b.ads_ctr
        }
      }
  },
  {
    title: "Ads CPC",
    dataIndex: "ads_cpc",
    align: "right",
    width: 100,
    sorter: (a, b) => {
        if(!a.name.includes("Total Team")) {
          return a.ads_cpc - b.ads_cpc
        }
      }
  },
  {
    title: "CPC上限",
    dataIndex: "cpc_top",
    align: "right",
    width: 100,
    sorter: (a, b) => {
        if(!a.name.includes("Total Team")) {
          return a.cpc_top - b.cpc_top
        }
      }
  },
  {
    title: "Avg.CPC",
    dataIndex: "avg_cpc",
    align: "right",
    width: 100,
    sorter: (a, b) => {
        if(!a.name.includes("Total Team")) {
          return a.avg_cpc - b.avg_cpc
        }
      }
  },
  {
    title: "花费",
    dataIndex: "spent",
    align: "right",
    width: 100,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
        if(!a.name.includes("Total Team")) {
          return a.spent - b.spent
        }
      }
  },
  {
    title: "宗数/花费",
    dataIndex: "z_s",
    align: "right",
    customRender: (text) => {
      return text + "%";
    },
    width: 100,
    sorter: (a, b) => {
        if(!a.name.includes("Total Team")) {
          return a.z_s - b.z_s
        }
      }
  },
  {
    title: "By Day",
    scopedSlots: { customRender: "action" },
    align: "center",
    width: 100,
  },
];

export default {
  data() {
    return {
      moment,
      userInfo: {},
      columns1,
      loading: false,
      okrList_2: [],
      dayzumList: {},
      quarterList: [],
      defaultStartDate,
      defaultEndDate,
      start_date: '',
      end_date: '',
      start_date_before: '',
      end_date_before:'',
      dateString: [defaultStartDate.format('YYYY-MM-DD'),defaultEndDate.format('YYYY-MM-DD')]
    };
  },
  methods: {
    goDetail(record) {
      const { href } = this.$router.resolve({
        path: "/zumexpress-detail",
        query: {
          slug: record.slug,
          name: record.name,
        },
      });
      window.open(href, "_blank");
    },
    getDailyPerf() {
      this.loading = true;
      this.$post(api.okr.getAllSitesZumData, {
          token: this.userInfo.token,
          // end_date: this.dateString,
          start_date: this.dateString[0],
          end_date: this.dateString[1],
          tag: 1
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status === 200) {
            this.dayzumList = res.data.data;
            this.start_date = this.dayzumList.date.start_date
            this.end_date = this.dayzumList.date.end_date
            this.start_date_before = this.dayzumList.date.start_date_before
            this.end_date_before = this.dayzumList.date.end_date_before
          } else {
            this.$message.error(res.data.message);
          }
        });
    },

    onDateChange(date, dateString) {
      this.dateString = dateString;
    },
    getData() {
      this.getDailyPerf();
    },
  },
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.getDailyPerf();
  },
};
</script>

<style>
.total-item {
  margin-right: 10px;
  margin-left: 10px;
  color: #ddd;
}
 .znum-data-table tr:last-child td{
    border-top: 3px solid #e1e1e1;
  }
</style>