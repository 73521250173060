<template>
  <div>
    <!-- <div class="ant-page-header-tl"><a-date-picker :default-value="defaultSelectDate" @change="onDateChange" /></div> -->
    <div class="section inputedit">
      <a-table
        :columns="columns"
        :data-source="dataList"
        :rowKey="(record) => record.campaign_id + record.ads_cpc"
        :pagination="false"
        size="middle"
        bordered
        :loading="loading"
        :scroll="{ x: 2000 }"
      >
        <div
          slot="campaign_id"
          slot-scope="text"
          style="width: 103px; overflow: hidden"
        >
          {{ text }}
        </div>
        <div
          slot="campaign_name"
          slot-scope="text, record"
          style="
            width: 500px;
            overflow: hidden;
            text-decoration: underline;
            cursor: pointer;
          "
          @click="toLine(record.campaign_id, dateString[0], dateString[1])"
        >
          {{ text }}
        </div>
        <div slot="suggest_cpc" slot-scope="text, record">
          <input
            class="inputtxt"
            type="text"
            v-model="record.suggest_cpc"
            @blur="inputBlur(record.suggest_cpc)"
          />
        </div>
        <!-- <div slot="suggest_cpc1" slot-scope="text, record">
          {{
            parseFloat(
              (
                record.cpc_top -
                (record.avg_cpc - record.default_cpc)
              ).toString()
            )
              .toFixed(3)
              .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
          }}
        </div> -->

        <div slot="daily_budget" slot-scope="text, record">
          <input class="inputtxt" type="text" v-model="record.daily_budget" />
        </div>
      </a-table>
    </div>
    <div class="section noCover">
      <div style="text-align: right;">
        <a-button
          style="margin-bottom: 16px;"
          type="primary"
          @click="submitCheck"
          >Apply</a-button
        >
      </div>

      <a-table
        :columns="column2"
        :data-source="dataList2"
        :rowKey="(record) => record.site_name"
        :pagination="false"
        size="middle"
        bordered
        :loading="loading"
      >
        <div class="smallbtn" slot="pause">
          <a-switch @change="pause" />
        </div>
        <div class="smallbtn" slot="bid" slot-scope="text, record">
          <a-input-number
            v-model="record.suggest_percent"
            :defaultValue="text"
            :step="5"
            :min="suggestPercentMin"
            :formatter="(value) => `${value}%`"
            :parser="(value) => value.replace('%', '')"
            @change="(value) => onChange(value, record)"
          />
        </div>

        <span slot="suggest_bid" slot-scope="text, record">{{
          parseFloat(
            (record.cpc_top - (record.avg_cpc - record.bid_before)).toString()
          )
            .toFixed(3)
            .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
        }}</span>

        <div slot="is_block" slot-scope="text, record">
          <a-switch @change="(value) => blockChange(value, record)" />
        </div>
      </a-table>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
const defaultSelectDate = moment()
  .startOf("day")
  .subtract(2, "days");

const columns = [
  {
    title: "Budget%",
    dataIndex: "budget_ratio",
    width: 100,
    customRender: (text) => {
      return (text * 100).toFixed(2) + "%";
    },
    align: "right",
  },
  {
    title: "Bid Strategy",
    dataIndex: "bid_type",
    width: 120,
  },
  {
    title: "Campaign ID",
    dataIndex: "campaign_id",
    width: 130,
    scopedSlots: { customRender: "campaign_id" },
  },
  {
    title: "Campaign Name",
    dataIndex: "campaign_name",
    width: 520,
    scopedSlots: { customRender: "campaign_name" },
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    width: 90,
    scopedSlots: { customRender: "start_date" },
  },
  {
    title: "Session",
    dataIndex: "sessions",
    customRender: (text) => {
      if (text) {
        return text;
      } else {
        return "/";
      }
    },
    width: 100,
    align: "right",
  },
  {
    title: "PV",
    dataIndex: "page_views",
    align: "right",
    width: 80,
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Ads CTR",
    dataIndex: "ads_ctr",
    align: "right",
    customRender: (text) => {
      if (text) {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "%";
      } else {
        return "/";
      }
    },
    width: 100,
  },
  {
    title: "Ads CPC",
    dataIndex: "ads_cpc",
    align: "right",
    customRender: (text) => {
      if (text) {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      } else {
        return "/";
      }
    },
    width: 100,
  },
  {
    title: "P/S",
    dataIndex: "pages_per_session",
    align: "right",
    width: 100,
  },
  {
    title: "CPC 上限",
    dataIndex: "cpc_top",
    align: "right",
    width: 100,
  },
  {
    title: "Average CPC",
    dataIndex: "avg_cpc",
    align: "right",
    width: 140,
  },
  {
    title: "CPC%",
    dataIndex: "ratio",
    align: "right",
    width: 100,
    customRender: (text) => {
      return (text * 100).toFixed(2) + "%";
    },
  },
  {
    title: "Default CPC Before",
    dataIndex: "default_cpc",
    align: "right",
    width: 180,
  },
  {
    title: "Default CPC After",
    dataIndex: "suggest_cpc",
    align: "right",
    scopedSlots: { customRender: "suggest_cpc" },
    width: 150,
  },
  {
    title: "Suggested Default CPC After",
    dataIndex: "x",
    customRender: (text) => {
      return text.toFixed(3);
    },
    width: 150,
    align: "right",
  },
  {
    title: "Budget",
    dataIndex: "daily_budget",
    align: "right",
    width: 100,
    scopedSlots: { customRender: "daily_budget" },
  },
  {
    title: "Monthly Budget",
    dataIndex: "monthly_budget",
    align: "right",
    width: 160,
    customRender: (text) => {
      if (text) {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      } else {
        return "/";
      }
    },
  },
];
const column2 = [
  {
    title: "Site Name",
    dataIndex: "site_name",
    sorter: (a, b) =>
      a.site_name.toString().localeCompare(b.site_name.toString()),
  },
  {
    title: "Session",
    dataIndex: "sessions",
    align: "right",
    sorter: (a, b) => a.sessions - b.sessions,
  },
  {
    title: "PV",
    dataIndex: "page_views",
    align: "right",
    sorter: (a, b) => a.page_views - b.page_views,
  },
  {
    title: "CPC 上限",
    dataIndex: "cpc_top",
    align: "right",
    // customRender: text => {if(text){return text}else{return '/'}},
    sorter: (a, b) => a.cpc_top - b.cpc_top,
  },
  {
    title: "Average CPC",
    dataIndex: "avg_cpc",
    align: "right",
    // customRender: text => {
    //   return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
    // },
    sorter: (a, b) => a.avg_cpc - b.avg_cpc,
  },
  {
    title: "CPC%",
    dataIndex: "cpc_percent",
    align: "right",
    customRender: (text) => {
      return text.toFixed(2) + "%";
    },
    sorter: (a, b) => a.cpc_percent - b.cpc_percent,
  },
  {
    title: "Bid Before",
    dataIndex: "bid_before",
    align: "right",
    sorter: (a, b) => a.bid_before - b.bid_before,
  },
  {
    title: "Suggested Bid",
    dataIndex: "x",
    customRender: (text) => {
      return text.toFixed(3);
    },
    width: 100,
    align: "right",
    sorter: (a, b) => a.x - b.x,
  },
  {
    title: "自动调价结果",
    dataIndex: "auto_adj_cpc",
    width: 100,
    align: "right",
    sorter: (a, b) => a.auto_adj_cpc - b.auto_adj_cpc,
  },
  {
    title: "Suggested CPC After",
    dataIndex: "bid_after",
    align: "right",
    customRender: (text) => {
      return text.toFixed(3);
    },
    sorter: (a, b) => a.bid_after - b.bid_after,
  },
  {
    title: "+/-",
    dataIndex: "suggest_percent",
    scopedSlots: { customRender: "bid" },
    align: "center",
    width: 100,
  },
  {
    title: "Is Block",
    dataIndex: "is_block",
    scopedSlots: { customRender: "is_block" },
    align: "center",
    width: 100,
  },
];

export default {
  data() {
    return {
      curRecord: {},
      title: "Suggested Bid",
      visible: false,
      userInfo: {},
      curItem: {},
      platform: "",
      curtable_id: 0,
      suggest_id: 0,
      bid_value: 0,
      default_cpc: "",
      bid_after: "",
      site: "",
      site_id: 0,
      site_list: [],
      defaultSelectDate,
      url: "",
      columns,
      column2,
      dataList: [],
      dataList2: [],
      loading: false,
      ediTtitleLoading: false,
      size: "small",
      dateString: defaultSelectDate.format("YYYY-MM-DD"),
      suggestCpc: "",
    };
  },
  computed: {
    suggestPercentMin: function() {
      // 11293代表2022.02.28之后新建的campaign，最低出价为0.01
      return Number(
        (
          ((this.platform == "outbrain"
            ? this.curItem.id <= 11293
              ? "0.03"
              : "0.01"
            : "0.01") /
            this.suggestCpc) *
            100 -
          100
        ).toFixed(0)
      );
    },
    // suggestCpc: function() {
    //   return Number(this.curItem.suggest_cpc) == 0 ? '1' : this.curItem.suggest_cpc;
    // },
  },
  methods: {
    inputBlur(text) {
      // this.suggestCpc = text
      this.suggestCpc = Number(text) == 0 ? "1" : text;
      console.log(text);
      if (this.curItem.default_cpc != text) {
        this.curItem.default_cpc = text;
        // console.log(this.dataList2)
        this.dataList2.forEach((item) => {
          item.bid_after = (text * (100 + item.suggest_percent)) / 100;
          // console.log(item.suggest_percent)
        });
      }
    },
    getList() {
      this.loading = true;
      this.$post(api.bysite.getSitesByCampaign, {
        token: this.userInfo.token,
        campaign_id: this.curItem.campaign_id,
        suggest_cpc: this.curItem.suggest_cpc,
        platform: this.platform,
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          let data = res.data.data;
          this.dataList2 = data;
          data.forEach((item) => {
            let site_json = {
              bid_value: item.suggest_percent,
            };
            if (this.platform == "outbrain") {
              site_json.site = item.site_name;
              site_json.site_id = item.site_id;
            } else if (this.platform == "taboola") {
              site_json.site = item.site;
            }
            // console.log(site_json)
            this.site_list.push(site_json);
            this.bid_after = item.bid_after;
          });
          this.initBlockList();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    goCampaignList(url) {
      const { href } = this.$router.resolve({
        path: "/recampaignlist",
        query: {
          platform: this.curItem.platform,
          url: url,
        },
      });
      window.open(href, "_blank");
    },
    pause() {},
    onChange(value, record) {
      // console.log(value)
      let newBidAfter = (this.curItem.suggest_cpc * (100 + value)) / 100;
      // console.log(this.curItem.id)
      // console.log(this.platform)
      // console.log(newBidAfter)
      if (newBidAfter <= 0.01 && this.platform == "taboola") {
        newBidAfter = 0.01;
        record.bid_after = newBidAfter;
        this.$message.error("已达到最低值0.01");
        return false;
      } else if (
        newBidAfter <= 0.03 &&
        this.platform == "outbrain" &&
        this.curItem.id <= 11293
      ) {
        console.log(this.curItem.id);
        newBidAfter = 0.03;
        record.bid_after = newBidAfter;
        this.$message.error("已达到旧Campaign最低值0.03");
        return false;
      } else if (
        newBidAfter <= 0.01 &&
        this.platform == "outbrain" &&
        this.curItem.id > 11293
      ) {
        newBidAfter = 0.01;
        record.bid_after = newBidAfter;
        this.$message.error("已达到新Campaign最低值0.01");
        return false;
      }
      this.site_list.forEach((item) => {
        if (
          (item.site_id == record.site_id && this.platform == "outbrain") ||
          (item.site == record.site && this.platform == "taboola")
        ) {
          item.bid_value = value;
        }
      });
      record.bid_after = newBidAfter;
      record.suggest_percent = value;
    },
    submitCheck() {
      if (this.curItem.suggest_cpc > 0.12) {
        this.$confirm({
          title: "Please Notice！",
          content: "Your Default CPC is over 0.12, Doublecheck Before Change.",
          okText: "Save",
          onOk: () => {
            this.submitData();
          },
          onCancel: () => {
            console.log("Cancel");
          },
        });
      } else {
        this.submitData();
      }
    },
    submitData() {
      this.loading = true;
      let params = {
        default_cpc: this.curItem.suggest_cpc,
        daily_budget: this.curItem.daily_budget,
        site_list: this.site_list,
      };
      this.$post(api.bysite.adjustSiteCpc, {
        token: this.userInfo.token,
        campaign_id: this.curItem.campaign_id,
        params: params,
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.$message.success("提交成功");
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    //  初始化开关状态
    initBlockList() {
      this.site_list.forEach((item) => {
        item.is_block = false;
      });
    },
    //  开关状态改变 修改字段(push测试)
    blockChange(checked, record) {
      this.site_list.forEach((item) => {
        if (
          (item.site_id == record.site_id && this.platform == "outbrain") ||
          (item.site == record.site && this.platform == "taboola")
        ) {
          item.is_block = checked;
        }
      });
    },
  },

  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }

    this.curItem = JSON.parse(this.$route.query.record);
    this.suggestCpc = this.curItem.suggest_cpc;
    this.dataList.push(this.curItem);
    this.default_cpc = this.curItem.default_cpc;
    this.platform = this.$route.query.platform;
    this.getList();
  },
};
</script>

<style>
.noCover .editor-box {
  top: 0px;
  padding: 8px;
  bottom: auto;
}
.noCover .editor-btn {
  margin-top: 8px;
}
.noCover .edittitle {
  bottom: 2px;
  right: 2px;
}
.noCover .edit_budget:hover .edittitle {
  opacity: 1;
}

.bySite .editor-box {
  top: 0px;
  padding: 8px;
  bottom: auto;
}
.bySite .editor-btn {
  margin-top: 8px;
}
.bySite .edittitle {
  bottom: 2px;
  right: 2px;
}
.bySite .edit_budget:hover .edittitle {
  opacity: 1;
}
.inputedit .inputtxt {
  border: none;
  text-align: right;
  background: none;
  width: 80px;
}
</style>
