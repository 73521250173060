<template>
    <div>
        <div class="ant-page-header-tl">
            <span style="margin-left: 10px; margin-right: 5px;">Platform:</span>
            <a-select
                defaultValue="outbrain"
                style="width: 120px; margin-right: 16px;"
                @change="handlePlatformChange"
                >
                <a-select-option
                    v-for="(item, index) in platformList"
                    :key="index"
                    :value="item.name"
                    >
                    {{ item.name }}
                </a-select-option>
            </a-select>
            <span style="margin-left: 10px; margin-right: 5px;">Date:</span>
                <a-range-picker
                style="width: 210px; margin-right: 16px;"
                @change="handleCustomChange"
                v-model="autoDate"
            />
            <span style="margin-left: 10px; margin-right: 5px;">Time:</span>
            <a-select
                :defaultValue = "hour"
                style="width: 120px; margin-right: 16px;"
                @change="handleHourChange"
                >
                <a-select-option
                    v-for="(item, index) in hourList"
                    :key="index"
                    :value="item"
                    >
                    {{ item }}
                </a-select-option>
            </a-select>
            <span style="margin-left: 10px; margin-right: 5px;">CPC% > = </span>
            <a-input style="width: 80px;" v-model="cpc_per"/>
            <a-button v-if="editorId"
                type="primary"
                style="margin-left: 16px;"
                @click="handleEditor"
                >Edit No.{{ Id }} Task
            </a-button>
            <a-button v-if="editorId === 0"
                type="primary"
                style="margin-left: 16px;"
                @click="handleApply"
                >自动定时Block
            </a-button>
            <a-button
                type="primary"
                style="margin-left: 16px;"
                @click="handleTask"
                >Task List
            </a-button>
        </div>
        <div class="section">
            <div v-if="editorId">
                <div v-for="(accounts, key) in clickRule" :key="key" class="form-tl">
                <b>{{ key }} </b>
                    <div class="form-tl-item" v-for="(accountName, index) in accounts" :key="index">
                        <div>{{ index }}: </div>
                        <a-input style="width: 80px; margin-right: 16px;" v-model="clickRule[key][index].clicks"/>
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-for="(accounts, key) in accountList" :key="key" class="form-tl">
                <b>{{ key }} </b>
                <div class="form-tl-item" v-for="(accountName, index) in accounts" :key="index">
                    <div>{{ index }}: </div>
                    <a-input style="width: 80px; margin-right: 16px;" v-model="clickRule[key][index].clicks"/>
                </div>
            </div>
            </div>
            <span>Notes：<br>
                Date 表示希望工具自动操作的时间段，选几天就会产生几个定时任务<br>
                Time 表示希望工具开始自动操作的时间点<br>
                CPC% >= 表示工具会 block 掉 Avg CPC/CPC Top 超过设定值的 site<br>
                *需要让工具操作哪些账户，就在哪些账户旁边的框内写上数字，数字代表 click 值，工具会筛选该值以上（包括该值），符合 block 条件的 site<br>
                *填写完成后，选择“自动定时Block”，即代表完成设定。进入“Task List”查看任务列表<br>
            </span>
        </div>
    </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
export default {
  data() {
    return {
        editorId:0,
        editordate:[],
        Id: 0,
        userInfo: {},
        cpc_per: 0,
        clickRule: {},
        accountList: [],
        hourList: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        hour: 10,
        isShowTotal: false,
        platform: "Outbrain",
        platformList: [
            {
            id: 1,
            name: "Outbrain",
            },
            {
            id: 2,
            name: "Taboola",
            },
        ],
        period: [
            moment().format("YYYY-MM-DD"),
            moment().add(7, "day").format("YYYY-MM-DD"),
        ],
        autoDate:[],
        };
    },
    methods: {
        handleTask(){
            this.$router.push({ path: "/autoblocktask" });
        },
        handlePlatformChange(e) {
            this.platform = e;
            this.getAccountList()
        },
        handleCustomChange(date, dateString) {
            this.autoDate = dateString;
        },
        handleHourChange(e) {
            this.hour = e;
        },
        getAccountList() {
            this.$post(api.bysite.getAccountByPlatform,{ 
                token: this.userInfo.token, 
                platform: this.platform 
            }).then((res) => {
                if(res.status == 200){
                    this.accountList = res.data.data;
                    let array = this.accountList
                    //将array数组赋值为clickRule
                    // array内容为{"bs":{bs: "bs", bs_ios: "bsios"}, "fd": {fd: "fd", fd_ios: "fdios", fd_bf: "fdbf", fd_ios_bf: "fdiosbf"}}
                    // clickRule内容为{"bs":{bs: {clicks: null}, bs_ios: {clicks: null}}, "fd": {fd: {clicks: null}, fd_ios: {clicks: null}, fd_bf: {clicks: null}, fd_ios_bf: {clicks: null}}}
                    // 按照accountList，accountList中每个site（如bs）下的accoutName对应一个clickRule，初始化clickRule

                    this.clickRule = JSON.parse(JSON.stringify(array));
                    Object.keys(this.clickRule).forEach(site => {
                        Object.keys(this.clickRule[site]).forEach(accountName => {
                            this.clickRule[site][accountName] = { clicks: null };
                        });
                    });
                }
                else{
                    this.$message.error(res.message);
                }
            });
        },
        getEditorData(){
            this.$post(api.bysite.getSiteBlockCornById, { token: this.userInfo.token, cron_id: this.Id }).then((res) => {
                if(res.status == 200){
                    this.autoDate = [res.data.data.date, res.data.data.date];
                    this.hour = res.data.data.hour;
                    this.cpc_per = res.data.data.cpc_per;
                    this.clickRule = res.data.data.cron_content;
                }
                else{
                    this.$message.error(res.message);
                }
            });
        },
        handleEditor(){
            let data = {
                token: this.userInfo.token,
                cron_id: this.Id,
                platform: this.platform,
                start_date: this.autoDate[0],
                end_date: this.autoDate[1],
                hour: this.hour,
                cron_content: this.clickRule,
                cpc_per: this.cpc_per,
                is_del: 0,
            }
            this.$post(api.bysite.updateSiteBlockCorn, data).then((res) => {
                if(res.status == 200){
                    this.$message.success(res.message);
                }
                else{
                    this.$message.error(res.message);
                }
            });
        },
        handleApply(){
            //处理this.cpc_per, 删除所有clicks为null的项
            Object.keys(this.clickRule).forEach(site => {
                Object.keys(this.clickRule[site]).forEach(accountName => {
                    if(this.clickRule[site][accountName].clicks == null){
                        delete this.clickRule[site][accountName];
                    }
                });
                // 如果 site 下没有任何 accountName，则删除该 site
                if (Object.keys(this.clickRule[site]).length === 0) {
                    delete this.clickRule[site];
                }
            });

            let data = {
                token: this.userInfo.token,
                platform: this.platform,
                start_date: this.autoDate[0],
                end_date: this.autoDate[1],
                hour: this.hour,
                cron_content: this.clickRule,
                cpc_per: this.cpc_per,
            }
            //如果start_date为空字符串，或者end_date为空字符串，或者cron_content为空数组，则提示用户不能提交
            if(data.start_date == "" || data.end_date == "" || Object.keys(data.cron_content).length == 0){
                this.$message.error("Please fill in the required fields");
                return false;
            }
            this.$post(api.bysite.addSiteBlockCorn, data).then((res) => {
                if(res.status == 200){
                    this.$message.success(res.message);
                }
                else{
                    this.$message.error(res.message);
                }
            });
        },
    },
    created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    //如果路由中含有id，则为编辑页面，获取id对应的数据
    if (this.$route.query.id) {
      this.Id = this.$route.query.id;
      this.getEditorData();
      this.editorId = 1;
    }
    else
    this.getAccountList();
  },
};
</script>
<style scoped>
.form-tl{
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
}
.form-tl-item{
    display: flex;
    justify-content: space-between;
    gap: 5px;
    margin: 5px 0;
}

</style>