<template>
  <div>
    <div class="ant-page-header-tl">
      <span style="margin-right:5px">Platform:</span>
      <a-select
        defaultValue="Taboola"
        style="width: 150px"
        @change="onPlatformChange"
      >
        <a-select-option
          v-for="(item, index) in platformList"
          :key="index"
          :value="item"
          >{{ item }}</a-select-option
        >
      </a-select>
      <span style="margin-left:16px;margin-right:5px">Date:</span>
      <a-range-picker :default-value="dateString" @change="onDateChange" />
    </div>
    <!-- table -->
    <div class="section">
      <a-card :bordered="false" :title="project">
        <a-table
          :columns="columns"
          :data-source="dataList"
          :pagination="false"
          :rowKey="(record) => record.landing_page"
          size="middle"
          bordered
          :loading="loading"
        >
          <div
            style="text-decoration: underline;cursor: pointer;"
            slot="tocampaign"
            slot-scope="text, record"
            @click="toCampaign(record)"
          >
            {{ text }}
          </div>
          <div class="smallbtn" slot="member">
            {{ name }}
          </div>
        </a-table>
      </a-card>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
const defaultStartDate = moment().startOf('quarter').format("YYYY-MM-DD");
const defaultEndDate = moment()
  .startOf("day")
  .subtract(2, "days");
const platformList = ["Taboola", "Outbrain"];
const columns = [
  {
    title: "文章",
    dataIndex: "landing_page",
    scopedSlots: { customRender: "tocampaign" },
    sorter: (a, b) => {
      return a.landing_page.localeCompare(b.landing_page);
    },
  },
  {
    title: "Member",
    scopedSlots: { customRender: "member" },
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.z_num - b.z_num;
    },
  },
];

export default {
  data() {
    return {
      userInfo: {},
      columns,
      dataList: [],
      name: "",
      name_id: "",
      project_id: "",
      project: "",
      platform: "Taboola",
      platformList,
      loading: false,
      defaultStartDate,
      defaultEndDate,
      dateString: [
        moment(defaultStartDate, "YYYY-MM-DD"),
        moment(defaultEndDate, "YYYY-MM-DD"),
      ],
    };
  },
  // 定义方法
  methods: {
    // 修改时间
    onDateChange(dateString) {
      this.dateString = dateString;
      this.getList();
    },

    // 修改平台
    onPlatformChange(e) {
      this.platform = e;
      this.getList();
    },

    // 跳转到Campaign页面
    toCampaign(param) {
      const { href } = this.$router.resolve({
        path: "/PromoteWorkCampaign",
        query: {
          url: param.landing_page,
          name_id: this.name_id,
          platform: this.platform,
          start_date: this.dateString[0],
          end_date: this.dateString[1],
        },
      });
      window.open(href, "_blank");
    },

    // 获取数据列表
    getList() {
      this.loading = true;
      this.$post(api.okr.getPromoteWorkIndividual, {
        token: this.userInfo.token,
        platform: this.platform,
        project_id: this.project_id,
        name_id: this.name_id,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataList = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
  // 页面初始化
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }

    this.name_id = this.$route.query.name_id;
    this.name = this.$route.query.name;
    this.project_id = this.$route.query.project_id;
    this.project = this.$route.query.project;
    this.platform = this.$route.query.platform;
    this.dateString[0] = this.$route.query.start_date;
    this.dateString[1] = this.$route.query.end_date;
    this.getList();
  },
};
</script>
