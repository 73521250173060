<template>
  <div>
    <a-spin :spinning="spinning">
    <div style="margin: 16px 24px -10px;"><b>{{start_date}} ～ {{end_date}}</b></div>
    <div class="section">
      <div style="margin-bottom: 16px;"><b>Total Top Num:</b> {{total_top_num}}  <span class="total-item">| </span> <b>Total Covered Num:</b> {{total_covered_num}}</div>
      <a-tabs type="card" v-model="activeKey" @change="paneChange">
        <a-tab-pane v-for="(pane) in panes" :key="pane.key" :tab="pane.title">
          <div v-if="levelData[pane.key]">
            <div style="margin-bottom: 8px;border-bottom: 1px solid #eee;padding-bottom: 16px;">
              <b style="color: #333;">Standard: </b> {{levelData[pane.key].standard}} <span class="total-item">| </span>
              <b style="color: #333;">Top Num: </b> {{levelData[pane.key].top_num}} <span class="total-item">| </span>
              <b style="color: #333;">Covered Num: </b> {{levelData[pane.key].covered_num}}
            </div>
            
            <div class="posturlitem" v-for="(item, index) in levelData[pane.key].top_post_url" :key="index">
              {{item}}
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
    </a-spin>
  </div>
</template>

<script>
  import api from '@/common/api.js'

  const panes = [
    { title: 'level1', key: 'level1' },
    { title: 'level2', key: 'level2' },
    { title: 'level3', key: 'level3' },
  ];

  export default {
    data() {
      return {
        spinning: false,
        panes,
        userInfo: {},
        curItem: {},
        dateString: '',
        start_date: '',
        end_date: '',
        activeKey: 'level1',
        total_top_num: 0,
        total_covered_num: 0,
        levelData: {},
      }
    },
    created(){
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.curItem = JSON.parse(this.$route.query.record)
      this.dateString = this.$route.query.date
      console.log(this.dateString)
      this.getList()
    },
    methods: {
      getList(){
        this.spinning = true
        this.$post(api.update.getTopUrl, {
          token: this.userInfo.token,
          params: {
            platform: this.curItem.platform,
            country: this.curItem.country,
            os: this.curItem.os,
            device: this.curItem.device,
            audience: this.curItem.audience,
            date: this.dateString, 
          }
        }).then(res => {
          this.spinning = false

          if (res.data.status == 200) {
            let data = res.data.data
            this.start_date = data.start_date
            this.end_date = data.end_date
            this.total_top_num = data.total_top_num
            this.total_covered_num = data.total_covered_num
            this.levelData.level1 = data.level1
            this.levelData.level2 = data.level2
            this.levelData.level3 = data.level3

            console.log(this.levelData)
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      paneChange(e){
        this.activeKey = e
        console.log(e)
        
        
      },
    }
  }
</script>

<style>
  .posturlitem{
    line-height: 30px;
  }
</style>
