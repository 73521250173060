<template>
  <div>
    <div class="ant-page-header-tl">
      <span style="margin-right:5px">Platform:</span>
      <a-select
        defaultValue="Taboola"
        style="width: 150px"
        @change="onPlatformChange"
      >
        <a-select-option
          v-for="(item, index) in platformList"
          :key="index"
          :value="item"
          >{{ item }}</a-select-option
        >
      </a-select>
      <span style="margin-left:16px;margin-right:5px">Date:</span>
      <a-range-picker :default-value="dateString" @change="onDateChange" />
    </div>
    <!-- table -->
    <div class="section">
      <a-tabs type="card" v-model="activeKey" @change="paneChange">
        <a-tab-pane key="1" tab="Top文章推广">
          <a-table
            :columns="columns1"
            :data-source="dataList"
            :pagination="{ pageSize: pageSize }"
            :rowKey="(record) => record.url"
            size="middle"
            bordered
            :loading="loading"
          >
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="旧文章潜力挖掘">
          <div style="margin-bottom: 16px;">
            <a-button
              type="primary"
              @click="addOldPost"
              style="background-color: #52c41a;border-color: #52c41a;"
              >Add Old Post</a-button
            >
          </div>
          <a-modal
            title="Add Old Post"
            :visible="aopVisible"
            :confirm-loading="aopConfirmLoading"
            @ok="addOldPostOk"
            @cancel="addOldPostCancel"
          >
          <div style="display:flex;justify-content:center;">
            <div>
              <div style="display:flex;align-item:center;justify-content:flex-end;">
                <span style="margin-right:20px;line-height:32px;">URL:</span>
                <a-input
                  v-model="addOldPostUrl"
                  style="width:200px;"
                  placeholder="" />
              </div>
              <div style="display:flex;align-item:center;justify-content:flex-end;margin-top:20px;">
                <span style="margin-right:20px;line-height:32px;">Platform:</span>
                  <a-select
                    default-value="Taboola"
                    style="width: 200px"
                    @change="platformChange">
                    <a-select-option value="Taboola">
                      Taboola
                    </a-select-option>
                    <a-select-option value="Outbrain">
                      Outbrain
                    </a-select-option>
                  </a-select>
              </div>
            </div>
          </div>
          </a-modal>
          <a-table
            :columns="columns2"
            :data-source="dataList"
            :pagination="{ pageSize: pageSize }"
            :rowKey="(record) => record.url"
            size="middle"
            bordered
            :loading="loading"
          >
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="3" tab="新文章推广">
          <a-table
            :columns="columns3"
            :data-source="dataList"
            :pagination="{ pageSize: pageSize }"
            :rowKey="(record) => record.url"
            size="middle"
            bordered
            :loading="loading"
          >
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="4" tab="其他文章">
          <a-table
            :columns="columns4"
            :data-source="dataList"
            :pagination="{ pageSize: pageSize }"
            :rowKey="(record) => record.url"
            size="middle"
            bordered
            :loading="loading"
          >
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import api from "@/common/api.js";
import moment from "moment";
const defaultStartDate = moment().startOf('quarter').format("YYYY-MM-DD");
const defaultEndDate = moment()
  .startOf("day")
  .subtract(2, "days");
const platformList = ["Taboola", "Outbrain"];

const panes = [
  { title: "Top文章推广", key: "1" },
  { title: "旧文章潜力挖掘", key: "2" },
  { title: "新文章推广", key: "3" },
  { title: "其他文章", key: "4" },
];

const columns1 = [
  {
    title: "Post",
    dataIndex: "url",
    sorter: (a, b) => {
      return a.url.localeCompare(b.url);
    },
  },
  {
    title: "上季度宗数",
    dataIndex: "last_quarter_z_num",
    align: "right",
    sorter: (a, b) => {
      return a.last_quarter_z_num - b.last_quarter_z_num;
    },
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "本季度宗数",
    dataIndex: "this_quarter_z_num",
    align: "right",
    sorter: (a, b) => a.this_quarter_z_num - b.this_quarter_z_num,
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "本季度新Campaign宗数",
    dataIndex: "this_quarter_new_z_num",
    align: "right",
    sorter: (a, b) => a.this_quarter_new_z_num - b.this_quarter_new_z_num,
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Editor",
    dataIndex: "author",
    sorter: (a, b) => {
      return a.author.localeCompare(b.author);
    },
  },
];

const columns2 = [
  {
    title: "Post",
    dataIndex: "url",
    sorter: (a, b) => {
      return a.url.localeCompare(b.url);
    },
  },
   {
    title: "Daily Session",
    dataIndex: "daily_session",
    align: "right",
    sorter: (a, b) => {
      return a.daily_session - b.daily_session;
    },
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(1)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
   {
    title: "CPC上限",
    dataIndex: "cpc_top",
    align: "right",
    sorter: (a, b) => {
      return a.cpc_top - b.cpc_top;
    },
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(3)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
   {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    sorter: (a, b) => {
      return a.z_num - b.z_num;
    },
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "本季度宗数",
    dataIndex: "this_quarter_z_num",
    align: "right",
    sorter: (a, b) => {
      return a.this_quarter_z_num - b.this_quarter_z_num;
    },
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Editor",
    dataIndex: "author",
    sorter: (a, b) => {
      return a.author.localeCompare(b.author);
    },
  },
];

const columns3 = [
  {
    title: "Post",
    dataIndex: "url",
    sorter: (a, b) => {
      return a.url.localeCompare(b.url);
    },
  },
  {
    title: "本季度宗数",
    dataIndex: "this_quarter_z_num",
    align: "right",
    sorter: (a, b) => a.this_quarter_z_num - b.this_quarter_z_num,
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Editor",
    dataIndex: "author",
    sorter: (a, b) => {
      return a.author.localeCompare(b.author);
    },
  },
  {
    title: "上线日期",
    dataIndex: "online_time",
    sorter: (a, b) => {
      return a.online_time.localeCompare(b.online_time);
    },
  },
];

const columns4 = [
  {
    title: "Post",
    dataIndex: "url",
    sorter: (a, b) => {
      return a.url.localeCompare(b.url);
    },
  },
  {
    title: "本季度宗数",
    dataIndex: "this_quarter_z_num",
    align: "right",
    sorter: (a, b) => {
      return a.this_quarter_z_num - b.this_quarter_z_num;
    },
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Editor",
    dataIndex: "author",
    sorter: (a, b) => {
      return a.author.localeCompare(b.author);
    },
  },
];


export default {
  data() {
    return {
      userInfo: {},
      activeKey: "1", // 控制默认标签页
      columns1,
      columns2,
      columns3,
      columns4,
      dataList: [],
      type: 1,
      pageSize: 100,
      loading: false,
      platform: "Taboola",
      platformList,
      panes,
      defaultStartDate,
      defaultEndDate,
      dateString: [
        moment(defaultStartDate, "YYYY-MM-DD"),
        moment(defaultEndDate, "YYYY-MM-DD"),
      ],
      aopVisible:false,
      aopConfirmLoading:false,
      addOldPostUrl:'',
      addOldPostPlatform:'Taboola'
    };
  },
  // 定义方法
  methods: {
    // 修改时间
    onDateChange(dateString) {
      this.dateString = dateString;
      this.getList();
    },

    // 修改平台
    onPlatformChange(e) {
      this.platform = e;
      this.getList();
    },

    // 切换标签页
    paneChange(e) {
      this.type = e;
      this.getList();
    },

    // 获取数据列表
    getList() {
      this.loading = true;
      this.$post(api.okr.getPromotePerf, {
        token: this.userInfo.token,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
        platform: this.platform,
        type: this.type,
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataList = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    addOldPost() {
      this.aopVisible = true
    },
    platformChange(value) {
      this.addOldPostPlatform = value
    },
    addOldPostOk(){
      if(this.addOldPostUrl == '') {
        this.$message.error('请输入url');
        return
      }
      // 发起请求
      this.aopConfirmLoading = true;
      this.$post(api.okr.PreomteOldUrlAdd, {
        token: this.userInfo.token,
        url: this.addOldPostUrl,
        platform: this.addOldPostPlatform,
      }).then((res) => {
        if (res.data.status === 200) {
          this.aopVisible = false;
          this.aopConfirmLoading = false;
          this.$message.success('添加成功');
          this.getList();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    addOldPostCancel() {
      this.aopVisible = false;
      this.aopConfirmLoading = false;
    }
  },

  // 页面初始化
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.getList();
  },
};
</script>

