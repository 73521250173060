<template>
  <div>
    <div class="ant-page-header-tl">Campaign Performance By Day
      <div class="righttop">
        <a-range-picker v-if="period.length" :default-value="[moment(period[0], 'YYYY-MM-DD'), moment(period[1], 'YYYY-MM-DD')]" @change="rangeChange" />
        <a-button type="primary" style="margin-left: 16px;" @click="submitRangeChange">Apply</a-button>
      </div>
    </div>
    <div class="section-wrapper">
      <a-card :loading="loading" :bordered="false" :title="title">
        <a-row>
          <a-col :span="20">
            <ve-line :data="velineData" :legend-visible="false" :settings="chartSettings" :extend="chartExtend"></ve-line>
          </a-col>
          <a-col :span="3" :offset="1">
            <div class="checkitem">
              <a-switch class="pageview" default-checked @change="onPageviewChange" />
              Page View
            </div>
            <div class="checkitem">
              <a-switch class="znum" default-checked @change="onZnumChange" />
              宗数
            </div>
            <div class="checkitem">
              <a-switch class="adscpc" @change="onAdscpcChange" />
              Ads CPC
            </div>
            <div class="checkitem">
              <a-switch class="adsctr" @change="onAdsctrChange" />
              Ads CTR
            </div>
            <div class="checkitem">
              <a-switch class="avgcpc" @change="onAvgcpcChange" />
              Avg.CPC
            </div>
            <div class="checkitem">
              <a-switch class="bouncerate" @change="onBounceRateChange" />
              Bounce Rate
            </div>
            <div class="checkitem">
              <a-switch class="cpctop" @change="onCpcTopChange" />
              CPC TOP
            </div>
            <div class="checkitem">
              <a-switch class="ctr" @change="onCtrChange" />
              CTR
            </div>
            <div class="checkitem">
              <a-switch class="click" @change="onClickChange" />
              Clicks
            </div>
            <div class="checkitem">
              <a-switch class="impression" @change="onImpressiChange" />
              Impressions
            </div>
            <div class="checkitem">
              <a-switch class="pagesession" @change="onPageSessionChange" />
              P/S
            </div>
            <div class="checkitem">
              <a-switch class="session" @change="onSessionChange" />
              Sessions
            </div>
            <div class="checkitem">
              <a-switch class="spent" @change="onSpentChange" />
              Spent
            </div>
            <div class="checkitem">
              <a-switch class="xnum" @change="onXnumChange" />
              系数
            </div>
          </a-col>
        </a-row>
        
        
      </a-card> 
    </div>
    <div class="section-wrapper">
      <a-card :bordered="false">
        <a-table 
          :columns="columns" 
          :data-source="dataList" 
          :pagination="false" 
          :rowKey="record => record.date"
          size="middle"
          bordered
        >
          <!-- <a slot="action">Check</a> -->
        </a-table>
      </a-card>
    </div>
  </div>
</template>
<script>
  import api from '@/common/api.js'
  import moment from 'moment';

  const columns = [
    {
      title:'日期',
      dataIndex: 'date',
      sorter: (a, b) => a.date_sort - b.date_sort,
      // align: 'right',
    },
    {
      title:'Ads CPC',
      dataIndex: 'ads_cpc',
      align: 'right',
      sorter: (a, b) => a.ads_cpc - b.ads_cpc,
    },
    {
      title:'Ads CTR',
      dataIndex: 'ads_ctr',
      align: 'right',
      sorter: (a, b) => a.ads_ctr - b.ads_ctr,
      customRender: text => {
        return text + '%'
      },
    },
    {
      title:'Avg.CPC',
      dataIndex: 'avg_cpc',
      align: 'right',
      sorter: (a, b) => a.avg_cpc - b.avg_cpc,
    },
    {
      title:'Bounce Rate',
      dataIndex: 'bounce_rate',
      align: 'right',
      sorter: (a, b) => a.bounce_rate - b.bounce_rate,
      customRender: text => {
        return text + '%'
      },
    },
    {
      title:'Clicks',
      dataIndex: 'clicks',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title:'CPC TOP',
      dataIndex: 'cpc_top',
      align: 'right',
      sorter: (a, b) => a.cpc_top - b.cpc_top,
    },
    {
      title:'CTR',
      dataIndex: 'ctr',
      align: 'right',
      sorter: (a, b) => a.ctr - b.ctr,
      customRender: text => {
        return text + '%'
      },
    },
    {
      title:'CPC%',
      dataIndex: 'cpc_percent',
      className: 'per',
      sorter: (a, b) => a.cpc_percent - b.cpc_percent,
      align: 'right',
      customRender: text => {
        return text + '%'
      },
    },
    {
      title:'Impressions',
      dataIndex: 'impressions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.impressions - b.impressions,
    },
    {
      title:'Page views',
      dataIndex: 'page_views',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.page_views - b.page_views,
    },
    {
      title:'P/S',
      dataIndex: 'page_per_session',
      align: 'right',
      sorter: (a, b) => a.page_per_session - b.page_per_session,
    },
    {
      title:'Sessions',
      dataIndex: 'sessions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.sessions - b.sessions,
    },
    {
      title:'Spent',
      dataIndex: 'spent',
      align: 'right',
      customRender: text => {
        return Math.round(text)
      },
      sorter: (a, b) => a.spent - b.spent,
    },
    {
      title:'系数',
      dataIndex: 'x_num',
      align: 'right',
      customRender: text => {
        return text.toFixed(2)
      },
      sorter: (a, b) => a.x_num - b.x_num,
    },
    {
      title:'宗数',
      dataIndex: 'z_num',
      align: 'right',
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => a.z_num - b.z_num,
    },
    {
      title:'宗数/花费',
      dataIndex: 'z_s',
      className: 'per',
      sorter: (a, b) => a.z_s - b.z_s,
      align: 'right',
      customRender: text => {
        return text + '%'
      },
    },
    // {
    //   title:'pv',
    //   dataIndex: 'page_views',
    //   customRender: text => {
    //     return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
    //   }
    // },
    // {
    //   title:'p/s',
    //   dataIndex: 'ps',
    //   customRender: text => {
    //     return text.toFixed(2)
    //   }
    // },
    // {
    //   title:'宗数',
    //   dataIndex: 'z_num',
    //   key: 'z_num',
    //   customRender: text => {
    //     return text.toFixed(2)
    //   }
    // },
    // {
    //   title:'Detail',
    //   dataIndex: 'action',
    //   scopedSlots: { customRender: 'action' },
    // }
  ]

  export default {
    data() {
      return {
        moment,
        period: [],
        userInfo: {},
        campaign_id: '',
        columns,
        // columns2,
        dataList: [],
        loading: false,  
        title: '',
        allData: [],
        velineData: {
          columns: ['date'],
          rows: []
        }, 
        chartSettings: {
          axisSite: { right: ['z_num','ads_cpc','ads_ctr','avg_cpc','bounce_rate','cpc_top','ctr','pages_per_session','spent', 'x_num'] },
          // yAxisType: ['KMB', 'percent'],
          // yAxisName: ['数值', '比率']
        },
        chartExtend: {
          color: ["#59b1ef", "#1ad4ae"]
        }
      }
    },
    methods: {
      rangeChange(date, dateString){
        this.period = dateString 
      },
      submitRangeChange(){
        this.getPerfLine()
      },
      onPageviewChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'page_views'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
          // this.velineData.columns = this.velineData.columns.filter(item => item !== 'PageView') 
        }else{
          this.velineData.columns.push('page_views')
          this.chartExtend.color.push('#59b1ef')
        }
        console.log(`a-switch to ${checked}`);
      },
      onZnumChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'z_num'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
          // this.velineData.columns = this.velineData.columns.filter(item => item !== 'PageView') 
        }else{
          this.velineData.columns.push('z_num')
          this.chartExtend.color.push('#1ad4ae')
        }
        console.log(`a-switch to ${checked}`);
      },
      onAdscpcChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'ads_cpc'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
          // this.velineData.columns = this.velineData.columns.filter(item => item !== 'PageView') 
        }else{
          this.velineData.columns.push('ads_cpc')
          this.chartExtend.color.push('#fa6f87')
        }
        console.log(`a-switch to ${checked}`);
      },
      onAdsctrChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'ads_ctr'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
          // this.velineData.columns = this.velineData.columns.filter(item => item !== 'PageView') 
        }else{
          this.velineData.columns.push('ads_ctr')
          this.chartExtend.color.push('#ffb980')
        }
        console.log(`a-switch to ${checked}`);
      },
      onAvgcpcChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'avg_cpc'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
          // this.velineData.columns = this.velineData.columns.filter(item => item !== 'PageView') 
        }else{
          this.velineData.columns.push('avg_cpc')
          this.chartExtend.color.push('#c4b4e4')
        }
        console.log(`a-switch to ${checked}`);
      },
      onBounceRateChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'bounce_rate'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
          // this.velineData.columns = this.velineData.columns.filter(item => item !== 'PageView') 
        }else{
          this.velineData.columns.push('bounce_rate')
          this.chartExtend.color.push('#87a996')
        }
        console.log(`a-switch to ${checked}`);
      },
      onCtrChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'ctr'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
          // this.velineData.columns = this.velineData.columns.filter(item => item !== 'PageView') 
        }else{
          this.velineData.columns.push('ctr')
          this.chartExtend.color.push('#d9d0c7')
        }
        console.log(`a-switch to ${checked}`);
      },
      onClickChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'clicks'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
          // this.velineData.columns = this.velineData.columns.filter(item => item !== 'PageView') 
        }else{
          this.velineData.columns.push('clicks')
          this.chartExtend.color.push('#9bbbff')
        }
        console.log(`a-switch to ${checked}`);
      },
      onImpressiChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'impressions'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
          // this.velineData.columns = this.velineData.columns.filter(item => item !== 'PageView') 
        }else{
          this.velineData.columns.push('impressions')
          this.chartExtend.color.push('#fba7d3')
        }
        console.log(`a-switch to ${checked}`);
      },
      onPageSessionChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'page_per_session'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('page_per_session')
          this.chartExtend.color.push('#a294ff')
        }

        console.log(`a-switch to ${checked}`);
      },
      onSessionChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'sessions'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('sessions')
          this.chartExtend.color.push('#80c19b')
        }
        console.log(`a-switch to ${checked}`);
      },
      onSpentChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'spent'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('spent')
          this.chartExtend.color.push('#6578a0')
        }
        console.log(`a-switch to ${checked}`);
      },
      onXnumChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'x_num'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('x_num')
          this.chartExtend.color.push('#c180b1')
        }
        console.log(`a-switch to ${checked}`);
      },
      onCpcTopChange(checked){
        if(!checked){
          this.velineData.columns.forEach((item, index) => {
            if(item == 'cpc_top'){
              this.chartExtend.color.splice(index-1,1)
              this.velineData.columns.splice(index,1)
            }
          })
        }else{
          this.velineData.columns.push('cpc_top')
          this.chartExtend.color.push('#0166a6')
        }
        console.log(`a-switch to ${checked}`);
      },
      getPerfLine(){
        this.loading = true
        this.$post(api.pref.getCampaignPerfByDay, {
          campaign_id: this.campaign_id,
          period: JSON.stringify(this.period),
          token: this.userInfo.token
        }).then(res => {
          // console.log(res)
          this.loading = false
          if (res.data.status === 200) {
            let data = res.data.data
            this.title = data.campaign_info.campaign_name + ' | ' + data.campaign_info.campaign_id
            this.allData = data.chart_data
            data.table_data.forEach(item => {
              item.date_sort = parseInt(item.date.replace(/[-]/g,''))
            })
            this.dataList = data.table_data
            this.velineData.columns.push('page_views')
            this.velineData.columns.push('z_num')
            this.velineData.rows = this.allData
            // console.log(this.velineData)
          } else {
            this.$message.error(res.data.message)
          }
        });
      }
    },
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.campaign_id =  this.$route.query.campaign_id
      this.period = JSON.parse(this.$route.query.period)
      this.getPerfLine()
    }
  };
</script>

<style> 
  .checkitem{
    margin-bottom: 6px;
  }
  .ant-switch-checked.pageview{
    background-color: #59b1ef;
  }
  .ant-switch-checked.znum{
    background-color: #1ad4ae;
  }
  .ant-switch-checked.adscpc{
    background-color: #fa6f87;
  }
  .ant-switch-checked.adsctr{
    background-color: #ffb980;
  }
  .ant-switch-checked.avgcpc{
    background-color: #c4b4e4;
  }
  .ant-switch-checked.bouncerate{
    background-color: #87a996;
  }
  .ant-switch-checked.cpctop{
    background-color: #0166a6;
  }
  .ant-switch-checked.ctr{
    background-color: #d9d0c7;
  }
  .ant-switch-checked.click{
    background-color: #9bbbff;
  }
  .ant-switch-checked.impression{
    background-color: #fba7d3;
  }
  .ant-switch-checked.pagesession{
    background-color: #a294ff;
  }
  .ant-switch-checked.session{
    background-color: #80c19b;
  }
  .ant-switch-checked.spent{
    background-color: #6578a0;
  }
  .ant-switch-checked.xnum{
    background-color: #c180b1;
  }
</style>
