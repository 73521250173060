<template>
  <div>
    <div class="ant-page-header-tl">
      <a-range-picker :default-value="dateString" @change="onDateChange" />
      <a-button type="primary" @click="getDailyPerf" style="margin-left: 8px"
        >Apply</a-button
      >
      <span style="float:right;font-weight:300;">说明：用于查看 R1 与 Data 0 宗数差距。整体数据取自 Data-0-cpc 报告，细分数据取自 R1 报告。</span>
    </div>
    <div class="section">
        <a-table
        size="small"
        :rowKey="(record) => record.id"
        bordered
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :loading="loading"
        defaultExpandAllRows
        style="width: 25%"
        v-if="dataSource.length>0"
      >
    </a-table>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
const defaultStartDate = moment().startOf("day").subtract(2, "days");
const defaultEndDate = moment().startOf("day").subtract(2, "days");

const columns = [
  {
    title: "产品",
    dataIndex: "slug",
    key: "slug",
    align: "left",
    width: 150,
    sorter: (a, b) => {
      return a.slug.localeCompare(b.slug);
    },
  },
  {
    title: "Google",
    dataIndex: "utc_google",
    key: "utc_google",
    align: "right",
    width: 150,
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.utc_google - b.utc_google;
    },
  },
  {
    title: "Bing",
    dataIndex: "pt_bing",
    key: "pt_bing",
    align: "right",
    width: 150,
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.pt_bing - b.pt_bing;
    },
  },
];

export default {
  data() {
    return {
      moment,
      userInfo: {},
      columns,
      dataSource:[],
      loading: false,
      start_date: "",
      end_date: "",
      dateString: [
        defaultStartDate.format("YYYY-MM-DD"),
        defaultEndDate.format("YYYY-MM-DD"),
      ],
    };
  },
  methods: {
    getDailyPerf() {
      this.loading = true;
      this.$post(api.ga4.allSiteTotalZnum, {
        token: this.userInfo.token,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataSource = res.data.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    onDateChange(date, dateString) {
      this.dateString = dateString;
    },
    getData() {
      this.getDailyPerf();
    },
  },
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.getDailyPerf();
  },
};
</script>

<style>
.total-item {
  margin-right: 10px;
  margin-left: 10px;
  color: #ddd;
}
.znum-data-table tr:last-child td {
  border-top: 1px #e8e8e8;
}
.section-wrapper-sp {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
}
.layout-mintit {
  padding-bottom: 6px;
  margin-bottom: 16px;
  font-size: 18px;
  border-bottom: 1px solid rgb(228, 228, 228);
}
.layout-minbox {
    width: calc( 50% - 10px);
    box-sizing: border-box;
    padding: 16px;
    background-color: #fff;
}
</style>
