<template>
  <div>
    <!-- date -->
    <div class="ant-page-header-tl">
      <a-range-picker :default-value="dateString" @change="onDateChange"/>
      <a-button type="primary" style="margin-left: 16px;" @click="submitDateChange">Apply</a-button>
    </div>
    <!-- table -->
    <div class="section">
        <a-table 
          :columns="columns" 
          :data-source="dataList" 
          :rowKey="record => record.platform"
          :pagination="false" 
          size="small"
          bordered
          :loading="loading"
          class="data-table"
        >
            <div class="smallbtn" slot="action" slot-scope="text, record" style="padding: 4px 0px">
              <a-button type="primary" size="small" @click="byDayPerf(record)">Check</a-button>
            </div>
        </a-table>
    </div>
  </div>
</template>
<script>
  import api from '@/common/api.js'
  import moment from 'moment';
  const defaultStartDate = moment().startOf('day').subtract(2, 'days');
  const defaultEndDate = moment().startOf('day').subtract(2, 'days')

  const columns = [
    {
      title:'Platform',
      dataIndex: 'platform',
      sorter: (a, b) => {
        if (!a.platform.includes("Total")) {
          return a.platform.localeCompare(b.platform)
        }
      }
    },
    {
      title:'Total Revenue',
      dataIndex: 'revenue',
      align: 'right',
      customRender: text => {
        return parseFloat(text.toString()).toFixed(2).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        if (!a.platform.includes("Total")) {
          return a.revenue - b.revenue
        }
      }
    },
    {
      title:'Total Spent',
      dataIndex: 'spent',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        if (!a.platform.includes("Total")) {
          return a.spent - b.spent
        }
      }
    },
    {
      title:'ROAS',
      dataIndex: 'roas',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {
        if (!a.platform.includes("Total")) {
          return a.roas - b.roas
        }
      }
    },
    {
      title:'Clicks',
      dataIndex: 'clicks',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        if (!a.platform.includes("Total")) {
          return a.clicks - b.clicks
        }
      }
    },
    {
      title:'Outbound Conversions',
      dataIndex: 'outbound_conversions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        if (!a.platform.includes("Total")) {
          return a.outbound_conversions - b.outbound_conversions
        }
      }
    },
    {
      title:'Outbond CVR',
      dataIndex: 'outbound_cvr',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {
        if (!a.platform.includes("Total")) {
          return a.outbound_cvr - b.outbound_cvr
        }
      }
    },
    {
      title:'Affilite Conversions ',
      dataIndex: 'aff_conversions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        if (!a.platform.includes("Total")) {
          return a.aff_conversions - b.aff_conversions
        }
      }
    },
    {
      title:'Affilite CVR ',
      dataIndex: 'aff_cvr',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {
        if (!a.platform.includes("Total")) {
          return a.aff_cvr - b.aff_cvr
        }
      }
    },
    {
      title: "By Day",
      scopedSlots: {customRender: "action"},
      align: "center",
      width: 100,
    }
  ]

  export default {
    data() {
      return {
        userInfo: {},
        defaultStartDate,
        defaultEndDate,
        columns,
        dataList: [],
        loading: false,
        start_date: '',
        end_date: '',
        dateString: [moment(defaultStartDate, 'YYYY-MM-DD'), moment(defaultEndDate, 'YYYY-MM-DD')]
      }
    },
    // 定义方法
    methods: {
      onDateChange(dateString) {
        this.dateString = dateString
      },
      // 修改日期获取数据
      submitDateChange(){
        this.getList()
      },
      // 获取数据列表
      getList(){
        this.loading = true
        this.$post(api.aff.getAffTotalPerf, {
          start_date: this.dateString[0].format('YYYY-MM-DD'),
          end_date: this.dateString[1].format('YYYY-MM-DD'),
          token: this.userInfo.token
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.dataList = res.data.data.data
            this.start_date = res.data.data.start_date
            this.end_date = res.data.data.end_date
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      // 跳转到by day表现
      byDayPerf(record) {
        const { href } = this.$router.resolve({
          path: '/aff-total-by-day',
          query: {
            platform: record.platform,
          },
        });
        window.open(href, "_blank");
      },
    },

    // 页面初始化
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.getList()
    },
  };
</script>

<style>
  .data-table tr:last-child td{
    border-top: 2px solid #e1e1e1;
  }
</style>
