// const PATH_API = 'http://www.adtool.com/'
// const PATH_API = 'http://api.silveropen.com/'
let PATH_API;
console.log(process.env.NODE_ENV)
// if (process.env.NODE_ENV == "development") {
//   // 开发
//   PATH_API = "http://www.adtool.com/";
// } else if (process.env.NODE_ENV == "production") {
//   // 线上
//   PATH_API = "https://api.silveropen.com/";
// }

PATH_API = 'https://api.silveropen.com/';

let api = {
  PATH_API: PATH_API,
  okr: {
    getL10Total: PATH_API + "api/Look/getL10Total",
    getL12Data: PATH_API + "api/Look/getL12Data",
    getL13Data: PATH_API + "api/Look/getL13Data",
    getL14Data: PATH_API + "api/Look/getL14Data",
    getL15Data: PATH_API + "api/Look/getL15Data",
    getL16Data: PATH_API + "api/Look/getL16Data",
    getL17Data: PATH_API + "api/Look/getL17Data",
    getL19Data: PATH_API + "api/Look/getL19Data",
    getL24Data: PATH_API + "api/Look/getL24Data",
    getL44Data: PATH_API + "api/Look/getL44Data",
    getOkr1Total: PATH_API + "api/Look/getOkr1Total",
    getOkr2Data: PATH_API + "api/Look/getOkr2Data",
    getQuarterPerf: PATH_API + "api/Look/getQuarterPerf",
    getDailyPerf: PATH_API + "api/Look/getDailyPerf",
    getPerfByDay: PATH_API + "api/Look/getPerfByDay",
    getAllSitesData: PATH_API + "api/Look/getAllSitesData",
    getSingleSiteData: PATH_API + "api/Look/getSingleSiteData",
    getSiteList: PATH_API + "api/Look/getSiteList",
    getAllSitesZumData: PATH_API + "api/Look/getAllSitesZumData",
    getPushPerf: PATH_API + "api/Look/getPushPerf",
    getPushLine: PATH_API + "api/Look/getPushLineData",
    getWorkTotal: PATH_API + "api/Look/getWorkTotal",
    getWorkIndividual: PATH_API + "api/Look/getWorkIndividual",
    getWorkCampaign: PATH_API + "api/Look/getWorkCampaign",
    getPromotePerf: PATH_API + "api/Look/getPromotePerf",
    getPromoteWorkTotal: PATH_API + "api/Look/getPromoteWorkTotal",
    getPromoteWorkIndividual: PATH_API + "api/Look/getPromoteWorkIndividual",
    getPromoteWorkCampaign: PATH_API + "api/Look/getPromoteWorkCampaign",
    CampaignTrackingList: PATH_API + "api/Look/campaignTrackingList",
    PreomteOldUrlAdd: PATH_API + "api/Look/preomteOldUrlAdd",
    CampaignTrackingRemarkAdd: PATH_API + "api/Look/campaignTrackingRemarkAdd",
    CampaignTrackingAdd: PATH_API + "api/Look/campaignTrackingAdd",
    splitFlowPerf: PATH_API + "api/Look/splitFlowPerf",
    allSitesPerf: PATH_API + "api/Look/allSitesPerf",
    getAllSitesZumDataNew: PATH_API + "api/Look/getAllSitesZumDataNew",
    getQuarterPerfNew: PATH_API + "api/Look/getQuarterPerfNew",
    getAdwPerfByType: PATH_API + "api/Look/getAdwPerfByType",
    getAdwPerfByCampaign: PATH_API + "api/Look/getAdwPerfByCampaign",
    getAdwPerfByDay: PATH_API + "api/Look/getAdwPerfByDay",
    synAdw: PATH_API + "api/Look/synAdw",
    getAdwTracking: PATH_API + "api/Look/getAdwTracking",
  },
  ga4: {
    getAllSitesZumDataNew: PATH_API + "api/Ga4/getAllSitesZumDataNew",
    getQuarterPerfNew: PATH_API + "api/Ga4/getQuarterPerfNew",
    getPerfByDay: PATH_API + "api/Ga4/getPerfByDay",
    getRevenueCompare: PATH_API + "api/Ga4/getRevenueCompare",
    synAdw: PATH_API + "api/Ga4/synAdw",
    getAllSitesZumDataNew0: PATH_API + "api/Ga4/getAllSitesZumDataNew0",
    getQuarterPerfNew0: PATH_API + "api/Ga4/getQuarterPerfNew0",
    getPerfByDay0: PATH_API + "api/Ga4/getPerfByDay0",
    getAllSitesZumDataNew01: PATH_API + "api/Ga4/getAllSitesZumDataNew01",
    getQuarterPerfNew01: PATH_API + "api/Ga4/getQuarterPerfNew01",
    getPerfByDay01: PATH_API + "api/Ga4/getPerfByDay01",
    bingTimeZoneReport: PATH_API + "api/Ga4/bingTimeZoneReport",
    bingTimeZoneChart: PATH_API + "api/Ga4/bingTimeZoneChart",
    allSiteTotalZnum: PATH_API + "api/Ga4/allSiteTotalZnum",
  },
  pref: {
    getCampaignPerfByDay: PATH_API + "api/Index/getCampaignPerfByDay",
    getCampaignListByPost: PATH_API + "api/Index/getCampaignListByPost",
    getAdwTracking: PATH_API + "api/Look/getAdwTracking",
    getBingSpentList: PATH_API + "api/BingReport/getBingSpentList",
    getBingSpentByCustomer: PATH_API + "api/BingReport/getBingSpentByCustomer",
    getBingSpentDetail: PATH_API + "api/BingReport/getBingSpentDetail",
  },
  filter: {
    getCampaignList: PATH_API + "api/Filter/getCampaignList",
    pauseCampaign: PATH_API + "api/Filter/pauseCampaign",
    archiveCampaign: PATH_API + "api/Filter/archiveCampaign",
    updateCampaign: PATH_API + "api/Filter/updateCampaign",
    operateLabel: PATH_API + "api/Filter/operateLabel",
    getLabelList: PATH_API + "api/Filter/getLabelList",
  },
  update: {
    getToCreatePost: PATH_API + "api/Update/getToCreatePost",
    getFields: PATH_API + "api/Update/getFields",
    getCampaignInfo: PATH_API + "api/Update/getCampaignInfo",
    getToOptimizeCampaign: PATH_API + "api/Update/getToOptimizeCampaign",
    getToRelaunchCampaign: PATH_API + "api/Update/getToRelaunchCampaign",
    getToOptimizeCampaignNew: PATH_API + "api/Update/getToOptimizeCampaignNew",
    getCampaignCoverage: PATH_API + "api/Update/getCampaignCoverage",
    getNoCoveredPost: PATH_API + "api/Update/getNoCoveredPost",
    getCampaignList: PATH_API + "api/Update/getCampaignList",
    changePromoteStatus: PATH_API + "api/Update/changePromoteStatus",
    getObCountry: PATH_API + "api/Update/getObCountry",
    campaignCreateAndUpdate: PATH_API + "api/Update/campaignCreateAndUpdate",
    createCreative: PATH_API + "api/Update/createCreative",
    updateTblCreative: PATH_API + "api/Update/updateTblCreative",
    deleteCreative: PATH_API + "api/Update/deleteCreative",
    updateCreative: PATH_API + "api/Update/updateCreative",
    getTopUrl: PATH_API + "api/Update/getTopUrl",
    getCreativeList: PATH_API + "api/Update/getCreativeList",
    noOptimize: PATH_API + "api/Update/noOptimize",
    getTopPerfCampaign: PATH_API + "api/Update/getTopPerfCampaign",
    getToPauseCampaign: PATH_API + "api/Update/getToPauseCampaign",
    pauseCampaign: PATH_API + "api/Update/pauseCampaign",
    hideLandingPage: PATH_API + "api/Update/hideLandingPage",
    getCpnDefaultFields: PATH_API + "api/Update/getCpnDefaultFields",
    batchRelaunchCpn: PATH_API + "api/Update/batchRelaunchCpn",
  },
  user: {
    login: PATH_API + "api/Index/login",
    loginByMail: PATH_API + "api/Index/loginByMail",
  },
  bysite: {
    getBysite1Data: PATH_API + "api/BySite/getCampaign1Data",
    getBysite2Data: PATH_API + "api/BySite/getCampaign2Data",
    addWhiteList: PATH_API + "api/BySite/addWhiteList",
    getWhiteListCampaignData: PATH_API + "api/BySite/getWhiteListCampaignData",
    getNewAccountCampaignData: PATH_API + "api/BySite/getNewAccountCampaignData",
    getSitesByCampaign: PATH_API + "api/BySite/getSitesByCampaign",
    adjustSiteCpc: PATH_API + "api/BySite/adjustSiteCpc",
    clearCache: PATH_API + "api/BySite/clearCacheAndUpdate",
    sitePerformance: PATH_API + "api/BySite/sitePerformance",
    getAccountByProduct: PATH_API + "api/BySite/getAccountByProduct",
    blockTblSite: PATH_API + "api/BySite/blockTblSite",
    blockObSite: PATH_API + "api/BySite/blockObSite",
    getBlockedLog: PATH_API + "api/BySite/getBlockedLog",
    getObMarketerBlockList: PATH_API + "api/BySite/getObMarketerBlockList",
    adjustAll: PATH_API + "api/BySite/adjustAll",
    getAdjustVal: PATH_API + "api/BySite/getAdjustVal",
    updateAdjustVal: PATH_API + "api/BySite/updateAdjustVal",
    unblockObSite: PATH_API + "api/BySite/unblockObSite",
    getObMarketers: PATH_API + "api/BySite/getObMarketers",
    getObMarketerBlockPublisherList: PATH_API + "api/BySite/getObMarketerBlockPublisherList",
    unblockObPublisher: PATH_API + "api/BySite/unblockObPublisher",
    getObMarketerBlockLists: PATH_API + "api/BySite/getObMarketerBlockLists",
    getBlacklist: PATH_API + "api/BySite/getBlacklist",
    updateBlacklist: PATH_API + "api/BySite/updateBlacklist",
    unblockTblSite: PATH_API + "api/BySite/unblockTblSite",
    getTblMarketers: PATH_API + "api/BySite/getTblMarketers",
    getTblMarketerBlockLists: PATH_API + "api/BySite/getTblMarketerBlockLists",
    getAccountByPlatform: PATH_API + "api/BySite/getAccountByPlatform",
    addSiteBlockCorn: PATH_API + "api/BySite/addSiteBlockCorn",
    updateSiteBlockCorn: PATH_API + "api/BySite/updateSiteBlockCorn",
    getSiteBlockCorn: PATH_API + "api/BySite/getSiteBlockCorn",
    getSiteBlockCornById: PATH_API + "api/BySite/getSiteBlockCornById",
    getSiteBlockCornRes: PATH_API + "api/BySite/getSiteBlockCornRes",
  },
  abtest: {
    abTest: PATH_API + "api/Look/abTest",
    siteList: PATH_API + "api/Look/siteList",
    abTestDetail: PATH_API + "api/Look/abTestDetail",
  },
  aff: {
    getAffTotalPerf: PATH_API + "api/Aff/getAffTotalPerf",
    getAffPerfByDay: PATH_API + "api/Aff/getAffPerfByDay",
    getAffCampaignPerf: PATH_API + "api/Aff/getAffCampaignPerf",
    getAffCampaignPerfByDay: PATH_API + "api/Aff/getAffCampaignPerfByDay",
    getSitesByCampaign: PATH_API + "api/Aff/getSitesByCampaign",
    adjustSiteCpc: PATH_API + "api/Aff/adjustSiteCpc",
    getFields: PATH_API + "api/Aff/getFields",
    getCreativeList: PATH_API + "api/Aff/getCreativeList",
    updateCampaign: PATH_API + "api/Aff/updateCampaign",
    campaignCreateAndUpdate: PATH_API + "api/Aff/campaignCreateAndUpdate",
  },
  auto: {
    getValuablePost: PATH_API + "api/Auto/getValuablePost",
    getCoveredList: PATH_API + "api/Auto/getCoveredList",
    getFields: PATH_API + "api/Auto/getFields",
    preTest: PATH_API + "api/Auto/preTest",
    batchCoverCampaign: PATH_API + "api/Auto/batchCoverCampaign",
    getCreativeList: PATH_API + "api/Auto/getCreativeList",
    addCreative: PATH_API + "api/Auto/addCreative",
    deleteCreative: PATH_API + "api/Auto/deleteCreative",
    hidePost: PATH_API + "api/Auto/hideTopPost",
    getLandingPagePerf: PATH_API + "api/Auto/getLandingPagePerf",
  },
  alan: {
    getAlanReport: PATH_API + "api/Look/getAlanReport",
    getAdViewOption: PATH_API + "api/TeamDash/getAdViewOption",
    getAdViewPerf: PATH_API + "api/TeamDash/getAdViewPerf",
  },
  tools: {
    pushAdd: PATH_API + "api/Tools/pushAdd",
    pushAddImmediate: PATH_API + "api/Tools/pushAddImmediate",
    pushGetHistory: PATH_API + "api/Tools/pushGetHistory",
    getTaskLog: PATH_API + "api/Tools/getTaskLog",
    runTask: PATH_API + "api/Tools/runTask",
    g7PushAdd: PATH_API + "api/Tools/g7PushAdd",
    pushGetG7History: PATH_API + "api/Tools/pushGetG7History",
  },

  daily: {
    // Adwords
    getAdwordsAccountList:PATH_API + "api/Report/getAdwordsAccountList",
    getAdwordsByCampaign: PATH_API + "api/Report/getAdwordsByCampaign",
    getAdwordsGa4AccountList:PATH_API + "api/Report/getAdwordsGa4AccountList",
    getAdwordsGa4ByCampaign: PATH_API + "api/Report/getAdwordsGa4ByCampaign",
    // Bing
    getBingGa4AccountList:PATH_API + "api/Report/getBingGa4AccountList",
    getBingGa4ByCampaign: PATH_API + "api/Report/getBingGa4ByCampaign",
  }
};

module.exports = api;
