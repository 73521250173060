<template>
  <div>
    <div class="ant-page-header-tl">
      <a-range-picker :default-value="dateString" @change="onDateChange" />
      <a-button type="primary" @click="getData" style="margin-left: 8px"
        >Apply</a-button
      >
    </div>
    <div
      class="section-wrapper"
      style=" margin-left: 24px; margin-right: 300px;float: left;"
    >
      <a-card :bordered="false" title="Revenue Compare">
        <a-table
          :columns="columns1"
          :data-source="dayzumList"
          :rowKey="(record) => record.key"
          :pagination="false"
          size="medium"
          bordered
          :loading="loading"
          class="znum-data-table"
        >
        </a-table>
      </a-card>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
const defaultStartDate = moment()
  .startOf("day")
  .subtract(2, "days");
const defaultEndDate = moment()
  .startOf("day")
  .subtract(2, "days");

const columns1 = [
  {
    title: "Product",
    dataIndex: "name",
    align: "left",
    width: 150,
    sorter: (a, b) => {
      return a.name.localeCompare(b.name);
    },
  },
  {
    title: "Platform",
    dataIndex: "medium",
    align: "left",
    width: 120,
    sorter: (a, b) => {
      return a.medium.localeCompare(b.medium);
    },
  },
  {
    title: "Group",
    dataIndex: "belong",
    align: "left",
    width: 90,
  },
  {
    title: "GA3",
    dataIndex: "g3",
    align: "right",
    customRender: (text, row) => {
      return {
        children: text,
        attrs: {
          style: `color: ${text === row.max ? "red" : ""}; text-align: right;`,
        },
      };
    },
    sorter: (a, b) => {
      return a.g3 - b.g3;
    },
  },
  {
    title: "GA4",
    dataIndex: "g4",
    align: "right",
    customRender: (text, row) => {
      return {
        children: text,
        attrs: {
          style: `color: ${text === row.max ? "red" : ""}; text-align: right;`,
        },
      };
    },
    sorter: (a, b) => {
      return a.g4 - b.g4;
    },
  },
  {
    title: "R1",
    dataIndex: "r1",
    align: "right",
    customRender: (text, row) => {
      return {
        children: text,
        attrs: {
          style: `color: ${text === row.max ? "red" : ""}; text-align: right;`,
        },
      };
    },
    sorter: (a, b) => {
      return a.r1 - b.r1;
    },
  },
  {
    title: "R2",
    dataIndex: "r2",
    align: "right",
    customRender: (text, row) => {
      return {
        children: text,
        attrs: {
          style: `color: ${text === row.max ? "red" : ""}; text-align: right;`,
        },
      };
    },
    sorter: (a, b) => {
      return a.r2 - b.r2;
    },
  },
];

export default {
  data() {
    return {
      moment,
      userInfo: {},
      columns1,
      loading: false,
      okrList_2: [],
      dayzumList: {},
      quarterList: [],
      defaultStartDate,
      defaultEndDate,
      start_date: "",
      end_date: "",
      dateString: [
        defaultStartDate.format("YYYY-MM-DD"),
        defaultEndDate.format("YYYY-MM-DD"),
      ],
    };
  },
  methods: {
    getDailyPerf() {
      this.loading = true;
      this.$post(api.ga4.getRevenueCompare, {
        token: this.userInfo.token,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dayzumList = res.data.data.map((item) => {
            const max = Math.max(item.r1, item.r2, item.g3, item.g4);
            return { ...item, max };
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    onDateChange(date, dateString) {
      this.dateString = dateString;
    },
    getData() {
      this.getDailyPerf();
    },
  },
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.getDailyPerf();
  },
};
</script>

<style>
.total-item {
  margin-right: 10px;
  margin-left: 10px;
  color: #ddd;
}
.znum-data-table tr:last-child td {
  border-top: 1px #e8e8e8;
}
</style>
