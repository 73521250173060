<template>
  <div>
    <div class="ant-page-header-tl">
        <a-select v-model="site" style="width: 170px; margin-right: 16px;" @change="handleSiteChange">
            <a-select-option v-for="(item, index) in site_list" :key="index" :value="item">{{item}}</a-select-option>
        </a-select>
        <a-range-picker :default-value="dateString" @change="onDateChange"/>
        <a-button type="primary" style="margin-left: 16px;" @click="submitDateChange">Apply</a-button>
    </div>
    <div style="margin: 16px 24px -10px;"><b>{{start_date}} ～ {{end_date}}</b><span style="margin-left: 24px">Compare to</span><b style="margin-left: 24px">{{start_date_before}} ～ {{end_date_before}}</b></div>
    <div class="section">
        <a-table 
          :columns="columns" 
          :data-source="dataList" 
          :rowKey="record => record.id"
          :pagination="false" 
          size="middle"
          bordered
          :loading="loading"
        >
          <div slot="pv_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>
          <div slot="sessions_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>
          <div slot="ps_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>
          <div slot="bounce_rate_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>
          <div slot="avg_session_duration_dod" slot-scope="text"><span v-if="text>0" style="color:#0b8043">{{text.toFixed(2)+'%'}}</span><span v-else style="color: #c53929">{{text.toFixed(2)+'%'}}</span></div>
        </a-table>
    </div>
  </div>
</template>
<script>
  import api from '@/common/api.js'
  import moment from 'moment';
  const defaultStartDate = moment().startOf('day').subtract(2, 'days');
  const defaultEndDate = moment().startOf('day').subtract(2, 'days')

  const columns = [
    {
      title:'Source/Medium',
      dataIndex: 'name',
      scopedSlots: { customRender: 'toline' },
      sorter: (a, b) => {
        if(!a.name.includes("sum")) {
          return a.name.localeCompare(b.name)
        }
      }
    },
    {
      title:'PV',
      dataIndex: 'pv',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {
        if(!a.name.includes("sum")) {
          return a.pv - b.pv
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'pv_dod',
      align: 'right',
      scopedSlots: { customRender: 'pv_dod' },
      sorter: (a, b) => {
        if(!a.name.includes("sum")) {
          return a.pv_dod - b.pv_dod
        }
      }
    },
    {
      title:'Sessions',
      dataIndex: 'sessions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {
        if(!a.name.includes("sum")) {
          return a.sessions - b.sessions
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'sessions_dod',
      align: 'right',
      scopedSlots: { customRender: 'sessions_dod' },
      sorter: (a, b) => {
        if(!a.name.includes("sum")) {
          return a.sessions_dod - b.sessions_dod
        }
      }
    },
    {
      title:'P/S',
      dataIndex: 'ps',
      align: 'right',
      customRender: text => {
        return parseFloat(text).toFixed(2)
      },
      sorter: (a, b) => {
        if(!a.name.includes("sum")) {
          return a.ps - b.ps
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'ps_dod',
      align: 'right',
      scopedSlots: { customRender: 'ps_dod' },
      sorter: (a, b) => {
        if(!a.name.includes("sum")) {
          return a.ps_dod - b.ps_dod
        }
      }
    },
    {
      title:'Bounce Rate',
      dataIndex: 'bounce_rate',
      align: 'right',
      customRender: text => {
        return parseFloat(text).toFixed(2)+'%'
      },
      sorter: (a, b) => {
        if(!a.name.includes("sum")) {
          return a.bounce_rate - b.bounce_rate
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'bounce_rate_dod',
      align: 'right',
      scopedSlots: { customRender: 'bounce_rate_dod' },
      sorter: (a, b) => {
        if(!a.name.includes("sum")) {
          return a.bounce_rate_dod - b.bounce_rate_dod
        }
      }
    },
    {
      title:'Avg. Session Duration',
      dataIndex: 'avg_session_duration',
      align: 'right',
      sorter: (a, b) => {
        if(!a.name.includes("sum")) {
          return a.avg_session_duration - b.avg_session_duration
        }
      }
    },
    {
      title:'DoD',
      dataIndex: 'avg_session_duration_dod',
      align: 'right',
      scopedSlots: { customRender: 'avg_session_duration_dod' },
      sorter: (a, b) => {
        if(!a.name.includes("sum")) {
          return a.avg_session_duration_dod - b.avg_session_duration_dod
        }
      }
    }
  ]

  export default {
    data() {
      return {
        userInfo: {},
        defaultStartDate,
        defaultEndDate,
        url: '',
        columns,
        dataList: [],
        site_list: [],
        loading: false,
        site: '',
        start_date: '',
        end_date: '',
        start_date_before: '',
        end_date_before:'',
        dateString: [defaultStartDate.format('YYYY-MM-DD'),defaultEndDate.format('YYYY-MM-DD')]
      }
    },
    methods: {
      handleSiteChange(e){
        console.log(e)
        this.site = e
      },
      onDateChange(date, dateString){
        this.dateString = dateString
        console.log(this.dateString); 
      },
      submitDateChange(){
        this.getList()
      },
      getSiteList(){
        this.$post(api.okr.getSiteList, {
          token: this.userInfo.token,
        }).then(res => {
          // console.log(res)
          if(res.data.status === 200) {
            this.site_list = res.data.data
          }
        });
      },
      getList(){
        this.loading = true
        this.$post(api.okr.getSingleSiteData, {
          site: this.site,
          start_date: this.dateString[0],
          end_date: this.dateString[1],
          token: this.userInfo.token
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.dataList = res.data.data.data
            this.start_date = res.data.data.start_date
            this.end_date = res.data.data.end_date
            this.start_date_before = res.data.data.start_date_before
            this.end_date_before = res.data.data.end_date_before
            // console.log(this.start_date)
            
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
    //   goCover(record){
    //     const {href} = this.$router.resolve({
    //       path: '/nocoverpost',
    //       query: {
    //         record: JSON.stringify(record),
    //       },
    //     })
    //     window.open(href, '_blank')
    //   },
    //   goTopUrl(record){
    //     const {href} = this.$router.resolve({
    //       path: '/topurl',
    //       query: {
    //         record: JSON.stringify(record),
    //         date: this.dateString
    //       },
    //     })
    //     window.open(href, '_blank')
    //   }
    },
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.site = this.$route.query.site
      this.getSiteList()
      this.getList()
    }
  };
</script>

<style> 
  .total-item{
    margin-right: 10px;
    margin-left: 10px;
    color: #ddd;
  }
</style>
