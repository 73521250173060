<template>
  <div>
    <div class="ant-page-header-tl">{{name}}
      <div class="righttop">
        <a-range-picker v-if="period.length" :default-value="[moment(period[0], 'YYYY-MM-DD'), moment(period[1], 'YYYY-MM-DD')]" @change="rangeChange" />
        <a-button type="primary" style="margin-left: 16px;" @click="submitRangeChange">Apply</a-button>
      </div>
    </div>
    <div class="section">
      <!-- <a-card :loading="loading" :bordered="false" :title="name"> -->
        <a-table 
          :columns="columns" 
          :data-source="dataList" 
          :rowKey="record => record.landing_page"
          :pagination="false"
          size="middle" 
          bordered
          :loading="loading"
        >
          <a slot="action" slot-scope="text, record" @click="goCampaign(url, name, record.landing_page)">Check</a>
        </a-table>
      <!-- </a-card>  -->
    </div>
  </div>
</template>
<script>
  // import api from '@/common/api.js'
  import moment from 'moment';
  const columns = [
    {
      title:'Landing Page',
      dataIndex: 'landing_page',
      sorter: (a, b) => a.landing_page.localeCompare(b.landing_page)
      // sorter: (a, b) => a.landing_page - b.landing_page,
    },
    {
      title:'Cam Number',
      dataIndex: 'campaign_num',
      align: 'right',
      sorter: (a, b) => a.campaign_num - b.campaign_num,
    },
    // {
    //   title:'clicks',
    //   dataIndex: 'clicks',
    //   customRender: text => {
    //     return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
    //   },
    //   align: 'right'
    // },
    {
      title:'PV',
      dataIndex: 'page_views',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      align: 'right',
      sorter: (a, b) => a.page_views - b.page_views,
    },
    {
      title:'P/S',
      dataIndex: 'ps',
      customRender: text => {
        return text.toFixed(2)
      },
      align: 'right',
      sorter: (a, b) => a.ps - b.ps,
    },
    {
      title:'宗数',
      dataIndex: 'z_num',
      key: 'z_num',
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      align: 'right',
      sorter: (a, b) => a.z_num - b.z_num,
    },
    {
      title:'Detail',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
      align: 'center'
    }
  ]

  const columns2 = [
    {
      title: 'Platform',
      dataIndex: 'name',
    },
    {
      title:'Cam Number',
      dataIndex: 'campaign_num',
    },
    {
      title:'PV',
      dataIndex: 'page_views',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      }
    },
    {
      title:'P/S',
      dataIndex: 'ps',
      customRender: text => {
        return text.toFixed(2)
      }
    },
    {
      title:'宗数',
      dataIndex: 'z_num',
      key: 'z_num',
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      }
    },
    {
      title:'Detail',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
    }
  ]
  export default {
    data() {
      return {
        moment,
        period: [],
        userInfo: {},
        url: '',
        name: '',
        type: '',
        columns,
        columns2,
        dataList: [],
        loading: false,      
      }
    },
    methods: {
      rangeChange(date, dateString){
        this.period = dateString 
      },
      submitRangeChange(){
        this.getGroup()
      },
      getGroup(){
        this.loading = true
        let params = {
          type: 'group_data',
          platform: this.name,
          period: JSON.stringify(this.period),
          token: this.userInfo.token
        }
        if(this.type == '16'){
          params = {
            type: 'group_data',
            platform: this.name,
            param: this.country,
            period: JSON.stringify(this.period),
            token: this.userInfo.token
          }
        }
        this.$post(this.url, params).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.dataList = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      goCampaign(url, name, post_url){
        const {href} = this.$router.resolve({
          path: '/campaign',
          query: {
            url: url,
            name: name,
            post_url: post_url,
            period: JSON.stringify(this.period)
          },
        })
        window.open(href, '_blank')
      }
    },
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.url =  this.$route.query.url
      this.name = this.$route.query.name.toLowerCase()
      this.period = JSON.parse(this.$route.query.period)
      // console.log(moment(period.startDate))
      // this.type = this.$route.query.type
      if(this.$route.query.platform){
        this.type = '16'
        this.name = this.$route.query.platform
        this.country = this.$route.query.country
      }
      this.getGroup()
    }
  };
</script>

<style> 

</style>
