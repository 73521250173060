<template>
  <div>
    <div class="ant-page-header-tl">
      <span style="margin-right:5px">Date:</span>
      <a-range-picker :default-value="dateString" @change="onDateChange" />
      <a-select default-value="所有" style="width: 100px;margin-left:16px;" @change="promiseStatusChange">
        <a-select-option value="0">
          所有
        </a-select-option>
        <a-select-option value="1">
          已推广
        </a-select-option>
        <a-select-option value="2">
          未推广
        </a-select-option>
      </a-select>
      <a-button
        class="btn-right"
        type="primary"
        style="margin-left:16px;"
        @click="onCommit"
        >Apply</a-button
      >
    </div>
    <!-- table -->
    <div class="section">
      <span style="margin-left:25px">{{ this.name }}</span>
      <a-card :bordered="false">
        <a-table
          :columns="columns1"
          :data-source="dataList1"
          :pagination="false"
          :rowKey="(record) => record.promote_num"
          size="middle"
          style="margin-bottom: 24px; width: 28%"
          bordered
          :loading="loading"
        >
        </a-table>

        <a-table
          @change="handleTableChange"
          :columns="columns2"
          :data-source="dataList2"
          :pagination="false"
          :rowKey="(record) => record.id"
          size="middle"
          bordered
          :loading="loading"
        >
          <div
            v-if="record.url != 'SUM'"
            style="text-decoration: underline;cursor: pointer;"
            slot="tocampaign"
            slot-scope="text, record"
            @click="toCampaign(record.url)"
          >
            {{ text }}
          </div>
          <div v-else slot="tocampaign" slot-scope="text">
            <b>{{ text }}</b>
          </div>
        </a-table>
      </a-card>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
let weekOfDay = parseInt(moment().format("E"));
const defaultStartDate = moment()
  .subtract(weekOfDay + 7, "days")
  .format("YYYY-MM-DD");
const defaultEndDate = moment()
  .subtract(weekOfDay + 1, "days")
  .format("YYYY-MM-DD");

const columns1 = [
  {
    title: "推广数",
    dataIndex: "promote_num",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "Daily Session>100 Num",
    dataIndex: "running_num",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "占比",
    dataIndex: "running_percent",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return (
        parseFloat(text.toString() * 100)
          .toFixed(0)
          .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "%"
      );
    },
  },
  {
    title: "宗数为正文章数",
    dataIndex: "profit_num",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "占比",
    dataIndex: "profit_percent",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return (
        parseFloat(text.toString() * 100)
          .toFixed(0)
          .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "%"
      );
    },
  },
];

export default {
  data() {
    return {
      isSwitch: false,
      userInfo: {},
      columns1,
      // columns2,
      dataList1: [],
      dataList2: [],
      loading: false,
      name: "",
      defaultStartDate,
      defaultEndDate,
      filteredInfo: null,
      dateString: [defaultStartDate, defaultEndDate],
      prmoteStatus:0
    };
  },

  computed: {
    columns2() {
      const columns2 = [
        {
          title: "序号",
          dataIndex: "id",
          sorter: (a, b) => {
            if (a.id !== "/") {
              return a.id - b.id;
            }
          },
        },
        {
          title: "文章LP",
          dataIndex: "url",
          scopedSlots: { customRender: "tocampaign" },
          sorter: (a, b) => {
            if (a.id !== "/") {
              return a.url.localeCompare(b.url);
            }
          },
        },
        {
          title: "推广状态",
          dataIndex: "is_promote",
        },
        {
          title: "推广天数",
          dataIndex: "days",
          align: "right",
          customRender: (text) => {
            if (text == undefined) return "/";
            return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
          },
          sorter: (a, b) => {
            if (a.id !== "/") {
              return a.days - b.days;
            }
          },
        },
        {
          title: "Session",
          dataIndex: "sessions",
          align: "right",
          customRender: (text) => {
            if (text == undefined) return "/";
            return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
          },
          sorter: (a, b) => {
            if (a.id !== "/") {
              return a.sessions - b.sessions;
            }
          },
        },
        {
          title: "PV",
          dataIndex: "page_views",
          align: "right",
          customRender: (text) => {
            if (text == undefined) return "/";
            return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
          },
          sorter: (a, b) => {
            if (a.id !== "/") {
              return a.page_views - b.page_views;
            }
          },
        },
        {
          title: "P/S",
          dataIndex: "p_s",
          align: "right",
          customRender: (text) => {
            if (text == undefined) return "/";
            return parseFloat(text.toString())
              .toFixed(1)
              .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
          },
          sorter: (a, b) => {
            if (a.id !== "/") {
              return a.p_s - b.p_s;
            }
          },
        },
        {
          title: "Ads*",
          dataIndex: "ads_score",
          align: "right",
          customRender: (text) => {
            if (text == undefined) return "/";
            return parseFloat(text.toString())
              .toFixed(1)
              .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
          },
          sorter: (a, b) => {
            if (a.id !== "/") {
              return a.ads_score - b.ads_score;
            }
          },
        },
        {
          title: "CPC 上限",
          dataIndex: "cpc_top",
          align: "right",
          customRender: (text) => {
            if (text == undefined) return "/";
            return parseFloat(text.toString())
              .toFixed(3)
              .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
          },
          sorter: (a, b) => {
            if (a.id !== "/") {
              return a.cpc_top - b.cpc_top;
            }
          },
        },
        {
          title: "宗数",
          dataIndex: "z_num",
          customRender: (text) => {
            if (text == undefined) return "/";
            return parseFloat(text.toString())
              .toFixed(1)
              .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
          },
          align: "right",
          sorter: (a, b) => {
            if (a.id !== "/") {
              return a.z_num - b.z_num;
            }
          },
        },
        {
          title: "Daily Session",
          dataIndex: "daily_session",
          align: "right",
          customRender: (text) => {
            if (text == undefined) return "/";
            return parseFloat(text.toString())
              .toFixed(0)
              .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
          },
          sorter: (a, b) => {
            if (a.id !== "/") {
              return a.daily_session - b.daily_session;
            }
          },
        },
        {
          title: "Daily PV",
          dataIndex: "daily_pv",
          align: "right",
          customRender: (text) => {
            if (text == undefined) return "/";
            return parseFloat(text.toString())
              .toFixed(0)
              .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
          },
          sorter: (a, b) => {
            if (a.id !== "/") {
              return a.daily_pv - b.daily_pv;
            }
          },
        },
        {
          title: "Daily 宗数",
          dataIndex: "daily_z_num",
          align: "right",
          customRender: (text) => {
            if (text == undefined) return "/";
            return parseFloat(text.toString())
              .toFixed(1)
              .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
          },
          sorter: (a, b) => {
            if (a.id !== "/") {
              return a.daily_z_num - b.daily_z_num;
            }
          },
        },
      ];
      return columns2;
    },
  },
  // 定义方法
  methods: {
    // 修改时间
    onDateChange(date, dateString) {
      this.dateString = dateString;
    },

    handleTableChange(pagination,filters) {
        this.filteredInfo = filters;
      },

    onCommit() {
      this.getList();
    },
    // promise状态改变
    promiseStatusChange(value){
      this.prmoteStatus = Number(value)
    },
    // 跳转到campaign页面
    toCampaign(url) {
      const { href } = this.$router.resolve({
        path: "/WorkCampaign",
        query: {
          url: url,
          start_date: this.dateString[0],
          end_date: this.dateString[1],
        },
      });
      window.open(href, "_blank");
    },

    // 获取数据列表
    getList() {
      this.loading = true;
      this.$post(api.okr.getWorkIndividual, {
        token: this.userInfo.token,
        name: this.name,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
        is_switch: this.isSwitch,
        prmote_status:this.prmoteStatus
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataList2 = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },

  // 页面初始化
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.name = this.$route.query.name;
    this.isSwitch = this.$route.query.is_switch;
    this.dateString[0] = this.$route.query.start_date;
    this.dateString[1] = this.$route.query.end_date;
    this.dataList1 = [
      {
        promote_num: this.$route.query.promote_num,
        running_num: this.$route.query.running_num,
        running_percent:
          this.$route.query.promote_num == 0
            ? 0
            : this.$route.query.running_num / this.$route.query.promote_num,
        profit_num: this.$route.query.profit_num,
        profit_percent:
          this.$route.query.promote_num == 0
            ? 0
            : this.$route.query.profit_num / this.$route.query.promote_num,
      },
    ];
    this.getList();
  },
};
</script>
