<template>
  <div>
    <!-- date -->
    <div class="ant-page-header-tl">
      <a-select v-model="platform" defaultValue="Taboola" style="width: 120px; margin-right: 16px;" @change="handlePlatformChange">
        <a-select-option v-for="(item, index) in platformList" :key="index" :value="item.name">{{item.name}}</a-select-option>
      </a-select>
      <a-range-picker :default-value="dateString" @change="onDateChange"/>
      <a-button type="primary" style="margin-left: 16px;" @click="submitDateChange">Apply</a-button>
    </div>
    <div style="margin: 16px 24px -10px;">{{this.platform}}</div>
    <!-- table -->
    <div class="section">
        <a-table 
          :columns="columns" 
          :data-source="dataList" 
          :rowKey="record => record.date"
          :pagination="false" 
          size="middle"
          bordered
          :loading="loading"
        >
        </a-table>
    </div>
  </div>
</template>
<script>
  import api from '@/common/api.js'
  import moment from 'moment';
  const defaultStartDate = moment().startOf('day').subtract(31, 'days');
  const defaultEndDate = moment().startOf('day').subtract(2, 'days')

  const columns = [
    {
      title:'Date',
      dataIndex: 'date',
      sorter: (a, b) => {
        return a.platform.localeCompare(b.date)
      }
    },
    {
      title:'Total Revenue',
      dataIndex: 'revenue',
      align: 'right',
      customRender: text => {
        return parseFloat(text.toString()).toFixed(2).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.revenue - b.revenue
      }
    },
    {
      title:'Total Spent',
      dataIndex: 'spent',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.spent - b.spent
      }
    },
    {
      title:'ROAS',
      dataIndex: 'roas',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {
        return a.roas - b.roas
      }
    },
    {
      title:'Clicks',
      dataIndex: 'clicks',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.clicks - b.clicks
      }
    },
    {
      title:'Outbound Conversions',
      dataIndex: 'outbound_conversions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.outbound_conversions - b.outbound_conversions
      }
    },
    {
      title:'Outbond CVR',
      dataIndex: 'outbound_cvr',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {
        return a.outbound_cvr - b.outbound_cvr
      }
    },
    {
      title:'Affilite Conversions ',
      dataIndex: 'aff_conversions',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      },
      sorter: (a, b) => {
        return a.aff_conversions - b.aff_conversions
      }
    },
    {
      title:'Affilite CVR ',
      dataIndex: 'aff_cvr',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {
        return a.aff_cvr - b.aff_cvr
      }
    },
  ]

  export default {
    data() {
      return {
        userInfo: {},
        defaultStartDate,
        defaultEndDate,
        columns,
        dataList: [],
        loading: false,
        start_date: '',
        end_date: '',
        platform: 'Taboola',
        dateString: [moment(defaultStartDate, 'YYYY-MM-DD'), moment(defaultEndDate, 'YYYY-MM-DD')],
        platformList: [
          {id: 0, name: 'Taboola'},
          {id: 1, name: 'Outbrain'},
          {id: 2, name: 'Total'},
        ],
      }
    },
    methods: {
      onDateChange(dateString){
        this.dateString = dateString
      },
      // 修改日期获取数据
      submitDateChange(){
        this.getList()
      },
      // 获取数据列表
      getList() {
        this.loading = true
        this.$post(api.aff.getAffPerfByDay, {
          platform: this.platform,
          start_date: this.dateString[0].format('YYYY-MM-DD'),
          end_date: this.dateString[1].format('YYYY-MM-DD'),
          token: this.userInfo.token
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.dataList = res.data.data.data
            this.start_date = res.data.data.start_date
            this.end_date = res.data.data.end_date
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      // 下拉选择平台
      handlePlatformChange(e){
        this.platform = e
      },

    },

    // 页面初始化
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.platform = this.$route.query.platform
      this.getList()
    },
  };
</script>
