<template>
  <div class="adw-perf">
    <div class="ant-page-header-tl">
      <span style="margin-right:5px">Date:</span>
      <a-range-picker :default-value="dateString" @change="onDateChange" />
      <a-button
        class="btn-right"
        type="primary"
        style="margin-left:16px;"
        @click="onCommit"
        >Apply</a-button
      >
    </div>
    <div style="margin: 16px 24px -10px;" v-if="showDate">
      <b>{{ showDate.start_date }} ～ {{ showDate.end_date }}</b
      ><span style="margin-left: 24px">Compare to</span
      ><b style="margin-left: 24px"
        >{{ showDate.start_date_before }} ～ {{ showDate.end_date_before }}</b
      >
    </div>
    <!-- table -->
    <div class="section">
      <a-table
        :columns="columns"
        :data-source="dataList"
        :pagination="false"
        :rowKey="(record) => record.type + record.sessions"
        size="middle"
        bordered
        :loading="loading"
        defaultExpandAllRows
        v-if="dataList.length > 0"
      >
        <span
          style="text-decoration: underline;cursor: pointer;"
          slot="adwCampaignPerf"
          slot-scope="text, record"
          @click="adwCampaignPerf(record)"
          >{{ text }}</span
        >

        <div
          class="smallbtn"
          slot="action"
          slot-scope="text, record"
          style="padding: 4px 0px"
        >
          <a-button type="primary" size="small" @click="perfByDay(record)"
            >Check</a-button
          >
        </div>
      </a-table>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
const defaultStartDate = moment()
  .startOf("day")
  .subtract(7, "days")
  .format("YYYY-MM-DD");
const defaultEndDate = moment()
  .startOf("day")
  .subtract(1, "days")
  .format("YYYY-MM-DD");

const columns = [
  {
    title: "产品",
    dataIndex: "name",
    sorter: (a, b) => {
      return a.name.localeCompare(b.name);
    },
  },
  {
    title: "Cpn Type",
    scopedSlots: { customRender: "adwCampaignPerf" },
    dataIndex: "type",
    sorter: (a, b) => {
      return a.cpn_type.localeCompare(b.cpn_type);
    },
  },
  {
    title: "Sessions",
    dataIndex: "sessions",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.sessions - b.sessions;
    },
  },
  {
    title: "PV",
    dataIndex: "page_views",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.page_views - b.page_views;
    },
  },
  {
    title: "Avg.CPC",
    dataIndex: "avg_cpc",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(3)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.avg_cpc - b.avg_cpc;
    },
  },
  {
    title: "CPC 上限",
    dataIndex: "cpc_top",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(3)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.cpc_top - b.cpc_top;
    },
  },
  {
    title: "花费",
    dataIndex: "spent",
    align: "right",
    customRender: (text) => {
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.spent - b.spent;
    },
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.spent - b.spent;
    },
  },

  {
    title: "宗数 DOD",
    dataIndex: "z_num_dod",
    align: "right",
    width: 100,
    scopedSlots: { customRender: "z_num_dod" },
    customRender: (text) => {
      return text + "%";
    },
    sorter: (a, b) => {
      return a.z_num_dod - b.z_num_dod;
    },
  },
  {
    title: "宗数/花费",
    dataIndex: "z_s",
    align: "right",
    customRender: (text) => {
      return text + "%";
    },
    width: 100,
    sorter: (a, b) => {
      if (!a.name.includes("Total")) {
        return a.z_s - b.z_s;
      }
    },
  },

  {
    title: "session 占比",
    dataIndex: "session_percent",
    align: "right",
    customRender: (text) => {
      return text + "%";
    },
    width: 100,
    sorter: (a, b) => {
      return a.session_percent - b.session_percent;
    },
  },

  {
    title: "宗数占比",
    dataIndex: "z_num_percent",
    align: "right",
    customRender: (text) => {
      return text + "%";
    },
    width: 100,
    sorter: (a, b) => {
      return a.z_num_percent - b.z_num_percent;
    },
  },
  {
    title: "By Day",
    scopedSlots: { customRender: "action" },
    align: "center",
    width: 100,
  },
];

export default {
  data() {
    return {
      isSwitch: false,
      userInfo: {},
      columns,
      dataList: [],
      loading: false,
      defaultStartDate,
      defaultEndDate,
      dateString: [defaultStartDate, defaultEndDate],
      showDate: {},
    };
  },
  // 定义方法
  methods: {
    // 修改时间
    onDateChange(date, dateString) {
      this.dateString = dateString;
    },

    onCommit() {
      this.getList();
    },

    // 跳转到Campaign页面
    adwCampaignPerf(param) {
      const { href } = this.$router.resolve({
        path: "/AdwPerfByCampaign",
        query: {
          name: param.show_name,
          site: param.site,
          type: param.type,
          start_date: this.dateString[0],
          end_date: this.dateString[1],
        },
      });
      window.open(href, "_blank");
    },

    perfByDay(param) {
      const { href } = this.$router.resolve({
        path: "/AdwPerfByDay",
        query: {
          name: param.show_name,
          site: param.site,
          type: param.type,
          // start_date: this.dateString[0],
          // end_date: this.dateString[1],
        },
      });
      window.open(href, "_blank");
    },

    // 获取数据列表
    getList() {
      this.loading = true;
      this.$post(api.okr.getAdwPerfByType, {
        token: this.userInfo.token,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataList = res.data.data.list;
          this.showDate = res.data.data.date;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
  // 页面初始化
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.getList();
  },
};
</script>

<style scoped>
.adw-perf {
  font-family: "Roboto";
}
.adw-perf >>> .ant-table-tbody > tr.ant-table-row-level-0 {
  background: #eeeeee !important;
}
.adw-perf
  >>> .ant-table-tbody
  > tr.ant-table-row-level-0
  > td:nth-child(5n + 6) {
  background: #eeeeee !important;
}
</style>
