<template>
  <div class="all-product">
    <div class="ant-page-header-tl">
      <span style="margin-right:5px">Date:</span>
      <a-range-picker :default-value="dateString" @change="onDateChange" />
      <a-button
        class="btn-right"
        type="primary"
        style="margin-left:16px;"
        @click="onCommit"
        >Apply</a-button
      >
      <a-button
        class="btn-right"
        type="primary"
        icon="download"
        style="float: right"
        @click="handleExports"
        >Export</a-button
      >
    </div>

    <div class="section-wrapper zumDetail">
      <a-card :loading="loading" :bordered="false" :title="name">
        <a-row style="display:flex;">
          <div class="checkitem">
              <a-switch
                class="total_session"
                default-checked
                @change="onTotalSessionsChange"
              />
              Session
            </div>
            <div class="checkitem">
              <a-switch
                class="total_znum"
                default-checked
                @change="onTotalZNumChange"
              />
              宗数
            </div>
            <div class="checkitem">
              <a-switch class="total_pv" @change="onTotalPageViewsChange" />
              PV
            </div>
            <div class="checkitem">
              <a-switch
                class="total_ps"
                @change="onTotalPagePerSessionChange"
              />
              P/S
            </div>
            <div class="checkitem">
              <a-switch
                class="total_cpc"
                @change="onTotalCpcChange"
              />
              CPC上限
            </div>
        </a-row>
        <a-row>
          <!-- <a-col> -->
            <ve-line
              :data="velineData"
              :legend-visible="true"
              :settings="chartSettings"
              :extend="chartExtend"
            ></ve-line>
          <!-- </a-col> -->
          <!-- <a-col :span="3" :offset="1">
            <div class="checkitem">
              <a-switch
                class="total_session"
                default-checked
                @change="onTotalSessionsChange"
              />
              Session
            </div>
            <div class="checkitem">
              <a-switch
                class="total_znum"
                default-checked
                @change="onTotalZNumChange"
              />
              宗数
            </div>
            <div class="checkitem">
              <a-switch class="total_pv" @change="onTotalPageViewsChange" />
              PV
            </div>
            <div class="checkitem">
              <a-switch
                class="total_ps"
                @change="onTotalPagePerSessionChange"
              />
              P/S
            </div>
            <div class="checkitem">
              <a-switch
                class="total_cpc"
                @change="onTotalCpcChange"
              />
              CPC上限
            </div>
          </a-col> -->
        </a-row>
      </a-card>
    </div>
    <div class="section-wrapper subDetail">
      <a-card class="card-promote" :loading="loading" :bordered="false" title="Promote">
        <ve-line
          :data="velinePromoteData"
          :legend-visible="true"
          :settings="promoteSettings"
          :extend="promoteExtend"
        ></ve-line>
      </a-card>
      <a-card class="card-push" :loading="loading" :bordered="false" title="Push">
        <ve-line
          :data="velinePushData"
          :legend-visible="true"
          :settings="pushSettings"
          :extend="pushExtend"
        ></ve-line>
      </a-card>
      <a-card class="card-others" :loading="loading" :bordered="false" title="Other">
        <ve-line
          :data="velineOtherData"
          :legend-visible="true"
          :settings="otherSettings"
          :extend="otherExtend"
        ></ve-line>
      </a-card>
    </div>
    <!-- table -->
    <div class="section">
      <a-table
        :columns="columns"
        :data-source="dataList"
        :pagination="false"
        :rowKey="(record) => record.date"
        size="middle"
        bordered
        :loading="loading"
      >
      </a-table>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";

const defaultStartDate = moment()
  .startOf("day")
  .subtract(31, "days").format("YYYY-MM-DD");
const defaultEndDate = moment()
  .startOf("day")
  .subtract(2, "days").format("YYYY-MM-DD");

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    sorter: (a, b) => {
      return a.date.localeCompare(b.date);
    },
  },
  {
    title: "Promote",
    children: [
      {
        title: "Session",
        dataIndex: "promote_sessions",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.promote_sessions - b.promote_sessions;
        },
      },
      {
        title: "PV",
        dataIndex: "promote_page_views",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.promote_page_views - b.promote_page_views;
        },
      },
      {
        title: "P/S",
        dataIndex: "promote_page_per_session",
        align: "right",
        customRender: (text) => {
          return parseFloat(text.toString())
            .toFixed(1)
            .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.promote_page_per_session - b.promote_page_per_session;
        },
      },
      {
        title: "CPC上限",
        dataIndex: "promote_cpc_top",
        align: "right",
        customRender: (text) => {
          return parseFloat(text.toString())
            .toFixed(3)
            .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.total_cpc_top - b.total_cpc_top;
        },
      },
      {
        title: "宗数",
        dataIndex: "promote_z_num",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.promote_z_num - b.promote_z_num;
        },
      },
    ],
  },
  {
    title: "Push",
    children: [
      {
        title: "Session",
        dataIndex: "push_sessions",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.push_sessions - b.push_sessions;
        },
      },
      {
        title: "PV",
        dataIndex: "push_page_views",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.push_page_views - b.push_page_views;
        },
      },
      {
        title: "P/S",
        dataIndex: "push_page_per_session",
        align: "right",
        customRender: (text) => {
          return parseFloat(text.toString())
            .toFixed(1)
            .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.push_page_per_session - b.push_page_per_session;
        },
      },
      {
        title: "CPC上限",
        dataIndex: "push_cpc_top",
        align: "right",
        customRender: (text) => {
          return parseFloat(text.toString())
            .toFixed(3)
            .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.push_cpc_top - b.push_cpc_top;
        },
      },
      {
        title: "宗数",
        dataIndex: "push_z_num",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.push_z_num - b.push_z_num;
        },
      },
    ],
  },
  {
    title: "Others",
    children: [
      {
        title: "Session",
        dataIndex: "other_sessions",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.other_sessions - b.other_sessions;
        },
      },
      {
        title: "PV",
        dataIndex: "other_page_views",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.other_page_views - b.other_page_views;
        },
      },
      {
        title: "P/S",
        dataIndex: "other_page_per_session",
        align: "right",
        customRender: (text) => {
          return parseFloat(text.toString())
            .toFixed(1)
            .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.other_page_per_session - b.other_page_per_session;
        },
      },
      {
        title: "CPC上限",
        dataIndex: "other_cpc_top",
        align: "right",
        customRender: (text) => {
          return parseFloat(text.toString())
            .toFixed(3)
            .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.other_cpc_top - b.other_cpc_top;
        },
      },
      {
        title: "宗数",
        dataIndex: "other_z_num",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.other_z_num - b.other_z_num;
        },
      },
    ],
  },
  {
    title: "Total",
    children: [
      {
        title: "Session",
        dataIndex: "total_sessions",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.total_sessions - b.total_sessions;
        },
      },
      {
        title: "PV",
        dataIndex: "total_page_views",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.total_page_views - b.total_page_views;
        },
      },
      {
        title: "P/S",
        dataIndex: "total_page_per_session",
        align: "right",
        customRender: (text) => {
          return parseFloat(text.toString())
            .toFixed(1)
            .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.total_page_per_session - b.total_page_per_session;
        },
      },
      {
        title: "CPC上限",
        dataIndex: "total_cpc_top",
        align: "right",
        customRender: (text) => {
          return parseFloat(text.toString())
            .toFixed(3)
            .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.total_cpc_top - b.total_cpc_top;
        },
      },
      {
        title: "宗数",
        dataIndex: "total_z_num",
        align: "right",
        customRender: (text) => {
          return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        },
        sorter: (a, b) => {
          return a.total_z_num - b.total_z_num;
        },
      },
    ],
  },
];

export default {
  data() {
    return {
      userInfo: {},
      columns,
      dataList: [],
      loading: false,
      defaultStartDate,
      defaultEndDate,
      dateString: [
        defaultStartDate,
        defaultEndDate,
      ],
      velineData: {
        columns: ["date","total_sessions","total_z_num"],
        rows: [],
      },
      velinePromoteData: {
        columns: ["date","promote_sessions","promote_z_num"],
        rows: [],
      },
      velinePushData: {
        columns: ["date","push_sessions","push_z_num"],
        rows: [],
      },
      velineOtherData: {
        columns: ["date","other_sessions","other_z_num"],
        rows: [],
      },
      chartSettings: {
        axisSite: {
          right: ["total_page_per_session", "total_z_num","total_cpc_top"],
        },
      },
      promoteSettings: {
        axisSite: {
          right: ["promote_page_per_session", "promote_z_num","promote_cpc_top"],
        },
      },
      pushSettings: {
        axisSite: {
          right: ["push_page_per_session", "push_z_num","push_cpc_top"],
        },
      },
      otherSettings: {
        axisSite: {
          right: ["other_page_per_session", "other_z_num","other_cpc_top"],
        },
      },
      chartExtend: {
        color: ["#6578a0", "#1ad4ae","#fa6f87","#ffb980","#694d9f"],
        series: {
          type:'line',
          smooth: false,
        },
      },
      promoteExtend: {
        color: ["#6578a0", "#1ad4ae","#fa6f87","#ffb980","#694d9f"],
        series: {
          type:'line',
          smooth: false,
        },
      },
      pushExtend: {
        color: ["#6578a0", "#1ad4ae","#fa6f87","#ffb980","#694d9f"],
        series: {
          type:'line',
          smooth: false,
        },
      },
      otherExtend: {
        color: ["#6578a0", "#1ad4ae","#fa6f87","#ffb980","#694d9f"],
        series: {
          type:'line',
          smooth: false,
        },
      },
      medium: "by_date",
      site: "all",
      name: "All Product",
    };
  },
  // 定义方法
  methods: {
    // 修改时间
    onDateChange(dateString) {
      this.dateString[0] = dateString[0].format('YYYY-MM-DD');
      this.dateString[1] = dateString[1].format('YYYY-MM-DD');
    },
    onCommit() {
      this.getList();
    },

    // 导出
    handleExports() {
      const { columns, dataList } = this;
      this.loading = true;
      let newlist = [];
      require.ensure([], () => {
        const { export_json_to_excel } = require("../../excel/Export2ExcelMulti"); //---require 括号里面是相对路径其实是引用  Export2Excel.js
        const tHeader = []; //----tHeader 数组里面放的是字段的对应名
        const multitHeader = [
          'Site',
          'Promote',
          '',
          '',
          '',
          '',
          'Push',
          '',
          '',
          '',
          '',
          'Others',
          '',
          '',
          '',
          '',
          'Total',
          '',
          '',
          '',
          '',
        ];
        const merges = [
          "A1:A2",
          "B1:F1",
          "G1:K1",
          "L1:P1",
          "Q1:U1"
        ];

        const filterVal = []; //-----filterVal  字段对应的值
        columns &&
          columns.map((data) => {
            console.log(data);
            if(data.children){
              data.children.forEach(child => {
                tHeader.push(child.title)
                filterVal.push(child.dataIndex)
              })
            }else{
              tHeader.push(data.title)
              filterVal.push(data.dataIndex)
            }
          });
        this.title =
          "所有产品表现(by Date)_" +
          moment()
            .locale("zh-cn")
            .format("YYMMDDHHmm");
        newlist = dataList;

        const data = this.formatJson(filterVal, newlist);
        export_json_to_excel(multitHeader, tHeader, data, this.title, merges);
        this.loading = false;
        this.$message.success("导出成功");
      });
    },

    // 格式化json(配合上面一起)
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    // 获取数据列表
    getList() {
      this.loading = true;
      this.$post(api.okr.allSitesPerf, {
        token: this.userInfo.token,
        medium: this.medium,
        site: this.site,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataList = res.data.data;
          // this.velineData.columns = ["date"];
          // this.velineData.columns.push("total_sessions");
          // this.velineData.columns.push("total_z_num");
          this.velineData.rows = Array.from(this.dataList);
          this.velineData.rows.reverse();

          this.velinePromoteData.rows = Array.from(this.dataList);
          this.velinePromoteData.rows.reverse();
          this.velinePushData.rows = Array.from(this.dataList);
          this.velinePushData.rows.reverse();
          this.velineOtherData.rows = Array.from(this.dataList);
          this.velineOtherData.rows.reverse();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    onTotalSessionsChange(checked) {
      if (!checked) {
        this.velineData.columns.forEach((item, index) => {
          if (item == "total_sessions") {
            this.chartExtend.color.splice(index - 1, 1);
            this.velineData.columns.splice(index, 1);
          }
        });
        this.velinePromoteData.columns.forEach((item, index) => {
          if (item == "promote_sessions") {
            this.promoteExtend.color.splice(index - 1, 1);
            this.velinePromoteData.columns.splice(index, 1);
          }
        });
        this.velinePushData.columns.forEach((item, index) => {
          if (item == "push_sessions") {
            this.pushExtend.color.splice(index - 1, 1);
            this.velinePushData.columns.splice(index, 1);
          }
        });
        this.velineOtherData.columns.forEach((item, index) => {
          if (item == "other_sessions") {
            this.otherExtend.color.splice(index - 1, 1);
            this.velineOtherData.columns.splice(index, 1);
          }
        });
      } else {
        this.chartExtend.color.push("#59b1ef");
        this.velineData.columns.push("total_sessions");

        this.promoteExtend.color.push("#59b1ef");
        this.velinePromoteData.columns.push("promote_sessions");

        this.pushExtend.color.push("#59b1ef");
        this.velinePushData.columns.push("push_sessions");

        this.otherExtend.color.push("#59b1ef");
        this.velineOtherData.columns.push("other_sessions");
      }
      console.log(`a-switch to ${checked}`);
    },
    onTotalZNumChange(checked) {
      if (!checked) {
        this.velineData.columns.forEach((item, index) => {
          if (item == "total_z_num") {
            this.chartExtend.color.splice(index - 1, 1);
            this.velineData.columns.splice(index, 1);
          }
        });
        this.velinePromoteData.columns.forEach((item, index) => {
          if (item == "promote_z_num") {
            this.promoteExtend.color.splice(index - 1, 1);
            this.velinePromoteData.columns.splice(index, 1);
          }
        });
        this.velinePushData.columns.forEach((item, index) => {
          if (item == "push_z_num") {
            this.pushExtend.color.splice(index - 1, 1);
            this.velinePushData.columns.splice(index, 1);
          }
        });
        this.velineOtherData.columns.forEach((item, index) => {
          if (item == "other_z_num") {
            this.otherExtend.color.splice(index - 1, 1);
            this.velineOtherData.columns.splice(index, 1);
          }
        });
      } else {
        this.chartExtend.color.push("#1ad4ae");
        this.velineData.columns.push("total_z_num");

        this.promoteExtend.color.push("#1ad4ae");
        this.velinePromoteData.columns.push("promote_z_num");

        this.pushExtend.color.push("#1ad4ae");
        this.velinePushData.columns.push("push_z_num");

        this.otherExtend.color.push("#1ad4ae");
        this.velineOtherData.columns.push("other_z_num");
      }
      console.log(`a-switch to ${checked}`);
    },

    onTotalPageViewsChange(checked) {
      if (!checked) {
        this.velineData.columns.forEach((item, index) => {
          if (item == "total_page_views") {
            this.chartExtend.color.splice(index - 1, 1);
            this.velineData.columns.splice(index, 1);
          }
        });
        this.velinePromoteData.columns.forEach((item, index) => {
          if (item == "promote_page_views") {
            this.promoteExtend.color.splice(index - 1, 1);
            this.velinePromoteData.columns.splice(index, 1);
          }
        });
        this.velinePushData.columns.forEach((item, index) => {
          if (item == "push_page_views") {
            this.pushExtend.color.splice(index - 1, 1);
            this.velinePushData.columns.splice(index, 1);
          }
        });
        this.velineOtherData.columns.forEach((item, index) => {
          if (item == "other_page_views") {
            this.otherExtend.color.splice(index - 1, 1);
            this.velineOtherData.columns.splice(index, 1);
          }
        });
      } else {
        this.velineData.columns.push("total_page_views");
        this.chartExtend.color.push("#fa6f87");

        this.promoteExtend.color.push("#fa6f87");
        this.velinePromoteData.columns.push("promote_page_views");

        this.pushExtend.color.push("#fa6f87");
        this.velinePushData.columns.push("push_page_views");

        this.otherExtend.color.push("#fa6f87");
        this.velineOtherData.columns.push("other_page_views");
      }
      console.log(`a-switch to ${checked}`);
    },

    onTotalPagePerSessionChange(checked) {
      if (!checked) {
        this.velineData.columns.forEach((item, index) => {
          if (item == "total_page_per_session") {
            this.chartExtend.color.splice(index - 1, 1);
            this.velineData.columns.splice(index, 1);
          }
        });
        this.velinePromoteData.columns.forEach((item, index) => {
          if (item == "promote_page_per_session") {
            this.promoteExtend.color.splice(index - 1, 1);
            this.velinePromoteData.columns.splice(index, 1);
          }
        });
        this.velinePushData.columns.forEach((item, index) => {
          if (item == "push_page_per_session") {
            this.pushExtend.color.splice(index - 1, 1);
            this.velinePushData.columns.splice(index, 1);
          }
        });
        this.velineOtherData.columns.forEach((item, index) => {
          if (item == "other_page_per_session") {
            this.otherExtend.color.splice(index - 1, 1);
            this.velineOtherData.columns.splice(index, 1);
          }
        });
      } else {
        this.chartExtend.color.push("#ffb980");
        this.velineData.columns.push("total_page_per_session");

        this.promoteExtend.color.push("#ffb980");
        this.velinePromoteData.columns.push("promote_page_per_session");

        this.pushExtend.color.push("#ffb980");
        this.velinePushData.columns.push("push_page_per_session");

        this.otherExtend.color.push("#ffb980");
        this.velineOtherData.columns.push("other_page_per_session");
      }
      console.log(`a-switch to ${checked}`);
    },

    onTotalCpcChange(checked) {
      if (!checked) {
        this.velineData.columns.forEach((item, index) => {
          if (item == "total_cpc_top") {
            this.chartExtend.color.splice(index - 1, 1);
            this.velineData.columns.splice(index, 1);
          }
        });
        this.velinePromoteData.columns.forEach((item, index) => {
          if (item == "promote_cpc_top") {
            this.promoteExtend.color.splice(index - 1, 1);
            this.velinePromoteData.columns.splice(index, 1);
          }
        });
        this.velinePushData.columns.forEach((item, index) => {
          if (item == "push_cpc_top") {
            this.pushExtend.color.splice(index - 1, 1);
            this.velinePushData.columns.splice(index, 1);
          }
        });
        this.velineOtherData.columns.forEach((item, index) => {
          if (item == "other_cpc_top") {
            this.otherExtend.color.splice(index - 1, 1);
            this.velineOtherData.columns.splice(index, 1);
          }
        });
      } else {
        this.chartExtend.color.push("#694d9f");
        this.velineData.columns.push("total_cpc_top");

        this.promoteExtend.color.push("#694d9f");
        this.velinePromoteData.columns.push("promote_cpc_top");

        this.pushExtend.color.push("#694d9f");
        this.velinePushData.columns.push("push_cpc_top");

        this.otherExtend.color.push("#694d9f");
        this.velineOtherData.columns.push("other_cpc_top");
      }
      console.log(`a-switch to ${checked}`);
    },
  },
  // 页面初始化
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }

    if (this.$route.query.site !== undefined) {
      this.site = this.$route.query.site;
      this.name = this.$route.query.full_name;
      this.dateString[0] = this.$route.query.start_date;
      this.dateString[1] = this.$route.query.end_date;
    }

    this.getList();
  },
};
</script>
<style scoped>
  .checkitem {
    margin-right:30px;
    display: flex;
    align-items: center;
  }
  .checkitem button {
    margin-right:10px;
  }
  .all-product >>> .ant-table-thead > tr:nth-child(1) >th:nth-child(1),
  .all-product >>> .ant-table-tbody > tr >td:nth-child(1) {
    border-left: 1px solid #333;
  }
</style>
<style>
.ant-switch-checked.total_session {
  background-color: #59b1ef;
}
.ant-switch-checked.total_znum {
  background-color: #1ad4ae;
}
.ant-switch-checked.total_pv {
  background-color: #fa6f87;
}
.ant-switch-checked.total_ps {
  background-color: #ffb980;
}
.ant-switch-checked.total_cpc {
  background-color: #6950a1;
}
.all-product .ant-table-tbody {
  color:#000;
}
.all-product .ant-table-tbody > tr > td:nth-child(6) {
  background: #ccd9f5;
}
.all-product .ant-table-tbody > tr > td:nth-child(11) {
  background: #dce9d5;
}
.all-product .ant-table-tbody > tr > td:nth-child(16) {
  background: #f8e6d0;
}
.all-product .ant-table-tbody > tr > td:nth-child(21) {
  background: #e6d2db;
}

.all-product .ant-table-row-level-0:last-child > td {
  border-bottom:1px solid #333;
}
.subDetail {
  display:flex;
  justify-content: space-between;
}

.card-promote,
.card-push,
.card-others {
  width:33%;
}

</style>

