<template>
  <div>
    <div class="ant-page-header-tl">
      <a-range-picker :default-value="dateString" @change="onDateChange"/>
      <a-button type="primary" style="margin-left: 16px;" @click="submitDateChange">Apply</a-button>
    </div>
    <div style="margin: 16px 24px -10px;"><b>{{start_date}} ～ {{end_date}}</b></div>
    <div class="section">
       <!-- <div style="margin-bottom: 16px;" v-if="post_coverage_data"><b>TBL POST:</b>   Running {{post_coverage_data.tbl_running_lp_num}} <span class="total-item">| </span> Rejected {{post_coverage_data.tbl_rejected_lp_num}}<span class="total-item">|</span>   Total {{post_coverage_data.tbl_total_lp_num}} 
        <b style="margin-left: 100px;">OB POST:</b>  Running {{post_coverage_data.ob_running_lp_num}} <span class="total-item">| </span>  Rejected {{post_coverage_data.ob_rejected_lp_num}} <span class="total-item"> | </span>  NoCovered {{post_coverage_data.ob_no_covered_lp_num}} <span class="total-item">| </span>  Total {{post_coverage_data.ob_total_lp_num}}</div> -->
        <a-table 
          :columns="columns" 
          :data-source="dataList" 
          :rowKey="record => record.platform+record.country+record.os+record.device+record.audience"
          :pagination="false" 
          size="middle"
          :scroll="{ x: 1500, y: 1500}"
          bordered
          :rowClassName="isGray"
          :loading="loading"
        >
          <div v-if="record.platform != 'Total'" class="smallbtn" slot="action" slot-scope="text, record">
            <a-button type="primary" size="small" @click="goCover(record, 0)">覆盖</a-button>
            <a-button type="primary" size="small" style="margin-left: 6px;background-color: #4dcbcb;border-color: #4dcbcb" @click="goCover(record, 1)">Smart覆盖</a-button>
          </div> 
        </a-table>
    </div>
  </div>
</template>
<script>
  import api from '@/common/api.js'
  import moment from 'moment';
  const defaultStartDate = moment().startOf('day').subtract(8, 'days');
  const defaultEndDate = moment().startOf('day').subtract(2, 'days')

  const columns = [
    {
      title:'Platform',
      dataIndex: 'platform',
      sorter: (a, b) => {
        if(!a.platform.includes("Total") && a.covered_post_num != '/') { 
          return a.platform.localeCompare(b.platform)
        }
      }
    },
    {
      title:'Country',
      dataIndex: 'country',
      customRender: text => {if(text){return text}else{return '/'}},
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.country.localeCompare(b.country)}}
    },
    {
      title:'OS',
      dataIndex: 'os',
      customRender: text => {if(text){return text}else{return '/'}},
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.os.localeCompare(b.os)}}
    },
    {
      title:'Device',
      dataIndex: 'device',
      customRender: text => {if(text){return text}else{return '/'}},
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.device.localeCompare(b.device)}}
    },
    {
      title:'Audience',
      dataIndex: 'audience',
      customRender: text => {if(text){return text}else{return '/'}},
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.audience.localeCompare(b.audience)}}
    },
    {
      title:'Top Post个数',
      dataIndex: 'total_post_num',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.total_post_num - b.total_post_num}}
    },
    {
      title:'已覆盖Post个数',
      dataIndex: 'covered_post_num',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.covered_post_num - b.covered_post_num}}
    },
    {
      title:'Post覆盖率',
      dataIndex: 'coverage_rate',
      align: 'right',
      customRender: text => {
        return text + '%'
      },
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.coverage_rate - b.coverage_rate}}
    },
    {
      title:'Campaign个数',
      dataIndex: 'campaign_num',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.campaign_num - b.campaign_num}}
    },
    {
      title:'SB已覆盖Post个数',
      dataIndex: 'smart_covered_camp_num',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.smart_covered_camp_num - b.smart_covered_camp_num}}
    },
    {
      title:'SB Post覆盖率',
      dataIndex: 'smart_camp_coverage',
      align: 'right',
      customRender: text => {
        return text + '%'
      },
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.smart_camp_coverage - b.smart_camp_coverage}}
    },
    {
      title:'SB Campaign个数',
      dataIndex: 'smart_camp_num',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.smart_camp_num - b.smart_camp_num}}
    },
    {
      title:'Session',
      dataIndex: 'sessions',
      align: 'right',
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.sessions - b.sessions}}
    },
    {
      title:'PV',
      dataIndex: 'page_views',
      align: 'right',
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.page_views - b.page_views}}
    },
    {
      title:'P/S',
      dataIndex: 'p_s',
      align: 'right',
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.p_s - b.p_s}}
    },
    {
      title:'CPC 上限',
      dataIndex: 'cpc_top',
      align: 'right',
      // customRender: text => {
      //   return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      // },
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.cpc_top - b.cpc_top}}
    },
    {
      title:'Avg.CPC',
      dataIndex: 'avg_cpc',
      align: 'right',
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.avg_cpc - b.avg_cpc}}
    },
    {
      title:'Spent',
      dataIndex: 'spent',
      align: 'right',
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.spent - b.spent}}
    },
    {
      title:'Avg.Spent',
      dataIndex: 'avg_spent',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.avg_spent - b.avg_spent}}
    },
    {
      title:'系数',
      dataIndex: 'x_num',
      align: 'right',
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.x_num - b.x_num}},
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
    },
    {
      title:'宗数',
      dataIndex: 'z_num',
      align: 'right',
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.z_num - b.z_num}},
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
    },
    {
      title:'宗数方差',
      dataIndex: 'variance',
      align: 'right',
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.variance - b.variance}},
      customRender: text => {
        return Math.round(text).toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')
      },
    },
    {
      title:'Avg.宗数',
      dataIndex: 'avg_z_num',
      align: 'right',
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.avg_z_num - b.avg_z_num}}
    },
    {
      title:'Daily.宗数',
      dataIndex: 'daily_z_num',
      align: 'right',
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.avg_z_num - b.avg_z_num}}
    },
     {
      title:'ROI',
      dataIndex: 'roi',
      align: 'right',
      customRender: text => {
        return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '%'
      },
      sorter: (a, b) => {if(!a.platform.includes("Total") && a.covered_post_num != '/') {return a.roi - b.roi}}
    },
    {
        title:'Operate',
        className: 'per',
        scopedSlots: { customRender: 'action' },
        align: 'center',
        width: '150px'
    }
  ]

  export default {
    data() {
      return {
        userInfo: {},
        defaultStartDate,
        defaultEndDate,
        url: '',
        columns,
        dataList: [],
        loading: false,
        // post_coverage_data: {},
        start_date: defaultStartDate.format('YYYY-MM-DD'),
        end_date: defaultEndDate.format('YYYY-MM-DD'),
        dateString: [defaultStartDate.format('YYYY-MM-DD'),defaultEndDate.format('YYYY-MM-DD')]
      }
    },
    methods: {
      isGray(record) {
            if (record.covered_post_num == '/') {
              return 'gray_line'
            } else {
              return ''
            }
      },
      onDateChange(date, dateString){
        this.dateString = dateString
        console.log(this.dateString); 
      },
      submitDateChange(){
        this.getList()
      },
      getList(){
        this.loading = true
        this.$post(api.update.getCampaignCoverage, {
          start_date: this.dateString[0],
          end_date: this.dateString[1],
          token: this.userInfo.token
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.dataList = res.data.data.coverage_data
            // this.post_coverage_data = res.data.data.post_coverage_data
            // this.start_date = res.data.data.start_date
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
      goCover(record, type){
        // console.log(record)
        let covered_post_list
        if (type == 0) {
          covered_post_list = record.covered_post
        } else {
          covered_post_list = record.sb_covered_post
        }
        const {href} = this.$router.resolve({
          path: '/valuepost',
          query: {
            platform: record.platform,
            covered_post: JSON.stringify(covered_post_list),
          },
        })
        window.open(href, '_blank')
      },
      goTopUrl(record){
        const {href} = this.$router.resolve({
          path: '/topurl',
          query: {
            record: JSON.stringify(record),
            date: this.dateString
          },
        })
        window.open(href, '_blank')
      }
    },
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.getList()
    }
  };
</script>

<style> 
  .total-item{
    margin-right: 10px;
    margin-left: 10px;
    color: #ddd;
  }
  .gray_line {
    color: rgb(193, 193, 193);
  }
</style>
