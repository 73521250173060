<template>
  <div>
    <div class="ant-page-header-tl">
      <span style="margin-right:5px">Date:</span>
      <a-range-picker :default-value="dateString" @change="onDateChange" />
      <a-button
        class="btn-right"
        type="primary"
        style="margin-left:16px;"
        @click="onCommit"
        >Apply</a-button
      >
    </div>
    <!-- table -->
    <div class="section">
      <span style="margin-left:25px">{{ this.url }}</span>
      <a-card :bordered="false">
        <a-table
          :columns="columns"
          :data-source="dataList"
          :pagination="false"
          :rowKey="(record) => record.campaign_id"
          size="middle"
          bordered
          :loading="loading"
        >
          <div
            v-if="record.campaign_id != '/'"
            style="text-decoration: underline;cursor: pointer;"
            slot="campaign_name"
            slot-scope="text, record"
            @click="toLine(record.campaign_id)"
          >
            <a-popover>
              <template slot="content">
                {{ text }}
              </template>
              {{ text }}
            </a-popover>
          </div>
          <div v-else slot="campaign_name" slot-scope="text">
            {{ text }}
          </div>
        </a-table>
      </a-card>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
let weekOfDay = parseInt(moment().format("E"));
const defaultStartDate = moment()
  .subtract(weekOfDay + 7, "days")
  .format("YYYY-MM-DD");
const defaultEndDate = moment()
  .subtract(weekOfDay + 1, "days")
  .format("YYYY-MM-DD");
const columns = [
  {
    title: "序号",
    dataIndex: "id",
    sorter: (a, b) => {
      if (a.platform !== "SUM") {
        return a.id - b.id;
      }
    },
  },
  {
    title: "Platform",
    dataIndex: "platform",
    sorter: (a, b) => {
      if (a.platform !== "SUM") {
        return a.platform.localeCompare(b.platform);
      }
    },
  },
  {
    title: "Campaign ID",
    dataIndex: "campaign_id",
    customRender: (text) => {
      if (text == undefined) return "/";
      return text;
    },
    sorter: (a, b) => {
      if (a.platform !== "SUM") {
        return a.campaign_id.localeCompare(b.campaign_id);
      }
    },
  },
  {
    title: "Campaign Name",
    dataIndex: "campaign_name",
    scopedSlots: { customRender: "campaign_name" },
    customCell: () => {
      return {
        style: {
          "max-width": "360px",
        },
      };
    },
    sorter: (a, b) => {
      if (a.platform !== "SUM") {
        return a.campaign_name.localeCompare(b.campaign_name);
      }
    },
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    customRender: (text) => {
      if (text == undefined) return "/";
      return text;
    },
    sorter: (a, b) => {
      if (a.platform !== "SUM") {
        return a.start_date.localeCompare(b.start_date);
      }
    },
  },
  {
    title: "Session",
    dataIndex: "sessions",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (a.platform !== "SUM") {
        return a.sessions - b.sessions;
      }
    },
  },
  {
    title: "PV",
    dataIndex: "page_views",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (a.platform !== "SUM") {
        return a.page_views - b.page_views;
      }
    },
  },
  {
    title: "P/S",
    dataIndex: "p_s",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return parseFloat(text.toString())
        .toFixed(1)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (a.platform !== "SUM") {
        return a.p_s - b.p_s;
      }
    },
  },
  {
    title: "Ads*",
    dataIndex: "ads_score",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return parseFloat(text.toString())
        .toFixed(1)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (a.platform !== "SUM") {
        return a.ads_score - b.ads_score;
      }
    },
  },
  {
    title: "CPC 上限",
    dataIndex: "cpc_top",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return parseFloat(text.toString())
        .toFixed(3)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (a.platform !== "SUM") {
        return a.cpc_top - b.cpc_top;
      }
    },
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    customRender: (text) => {
      if (text == undefined) return "/";
      return parseFloat(text.toString())
        .toFixed(1)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    align: "right",
    sorter: (a, b) => {
      if (a.platform !== "SUM") {
        return a.z_num - b.z_num;
      }
    },
  },
  {
    title: "Daily Session",
    dataIndex: "daily_session",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (a.platform !== "SUM") {
        return a.daily_session - b.daily_session;
      }
    },
  },
  {
    title: "Daily PV",
    dataIndex: "daily_pv",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (a.platform !== "SUM") {
        return a.daily_pv - b.daily_pv;
      }
    },
  },
  {
    title: "Daily 宗数",
    dataIndex: "daily_z_num",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return parseFloat(text.toString())
        .toFixed(1)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      if (a.platform !== "SUM") {
        return a.daily_z_num - b.daily_z_num;
      }
    },
  },
];

export default {
  data() {
    return {
      userInfo: {},
      columns,
      dataList: [],
      loading: false,
      url: "",
      defaultStartDate,
      defaultEndDate,
      dateString: [
        defaultStartDate,
        defaultEndDate,
      ],
    };
  },
  // 定义方法
  methods: {
    // 修改时间
    onDateChange(date, dateString) {
      this.dateString = dateString;
    },

    onCommit() {
      this.getList();
    },

    // 跳转到camapign daily页面
    toLine(campaign_id) {
      const { href } = this.$router.resolve({
        path: "/line",
        query: {
          campaign_id: campaign_id,
          period: JSON.stringify([this.dateString[0], this.dateString[1]]),
        },
      });
      window.open(href, "_blank");
    },

    // 获取数据列表
    getList() {
      this.loading = true;
      this.$post(api.okr.getWorkCampaign, {
        token: this.userInfo.token,
        url: this.url,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataList = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },

  // 页面初始化
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.url = this.$route.query.url;
    this.dateString[0] = this.$route.query.start_date;
    this.dateString[1] = this.$route.query.end_date;
    this.getList();
  },
};
</script>
<style scoped>
.ant-table-thead >>> tr > th,
.ant-table-tbody > tr > td div {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
