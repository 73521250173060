<template>
  <div>
    <div class="ant-page-header-tl">
      <span style="margin-left:16px;margin-right:5px">Product:</span>
      <a-select defaultValue="" style="width: 150px;" @change="onProductChange">
        <a-select-option
          v-for="(item, index) in product_list"
          :key="index"
          :value="index"
          >{{ item }}</a-select-option
        >
      </a-select>
      <span style="margin-left:16px;margin-right:5px">Date:</span>
      <a-range-picker :default-value="dateString" @change="onDateChange" />
      <span style="margin-left:16px;margin-right:5px">Country:</span>
      <a-select defaultValue="United States" style="width: 150px;" @change="onCountryChange">
        <a-select-option
          v-for="(item, index) in country_list"
          :key="index"
          :value="item"
          >{{ item }}</a-select-option
        >
      </a-select>
      <span style="margin-left:16px;margin-right:5px">Device:</span>
      <a-select defaultValue="mobile" style="width: 150px;" @change="onDeviceChange">
        <a-select-option
          v-for="(item, index) in device_list"
          :key="index"
          :value="item"
          >{{ item }}</a-select-option
        >
      </a-select>
      <a-button
        class="btn-right"
        type="primary"
        style="margin-left:16px;"
        @click="buttonSubmit"
        >Apply</a-button
      >
    </div>
    <!-- table -->
    <div class="section">
      <a-table
        :columns="columns"
        :data-source="dataList"
        :rowKey="(record) => record.section_id"
        :pagination="{ pageSize: pageSize }"
        size="middle"
        bordered
        :loading="loading"
      >
      </a-table>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
const defaultStartDate = moment()
  .startOf("day")
  .subtract(31, "days")
  .format("YYYY-MM-DD");
const defaultEndDate = moment()
  .startOf("day")
  .subtract(2, "days")
  .format("YYYY-MM-DD");
const columns = [
  {
    title: "Date",
    dataIndex: "date",
    width: 100,
    sorter: (a, b) => {
      return a.date.localeCompare(b.date);
    },
  },
  {
    title: "ads.VTR",
    dataIndex: "ads_vtr",
    align: "right",
    customRender: (text) => {
      return (
        parseFloat(text.toString())
          .toFixed(2)
          .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "%"
      );
    },
    width: 100,
    sorter: (a, b) => {
      return a.ads_vtr - b.ads_vtr;
    },
  },
  {
    title: "ads.CTR",
    dataIndex: "ads_ctr",
    align: "right",
    customRender: (text) => {
      return (
        parseFloat(text.toString())
          .toFixed(2)
          .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "%"
      );
    },
    width: 100,
    sorter: (a, b) => {
      return a.ads_ctr - b.ads_ctr;
    },
  },
  {
    title: "CPC上限",
    dataIndex: "cpc_top",
    align: "right",
    width: 100,
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(3)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.cpc_top - b.cpc_top;
    },
  },
];

export default {
  data() {
    return {
      pageSize: 100,
      userInfo: {},
      columns,
      dataList: [],
      loading: false,
      product_list: [],
      product: "",
      country_list: [],
      country: "",
      device_list: [],
      device: "",
      selectedRowKeys: [],
      defaultStartDate,
      defaultEndDate,
      dateString: [
        defaultStartDate,
        defaultEndDate,
      ],
    };
  },
  // 定义方法
  methods: {
    onProductChange(e) {
      this.product = e;
    },
    onDateChange(date, dateString) {
      this.dateString = dateString;
    },
    onCountryChange(e) {
      this.country = e;
    },
    onDeviceChange(e) {
      this.device = e;
    },
    buttonSubmit() {
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$post(api.alan.getAdViewPerf, {
        token: this.userInfo.token,
        slug: this.product,
        device: this.device,
        country: this.country,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataList = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
        this.selectedRowKeys = [];
      });
    },
    getSelectOption() {
      this.loading = true;
      this.$post(api.alan.getAdViewOption, {
        token: this.userInfo.token,
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.product_list = res.data.data.product_list;
          this.country_list = res.data.data.country_list;
          this.device_list = res.data.data.device_list;
        } else {
          this.$message.error(res.data.message);
        }
        this.selectedRowKeys = [];
      });
    },
  },

  // 页面初始化
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.getSelectOption();
  },
};
</script>
