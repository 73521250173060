<template>
  <div>
    <div class="ant-page-header-tl">
      <span style="margin-left:5px;margin-right:5px">Date:</span>
       <a-range-picker :default-value="dateString" @change="onDateChange" />
      <span style="margin-left:16px;margin-right:5px">Platform:</span>
      <a-select
        :defaultValue="platform"
        style="width: 150px"
        @change="onPlatformChange"
      >
        <a-select-option
          v-for="(item, index) in platformList"
          :key="index"
          :value="item"
          >{{ item }}</a-select-option
        >
      </a-select>
      <span style="margin-left:16px;margin-right:5px">User:</span>
      <a-select
        defaultValue=""
        style="width: 150px"
        @change="onUserChange"
      >
        <a-select-option
          v-for="item in userList"
          :key="item.id"
          :value="item.name"
          >{{ item.name }}</a-select-option
        >
      </a-select>
      <a-button
        class="btn-right"
        type="primary"
        icon="download"
        style="float: right"
        @click="handleExports"
        >Export</a-button
      >
    </div>
    <!-- table -->
    <div class="section">
      <div style="margin-bottom: 16px;">
        <a-button
          type="primary"
          @click="addCampaignTracking"
          style="background-color: #52c41a;border-color: #52c41a;"
          >Add Campaign Tracking</a-button
        >
      </div>
      <a-modal
        title="Add Campaign Tracking"
        :visible="acpVisible"
        :confirm-loading="acpConfirmLoading"
        @ok="addCampaignTrackingOk"
        @cancel="addCampaignTrackingCancel"
      >
      <div style="display:flex;justify-content:center;">
        <div>
          <div style="display:flex;align-item:center;justify-content:flex-end;">
            <span style="margin-right:20px;line-height:32px;">Campaign ID:</span>
            <a-input
              v-model="campaignId"
              style="width:200px;"
              placeholder="" />
          </div>
          <div style="display:flex;align-item:center;justify-content:flex-end;margin-top:20px;">
            <span style="margin-right:20px;line-height:32px;">Campaign Name:</span>
            <a-input
              v-model="campaignName"
              style="width:200px;"
              placeholder="" />
          </div>
          <div style="display:flex;align-item:center;justify-content:flex-end;margin-top:20px;">
            <span style="margin-right:20px;line-height:32px;">Create Date(添加时间):</span>
            <a-date-picker
              style="width:200px;"
              @change="dateOnChange" />
          </div>
          <div style="display:flex;align-item:center;justify-content:flex-end;margin-top:20px;">
            <span style="margin-right:20px;line-height:32px;">Start Date(开始时间，重推时添加):</span>
            <a-date-picker
              style="width:200px;"
              @change="startDateOnChange" />
          </div>
        </div>
      </div>
      </a-modal>
      <a-table
        style="width:100%;"
        :columns="columns"
        :data-source="dataList"
        :rowKey="(record) => record.campaign_id"
        size="middle"
        :pagination="{ pageSize: pageSize }"
        bordered
        :loading="loading"
      >

        <div
          style="text-decoration: underline;cursor: pointer;"
          slot="campaign_name"
          slot-scope="text, record"
          @click="toLine(record.campaign_id)"
        >
          <a-popover>
            <template slot="content">
              {{ text }}
            </template>
              {{ text }}
          </a-popover>
        </div>
        <div
          slot="landing_page"
          slot-scope="text"
        >
          <a-popover>
            <template slot="content">
              {{ text }}
            </template>
              {{ text }}
          </a-popover>
        </div>
        <div
          slot="create_remark"
          slot-scope="text, record"
        >
          <a-input
            style="resize:none;height:24px;width:80px;"
            v-model="record.remark"
          ></a-input>
          <a
            @click="toRemark(record.campaign_id,record.remark)"
            style="margin-left:10px;">提交</a>
        </div>
      </a-table>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
const platformList = ["All", "Taboola", "Outbrain"];
const defaultStartDate = moment()
  .startOf("day")
  .subtract(29, "days").format("YYYY-MM-DD");
const defaultEndDate = moment()
  .startOf("day")
  .subtract(0, "days").format("YYYY-MM-DD");

const columns = [
  {
    title: "Platform",
    dataIndex: "platform",

    sorter: (a, b) => {
      return a.platform.localeCompare(b.platform);
    },
  },
  {
    title: "Campaign ID",
    dataIndex: "campaign_id",
    sorter: (a, b) => {
      return a.campaign_id.localeCompare(b.campaign_id);
    },
  },
  {
    title: "Campaign Name",
    dataIndex: "campaign_name",
    customCell: () => {
      return {
        style: {
          'max-width': '360px',
        },
      };
    },
    scopedSlots: { customRender: "campaign_name" },
    sorter: (a, b) => {
      return a.campaign_name.localeCompare(b.campaign_name);
    },
  },
  {
    title: "Landing Page",
    dataIndex: "landing_page",
    scopedSlots: { customRender: 'landing_page' },
    customCell: () => {
      return {
        style: {
          'max-width': '360px',
        },
      };
    },
    sorter: (a, b) => {
      return a.landing_page.localeCompare(b.landing_page);
    },
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    sorter: (a, b) => {
      return a.start_date.localeCompare(b.start_date);
    },
  },
  {
    title: "Create Date",
    dataIndex: "create_date",
    sorter: (a, b) => {
      return a.create_date.localeCompare(b.create_date);
    },
  },
  {
    title: "Status",
    dataIndex: "campaign_status",
    filters:[],
    onFilter: (value, record) => record.campaign_status == value,
    sorter: (a, b) => {
      return a.campaign_status.localeCompare(b.campaign_status);
    },
  },
  {
    title: "User",
    dataIndex: "create_user",
    sorter: (a, b) => {
      return a.create_user.localeCompare(b.create_user);
    },
  },
  {
    title:"备注",
    scopedSlots: { customRender: 'create_remark' },
  }
];

export default {
  data() {
    return {
      userInfo: {},
      columns,
      pageSize: 60,
      dataList: [],
      url: "",
      loading: false,
      defaultStartDate,
      defaultEndDate,
      dateString: [
        moment(defaultStartDate, "YYYY-MM-DD"),
        moment(defaultEndDate, "YYYY-MM-DD"),
      ],
      platform: "Taboola",
      platformList,
      userList: [
        { id: 'wanghaixia', name: "Haixia" },
        { id: 'sunzhizhen', name: "ZZ" },
        { id: 'geaijia', name: "Karen" },
        { id: 'wumengyuan', name: "MY" },
        { id: 'zhouzixuan', name: "Zixuan" },
        { id: 'shanghezhu', name: "Hezhu" },
        { id: 'liuyutian', name: "Yutian" },
      ],
      name: "wanghaixia",
      campaign_id: '',
      create_date: '',
      start_date_manual: '',
      acpVisible: false,
      acpConfirmLoading: false,
      campaignId:'',
      campaignName:'',
      campaignDate:'',
    };
  },
  // 定义方法
  methods: {
    // 修改时间
    onDateChange(date, dateString) {
      this.dateString = dateString;
      this.getList();
    },

    // 修改平台
    onPlatformChange(e) {
      this.platform = e;
      this.getList();
    },

    // 修改用户
    onUserChange(e, a) {
      this.name = a.key;
      this.getList();
    },

    // 导出
    handleExports() {
      const { columns, dataList } = this;
      this.loading = true;
      let newlist = [];
      require.ensure([], () => {
        const { export_json_to_excel } = require("../../excel/Export2Excel"); //---require 括号里面是相对路径其实是引用  Export2Excel.js
        const tHeader = []; //----tHeader 数组里面放的是字段的对应名
        const filterVal = []; //-----filterVal  字段对应的值
        columns &&
          columns.map((data) => {
            tHeader.push(data.title);
            filterVal.push(data.dataIndex);
          });
        this.title = "Campaign Tracking " + moment().locale('zh-cn').format('YYYY_MM_DD_HH_mm_ss');
        newlist = dataList;

        const data = this.formatJson(filterVal, newlist);
        export_json_to_excel(tHeader, data, this.title);
        this.loading = false;
        this.$message.success("导出成功");
      });
    },

    // 格式化json
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    // 跳转到camapign daily页面
    toLine(campaign_id) {
      const { href } = this.$router.resolve({
        path: "/line",
        query: {
          campaign_id: campaign_id,
          period: JSON.stringify([this.dateString[0], this.dateString[1]]),
        },
      });
      window.open(href, "_blank");
    },

    // 获取数据列表
    getList() {
      this.loading = true;
      this.$post(api.okr.CampaignTrackingList, {
        token: this.userInfo.token,
        user_name: this.name,
        platform: this.platform,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataList = res.data.data;
          let list = res.data.data;
          // 添加筛选内容
          let columns6Filter = []
          list.forEach(item => {
          let index = columns6Filter.findIndex(a => a.text == item.campaign_status)
          // console.log(index);
          if (index === -1) {
            columns6Filter.push({
              text: item.campaign_status,
              value: item.campaign_status,
            })
          }
        })
        this.columns[6].filters = columns6Filter
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    addCampaignTracking() {
      this.acpVisible = true
    },
    dateOnChange(date, dateString) {
      this.campaignDate = dateString
    },
    startDateOnChange(date, dateString) {
      this.start_date_manual = dateString
    },

    addCampaignTrackingOk(){
      this.acpConfirmLoading = true;
      // 发起请求
      this.$post(api.okr.CampaignTrackingAdd, {
        token: this.userInfo.token,
        campaign_id: this.campaignId,
        campaign_name: this.campaignName,
        create_date: this.campaignDate,
        start_date_manual: this.start_date_manual,
      }).then((res) => {
        if (res.data.status === 200) {
          this.acpVisible = false;
          this.acpConfirmLoading = false;
          this.$message.success('添加成功');
          this.getList();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    addCampaignTrackingCancel() {
      this.acpVisible = false;
      this.acpConfirmLoading = false;
    },
    toRemark(id,remark){
      this.$post(api.okr.CampaignTrackingRemarkAdd, {
        token: this.userInfo.token,
        campaign_id:id,
        remark:remark
      }).then((res) => {
        if (res.data.status === 200) {
          this.$message.success('添加备注成功');
          this.getList();
        } else {
          this.$message.error(res.data.message);
        }
      });
    }
  },
  // 页面初始化
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }
    this.name = this.userInfo.user_name;
    this.getList();
  },
};
</script>
<style scoped>
  .ant-table-thead >>> tr > th, .ant-table-tbody > tr > td div{
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
  }

</style>

