<template>
  <div>
    <div class="ant-page-header-tl">
      <span style="margin-right:5px">Platform: {{ this.platform }} </span>
      <span style="margin-left:16px;margin-right:5px">Date:</span>
      <a-range-picker :default-value="dateString" @change="onDateChange" />
      <a-button
        class="btn-right"
        type="primary"
        icon="download"
        style="float: right"
        @click="handleExports"
        >Export</a-button
      >
    </div>
    <!-- table -->
    <div class="section">
      <a-card :bordered="false" :title="url">
        <a-table
          :columns="columns"
          :data-source="dataList"
          :pagination="{ pageSize: pageSize }"
          :rowKey="(record) => record.campaign_id"
          size="middle"
          bordered
          :loading="loading"
        >
          <div
            style="text-decoration: underline;cursor: pointer;"
            slot="campaign_name"
            slot-scope="text, record"
            @click="toLine(record.campaign_id)"
          >
            <a-popover>
              <template slot="content">
                {{ text }}
              </template>
              {{ text }}
            </a-popover>
          </div>
          <div slot="landing_page" slot-scope="text">
            <a-popover>
              <template slot="content">
                {{ text }}
              </template>
              {{ text }}
            </a-popover>
          </div>

          <div slot="note" slot-scope="text, record">
            <a-input
              style="resize:none;height:24px;width:80px;"
              v-model="record.note"
            ></a-input>
            <a
              @click="toNote(record.campaign_id, record.note)"
              style="margin-left:10px;"
              >提交</a
            >
          </div>

          <div
            class="smallbtn"
            slot="action"
            slot-scope="text, record"
            style="padding: 4px 0px"
          >
            <a-button
              type="primary"
              size="small"
              @click="toPause(record.campaign_id)"
              >Pause</a-button
            >
          </div>
        </a-table>
      </a-card>
    </div>
  </div>
</template>
<script>
import api from "@/common/api.js";
import moment from "moment";
const defaultStartDate = '2022-01-01';
const defaultEndDate = moment()
  .startOf("day")
  .subtract(2, "days");
const platformList = ["Taboola", "Outbrain"];
const columns = [
  {
    title: "Campaign ID",
    dataIndex: "campaign_id",
    sorter: (a, b) => {
      return a.campaign_id.localeCompare(b.campaign_id);
    },
  },
  {
    title: "Campaign Name",
    dataIndex: "campaign_name",
    scopedSlots: { customRender: "campaign_name" },
    customCell: () => {
      return {
        style: {
          "max-width": "360px",
        },
      };
    },
    sorter: (a, b) => {
      return a.campaign_name.localeCompare(b.campaign_name);
    },
  },
  {
    title: "Landing Page",
    dataIndex: "landing_page",
    scopedSlots: { customRender: "landing_page" },
    customCell: () => {
      return {
        style: {
          "max-width": "360px",
        },
      };
    },
    sorter: (a, b) => {
      return a.landing_page.localeCompare(b.landing_page);
    },
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    sorter: (a, b) => {
      return a.start_date.localeCompare(b.start_date);
    },
  },
  {
    title: "Status",
    dataIndex: "campaign_status",
    sorter: (a, b) => {
      return a.campaign_status.localeCompare(b.campaign_status);
    },
  },
  {
    title: "Session",
    dataIndex: "sessions",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return text.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "CPC 上限",
    dataIndex: "cpc_top",
    align: "right",
    customRender: (text) => {
      if (text == undefined) return "/";
      return parseFloat(text.toString())
        .toFixed(3)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  {
    title: "宗数",
    dataIndex: "z_num",
    align: "right",
    customRender: (text) => {
      return parseFloat(text.toString())
        .toFixed(0)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    sorter: (a, b) => {
      return a.z_num - b.z_num;
    },
  },
  {
    title: "Action",
    scopedSlots: { customRender: "action" },
    align: "center",
    width: 100,
  },
  {
    title: "Note",
    dataIndex: "note",
    scopedSlots: { customRender: "note" },
  },
];

export default {
  data() {
    return {
      userInfo: {},
      columns,
      dataList: [],
      url: "",
      loading: false,
      platform: "Taboola",
      name_id: "",
      project_id: "",
      platformList,
      defaultStartDate,
      defaultEndDate,
      campaign_id: [],
      dateString: [
        moment(defaultStartDate, "YYYY-MM-DD"),
        moment(defaultEndDate, "YYYY-MM-DD"),
      ],
      pageSize: 100,
    };
  },
  // 定义方法
  methods: {
    // 导出
    handleExports() {
      const { columns, dataList } = this;
      this.loading = true;
      let newlist = [];
      require.ensure([], () => {
        const { export_json_to_excel } = require("../../excel/Export2Excel"); //---require 括号里面是相对路径其实是引用  Export2Excel.js
        const tHeader = [];
        const filterVal = [];
        columns &&
          columns.map((data) => {
            tHeader.push(data.title);
            filterVal.push(data.dataIndex);
          });
        this.title =
          "Work Perf " +
          moment()
            .locale("zh-cn")
            .format("YYYY_MM_DD_HH_mm_ss");
        newlist = dataList;

        const data = this.formatJson(filterVal, newlist);
        export_json_to_excel(tHeader, data, this.title);
        this.loading = false;
        this.$message.success("导出成功");
      });
    },

    // 格式化json
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    // 修改时间
    onDateChange(dateString) {
      this.dateString = dateString;
      this.getList();
    },

    // 修改平台
    onPlatformChange(e) {
      this.platform = e;
      this.getList();
    },

    // 跳转到camapign daily页面
    toLine(campaign_id) {
      const { href } = this.$router.resolve({
        path: "/line",
        query: {
          campaign_id: campaign_id,
          period: JSON.stringify([this.dateString[0], this.dateString[1]]),
        },
      });
      window.open(href, "_blank");
    },

    // 获取数据列表
    getList() {
      this.loading = true;
      this.$post(api.okr.getPromoteWorkCampaign, {
        token: this.userInfo.token,
        url: this.url,
        project_id: this.project_id,
        name_id: this.name_id,
        platform: this.platform,
        start_date: this.dateString[0],
        end_date: this.dateString[1],
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.dataList = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    // 对Campaign做记录
    toNote(id, remark) {
      this.$post(api.okr.CampaignTrackingRemarkAdd, {
        token: this.userInfo.token,
        campaign_id: id,
        remark: remark,
        type: 1,
      }).then((res) => {
        if (res.data.status === 200) {
          this.$message.success("添加备注成功");
          this.getList();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    // 暂停campaign
    toPause(campaign_id) {
      this.loading = true;
      this.campaign_id = [campaign_id];
      this.$post(api.update.pauseCampaign, {
        token: this.userInfo.token,
        campaign_ids: this.campaign_id,
      }).then((res) => {
        this.loading = false;
        if (res.data.status === 200) {
          this.$message.success("Campaign Pause成功");
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
  // 页面初始化
  created() {
    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo);
    }
    if (!this.userInfo.user_name) {
      this.$router.push({ path: "/login" });
      return false;
    }

    this.url = this.$route.query.url;
    this.name_id = this.$route.query.name_id;
    this.project_id = this.$route.query.project_id;
    this.platform = this.$route.query.platform;
    this.dateString[0] = this.$route.query.start_date;
    this.dateString[1] = this.$route.query.end_date;
    this.getList();
  },
};
</script>
<style scoped>
.ant-table-thead >>> tr > th,
.ant-table-tbody > tr > td div {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
