<template>
    <!-- table -->
    <div class="section">
        <a-table 
          :columns="columns" 
          :data-source="dataList" 
          :rowKey="record => record.id"
          :pagination="{ pageSize: pageSize }"
          size="middle"
          bordered
          :loading="loading"
        >
        </a-table>
    </div>
</template>
<script>
  import api from '@/common/api.js'

  const columns = [
    {
      title:'Platform',
      dataIndex: 'platform',
      sorter: (a, b) => {
        return a.platform.localeCompare(b.platform)
      }
    },
    {
      title:'Type',
      dataIndex: 'type',
      sorter: (a, b) => {
        return a.type.localeCompare(b.type)
      }
    },
    {
      title:'Publisher',
      dataIndex: 'marketer',
      sorter: (a, b) => {
        return a.marketer.localeCompare(b.marketer)
      }
    },
    {
      title:'Site/Publisher',
      dataIndex: 'block_site',
      sorter: (a, b) => {
        return a.block_site.localeCompare(b.block_site)
      }
    },
    {
      title:'User',
      dataIndex: 'user_name',
      sorter: (a, b) => {
        return a.user_name.localeCompare(b.user_name)
      }
    },
    {
      title:'Block Time',
      dataIndex: 'create_time',
      sorter: (a, b) => {
        return a.create_time.localeCompare(b.create_time)
      }
    },
  ]

  export default {
    data() {
      return {
        pageSize: 100,
        userInfo: {},
        columns,
        dataList: [],
        loading: false,
      }
    },
    // 定义方法
    methods: {
      // 获取数据列表
      getList(){
        this.loading = true
        this.$post(api.bysite.getBlockedLog, {
          token: this.userInfo.token
        }).then(res => {
          this.loading = false
          if (res.data.status === 200) {
            this.dataList = res.data.data
          } else {
            this.$message.error(res.data.message)
          }
        });
      },
    },

    // 页面初始化
    created () {
      if(localStorage.userInfo){
        this.userInfo = JSON.parse(localStorage.userInfo)
      }
      if(!this.userInfo.user_name) {
        this.$router.push({ path: '/login' })
        return false
      }
      this.getList()
    },
  };
</script>
